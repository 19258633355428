import { Actions } from '~/Actions';
import { BlurBackgroundStatus, ICurrentPage } from '~/Actions/ActionAppType';
import {
    getBundlesAndCategoriesMap,
    prepareBundlesForState,
    prepareCategoryBundlesPricesInfo,
    prepareFiltersByBundles,
    updateBundleIsPurchased,
    updateBundlesPurchasedForState,
} from '~/utils/bundles';
import { getCurrenciesByCategoryName } from '~/utils/category';
import { getSearchResults } from '~/search/search';
import {
    BLUR_VIEW,
    CHANGE_CURRENCIES_DIAPASON_FILTER,
    CHANGE_CURRENT_PAGE,
    CHANGE_FILTER,
    CHANGE_FILTERS_POSITION,
    CHANGE_MULTIPLE_CURRENCY_DIAPASON,
    CHANGE_MULTIPLE_FILTER,
    CHANGE_VIEW_BACKGROUND,
    CHANGE_VISIBLE_POPUP,
    CHANGE_VISIBLE_PORT,
    DATA_INITIALIZE,
    DISABLE_CATEGORY,
    FINISH_VIDEO,
    HIDDEN_MENU_ITEM,
    SHOW_MENU_ITEM,
    HIDDEN_MOBILE_NAVIGATE,
    IS_FETCHING,
    ON_SEARCH,
    REMOVE_CLIENT_SOURCE,
    RESET_CATEGORY_FILTER,
    RESET_FILTER,
    RESET_SEARCH,
    RESET_SORT_CATEGORY,
    SELECT_GROUP_BUNDLE,
    SET_CURRENT_NOTIFICATION_NAME,
    SET_FILTERS_BY_QUERY_PARAMS,
    SET_IFRAME_POPUP_NAME,
    SET_MENU,
    SET_RANDOM_BUNDLE_ANIMATION,
    SET_SORT_METHOD,
    SET_VISIBLE_INFO_SCREEN,
    SET_VISIBLE_TOP_PANEL,
    SHOW_PARALLAX_ANIMATION_SHIP,
    START_VIDEO,
    TRIGGER_GUIDE_SHOW,
    UPDATE_ACTIVE_FILTER_PRESET,
    UPDATE_BUNDLE_PRICES_INFO,
    UPDATE_BUNDLES,
    UPDATE_CATEGORY_BY_FILTERS,
    UPDATE_QUANTITY,
    SET_TRUSTED,
    UPDATE_ANIMATION_STATUS_CATEGORY,
    FINISH_LOADING_RESOURCES,
    UPDATE_SOUND_STATUS,
    SET_MENU_VISIBILITY,
    SET_FULLSCREEN,
    UPDATE_BUNDLE_LIST,
    UPDATE_BUNDLES_PURCHASED_INFO,
    SET_BUNDLE_IS_PURCHASED,
    BLUR_BACKGROUND,
    UPDATE_CUSTOM_PAGE_SETTINGS,
    UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES,
    UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES,
    UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND,
    UPDATE_POPUP_SETTINGS,
    UPDATE_NESTED_POPUP_SETTINGS,
    TOGGLE_CATEGORY_TIMER,
    HIDE_ESC,
    IFRAME_SYNC_ENABLED,
    SET_EMOTION_FROM_BOX_SOURCE,
} from '~/Actions/ActionApp';

import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { DEV_MODE } from '~/Actions/ActionAccount';
import { IDevModeParams } from '~/Actions/ActionAccountType';
import { getInitialPopup } from '~/components/Popups/settings';
import { VIDEO_NAMES } from '~/components/VideoPlayer/videoMap';
import { CLIENT_BUTTON_COPYRIGHT_BY_ROUTE, FILTER_CURRENCY_NAME, GET_PARAM_CLIENT_SOURCE } from '~/const';
import {
    addFiltersToLocation,
    getFacetPresetsFilters,
    prepareFiltersPresetsFromState,
    getStateAfterChangedCurrencyDiapason,
    getStateFromSetFiltersByUrl,
    getStateAfterChangedPreset,
    getStateAfterResetFilter,
    getStateAfterChangedFilter,
    getStateAfterChangeMultipleFilters,
    getFilterPositionFromAppInit,
} from '~/utils/filters';
import { getSearchParam } from '~/utils/utils';
import { getLootboxesMap } from '~/components/BundleLimitWidget/settings';
import { getArmoryContentFromSettings, prepareArmoryBundles } from '~/utils/global_content';
import { getMenuForState } from '~/utils/menu';
import { FilterPosition } from '~/types/category';
import { armoryState } from '~/utils/settings';
import { isDisabledCoupon } from '~/utils/coupons';
import { CustomPageSettings } from '~/types/customPage';
import { PortDataType, PortType } from '~/components/Port/settings';

export type IBundleCategory = Record<string, number[]>;
export type IVehicleTypes = Record<string, IVehicleClass>;
export type INations = Record<string, INation>;
export type IComplexities = Record<string, IComplexity>;
export type IServiceIcons = Record<string, IServiceIcon>;
export type IInfoScreen = {
    isVisible: boolean;
    name?: string;
};
export type IGroupBundles = Record<string, number>;

export interface AppState {
    currentPage?: ICurrentPage;
    isFinishedRequest?: boolean;
    bundles?: IBundleList;
    categoryBundlesPricesInfo?: IBundlePricesInfo;
    defaultCategoryBundlesPricesInfo?: IBundlePricesInfo;
    featuring?: IFeature[];
    currencies?: ICurrencies[];
    bundleCategory?: IBundleCategory;
    port?: IPort;
    popups: IPopup[];
    popupActive: IPopup;
    vehicleTypes?: IVehicleTypes;
    nations?: INations;
    complexities?: IComplexities;
    serviceIcons?: IServiceIcons;
    filters?: IFilters;
    facetState?: IFilters;
    facetCurrenciesState?: IFacetCurrenciesState;
    filterPosition: FilterPosition;
    sortedBy?: ISortCategories;
    activePreset?: string;
    filtersDiapasonCurrency?: IFiltersDiapasonCurrencies;
    disabledCategories?: string[];
    isFetching: boolean;
    categoryCurrencies: string[];
    infoScreen: IInfoScreen;
    currentNotificationVisibleName?: string;
    randomBundleAnimation: {
        showAnimation: boolean;
    };
    viewClassName: string;
    viewBackground: string;
    isVisibleTopPanel: boolean;
    isVisibleMenu: boolean;
    isFullscreen: boolean;
    parallaxAnimationShip: number;
    searchResults: ISearchResultItem[];
    isStartedVideo: boolean;
    isVideoEffect?: boolean;
    fadeOutVideoInSeconds?: number;
    volume: number;
    guide?: {
        name: GUIDE_NAMES;
    };
    isBlurView: boolean;
    isBlurBackground: BlurBackgroundStatus;
    isHiddenMobileNavigate: boolean;
    devMode: IDevModeParams;
    groupBundles?: IGroupBundles;
    menu?: IMenuMap;
    clientSource: keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE;
    currentVideoName?: VIDEO_NAMES;
    currentVideoUrl?: string;
    onFinishVideoCallback?: () => void;
    categories: ICategories;
    iframePopupName?: string;
    iframeEscIsHidden: boolean;
    iframeSyncEnabled: boolean;
    isTrusted: boolean;
    categoriesAnimationStatuses: ICategoriesAnimationStatuses;
    soundStatus: SoundStatus;
    lootboxesBundlesMap?: ILootboxesBundlesMap;
    customPageSettings?: CustomPageSettings;
    popupSettings?: PopupSettings;
    emotionFromBoxSource?: string;
}

export const initialAppState: AppState = {
    featuring: [],
    port: {
        isVisible: false,
        dataType: PortDataType.DEFAULT,
        portType: PortType.DEFAULT,
    },
    popups: [],
    popupActive: null,
    disabledCategories: [],
    categoryCurrencies: [],
    isFetching: false,
    infoScreen: {
        isVisible: false,
    },
    randomBundleAnimation: {
        showAnimation: false,
    },
    filtersDiapasonCurrency: {},
    viewClassName: null,
    viewBackground: null,
    isVisibleTopPanel: true,
    isVisibleMenu: true,
    isFullscreen: false,
    parallaxAnimationShip: null,
    searchResults: null,
    isStartedVideo: false,
    isVideoEffect: false,
    devMode: {},
    isBlurView: false,
    isBlurBackground: 2,
    isHiddenMobileNavigate: false,
    categories: {},
    clientSource: null,
    activePreset: null,
    filterPosition: FilterPosition.TOP,
    iframePopupName: null,
    iframeEscIsHidden: false,
    iframeSyncEnabled: true,
    isTrusted: false,
    categoriesAnimationStatuses: {},
    soundStatus: {},
    lootboxesBundlesMap: null,
    volume: null,
    customPageSettings: {
        background: {
            naturalSizes: {
                width: 0,
                height: 0,
            },
            currentSizes: {
                width: window.document.documentElement.clientHeight * 1.78,
                height: window.document.documentElement.clientHeight,
            },
        },
        popupBackground: {
            url: '',
        },
    },
    popupSettings: {},
};

let nextPopupId = 1;

const ReducerApp = (state: AppState = initialAppState, action: Actions): AppState => {
    switch (action.type) {
        case CHANGE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: {
                    ...action.currentPage,
                },
                categoryCurrencies: action.currentPage.currencies ? action.currentPage.currencies : getCurrenciesByCategoryName(state.bundles, state.categories, action.currentPage.name),
                isBlurBackground: action.isBlurBackground,
            };

        case FINISH_LOADING_RESOURCES:
            return {
                ...state,
                isFinishedRequest: true,
            };

        case DATA_INITIALIZE:
            const armoryContent = getArmoryContentFromSettings(action.response.data, action.response.currencies);
            const preparedBundles = prepareArmoryBundles(action.response.data, armoryContent.categories);
            const preparedCoupons = armoryState.account?.usedCoupons
                ? armoryContent.coupons.map((coupon) => {
                      return { ...coupon, isDisabled: isDisabledCoupon(coupon, armoryState.account.usedCoupons) };
                  })
                : armoryContent.coupons;
            const bundles = prepareBundlesForState(preparedBundles, action.purchasedLimitedBundles, action.deniedBundlesByUniqueItems, preparedCoupons);
            prepareFiltersByBundles(bundles, armoryContent.categories);

            const nations =
                action.response.nations?.reduce((result: any, item: INation) => {
                    result[item.name] = item;
                    return result;
                }, {}) || [];

            const vehicleTypes =
                action.response.vehicleTypes?.reduce((result: any, item: IVehicleClass) => {
                    result[item.name] = item;
                    return result;
                }, {}) || [];

            const complexities =
                action.response.complexities?.reduce((result: any, item: IComplexity) => {
                    result[item.level] = item;
                    return result;
                }, {}) || [];

            const serviceIcons = action.response.serviceIcons;

            const bundlesCategory = getBundlesAndCategoriesMap(bundles, armoryContent.categories);
            const featuring = armoryContent.featuring as IFeature[];
            const initialPopup = getInitialPopup(nextPopupId++);

            return {
                ...state,
                featuring,
                categories: prepareFiltersPresetsFromState(armoryContent.categories, bundles, bundlesCategory),
                isFinishedRequest: false,
                bundles: bundles,
                categoryBundlesPricesInfo: prepareCategoryBundlesPricesInfo(bundles),
                defaultCategoryBundlesPricesInfo: prepareCategoryBundlesPricesInfo(bundles, true),
                vehicleTypes: vehicleTypes,
                nations: nations,
                complexities: complexities,
                bundleCategory: bundlesCategory,
                currencies: armoryContent.currencies as unknown as ICurrencies[],
                popups: initialPopup ? [initialPopup] : [],
                popupActive: initialPopup,
                clientSource: getSearchParam(GET_PARAM_CLIENT_SOURCE) as keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE,
                filterPosition: getFilterPositionFromAppInit(),
                lootboxesBundlesMap: getLootboxesMap(bundles),
                menu: getMenuForState(armoryContent.categories, bundles, featuring),
                serviceIcons: serviceIcons,
            };

        case UPDATE_BUNDLE_PRICES_INFO: {
            return {
                ...state,
                categoryBundlesPricesInfo: {
                    ...(state.categoryBundlesPricesInfo || {}),
                    [action.category]: prepareCategoryBundlesPricesInfo(action.bundles)[action.category],
                },
            };
        }

        case UPDATE_BUNDLES:
            const updateBundles = prepareBundlesForState(state.bundles, action.purchasedLimitedBundles, action.deniedBundlesByUniqueItems, action.coupons);
            prepareFiltersByBundles(updateBundles, state.categories);

            return {
                ...state,
                bundles: updateBundles,
            };

        case UPDATE_BUNDLES_PURCHASED_INFO:
            const updateBundlesPurchased = updateBundlesPurchasedForState(state.bundles, action.inventory);

            return {
                ...state,
                bundles: updateBundlesPurchased,
            };

        case SET_BUNDLE_IS_PURCHASED:
            const updatedBundles = updateBundleIsPurchased(state.bundles, action.bundleId);

            return {
                ...state,
                bundles: updatedBundles,
            };

        case CHANGE_VISIBLE_PORT:
            return {
                ...state,
                port: action.port,
            };

        case UPDATE_CATEGORY_BY_FILTERS: {
            const currenciesDiapason =
                action.filterName === FILTER_CURRENCY_NAME ? state.facetCurrenciesState?.[action.category] : getFacetPresetsFilters(Object.values(action.bundlesList))?.currenciesDiapason;

            return {
                ...state,
                bundleCategory: {
                    ...state.bundleCategory,
                    [action.category]: action.bundles,
                },
                facetCurrenciesState: {
                    ...(state.facetCurrenciesState || {}),
                    [action.category]: currenciesDiapason,
                },
            };
        }

        case CHANGE_VISIBLE_POPUP: {
            // Hide
            if (!action.isVisible) {
                let popups: IPopup[];

                if (action.name && action.name === 'all') {
                    // Hide all
                    popups = [];
                } else if (action.name) {
                    // Hide by name
                    popups = state.popups.filter((popup) => popup.name !== action.name);
                } else {
                    // Hide last
                    popups = state.popups.slice(0, state.popups.length - 1);
                }

                const prevPopup = popups.slice(-1)[0] || null;

                return {
                    ...state,
                    popups: popups,
                    popupActive: prevPopup,
                    isBlurView: !!prevPopup,
                };
            }

            // !! Popups can be open without name and data. It's for blured background. WTF...

            // Show
            const popup: IPopup = {
                id: nextPopupId++,
                name: action.name,
                data: action.data,
            };

            return {
                ...state,
                popups: [...state.popups, popup],
                popupActive: popup,
                isBlurView: action.isVisible,
            };
        }

        case CHANGE_MULTIPLE_FILTER: {
            return {
                ...state,
                ...getStateAfterChangeMultipleFilters(state, action),
            };
        }

        case CHANGE_FILTER: {
            return {
                ...state,
                ...getStateAfterChangedFilter(state, action),
            };
        }

        case RESET_FILTER:
            return {
                ...state,
                filters: {},
                filtersDiapasonCurrency: {},
                bundleCategory: getBundlesAndCategoriesMap(state.bundles, state.categories),
                facetState: {},
            };

        case RESET_CATEGORY_FILTER: {
            return {
                ...state,
                ...getStateAfterResetFilter(state, action.category as ICategoryList, action.withoutUpdateHistory),
            };
        }

        case SET_FILTERS_BY_QUERY_PARAMS: {
            return {
                ...state,
                ...getStateFromSetFiltersByUrl(state, action.category, action),
            };
        }

        case CHANGE_MULTIPLE_CURRENCY_DIAPASON: {
            return {
                ...state,
                filtersDiapasonCurrency: {
                    ...state.filtersDiapasonCurrency,
                    [action.category]: {
                        ...(state.filtersDiapasonCurrency[action.category] || {}),
                        ...action.values,
                    },
                },
            };
        }

        case CHANGE_CURRENCIES_DIAPASON_FILTER: {
            const diapason = {
                ...state.filtersDiapasonCurrency,
                [action.category]: {
                    ...(state.filtersDiapasonCurrency[action.category] || {}),
                    [action.currency]: {
                        min: action.min,
                        max: action.max,
                    },
                },
            };

            addFiltersToLocation(action.category, { [action.currency]: [action.min, action.max] });

            return {
                ...state,
                ...getStateAfterChangedCurrencyDiapason(state, diapason[action.category]),
                filtersDiapasonCurrency: diapason,
            };
        }

        case UPDATE_ACTIVE_FILTER_PRESET:
            return {
                ...state,
                ...getStateAfterChangedPreset(state, action.preset, action.withoutUpdateHistory),
            };

        case UPDATE_QUANTITY:
            const quantityData = action.quantity
                ? {
                      quantity: action.quantity,
                      ...action.quantityData,
                  }
                : null;

            return {
                ...state,
                bundles: {
                    ...state.bundles,
                    [action.bundleId]: {
                        ...state.bundles[action.bundleId],
                        quantityData,
                    },
                },
            };

        case DISABLE_CATEGORY:
            return {
                ...state,
                disabledCategories: [...(state.disabledCategories || []), action.category],
            };

        case IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case SET_VISIBLE_INFO_SCREEN:
            return {
                ...state,
                infoScreen: {
                    isVisible: action.isVisible,
                    name: action.name,
                },
            };

        case SET_RANDOM_BUNDLE_ANIMATION:
            return {
                ...state,
                randomBundleAnimation: {
                    showAnimation: action.showAnimation,
                },
            };

        case CHANGE_VIEW_BACKGROUND:
            return {
                ...state,
                viewClassName: action.className,
                viewBackground: action.background,
            };

        case SET_VISIBLE_TOP_PANEL:
            return {
                ...state,
                isVisibleTopPanel: action.isVisible,
            };

        case SHOW_PARALLAX_ANIMATION_SHIP:
            return {
                ...state,
                parallaxAnimationShip: action.bundleId,
            };

        case ON_SEARCH:
            return {
                ...state,
                searchResults: getSearchResults(action.value, state.bundles, state.categories),
            };

        case RESET_SEARCH:
            return {
                ...state,
                searchResults: null,
            };

        case START_VIDEO:
            return {
                ...state,
                isStartedVideo: true,
                isVideoEffect: action.isVideoEffect,
                currentVideoName: action.name as VIDEO_NAMES,
                currentVideoUrl: action.url,
                onFinishVideoCallback: action.onFinish,
                fadeOutVideoInSeconds: action.fadeOutVideoInSeconds,
                volume: action.volume,
            };

        case FINISH_VIDEO:
            return {
                ...state,
                isStartedVideo: false,
                isVideoEffect: null,
                currentVideoName: null,
                currentVideoUrl: null,
                fadeOutVideoInSeconds: null,
                onFinishVideoCallback: null,
            };

        case TRIGGER_GUIDE_SHOW:
            return {
                ...state,
                guide: {
                    name: action.name,
                },
            };

        case BLUR_VIEW:
            return {
                ...state,
                isBlurView: action.isBlur,
            };

        case BLUR_BACKGROUND:
            return {
                ...state,
                isBlurBackground: action.isBlurBackground,
            };

        case HIDDEN_MOBILE_NAVIGATE:
            return {
                ...state,
                isHiddenMobileNavigate: action.isHidden,
            };

        case DEV_MODE:
            return {
                ...state,
                devMode: {
                    ...state.devMode,
                    ...action.params,
                },
            };

        case SELECT_GROUP_BUNDLE:
            return {
                ...state,
                groupBundles: {
                    ...(state.groupBundles || {}),
                    [action.group]: action.id,
                },
            };

        case SET_MENU:
            return {
                ...state,
                menu: action.menu,
            };

        case HIDDEN_MENU_ITEM:
            return {
                ...state,
                menu: {
                    ...state.menu,
                    [action.menuItemKey]: {
                        ...state.menu[action.menuItemKey],
                        isHidden: true,
                    },
                },
            };

        case SHOW_MENU_ITEM:
            return {
                ...state,
                menu: {
                    ...state.menu,
                    [action.menuItemKey]: {
                        ...state.menu[action.menuItemKey],
                        isHidden: false,
                    },
                },
            };

        case SET_CURRENT_NOTIFICATION_NAME:
            return {
                ...state,
                currentNotificationVisibleName: action.name,
            };

        case SET_SORT_METHOD:
            return {
                ...state,
                sortedBy: {
                    ...(state.sortedBy || {}),
                    [action.category]: {
                        name: action.name,
                        method: action.method,
                    },
                },
            };

        case RESET_SORT_CATEGORY:
            return {
                ...state,
                sortedBy: {
                    ...(state.sortedBy || {}),
                    [action.category]: null,
                },
            };

        case CHANGE_FILTERS_POSITION:
            return {
                ...state,
                filterPosition: action.position,
            };

        case REMOVE_CLIENT_SOURCE:
            return {
                ...state,
                clientSource: null,
            };

        case SET_IFRAME_POPUP_NAME:
            return {
                ...state,
                iframePopupName: action.name,
            };

        case HIDE_ESC:
            return {
                ...state,
                iframeEscIsHidden: action.hide,
            };

        case IFRAME_SYNC_ENABLED:
            return {
                ...state,
                iframeSyncEnabled: action.enabled,
            };

        case SET_TRUSTED:
            return {
                ...state,
                isTrusted: true,
            };

        case UPDATE_ANIMATION_STATUS_CATEGORY:
            return {
                ...state,
                categoriesAnimationStatuses: {
                    ...(state.categoriesAnimationStatuses || {}),
                    [action.categoryName]: action.status,
                },
            };

        case UPDATE_SOUND_STATUS:
            return {
                ...state,
                soundStatus: {
                    ...(state.soundStatus || {}),
                    [action.soundKey]: action.status,
                },
            };

        case SET_MENU_VISIBILITY:
            return {
                ...state,
                isVisibleMenu: action.isVisible,
            };

        case SET_FULLSCREEN:
            return {
                ...state,
                isVisibleMenu: !action.isVisible,
                isVisibleTopPanel: !action.isVisible,
                isFullscreen: action.isVisible,
            };

        case UPDATE_BUNDLE_LIST:
            return {
                ...state,
                bundles: action.bundles,
            };

        case UPDATE_CUSTOM_PAGE_SETTINGS: {
            return {
                ...state,
                customPageSettings: action.customPageSettings,
            };
        }

        case UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES: {
            return {
                ...state,
                customPageSettings: {
                    ...(state.customPageSettings || {}),
                    background: {
                        ...(state.customPageSettings?.background || {}),
                        currentSizes: {
                            ...action.sizes,
                        },
                    },
                },
            };
        }

        case UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES: {
            return {
                ...state,
                customPageSettings: {
                    ...(state.customPageSettings || {}),
                    background: {
                        ...(state.customPageSettings?.background || {}),
                        naturalSizes: {
                            ...action.sizes,
                        },
                    },
                },
            };
        }

        case UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND: {
            return {
                ...state,
                customPageSettings: {
                    ...(state.customPageSettings || {}),
                    popupBackground: {
                        url: action.url,
                    },
                },
            };
        }

        case UPDATE_POPUP_SETTINGS: {
            return {
                ...state,
                popupSettings: {
                    ...(state.popupSettings || {}),
                    ...action.popupSettings,
                },
            };
        }

        case UPDATE_NESTED_POPUP_SETTINGS: {
            return {
                ...state,
                popupSettings: {
                    ...(state.popupSettings || {}),
                    nestedPopup: {
                        ...(state.popupSettings.nestedPopup || {}),
                        ...action.nestedPopup,
                    },
                },
            };
        }

        case TOGGLE_CATEGORY_TIMER: {
            const newState = { ...state };

            if (state.categories?.[action.categoryName]?.activityCountdown) {
                newState.categories[action.categoryName].activityCountdown.isEnabled = action.value;
            }

            return {
                ...newState,
            };
        }

        case SET_EMOTION_FROM_BOX_SOURCE: {
            return { ...state, emotionFromBoxSource: action.emotionFromBoxSource };
        }

        default:
            return { ...state };
    }
};

export default ReducerApp;
