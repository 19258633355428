import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { CURRENCY } from '~/const';
import { interpolate, t } from '~/utils/localization';
import { Currencies, PresentationStyles } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { DivTooltip } from '@wg/wows-react-uikit';

import styles from './MrpsProgressTooltip.scss';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';

interface MrpsProgressTooltipProps {
    resourceInUse: MrpsResourcesSpent;
    resourceSpent: MrpsResourcesSpent;
    progress: number;
    children?: React.ReactNode;
}

interface TooltipContentProps {
    resources: MrpsResourcesSpent;
    title: string;
    subtitle?: string;
}

interface StateSelector {
    rewardsAmount: number;
}

function stateSelector(state: State): StateSelector {
    const { events, currentEvent } = state.reducerMrps;
    const currentEventData = events.find((event) => event.name === currentEvent.eventName);

    return {
        rewardsAmount: currentEventData?.rewards?.length || 0,
    };
}

function TooltipContent(props: TooltipContentProps) {
    return (
        <div className={styles.resourcesContentWrapper}>
            <div className={styles.resourcesContentHeader}>{props.title}</div>
            {props.subtitle && (
                <>
                    <hr className={styles.divider} />
                    <div className={styles.resourcesContentInfo}>{props.subtitle}</div>
                </>
            )}
            <hr className={styles.divider} />
            <div className={styles.resourcesWrapper}>
                {Object.entries(props.resources).map(([name, amount]) => {
                    if (amount === 0) {
                        return null;
                    }

                    const isPremResource = name === Currencies.GOLD || name === CURRENCY.WARSHIPS_PREMIUM;

                    return (
                        <div
                            className={classNames(styles.resourceWrapper, {
                                [styles.resourceWrapper_gold]: isPremResource,
                            })}
                        >
                            <WoWSEntity
                                type={name}
                                presentation={{
                                    style: PresentationStyles.WIDGET,
                                    withoutAssetIcon: true,
                                }}
                            />
                            <WoWSEntity type={name} amount={amount} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function composeObjects<T>(...objects: Array<Record<string, number>>) {
    const keys = new Set(...objects.map((obj) => Object.keys(obj)));

    const result: Record<string, number> = {};

    keys.forEach((key) => {
        const value = objects.reduce((result, obj) => {
            return result + (obj[key as keyof typeof obj] || 0);
        }, 0);
        result[key] = value;
    });

    return result as T;
}

export const MrpsProgressTooltip = memo(({ resourceInUse = {}, resourceSpent = {}, progress, children }: MrpsProgressTooltipProps) => {
    const state = useSelector<State, StateSelector>(stateSelector, equal);

    const hasResourcesInUse = useMemo(() => {
        return Object.values(resourceInUse).some((value) => value > 0);
    }, [resourceInUse]);
    const hasResourcesSpent = useMemo(() => {
        return Object.values(resourceSpent).some((value) => value > 0);
    }, [resourceSpent]);

    const tooltipBody = progress ? (
        <div className={styles.wrapper}>
            {hasResourcesInUse ? (
                <>
                    <TooltipContent resources={resourceInUse} title={t('Вы добавляете')} />
                    <hr className={styles.divider} />
                </>
            ) : null}
            {hasResourcesSpent ? <TooltipContent title={t('Вы обменяли')} resources={resourceSpent} /> : null}
        </div>
    ) : null;

    return (
        <DivTooltip className={styles.tooltipWrapper} tooltipBody={tooltipBody}>
            {children || null}
        </DivTooltip>
    );
});
