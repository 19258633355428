import * as React from 'react';
import styles from './BlackFriday.scss';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { useSelector } from 'react-redux';
import { isMobileOrTabletWindow } from '~/utils/utils';

interface IBlackFridayAnimation {
    categoryName: ICategoryList;
}

interface IStateSelector {
    categories: ICategories;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
    };
};

const BlackFridayAnimation = ({ categoryName }: IBlackFridayAnimation) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const category = state.categories[categoryName];

    if (!category || isMobileOrTabletWindow) {
        return null;
    }

    return (
        <React.Fragment>
            <div className={styles.wrapper} />
            <div
                className={styles.background}
                style={{
                    backgroundImage: `url(${category.background})`,
                }}
            />
        </React.Fragment>
    );
};

export default BlackFridayAnimation;
