import { Actions } from '~/Actions';
import { UPDATE_FILTER, CHOOSE_ITEM_FOR, CLEAR_STATE, SELECT_TARGET_ITEM, SELECT_SOURCE_ITEM, SET_TRADEIN_LISTS, CLEAR_FILTERS, SET_TS } from '~/Actions/ActionTradeIn';
import { FILTERS_TYPE } from '~/components/Filters/Filters';
import { FILTER_SHIP_TIER_NAME } from '~/const';
import { ChooseItemFor, ITradeItem, ITradeLists } from '~/types/tradein';
import { flat } from '~/utils/utils';

export interface TradeInState {
    lists: ITradeLists;
    selectedSourceItem: Nullable<ITradeItem>;
    selectedTargetItem: Nullable<ITradeItem>;
    choosenItemFor: ChooseItemFor;
    activeFilters: FILTERS_TYPE;
    ts: number;
}

export const initialState: TradeInState = {
    lists: {},
    selectedTargetItem: null,
    selectedSourceItem: null,
    choosenItemFor: ChooseItemFor.TRADE,
    activeFilters: {},
    ts: null,
};

const ReducerTradeIn = (state: TradeInState = initialState, action: Actions) => {
    switch (action.type) {
        case SELECT_TARGET_ITEM:
            return {
                ...state,
                selectedTargetItem: action.item,
            };

        case SELECT_SOURCE_ITEM:
            return {
                ...state,
                selectedSourceItem: action.item,
            };

        case SET_TS:
            return {
                ...state,
                ts: action.ts,
            };

        case SET_TRADEIN_LISTS:
            return {
                ...state,
                lists: action.lists,
            };

        case CHOOSE_ITEM_FOR:
            return {
                ...state,
                choosenItemFor: action.chooseItemFor,
                activeFilters: {},
            };

        case CLEAR_STATE:
            return {
                ...state,
                choosenItemFor: ChooseItemFor.TRADE,
                selectedSourceItem: null,
                selectedTargetItem: null,
                activeFilters: {},
            };

        case UPDATE_FILTER:
            let activeFilters: FILTERS_TYPE = Object.assign({}, state.activeFilters);
            if (!activeFilters[action.filterName]) {
                activeFilters[action.filterName] = [];
            }
            const prepareFiltersState = (value: any) => {
                const index = activeFilters[action.filterName].indexOf(value);
                if (index > -1) {
                    activeFilters[action.filterName].splice(index, 1);
                    if (!flat(Object.values(activeFilters)).length) {
                        activeFilters = {};
                    }
                } else {
                    activeFilters[action.filterName].push(value);
                }
            };
            if (Array.isArray(action.value)) {
                action.value.forEach((val) => prepareFiltersState(val));
            } else {
                prepareFiltersState(action.value);
            }

            return {
                ...state,
                activeFilters: activeFilters,
            };

        case CLEAR_FILTERS:
            return {
                ...state,
                activeFilters: {},
            };

        default:
            return { ...state };
    }
};

export default ReducerTradeIn;
