import * as React from 'react';
import styles from './Contains.scss';
import classNames from 'classnames';
import { interpolate, t } from '~/utils/localization';
import { isInGameBrowser } from '~/utils/utils';
import CrewVoiceIconButton from '~/components/CrewVoiceIconButton/CrewVoiceIconButton';
import { ManagerData } from '~/core/ManagerData';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { playButtonClickSound } from '~/api/WoWsClient';

export interface IContainsItem {
    id?: string;
    type?: string;
    content: string | React.ReactChild;

    isBonus?: boolean;
    isExistsInInventory?: boolean;
    isPortPreviewEnabled?: boolean;

    portPreviewOnClick?: () => void;
    onClick?: (event?: React.MouseEvent, item?: IContainsItem) => void;

    classNames?: {
        item?: string;
        portPreviewIcon?: string;
        span?: string;
        title?: string;
    };
}

interface IContains {
    items: IContainsItem[];
    limit?: number;
    isLarge?: boolean;
    title?: string;
    titleClassName?: string;
    hiddenVoButton?: boolean;
    wrapperClassName?: string;
}

const Contains = (props: IContains) => {
    const items = props.limit ? [...props.items].splice(0, props.limit) : props.items;

    return (
        <div
            className={classNames(styles.wrapper, props.wrapperClassName, {
                [styles.wrapper_big]: props.isLarge,
            })}
        >
            <ul>
                <li className={styles.item}>
                    <span className={props.titleClassName}>{props.title || t('Состав')}</span>
                </li>
                {items.map((item, index) => {
                    const hasSamleVo = ManagerData.getCrew(+item.id)?.hasSampleVo;

                    return (
                        <li
                            className={classNames(styles.item, item.classNames?.item, {
                                [styles.hiddenBullet]: isInGameBrowser && (item.isPortPreviewEnabled || hasSamleVo),
                            })}
                            key={`${item.type}_${item.id}_${index}`}
                            data-id={item.id}
                        >
                            {!!item.isPortPreviewEnabled && isInGameBrowser && (
                                <DivTooltip
                                    tooltipBody={<DefaultTooltip text={t('Просмотреть в порту')} />}
                                    style={{ cursor: 'pointer' }}
                                    className={classNames(styles.viewIcon, item.classNames?.portPreviewIcon)}
                                    onClick={() => {
                                        playButtonClickSound();
                                        item.portPreviewOnClick?.();
                                    }}
                                />
                            )}
                            {!props.hiddenVoButton && hasSamleVo && isInGameBrowser && <CrewVoiceIconButton crewId={+item.id} className={styles.viewCrewVoIcon} />}
                            <div className={styles.itemContainer}>
                                {item.isBonus && <div className={styles.bonus}>{t('Бонус')}</div>}
                                <span
                                    className={classNames(item.classNames?.span, {
                                        [styles.textWithBonus]: item.isBonus,
                                    })}
                                    onClick={(event) => {
                                        item.onClick?.(event, item);
                                    }}
                                >
                                    {item.content}
                                </span>
                            </div>
                        </li>
                    );
                })}
                {props.items.length - props.limit > 0 && (
                    <li className={styles.lastItemContent}>
                        <div className={styles.lastItemContentText}>
                            {interpolate(t('и ещё {count}'), {
                                count: props.items.length - props.limit,
                            })}
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Contains;
