import styles from './PromoTimer.scss';
import { interpolate, t } from '~/utils/localization';
import * as React from 'react';
import { useMemo } from 'react';

interface PromoTimerTooltipProps {
    bundle: IBundle;
}

export default function PromoTimerTooltip(props: PromoTimerTooltipProps) {
    const promoTimer = props.bundle.promoTimer;
    const localDateTime = useMemo(() => promoTimer?.timerActiveTill && new Date(promoTimer.timerActiveTill).toLocaleString(), [promoTimer?.timerActiveTill]);
    if (!promoTimer?.timerActiveTill) return null;
    return <span className={styles.tooltip}>{interpolate(promoTimer.timerTooltipDescription || t('Покупка станет доступна {timerActiveTill}'), { timerActiveTill: localDateTime })}</span>;
}
