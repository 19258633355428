import store from '~/Store';
import { CATEGORIES } from '~/const';
import { TransactionStatus } from '~/processors/TreasureProcessor';
import Account from '~/account/Account';
import { ItemTypes } from '@wg/wows-entities/const';

export function isTreasureCategory(categoryName: string): boolean {
    return store.getState().ReducerApp.categories[categoryName].type === CATEGORIES.TREASURE;
}

export function isTreasureCategoryEnabled(categoryName: TreasureMasterBox['name']): boolean {
    const { ReducerApp, ReducerTreasure } = store.getState();

    if (!ReducerTreasure.boxes?.length) {
        return false;
    }

    const categoryData = ReducerApp.categories[categoryName];
    if (!categoryData) {
        return false;
    }

    return !!ReducerTreasure.boxes.find((box) => box.name === categoryName);
}

export function concatRewards(masterBox: TreasureMasterBox, withFillers = false): TreasureReward[] {
    const rewards: TreasureReward[] = [];
    masterBox.chains.forEach((chain) => {
        rewards.push(...chain.rewards);
        withFillers && chain.filler && rewards.push(chain.filler);
    });
    rewards.sort((a, b) => a?.weight - b?.weight);
    return rewards;
}

export function isTransactionInProcess(transaction: TreasureTransaction) {
    return transaction.status === TransactionStatus.PAYMENT || transaction.status === TransactionStatus.PENDING;
}

export function sortRewardsForCarousel(rewards: TreasureReward[], inventory?: InventoryState): TreasureReward[] {
    if (!inventory) return rewards;
    const sortedRewards: TreasureReward[] = [...rewards];
    rewards.forEach((rewardData) => {
        const reward = rewardData.entitlements[0];
        const [isExistsItemInInventory] = Account.getInventoryInfoByType(reward.type, reward.identifier, undefined, undefined, undefined, inventory);
        if (isExistsItemInInventory && reward.type === ItemTypes.VEHICLES) {
            sortedRewards.push(...sortedRewards.splice(sortedRewards.indexOf(rewardData), 1));
        }
    });
    return sortedRewards;
}
