import { arrayToObjectByKey, isEmptyObject } from '~/utils/utils';
import { interpolate } from '~/utils/localization';
import store from '~/Store';
import VortexDataManager from '~/core/VortexDataStorage';
import { SectionNames } from '~/core/ManagerData';

export interface ICurrency {
    title: string;
    name: string;
    description: string;
    hiddenIfAbsent: boolean;
    showDescriptionInPriceTooltip: boolean;
    isEvent: boolean;
    isDefault: boolean;
    tooltipDescription?: string;
    tooltipGetDescription?: string;
    tooltipStatusInfo?: string;
    icons: {
        default: string;
        small: string;
        large: string;
    };
}

export const prepareCurrenciesForState = (vortexCurrencies: ICurrency[]) => {
    if (isEmptyObject(vortexCurrencies)) {
        return [];
    }

    const currencies: ICurrencies[] = window.metashop.state.content.currencies;
    const map: { [key: string]: ICurrency } = arrayToObjectByKey(vortexCurrencies, 'name');

    const final: ICurrency[] = [];

    currencies.forEach((currency) => {
        const fromVortex = map[currency.name] || null;

        const description =
            currency.description &&
            interpolate(
                currency.description,
                {
                    tag_open: `<b>`,
                    tag_close: `</b>`,
                },
                true,
                false,
            );

        final.push({
            title: currency.title || fromVortex?.title,
            description: description,
            name: currency.name,
            icons: {
                small: currency.icon || fromVortex?.icons?.small,
                default: currency.icon || fromVortex?.icons?.default,
                large: fromVortex?.icons?.large,
            },
            hiddenIfAbsent: currency.hiddenIfAbsent,
            showDescriptionInPriceTooltip: currency.showDescriptionInPriceTooltip,
            isEvent: currency.isEvent,
            isDefault: currency.isDefault,
            tooltipDescription: fromVortex?.tooltipDescription,
            tooltipStatusInfo: fromVortex?.tooltipStatusInfo,
            tooltipGetDescription: fromVortex?.tooltipGetDescription,
        });
    });

    return final;
};

export const getCurrency = (name: string): ICurrency => {
    const app = store.getState().ReducerApp;
    const currencies = arrayToObjectByKey(app.currencies || VortexDataManager.getDataBySection(SectionNames.currency) || [], 'name');

    return (
        currencies[name] || {
            title: null,
            description: null,
            name: null,
            icons: {
                small: null,
                default: null,
            },
        }
    );
};

export const isCurrency = (currencyName: string) => {
    return !!getCurrency(currencyName)?.name;
};

export const hasCurrencyInCache = (currencyName: string) => {
    const currencies = arrayToObjectByKey(VortexDataManager.getDataBySection(SectionNames.currency) || [], 'name');
    return !!currencies[currencyName];
};
