import * as React from 'react';
import styles from './WonPopup.scss';
import { useDispatch } from 'react-redux';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { OrangeButton } from '~/components/Button/Buttons';
import AuctionLotVisitCard from '~/components/AuctionLotVisitCard/AuctionLotVisitCard';
import classNames from 'classnames';
import AuctionProcessor from '~/processors/AuctionProcessor';
import { isMobileOrTabletWindow } from '~/utils/utils';
import AuctionLotHeader from '~/components/Auction/AuctionHeader';
import AuctionCurrentBid from '~/components/AuctionCurrentBid/AuctionCurrentBid';
import { FadeInDiv, ZoomInDiv } from '~/components/Transitions';
import { AUCTION_ACTION_POST_PREFIX, getActionIdWithPostPrefixByType, getBidWithShipFromBids } from '~/utils/auction';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

const WonPopup = () => {
    const { popup } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    let wonBids = popup.data?.bids as IBids[];

    const bidWithShip = getBidWithShipFromBids(wonBids);
    const [isVisibleShinyShipTemplate, setVisibleShinyShipTemplate] = React.useState<boolean>(!isMobileOrTabletWindow && !!bidWithShip?.lot);
    if (bidWithShip && !isMobileOrTabletWindow) {
        wonBids = wonBids.filter((bid) => bid.lot.id !== bidWithShip.lot.id);
    }

    const markViewed = () => {
        const bids = (popup.data?.bids || []) as IBids[];
        const actionIds = bids.map((bid) => getActionIdWithPostPrefixByType(bid.lot.id, AUCTION_ACTION_POST_PREFIX.WON_POPUP));
        if (bids.length) {
            AuctionProcessor.completeActions(bids[0].lot.auctionId, actionIds);
        }
    };

    const onClose = () => {
        markViewed();
        if (isVisibleShinyShipTemplate) {
            setVisibleShinyShipTemplate(false);
            if (!wonBids.length) {
                dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
            }
            return;
        }
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
    };

    const classesPopup = classNames(styles.popup, {
        [styles.four]: wonBids.length > 3,
        [styles.isShiny]: isVisibleShinyShipTemplate,
    });

    const classesBodyPopup = classNames(styles.body, {
        [styles.maxHeight]: wonBids.length > 1,
    });

    const classesContent = classNames(styles.content, {
        [styles.four]: wonBids.length >= 3,
    });

    const classesItem = classNames({
        [styles.itemBigSize]: wonBids.length < 3,
        [styles.itemShiny]: isVisibleShinyShipTemplate,
    });

    return (
        <Popup
            onClose={onClose}
            withoutZoomIn={isVisibleShinyShipTemplate}
            renderEscButton={!isVisibleShinyShipTemplate}
            className={classesPopup}
            popupClassName={classNames(styles.popupWrapper, {
                [styles.popupShinyWrapper]: isVisibleShinyShipTemplate,
            })}
            style={{
                backgroundImage: isVisibleShinyShipTemplate ? `url(${bidWithShip.lot.previewImageUrl4K})` : 'none',
            }}
        >
            <PopupHeader title={t('Поздравляем!')} />
            <PopupBody className={classesBodyPopup}>
                <FadeInDiv className={styles.subTitle}>
                    {t('Аукцион завершен.')}&nbsp;{t('Ваша ставка выиграла')}
                </FadeInDiv>
                <div className={styles.contentFooter}>
                    {isVisibleShinyShipTemplate && (
                        <FadeInDiv className={styles.shipInfo}>
                            <div className={styles.shipName}>
                                <AuctionLotHeader {...bidWithShip.lot} />
                            </div>
                            <div className={styles.bidWrapper}>
                                <AuctionCurrentBid bid={bidWithShip} className={styles.bid} />
                            </div>
                        </FadeInDiv>
                    )}
                    <div className={classesContent}>
                        {!isVisibleShinyShipTemplate &&
                            wonBids.map((bid) => {
                                return (
                                    <AuctionLotVisitCard
                                        key={bid.lot.id}
                                        lot={bid.lot}
                                        className={classesItem}
                                        imageClassName={styles.cardImage}
                                        titleClassName={styles.cardTitle}
                                        ComponentWrapper={ZoomInDiv}
                                        bid={bid}
                                    />
                                );
                            })}
                    </div>
                </div>
            </PopupBody>
            <PopupFooter className={styles.footer}>
                <FadeInDiv className={styles.footerContent}>
                    <div className={styles.footerTitle}>{t('До встречи на аукционе!')}</div>
                    <OrangeButton label={t('Закрыть')} onClick={onClose} />
                </FadeInDiv>
            </PopupFooter>
        </Popup>
    );
};

export default React.memo(WonPopup);
