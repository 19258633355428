import * as React from 'react';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { useSelector } from 'react-redux';
import ContainerPityTimer from '~/components/ContainerPityTimer/ContainerPityTimer';

interface IInventoryCounterContainer {
    identifier: number;
    autoDescriptionSettings: IAutoDescriptionSettings;
    title: string;
    className?: string;
    fallback?: React.ReactNode;
}

interface IStateSelector {
    lootboxes: ILootboxes;
    accountId: number;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        lootboxes: state.ReducerLootbox.lootboxes,
        accountId: state.ReducerAccount.id,
    };
};

const PityTimerContainer = ({ identifier, autoDescriptionSettings, title, className, fallback = null }: IInventoryCounterContainer) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    if (!identifier) {
        return fallback;
    }

    const lootbox = state.lootboxes[identifier];
    if (!lootbox || (!lootbox.savePoint && !lootbox.savePointForValuableGroup?.savePoint)) {
        return fallback;
    }

    const isDroprateHidden = autoDescriptionSettings.lootboxSettings?.[lootbox.id]?.isDroprateHidden;
    if (isDroprateHidden) {
        return fallback;
    }

    return (
        <div className={className}>
            <ContainerPityTimer accountId={state.accountId} containerId={identifier} lootbox={lootbox} bundleName={title} />
        </div>
    );
};

export default PityTimerContainer;
