import * as React from 'react';
import styles from './Ship.scss';
import classNames from 'classnames';
import { getLevelFromRomanFormat } from '~/utils/utils';
import { ManagerData } from '~/core/ManagerData';
import { ShipComplexity } from '../ShipComplexity/ShipComplexity';
import { getShipFeatures } from '~/utils/getShipFeatures';

interface IShip {
    shipId: number;
    fallback: string;
    className?: string;
    titleClassName?: string;
    withoutNationIcon?: boolean;
    withoutTypeIcon?: boolean;
    isOnlyShipBundle?: boolean;
}

export const isPremiumOrSpecialVehicle = (vehicle: any): boolean => {
    return vehicle.isPremium || vehicle.isSpecial;
};

export const getShipClassIcon = (vehicle: any): string => {
    if (vehicle.isPremium) {
        return vehicle.type.icons.premium;
    } else if (vehicle.isSpecial) {
        return vehicle.type.icons.special;
    }

    return vehicle.type.icons.default;
};

export const isLegendaryShip = (tags: string[]): boolean => {
    return tags.includes('legendaryBattle');
};

const Ship = (props: IShip) => {
    const vehicle = ManagerData.getShip(props.shipId);
    const [vehicleFeatures, setVehicleFeatures] = React.useState<IFeatureTagsItem | undefined>();

    React.useLayoutEffect(() => {
        getShipFeatures(props.shipId).then((data) => setVehicleFeatures(data));
    }, []);

    if (!vehicle || !vehicle?.title) {
        return <div className={classNames(styles.container, props.className)}>{props.fallback}</div>;
    }

    const level = getLevelFromRomanFormat(vehicle.level);
    const classIcon = getShipClassIcon(vehicle);
    const nationIcon = vehicle.nation.icons.tiny || vehicle.nation.icons.small;
    const titleClassNames = classNames(
        styles.title,
        {
            [styles.gold]: isPremiumOrSpecialVehicle(vehicle),
        },
        props.titleClassName,
    );

    const _isLegendary = isLegendaryShip(vehicle?.tags || []);
    const shipTitle = vehicle?.titleShort || vehicle?.title;
    const title = _isLegendary ? shipTitle : `${level} ${shipTitle}`;

    return (
        <div className={classNames(styles.container, props.className)}>
            <div className={styles.shipTitle}>
                {!props.withoutNationIcon && (
                    <div
                        className={styles.nationIcon}
                        style={{
                            backgroundImage: `url(${nationIcon})`,
                        }}
                    />
                )}
                {!props.withoutTypeIcon && (
                    <div
                        className={styles.classIcon}
                        style={{
                            backgroundImage: `url(${classIcon})`,
                        }}
                    />
                )}
                {_isLegendary && <div className={styles.legendaryStarIcon} />}
                <div className={titleClassNames}>{title}</div>
            </div>
            {vehicleFeatures && !!props.isOnlyShipBundle ? <ShipComplexity complexity={vehicleFeatures.complexity} withoutTooltip /> : null}
        </div>
    );
};

export default Ship;
