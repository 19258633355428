import * as React from 'react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import confetti from 'canvas-confetti';
import styles from './Anniversary.scss';

const Anniversary = () => {
    const leftCanvasRef = React.useRef<HTMLCanvasElement>(null);
    const rightCanvasRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            return;
        }

        const end = Date.now() + 1000;
        const colors = ['#ffca52', '#ff7626', '#6bc2ff', '#4fbc81'];
        const leftConfetti = confetti.create(leftCanvasRef.current, { resize: true });
        const rightConfetti = confetti.create(rightCanvasRef.current, { resize: true });

        setTimeout(() => {
            (function frame() {
                leftConfetti({
                    particleCount: 4,
                    angle: 60,
                    spread: 55,
                    origin: { x: 0 },
                    colors: colors,
                    ticks: 350,
                    drift: 0.5,
                });

                rightConfetti({
                    particleCount: 4,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1 },
                    colors: colors,
                    ticks: 350,
                    drift: -0.5,
                });

                if (Date.now() < end) {
                    requestAnimationFrame(frame);
                }
            })();
        }, 250);

        return () => {
            leftConfetti.reset();
            rightConfetti.reset();
        };
    }, []);

    if (isMobileOrTabletWindow) {
        return null;
    }

    return (
        <React.Fragment>
            <canvas ref={leftCanvasRef} className={styles.canvas} />
            <canvas ref={rightCanvasRef} className={styles.canvas} />
        </React.Fragment>
    );
};

export default Anniversary;
