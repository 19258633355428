import * as React from 'react';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Account from '~/account/Account';
import useMatchMedia from '~/hooks/useMatchMedia';
import { SCREEN_WIDTH } from '~/const';
import { isCurrency } from '~/utils/currencies';
import { LoadAmountToReward } from '~/components/Bundle/LoadAmountToReward';
import { items as ITEMS_TYPES } from '@wg/wows-entities/const';

interface IBundleContains {
    bundle: IBundle;
    limit?: number;
    className?: string;
    hiddenVoButton?: boolean;
}

export const prepareItems = (bundle: IBundle, inventory: InventoryState): IContainsItem[] => {
    return bundle.entitlements.map((item, index) => {
        let content;

        const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item.type, item.identifier, item.customisation?.shipId, item.shipShardsLevel, item.shipShardsId);
        function getItemType(item: IBundleEntity): string {
            return item.type === 'ship_components' ? ITEMS_TYPES.SHIP_COMPONENTS : item.type.includes(ITEMS_TYPES.SHIP_MATERIALS) ? `ship_material_${item.shipShardsLevel}` : item.type;
        }
        const isShards = item.type.includes(ITEMS_TYPES.SHIP_COMPONENTS) || item.type.includes(ITEMS_TYPES.SHIP_MATERIALS);

        if (item.customTitle) {
            content = (
                <DivTooltip tooltipBody={<DefaultTooltip text={item.customDescription || item.customTitle} />}>
                    <div dangerouslySetInnerHTML={{ __html: item.customTitle }} />
                </DivTooltip>
            );
        } else {
            content = (
                <LoadAmountToReward
                    // @ts-ignore
                    bundle={item}
                    key={`item_${item.identifier || item.type}_${index}_${inventory ? 'loaded' : 'not_loaded'}_${inventoryCount}`}
                    type={getItemType(item)}
                    id={item.identifier}
                    amount={item.amount}
                    customisation={{
                        ...(item.customisation || {}),
                        inventoryCount: !item.isUnique && inventoryCount,
                        isExistsInInventory: item.isUnique && isExistsItemInInventory,
                        shipShardsId: item.shipShardsId,
                    }}
                    presentation={{
                        withText: false,
                        withTooltip: true,
                        renderWithoutFade: true,
                        iconPosition: 'left',
                        lineClamp: 1,
                        hiddenInlineShip: true,
                        hideTooltipAmount: isCurrency(item.type) || isShards,
                        isShards: isShards,
                    }}
                />
            );
        }
        return {
            id: (item.identifier || `${index}_${item.type}`)?.toString(),
            type: item.type as any,
            isBonus: item.isBonus,
            content,
        } as IContainsItem;
    });
};

interface IStateSelector {
    inventory: InventoryState;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        inventory: state.ReducerAccount.inventory,
    };
};

const ShortBundleContains = ({ bundle, limit, className, hiddenVoButton }: IBundleContains) => {
    const [totalLimit, setLimit] = React.useState(window.screen.availWidth <= SCREEN_WIDTH.w1770px ? 1 : limit);
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const items = prepareItems(bundle, state.inventory);

    useMatchMedia(`(max-width: ${SCREEN_WIDTH.w1770px}px)`, (match) => {
        setLimit(match.matches ? 1 : limit);
    });

    return (
        <div className={className}>
            <Contains items={items} limit={totalLimit} key={`bundle_${bundle.id}_${state.inventory ? 'loaded' : 'not_loaded'}`} hiddenVoButton={hiddenVoButton} />
        </div>
    );
};

export default ShortBundleContains;
