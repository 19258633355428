import store from '~/Store';
import Account from '~/account/Account';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import { isBundleDisabledByPromoTimer, isRandomBundleOpeningEnabled, isFreeBundle, isPeriodicBundle, isPeriodicBundlePurchaseDisabled } from '~/utils/bundles';
import { CATEGORIES } from '~/const';
import { BUNDLE_DECORATION } from '~/types/bundle';
import { FEATURING_DESTINATION } from '~/types/contents';
import { generateAllCategoryBundlesDeeply } from '~/utils/category';

export const hasWithoutGlowAnimationDecoration = (bundle: IBundle): boolean => {
    return bundle?.decoration?.includes(BUNDLE_DECORATION.WITHOUT_GLOW_ANIMATION);
};

export const isEnableShinyForBundle = (bundle: IBundle, fromCategory = false, ignoreTimer = false): boolean => {
    if (!bundle || !isFreeBundle(bundle) || (!ignoreTimer && isBundleDisabledByPromoTimer(bundle))) {
        return false;
    }

    if (hasWithoutGlowAnimationDecoration(bundle) && !fromCategory) {
        return false;
    }

    const accountState = store.getState().ReducerAccount;

    const isAlreadyPurchased = bundle.isPurchased;

    if (isAlreadyPurchased) {
        return false;
    }

    if (bundle.serialPurchase || (bundle.isRandom && bundle.serialPurchase)) {
        return !Account.isDisabledSerialBundle(accountState.purchasedLimitedBundles, bundle);
    }

    if (isPeriodicBundle(bundle)) {
        if (!accountState.id) {
            return true;
        }
        return !isPeriodicBundlePurchaseDisabled(bundle) || isRandomBundleOpeningEnabled(bundle);
    }

    return true;
};

export const isShinyCategory = (categoryName: ICategoryList, bundleSplice?: number) => {
    for (const bundle of generateAllCategoryBundlesDeeply(categoryName, bundleSplice)) {
        if (isEnableShinyForBundle(bundle, true)) return true;
    }
    return false;
};
