const fallback = (str: string): string => str;

export const gettext = window.gettext || fallback;
export const t = window.gettext || fallback;
export const ngettext = window.ngettext || fallback;
export const getFormat = window.get_format || null;

export const interpolate = function (fmt: string, obj: any, named = true, useBraces = true) {
    if (named) {
        let regex, sliceNum: number;

        if (useBraces) {
            regex = /\{\w+\}/g;
            sliceNum = 1;
        } else {
            regex = /%\(\w+\)s/g;
            sliceNum = 2;
        }

        return fmt.replace(regex, function (match) {
            return String(obj[match.slice(sliceNum, -sliceNum)]);
        });
    } else {
        const regex = useBraces ? /\{\}/g : /%s/g;

        return fmt.replace(regex, function () {
            return String(obj.shift());
        });
    }
};
