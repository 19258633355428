import * as React from 'react';
import styles from './FastGoldLink.scss';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import { playButtonGoldClickSound } from '~/api/WoWsClient';

interface IFastGoldLink {
    withIcon?: boolean;
    className?: string;
    onClick: (event?: React.MouseEvent) => void;
}

const FastGoldLink = ({ withIcon, onClick, className }: IFastGoldLink) => {
    return (
        <div
            className={classNames(
                styles.link,
                {
                    [styles.withIcon]: withIcon,
                },
                className,
            )}
            onClick={(event) => {
                playButtonGoldClickSound();
                onClick(event);
            }}
        >
            <span>{t('Купить дублоны')}</span>
        </div>
    );
};

export default FastGoldLink;
