import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import * as React from 'react';
import { interpolate, t } from '~/utils/localization';
import DefaultTooltip from '../Tooltip/DefaultTooltip';
import styles from './ProgressBar.scss';

interface IProgressBar {
    maxValue: number;
    step?: number;
    minValue?: number;
    valuableIndexes?: number[];
    currentValue: number;
    separateLimit?: number;
    selectedIndex?: number;
    itemTooltips?: React.ReactChild[];
    onClickHandler?: (index: number) => void;
    className?: {
        seperateItem?: string;
    };
}

const ProgressBar = ({ maxValue, minValue, currentValue, separateLimit, step, onClickHandler, valuableIndexes, itemTooltips, selectedIndex, className }: IProgressBar) => {
    const minimalValue = minValue || 1;
    const _step = step || 1;
    const countBars = Math.floor(maxValue / _step);

    const SeparateProgressBar = () => {
        return (
            <div className={styles.progressBar}>
                {new Array(countBars).fill(1).map((i, _index) => {
                    const index = _index + 1;
                    const tooltip = itemTooltips?.[_index];
                    return (
                        <DivTooltip
                            tooltipBody={tooltip}
                            className={classNames(
                                styles.bar,
                                {
                                    [styles.valuable]: valuableIndexes?.includes(index),
                                    [styles.active]: index <= currentValue,
                                    [styles.selected]: selectedIndex === _index,
                                },
                                className?.seperateItem,
                            )}
                            style={{ cursor: 'pointer' }}
                            key={`progress_bar_${index}`}
                            onClick={() => onClickHandler(_index)}
                        />
                    );
                })}
            </div>
        );
    };

    const DefaultProgressBar = () => {
        const currentWidth = Math.min(Math.floor((currentValue * 100) / countBars), 100);

        return (
            <DivTooltip
                className={styles.lineProgressBar}
                tooltipBody={
                    <DefaultTooltip
                        text={interpolate(t('Приобретено наборов {amount} из {total}'), {
                            amount: currentValue,
                            total: maxValue,
                        })}
                    />
                }
            >
                <div
                    className={classNames(styles.progressLine, {
                        [styles.hiddenPoint]: currentWidth === 100 || !currentWidth,
                    })}
                    style={{ width: `${currentWidth}%` }}
                />
            </DivTooltip>
        );
    };

    return (
        <div className={styles.wrapper}>
            {countBars > separateLimit ? DefaultProgressBar() : SeparateProgressBar()}
            <div className={styles.positions}>
                <div className={styles.startPosition}>{minimalValue}</div>
                <div className={styles.endPosition}>{maxValue}</div>
            </div>
        </div>
    );
};

export default ProgressBar;
