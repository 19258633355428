import * as React from 'react';
import styles from './LegalFooter.scss';
import { interpolate, t } from '~/utils/localization';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { openUrl, settings } from '~/utils/settings';
import { detectPlatform, PLATFORMS } from '@wg/wows-commerce';
import { TSV_ACCOUNT_STATUSES } from '@wg/wows-commerce/constants/web/tsvAccountStatuses';
import { PrepareTsv } from '@wg/wows-commerce/api/platform/web';

interface IStateSelector {
    managementUrl: string;
    tsv: PrepareTsv;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        managementUrl: state.ReducerWSMart.managementUrl,
        tsv: state.ReducerWSMart.tsv,
    };
};

const LegalFooter = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const managementUrl = state.managementUrl;
    const platform = detectPlatform();

    const openExternalLink = (event: React.MouseEvent) => {
        event.preventDefault();
        const target = event.target as HTMLLinkElement;
        if (target.tagName.toLowerCase() === 'a') {
            openUrl(target.href);
        }
    };

    const twoFactorOptions = {
        openLink: managementUrl ? `<a class="external-link" target="_blank" href="${managementUrl}">` : '',
        closeLink: managementUrl ? `</a>` : '',
    };

    let twoFactorChangeText = interpolate(t('Для обеспечения дополнительной безопасности платежа предлагаем вам {openLink}настроить защиту платежных операций{closeLink}.'), twoFactorOptions);
    if (state.tsv?.accountStatus === TSV_ACCOUNT_STATUSES.ENABLED) {
        twoFactorChangeText = interpolate(t('{openLink}Изменить защиту платежных операций{closeLink}.'), twoFactorOptions);
    }

    const legalText = interpolate(
        t(
            'Итоговая стоимость товара может незначительно отличаться в зависимости от способа оплаты. Чтобы увидеть итоговую стоимость, нажмите на кнопку выбранного способа оплаты. Более подробную информацию вы найдёте в {openLink}Юридических документах{closeLink}.',
        ),
        { openLink: `<a class="external-link" target="_blank" href="${settings.urls.lawDocumentsUrl}">`, closeLink: `</a>` },
    );

    const legalTextForCis = interpolate(
        t(
            'Цена указана с учётом НДС и налога с продаж, если они применяются. Итоговая цена может незначительно отличаться в зависимости от способа оплаты. Чтобы увидеть окончательную цену, нажмите на кнопку вашего платежного метода. Более подробная информация содержится в {openLink}юридических документах{closeLink}.',
        ),
        { openLink: `<a class="external-link" target="_blank" href="${settings.urls.lawDocumentsUrl}">`, closeLink: `</a>` },
    );

    return (
        <div className={styles.legalFooter}>
            {platform === PLATFORMS.WEB ? (
                <>
                    <div className={styles.twoFactorChange} onClick={openExternalLink} dangerouslySetInnerHTML={{ __html: twoFactorChangeText }} />
                    <div className={styles.legalText} onClick={openExternalLink} dangerouslySetInnerHTML={{ __html: settings.realm === 'ru' ? legalTextForCis : legalText }} />
                </>
            ) : (
                <div className={styles.legalText}>{t('The final cost may vary depending on the rules of the platform. Please consult platform terms and conditions for more information.')}</div>
            )}
        </div>
    );
};

export default LegalFooter;
