import * as Redux from 'redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from '~/Reducers';
import { settings } from '~/utils/settings';

const middleware: Redux.Middleware[] = [thunk];

const PRODUCTION_MODE = 'production';

if (process.env.NODE_ENV !== PRODUCTION_MODE || settings.debug) {
    middleware.push(createLogger());
}

const store = createStore(reducer, applyMiddleware(...middleware));

if (process.env.NODE_ENV !== PRODUCTION_MODE) {
    window.store = store;
}

export default store;
