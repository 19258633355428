import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import MrpsReward from '../MrpsReward/MrpsReward';
import { ItemsContainsTooltip } from '~/components/Tooltip/Tooltips';

import styles from './MrpsProgressBarItem.scss';

interface MrpsProgressbarItemProps {
    className?: string;
    reward: MrpsLoadedReward;
    isReceived: boolean;
    isAvailable: boolean;
    width: number;
}

export default function MrpsProgressBarItem(props: MrpsProgressbarItemProps) {
    const { reward, isAvailable, isReceived, width } = props;

    const isShip = reward.entitlements.some((entitlement) => entitlement.type === 'ship');

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_ship]: isShip,
            })}
            style={{ width: `${width}%` }}
        >
            <div className={styles.rewardWrapper}>
                <div
                    className={classNames(styles.rewardContent, {
                        [styles.rewardContent_ship]: isShip,
                    })}
                >
                    <div
                        className={classNames(styles.rewardEntityWrapper, {
                            [styles.rewardEntityWrapper_ship]: isShip,
                        })}
                    >
                        <DivTooltip
                            tooltipBody={
                                <ItemsContainsTooltip
                                    bundle={
                                        {
                                            entitlements: reward.entitlements as unknown as IBundleEntity[],
                                        } as IBundle
                                    }
                                />
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            <MrpsReward isReceived={isReceived} isAvailable={isAvailable} isBarItem={true} reward={reward} />
                        </DivTooltip>
                    </div>
                    <div
                        className={classNames(styles.rewardLine, {
                            [styles.rewardLine_available]: isAvailable,
                            [styles.rewardLine_received]: isReceived,
                        })}
                    />
                    {!isReceived && reward.requiredProgressPoints !== 100 && (
                        <div
                            className={classNames(styles.rewardRequiredProgress, {
                                [styles.rewardRequiredProgress_available]: isAvailable,
                            })}
                        >
                            {reward.requiredProgressPoints}%
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
