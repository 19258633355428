import { IAddLootboxToStore } from '~/Actions/ActionLootboxType';
import { Dispatch } from 'react';
import { getLootboxApiUrl, prepareLootbox } from '~/Layouts/Lootboxes/utils';

export const ADD_LOOTBOX_TO_STORE = 'ADD_LOOTBOX_TO_STORE';
export const addLootboxToStore = (lootbox: ILootboxStateItem): IAddLootboxToStore => {
    return {
        type: ADD_LOOTBOX_TO_STORE,
        lootbox,
    };
};

export const loadLootbox = (lootboxId: number) => {
    return async (dispatch: Dispatch<IAddLootboxToStore>): Promise<void> => {
        const response = await fetch(getLootboxApiUrl(lootboxId));
        const json: { data: ILootboxStateItem } = await response.json();
        dispatch(addLootboxToStore(prepareLootbox(json.data)));
    };
};
