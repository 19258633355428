import { createBrowserRouter, type RouteObject } from 'react-router-dom';
import { urlsMap } from '~/utils/menu';
import DefaultRouterWrapper from '~/routing/DefaultRouterWrapper';
import CategoryRouter from '~/routing/CategoryRouter';
import MobileContainerRouter from '~/routing/MobileContainerRouter';
import ViewContainer from '~/containers/ViewContainer/ViewContainer';
import AuctionContainer from '~/containers/AuctionContainer/AuctionContainer';
import ConfirmPurchaseContainer from '~/containers/ConfirmPurchaseContainer/ConfirmPurchaseContainer';
import MenuMobile from '~/components/Menu/MenuMobile';
import MobileWalletCategory from '~/components/Wallet/MobileWalletCategory';
import MobileCouponsContainer from '~/containers/CouponsContainer/MobileCouponsContainer';
import BundlePageRouter from '~/routing/BundlePageRouter';
import PortContainer from '~/containers/PortContainer/PortContainer';

// Disable default scroll restoration
if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}

// Routes
const routes: RouteObject[] = [
    {
        path: '/',
        element: <ViewContainer />,
        children: [
            {
                path: urlsMap.bundle,
                element: (
                    <DefaultRouterWrapper>
                        <BundlePageRouter />
                    </DefaultRouterWrapper>
                ),
            },
            {
                path: urlsMap.categories,
                element: (
                    <DefaultRouterWrapper>
                        <CategoryRouter />
                    </DefaultRouterWrapper>
                ),
            },
            {
                path: urlsMap.port,
                element: <PortContainer />,
            },
            {
                path: urlsMap.coupons,
                element: (
                    <MobileContainerRouter>
                        <MobileCouponsContainer />
                    </MobileContainerRouter>
                ),
            },
            {
                path: urlsMap.wallet,
                element: (
                    <MobileContainerRouter>
                        <MobileWalletCategory />
                    </MobileContainerRouter>
                ),
            },
            {
                path: urlsMap.mobileMenu,
                element: (
                    <MobileContainerRouter>
                        <MenuMobile />
                    </MobileContainerRouter>
                ),
            },
            {
                path: urlsMap.bundlePurchase,
                element: (
                    <MobileContainerRouter>
                        <ConfirmPurchaseContainer />
                    </MobileContainerRouter>
                ),
            },
            {
                path: urlsMap.auction,
                element: (
                    <DefaultRouterWrapper>
                        <AuctionContainer />
                    </DefaultRouterWrapper>
                ),
            },
            {
                path: urlsMap.auctionLot,
                element: (
                    <DefaultRouterWrapper>
                        <AuctionContainer />
                    </DefaultRouterWrapper>
                ),
            },
            {
                path: '*',
                element: (
                    <DefaultRouterWrapper>
                        <CategoryRouter />
                    </DefaultRouterWrapper>
                ),
            },
        ],
    },
];

const router = createBrowserRouter(routes as RouteObject[], { future: { v7_normalizeFormMethod: true } });

export type Router = typeof router;

export default router;
