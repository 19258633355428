import * as React from 'react';
import styles from './BundlePageLabels.scss';
import Account from '~/account/Account';
import { getPurchaseLimit, hasUniqueItems } from '~/utils/bundles';
import OfferMark from '~/components/OfferMark/OfferMark';

interface IBundlePageLabels {
    bundle: IBundle;
}

const BundlePageLabels = ({ bundle }: IBundlePageLabels) => {
    const hasDiscount = bundle.discount || (bundle.originalPrice && bundle.originalPrice !== bundle.price);
    const isNeedToShowTimer = !!bundle.activityCountdown?.isEnabled && new Date(bundle.activityCountdown?.activeFrom).getTime() <= new Date().getTime() && bundle.promoTimerActiveTill && !hasDiscount;
    const limit = Account.getAccount()?.id ? getPurchaseLimit(bundle) : bundle.limitedQuantity?.personalLimit;
    const isNeedToShowLimit = limit && !bundle.isRandom && !bundle.serialPurchase && !hasUniqueItems(bundle);

    if (!isNeedToShowTimer && !isNeedToShowLimit && !bundle.promoLabel) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            {!!bundle.promoLabel && <OfferMark label={bundle.promoLabel} className={styles.label} />}
            {isNeedToShowTimer && <OfferMark expiredTime={bundle.promoTimerActiveTill} className={styles.label} />}
            {isNeedToShowLimit && <OfferMark limit={limit} className={styles.label} />}
        </div>
    );
};

export default BundlePageLabels;
