import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './Auction.scss';
import { useSelector } from 'react-redux';
import { t } from '~/utils/localization';
import { Interpolate } from '@wg/wows-react-uikit';
import Currency from '~/components/Currency/Currency';
import Attention from '~/components/Attention/Attention';
import classNames from 'classnames';
import { getUrl, urlsMap } from '~/utils/menu';
import AuctionProcessor from '~/processors/AuctionProcessor';
import AuctionLotHeader from './AuctionHeader';
import AuctionBidButton from '~/components/AuctionBidButton/AuctionBidButton';
import { State } from '~/Reducers';
import CountDown from '~/components/CountDown/CountDown';
import { isLost, isWaitingWinners, isWon } from '~/utils/auction';
import { BID_STATUS, DWH_EVENTS } from '~/const';
import dwhExport from '~/api/dwhExport';
import AuctionStatus from '~/components/Auction/AuctionStatus';
import ActiveBid from '~/components/Popups/Auction/ActiveBid/ActiveBid';
import { formatNumber } from '~/utils/utils';
import { items as ITEMS, assets as ASSETS } from '@wg/wows-entities/const';
import { LotWidth } from '~/types/auction';
import { DivTooltip } from '@wg/wows-react-uikit';
import AuctionTooltip from '~/components/Auction/AuctionTooltip';
import { playCardClickSound } from '~/api/WoWsClient';
import History from '~/utils/history';
import { getCurrency } from '~/utils/currencies';

interface IAuctionLot extends ILot {
    template?: LotWidth;
    auction?: IAuction;
    items: ILotItem[];
}

interface IAuctionState {
    auctionAccount: IAuctionAccountState;
    minimalWonBids: IAuctionMinimalWonBids[];
}

const stateSelector = (state: State): IAuctionState => {
    return {
        auctionAccount: state.ReducerAuction.account,
        minimalWonBids: state.ReducerAuction.minimalWonBids,
    };
};

const AuctionLot = (props: IAuctionLot) => {
    const state = useSelector<State, IAuctionState>(stateSelector, equal);

    const onClick = () => {
        playCardClickSound();
        dwhExport.send(DWH_EVENTS.OPEN_LOT, { lot_id: props.id });
        History.navigate(getUrl(urlsMap.auctionLot, { id: props.displayId }));
    };

    const auctionProcessor = new AuctionProcessor(props.auction, props, state.auctionAccount);
    const isBidAlreadyPlaced = auctionProcessor.isBidAlreadyPlaced();
    const currentBid = auctionProcessor.getActiveBidByLotId()?.bid?.amount || 0;
    const canPlacedBid = auctionProcessor.canPlacedBid(props.id);
    const CURRENT_BID_STATUS = auctionProcessor.getBidStatus();
    const isBidCanceling = auctionProcessor.isBidCanceling();
    const [isFinished, setFinish] = React.useState<boolean>(auctionProcessor.isFinishedLot());
    const hasActiveTransaction = state.auctionAccount?.transactions?.length > 0;
    const isAlreadyHaveLot = auctionProcessor.isAlreadyHaveLot(props.id);
    const isBidPlacingByLot = auctionProcessor.isBigPlacingByLot();

    const _isWon = isFinished && isWon(auctionProcessor.getBidStatus());
    const _isLost = isFinished && isLost(auctionProcessor.getBidStatus());

    const isNeedToShownBid = isBidAlreadyPlaced && (!isFinished || isWaitingWinners(props.auction.status));

    const currencyComponent = <Currency currency={props.minimalBid.currency} className={styles.betCurrency} amount={currentBid} withoutAnimation />;

    const betClasses = classNames(styles.bet);
    const buttonClasses = classNames(styles.button);

    const style = window.screen.availWidth <= 680 ? { backgroundImage: `url(${props.purchasePreviewImageUrl})` } : { backgroundImage: `url(${props.gridImageUrl})` };

    const isOnlyShipLot = (items: ILotItem[]) => {
        const lotItems = items.filter((item: ILotItem) => item.type !== ITEMS.DEFAULT_CREW && item.type !== ASSETS.SLOT);
        return lotItems.length === 1 && lotItems[0].type === ITEMS.VEHICLES;
    };

    React.useEffect(() => {
        setFinish(auctionProcessor.isFinishedLot());
    }, [props.auction.status]);

    const classesLot = classNames(styles.item, styles[props.template], {
        [styles.win]: _isWon,
        [styles.alreadyHaveLot]: isAlreadyHaveLot,
    });

    const minimalWonBid = state.minimalWonBids.find((bid) => bid.auctionId === props.auctionId && bid.lotId === props.id);

    return (
        <div className={classesLot} onClick={onClick} style={style} key={`auction_lot_${props.id}_${props.auction.status}`}>
            <DivTooltip tooltipBody={isFinished ? <AuctionTooltip {...props} minimalWonBid={minimalWonBid} currentBid={currentBid} currentBidStatus={CURRENT_BID_STATUS} /> : null}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            <AuctionLotHeader {...props} crewClassName={styles.crewTitle} inOnlyShipLot={isOnlyShipLot(props.items)} />
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={classNames(styles.timer, styles.hidden)}>
                            {!isFinished ? (
                                <CountDown date={auctionProcessor.getFinishedAt()} />
                            ) : (
                                <div className={styles.auctionFinishedTimer}>
                                    <div className={styles.timerIcon} />
                                    <div className={styles.finishedLabel}>{t('Аукцион завершён')}</div>
                                </div>
                            )}
                        </div>
                        {!isFinished && (
                            <div className={styles.amount}>
                                {t('Количество победителей:')}&nbsp;<b>{formatNumber(props.winnersCount)}</b>
                            </div>
                        )}
                        <div className={betClasses}>
                            {!isFinished && isBidAlreadyPlaced && (CURRENT_BID_STATUS === BID_STATUS.PLACED || _isWon || _isLost) && (
                                <div className={styles.betPlaced}>
                                    <Interpolate
                                        str={t('Ваша ставка: %(bet)s')}
                                        bet={
                                            <ActiveBid
                                                auction={props.auction}
                                                currency={props.minimalBid.currency}
                                                amount={currentBid}
                                                className={styles.betCurrency}
                                                placedAt={auctionProcessor.getActiveBidByLotId()?.placedAt}
                                            />
                                        }
                                    />
                                </div>
                            )}
                            {isNeedToShownBid && isBidPlacingByLot && (
                                <div className={styles.bidInfo}>
                                    <Interpolate str={t('Обработка ставки: %(bet)s')} bet={currencyComponent} />
                                </div>
                            )}
                            {!isBidAlreadyPlaced && !isFinished && (
                                <div className={styles.minimalBet}>
                                    <Interpolate
                                        str={t('Минимальная ставка: %(bet)s')}
                                        bet={
                                            <Currency
                                                currency={props.minimalBid.currency}
                                                className={styles.betCurrency}
                                                amount={props.minimalBid.amount}
                                                withoutAnimation
                                                showDescriptionTooltip={props.auction.showDescriptionInPriceTooltip && getCurrency(props.minimalBid.currency)?.showDescriptionInPriceTooltip}
                                            />
                                        }
                                    />
                                </div>
                            )}
                            {isAlreadyHaveLot && <Attention level={'done'} className={styles.betStatus} isInline={true} label={t('Имущество получено')} />}
                        </div>
                        {canPlacedBid && (
                            <React.Fragment>
                                {!isFinished ? (
                                    isBidAlreadyPlaced && auctionProcessor.isDisabledChangeBid() ? null : (
                                        <div className={buttonClasses}>
                                            <AuctionBidButton
                                                lotId={props.id}
                                                isProcessing={hasActiveTransaction || isBidCanceling}
                                                isBidAlreadyPlaced={isBidAlreadyPlaced}
                                                coolDownAt={auctionProcessor.getCoolDownAt()}
                                                typeButton={'default'}
                                                minimalBid={props.minimalBid}
                                                isForceRenderPurchaseButton={true}
                                                onClick={(event) => {
                                                    event?.stopPropagation();
                                                    auctionProcessor.showBetPopup(props);
                                                }}
                                            />
                                        </div>
                                    )
                                ) : (
                                    <AuctionStatus
                                        bidStatus={CURRENT_BID_STATUS}
                                        currentBid={currentBid}
                                        minimalWonBid={minimalWonBid}
                                        auctionStatus={props.auction.status}
                                        isBidPlaced={isBidAlreadyPlaced}
                                        hiddenChoosingWinnersStatus={true}
                                        mergeStatusToOneBlock
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </DivTooltip>
        </div>
    );
};

export default AuctionLot;
