import Account from '~/account/Account';
import { settings } from '~/utils/settings';
import { get, post } from '~/utils/ajax';
import { logError } from '~/utils/logging';

const API_PATH = {
    CONTENT: 'api/state/content',
    ACCOUNT: 'api/state/account',
    PURCHASE: 'api/purchase/',
    TRANSACTION_STATUS: 'api/transactions/{}',
};
const UNAVAILABLE_ERROR = 'Treasure Chain is unavailable';
const NO_ACCOUNT_DATA = 'No account data for Treasure Chain';
export const UNSUCCESSFUL_TRANSACTION = 'Treasure Chain transaction request is unsuccessful';

export async function getTreasureBoxes(): Promise<TreasureContentResponse> {
    const baseUrl = settings.urls.treasure;
    if (!baseUrl) return;

    const url = new URL(API_PATH.CONTENT, baseUrl);
    try {
        return (await get(url.toString(), null, false, true, true, {
            credentials: 'include',
        })) as TreasureContentResponse;
    } catch (e) {
        logError(UNAVAILABLE_ERROR, e);
    }
}

export async function getTreasureAccountData(): Promise<TreasureAccountData> {
    const baseUrl = settings.urls.treasure;
    if (!Account.getAccount()?.id || !baseUrl) return;

    const url = new URL(API_PATH.ACCOUNT, baseUrl);
    try {
        return (
            await get(url.toString(), null, false, true, true, {
                credentials: 'include',
            })
        )?.account as TreasureAccountData;
    } catch (e) {
        logError(NO_ACCOUNT_DATA, e);
    }
}

export async function treasurePurchase(params: TreasurePurchaseRequest): Promise<TreasurePurchaseResponse> {
    const baseUrl = settings.urls.treasure;
    if (!Account.getAccount()?.id || !baseUrl) return;

    const url = new URL(API_PATH.PURCHASE, baseUrl);
    try {
        return (await post(url.toString(), params, null, false, true, true, {
            credentials: 'include',
        })) as TreasurePurchaseResponse;
    } catch (e) {
        logError(UNSUCCESSFUL_TRANSACTION, e);
    }
}

export async function getTransactionStatus(id: string | number): Promise<TreasureTransaction> {
    const baseUrl = settings.urls.treasure;
    if (!Account.getAccount()?.id || !baseUrl) return;

    const url = new URL(API_PATH.TRANSACTION_STATUS.replace('{}', id.toString()), baseUrl);
    try {
        const res = await get(url.toString(), null, false, true, true, {
            credentials: 'include',
        });
        return res;
    } catch (e) {
        logError(UNSUCCESSFUL_TRANSACTION, e);
    }
}
