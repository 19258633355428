import styles from './TreasureProgress.scss';
import TreasureProgressBar from './TreasureProgressBar/TreasureProgressBar';
import { t } from '~/utils/localization';
import { State } from '~/Reducers';
import { shallowEqual, useSelector } from 'react-redux';
import * as React from 'react';
import { ProgressTooltip } from './ProgressTooltip';
import classNames from 'classnames';

type Props = {
    masterBox: TreasureMasterBox;
};

type StateSelector = {
    account: TreasureAccountData;
    showAnimation: boolean;
    animationData?: TreasureAnimationData;
};

function stateSelector(state: State): StateSelector {
    return {
        account: state.ReducerTreasure.account,
        showAnimation: state.ReducerTreasure.showAnimation,
        animationData: state.ReducerTreasure.animationData,
    };
}

export function getChainGuaranteeMsg(amountToReward: number): string {
    return t('До гарантированного выпадения: {number}').replace('{number}', amountToReward.toString());
}

export function getRotationForNoAccount(chains: TreasureChain[]): TreasureRotation {
    const rotation: TreasureRotation = {};
    chains.forEach((chain) => (rotation[chain.name] = chain?.rotationsTillGuarantee));
    return rotation;
}

export default function TreasureProgress(props: Props) {
    const state = useSelector<State, StateSelector>((state) => stateSelector(state), shallowEqual);
    const filteredChains = props.masterBox.chains.filter((chain) => chain.guarantee);

    const rotation = state.account?.rotations?.[props.masterBox.name] || getRotationForNoAccount(filteredChains);
    let chainWithCloseGuarantee = filteredChains.sort((a, b) => rotation[a.name] - rotation[b.name])[0];
    if (state.animationData?.targetChain) {
        chainWithCloseGuarantee = filteredChains.find((chain) => chain.name === state.animationData.targetChain);
    }
    const text = getChainGuaranteeMsg(state.animationData?.to === 0 ? 0 : rotation[chainWithCloseGuarantee.name]);
    const tilGuarantee = state.animationData?.from || rotation[chainWithCloseGuarantee.name];

    const colored = { '--color': chainWithCloseGuarantee?.color } as React.CSSProperties;

    return (
        <div className={styles.wrapper}>
            <ProgressTooltip account={state.account} text={text} boxName={props.masterBox.name} chains={filteredChains} />
            <div className={classNames(styles.animationWrapper, state.showAnimation && state.animationData.to === 0 && styles.animate)}>
                <img className={styles.shiny} style={colored} />
                <img className={classNames(styles.shiny, styles.reversed)} style={colored} />
                <img className={styles.icon} src={chainWithCloseGuarantee?.icon} alt={'icon'} />
            </div>
            <TreasureProgressBar chain={chainWithCloseGuarantee} tilGuarantee={tilGuarantee} showAnimation={state.showAnimation} animationData={state.animationData} />
        </div>
    );
}
