import * as React from 'react';
import AnimatedBorder from '~/customization/Common/AnimatedBorder/AnimatedBorder';
import HololiveAnimation from '~/customization/HololiveAnimation/HololiveAnimation';
import { BUNDLE_DECORATION } from '~/types/bundle';
import { isMobileOrTabletWindow } from '~/utils/utils';

interface IBundleDecoration {
    children: React.ReactChild | React.ReactChild[];
    bundle: IBundle;
}

const BundleDecoration = ({ children, bundle }: IBundleDecoration) => {
    const { decoration } = bundle;

    if (decoration.includes(BUNDLE_DECORATION.ANIMATE_BORDER)) {
        return <AnimatedBorder>{children}</AnimatedBorder>;
    }

    if (!isMobileOrTabletWindow && !bundle.isPurchased && decoration.includes(BUNDLE_DECORATION.HOLOLIVE_ANIMATION)) {
        return <HololiveAnimation>{children}</HololiveAnimation>;
    }

    return <>{children}</>;
};

export default BundleDecoration;
