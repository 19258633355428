import * as React from 'react';
import styles from './Tooltip.scss';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Price from '~/components/Price/Price';
import { arrayToObjectByKey } from '~/utils/utils';
import PriceWrapper from '../PriceWrapper/PriceWrapper';
import Discount, { DiscountSize } from '../Discount/Discount';

export type CurrencySecuenceItem = Record<string, { value: number; originalValue: number; discount: number; withExchange?: boolean; isSum?: boolean }>;

interface IBundleCurrencySequenceTooltip {
    currencies: CurrencySecuenceItem;
}

interface IStateSelector {
    currencies: ICurrencies[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currencies: state.ReducerApp.currencies,
    };
};

const BundleCurrencySequenceTooltip = ({ currencies }: IBundleCurrencySequenceTooltip): React.ReactChild | any => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const currenciesMap = arrayToObjectByKey(state.currencies, 'name');

    return (
        <div className={styles.currenciesTooltip}>
            {Object.keys(currencies).map((currency) => {
                return (
                    <div className={styles.bundleCurrencyItem} key={currency}>
                        <div className={styles.currencyItemTitle}>{currenciesMap[currency]?.title}</div>
                        <div className={styles.currencyItemDotted}></div>
                        <div className={styles.currencyItemWidget}>
                            {currencies[currency].discount ? (
                                <div className={styles.currencyItemPriceWithDiscountWrapper}>
                                    <Discount discount={currencies[currency].discount} className={styles.currencyItemPriceDiscount} size={DiscountSize.SMALL} />
                                    <div className={styles.currencyItemPrices}>
                                        <Price
                                            amount={currencies[currency]?.originalValue}
                                            isOld
                                            currency={currency}
                                            className={styles.currencyItemOldPriceWithDiscount}
                                            withoutAnimation
                                            withoutIcon
                                        />
                                        <Price amount={currencies[currency]?.value} currency={currency} className={styles.currencyItemPriceWithDiscount} withoutAnimation />
                                    </div>
                                </div>
                            ) : (
                                <Price amount={currencies[currency]?.value} currency={currency} className={styles.currencyItemPrice} withoutAnimation />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default BundleCurrencySequenceTooltip;
