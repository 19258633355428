import classNames from 'classnames';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { t } from '~/utils/localization';
import { CheckboxWithLabel } from '@wg/wows-react-uikit';
import QuantityContainer from '~/containers/QuantityContainer/QuantityContainer';
import { IPurchasePopupDetails } from '../../PurchasePopupDetails';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import { BundleLimitSize } from '~/components/BundleLimitWidget/BundleLimitWidget';
import PriceWrapper, { PRICE_WRAPPER_PLACE, PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';

import styles from './CustomCustomization.scss';

export function PurchasePopupCustomDetails({
    bundle,
    isEnabledPurchaseLimitToContainer,
    canChangeQuantity,
    activeCouponId,
    activeCoupon,
    isNeedToShownAdultCheckbox,
    isAdult,
    changeUserAdultFlag,
    hasRestriction,
}: IPurchasePopupDetails) {
    const amount = bundle.primaryItem.amount * (bundle.quantityData?.quantity || 1);

    const setRestrictionRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            RefManager.setRef(RefManagerKeys.PurchaseRestriction, _ref);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.content__info}>
                    <div className={styles.header}>
                        <div className={styles.header__title}>{bundle.titleImage || true ? <img className={styles.titleImage} src={bundle.titleImage} alt={bundle.title} /> : bundle.title}</div>
                        {isEnabledPurchaseLimitToContainer && (
                            <div className={styles.purchaseDetailsLimit}>
                                <BundleLimitContainer size={BundleLimitSize.BIG} className={styles.bundleLimitWrapper} bundle={bundle} />
                            </div>
                        )}
                    </div>
                    {!hasRestriction && (
                        <div className={styles.purchaseDetailsWrapper}>
                            {(amount > 1 || canChangeQuantity) && (
                                <div className={classNames(styles.purchaseDetailsWrapper__quantityWrapper, styles.quantityWrapper)}>
                                    <div className={styles.quantityWrapper__title}>{t('Количество:')}</div>
                                    {amount > 1 && !canChangeQuantity && <div className={styles.quantityWrapper__amount}>{amount}</div>}
                                    {canChangeQuantity && (
                                        <div className={styles.quantityWrapper__widget}>
                                            <QuantityContainer bundle={bundle} />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className={styles.purchaseDetailsWrapper__priceWrapper}>
                                <PriceWrapper
                                    bundle={bundle}
                                    coupon={activeCoupon}
                                    size={PRICE_WRAPPER_SIZE.BIG}
                                    showExchangePrices
                                    place={PRICE_WRAPPER_PLACE.POPUP}
                                    withoutAnimation={activeCouponId === null || activeCouponId === undefined}
                                    oldPriceClassName={styles.priceWrapper__oldPrice}
                                    newPriceClassName={styles.priceWrapper__newPrice}
                                    wrapperClassName={styles.priceWrapper}
                                />
                            </div>
                            {!hasRestriction && isNeedToShownAdultCheckbox && (
                                <div className={styles.purchaseDetailsWrapper__restriction} ref={setRestrictionRef}>
                                    <CheckboxWithLabel
                                        isChecked={isAdult}
                                        className={classNames({
                                            [styles.warn]: !isAdult,
                                        })}
                                        labelText={<span className={styles.ageRestrictionLabel}>{t('Я подтверждаю, что мне исполнилось 18 лет')}</span>}
                                        onChange={changeUserAdultFlag}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.imageWrapper}>
                    <img src={bundle.icons.small} className={styles.image} />
                </div>
            </div>
        </div>
    );
}
