import * as React from 'react';
import styles from './SubCategoryMenu.scss';
import classNames from 'classnames';
import History from '~/utils/history';
import { getUrl, urlsMap } from '~/utils/menu';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import useClickAway from '~/hooks/useClickAway';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { isLabelNewShownForCategory } from '~/utils/labels';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import { getAvailableSubCategories, getTabTitle, isAllPreviewCategoryBundlesPurchased } from '~/utils/category';
import equal from 'fast-deep-equal/react';
import { playTabClickSound } from '~/api/WoWsClient';
import SubCategoryMenuItem from '~/components/SubCategoryMenu/SubCategoryMenuItem';
import { isNeedToRenderFilter } from '~/utils/filters';
import { isTradeInCategory } from '~/utils/tradein';
import { isAvailableSortCategory } from '~/utils/sort/settings';

interface ISubCategoryMenu {
    category: ICategoryList;
    onChangeVisible?: (isVisible: boolean) => void;
}

interface IStateSelector {
    viewedLabels: string[];
    port: IPort;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        viewedLabels: state.ReducerAccount.viewedLabels,
        port: state.ReducerApp.port,
    };
};

const SubCategoryMenu = (props: ISubCategoryMenu) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isVisible, setVisible] = React.useState(false);
    const refMobileSubMenu: React.RefObject<HTMLDivElement> = React.useRef(null);

    useClickAway(refMobileSubMenu, () => {
        if (!props.category) {
            return null;
        }

        if (isVisible) {
            props.onChangeVisible && props.onChangeVisible(false);
        }

        setVisible(false);
    });

    const reducerApp = useSelector<State, AppState>((state) => state.ReducerApp, shallowEqual);
    if (reducerApp.currentPage?.isBundlePage || state.port?.isVisible) {
        return null;
    }

    const category = reducerApp.categories[props.category];
    const parentCategory = category.parentCategoryData || category;
    let subCategories: string[] = [];

    if (category?.subCategories.length) {
        subCategories = category.subCategories || [];
    } else {
        subCategories = parentCategory?.subCategories || [];
    }

    subCategories = [...new Set(subCategories)];

    if (parentCategory.disableFeatured) {
        subCategories.shift();
    }

    const availableSubCategories = getAvailableSubCategories(subCategories as ICategoryList[], reducerApp.categories);

    if (!subCategories.length || (!parentCategory.disableFeatured && availableSubCategories?.length < 2) || (parentCategory.disableFeatured && !availableSubCategories?.length)) {
        return null;
    }

    const totallyPurchasedCategories: string[] = [];
    const restCategories: string[] = [];
    subCategories.forEach((categoryName) => {
        const category = reducerApp.categories[categoryName];
        if (parentCategory?.name === category?.name) {
            restCategories.push(categoryName);
            return;
        }
        const isTotallyPurchased = isAllPreviewCategoryBundlesPurchased(category);
        if (isTotallyPurchased) {
            totallyPurchasedCategories.push(categoryName);
        } else {
            restCategories.push(categoryName);
        }
    });

    subCategories = [...restCategories, ...totallyPurchasedCategories];

    const oldActiveElement = document.querySelectorAll(`.${styles.menuItem}.${styles.isActive}`)[0];
    oldActiveElement?.classList.remove(styles.isActive);

    const activeItemElement = document.querySelectorAll(`[data-sub-menu-category="${props.category}"]`)[0];
    activeItemElement?.classList.add(styles.isActive);

    const changeCategory = (category: string) => {
        if (props.category === category) {
            return;
        }

        playTabClickSound();

        let url = getUrl(urlsMap.subCategory, {
            categoryName: parentCategory?.name || props.category,
            subCategoryName: category,
        });

        if (category === parentCategory?.name) {
            url = getUrl(urlsMap.categories, {
                categoryName: category,
            });
        }

        History.navigate(url);
    };

    const currentCategory = (reducerApp.categories[reducerApp.currentPage.name] || {}) as ICategory;

    const mobileClassNames = classNames(styles.mobileWrapperItems, {
        [styles.isVisible]: !isVisible,
    });

    const changeMobileCategory = (category: string) => {
        changeCategory(category);
        setVisible(false);
        props.onChangeVisible && props.onChangeVisible(false);
    };

    const mobileWrapperClassNames = classNames(styles.mobileWrapper, {
        [styles.active]: isVisible,
    });

    const mobileAvailableSubCategories = subCategories.filter((categoryName: ICategoryList) => {
        const subCategory = reducerApp.categories[categoryName];
        return !(!subCategory || (parentCategory?.name !== currentCategory?.name && !subCategory?.bundles?.length));
    });

    const isAvailableSort = !isTradeInCategory(category) && isAvailableSortCategory(category.name);
    const isNeedToRenderFilters = category && isNeedToRenderFilter(category?.name as ICategoryList);
    const menuWithNoMarginBottom = isAvailableSort || isNeedToRenderFilters;
    const classesWrapper = classNames(styles.wrapper, {
        [styles.noMarginBottom]: menuWithNoMarginBottom,
    });

    return (
        <React.Fragment>
            {mobileAvailableSubCategories?.length > 1 && (
                <div className={mobileWrapperClassNames} ref={refMobileSubMenu}>
                    <div
                        className={styles.mobileWrapperTitle}
                        onClick={() => {
                            setVisible(!isVisible);
                            props.onChangeVisible && props.onChangeVisible(!isVisible);
                        }}
                    >
                        {getTabTitle(currentCategory, parentCategory.name)}
                    </div>
                    <div className={mobileClassNames}>
                        {subCategories.map((categoryName: ICategoryList) => {
                            if (reducerApp.currentPage?.name === categoryName) {
                                return null;
                            }

                            const subCategory = reducerApp.categories[categoryName];
                            const className = classNames(styles.mobileItem);

                            const isEnabledLabelNew = isLabelNewShownForCategory(subCategory.name, null, false, true);
                            const _changeCategory = () => {
                                changeMobileCategory(categoryName);
                            };

                            return (
                                <LabelDecorator id={subCategory.name} isEnabled={isEnabledLabelNew} type={LABEL_TYPE.category} className={className} key={subCategory.name} onClick={_changeCategory}>
                                    <div className={className}>
                                        <div className={styles.mobileItemTitleWrapper}>
                                            <div className={styles.mobileItemTitle}>{getTabTitle(subCategory, parentCategory.name)}</div>
                                            <LabelContainer className={styles.categoryMobileLabel} isVisible={isEnabledLabelNew} />
                                        </div>
                                    </div>
                                </LabelDecorator>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className={classesWrapper}>
                {subCategories.map((categoryName: ICategoryList) => {
                    const subCategory = reducerApp.categories[categoryName] as ICategory;
                    const className = classNames({
                        [styles.isActive]: category.name === categoryName,
                    });

                    const _changeCategory = () => {
                        changeCategory(categoryName);
                    };

                    return <SubCategoryMenuItem key={subCategory.name} category={subCategory} parentCategoryName={parentCategory.name} className={className} onClick={_changeCategory} />;
                })}
            </div>
        </React.Fragment>
    );
};

export default SubCategoryMenu;
