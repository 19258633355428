import * as React from 'react';
import styles from './TradeInSubCategories.scss';
import TabMenu, { ITabMenuItem } from '~/components/TabMenu/TabMenu';
import { State } from '~/Reducers';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import History from '~/utils/history';
import { getUrl, urlsMap } from '~/utils/menu';

interface ITradeInSubCategories {
    category: ICategory;
}

interface IStateSelector {
    categories: ICategories;
    currentPage: ICurrentPage;
}

const stateSelector = (state: State) => {
    return {
        categories: state.ReducerApp.categories,
        currentPage: state.ReducerApp.currentPage,
    };
};

const TradeInSubCategories = ({ category }: ITradeInSubCategories) => {
    const { categories, currentPage } = useSelector<State, IStateSelector>(stateSelector, equal);
    const subCategories = React.useMemo<string[]>(() => {
        if (!category) {
            return [];
        }
        const names = !!category.subCategories.length ? [...category.subCategories] : [...(category.parentCategoryData?.subCategories || [])];
        return names.splice(1, names.length);
    }, [category]);

    if (!subCategories.length || subCategories.length === 1) {
        return null;
    }

    const tabs: ITabMenuItem[] = subCategories.map((categoryName: string) => {
        let isActive = currentPage?.name === categoryName;

        if (categories[currentPage?.name].subCategories?.length) {
            isActive = categoryName === subCategories[0];
        }

        return {
            title: categories[categoryName].title,
            isActive,
            onClick: () => {
                History.navigate(
                    getUrl(urlsMap.subCategory, {
                        categoryName: categories[categoryName].parentCategoryData?.name,
                        subCategoryName: categoryName,
                    }),
                );
            },
        };
    });

    return (
        <div className={styles.wrapper}>
            <TabMenu tabs={tabs} />
        </div>
    );
};

export default TradeInSubCategories;
