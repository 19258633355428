import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { isEnableShinyForBundle, isShinyCategory } from '~/utils/shiny/shiny';
import { useEffect, useState } from 'react';
import { generateAllCategoryBundlesDeeply } from '~/utils/category';
import { isBundleDisabledByPromoTimer } from '~/utils/bundles';
import { handlePromoTimerComplete } from '~/utils/promoTimer';

interface IStateSelector {
    purchasedBundles?: AccountPurchasedLimitedBundles;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        purchasedBundles: state.ReducerAccount.purchasedLimitedBundles, // for rerender
    };
};

export default function useShinyCategory(categoryName: ICategoryList, bundleSplice?: number) {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isShinyState, setIsShiny] = useState(false);

    useEffect(() => {
        for (const bundle of generateAllCategoryBundlesDeeply(categoryName, bundleSplice)) {
            if (isBundleDisabledByPromoTimer(bundle) && isEnableShinyForBundle(bundle, true, true)) {
                handlePromoTimerComplete(bundle, () => {
                    setIsShiny(isEnableShinyForBundle(bundle, true));
                });
            }
        }
    }, []);

    const isShiny = isShinyCategory(categoryName, bundleSplice);
    if (isShiny !== isShinyState) setIsShiny(isShiny);

    return isShinyState;
}
