import * as React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { State } from '~/Reducers';
import { AccountState } from '~/Reducers/ReducerAccount';
import MobileCouponsCategory from '~/components/Coupon/MobileCouponsCategory';
import { changeCurrentPage } from '~/Actions/ActionApp';
import { t } from '~/utils/localization';
import History from '~/utils/history';
import { urlsMap } from '~/utils/menu';
import { scrollMobileContainer } from '~/utils/scrollTop';
import { hasCoupons, isCouponsTabHidden } from '~/utils/coupons';
import Account from '~/account/Account';

const MobileCouponsContainer = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: State): AccountState => state.ReducerAccount, shallowEqual);

    React.useEffect(() => {
        if (!Account.getAccount() || isCouponsTabHidden() || !hasCoupons()) {
            History.navigate(urlsMap.home);
            return;
        }

        scrollMobileContainer({ top: 0 });
        dispatch(
            changeCurrentPage({
                title: t('Купоны'),
                name: 'coupons',
                isBundlePage: false,
            }),
        );
    }, []);

    const defaultCoupons = account.coupons?.filter((coupon: ICoupon) => coupon.isProlongable);
    const extraCoupons = account.coupons?.filter((coupon: ICoupon) => !coupon.isProlongable && !coupon.isDisabled);

    if (!account.id) {
        return null;
    }

    return <MobileCouponsCategory coupons={defaultCoupons} extraCoupons={extraCoupons} />;
};

export default MobileCouponsContainer;
