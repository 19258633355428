import * as React from 'react';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import { OrangeButton } from './Buttons';
import { t } from '~/utils/localization';
import { settings } from '~/utils/settings';
import { DivTooltip } from '@wg/wows-react-uikit';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { IChangeVisiblePopup, ICurrentPage, ISetFetching } from '~/Actions/ActionAppType';
import { State } from '~/Reducers';
import { DWH_EVENTS as WOWS_COMMERCE_DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents';
import { RegionalInfo } from '@wg/wows-commerce/api/user';
import { changeVisiblePopup, setFetching } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { isFreeBundle } from '~/utils/bundles';
import { updateSearchParam } from '~/utils/utils';

interface IRealPurchaseButton {
    bundle: IBundle;
    quantity?: number;
    label?: string;
    buttonClassName?: string | string[];
    onMouseMove?: (event: React.MouseEvent) => void;
    isDisabled?: boolean;
    onClickFromDisableButton?: (event: React.MouseEvent) => void;
}

interface IStateSelector {
    currentPage: ICurrentPage;
    regional: Nullable<RegionalInfo>;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        regional: state.ReducerWSMart.regional,
    };
};

const RealPurchaseButton = ({ bundle, buttonClassName, onMouseMove, onClickFromDisableButton, isDisabled, quantity, label: customLabel }: IRealPurchaseButton) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const isFree = isFreeBundle(bundle);
    const label = customLabel || (isFree ? t('Получить бесплатно') : t('Купить'));

    if (bundle.isPurchased) {
        return <PurchasedItem bundle={bundle} />;
    }

    if (bundle.isLoading) {
        return <OrangeButton isFree={isFree} label={label} disabled />;
    }

    if (bundle.originalProduct?.isVariablePriceProduct && !state.currentPage?.isBundlePage) {
        return <OrangeButton label={t('Перейти')} />;
    }

    if (settings.disableRealPurchaseForTesting) {
        return (
            <DivTooltip tooltipBody={<DefaultTooltip text={t('Покупки недоступны на этапе тестирования')} />}>
                <OrangeButton isFree={isFree} label={label} disabled={true} />
            </DivTooltip>
        );
    }

    const dwhEventName = state.currentPage?.isBundlePage ? WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE : WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_CATEGORY;

    return (
        <OrangeButton
            label={label}
            isFree={isFree}
            onMouseMove={onMouseMove}
            onFallback={onClickFromDisableButton}
            onClick={async (event) => {
                if (!bundle.originalProduct?.isPurchasable) {
                    return;
                }
                event.stopPropagation();
                dispatch<IChangeVisiblePopup>(changeVisiblePopup(POPUPS_NAME.IFRAME_POPUP, false));
                if (state.regional && !state.regional.purchaseIsAllowed) {
                    dispatch(changeVisiblePopup(POPUPS_NAME.FORBIDDEN_REGION, true, state.regional));
                    return;
                }
                const wsmartProcessor = new WSMartPurchaseProcessor(bundle.originalProduct);
                wsmartProcessor.setBundle(bundle);
                await wsmartProcessor.buy(dwhEventName, quantity || 1);
            }}
            disabled={isDisabled || !bundle.originalProduct?.isPurchasable}
            className={buttonClassName}
        />
    );
};

export default RealPurchaseButton;
