import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import store from '~/Store';
import { hiddenMenuItem } from '~/Actions/ActionApp';
import Account from '~/account/Account';
import { CategoryType } from '~/types/category';
import { getTreasureAccountData, getTreasureBoxes } from '~/api/treasure';
import { saveTreasureAccountData, saveTreasureBoxes } from '~/Actions/ActionTreasure';
import { loadAdditionalRewardDataFromVrtx } from '~/utils/mrps';
import { concatRewards } from '~/utils/treasure';
import TreasureProcessor from '~/processors/TreasureProcessor';

export default class TreasureChainPreloader extends AbstractPreloader {
    async load() {
        const categories = store.getState().ReducerApp.categories;
        const treasureCategories = Object.entries(categories).filter(([_, categoryData]) => categoryData.type === CategoryType.TREASURE);

        if (!treasureCategories.length) return;

        const treasureMasterBoxes = await getTreasureBoxes();
        if (!treasureMasterBoxes?.length) {
            treasureCategories.forEach((category) => {
                store.dispatch(hiddenMenuItem(category[0]));
            });
            return;
        }

        const allowedBoxes = treasureMasterBoxes.map((box) => box.name);
        treasureCategories.forEach((category) => {
            if (!allowedBoxes.includes(category[0])) store.dispatch(hiddenMenuItem(category[0]));
        });

        const allRewards: TreasureReward[] = [];
        treasureMasterBoxes.forEach((box) => {
            allRewards.push(...concatRewards(box, true));
        });
        const loadedRewards = (await loadAdditionalRewardDataFromVrtx(allRewards)) as TreasureLoadedReward[];
        const loadedRewardsMapping: { [key: string]: TreasureLoadedReward } = {};

        loadedRewards.forEach((reward) => {
            if (!loadedRewardsMapping?.[reward.name]) {
                loadedRewardsMapping[reward.name] = reward;
            }
        });

        treasureMasterBoxes.forEach((box, boxID) =>
            box.chains.forEach((chain, chainID) => {
                chain.rewards.forEach((reward, rewardID) => {
                    treasureMasterBoxes[boxID].chains[chainID].rewards[rewardID] = { ...loadedRewardsMapping[reward.name], color: treasureMasterBoxes[boxID].chains[chainID]?.color };
                });
                if (chain.filler) {
                    treasureMasterBoxes[boxID].chains[chainID].filler = { ...loadedRewardsMapping[chain.filler.name], color: treasureMasterBoxes[boxID].chains[chainID]?.color };
                }
            }),
        );

        store.dispatch(saveTreasureBoxes(treasureMasterBoxes));

        if (Account.getAccount() && !Account.isDeactivated()) {
            const accountData = await getTreasureAccountData();
            accountData && store.dispatch(saveTreasureAccountData(accountData));
            if (accountData.pendingTransaction) {
                new TreasureProcessor().initUserWithPendingTransaction(accountData.pendingTransaction);
            }
        }

        return Promise.resolve(1 as const);
    }
}
