import React from 'react';
import { CustomPageParams } from '~/types/customPage';

import styles from './CustomPageAdditionalContent.scss';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES, PLACEMENT } from '~/components/WelcomePage/steps';

interface CustomPageAdditionalContentProps {
    customParams: CustomPageParams;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
}

export function CustomPageAdditionalContent({ customParams, onClick, onMouseEnter, onMouseLeave }: CustomPageAdditionalContentProps) {
    const wrapperStyles = React.useMemo<React.CSSProperties>(() => {
        const gridColumnStartIndex = customParams.place[0];
        const gridRowStartIndex = customParams.place[1];

        return {
            gridColumn: `${gridColumnStartIndex} / span ${customParams.width}`,
            gridRow: `${gridRowStartIndex} / span ${customParams.height}`,
        };
    }, [customParams]);

    const mouseEnterHandler = React.useCallback(() => {
        onMouseEnter?.();
    }, [onMouseEnter]);

    const mouseLeaveHandler = React.useCallback(() => {
        onMouseLeave?.();
    }, [onMouseLeave]);

    const mouseClickHandler = React.useCallback(() => {
        onClick?.();
    }, [onClick]);

    return (
        <div className={styles.wrapper} style={wrapperStyles}>
            <GuideDecorator names={[GUIDE_NAMES.guide_portal_company]} className={styles.guideWrapper} placement={PLACEMENT.CENTER}>
                <div className={styles.fakeContentWrapepr} onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} onClick={mouseClickHandler} />
            </GuideDecorator>
        </div>
    );
}
