import { interpolate, ngettext } from '~/utils/localization';

export const getParsedTime = (time: number) => {
    const days = Math.floor(time / 86400);
    const hoursLeft = Math.floor(time - days * 86400);
    const hours = Math.floor(hoursLeft / 3600);
    const minutesLeft = Math.floor(hoursLeft - hours * 3600);
    const minutes = Math.floor(minutesLeft / 60);
    const seconds = Math.floor(time % 60);

    return {
        days,
        hours,
        minutes,
        seconds,
    };
};

export const getParsedTimeFromTimer = (time: number) => {
    const { days, hours, minutes, seconds } = getParsedTime(time);

    return {
        days: `${days}`,
        hours: `${hours < 10 ? '0' : ''}${hours}`,
        minutes: `${minutes < 10 ? '0' : ''}${minutes}`,
        seconds: `${seconds < 10 ? '0' : ''}${seconds}`,
    };
};

export const getSecondsPassedFromPageRender = (): number => {
    return Math.floor(performance.now() / 1000);
};

export const getTimeWholePeriod = (time: number) => {
    let days, hours, minutes, seconds;
    days = hours = minutes = seconds = 0;

    if (time % 86400 === 0) {
        days = time / 86400;
    } else if (time % 3600 === 0) {
        hours = time / 3600;
    } else if (time % 60 === 0) {
        minutes = time / 60;
    } else {
        seconds = time;
    }

    return {
        days,
        hours,
        minutes,
        seconds,
    };
};

export const getPeriodicInfo = (time: number) => {
    const { days, hours, minutes, seconds } = getTimeWholePeriod(time);
    let text = '';
    if (days) {
        text = interpolate(ngettext('{count} день.', '{count} дня.', days), {
            count: days,
        });
    } else if (hours) {
        text = interpolate(ngettext('{count} час.', '{count} часа.', hours), {
            count: hours,
        });
    } else if (minutes) {
        text = interpolate(ngettext('{count} минута.', '{count} минуты.', minutes), {
            count: minutes,
        });
    } else if (seconds) {
        text = interpolate(ngettext('{count} секунда.', '{count} секунды.', seconds), {
            count: seconds,
        });
    }

    return text;
};

export const isValidDate = (date: string): boolean => new Date(date).getTime() >= Date.now();

export const isValidDateInterval = (activePeriod: IActiveTime): boolean => {
    if (activePeriod == null) {
        return false;
    }

    if (activePeriod.activeFrom && activePeriod.activeTill) {
        return new Date(activePeriod.activeTill).getTime() >= Date.now() && new Date(activePeriod.activeFrom).getTime() < Date.now();
    }
    return isValidDate(activePeriod.activeTill);
};

export const tzNaiveToUtcDate = (date: Date): Date => {
    return new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
};
