import * as React from 'react';
import styles from './BidPlacingErrorPopup.scss';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import useClickAway from '~/hooks/useClickAway';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { ZoomInDiv } from '~/components/Transitions';
import { t } from '~/utils/localization';
import { Button } from '~/components/Button/Buttons';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

const BidPlacingErrorPopup = () => {
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const ref = React.useRef(null);

    const close = () => dispatch(changeVisiblePopup(null));

    useClickAway(
        ref,
        () => {
            if (!isPopupActive) {
                return;
            }
            close();
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    return (
        <ZoomInDiv className={styles.wrapper} ref={ref}>
            <div className={styles.content}>
                <div className={styles.icon} />
                <div className={styles.betTitle}>{t('Произошла ошибка. Попробуйте сделать ставку позднее')}</div>
                <div className={styles.footer}>
                    <Button label={t('Закрыть')} onClick={close} />
                </div>
            </div>
        </ZoomInDiv>
    );
};

export default BidPlacingErrorPopup;
