import * as Sentry from '@sentry/browser';

export const logError = (msg: string, error?: Error, ...args: any[]): void => {
    const logArgs = [...args];
    if (error) {
        logArgs.unshift(JSON.stringify(error));
    }
    console.error.apply(console, [_logPrefix() + msg, ...logArgs]);
    if (error) {
        Sentry.captureException(error);
    }
};

export const logInfo = (msg: string, ...args: any[]): void => {
    console.log.apply(console, [_logPrefix() + msg, ...args]);
};

function _logPrefix() {
    const now = new Date();
    return `[ARMORY] [${now.getHours()}:${now.getMinutes()}] `;
}
