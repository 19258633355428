import styles from './TreasureRewardsSlider.scss';
import { t } from '~/utils/localization';
import * as React from 'react';
import { useState } from 'react';
import Carousel from '~/components/Carousel/Carousel';
import type { SwiperClass as SwiperInstance } from 'swiper/swiper-react';
import { playTabClickSound } from '~/api/WoWsClient';
import classNames from 'classnames';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal';
import { TreasureRewardItem } from './TreasureReward/TreasureReward';
import { concatRewards, sortRewardsForCarousel } from '~/utils/treasure';

type Props = {
    chains: Array<TreasureChain>;
    box: TreasureMasterBox;

    hasPending: boolean;
};

function setMask(sliderState: number, sliderWrapper: HTMLElement): void {
    if (sliderState > 0 && sliderState < 1) {
        sliderWrapper.style.webkitMaskImage =
            '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgba(0, 0, 0, 0)), color-stop(0.05, rgb(0, 0, 0)), color-stop(0.95, rgb(0, 0, 0)), color-stop(0.99, rgba(0, 0, 0, 0.145)))';
    } else if (sliderState === 0) {
        sliderWrapper.style.webkitMaskImage =
            '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(0, 0, 0)), color-stop(0.05, rgb(0, 0, 0)), color-stop(0.95, rgb(0, 0, 0)), color-stop(0.99, rgba(0, 0, 0, 0)))';
    } else if (sliderState === 1) {
        sliderWrapper.style.webkitMaskImage =
            '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgba(0, 0, 0, 0)), color-stop(0.05, rgb(0, 0, 0)), color-stop(0.95, rgb(0, 0, 0)), color-stop(0.99, rgb(0, 0, 0)))';
    }
}

interface StateSelector {
    inventory: InventoryState;
}

function stateSelector(state: State): StateSelector {
    return {
        inventory: state.ReducerAccount.inventory,
    };
}

export default function TreasureRewardsSlider(props: Props) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);
    const [s, setSwiperRef] = React.useState<SwiperInstance>(null);
    const boxRewards = concatRewards(props.box);
    const rewards: TreasureReward[] = sortRewardsForCarousel(boxRewards, state.inventory);
    const [carouselProgress, setCarouselProgress] = useState<number>(0);

    const dispatch = useDispatch();

    function catchProgress(sliderState: number, sliderWrapper: HTMLElement): void {
        setCarouselProgress(sliderState);
        setMask(sliderState, sliderWrapper);
    }

    const navigationNextStyle = classNames(styles.navigationButtonNext, carouselProgress === 1 && styles.disabled);
    const navigationBackStyle = classNames(styles.navigationButtonPrev, !carouselProgress && styles.disabled);

    const initSeeAllPopup = () => {
        dispatch(changeVisiblePopup(POPUPS_NAME.TREASURE_GROUPS_POPUP, true, { chains: props.chains, box: props.box.name, background: props.box.imageBackground }));
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.slider}>
                <Carousel
                    items={rewards.map((reward) => (
                        <TreasureRewardItem rewardData={reward} inventory={state.inventory} background={props.box?.imageBackground} className={props.hasPending && styles.blocked} />
                    ))}
                    className={{
                        navigationButtonNext: navigationNextStyle,
                        navigationButtonPrev: navigationBackStyle,
                        item: styles.slide,
                    }}
                    freeMode={false}
                    setSwiperRef={(swiper) => {
                        setSwiperRef(swiper);
                    }}
                    withoutLazy
                    onNavigationNext={() => {
                        playTabClickSound();
                        s.slideNext();
                    }}
                    onNavigationPrev={() => {
                        playTabClickSound();
                        s.slidePrev();
                    }}
                    onSliderScrollProgress={catchProgress}
                    outerControls={styles.outerControls}
                />
            </div>
            <div className={classNames(styles.seeAll, props.hasPending && styles.blocked)} onClick={initSeeAllPopup}>
                <div className={styles.seeAll__text}>{t('Посмотреть все награды')}</div>
            </div>
        </div>
    );
}
