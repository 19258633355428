import styles from './TreasurePurchaseBlock.scss';
import TreasurePurchase from '~/components/TreasureChain/TreasurePurchaseBlock/TreasurePurchase/TreasurePurchase';
import TreasureProgress from '~/components/TreasureChain/TreasurePurchaseBlock/TreasureProgress/TreasureProgress';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

type Props = {
    className: string;
    masterBox: TreasureMasterBox;

    hasPending: boolean;
    setPending: (val: boolean) => void;
};

interface ITreasurePurchaseState {
    balance: IBalance;
    accountId: number;
}

const stateSelector = (state: State): ITreasurePurchaseState => {
    return {
        balance: state.ReducerAccount.balance,
        accountId: state.ReducerAccount.id,
    };
};

export default function TreasurePurchaseBlock(props: Props) {
    const state = useSelector<State, ITreasurePurchaseState>(stateSelector, equal);
    return (
        <div className={props.className}>
            <div className={styles.content}>
                <div className={styles.purchaseBlock}>
                    <TreasureProgress masterBox={props.masterBox} />
                    <TreasurePurchase masterBox={props.masterBox} balance={state.balance} setPending={props.setPending} hasPending={props.hasPending} />
                </div>
            </div>
        </div>
    );
}
