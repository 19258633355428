import * as React from 'react';
import { useEffect } from 'react';
import equal from 'fast-deep-equal/react';
import FeaturingLayout from '~/Layouts/FeaturingLayout';
import DefaultLayout from '~/Layouts/DefaultLayout';
import SubCategoriesLayout from '~/Layouts/SubCategoriesLayout';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentPage, changeViewBackground, removeClientSource, resetCategoryFilter, resetSortCategory, setFetching, updateActiveFilterPreset } from '~/Actions/ActionApp';
import { urlsMap } from '~/utils/menu';
import { State } from '~/Reducers';
import { scrollTop } from '~/utils/scrollTop';
import { t } from '~/utils/localization';
import { resetCoupon } from '~/Actions/ActionAccount';
import { getPresetByName, getPresetByType, isCategoryDisplayRestricted, openCategoryByName } from '~/utils/category';
import { PreCacheImageFromCategory } from '~/utils/preCacheImage';
import { BundlesObserver } from '~/components/BundlesObserver/BundlesObserver';
import ThemeManager, { isThemeEnabled, SANTA_PAGE_THEME, SINGLE_BUNDLE_PAGE } from '~/Layouts/Themes/ThemeManager';
import { CATEGORIES, DWH_EVENTS, FILTER_DISCOUNT_NAME, FILTER_DISCOUNT_VALUES } from '~/const';
import dwhExport from '~/api/dwhExport';
import PresetsLayouts from '~/Layouts/PresetsLayouts';
import { ICurrentPage, IUpdateActiveFilterPreset } from '~/Actions/ActionAppType';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import ContentLayoutDecoration from '~/decorators/ContentLayoutDecoration/ContentLayoutDecoration';
import { isActiveFilters } from '~/utils/filters';
import { getCategoryBackground } from '~/utils/background';
import useEntryVideo from '~/hooks/useEntryVideo';
import SeaBattleContainer from '~/containers/SeaBattleContainer/SeaBattleContainer';
import { FEATURING_PLACEHOLDER } from '~/types/contents';
import { CategoryType, FilterPosition, PRESETS } from '~/types/category';
import { useDisabledAnimationByGraphics } from '~/hooks/isDisabledAnimation';
import AccountLevelingRestictionContainer from '~/containers/AccountLevelingRestictionContainer/AccountLevelingRestictionContainer';
import AccountCompletion from '~/components/AccountCompletion/AccountCompletion';
import WowsCommerce from '@wg/wows-commerce';
import ShopUnavailable from '~/Layouts/ShopUnavailable/ShopUnavailable';
import CategoryPromoTimer from '~/Layouts/CategoryPromoTimer/CategoryPromoTimer';
import { isValidDate } from '~/utils/time';
import TradeInContainer from '../TradeInContainer/TradeInContainer';
import TradeInParentLayout from '~/Layouts/TradeIn/Layouts/TradeInParentLayout/TradeInParentLayout';
import History from '~/utils/history';
import { isBundlePurchased } from '~/utils/bundles';
import { SANTA_TITLE_IMAGE } from '~/Layouts/Themes/SantaPage/settings';
import MrpsPage from '~/Layouts/MrpsPage/MrpsPage';
import TreasureChainPage from '~/Layouts/TreasureChainPage/TreasureChainPage';
import { ScenePage } from '~/Layouts/ScenePage/ScenePage';
import { ScenePageContextProvider } from '~/Contexts/ScenePageContext';

interface ICategoryContainer {
    params: {
        categoryName: string;
        id: string | number;
    };
}

interface ICategoryContainerState {
    currentPage: ICurrentPage;
    filters: IFilters;
    filtersPresetsRecommended: number[];
    bundles: IBundleList;
    activePreset: string;
    categories: ICategories;
    featuring: IFeature[];
    activeCouponId: number;
    sortedBy: ISortCategories;
    bundleCategory: IBundleCategory;
    isStartedVideo: boolean;
    filterPosition: FilterPosition;
    WowsCommerce: WowsCommerce;
    shopUnavailable: boolean;
    mrpsEvents: MrpsEventWithUpdatedRewards[];
    treasureBoxes: TreasureMasterBox[];
}

const stateSelector = (state: State): ICategoryContainerState => {
    return {
        currentPage: state.ReducerApp.currentPage,
        bundleCategory: state.ReducerApp.bundleCategory,
        filters: state.ReducerApp.filters,
        bundles: state.ReducerApp.bundles,
        activePreset: state.ReducerApp.activePreset,
        featuring: state.ReducerApp.featuring,
        activeCouponId: state.ReducerAccount.activeCouponId,
        categories: state.ReducerApp.categories,
        sortedBy: state.ReducerApp.sortedBy,
        filtersPresetsRecommended: state.ReducerAccount.filtersPresetsRecommended,
        isStartedVideo: state.ReducerApp.isStartedVideo,
        filterPosition: state.ReducerApp.filterPosition,
        WowsCommerce: state.ReducerWSMart.wowsCommerce,
        shopUnavailable: state.ReducerWSMart.shopUnavailable,
        mrpsEvents: state.reducerMrps.events,
        treasureBoxes: state.ReducerTreasure.boxes,
    };
};

const isNeedToRemoveClientSource = (function () {
    let name: string;
    return function (categoryName: string) {
        if (name && name !== categoryName) {
            return true;
        }
        name = categoryName;
        return false;
    };
})();

const CategoryContainer = function (props: ICategoryContainer): any {
    const dispatch = useDispatch();
    const state = useSelector<State, ICategoryContainerState>(stateSelector, equal);
    const { categories } = state;

    const params = props.params;
    const categoryName = params.categoryName === CATEGORIES.FEATURED ? CATEGORIES.FEATURED : params.categoryName || CATEGORIES.FEATURED;
    const subCategoryName = params.id as ICategoryList;
    const category = (subCategoryName ? categories[subCategoryName] : categories[categoryName]) as IMenuMapItem;
    const bundles = (subCategoryName ? state.bundleCategory[subCategoryName] : state.bundleCategory[categoryName]) || [];
    const activePresetConfig = getPresetByName(category?.filters?.presets || [], state.activePreset);
    const presetWithTypeAllConfig = getPresetByType(category?.filters?.presets || [], PRESETS.ALL);

    let availableBundlesForPurchase: number[] = [];
    const alreadyPurchasedBundles: number[] = [];

    const filters = state.filters?.[category?.name as ICategoryList];

    bundles.forEach((bundleId: number) => {
        const bundle = state.bundles[bundleId];

        if (!bundle) {
            return;
        }

        const _isPurchased = isBundlePurchased(bundle);

        if (category.denyReorder || !_isPurchased) {
            availableBundlesForPurchase.push(bundle.id);
        } else {
            if (!filters?.[FILTER_DISCOUNT_NAME]?.includes(FILTER_DISCOUNT_VALUES.COUPON)) {
                alreadyPurchasedBundles.push(bundle.id);
            }
        }
    });

    const _isActiveFilters = isActiveFilters(state.filters, category?.name);

    useEffect(() => {
        setTimeout(() => {
            BundlesObserver.clear().init();
        }, 0);
    }, [availableBundlesForPurchase, alreadyPurchasedBundles, state.activePreset]);

    const checkRestrictionByCountry = () => {
        state.WowsCommerce?.checkPurchaseRestrictionByCountryMismatch();
        state.WowsCommerce?.checkPurchaseRestrictionByUserCountryIsSet(true);
    };

    useEffect(() => {
        scrollTop(0, true);

        if (!category || isCategoryDisplayRestricted(category as ICategory, state.bundles) || (typeof category?.isEnabledCategory === 'function' && !category.isEnabledCategory())) {
            History.navigate(urlsMap.home);
            dispatch(setFetching(false));
            return;
        }

        if (category?.theme !== SINGLE_BUNDLE_PAGE) {
            dispatch(changeViewBackground(null, getCategoryBackground(subCategoryName ?? categoryName)));
        }

        if (category.isContainBundlesForReal) {
            checkRestrictionByCountry();
        } else {
            if (category.subCategories) {
                const isContainBundlesForReal = category.subCategories.some((categoryName) => {
                    return categories[categoryName]?.isContainBundlesForReal;
                });
                if (isContainBundlesForReal) {
                    checkRestrictionByCountry();
                }
            }
        }

        if (category?.name) {
            dwhExport.send(DWH_EVENTS.OPEN_CATEGORY, {
                category: category.name,
            });
        }

        const titleImage = category.theme === SANTA_PAGE_THEME ? SANTA_TITLE_IMAGE : category.titleImage;

        dispatch(
            changeCurrentPage({
                title: category.title,
                titleImage: titleImage,
                name: category.name as ICategoryList,
                isBundlePage: false,
                bundleId: null,
            }),
        );

        new PreCacheImageFromCategory().addRandomBundleToCache(category.name);

        if (state.activeCouponId) {
            dispatch(resetCoupon());
        }

        if (isNeedToRemoveClientSource(category.parentCategory || category.name)) {
            dispatch(removeClientSource());
        }
    }, [categoryName, subCategoryName]);

    const categoryDataForEntryVideo = React.useMemo(() => {
        if (category.type === CategoryType.MRPS) {
            return state.mrpsEvents.find((event) => event.name === category.name);
        }

        return category;
    }, [category, state.mrpsEvents]);

    useEntryVideo(
        {
            fadeOutVideoInSeconds: 0,
            videoUrlsMap: categoryDataForEntryVideo?.entryVideo,
            isAllowedVideoForMobile: false,
            categoryName: categoryDataForEntryVideo?.name,
            isViewAccountSpecific: false,
        },
        [categoryDataForEntryVideo?.name],
    );

    useDisabledAnimationByGraphics(category?.name);

    const [, update] = React.useState(Date.now());

    if (category?.type === CategoryType.PREMIUM && state.shopUnavailable) {
        return <ShopUnavailable />;
    }

    if (category.promoTimer) {
        const isValidTimer = isValidDate(category.promoTimer.timerActiveTill);
        const hasBundles = !!category.bundles.length;
        if (!hasBundles || (hasBundles && isValidTimer)) {
            return (
                <CategoryPromoTimer
                    category={category}
                    delayCompleteHandler={100}
                    onComplete={() => {
                        if (!hasBundles) {
                            return;
                        }
                        if (category.promoTimer) {
                            category.promoTimer.timerActiveTill = null;
                        }
                        update(Date.now());
                    }}
                />
            );
        }
    }

    if (category && category.type === CategoryType.FEATURED) {
        return (
            <>
                <AccountLevelingRestictionContainer />
                <FeaturingLayout featuring={state.featuring} key={'category_featuring'} categoryName={category.name} />
            </>
        );
    }

    if (category && category.type === CategoryType.TRADEIN && !category.subCategories?.length) {
        return <TradeInContainer />;
    }

    if (category && category.type === CategoryType.SEA_BATTLE) {
        return <SeaBattleContainer />;
    }

    if (category && category.type === CategoryType.MRPS) {
        if (!state.mrpsEvents.length) {
            openCategoryByName();
        }

        return <MrpsPage />;
    }

    if (category && category.type === CategoryType.TREASURE) {
        if (!state.treasureBoxes.length) {
            openCategoryByName();
        }

        return <TreasureChainPage />;
    }

    // if ((category && category.type === CategoryType.SCENE) || true) {
    //     return (
    //         <ScenePageContextProvider>
    //             <ScenePage />
    //         </ScenePageContextProvider>
    //     );
    // }

    const subCategories = category?.subCategories;
    if (Array.isArray(subCategories) && subCategories.length) {
        return (
            <React.Fragment>
                <AccountLevelingRestictionContainer />
                <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_main`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.MAIN} />
                {category.type === CategoryType.TRADEIN ? (
                    <TradeInParentLayout category={category} />
                ) : (
                    <SubCategoriesLayout key={`sub_category_${subCategoryName}`} categoryName={categoryName} categories={subCategories} />
                )}
                <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_bottom`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.BOTTOM} />
            </React.Fragment>
        );
    }

    if (!Array.isArray(bundles) || !category) {
        return null;
    }

    let hiddenBundlesFromPurchase = false;

    if (!availableBundlesForPurchase.length && alreadyPurchasedBundles.length && !_isActiveFilters) {
        hiddenBundlesFromPurchase = true;
    }

    if (_isActiveFilters) {
        availableBundlesForPurchase = availableBundlesForPurchase.filter((bundleId: number) => {
            const bundle: IBundle = state.bundles[+bundleId];
            return !!(!bundle.serialPurchase || (bundle.serialPurchase && bundle.serialSequence?.length));
        });
    }

    if (isThemeEnabled(category.theme)) {
        return <ThemeManager theme={category?.theme as ALLOWED_THEMES} bundles={bundles} category={category} />;
    }

    const isHiddenLayoutIfEmptyBundlesFromFilters = _isActiveFilters && !availableBundlesForPurchase.length && !!alreadyPurchasedBundles.length;
    let isNeedToShowCommonBundles = !hiddenBundlesFromPurchase && !isHiddenLayoutIfEmptyBundlesFromFilters;
    if (!activePresetConfig && presetWithTypeAllConfig && !_isActiveFilters) {
        isNeedToShowCommonBundles = false;
    }

    return (
        <ContentLayoutDecoration theme={category.theme as ALLOWED_THEMES} categoryName={category.name as ICategoryList}>
            <AccountLevelingRestictionContainer />
            <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_main`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.MAIN} />
            {category.isContainBundlesForReal && <AccountCompletion />}
            {!_isActiveFilters && !state.activePreset && <PresetsLayouts categoryName={category.name as ICategoryList} />}
            {isNeedToShowCommonBundles && (
                <DefaultLayout
                    key={`category_${categoryName}_${activePresetConfig?.name || 'no_preset'}`}
                    bundles={availableBundlesForPurchase}
                    activityCountdown={category.activityCountdown}
                    activeTill={category.activeTill}
                    isLazy={!subCategoryName}
                    title={!activePresetConfig ? presetWithTypeAllConfig?.title : null}
                    description={activePresetConfig && activePresetConfig.description}
                    isNotRenderFilter
                />
            )}
            <FeaturingLayout featuring={state.featuring} key={`${category.name}_featuring_bottom`} categoryName={category.name} placeholder={FEATURING_PLACEHOLDER.BOTTOM} />
            {!!alreadyPurchasedBundles.length && <DefaultLayout title={t('Получено')} key={`category_${categoryName}_already_purchased`} bundles={alreadyPurchasedBundles} isNotRenderFilter isLazy />}
        </ContentLayoutDecoration>
    );
};

export default CategoryContainer;
