import { get, post } from '~/utils/ajax';
import { settings } from '~/utils/settings';

export const getAvailableGifts = async () => {
    return await get(settings.urls.getGifts);
};

export const takeGiftFromApi = async (id: number) => {
    const url = settings.urls.obtainGift || '';
    return await post(url.replace('{}', id.toString()));
};
