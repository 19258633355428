import * as React from 'react';
import styles from './TradeInItemWidget.scss';
import classNames from 'classnames';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import Price from '~/components/Price/Price';
import { ITradeItem } from '~/types/tradein';
import { PresentationStyles, items } from '@wg/wows-entities/const';
import { DivTooltip } from '@wg/wows-react-uikit';
import Purchased from '~/components/Purchased/Purchased';
import { playCardClickSound } from '~/api/WoWsClient';
import PreCacheImage from '~/utils/preCacheImage';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { t } from '~/utils/localization';

interface ITradeInItemWidget {
    className?: string;
    item?: ITradeItem;
    isTrade?: boolean;
    isSelected?: boolean;
    onClose?: () => void;
    onClick?: () => void;
    withoutHover?: boolean;
    isRareItem?: boolean;
    isNotClickable?: boolean;
    withTooltip?: boolean;
    showPurchasedInfo?: boolean;
    price?: {
        oldPrice?: number;
        price: number;
        currency: string;
    };
}

const TradeInItemWidget = ({ className, onClick, onClose, price, item, isTrade, isSelected, withoutHover, isNotClickable, isRareItem, withTooltip, showPurchasedInfo }: ITradeInItemWidget) => {
    const close = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClose();
    };

    const onClickHandler = () => {
        playCardClickSound();
        onClick?.();
    };

    React.useEffect(() => {
        if (item?.images?.big) {
            PreCacheImage.add(item.images.big);
        }
    }, []);

    const _withoutHover = withoutHover || isNotClickable;

    const classesItem = classNames(
        styles.item,
        {
            [styles.empty]: !item,
            [styles.selected]: isSelected,
            [styles.withoutHover]: _withoutHover,
            [styles.rare]: isRareItem,
        },
        styles[item?.type],
        className,
    );

    const icon = item?.additionalData?.images?.contour || item?.additionalData?.images?.small;
    const iconStyles: React.CSSProperties = !!icon && { backgroundImage: `url(${icon})` };

    const nationStyles: React.CSSProperties = {
        backgroundImage: `url(${item?.additionalData?.nation?.icons?.default})`,
    };

    return (
        <div className={classesItem} onClick={() => !isNotClickable && onClickHandler()}>
            {item?.type === items.VEHICLES && <div className={styles.nation} style={nationStyles} />}
            {isRareItem && <span className={styles.rareBorder}></span>}
            <div className={styles.content}>
                {!item ? (
                    <div className={styles.emptyContour} />
                ) : (
                    <>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                <WoWSEntity type={item.type} id={item.identifier} presentation={{ renderWithoutFade: true, hideNationIcon: true, withShortText: true, withTooltip }} />
                            </div>
                            {onClose && !isNotClickable && <div className={styles.close} onClick={close} />}
                        </div>
                        <div className={classNames(styles.icon, styles[item.type])} style={iconStyles} />
                        <div className={styles.footer}>
                            {isTrade ? (
                                <DivTooltip tooltipBody={<DefaultTooltip text={t('Размер скидки')} />} className={styles.tradePrice}>
                                    <Price currency={price.currency} amount={price.price} className={styles.price} withoutAnimation withoutTooltip />
                                </DivTooltip>
                            ) : (
                                <div className={styles.prices}>
                                    {price.oldPrice && <Price currency={price.currency} amount={price.oldPrice} className={styles.oldPrice} isOld withoutAnimation withoutIcon withoutTooltip />}
                                    <Price currency={price.currency} amount={price.price} className={styles.price} withoutAnimation />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            {showPurchasedInfo && (
                <div className={styles.received}>
                    <Purchased />
                </div>
            )}
        </div>
    );
};

export default TradeInItemWidget;
