import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import { State } from '~/Reducers';
import { CURRENCY } from '~/const';
import { formatNumber } from '~/utils/utils';
import { interpolate, t } from '~/utils/localization';
import { Currencies, PresentationStyles } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { DivTooltip, Interpolate } from '@wg/wows-react-uikit';
import FastGoldLinkContainer, { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import MrpsQuantity, { MrpsQuantityRef } from '~/components/Mrps/MrpsQuantity/MrpsQuantity';

import styles from './MrpsResourceBlockItem.scss';

interface MrpsPriceBlockItemProps {
    resource: MrpsResource;
}

interface StateSelector {
    resourceBalance: number;
}

function stateSelector(state: State, resource: MrpsResource): StateSelector {
    return {
        resourceBalance: state.ReducerAccount?.balance?.find((data) => data.currency === resource.name)?.value || 0,
    };
}

export default function MrpsResourceBlockItem({ resource }: MrpsPriceBlockItemProps) {
    const { resourceBalance } = useSelector<State, StateSelector>((state) => stateSelector(state, resource), shallowEqual);

    const [isDisabled, setIsDisabled] = React.useState(resourceBalance < resource.amountPerPoint);
    const quantityRef = React.useRef<MrpsQuantityRef>(null);

    const onDisableChangeHandler = React.useCallback((flag: boolean) => {
        setIsDisabled(flag);
    }, []);

    const baseResourceLimit = Math.min(resource.amountLimit || Infinity, (100 / resource.baseProgressPoints) * resource.amountPerPoint);
    const maxProgressByBaseLimit = Math.floor(baseResourceLimit / resource.amountPerPoint) * resource.baseProgressPoints;
    const accountResourceLimit = Math.floor(resourceBalance / resource.amountPerPoint) * resource.amountPerPoint;
    const maxProgressByAccountLimit = Math.min(accountResourceLimit / resource.amountPerPoint) * resource.baseProgressPoints;

    const isGold = resource.name === Currencies.GOLD;
    const isPremiumCurrency = resource.name === Currencies.GOLD || resource.name === CURRENCY.WARSHIPS_PREMIUM;

    const informerTooltipHeader = t('Заполнение прогресса');
    const informerText = t('Вы сможете заполнить шкалу прогресса  на %(percent)s%, если используете %(resource)s');
    const informerTooltipBody = (
        <Interpolate
            className={classNames(styles.infoTooltipText, {
                [styles.infoTooltipText_gold]: isPremiumCurrency,
            })}
            str={informerText}
            percent={maxProgressByBaseLimit}
            resource={
                <WoWSEntity
                    type={resource.name}
                    amount={baseResourceLimit}
                    presentation={{
                        style: PresentationStyles.INLINE,
                    }}
                />
            }
        />
    );

    const onResourceClick = React.useCallback(() => {
        if (!isDisabled) {
            quantityRef.current?.increment?.();
        }
    }, [isDisabled]);

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_disabled]: isDisabled,
            })}
        >
            <DivTooltip
                tooltipBody={
                    <div className={styles.infoTooltipWrapper}>
                        <div className={styles.infoTooltipHeader}>{informerTooltipHeader}</div>
                        <hr className={styles.divider} />
                        <div className={styles.infoTooltipBody}>{informerTooltipBody}</div>
                    </div>
                }
            >
                <div className={styles.infoIcon} />
            </DivTooltip>
            <button className={styles.resourceInfoWrapper} onClick={onResourceClick}>
                <WoWSEntity
                    className={styles.resourceImage}
                    type={resource.name}
                    presentation={{
                        withText: false,
                        style: PresentationStyles.WIDGET,
                    }}
                />
                <div className={styles.resourceInfo}>
                    <WoWSEntity
                        className={classNames(styles.resourceInfoText, {
                            [styles.resourceInfoText_gold]: isPremiumCurrency,
                        })}
                        type={resource.name}
                        amount={resource.amountPerPoint}
                    />
                    <span className={styles.equal}>=</span>
                    <span className={styles.percents}>
                        {interpolate(t('{percent}%'), {
                            percent: formatNumber(resource.baseProgressPoints),
                        })}
                    </span>
                </div>
            </button>
            <div className={styles.inputResource}>
                <MrpsQuantity resource={resource} ref={quantityRef} disableChangeCallback={onDisableChangeHandler} />
                {isGold && maxProgressByAccountLimit < Math.min(maxProgressByBaseLimit, 100) && (
                    <div className={styles.fastGoldWrapper}>
                        <FastGoldLinkContainer goldShortage={resource.amountLimit} place={FAST_GOLD_PLACE.MRPS_VIEW_PAGE} withIcon />
                    </div>
                )}
            </div>
        </div>
    );
}
