import { ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY } from '~/const';

export const prepareInventory = (_inventory: Inventory): InventoryState => {
    const inventory: InventoryState = Object.assign({}, _inventory, {
        emptySlots: _inventory.empty_slots,
        uniqueItems: _inventory.unique_items,
        itemsStorage: _inventory.items_storage,
    });
    return inventory;
};

export const getValueFromInventory = (inventory: InventoryState, type: string, id?: number) => {
    const _type = ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY[type as keyof typeof ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY] || type;
    return +inventory?.[_type as keyof InventoryState] || +inventory?.storage?.[id];
};
