import ReducerApp, { AppState } from '~/Reducers/ReducerApp';
import ReducerAccount, { AccountState } from '~/Reducers/ReducerAccount';
import ReducerAuction, { AuctionState } from '~/Reducers/ReducerAuction';
import ReducerWSMart, { WSMartState } from '~/Reducers/ReducerWSMart';
import ReducerLootbox, { LootboxState } from '~/Reducers/ReducerLootbox';
import ReducerSeaBattle, { SeaBattleState } from '~/Reducers/ReducerSeaBattle';
import ReducerTradeIn, { TradeInState } from '~/Reducers/ReducerTradeIn';
import reducerMrps, { MrpsState } from '~/Reducers/ReducerMrps';
import { combineReducers } from 'redux';
import ReducerTreasure, { TreasureState } from '~/Reducers/ReducerTreasure';

const reducers = {
    ReducerApp,
    ReducerAccount,
    ReducerAuction,
    ReducerWSMart,
    ReducerLootbox,
    ReducerSeaBattle,
    ReducerTradeIn,
    reducerMrps,
    ReducerTreasure,
};

export interface State {
    ReducerApp: AppState;
    ReducerAccount: AccountState;
    ReducerAuction: AuctionState;
    ReducerWSMart: WSMartState;
    ReducerLootbox: LootboxState;
    ReducerSeaBattle: SeaBattleState;
    ReducerTradeIn: TradeInState;
    reducerMrps: MrpsState;
    ReducerTreasure: TreasureState;
}

export default combineReducers(reducers);
