import { clearString, normalizeString } from '~/utils/normalization';
import { ManagerData, SectionNames } from '~/core/ManagerData';

export const onSearch = (value: string, items: ILootboxStateItemGroupRewards<ILootboxRewardGroup>) => {
    const availableItems = JSON.parse(JSON.stringify(items));
    value = normalizeString(clearString(value)).toLowerCase();

    Object.keys(availableItems).forEach((key) => {
        availableItems[key].rewards = availableItems[key].rewards.filter((item: ILootboxReward) => {
            const preparedTitle = normalizeString(clearString(item.additionalData?.title)).toLowerCase();
            const preparedShipTitle = normalizeString(clearString(item.additionalData?.ship?.title))?.toLowerCase();
            return preparedTitle.includes(value) || (preparedShipTitle && preparedShipTitle.includes(value));
        });
    });

    return availableItems;
};

export const scrollToGroup = (group: string, behavior: ScrollBehavior = 'smooth') => {
    const target = document.querySelectorAll(`[data-category-title="lootbox_reward_group_${group}"]`)[0] as HTMLDivElement;
    target && target.scrollIntoView({ behavior, block: 'start' });
};

export const getTypeIcon = (typeName: any, shipData: any) => {
    const shipClass = ManagerData.getDataBySection(SectionNames.class, 'name')?.[typeName];
    let typeIcon = shipClass?.icons?.default;
    if (shipData?.isPremium) {
        typeIcon = shipClass?.icons?.premium;
    } else if (shipData?.isSpecial) {
        typeIcon = shipClass?.icons?.special;
    }
    return typeIcon;
};
