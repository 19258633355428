import * as React from 'react';
import styles from './Tooltip.scss';
import ShortBundleContains from '~/components/Bundle/Contains/ShortBundleContains';

class IItemsContainsTooltip {
    bundle: IBundle;
    limit?: number;
}

export const ItemsContainsTooltip = (props: IItemsContainsTooltip): React.ReactChild | any => {
    return (
        <div className={styles.defaultTooltip}>
            <ShortBundleContains bundle={props.bundle} className={styles.defaultTooltipBody} hiddenVoButton limit={props.limit} />
        </div>
    );
};
