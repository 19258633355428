import { playCrewRandomVOSample } from '@wg/web2clientapi/sound';
import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import * as React from 'react';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { t } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import styles from './CrewVoiceIconButton.scss';

interface ICrewVoiceIconButton {
    crewId: number;
    className?: string;
}

const CrewVoiceIconButton = ({ crewId, className }: ICrewVoiceIconButton) => {
    const [isAnimate, setAnimate] = React.useState(false);
    const timer = React.useRef<NodeJS.Timeout>(null);

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setAnimate(false);
        }, 1500);
        setAnimate(true);
        dwhExport.send(DWH_EVENTS.PLAY_CREW_SAMPLE_VO, { crew_id: crewId });
        playCrewRandomVOSample(+crewId);
    };

    return (
        <DivTooltip
            className={classNames(styles.button, className, {
                [styles.animate]: isAnimate,
            })}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
            tooltipBody={<DefaultTooltip text={t('Прослушать случайную реплику')} />}
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </DivTooltip>
    );
};

export default CrewVoiceIconButton;
