import { ThunkAction } from 'redux-thunk';
import { State } from '~/Reducers';
import {
    IResetMrpsCurrentEvent,
    ISaveMrpsAccountData,
    ISaveMrpsEvents,
    ISpendMrpsResource,
    ITogggleExchangeMenu,
    IToggleActiveExchangeTransaction,
    IToggleNextRenderWithoutReset,
    IUpdateMrpsEventsRewards,
    IUpdateMrpsInfoBlockContent,
    RESET_MRPS_CURRENT_EVENT,
    SAVE_MRSP_ACCOUNT_DATA,
    SAVE_MRSP_EVENTS,
    SPEND_MRPS_RESOURCE,
    TOGGLE_ACTIVE_EXCHANGE_TRANSACTION,
    TOGGLE_EXCHANGE_MENU,
    TOGGLE_NEXT_RENDER_WITHOUT_RESET_STATE,
    UPDATE_MRPS_EVENTS_REWARDS,
    UPDATE_MRPS_INFO_BLOCK_CONTENT,
} from '~/Actions/ActionMrpsType';
import { getProgressForResources } from '~/utils/mrps';
import { blurBackground } from './ActionApp';
import { IBlurBackground } from './ActionAppType';

export function saveMrpsEvents(events: MrpsEvent[]): ISaveMrpsEvents {
    return {
        type: SAVE_MRSP_EVENTS,
        events,
    };
}

export function saveMrpsAccountData(accountData: MrpsAccountData): ISaveMrpsAccountData {
    const account: MrpsAccountState = {};
    if (!accountData.progress || !accountData.resourcesSpent) return;
    Object.keys(accountData.progress).forEach((eventName) => {
        account[eventName] = {
            progress: accountData.progress[eventName],
            resourcesSpent: accountData.resourcesSpent[eventName],
            rewards: accountData.rewards[eventName],
        };
    });

    return {
        type: SAVE_MRSP_ACCOUNT_DATA,
        account,
    };
}

export function updateMrpsAccountData(accountData: MrpsAccountData): ThunkAction<void, State, any, ISaveMrpsAccountData> {
    return (dispatch, getState) => {
        if (!accountData.progress || !accountData.resourcesSpent) return;
        const currentPage = getState().ReducerApp.currentPage;
        const currentAccountState = getState().reducerMrps.account;

        let needToResetCurrentMrpsPage: boolean = false;

        const newAccountData: MrpsAccountState = {};

        Object.keys(accountData.progress).forEach((eventName) => {
            const currentAccountEventProgress = currentAccountState?.[eventName].progress || 0;
            if (currentAccountEventProgress !== accountData.progress[eventName] && eventName === currentPage.name) {
                needToResetCurrentMrpsPage = true;
            }

            newAccountData[eventName] = {
                progress: accountData.progress[eventName],
                resourcesSpent: accountData.resourcesSpent[eventName],
                rewards: accountData.rewards[eventName],
            };
        });

        dispatch({
            type: SAVE_MRSP_ACCOUNT_DATA,
            account: newAccountData,
        });

        if (needToResetCurrentMrpsPage) {
            dispatch(resetMrpsCurrentEvent(currentPage.name, accountData.progress[currentPage.name] !== 100));
        }
    };
}

export function resetMrpsCurrentEvent(eventName: MrpsEvent['name'], onlyResources: boolean = false): ThunkAction<void, State, any, IResetMrpsCurrentEvent | IBlurBackground> {
    return (dispatch, getState) => {
        const mrpsState = getState().reducerMrps;
        const accountEventData = mrpsState.account?.[eventName];
        const eventData = mrpsState.events.find((event) => event.name === eventName);

        const resourcesInUse = eventData?.currencies?.reduce<Record<string, number>>((acc, currency) => {
            acc[currency.name] = 0;
            return acc;
        }, {});

        const currentEvent: MrpsCurrentEventState = {
            eventName,
            baseProgress: accountEventData?.progress || 0,
            currentProgress: accountEventData?.progress || 0,
            resourcesInUse,
            isActiveExchangeTransaction: false,
            data: {
                availableRewards: [],
                currentInfo: accountEventData?.rewards.length ? eventData.rewards.at(-1) : undefined,
                isExchangeMenuOpen: onlyResources ? mrpsState.currentEvent.data.isExchangeMenuOpen : 2,
            },
        };

        dispatch({
            type: RESET_MRPS_CURRENT_EVENT,
            currentEvent,
        });

        if (accountEventData?.progress === 100) {
            dispatch(blurBackground(2));
        }
    };
}

export function updateEventsRewards(updatedEvents: MrpsEventWithUpdatedRewards[]): IUpdateMrpsEventsRewards {
    return {
        type: UPDATE_MRPS_EVENTS_REWARDS,
        updatedEvents: updatedEvents,
    };
}

export function spendResource(currencyName: string, amount: number): ThunkAction<void, State, any, ISpendMrpsResource> {
    return (dispatch, getState) => {
        const mrpsState = getState().reducerMrps;

        const currentEvent = mrpsState.currentEvent;
        const eventName = currentEvent.eventName;

        const currentEventSettings = mrpsState.events.find((event) => event.name === eventName);
        const currencySettings = currentEventSettings?.currencies.find((currency) => currency.name === currencyName);
        if (!currencySettings || currencySettings.amountLimit < amount || amount % currencySettings.amountPerPoint) return;

        const resourcesInUse = { ...currentEvent.resourcesInUse };
        resourcesInUse[currencyName] = amount;

        const additionalProgress = getProgressForResources(eventName, resourcesInUse);
        const currentProgress = currentEvent.baseProgress + additionalProgress;
        const availableRewards = currentEventSettings.rewards.filter(
            (reward) => reward.requiredProgressPoints <= currentProgress && reward.requiredProgressPoints > mrpsState.currentEvent.baseProgress,
        );

        dispatch({
            type: SPEND_MRPS_RESOURCE,
            currentProgress,
            resourcesSpent: resourcesInUse,
            availableRewards,
        });
    };
}

export function updateCurrentInfo(newInfo: MrpsLoadedReward): IUpdateMrpsInfoBlockContent {
    return {
        type: UPDATE_MRPS_INFO_BLOCK_CONTENT,
        currentInfo: newInfo,
    };
}

export function toggleExchangeMenu(newValue: MrpsExchangeMenuStatus): ITogggleExchangeMenu {
    return {
        type: TOGGLE_EXCHANGE_MENU,
        isExchangeMenuOpen: newValue,
    };
}

export function toggleActiveExchangeTransaction(newValue: boolean): IToggleActiveExchangeTransaction {
    return {
        type: TOGGLE_ACTIVE_EXCHANGE_TRANSACTION,
        hasPendingTransactions: newValue,
    };
}

export function toggleNextRenderWithoutReset(newValue: boolean): IToggleNextRenderWithoutReset {
    return {
        type: TOGGLE_NEXT_RENDER_WITHOUT_RESET_STATE,
        isNextRenderWithoutReset: newValue,
    };
}
