import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import equal from 'fast-deep-equal';
import { State } from '~/Reducers';
import { UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES, UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES, updateCustomPageBackgroundSizes } from '~/Actions/ActionApp';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { DEFAULT_ASPECT_RATIO, MOBILE_BREAK_POINT_HEIGHT, MOBILE_BREAK_POINT_WIDTH } from '../utils';

import styles from './CustomBackground.scss';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import useMobile from '~/hooks/useMobile';
import classNames from 'classnames';
import { getNotification, getNotificationToShowIfNeeded } from '~/components/Notifications/NotificationManager';
import { filterNotifications } from '~/utils/notifications';

interface CustomBackgroundProps {
    popupData?: {
        bundle?: IBundle;
        bundleId?: IBundle['id'];
    };
}

interface StateSelector {
    bundles?: IBundleList;
    currentPage?: ICurrentPage;
    categories?: ICategories;
    customPageSettings?: State['ReducerApp']['customPageSettings'];
    isTrusted?: boolean;
    //* For background blur
    popupActive?: IPopup;
    port?: IPort;
    clientSource?: ClientSource_Type;
    currentNotificationVisibleName?: string;
    notifications?: INotification[];
    isVisibleRewardPopup?: boolean;
}

function stateSelector(state: State): StateSelector {
    return {
        bundles: state.ReducerApp.bundles,
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
        customPageSettings: state.ReducerApp.customPageSettings,
        isTrusted: !!state.ReducerApp.isTrusted,
        //* For background blur
        popupActive: state.ReducerApp.popupActive,
        port: state.ReducerApp.port,
        clientSource: state.ReducerApp.clientSource,
        currentNotificationVisibleName: state.ReducerApp.currentNotificationVisibleName,
        notifications: state.ReducerAccount.notifications,
        isVisibleRewardPopup: state.ReducerAccount.rewards?.isVisible,
    };
}

export function CustomBackground({ popupData = {} }: CustomBackgroundProps) {
    const dispatch = useDispatch();
    const { bundles, categories, currentPage, customPageSettings, isTrusted, popupActive, port, clientSource, currentNotificationVisibleName, notifications, isVisibleRewardPopup } = useSelector<
        State,
        StateSelector
    >(stateSelector, equal);
    const { currentSizes, naturalSizes } = customPageSettings?.background;
    const categoryData = React.useMemo(() => categories?.[currentPage?.name], [categories?.[currentPage?.name]]);
    const bundleData = React.useMemo(() => {
        return (popupData?.bundle ? popupData?.bundle : bundles?.[popupData?.bundleId]) || ({} as Partial<IBundle>);
    }, []);

    const [canShowBackground, setCanShowBackground] = React.useState(false);
    const [needShowMask, setNeedShowMask] = React.useState(false);
    const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>();

    const backgroundSrc = React.useMemo(() => {
        return bundleData?.icons?.confirmationBackground || categoryData.background;
    }, [bundleData, categoryData.background]);

    const backgroundAspectRatio = React.useMemo(() => {
        return naturalSizes.width / naturalSizes.height || DEFAULT_ASPECT_RATIO;
    }, [naturalSizes.height, naturalSizes.width]);

    const onReasizeCallback = React.useCallback(() => {
        const clientWidth = window.document.documentElement.clientWidth;
        const clientHeight = window.document.documentElement.clientHeight;

        if (clientWidth < MOBILE_BREAK_POINT_WIDTH || clientHeight < MOBILE_BREAK_POINT_HEIGHT) {
            setNeedShowMask(true);
            dispatch(
                updateCustomPageBackgroundSizes(
                    {
                        width: clientHeight * backgroundAspectRatio,
                        height: clientHeight,
                    },
                    UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES,
                ),
            );
        } else {
            const nextBackgroundHeight = naturalSizes.height ? Math.min(clientHeight, naturalSizes.height) : clientHeight;
            const nextBackgroundWidth = nextBackgroundHeight * backgroundAspectRatio;

            if (nextBackgroundWidth < clientWidth || nextBackgroundWidth > clientWidth || nextBackgroundHeight < clientHeight) {
                setNeedShowMask(true);
            } else {
                setNeedShowMask(false);
            }

            dispatch(
                updateCustomPageBackgroundSizes(
                    {
                        width: nextBackgroundWidth,
                        height: nextBackgroundHeight,
                    },
                    UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES,
                ),
            );
        }
    }, [backgroundAspectRatio, naturalSizes.height]);

    const onBackgroundLoadCallback = React.useCallback<(this: HTMLImageElement, ev: Event) => void>(function (_event: Event) {
        dispatch(
            updateCustomPageBackgroundSizes(
                {
                    width: this.naturalWidth,
                    height: this.naturalHeight,
                },
                UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES,
            ),
        );
    }, []);

    //* Add resize handler
    React.useEffect(() => {
        window.addEventListener('resize', onReasizeCallback);

        return () => {
            window.removeEventListener('resize', onReasizeCallback);
        };
    }, [naturalSizes.height]);

    //* Get natural image size
    React.useLayoutEffect(() => {
        const backgroundImage = new Image();
        backgroundImage.addEventListener('load', onBackgroundLoadCallback);
        backgroundImage.src = backgroundSrc;

        return () => {
            backgroundImage.removeEventListener('load', onBackgroundLoadCallback);
        };
    }, [backgroundSrc]);

    //* Resize after background download
    React.useLayoutEffect(() => {
        onReasizeCallback();
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => setCanShowBackground(true), 150);

        return () => {
            timerRef.current && clearTimeout(timerRef.current);
        };
    }, [naturalSizes]);

    const fakeBackgroundStyles: React.CSSProperties = React.useMemo(() => {
        return {
            backgroundColor: categoryData.backgroundColor,
        };
    }, []);

    const backgroundStyles: React.CSSProperties = React.useMemo(() => {
        return {
            backgroundImage: `url(${backgroundSrc})`,
            backgroundColor: bundleData?.backgroundColor || categoryData?.backgroundColor || `black`,
            backgroundSize: `${currentSizes.width}px ${currentSizes.height}px`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
        };
    }, [bundleData, categoryData.background, categoryData.backgroundColor, currentSizes.height, currentSizes.width]);

    const videoBackgroundStyles: React.CSSProperties = React.useMemo(() => {
        return {
            width: `${currentSizes.width}px`,
            height: `${currentSizes.height}px`,
        };
    }, [currentSizes.height, currentSizes.width]);

    const backgroundMaskStyles: React.CSSProperties = React.useMemo(() => {
        return {
            maskSize: `${currentSizes.width}px ${currentSizes.height}px`,
            backgroundColor: bundleData?.backgroundColor || categoryData?.backgroundColor || 'black',
        };
    }, [currentSizes.width, currentSizes.height]);

    const backgroundCustomMaskStyles: React.CSSProperties = React.useMemo(() => {
        return {
            backgroundImage: categoryData.backgroundMask ? `url(${categoryData.backgroundMask})` : undefined,
            backgroundSize: `${currentSizes.width * 1.22}px ${currentSizes.height * 1.27}px`,
        };
    }, [currentSizes.width, currentSizes.height, categoryData.backgroundMask]);

    //* Videobackground setting
    const isAvailableSoundEffect = useAvailableSound(categoryData, null);
    const isDisabledAnimation = useIsDisabledAnimation(currentPage.name);
    const [isMobile] = useMobile();

    if (!categoryData) {
        return null;
    }

    let muted = !isAvailableSoundEffect;
    if (!isTrusted) {
        muted = true;
    }

    const videoBackground = bundleData?.confirmationVideoBackground || categoryData?.videoBackground;
    const videoPoster = bundleData?.icons?.confirmationBackground || categoryData?.background;

    //* Background blur logic
    const isVisiblePopup = !!popupActive;
    const availableInfoScreenName = getNotificationToShowIfNeeded();
    const infoScreenName = !isVisiblePopup && !port.isVisible && !clientSource ? availableInfoScreenName ?? currentNotificationVisibleName : null;
    const filteredNotifications = filterNotifications(notifications);
    const isAvalaibleNotification = !!filteredNotifications?.find((notification) => notification.name === infoScreenName);
    const blurFlag =
        (isVisiblePopup || (getNotification(infoScreenName?.toString()).display === 'popup' && isAvalaibleNotification) || isVisibleRewardPopup) &&
        !popupActive?.data?.isNoBlur &&
        !(popupData.bundle || popupData.bundleId);

    return canShowBackground ? (
        <div
            style={backgroundStyles}
            className={classNames(styles.backgroundWrapper, {
                [styles.backgroundWrapper_inPopup]: !!(popupData.bundle || popupData.bundleId),
                [styles.backgroundWrapper_blur]: !!blurFlag,
            })}
        >
            {videoBackground && !isMobile && !isDisabledAnimation ? (
                <div className={styles.videoBackgroundWrapper} style={videoBackgroundStyles}>
                    <VideoBackground
                        key={videoBackground.webm}
                        poster={videoPoster}
                        video={videoBackground}
                        className={styles.videoBackground}
                        posterClassName={styles.videoBackground}
                        muted={muted}
                        volume={0.5}
                        isInPopup={!!popupData}
                    />
                </div>
            ) : null}
            {needShowMask && (
                <>
                    <div style={backgroundMaskStyles} className={styles.backgroundMask} />
                    {categoryData.backgroundMask ? <div className={styles.backgroundCustomMask} style={backgroundCustomMaskStyles} /> : null}
                </>
            )}
        </div>
    ) : (
        <div className={styles.fakeBackgroundWrapper} style={fakeBackgroundStyles} />
    );
}
