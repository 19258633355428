import * as React from 'react';
import styles from './Checkbox.scss';
import classNames from 'classnames';
import { playCheckboxClickSound } from '~/api/WoWsClient';

interface ICheckbox {
    label?: string | React.ReactChild;
    onChange: (isChecked: boolean) => void;
    isDisabled?: boolean;
    isChecked?: boolean;
    className?: string;
}

const Checkbox = ({ label, onChange, isChecked, isDisabled, className }: ICheckbox) => {
    const [checked, setChecked] = React.useState<boolean>(isChecked);

    const classesLabel = classNames(styles.label, className);

    const classesWrapper = classNames(styles.wrapper, {
        [styles.checked]: checked,
        [styles.disabled]: isDisabled,
    });

    const classesCheckbox = classNames(styles.checkbox, {
        [styles.checked]: checked,
    });

    const onClick = () => {
        if (isDisabled) {
            return;
        }

        playCheckboxClickSound();

        setChecked(!checked);
        onChange?.(!checked);
    };

    React.useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    return (
        <div className={classesWrapper} onClick={onClick}>
            <div className={classesCheckbox} />
            {label && <div className={classesLabel}>{label}</div>}
        </div>
    );
};

export default Checkbox;
