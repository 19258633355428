import * as React from 'react';
import { isMobileOrTabletWindow, updateFlagMobileOrTablet } from '~/utils/utils';
import { MOBILE_RESOLUTION } from '~/const';

export default function (): [boolean] {
    const [isMobile, setMobile] = React.useState(isMobileOrTabletWindow);

    const resizeCallback = React.useCallback(() => {
        const flag = window.document.documentElement.clientWidth <= MOBILE_RESOLUTION;
        updateFlagMobileOrTablet(flag);
        setMobile(flag);
    }, []);

    React.useEffect(() => {
        window.addEventListener('resize', resizeCallback);

        return () => {
            window.removeEventListener('resize', resizeCallback);
        };
    }, []);

    return [isMobile];
}
