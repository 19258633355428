import * as React from 'react';

const useMatchMedia = (query: string, onUpdate: (this: MediaQueryList, ev: MediaQueryListEvent) => any): void => {
    const matcher = window.matchMedia(query);

    React.useEffect(() => {
        matcher.addEventListener('change', onUpdate);

        return () => {
            matcher.removeEventListener('change', onUpdate);
        };
    });
};

export default useMatchMedia;
