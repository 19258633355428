import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './Coupon.scss';
import currency_styles from '~/components/Currency/Currency.scss';
import classNames from 'classnames';
import { getParsedTime } from '~/utils/time';
import { interpolate, t } from '~/utils/localization';
import Currency from '~/components/Currency/Currency';
import { getCurrenciesByCategoryName } from '~/utils/category';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { assets } from '@wg/wows-entities/const';
import { calculateBundlePrices } from '~/utils/purchase';
import { formatNumber, localizedDateTime, localizedDateWithFormat } from '~/utils/utils';
import { playPaperCardClickSound } from '~/api/WoWsClient';
import { getCurrency } from '~/utils/currencies';
import CountDown from '~/components/CountDown/CountDown';

export enum CouponSize {
    SMALL = 'small',
    BIG = 'big',
}

interface ICouponProps {
    coupon: ICoupon;
    size?: CouponSize;
    onClick?: (event: React.MouseEvent) => void;
    shownCurrencies?: boolean;
    usePriceWithDiscount?: boolean;
    bundle?: IBundle;
    isActive?: boolean;
    showCurrencyDescriptionTooltip?: boolean;
    hiddenCounter?: boolean;
    disableHover?: boolean;
}

interface IStateSelector {
    categories: ICategories;
    bundles: IBundleList;
    balance: IBalance;
    coupons: ICoupon[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
        bundles: state.ReducerApp.bundles,
        balance: state.ReducerAccount.balance,
        coupons: state.ReducerAccount.coupons,
    };
};

const Coupon = (props: ICouponProps): any => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const coupon = props.coupon;
    const couponCategory = state.categories[coupon.category];

    const couponBackground =
        (coupon.icon && {
            backgroundImage: `url(${coupon.icon})`,
        }) ||
        {};

    const couponLeftBackground = coupon.backgroundLeft
        ? {
              backgroundImage: `url(${coupon.backgroundLeft})`,
          }
        : {};

    const couponRightBackground = coupon.backgroundRight
        ? {
              backgroundImage: `url(${coupon.backgroundRight})`,
          }
        : {};

    const startAt = new Date(coupon.startAt).getTime();
    const validityPeriod = coupon.validityPeriod * 1000;
    const nextPeriodTime = new Date(startAt + validityPeriod).getTime();
    const nextPeriodDate = new Date(nextPeriodTime);
    const prevPeriodDate = coupon.prevStartAt ? new Date(coupon.prevStartAt + validityPeriod) : new Date(nextPeriodDate);
    const parsedTime = getParsedTime(Math.floor((nextPeriodTime - Date.now()) / 1000));

    const categoryCurrencies =
        getCurrenciesByCategoryName(state.bundles, state.categories, props.coupon.category)?.filter((item) => {
            return !props.coupon.deniedCurrencies.includes(item);
        }) || [];

    const hasGoldCurrency = categoryCurrencies.includes(assets.GOLD);
    const isExpiring = parsedTime.days <= 0;

    const couponClassNames = classNames(
        styles.coupon,
        {
            [styles.cursor]: !!props.onClick,
            [styles.extra]: !coupon.isProlongable,
            [styles.goldCoupon]: hasGoldCurrency,
            [styles.disableHover]: props.disableHover,
        },
        styles[props.size],
        'armory__auto--coupon',
    );

    const isSeveralCoupons = !props.hiddenCounter && coupon.counter > 1;

    const couponAboutClassNames = classNames(styles.couponAbout, {
        [styles.gold]: hasGoldCurrency,
        [styles.extra]: !coupon.isProlongable,
        [styles.isActive]: props.isActive,
        [styles.disabled]: coupon.isDisabled,
        [styles.severalCoupons]: isSeveralCoupons,
    });

    const couponDiscountBackClassNames = classNames(styles.couponDiscountBack, {
        [styles.gold]: hasGoldCurrency,
        [styles.extra]: !coupon.isProlongable,
        [styles.isActive]: props.isActive,
        [styles.disabled]: coupon.isDisabled,
        [styles.severalCoupons]: isSeveralCoupons,
    });

    const tooltipStyles = { cursor: 'pointer' };

    let _calculatePrice: any;
    if (props.usePriceWithDiscount) {
        _calculatePrice = calculateBundlePrices(props.bundle, state.balance, 1, coupon?.discount);
    }

    const discount = parseInt((coupon.discount * 100).toString(), 10);

    let tooltipText = t('Новый купон будет доступен %(date)s');
    if (!coupon.isProlongable) {
        tooltipText = t('Купон доступен для использования до: %(date)s');
    }

    const classesCouponDate = classNames(styles.couponDate, {
        [styles.unique]: !coupon.isProlongable,
        [styles.expiring]: !coupon.isProlongable && isExpiring,
    });

    const datePeriod = coupon.isDisabled ? prevPeriodDate : nextPeriodDate;

    return (
        <div
            className={couponClassNames}
            data-coupon-counter={coupon.counter}
            onClick={(event: React.MouseEvent) => {
                if (props.onClick) {
                    !props.disableHover && playPaperCardClickSound();
                    props.onClick(event);
                }
            }}
        >
            <div className={couponDiscountBackClassNames} style={couponLeftBackground}>
                <div className={styles.couponDiscount}>{interpolate(t('-{percent}%'), { percent: formatNumber(discount) })}</div>
                <div className={styles.dateContent}>
                    <DivTooltip tooltipBody={<DefaultTooltip text={tooltipText.replace('%(date)s', localizedDateTime(datePeriod))} />} style={tooltipStyles}>
                        <div className={classesCouponDate}>
                            <span>
                                {!coupon.isProlongable
                                    ? !isExpiring
                                        ? interpolate(t('{count}д'), { count: parsedTime.days })
                                        : parsedTime.hours < 1 && parsedTime.days <= 0
                                        ? interpolate(t('<{count}ч'), { count: 1 })
                                        : interpolate(t('{count}ч'), { count: parsedTime.hours })
                                    : localizedDateWithFormat(nextPeriodDate, { day: 'numeric', month: 'numeric' })}
                            </span>
                        </div>
                    </DivTooltip>
                </div>
            </div>
            <div className={couponAboutClassNames} style={couponRightBackground}>
                {!props.usePriceWithDiscount ? (
                    <React.Fragment>
                        <div className={classNames(styles.couponTitle, styles.bold)}>{coupon.title ? coupon.title : <span>{couponCategory.title}</span>}</div>
                        <DivTooltip
                            tooltipBody={<DefaultTooltip text={t('Купон действует на товары, приобретаемые за перечисленные валюты')} />}
                            className={classNames(styles.currencies)}
                            style={tooltipStyles}
                        >
                            <div className={styles.currenciesLabel}>{t('Ресурсы: ')}</div>
                            <div className={styles.currenciesBlock}>
                                {categoryCurrencies.map((currency: string) => {
                                    return <Currency key={currency} currency={currency} iconClassName={styles.iconCurrency} withoutAnimation useCustomIcon />;
                                })}
                            </div>
                        </DivTooltip>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className={styles.couponTitle}>{t('Цена с купоном')}</div>
                        <div className={classNames(styles.currenciesBlock, styles.price)}>
                            {_calculatePrice.withDiscount[props.bundle.currency] === 0 ? (
                                <div className={currency_styles.wrapper}>
                                    <div className={classNames(currency_styles.amount, [currency_styles[`currency-${props.bundle.currency}`]], styles.priceText)}>{t('Бесплатно')}</div>
                                </div>
                            ) : (
                                <Currency
                                    currency={props.bundle.currency}
                                    showDescriptionTooltip={getCurrency(props.bundle.currency)?.showDescriptionInPriceTooltip}
                                    amount={_calculatePrice.withDiscount[props.bundle.currency]}
                                    className={styles.priceText}
                                    withoutAnimation
                                    isSmallIcon
                                />
                            )}
                        </div>
                    </React.Fragment>
                )}
                <div className={classNames(styles.couponIcon, styles[`coupon_${coupon.category}`])} style={couponBackground} />
                {!props.hiddenCounter && coupon.counter > 1 && (
                    <DivTooltip className={styles.couponCounter} tooltipBody={<DefaultTooltip text={t('Количество доступных купонов')} />} style={tooltipStyles}>
                        <span className={styles.couponCounterSymbol}>×</span>
                        <span>{coupon.counter}</span>
                    </DivTooltip>
                )}
            </div>
            {coupon.isDisabled && parsedTime.days < 30 && (
                <DivTooltip tooltipBody={<DefaultTooltip text={tooltipText.replace('%(date)s', localizedDateTime(datePeriod))} />} className={styles.timerWrapper} style={tooltipStyles}>
                    <CountDown date={datePeriod.getTime()} />
                </DivTooltip>
            )}
        </div>
    );
};

export default Coupon;
