import * as React from 'react';
import classNames from 'classnames';
import Spoiler from '~/components/Spoiler/Spoiler';
import styles from '../common.scss';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items as ITEM_TYPES } from '@wg/wows-entities/const/';
import { ManagerData, SectionNames } from '~/core/ManagerData';
import { CommanderPerks } from '~/components/Commanders/Commanders';
import CrewVoiceLabel from '~/components/CrewVoiceLabel/CrewVoiceLabel';
import useLoadPrimaryItemData from '~/hooks/useLoadPrimaryItemData';
import { Crew } from '~/vortex';
import { isInGameBrowser } from '~/utils/utils';
import CrewVoiceIconButton from '~/components/CrewVoiceIconButton/CrewVoiceIconButton';

interface ICrewsSubTitleContentProps {
    item: IItemCommonData;
    renderPreviewContent?: boolean;
}

export const CrewsSubTitleContent = ({ item, renderPreviewContent }: ICrewsSubTitleContentProps) => {
    const crew = useLoadPrimaryItemData<Crew>(item?.type, item?.identifier, SectionNames.crews);
    return (
        <>
            <div className={styles.preBundleTitle}>
                <CommanderPerks item={item} />
            </div>
            {crew?.hasSampleVo && <CrewVoiceLabel className={styles.preBundleTitle} />}
            {isInGameBrowser && renderPreviewContent && crew?.hasSampleVo && (
                <div className={styles.crewPreviewButton}>
                    <CrewVoiceIconButton crewId={item.identifier} />
                </div>
            )}
        </>
    );
};

interface ICrewContent {
    item: IItemCommonData;
    renderSpoiler?: boolean;
}

const getCrewsContent = (props: ICrewContent) => {
    const crew = ManagerData.getCrew(props.item.identifier) || {};
    const isNeedToRender = !crew.talents?.length && !crew.skills?.length && !crew?.unlocks?.length;
    if (isNeedToRender) {
        return null;
    }

    const classesTitle = classNames({
        [styles.spoilerSubTitle]: props.renderSpoiler,
        [styles.contentBlockTitle]: !props.renderSpoiler,
    });

    const content = (
        <React.Fragment>
            {!!crew.talents?.length && (
                <div
                    className={classNames(styles.contentBlock, styles.borderBottom, {
                        [styles.spoilerContent]: props.renderSpoiler,
                    })}
                >
                    <div className={classesTitle}>{t('Национальные таланты: ')}</div>
                    <div className={styles.contentBlockWowSEntities}>
                        <WoWSEntity
                            type={ITEM_TYPES.CREWS_TALENTS}
                            id={props.item.identifier}
                            presentation={{
                                withTooltip: true,
                                renderWithoutFade: true,
                            }}
                        />
                    </div>
                </div>
            )}
            {!!crew.skills?.length && (
                <div
                    className={classNames(styles.contentBlock, styles.borderBottom, {
                        [styles.spoilerContent]: props.renderSpoiler,
                    })}
                >
                    <div className={classesTitle}>{t('Улучшенные навыки: ')}</div>
                    <div className={styles.contentBlockWowSEntities}>
                        <WoWSEntity
                            type={ITEM_TYPES.CREWS_SKILLS}
                            id={props.item.identifier}
                            presentation={{
                                withTooltip: true,
                                renderWithoutFade: true,
                            }}
                        />
                    </div>
                </div>
            )}
            {!!crew?.unlocks?.length && (
                <div
                    className={classNames(styles.contentBlock, styles.borderBottom, {
                        [styles.spoilerContent]: props.renderSpoiler,
                    })}
                >
                    <div className={classesTitle}>{t('Персонализация: ')}</div>
                    <div className={styles.contentBlockWowSEntities}>
                        <WoWSEntity
                            type={ITEM_TYPES.CREWS_UNLOCKS}
                            id={props.item.identifier}
                            presentation={{
                                withTooltip: true,
                                renderWithoutFade: true,
                            }}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );

    if (props.renderSpoiler) {
        return (
            <Spoiler
                title={
                    <div className={classNames(styles.contentAdditionalBlock)}>
                        <div className={styles.additionalContentTitle}>
                            <WoWSEntity
                                key={props.item.identifier}
                                type={props.item.type}
                                id={props.item.identifier}
                                className={styles.weCrewComponent}
                                presentation={{
                                    withText: true,
                                    withoutAssetIcon: true,
                                }}
                            />
                        </div>
                    </div>
                }
                content={content}
            />
        );
    }

    return content;
};

export default getCrewsContent;
