import * as React from 'react';
import styles from './Spoiler.scss';
import className from 'classnames';
import classNames from 'classnames';
import { playTabClickSound } from '~/api/WoWsClient';

interface ISpoiler {
    title: React.ReactChild;
    content: React.ReactChild | React.ReactChild[];
    onChange?: (isVisible: boolean) => void;
    titleClassName?: string;
    wrapperClassName?: string;
    isOpened?: boolean;
}

const Spoiler = (props: ISpoiler) => {
    const [isVisible, setVisible] = React.useState(props.isOpened);

    const changeVisible = () => {
        playTabClickSound();
        setVisible(!isVisible);
        props.onChange && props.onChange(!isVisible);
    };

    const titleClassNames = className(
        styles.title,
        {
            [styles.active]: isVisible,
        },
        props.titleClassName,
    );

    const contentClassNames = className(styles.content, {
        [styles.active]: isVisible,
    });

    return (
        <div className={classNames(styles.wrapper, props.wrapperClassName)}>
            <div className={titleClassNames} onClick={changeVisible}>
                {props.title}
            </div>
            <div className={contentClassNames}>{props.content}</div>
        </div>
    );
};

export default Spoiler;
