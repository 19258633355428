import * as React from 'react';
import { useDispatch } from 'react-redux';
import { scrollMobileContainer } from '~/utils/scrollTop';
import { changeCurrentPage, hiddenMobileNavigate } from '~/Actions/ActionApp';
import ConfirmPurchase from '~/components/Popups/Purchase/ConfirmPurchase';
import { useParams } from 'react-router-dom';

export const PURCHASE_POPUP_CONTAINER = 'purchase-popup';

const ConfirmPurchaseContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    React.useEffect(() => {
        scrollMobileContainer({ top: 0 });

        dispatch(hiddenMobileNavigate(true));
        dispatch(
            changeCurrentPage({
                name: PURCHASE_POPUP_CONTAINER,
                isBundlePage: false,
            }),
        );

        return () => {
            dispatch(hiddenMobileNavigate(false));
        };
    }, []);

    return <ConfirmPurchase bundleId={Number(id)} />;
};

export default ConfirmPurchaseContainer;
