import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import { BANNER_LINK_TYPE, FEATURING_DESTINATION } from '~/types/contents';
import { isDisplayRestricted } from '~/utils/bundles';
import { tzNaiveToUtcDate } from '~/utils/time';

export const getCategoryFeaturedTimerForMenu = (categoryName: string, featuring: IFeature[]): ITimerData | null => {
    if (!featuring) {
        return null;
    }

    const contentBlock = featuring.reduce((timerBlock: Partial<IContentBlock>, featuringBlock) => {
        if (featuringBlock.destination === FEATURING_DESTINATION.CATEGORY && featuringBlock.destinationId === categoryName) {
            if (!timerBlock) {
                featuringBlock.contentBlocks.forEach((content) => {
                    if (content.type === FEATURING_TEMPLATES.TIMER && !(content.data as IFeaturedTimer)?.hideFromMenu && content.activeTill) {
                        timerBlock = content;
                    }
                });
            }
        }
        return timerBlock;
    }, null);

    if (!contentBlock) {
        return null;
    }

    const activeTill = new Date(contentBlock.activeTill);

    return !!activeTill.getTime() ? { activeTill: activeTill, title: contentBlock?.data?.title } : null;
};

export const isDisplayAllowedForBanner = (bannerData: BANNERS, bundles: IBundleList, categories: ICategories) => {
    if (bannerData.linkType === BANNER_LINK_TYPE.BUNDLE) {
        const bundle = bundles[bannerData.linkValue];
        return bundle && !isDisplayRestricted(bundle);
    } else if (bannerData.linkType === BANNER_LINK_TYPE.CATEGORY) {
        return !!categories[bannerData.linkValue];
    }

    return true;
};

export const prepareFeaturing = (bundles: IBundleList, categories: ICategories): IFeature[] => {
    const featuring = [...(window.metashop?.state?.content?.featuring || [])] as IFeature[];

    featuring.map((item) => {
        const contentBlocks: IContentBlock[] = [];

        item.contentBlocks.map((contentBlock) => {
            if (isDisplayRestricted(contentBlock)) {
                return;
            }

            if (contentBlock.type === FEATURING_TEMPLATES.BANNER_BLOCK || contentBlock.type === FEATURING_TEMPLATES.BANNER_BAR || contentBlock.type === FEATURING_TEMPLATES.ACCORDION) {
                if (!isDisplayAllowedForBanner(contentBlock.data as BANNERS, bundles, categories)) {
                    return;
                }
            }

            // uglyhack, backend returns tz naive dates for featuring content blocks
            if (contentBlock.activeTill) {
                const activeTill = tzNaiveToUtcDate(new Date(contentBlock.activeTill));
                if (!!activeTill?.getTime()) {
                    contentBlock.activeTill = activeTill.toISOString();
                }
            }
            contentBlocks.push(contentBlock);
        });

        item.contentBlocks = contentBlocks;
    });

    return featuring;
};
