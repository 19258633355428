import * as React from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../assets/styles/default_popups.scss';
import classNames from 'classnames';
import { FadeInDiv } from '~/components/Transitions';
import { Button, OrangeButton } from '~/components/Button/Buttons';
import { interpolate, t } from '~/utils/localization';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import useClickAway from '~/hooks/useClickAway';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { disableBodyScroll as addHiddenScrollToBody } from '~/utils/dom';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import { LOCAL_STORAGE_ACTIVATE_ACCOUNT_POPUP } from '~/utils/keys';
import Account from '~/account/Account';
import { LocalStorageHelper } from '~/utils/storage';
import { isAvailableRebrandingForCIS, settings } from '~/utils/settings';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

export const getActivateAccountLSKey = (): string => LOCAL_STORAGE_ACTIVATE_ACCOUNT_POPUP.replace('{}', String(Account.getAccount()?.id));

const DownloadGamePopup = (): React.ReactElement => {
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);

    const dispatch = useDispatch();
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const refDescription: React.RefObject<HTMLDivElement> = React.useRef(null);

    const close = () => {
        addHiddenScrollToBody(false);
        clearAllBodyScrollLocks();
        LocalStorageHelper.set(getActivateAccountLSKey(), true);
        dispatch(changeVisiblePopup(null));
        !!popup.data?.onClose && popup.data.onClose();
    };

    const click = () => {
        close();
        window.open(settings.urls.downloadClient[settings.realm], '_blank');
    };

    useClickAway(
        ref,
        () => {
            if (!isPopupActive) {
                return;
            }
            close();
        },
        [isPopupActive],
    );

    useMaskScrollEffect(refDescription);

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close.bind(this)();
    }, [KEYS_CODE.ESC]);

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            addHiddenScrollToBody(true);
            disableBodyScroll(document.querySelectorAll(`.${styles.popup}`)[0]);
        }
    }, []);

    const descriptionText = isAvailableRebrandingForCIS()
        ? t(
              'Для совершения покупок и получения подарков в Адмиралтействе необходимо хотя бы один раз зайти в игру. {block}Для этого: {list}— Скачайте и установите игру{end_list}{list}— Войдите в игру, используя свой аккаунт{end_list}{end_block}',
          )
        : t(
              'Для совершения покупок и получения подарков в Адмиралтействе необходимо хотя бы один раз зайти в игру. {block}Для этого: {list}— Скачайте и установите World of Warships{end_list}{list}— Войдите в игру, используя свой аккаунт{end_list}{end_block}',
          );

    const description = interpolate(descriptionText, {
        block: `<div class="${styles.descriptionBlock}">`,
        end_block: `</div>`,
        list: `<span>`,
        end_list: `</span>`,
    });

    return (
        <div className={styles.wrapper}>
            <FadeInDiv className={styles.popup} ref={ref}>
                <div className={classNames(styles.popupClose)} onClick={close} />
                <div className={classNames(styles.popupImage, styles.activateAccount)} />
                <div className={styles.about}>
                    <div className={styles.title}>{t('Требуется активация аккаунта')}</div>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} ref={refDescription} />
                    <div className={styles.footer}>
                        <OrangeButton className={classNames(styles.popupButton)} label={t('Скачать игру')} onClick={click} />
                        <Button label={t('Закрыть')} onClick={close} />
                    </div>
                </div>
            </FadeInDiv>
        </div>
    );
};

export default DownloadGamePopup;
