import React from 'react';

import { AdditionalFeatures, MainFeatures } from '~/Layouts/BundlePage/Contents/ShipsContent';
import ShipConsumables from '~/components/ShipConsumables/ShipConsumables';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import { ShipDescription } from '~/components/ShipDescription/ShipDescription';
import { CopyrightContent } from '~/components/Popups/Purchase/AdditionalContent';

import styles from './PortContentBlockTabContent.scss';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import { ShipWeapons } from '~/components/ShipWeapons/ShipWeapons';

interface PortContentBlockTabContentProps {
    type: PortContentBlockTabType;
    shipId?: string | number;
    containsItems?: Array<IContainsItem>;
}

export enum PortContentBlockTabType {
    SHIP_INFO = 'о корабле',
    SHIP_FEATURES = 'особенности',
    CONTAINS = 'состав',
}

function getTabContent(type: PortContentBlockTabType, shipId?: string | number, containsItems?: Array<IContainsItem>): React.ReactNode {
    switch (type) {
        case PortContentBlockTabType.SHIP_INFO:
            if (!shipId) return null;

            return (
                <div className={styles.descriptionWrapper}>
                    <ShipDescription shipId={shipId} withShipTitle wrapperClassName={styles.descriptionWrapper__shipDescription} />
                </div>
            );

        case PortContentBlockTabType.SHIP_FEATURES:
            if (!shipId) return null;

            return (
                <div className={styles.featuresWrapper}>
                    <MainFeatures shipId={Number(shipId)} className={styles.featuresWrapper__main} />
                    <AdditionalFeatures shipId={Number(shipId)} className={styles.featuresWrapper__additional} />
                    <ShipWeapons shipId={Number(shipId)} className={styles.featuresWrapper__weapons} />
                    <ShipConsumables shipId={Number(shipId)} />
                </div>
            );

        case PortContentBlockTabType.CONTAINS:
            if (!containsItems?.length) return null;

            return (
                <div className={styles.containsWrapper}>
                    <Contains items={containsItems} isLarge={true} />
                    <div className={styles.containsCopyright}>
                        <CopyrightContent isNeedToHideDisclaimerAppearance />
                    </div>
                </div>
            );

        default:
            return null;
    }
}

export function PortContentBlockTabContent({ type, shipId, containsItems }: PortContentBlockTabContentProps) {
    const wrapperRef = React.useRef(null);

    const content = getTabContent(type, shipId, containsItems);

    useMaskScrollEffect(wrapperRef);

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            {content}
        </div>
    );
}
