import * as React from 'react';
import styles from './CloseButton.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';

interface ICloseButton {
    onClick: (event: React.MouseEvent) => void;
    className?: string;
}

const CloseButton = (props: ICloseButton) => {
    return (
        <div className={classNames(styles.button, props.className)} onClick={props.onClick}>
            {t('Закрыть')}
        </div>
    );
};

export default CloseButton;
