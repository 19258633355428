import { Actions } from '~/Actions';
import { excludeCouponsForNotExistingCategories, prepareCouponsFromState } from '~/utils/coupons';
import { prepareNotifications } from '~/utils/notifications';
import { IGuideStep } from '~/components/WelcomePage/steps';
import { prepareInventory } from '~/utils/inventory';
import { prepareBalanceFromState } from '~/utils/balance';
import {
    ACCOUNT_INITIALIZE,
    ACCOUNT_UPDATE_STATE,
    ACTIVATE_COUPON,
    ADD_BUNDLE_IN_TRANSACTION,
    ADD_GUIDE_STEP,
    CLOSE_RANDOM_BUNDLE,
    COMPLETE_ACTION,
    DENIED_BUNDLES_BY_UNIQUE_ITEMS,
    GIFT_STATUS,
    HIDE_GUIDE_STEP,
    INIT_GUIDE_STEPS,
    MARK_LABEL_AS_READ,
    MARK_REWARD_AS_VIEWED,
    OPEN_BUNDLE,
    PURCHASE_BUNDLE,
    PURCHASE_RANDOM_BUNDLE,
    PUSH_REWARDS_TO_STACK,
    REMOVE_ACTIVATED_COUPON,
    REMOVE_BUNDLE_IN_TRANSACTION,
    REMOVE_PURCHASING_BUNDLE_ID,
    RESET_COUPON,
    SET_ASK_CONFIRMATION_PURCHASE,
    SET_PURCHASING_BUNDLE_ID,
    UNSHIFT_GUIDE_STEP,
    SET_NOTIFICATIONS,
    UPDATE_BALANCE,
    UPDATE_COUPONS_FROM_STATE,
    UPDATE_VIEW_NOTIFICATION,
    SET_USER_ADULT_FLAG,
    SET_INVENTORY,
    SET_STORAGE,
    SET_COUPONS,
    SET_USER_PC_PERFORMANCE,
} from '~/Actions/ActionAccount';
import { FilterPosition } from '~/types/category';

export interface AccountState {
    id?: number;
    balance?: IBalance;
    coupons?: ICoupon[];
    activeCouponId?: number;
    prevActiveCouponId?: number;
    activeCouponFromBundle?: number;
    deniedBundlesByUniqueItems?: number[];
    bundlesInTransaction?: number[];
    randomBundlesHistory?: AccountRandomBundleHistory;
    completedActions?: string[];
    selectedRandomBundles?: AccountSelectedRandomBundles;
    purchasedLimitedBundles?: AccountPurchasedLimitedBundles;
    inventory?: InventoryState;
    purchasingBundleId?: number;
    usedCoupons?: AccountUsedCoupons;
    viewedNotifications?: string[];
    notifications?: INotification[];
    viewedLabels?: string[];
    isNoAskForConfirmationPurchase?: boolean;
    openedRandomBundles?: number[];
    guideSteps?: IGuideStep[];
    filterPosition?: FilterPosition;
    filtersPresetsRecommended?: number[];
    restrictedLootboxesPurchaseCount?: AccountRestrictedLootboxesPurchaseCount;
    isLowPcPerformance?: boolean;
    ts?: number;
    isAdult?: boolean;
    gifts?: IGifts;
    rewards?: {
        isVisible: boolean;
        rewards: IReward[];
    };
}

const contentState = window.metashop.state?.content;
const accountState = window.metashop.state?.account;

export const initialAccountState: AccountState = !!accountState?.id
    ? {
          activeCouponId: null,
          prevActiveCouponId: null,
          activeCouponFromBundle: null,
          purchasingBundleId: null,
          inventory: null,
      }
    : {};

const ReducerAccount = (state: AccountState = initialAccountState, action: Actions) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE:
            return {
                ...state,
                id: accountState.id,
                // @ts-ignore
                balance: prepareBalanceFromState(accountState.balance),
                coupons: [] as ICoupon[],
                deniedBundlesByUniqueItems: accountState.deniedBundlesByUniqueItems || [],
                purchasedLimitedBundles: accountState.purchasedLimitedBundles || {},
                usedCoupons: accountState.usedCoupons,
                bundlesInTransaction: accountState.bundlesInTransaction,
                randomBundlesHistory: accountState.randomBundlesHistory,
                openedRandomBundles: accountState.openedRandomBundles,
                selectedRandomBundles: accountState.selectedRandomBundles,
                completedActions: accountState.completedActions,
                viewedNotifications: accountState.viewedNotifications || [],
                viewedLabels: accountState.viewedLabels || [],
                filterPosition: accountState.filtersSettings?.position || FilterPosition.TOP,
                filtersPresetsRecommended: accountState.filtersPresetsRecommended,
                restrictedLootboxesPurchaseCount: accountState.restrictedLootboxesPurchaseCount,
                isAdult: accountState.isAdult,
            };

        case SET_COUPONS: {
            return {
                ...state,
                coupons: prepareCouponsFromState(excludeCouponsForNotExistingCategories(action.coupons, action.categories), state.usedCoupons, true),
            };
        }

        case SET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: prepareNotifications(action.notifications, action.categories, action.bundles),
            };
        }

        case OPEN_BUNDLE:
            return {
                ...state,
                openedRandomBundles: [...(state.openedRandomBundles || []), action.bundleId],
            };

        case ACTIVATE_COUPON:
            return {
                ...state,
                activeCouponId: action.id,
                prevActiveCouponId: action.id,
                activeCouponFromBundle: action.bundleId,
            };

        case RESET_COUPON:
            return {
                ...state,
                activeCouponId: null,
                activeCouponFromBundle: null,
                prevActiveCouponId: null,
            };

        case ACCOUNT_UPDATE_STATE:
            return {
                ...state,
                ...action.data,
                coupons: action.coupons,
                purchasedLimitedBundles: {
                    ...state.purchasedLimitedBundles,
                    ...action.data.purchasedLimitedBundles,
                },
                deniedBundlesByUniqueItems: [...new Set([...state.deniedBundlesByUniqueItems, ...action.data.deniedBundlesByUniqueItems])],
            };

        case UPDATE_BALANCE:
            return {
                ...state,
                balance: action.balance,
            };

        case REMOVE_ACTIVATED_COUPON:
            return {
                ...state,
                activeCouponId: null,
            };

        case PURCHASE_BUNDLE:
            const purchasedLimitedBundles = {
                ...state.purchasedLimitedBundles,
                [action.id]: state.purchasedLimitedBundles[action.id] ? state.purchasedLimitedBundles[action.id] + action.quantity : 1,
            };

            return {
                ...state,
                purchasedLimitedBundles,
            };

        case DENIED_BUNDLES_BY_UNIQUE_ITEMS:
            return {
                ...state,
                deniedBundlesByUniqueItems: [...new Set([...state.deniedBundlesByUniqueItems, action.bundleId])],
            };

        case PURCHASE_RANDOM_BUNDLE:
            return {
                ...state,
                selectedRandomBundles: {
                    ...state.selectedRandomBundles,
                    [action.bundleId]: action.newSelectedBundleId,
                },
            };

        case UPDATE_VIEW_NOTIFICATION:
            return {
                ...state,
                viewedNotifications: [...(state.viewedNotifications || []), action.name],
            };

        case SET_ASK_CONFIRMATION_PURCHASE:
            return {
                ...state,
                isNoAskForConfirmationPurchase: action.value,
            };

        case ADD_BUNDLE_IN_TRANSACTION:
            return {
                ...state,
                bundlesInTransaction: [...(state.bundlesInTransaction || []), ...action.bundleIds],
            };

        case REMOVE_BUNDLE_IN_TRANSACTION:
            const bundlesInTransaction = state.bundlesInTransaction?.filter((id) => id !== action.bundleId) || [];
            return {
                ...state,
                bundlesInTransaction: bundlesInTransaction,
            };

        case COMPLETE_ACTION:
            return {
                ...state,
                completedActions: [...state.completedActions, action.action],
            };

        case UPDATE_COUPONS_FROM_STATE:
            return {
                ...state,
                coupons: prepareCouponsFromState(state.coupons, state.usedCoupons),
            };

        case SET_PURCHASING_BUNDLE_ID:
            return {
                ...state,
                purchasingBundleId: action.bundleId,
            };

        case REMOVE_PURCHASING_BUNDLE_ID:
            return {
                ...state,
                purchasingBundleId: null,
            };

        case CLOSE_RANDOM_BUNDLE:
            return {
                ...state,
                selectedRandomBundles: {
                    ...(state.selectedRandomBundles || {}),
                    [action.bundleId]: null,
                },
            };

        case MARK_LABEL_AS_READ:
            return {
                ...state,
                viewedLabels: [...(state.viewedLabels || []), action.name],
            };

        case GIFT_STATUS:
            return {
                ...state,
                gifts: {
                    status: action.status,
                    bundles: action.gifts,
                    secretBundles: action.secretGifts,
                },
            };

        case PUSH_REWARDS_TO_STACK: {
            const rewards = [...(state.rewards?.rewards || []), ...(action.rewards || [])];
            const isVisible = !!rewards.length;
            return {
                ...state,
                rewards: {
                    isVisible,
                    rewards,
                },
            };
        }

        case MARK_REWARD_AS_VIEWED: {
            const rewards =
                state.rewards?.rewards?.filter((reward) => {
                    return reward.id !== action.reward.id;
                }) || [];

            return {
                ...state,
                rewards: {
                    isVisible: !!rewards?.length,
                    rewards,
                },
            };
        }

        case INIT_GUIDE_STEPS:
            return {
                ...state,
                guideSteps: action.guideSteps,
            };

        case UNSHIFT_GUIDE_STEP: {
            const isAlreadyExists = state.guideSteps?.filter((step) => step.name === action.step?.name)[0];
            if (isAlreadyExists) {
                return state;
            }

            return {
                ...state,
                guideSteps: [action.step, ...(state.guideSteps || [])],
            };
        }

        case ADD_GUIDE_STEP: {
            const isAlreadyExists = state.guideSteps?.filter((step) => step.name === action.step?.name)[0];
            if (isAlreadyExists) {
                return state;
            }

            return {
                ...state,
                guideSteps: [...(state.guideSteps || []), action.step],
            };
        }

        case HIDE_GUIDE_STEP: {
            const steps = state.guideSteps?.filter?.((step) => step.name !== action.step.name);

            return {
                ...state,
                guideSteps: steps,
            };
        }

        case SET_USER_ADULT_FLAG:
            return {
                ...state,
                isAdult: !state.isAdult,
            };

        case SET_INVENTORY:
            return {
                ...state,
                inventory: prepareInventory(action.inventory),
            };

        case SET_STORAGE:
            return {
                ...state,
                inventory: {
                    ...(state.inventory || {}),
                    storage: action.storage,
                },
            };

        case SET_USER_PC_PERFORMANCE:
            return {
                ...state,
                isLowPcPerformance: action.isLow,
            };

        default:
            return { ...state };
    }
};

export default ReducerAccount;
