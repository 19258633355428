import styles from './PostProgress.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { TreasureRewardItem } from '~/components/TreasureChain/TreasureInfoBlock/TreasureRewardsSlider/TreasureReward/TreasureReward';
import { isMobileOrTabletWindow } from '~/utils/utils';
import HelperIcon from '~/components/HelperIcon/HelperIcon';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { DivTooltip } from '@wg/wows-react-uikit';
import Info from '~/components/Info/Info';
import React from 'react';

type Props = {
    reward: TreasureLoadedReward;
    inventory: InventoryState;
    background?: string;
};

export default function PostProgress({ reward, inventory, background }: Props) {
    const postProgressTitle = t('Постпрогресс');
    const postProgressDescription = t('Если вам выпадет группа, в которой вы уже забрали все награды, вы получите награду Постпрогресса');
    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.item, styles.informer)}>
                <div className={styles.informer__title}>{postProgressTitle}</div>
                <DivTooltip className={styles.informer__description} tooltipBody={<DefaultTooltip text={t(postProgressDescription)} />}>
                    <Info color={'white'} />
                </DivTooltip>
            </div>
            <div className={styles.item}>
                <TreasureRewardItem rewardData={reward} inventory={inventory} withAmount withoutPopup={isMobileOrTabletWindow} background={background} />
            </div>
        </div>
    );
}
