import { getCDNUrl, getStaticPathFromClientOrWeb } from '~/utils/utils';

export type VIDEO_NAMES = 'submarine-promo' | 'auction_promo_default_video' | 'tier_11';

type VideoMap_Type = {
    [key in VIDEO_NAMES]?: string;
};

export const AUCTION_PROMO_DEFAULT_VIDEO = 'auction_promo_default_video';
export const TIER_11_PREVIEW_VIDEO = 'tier_11';

export const VIDEO_MAP: VideoMap_Type = {
    [AUCTION_PROMO_DEFAULT_VIDEO]: getStaticPathFromClientOrWeb({
        cdn: getCDNUrl('armory/0109/auction_promo.webm'),
        client: 'armory/auction_promo.webm',
    }),
    [TIER_11_PREVIEW_VIDEO]: getCDNUrl('armory/01010/T11.webm'),
};
