import React from 'react';

import { ShipData } from '~/types/entities';
import { getShipData } from '~/utils/getShipData';

import styles from './ShipDescription.scss';
import classNames from 'classnames';

interface ShipDescriptionProps {
    shipId: string | number;
    withShipTitle?: boolean;

    wrapperClassName?: string;
}

export function ShipDescription({ shipId, withShipTitle, wrapperClassName }: ShipDescriptionProps) {
    const [shipData, setShipData] = React.useState<ShipData | null>(null);

    React.useEffect(() => {
        getShipData(shipId).then(setShipData);
    }, [shipId]);

    if (!shipData) return null;

    return (
        <div className={classNames(styles.wrapper, wrapperClassName)}>
            {withShipTitle ? <div className={styles.title}>{shipData.title}</div> : null}
            <div className={styles.text}>{shipData.description}</div>
        </div>
    );
}
