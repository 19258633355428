import * as React from 'react';
import { useDispatch } from 'react-redux';

import store from '~/Store';
import { changeVisiblePopup, hidePopup } from '~/Actions/ActionApp';
import Account from '~/account/Account';
import { openPortByDefaultItem } from '~/settings/port';
import dwhExport from '~/api/dwhExport';
import { playButtonClickSound } from '~/api/WoWsClient';
import { playCrewRandomVOSample } from '@wg/web2clientapi/sound';
import { CrewTypes, items, PresentationStyles } from '@wg/wows-entities/const';
import { DWH_EVENTS } from '~/const';

import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import { POPUPS_NAME } from '~/components/PopupManager';
import { rewardHasSampleVo, isEnabledPortPreviewForReward } from '~/utils/rewardPreview';

export enum RewardItemPlaces {
    POPUP = 'popup',
    LIST = 'list',
}

interface IItemCard {
    reward: ILootboxReward;
    className?: string;
    inventory?: InventoryState;
    hideInventoryWidget?: boolean;
    place?: RewardItemPlaces;
    isUniqueGroup?: boolean;
    bundleId: number;
    lootboxId: number;
    goToPortHandler?: () => void;
    onRender?: () => void;
    isValuableLootboxReward?: boolean;
    theme?: ALLOWED_THEMES;
}

const LootboxRewardItemCard = ({ reward, className, isUniqueGroup, bundleId, goToPortHandler, lootboxId, onRender, isValuableLootboxReward, theme }: IItemCard) => {
    const dispatch = useDispatch();
    const { additionalData } = reward;
    const [isExistsInInventory, inventoryCount] = Account.getInventoryInfoByType(reward.type as keyof Inventory, reward.id, reward.shipId);
    const isEnabledPortPreview = isEnabledPortPreviewForReward(reward);
    const hasSampleVo = rewardHasSampleVo(reward);
    const isNotUniqueReward = !isUniqueGroup;

    const additionalCrew = reward?.additionalData?.defaultCrew;
    const isAdditionalCrewVisible = additionalCrew && additionalCrew?.type !== CrewTypes.COMMON;

    const previewInPort = () => {
        window?.tooltipProvider?.hide?.();

        dispatch(hidePopup());
        openPortByDefaultItem(
            {
                identifier: reward.id,
                shipId: +additionalData.ship?.id,
                type: reward.type,
            },
            false,
        );
        goToPortHandler?.();
    };

    const previewVoice = () => {
        const crewId = isAdditionalCrewVisible ? +reward.defaultCrew : +reward.id;
        if (!crewId) return;
        playCrewRandomVOSample(crewId);
        dwhExport.send(DWH_EVENTS.PLAY_CREW_SAMPLE_VO, { crew_id: crewId });
    };

    const isPreviewEnabled = isEnabledPortPreview || hasSampleVo;

    const DescriptionPopupWrapper = ({ children }: { children: any }) => {
        if (reward.type === items.LOOTBOX) {
            const onClick = () => {
                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.ITEM_DESCRIPTION_POPUP, true, {
                        item: {
                            identifier: reward.id,
                            type: reward.type,
                        },
                        autoDescriptionSettings: {},
                        theme,
                    }),
                );
            };
            return <div onClick={onClick}>{children}</div>;
        }

        return children;
    };

    function preview(event: React.MouseEvent) {
        if (!isPreviewEnabled) return;
        playButtonClickSound();
        if ((event.target as HTMLElement).closest('.we-js-preview__voice') && hasSampleVo) {
            return previewVoice();
        }
        if (isEnabledPortPreview) return previewInPort();
    }

    return (
        <DescriptionPopupWrapper>
            <WoWSEntity
                className={className}
                type={reward.type}
                id={reward.id}
                presentation={{
                    withTooltip: true,
                    style: PresentationStyles.CARD,
                    showPreviewElement: isPreviewEnabled,
                }}
                customisation={{
                    shipId: reward.shipId,
                    crewId: isAdditionalCrewVisible && reward.defaultCrew,
                    points: reward.crewLevel,
                    isExistsInInventory: !isNotUniqueReward && isExistsInInventory,
                    inventoryCount: isNotUniqueReward ? inventoryCount : 0,
                    isValuableLootboxReward: isValuableLootboxReward,
                }}
                onClick={preview}
                onRender={onRender}
            />
        </DescriptionPopupWrapper>
    );
};

export default LootboxRewardItemCard;
