require('intersection-observer');

if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement: any, fromIndex: any) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            const o = Object(this);

            const len = o.length >>> 0;

            if (len === 0) {
                return false;
            }

            const n = fromIndex | 0;

            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            function sameValueZero(x: any, y: any) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }

            while (k < len) {
                if (sameValueZero(o[k], searchElement)) {
                    return true;
                }
                k++;
            }

            return false;
        },
    });
}

if (!Number.isFloat) {
    Number.isFloat = (n: number) => Number(n) === n && n % 1 !== 0;
}

if (!Array.prototype.at) {
    Object.defineProperty(Array.prototype, 'at', {
        value: function (index: number) {
            index = Math.trunc(index) || 0;
            if (index < 0) {
                index += this.length;
            }
            if (index < 0 || index >= this.length) {
                return undefined;
            }
            return this[index];
        },
        writable: true,
        enumerable: false,
        configurable: true,
    });
}

if (!Number.getCountNumbersAfterComma) {
    Number.getCountNumbersAfterComma = (n: number) => {
        const arr = n.toString().split('.');
        if (arr.length <= 1) {
            return 0;
        }
        return arr.pop().split('').length;
    };
}
