import { ISaveSeaBattleSettings } from './ActionSeaBattleType';
import { Dispatch } from 'react';
import { settings } from '~/utils/settings';
import { IHiddenMenuItem, IShowMenuItem } from '~/Actions/ActionAppType';
import { ThunkAction } from 'redux-thunk';
import { State } from '~/Reducers';
import { Actions } from '~/Actions';
import { CATEGORIES } from '~/const';

export const SAVE_SEA_BATTLE_SETTINGS = 'SAVE_SEA_BATTLE_SETTINGS';
export const saveSeaBattleSettings = (isError = false, isEnabled = false, isActivatedUser = false, hasActiveSeason = false): ISaveSeaBattleSettings => {
    return {
        type: SAVE_SEA_BATTLE_SETTINGS,
        isError,
        isEnabled,
        isActivatedUser,
        hasActiveSeason,
    };
};

export const loadSeaBattleSettings = (): ThunkAction<void, State, unknown, Actions> => {
    return async (dispatch: Dispatch<ISaveSeaBattleSettings | IHiddenMenuItem | IShowMenuItem>, getState): Promise<void> => {
        const settingsUrl = settings.urls.seaBattleSettings;
        const hasActiveCategory = !!getState()?.ReducerApp?.menu?.[CATEGORIES.SEA_BATTLE];
        if (!settingsUrl || !hasActiveCategory) {
            dispatch(saveSeaBattleSettings(true));
            return;
        }
        const { loadSettings } = await import('@wg/seabattle/settings');
        const seaBattleSettings = await loadSettings(settingsUrl, { languageCode: settings.languageCode });

        dispatch(saveSeaBattleSettings(!seaBattleSettings.isEnabled, seaBattleSettings.isEnabled, seaBattleSettings.isActivatedUser, seaBattleSettings.hasActiveSeason));
    };
};
