import * as React from 'react';
import styles from './RandomBundleHistoryCarousel.scss';
import classNames from 'classnames';

interface IRandomBundleShinyAnimation {
    isEnabled: boolean;
    shinyAnimation: RandomBundleShinyAnimationType;
}

const RandomBundleShinyAnimation = ({ isEnabled, shinyAnimation }: IRandomBundleShinyAnimation) => {
    const image = shinyAnimation?.image;
    const animationStyles = image ? ({ '--shiny-image': `url(${image})` } as React.CSSProperties) : {};

    return (
        <>
            {isEnabled && (
                <div className={styles.animationWrapper}>
                    <div className={classNames(styles.shinyAnimation, { [styles.withoutAnimation]: !!image })} style={animationStyles} />
                </div>
            )}
        </>
    );
};

export default RandomBundleShinyAnimation;
