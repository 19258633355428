import * as React from 'react';
import styles from './MobilePurchaseWrapper.scss';
import classNames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { AccountState } from '~/Reducers/ReducerAccount';
import { AppState } from '~/Reducers/ReducerApp';
import Account from '~/account/Account';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import { t } from '~/utils/localization';
import { Button } from '~/components/Button/Buttons';
import { getRandomBundleChild, getSerialBundlesById, isRandomBundleOpeningEnabled, isFreeBundle, isPeriodicBundle } from '~/utils/bundles';
import { openBundleById, openBundleByUrl } from '~/utils/category';
import PurchaseButton from '~/components/Button/PurchaseButton';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { BundlePurchaseTypes } from '~/types/bundle';
import Attention from '../Attention/Attention';
import { getLevelingRestrictionForBundle, getLevelingRestrictionTextForBundle } from '~/utils/levelingResctrictions';
import RealPurchaseButton from '../Button/RealPurchaseButton';

interface IMobilePurchaseWrapper {
    bundle: IBundle;
}

const MobilePurchaseWrapper = (props: IMobilePurchaseWrapper) => {
    const account = useSelector((state: State): AccountState => state.ReducerAccount, shallowEqual);
    const appState = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);
    const activeCoupon = Account.getActiveCoupon(account.activeCouponId, account.coupons);

    if (props.bundle.isPurchased) {
        return (
            <div className={styles.purchaseMobileWrapper} id="MobilePurchaseWrapper">
                <PurchasedItem bundle={props.bundle} className={styles.background} />
            </div>
        );
    }

    const _isDisabledSerialBundle = Account.isDisabledSerialBundle(account.purchasedLimitedBundles, props.bundle);

    if ((props.bundle.serialPurchase && _isDisabledSerialBundle) || (props.bundle.serialPurchase && !account.id && !props.bundle.serialSequence?.length)) {
        const serialSequence = getSerialBundlesById(appState.bundles, props.bundle.id).map((bundle) => bundle.id);
        const nextAvailableFromPurchaseSerialBundle = Account.getNextAvailableFromPurchaseSerialBundle(account.deniedBundlesByUniqueItems, account.purchasedLimitedBundles, serialSequence);
        const openAvailableBundle = () => {
            if (nextAvailableFromPurchaseSerialBundle) {
                openBundleByUrl(appState.currentPage?.name, nextAvailableFromPurchaseSerialBundle);
            } else if (serialSequence?.[0]) {
                openBundleById(serialSequence?.[0]);
            }
        };

        return (
            <div className={styles.purchaseMobileWrapper} id="MobilePurchaseWrapper">
                <div className={classNames(styles.wrapper)}>
                    <div className={styles.priceWrapperTitle}>{t('Цена:')}</div>
                    <PriceWrapper bundle={props.bundle} />
                    <Button label={t('Перейти к доступному для приобретения')} className={styles.buttonFullWidth} onClick={openAvailableBundle} isFull />
                </div>
            </div>
        );
    }

    const openPurchasePopup = () => {
        const bundle = appState.bundles[props.bundle.id];
        const purchaseProcessor = new PurchaseProcessor(bundle);

        if (isFreeBundle(bundle)) {
            const childBundle = getRandomBundleChild(account.selectedRandomBundles, bundle);
            if (bundle.isRandom) {
                purchaseProcessor.purchaseRandomBundle(childBundle, null, null, true);
            } else {
                purchaseProcessor.purchase();
            }
            return;
        }

        purchaseProcessor.showPopup();
    };

    if (isPeriodicBundle(props.bundle) && !isRandomBundleOpeningEnabled(props.bundle) && !account.selectedRandomBundles[props.bundle.id]) {
        return null;
    }

    const levelingResctrictions = getLevelingRestrictionForBundle(props.bundle);

    const purchaseWrapperRowClassNames = classNames(styles.purchaseWrapperRow, { [styles.isFreePurchaseWrapperRow]: isFreeBundle(props.bundle) });

    return (
        <div className={styles.purchaseMobileWrapper} id="MobilePurchaseWrapper">
            <div className={styles.wrapper}>
                {!!levelingResctrictions?.length && (
                    <div className={styles.restrictionText}>
                        <Attention label={getLevelingRestrictionTextForBundle(props.bundle, levelingResctrictions)} level="info" isInline />
                    </div>
                )}
                <div className={purchaseWrapperRowClassNames}>
                    {!isFreeBundle(props.bundle) && (
                        <div className={styles.purchaseColumn}>
                            <div className={styles.priceWrapperTitle}>{t('Цена:')}</div>
                            <div className={styles.priceWrapper}>
                                <PriceWrapper
                                    wrapperClassName={classNames({
                                        [styles.realPurchaseWrapper]: props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY,
                                    })}
                                    bundle={props.bundle}
                                    coupon={activeCoupon}
                                    discountClassName={styles.discountWrapper}
                                    newPriceClassName={styles.newPrice}
                                    oldPriceClassName={styles.oldPrice}
                                    withoutTimer={true}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.purchaseColumn}>
                        <div className={styles.purchaseButton}>
                            {props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY ? (
                                <RealPurchaseButton bundle={props.bundle} buttonClassName={'armory__auto--bundle_button_real_purchase'} />
                            ) : (
                                <PurchaseButton bundle={props.bundle} onClick={openPurchasePopup} isForceRenderPurchaseButton buttonClassName={'armory__auto--bundle_button_purchase'} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobilePurchaseWrapper;
