import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from '~/components/Popups/purchase_popup.scss';
import { State } from '~/Reducers';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { Button, OrangeButton } from '~/components/Button/Buttons';
import { t } from '~/utils/localization';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import equal from 'fast-deep-equal/react';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { openUrl, settings } from '~/utils/settings';
import WowsCommerce, { getPurchaseErrorCodeText } from '@wg/wows-commerce';
import { PURCHASE_ERROR_CODES, PURCHASE_ERRORS_BY_COUNTRY } from '@wg/wows-commerce/constants/purchaseErrorCodes';
import { arrayToObjectByKey } from '~/utils/utils';
import { IPopupSoundset } from '~/types/soundsets';
import { BillingAddress } from '@wg/wows-commerce/api/user';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { completeAccount } from '@wg/web2clientapi/browser/completeAccount';
import { POPUPS_NAME } from '~/components/PopupManager';

interface IStateSelector {
    products: ProductEntity[];
    billingAddressSettings: BillingAddress;
    bundles: IBundleList;
    wowsCommerce: WowsCommerce;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        products: state.ReducerWSMart.products,
        billingAddressSettings: state.ReducerWSMart.billingAddressSettings,
        bundles: state.ReducerApp.bundles,
        wowsCommerce: state.ReducerWSMart.wowsCommerce,
    };
};

const WSMartPurchaseResultPopup = () => {
    const { popup } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const app = useSelector<State, IStateSelector>(stateSelector, equal);
    const bundlesMap = arrayToObjectByKey(Object.values(app.bundles), 'productCode');
    const { isSuccess, product, errorCode, bundle } = popup.data;
    let errorMsg: string = null;

    const isErrorByCountry = PURCHASE_ERRORS_BY_COUNTRY.includes(errorCode);
    const isBillingAddressError = errorCode === PURCHASE_ERROR_CODES.ADDRESS_REQUIRED_BUT_NOT_EXIST;
    const isErrorByEmail = errorCode === PURCHASE_ERROR_CODES.ACCOUNT_EMAIL_NOT_SPECIFIED;
    const isCustomError = isErrorByCountry || isBillingAddressError || isErrorByEmail;

    if (errorCode) {
        errorMsg = getPurchaseErrorCodeText(errorCode, settings.realm === 'ru');
        if (isErrorByCountry) {
            errorMsg = errorMsg.replace('{link-open}', '').replace('{link-close}', '');
        }
    }

    const close = () => {
        if (isSuccess) dispatch(changeVisiblePopup(POPUPS_NAME.WSMART_DOUBLOONS_POPUP, false));
        dispatch(changeVisiblePopup(null));
    };

    const imageUrl = bundlesMap[product.code]?.icons?.small || product.tileImageUrl;
    const backgroundStyle = {
        backgroundImage: `url(${imageUrl})`,
    };

    const classesContentBackground = classNames({
        [styles.purchaseSuccessImageWrapper]: isSuccess,
        [styles.eliteBackground]: isSuccess,
        [styles.purchaseErrorImageWrapper]: !isSuccess,
    });

    function openExternalLink(event: React.MouseEvent) {
        event.preventDefault();
        let target = event.target as HTMLLinkElement;
        if (target.tagName.toLowerCase() === 'span') {
            target = target.parentNode as HTMLLinkElement;
        }
        if (target.tagName.toLowerCase() === 'a') {
            openUrl(target.href);
        }
    }

    function getTitle() {
        if (isSuccess) {
            return t('Поздравляем, покупка прошла успешо!');
        } else if (isErrorByEmail) {
            return t('Внимание');
        } else if (isCustomError) {
            return t('Ошибка');
        } else {
            return t('Произошла ошибка. Повторите попытку позже');
        }
    }

    return (
        <Popup onClose={close} soundset={isSuccess && IPopupSoundset.EXCHANGE}>
            <PopupHeader title={getTitle()} />
            <PopupBody>
                <div className={styles.purchaseSuccessContent}>
                    <div
                        className={classNames(styles.purchaseErrorDescription, {
                            [styles.customError]: isCustomError,
                        })}
                        onClick={openExternalLink}
                        dangerouslySetInnerHTML={{ __html: errorMsg || '' }}
                    />
                    {!isCustomError && (
                        <>
                            <div className={classesContentBackground}>
                                <div
                                    className={classNames(styles.popupImage, {
                                        [styles.grayscale]: !isSuccess,
                                    })}
                                    style={backgroundStyle}
                                />
                            </div>
                            <div className={styles.purchaseSuccessContentData}>
                                <div className={styles.purchaseSuccessContentTitle} dangerouslySetInnerHTML={{ __html: bundle?.title || product.title }} />
                            </div>
                        </>
                    )}
                </div>
            </PopupBody>
            <PopupFooter>
                {isCustomError ? (
                    <div className={styles.buttonsLine}>
                        {isErrorByEmail && <Button className={styles.customErrorButton} linkTarget={true} onClick={completeAccount} label={t('Форма дополнения аккаунта')} />}
                        {!isErrorByEmail && isErrorByCountry && (
                            <Button className={styles.customErrorButton} linkTarget={true} onClick={() => openUrl(settings.urls.supportUrl)} label={t('Центр поддержки')} />
                        )}
                        {isBillingAddressError && (
                            <Button className={styles.customErrorButton} linkTarget={true} onClick={() => openUrl(app.billingAddressSettings.managementUrl)} label={t('Добавить адрес')} />
                        )}
                        <Button onClick={close} label={t('Закрыть')} />
                    </div>
                ) : (
                    <OrangeButton onClick={close} label={t('Закрыть')} />
                )}
            </PopupFooter>
        </Popup>
    );
};

export default WSMartPurchaseResultPopup;
