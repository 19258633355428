import { getCookie } from '~/utils/cookie';
import { get } from '~/utils/ajax';
import { isInGameBrowser } from '~/utils/utils';
import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl';

const COMPENSATION_TEXT_PLACEHOLDER = '[compensation-text]';

const settings = window.metashop?.settings;
const armoryState = window.metashop?.state;

const getLanguageCode = () => settings.languageCode || 'en';

export const csrf = async () => {
    return await get(settings.urls.csrf, null, false);
};

export const isIEBrowser = (): boolean => {
    const userAgent = navigator.userAgent;
    const msIE = userAgent.indexOf('MSIE ');
    return msIE > 0 || !!userAgent.match(/Trident.*rv\:11\./) || userAgent.indexOf('Edge') > -1;
};

const getUserId = () => armoryState?.account?.id;

let platformCookie = '';
const getPlatform = () => {
    if (platformCookie) return platformCookie;
    return (platformCookie = getCookie('wows_client_platform') || 'unknown');
};

export const openUrl = (url: string) => {
    if (isInGameBrowser) {
        openExternalUrl(url);
    } else {
        window.open(url, '_blank');
    }
};

export const isChina = () => {
    return settings.realm === 'cn360';
};

export const isAvailableRebrandingForCIS = () => {
    return settings.isAvailableRebrandingForCIS && settings.realm === 'ru';
};

export const getGlobalContent = () => {
    return window.metashop.state?.content?.global;
};

export { settings, armoryState, getLanguageCode, getUserId, getPlatform, COMPENSATION_TEXT_PLACEHOLDER };
