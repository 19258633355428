import * as React from 'react';
import client from '~/client';
import { ManagerData, SectionNames } from '~/core/ManagerData';
import VortexDataStorage, { IMergeData } from '~/core/VortexDataStorage';
import { GRAPHQL_QUERIES_MAP } from '~/queries/helpers';
import { settings } from '~/utils/settings';

export async function loadPrimaryItemData<T>(type: string, id: number): Promise<Nullable<T>> {
    if (!GRAPHQL_QUERIES_MAP[type]) return null;
    const response = await client.get().query({
        query: GRAPHQL_QUERIES_MAP[type],
        variables: {
            ids: [id],
            lang: settings.languageCode,
        },
    });
    if (response?.data) {
        // !CODE TO DELETE (start)
        const vortexRequestNames = Object.keys(response.data);
        const mainVortexRequestName = vortexRequestNames.find((requestName) => {
            switch (requestName) {
                case SectionNames.class:
                case SectionNames.currency:
                case SectionNames.crews:
                case SectionNames.dolls:
                case SectionNames.items:
                case SectionNames.vehicles:
                case SectionNames.lootbox:
                case SectionNames.permoflages:
                    return true;
                default:
                    return false;
            }
        }) as SectionNames | undefined;

        const newDatabaseData = {
            data: {},
        } as {
            data: IMergeData;
        };

        for (let i = 0; i < vortexRequestNames.length; i++) {
            const currVortexRequestName = vortexRequestNames[i];

            if (newDatabaseData.data[mainVortexRequestName]) {
                response.data[currVortexRequestName].forEach((newData: any) => {
                    // @ts-ignore
                    const currData = newDatabaseData.data[mainVortexRequestName].find((currData: any) => {
                        return currData?.id === newData?.id;
                    });
                    if (currData) {
                        Object.assign(currData, newData);
                    } else {
                        newDatabaseData.data[mainVortexRequestName].push(newData);
                    }
                });
            } else {
                newDatabaseData.data[mainVortexRequestName] = [...response.data[currVortexRequestName]];
            }
        }

        VortexDataStorage.merge(newDatabaseData.data);

        return newDatabaseData as T;
        // !CODE TO DELETE (end)

        // TODO: uncomment this when ship features moves in ship request and delete the shit above
        // const firstKey = Object.keys(response.data)[0];
        // VortexDataStorage.merge(response.data);
        // return response.data[firstKey][0] as T;
    }

    return null;
}

export default function useLoadPrimaryItemData<T>(type: string, id: number, sectionName: SectionNames) {
    const dataFromStorage = ManagerData.getData(sectionName, id, true);
    const [itemData, setItemData] = React.useState<T>(dataFromStorage);

    React.useEffect(() => {
        if (!type || !id) {
            return;
        }

        if (itemData) {
            return;
        }

        async function loadData() {
            const data = await loadPrimaryItemData<T>(type, id);
            if (data) {
                setItemData(data);
            }
        }

        loadData();
    }, [type, id]);

    return itemData;
}
