import * as React from 'react';
import styles from './PageHeader.scss';
import classNames from 'classnames';
import SearchPanel from '~/components/SearchPanel/SearchPanel';
import { isInGameBrowser } from '~/utils/utils';
import User from '~/components/User/User';
import Account from '~/account/Account';
import { urlsMap } from '~/utils/menu';
import History from '~/utils/history';
import MobilePremiumAccountLink from '~/components/PremiumAccountLink/MobilePremiumAccountLink';

interface IMobileHeaderProps {
    isFullscreen?: boolean;
}

const MobileHeader: React.FC<IMobileHeaderProps> = ({ isFullscreen }): React.ReactElement => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);

    React.useEffect(() => {
        let pos = 0;

        const hiddenHeader = () => {
            ref.current?.classList.add(styles.hidden);
        };

        const showHeader = () => {
            ref.current?.classList.remove(styles.hidden);
        };

        document.addEventListener('scroll', () => {
            const top: number = document.documentElement.scrollTop;
            if (!pos) {
                if (top >= 65) {
                    pos = top;
                    hiddenHeader();
                }
            } else {
                if (top < pos) {
                    showHeader();
                    pos = top;
                } else {
                    hiddenHeader();
                    pos = top;
                }
            }

            if (top <= 65) {
                showHeader();
                pos = top;
            }
        });
    }, []);

    const goToHome = () => History.navigate(urlsMap.home);

    const isAuthorizedUser = !!Account.getAccount();

    const searchClassNames = classNames(styles.mobileHeaderSearch, {
        [styles.hidden]: !isAuthorizedUser,
    });

    const userClassNames = classNames({
        [styles.mobileHeaderUser]: !isAuthorizedUser,
    });

    const classesHeader = classNames(styles.mobileHeader, { [styles.fullScreen]: !!isFullscreen });

    const [isVisibleProfileIcon, setVisibleProfileIcon] = React.useState(true);

    const iconsClassNames = classNames(styles.icons, {
        [styles.small]: !isAuthorizedUser && isVisibleProfileIcon,
        [styles.full]: !isAuthorizedUser && !isVisibleProfileIcon,
    });

    return (
        <div className={classesHeader} ref={ref}>
            <div className={styles.mobileHeaderWrapper}>
                <div className={iconsClassNames}>
                    <div className={styles.mobileHeaderHome}>
                        <div className={styles.iconHome} onClick={goToHome} />
                    </div>
                    <div className={searchClassNames}>
                        <SearchPanel
                            isMobile={true}
                            isActive={isAuthorizedUser}
                            onFocus={() => {
                                !isAuthorizedUser && setVisibleProfileIcon(false);
                            }}
                            onBlur={() => {
                                !isAuthorizedUser && setVisibleProfileIcon(true);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.mobileHeaderProfileWrapper}>
                    <MobilePremiumAccountLink />
                    {!isInGameBrowser && isVisibleProfileIcon && (
                        <div className={userClassNames}>
                            <User useProfileIcon />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MobileHeader;
