import { playCrewRandomVOSample } from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { t } from '~/utils/localization';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import styles from './CrewVoiceButton.scss';

interface ICrewVoiceButton {
    crewId: number;
    className?: string;
    withoutGuideTip?: boolean;
}

const CrewVoiceButton = ({ crewId, className, withoutGuideTip }: ICrewVoiceButton) => {
    const [isAnimate, setAnimation] = React.useState(false);
    const timer = React.useRef<NodeJS.Timeout>(null);

    const onClick = () => {
        dwhExport.send(DWH_EVENTS.PLAY_CREW_SAMPLE_VO, { crew_id: crewId });
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setAnimation(false);
        }, 2000);
        setAnimation(true);
        playCrewRandomVOSample(+crewId);
    };

    const classes = classNames(
        styles.wrapper,
        {
            [styles.animate]: isAnimate,
        },
        className,
    );

    return (
        <div className={classes} onClick={onClick}>
            <GuideDecorator names={withoutGuideTip ? [] : [GUIDE_NAMES.guide_crew_voice]}>
                <div className={styles.icon}>
                    {isAnimate && (
                        <>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </>
                    )}
                    <div className={classNames(styles.volumeIcon, { [styles.hidden]: isAnimate })}></div>
                </div>
            </GuideDecorator>
            <div className={styles.text}>{t('Прослушать случайную реплику')}</div>
        </div>
    );
};

export default CrewVoiceButton;
