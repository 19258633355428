import * as React from 'react';
import styles from './Accordion.scss';
import AccordionBanner from '~/Layouts/Banners/Accordion/AccordionBanner';

type Accordion_Type = {
    banners: IAccordionBanner[];
};

export const ACCORDION_SHOW_INTERVAL = 200;

const Accordion = ({ banners }: Accordion_Type) => {
    const [activeBannerIndex, setActiveBanner] = React.useState<number>(0);
    const timeout = React.useRef(null);

    return (
        <div className={styles.wrapper}>
            {banners.map((banner, index) => {
                return (
                    <AccordionBanner
                        key={`accordion_banner_${index}_${banner.group}`}
                        isSelected={index === activeBannerIndex}
                        banner={banner}
                        onMouseLeave={() => {
                            clearTimeout(timeout.current);
                        }}
                        onHover={() => {
                            clearTimeout(timeout.current);
                            timeout.current = setTimeout(() => {
                                setActiveBanner(index);
                            }, ACCORDION_SHOW_INTERVAL);
                        }}
                    />
                );
            })}
        </div>
    );
};

export default Accordion;
