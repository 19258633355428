import { showMenuItem } from '~/Actions/ActionApp';
import { setTradeInLists, setTs } from '~/Actions/ActionTradeIn';
import store from '~/Store';
import { tradeinStateSync } from '~/api/tradein';
import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { ITradeLists } from '~/types/tradein';
import { settings } from '~/utils/settings';
import { CategoryType } from '~/types/category';

export default class TradeInPreloader extends AbstractPreloader {
    pause = 5;

    async load(): Promise<any> {
        const tradeInEnabled = !!window.metashop.state.content.categories.filter((category) => category.type === CategoryType.TRADEIN)?.length;
        if (!tradeInEnabled) return;

        const { ts } = store.getState().ReducerTradeIn;
        const response = await tradeinStateSync();
        if (!response || !response.tradeinState?.length || ts + this.pause >= response.ts) {
            return;
        }

        const lists = response.tradeinState.reduce((state: ITradeLists, listItem) => {
            if (listItem.tradeinLists.length > 0) {
                store.dispatch(showMenuItem(listItem.categoryName));
                listItem.tradeinLists.forEach((list) => {
                    list.source.forEach((item) => {
                        item.title = item.additionalData.title[settings.languageCode];
                    });
                    list.target.forEach((item) => {
                        item.title = item.additionalData.title[settings.languageCode];
                    });
                });
                state[listItem.categoryName] = listItem.tradeinLists;
            }
            return state;
        }, {});

        store.dispatch(setTs(response.ts));
        store.dispatch(setTradeInLists(lists));
    }
}
