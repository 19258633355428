import classNames from 'classnames';
import store from '~/Store';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { ManagerData, getSectionName } from '~/core/ManagerData';
import { isCurrency } from '~/utils/currencies';
import Account from '~/account/Account';
import { isPortPreviewEnabledByItem, openPortByDefaultItem } from '~/settings/port';
import { ItemTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { isAvailableAdditionalContentForType } from '~/Layouts/BundlePage/helpers';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import { POPUPS_NAME } from '~/components/PopupManager';

import styles from './MrpsInfo.scss';

export function prepareContainsItems(entitlements: MrpsLoadedEntitlement[], inventory: InventoryState, withText = true) {
    return entitlements.map((entitlement, index) => {
        const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(entitlement.type, entitlement.identifier);

        const isAvailableAdditionalContent = !entitlement.isPrimary && isAvailableAdditionalContentForType(entitlement.type, entitlement.identifier);
        const data = ManagerData.getData(getSectionName(entitlement.type), entitlement.identifier, true);
        const content = (
            <div
                className={classNames({
                    [styles.WoWSEntityWrapper]: isAvailableAdditionalContent,
                    [styles.gold]: (entitlement.type !== ItemTypes.LOOTBOX && data?.isPremium) || data?.isSpecial || data?.isUnique,
                })}
                onClick={() => {
                    if (!isAvailableAdditionalContent) {
                        return;
                    }

                    store.dispatch(
                        changeVisiblePopup(POPUPS_NAME.ITEM_DESCRIPTION_POPUP, true, {
                            item: entitlement,
                        }),
                    );
                }}
            >
                <WoWSEntity
                    key={`item_${entitlement.identifier}_${index}_${inventory ? 'loaded' : 'not_loaded'}_${inventoryCount}`}
                    type={entitlement.type}
                    id={entitlement.identifier}
                    amount={entitlement.amount}
                    customisation={{
                        inventoryCount: !entitlement.isUnique && inventoryCount,
                        isExistsInInventory: entitlement.isUnique && isExistsItemInInventory,
                    }}
                    presentation={{
                        withText: withText,
                        withTooltip: true,
                        iconPosition: 'left',
                        renderWithoutFade: true,
                        hiddenInlineShip: entitlement.type === ItemTypes.CREWS,
                        hideTooltipAmount: isCurrency(entitlement.type),
                    }}
                />
            </div>
        );

        return {
            id: (entitlement.identifier || `${index}_${entitlement.type}`)?.toString(),
            type: entitlement.type,
            isExistsInInventory: true,
            isPortPreviewEnabled: isPortPreviewEnabledByItem(entitlement),
            portPreviewOnClick: () => {
                openPortByDefaultItem({ identifier: entitlement.identifier, type: entitlement.type });
            },
            content,
        } as IContainsItem;
    });
}

interface StateSelector {
    currentEvent?: MrpsCurrentEventState;
    currentEventData: MrpsEvent;
    inventory: InventoryState;
}

function stateSelector(state: State): StateSelector {
    const inventory = state.ReducerAccount.inventory;
    const mrpsState = state.reducerMrps;

    return {
        currentEvent: mrpsState.currentEvent || ({} as MrpsCurrentEventState),
        currentEventData: mrpsState.events.find((event) => event.name === mrpsState.currentEvent?.eventName),
        inventory,
    };
}

export default function MrpsInfo() {
    const state = useSelector<State, StateSelector>(stateSelector, equal);

    let title: string;
    let subtitle: string;
    let description: string | JSX.Element;
    if (state.currentEvent.data.currentInfo) {
        const rewardToShow = state.currentEvent.data.currentInfo;
        const preparedItem = prepareContainsItems(rewardToShow.entitlements, state.inventory);
        title = rewardToShow.mainEntitlementAdditionalData?.title || rewardToShow.title || '';
        subtitle = rewardToShow.mainEntitlementAdditionalData?.typeTitle || '';
        description = (
            <>
                <Contains items={preparedItem} isLarge={true} />
                <hr className={styles.divider} />
                {rewardToShow.description}
            </>
        );
    } else {
        title = state.currentEventData?.title;
        description = state.currentEventData?.description;
    }

    return (
        <div className={styles.wrapper} key={`mrpsinfo_${state.currentEvent.data.currentInfo?.name || state.currentEvent.eventName}`}>
            <div className={styles.header}>
                {title}
                {subtitle ? <span className={styles.subHeader}>{subtitle}</span> : null}
            </div>
            {state.currentEvent.data.currentInfo ? <hr className={styles.divider} /> : null}
            <div className={styles.description}>{description}</div>
        </div>
    );
}
