import { items } from '@wg/wows-entities/const';
import { t } from './localization';
import { ChooseItemFor, ITradeItem, ITradeList, ITradeLists } from '~/types/tradein';
import { FILTERS_TYPE } from '~/components/Filters/Filters';
import { getFiltersInfo } from '~/settings/filtersMap';
import { flat, getDeepProperty } from './utils';
import { TAGS } from '~/const';
import { CategoryType } from '~/types/category';
import Account from '~/account/Account';

type TRADEIN_RULES_TYPE = {
    title: string;
    rule: {
        title: string;
        description: string;
    };
};
type TRADEIN_TEXTS_TYPE = Record<
    string,
    {
        trade: TRADEIN_RULES_TYPE;
        get: TRADEIN_RULES_TYPE;
    }
>;
export const TRADEIN_TEXTS: TRADEIN_TEXTS_TYPE = {
    [items.VEHICLES]: {
        trade: {
            title: t('Корабль для обмена'),
            rule: {
                title: t('Списание корабля'),
                description: t('Модернизации, модули и другое имущество обмениваемого корабля будут перемещены в Имущество. Командир будет отправлен в Резерв.'),
            },
        },
        get: {
            title: t('Корабль для получения'),
            rule: {
                title: t('Начисление корабля'),
                description: t('Получаемый корабль начисляется без командира и слота.'),
            },
        },
    },
};

const deleteDublicates = (items: ITradeItem[]): ITradeItem[] => {
    const result = items.reduce((state: Record<number, ITradeItem>, item) => {
        if (!state[item.identifier]) {
            state[item.identifier] = item;
        }
        return state;
    }, {});

    return Object.values(result);
};

export type LISTS_FOR_DISPLAY_TYPE = { availableItems: ITradeItem[]; deniedItems?: ITradeItem[]; availableItemsWithoutFilters: ITradeItem[] };
export const getPreparedLists = (
    currentList: ITradeList[],
    choosenItemFor: ChooseItemFor,
    selectedTargetItem: ITradeItem,
    selectedSourceItem: ITradeItem,
    filters?: FILTERS_TYPE,
): LISTS_FOR_DISPLAY_TYPE => {
    if (!currentList) {
        return { availableItems: [], availableItemsWithoutFilters: [] };
    }
    const isAuthorized = !!Account.getAccount()?.id;
    if (!selectedTargetItem && !selectedSourceItem) {
        let list: ITradeItem[] = [];
        if (choosenItemFor === ChooseItemFor.GET) {
            list = currentList.reduce((state, item) => (state = state.concat(item.target)) && state, []);
        } else {
            list = currentList
                .reduce((state, item) => (state = state.concat(item.source)) && state, [])
                .filter((item) => {
                    return isAuthorized ? item.isReceived : true;
                });
        }
        const result = filters ? filterItems(list, filters) : list;
        return { availableItems: deleteDublicates(result), availableItemsWithoutFilters: list };
    }

    let availableItems: ITradeItem[] = [];
    const deniedItems: ITradeItem[] = [];
    let source: ITradeItem[] = [];

    if (choosenItemFor === ChooseItemFor.GET) {
        if (selectedSourceItem?.identifier) {
            availableItems = currentList.reduce((result, group) => {
                const isAllowedGroup = group.source.findIndex((item) => item.identifier == selectedSourceItem?.identifier) > -1;
                if (isAllowedGroup) {
                    result = result.concat(group.target);
                }
                return result;
            }, []);
            availableItems = availableItems.filter((item) => item.identifier !== selectedSourceItem.identifier);
        } else {
            availableItems = currentList.reduce((state, item) => (state = state.concat(item.target)) && state, []);
        }
    } else if (choosenItemFor === ChooseItemFor.TRADE) {
        if (selectedTargetItem?.identifier) {
            source = currentList.reduce((result, group) => {
                const isAllowedGroup = group.target.findIndex((item) => item.identifier == selectedTargetItem?.identifier && selectedTargetItem.currency === item.currency) > -1;
                if (isAllowedGroup) {
                    result = result.concat(group.source);
                }
                return result;
            }, []);
            if (source) {
                source.forEach((item) => {
                    if (item.isReceived || !isAuthorized) {
                        availableItems.push(item);
                    } else {
                        deniedItems.push(item);
                    }
                });
            }
        } else {
            availableItems = currentList
                .reduce((state, item) => (state = state.concat(item.source)) && state, [])
                .filter((item) => {
                    return isAuthorized ? item.isReceived : true;
                });
        }
        if (selectedTargetItem) {
            availableItems = availableItems.filter((item) => item.identifier !== selectedTargetItem.identifier);
        }
    }
    const result = filters ? filterItems(availableItems, filters) : availableItems;
    return { availableItems: deleteDublicates(result), availableItemsWithoutFilters: availableItems };
};

export const filterItems = (items: ITradeItem[], filters: FILTERS_TYPE): ITradeItem[] => {
    const filtensInfo = getFiltersInfo();

    return items.reduce((state, item) => {
        let isAllowed = true;

        for (const [filterName, values] of Object.entries(filters)) {
            if (!isAllowed) {
                break;
            }
            if (values.length) {
                isAllowed = values.includes(getDeepProperty(filtensInfo[filterName as FILTER_INFO_NAME].tradeInField, item));
            }
        }

        if (isAllowed) {
            state.push(item);
        }

        return state;
    }, []);
};

export const getAvailableFiltersForLists = (lists: ITradeItem[]): FILTERS_TYPE => {
    const result = lists.reduce(
        (accumulator: FILTERS_TYPE, list) => {
            lists.forEach((item) => {
                if (!item.additionalData) {
                    return;
                }
                if (list.type === items.VEHICLES) {
                    if (!accumulator.tier.includes(item.additionalData.level)) {
                        accumulator.tier.push(item.additionalData.level);
                    }
                    if (item.additionalData.nation && !accumulator.nation.includes(item.additionalData.nation.name)) {
                        accumulator.nation.push(item.additionalData.nation.name);
                    }
                    if (!accumulator.class.includes(item.additionalData.shipType)) {
                        accumulator.class.push(item.additionalData.shipType);
                    }
                }
                if (!accumulator.currency.includes(item.currency)) {
                    accumulator.currency.push(item.currency);
                }
            });
            return accumulator;
        },
        {
            tier: [],
            class: [],
            nation: [],
            currency: [],
        },
    );

    for (const item of Object.entries(result)) {
        if (item[1].length === 1) {
            delete result[item[0] as keyof typeof result];
        }
    }

    return result;
};

export const isRareItem = (tags: string[]): boolean => {
    return tags?.includes(TAGS.catalogueLegendary);
};

export const isTradeInCategory = (category: ICategory) => {
    return category?.type === CategoryType.TRADEIN;
};

export const findTarget = (lists: ITradeLists, id: number): Nullable<ITradeItem> => {
    const arrayLists: ITradeList[] = flat(Object.values(lists));
    for (let i = 0; i < arrayLists.length; i++) {
        for (let j = 0; j < arrayLists[i].target.length; j++) {
            if (arrayLists[i].target[j].identifier === id) {
                return arrayLists[i].target[j];
            }
        }
    }

    return null;
};

export const markItemsAsUnAvailableAfterTransaction = (lists: ITradeLists, sourceId: number, targetId: number): ITradeLists => {
    Object.values(lists).forEach((list: ITradeList[]) => {
        list.forEach((item) => {
            item.source.forEach((sourceItem) => {
                if (sourceItem.identifier === sourceId) {
                    sourceItem.isReceived = false;
                }
            });
            item.target.forEach((targetItem) => {
                if (targetItem.identifier === targetId) {
                    targetItem.isReceived = true;
                }
            });
        });
    });

    return lists;
};

export const getPrice = (sourcePrice: number, targetPrice: number): number => {
    return Math.max(targetPrice - sourcePrice, 0);
};
