import * as React from 'react';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import usePurchaseMaxCountForRandomBundle from '~/hooks/usePurchaseMaxCountForRandomBundle';
import { hasRestrictionPurchaseForRandomBundle } from '~/components/BundleLimitWidget/settings';
import { calculateBundlePrices, isQuantityIncrementOverdraftAvailable } from '~/utils/purchase';
import Account from '~/account/Account';
import { range } from '~/utils/generate';
import { arrayToObjectByKey } from '~/utils/utils';
import {
    getCountRandomBundleChildren,
    getMaxAmountForOnePurchaseRandomBundle,
    getProgressiveDiscountFromBundle,
    getRandomBundleChild,
    isAvailableChangeQuantityRandomBundle,
    isAvailableFullPurchaseRandomBundle,
    isRandomBundleOpeningEnabled,
    isPeriodicBundle,
    isTimeUpForPeriodicBundleObtain,
} from '~/utils/bundles';

interface IStateSelector {
    selectedRandomBundles: AccountSelectedRandomBundles;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    randomBundlesHistory: AccountRandomBundleHistory;
    deniedBundlesByUniqueItems: number[];
    accountId: number;
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
    balance: IBalance;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        randomBundlesHistory: state.ReducerAccount.randomBundlesHistory,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,
        balance: state.ReducerAccount.balance,
    };
};

export type PriceMapItem = { quantity: number; price: number; discount: number };
function getPricesMapForProgressivePurchase(bundle: IBundle) {
    return [...range(getCountRandomBundleChildren(bundle), 0, 1)].reduce((state: PriceMapItem[], _, index) => {
        const quantity = index + 1;
        const data = getProgressiveDiscountFromBundle(bundle, quantity);
        if (!data) {
            return state;
        }
        state.push({
            quantity,
            price: data.price * quantity,
            discount: data.discount,
        });
        return state;
    }, []);
}

function getBeneficialOffer(bundle: IBundle, map: ReturnType<typeof getPricesMapForProgressivePurchase>, availableQuantityForPurchase: number): Nullable<PriceMapItem> {
    const lastIndex = map.findIndex((item) => item.quantity === availableQuantityForPurchase);
    const prices = [...map].splice((bundle.quantityData?.quantity || 1) - 1, lastIndex + 1);
    const configPrices = arrayToObjectByKey(map, 'quantity');
    const currentPrice = configPrices[bundle.quantityData?.quantity || 1];
    const offerIndex = prices.findIndex((item) => item.price < currentPrice.price && item.quantity > bundle.quantityData?.quantity);
    const offer = prices[offerIndex];
    if (availableQuantityForPurchase < offer?.quantity) {
        return null;
    }
    return offer;
}

const useRandomBundleData = (bundle: IBundle) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const isAlreadyPurchasedBundle = bundle.isPurchased;
    const randomBundleChild = state.accountId && getRandomBundleChild(state.selectedRandomBundles, bundle);
    const currentBundle: IBundle = randomBundleChild || bundle;
    const history = state.randomBundlesHistory?.[bundle.id] || [];
    const totalRandomBundlesCount = getCountRandomBundleChildren(bundle);
    const historyLength = history?.length;
    const [isLoadingPurchaseMaxCount, purchaseMaxCountForRandomBundle] = usePurchaseMaxCountForRandomBundle(bundle, [historyLength]);
    const _hasRestrictionPurchaseForRandomBundle = React.useCallback(() => {
        return hasRestrictionPurchaseForRandomBundle(bundle);
    }, [])();

    const pricesMap = React.useCallback(() => getPricesMapForProgressivePurchase(bundle), [])();

    if (!bundle.isRandom) {
        return {};
    }

    const availableAmountForFullPurchase = totalRandomBundlesCount - historyLength;
    let _isAvailableFullPurchaseRandomBundle = state.accountId && isAvailableFullPurchaseRandomBundle(bundle);
    const _isAvailableChangeQuantity = state.accountId && isAvailableChangeQuantityRandomBundle(bundle);
    const hasProgressiveDiscount = !!bundle?.progressivePurchase?.discountDiapasons?.length;
    const progressiveDiscount = getProgressiveDiscountFromBundle(bundle, bundle?.quantityData?.quantity || 1);
    const isEnableFirstOpen = isRandomBundleOpeningEnabled(bundle);
    const isSelectedRandomBundleExist = !!state.selectedRandomBundles?.[bundle.id];
    const isTimeUp = !isEnableFirstOpen && !isSelectedRandomBundleExist && isTimeUpForPeriodicBundleObtain(bundle);
    const isAvailablePurchasePeriodicBundle = !isAlreadyPurchasedBundle && (!state.accountId || isEnableFirstOpen || (!isTimeUp && isSelectedRandomBundleExist));
    const isNeedToShowTimer = isPeriodicBundle(bundle) && !(!state.accountId || isAvailablePurchasePeriodicBundle || isAlreadyPurchasedBundle || isTimeUp);
    let maxAmountForOnePurchaseRandomBundle;

    let maxAvailableProgressiveDiscount = getProgressiveDiscountFromBundle(bundle, availableAmountForFullPurchase);
    if (purchaseMaxCountForRandomBundle !== null || isLoadingPurchaseMaxCount) {
        const maxCount = isLoadingPurchaseMaxCount ? 1 : purchaseMaxCountForRandomBundle;
        _isAvailableFullPurchaseRandomBundle = availableAmountForFullPurchase === maxCount;
        maxAmountForOnePurchaseRandomBundle = maxCount;
    } else {
        if (bundle.randomIsInfinite) {
            maxAmountForOnePurchaseRandomBundle = getMaxAmountForOnePurchaseRandomBundle(bundle);
            maxAvailableProgressiveDiscount = getProgressiveDiscountFromBundle(bundle, maxAmountForOnePurchaseRandomBundle);
        } else {
            maxAmountForOnePurchaseRandomBundle = availableAmountForFullPurchase;
        }
    }

    const maxAmountForOnePurchaseWithoutSinglePurchaseLimit = maxAmountForOnePurchaseRandomBundle;

    if (bundle.singlePurchaseMaxQuantity && bundle.singlePurchaseMaxQuantity < maxAmountForOnePurchaseRandomBundle) {
        maxAmountForOnePurchaseRandomBundle = bundle.singlePurchaseMaxQuantity;
    }

    let beneficialOffer = getBeneficialOffer(bundle, pricesMap, availableAmountForFullPurchase);
    if (_hasRestrictionPurchaseForRandomBundle && Number.isInteger(purchaseMaxCountForRandomBundle) && (purchaseMaxCountForRandomBundle as number) < beneficialOffer?.quantity) {
        beneficialOffer = null;
    } else if (bundle.singlePurchaseMaxQuantity && bundle.singlePurchaseMaxQuantity < beneficialOffer?.quantity) {
        beneficialOffer = null;
    }

    const isManyQuantity = !bundle.singlePurchaseMaxQuantity || (maxAmountForOnePurchaseRandomBundle > 1 && maxAmountForOnePurchaseRandomBundle <= bundle.singlePurchaseMaxQuantity);

    const progressiveDiscountData = getProgressiveDiscountFromBundle(bundle, availableAmountForFullPurchase);
    const purchaseDataForAllBundles = calculateBundlePrices(bundle, state.balance, availableAmountForFullPurchase, null, progressiveDiscountData);
    const pricesDataForSingleBundle = calculateBundlePrices(bundle, state.balance, 1, null, progressiveDiscountData);
    const canIncrementQuantity = Account.canIncrementQuantity(pricesDataForSingleBundle, state.balance, bundle, null) || isQuantityIncrementOverdraftAvailable(pricesDataForSingleBundle);
    const canPurchaseWholeBundle =
        (Account.canIncrementQuantity(purchaseDataForAllBundles, state.balance, bundle, null) || isQuantityIncrementOverdraftAvailable(purchaseDataForAllBundles)) &&
        maxAmountForOnePurchaseRandomBundle >= availableAmountForFullPurchase;

    return {
        parentRandomBundle: bundle,
        childRandomBundle: randomBundleChild,
        totalRandomBundlesCount,
        currentBundle,
        isAlreadyPurchased: isAlreadyPurchasedBundle,
        history,
        historyLength,
        availableAmountForFullPurchase,
        isAvailableFullPurchaseRandomBundle: _isAvailableFullPurchaseRandomBundle && isManyQuantity,
        isAvailableChangeQuantity: _isAvailableChangeQuantity && isManyQuantity,
        hasProgressiveDiscount,
        currentProgressiveDiscount: progressiveDiscount,
        maxAvailableProgressiveDiscount,
        isEnableFirstOpen,
        isTimeUp,
        isAvailablePurchasePeriodicBundle,
        isNeedToShowTimer,
        maxAmountForOnePurchaseRandomBundle,
        maxAmountForOnePurchaseWithoutSinglePurchaseLimit,
        purchaseMaxCountForRandomBundle,
        canPurchaseWholeBundle,
        canIncrementQuantity,
        beneficialOffer,
        hasRestrictionPurchaseForRandomBundle: purchaseMaxCountForRandomBundle && _hasRestrictionPurchaseForRandomBundle,
    };
};

export default useRandomBundleData;
