import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { settings } from '~/utils/settings';

export enum VORTEX_ENDPOINT {
    glossary = '/api/graphql/glossary/',
    version = '/api/v2/graphql/glossary/version/',
}

const cache = new InMemoryCache({
    dataIdFromObject: (object) => object.id,
});

export class Client {
    private link: BatchHttpLink;
    private apolloClient: ApolloClient<any>;
    private url = settings.urls.vortex + VORTEX_ENDPOINT.glossary;

    constructor(urlPath?: string) {
        if (urlPath) this.url = settings.urls.vortex + urlPath;
    }

    public initClient(url: string) {
        this.link = new BatchHttpLink({
            uri: url,
            credentials: 'same-origin',
        });

        this.apolloClient = new ApolloClient({
            cache: cache,
            link: this.link,
            name: 'wows-entities',
            version: '1.0',
            queryDeduplication: false,
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
    }

    public get() {
        if (!this.apolloClient) {
            this.initClient(this.url);
        }
        return this.apolloClient;
    }
}

export default new Client();
