import * as React from 'react';
import styles from './Timer.scss';
import { getParsedTime } from '~/utils/time';
import { interpolate, ngettext } from '~/utils/localization';
import classNames from 'classnames';
import { toInt } from '~/utils/utils';

interface ITimerProps {
    time: string | number;
    onFinishCallback?: () => void;
    timerClassName?: string;
    withoutIcon?: boolean;
    className?: string;
    iconClassName?: string;
    renderTimerIfManyDays?: boolean;
    attentionClassName?: string;
    hiddenZero?: boolean;
}

const Timer = (props: ITimerProps) => {
    const nowTime = Math.floor(Date.now() / 1000);
    const activeTill = Math.floor(new Date(props.time).getTime() / 1000);
    const time = activeTill - nowTime;
    const [stateTime, setTime] = React.useState(time);
    const timerTime = parseInt(stateTime.toString(), 10);

    React.useEffect(() => {
        let interval: any = null;

        if (timerTime < 0) {
            clearInterval(interval);
        }

        if (timerTime <= 0) {
            clearInterval(interval);
            if (props.onFinishCallback) {
                props.onFinishCallback();
            }
        } else {
            interval = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                const diffTime = currentTime - nowTime;
                setTime((timerTime: number) => timerTime - diffTime);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [timerTime]);

    let { days, hours, minutes, seconds }: any = getParsedTime(timerTime);

    if (timerTime < 0) {
        days = hours = minutes = seconds = 0;
    }

    if (hours < 10) {
        hours = `0${hours}`;
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }

    if (seconds < 10) {
        seconds = `0${seconds}`;
    }

    let timerContent = `${hours}:${minutes}:${seconds}`;
    if (days > 0) {
        timerContent = ngettext('{days} день', '{days} дня', days);
        timerContent = interpolate(timerContent, {
            days: days,
        });

        if (props.renderTimerIfManyDays) {
            timerContent += ' ' + `${hours}:${minutes}:${seconds}`;
        }
    } else {
        let content = '';
        if (hours !== '00') {
            content += `${hours}:`;
        } else {
            if (!props.hiddenZero) {
                content += `${hours}:`;
            }
        }

        if (minutes !== '00') {
            content += `${minutes}:`;
        } else {
            if (!props.hiddenZero) {
                content += `${minutes}:`;
            }
        }

        if (props.hiddenZero) {
            content += toInt(seconds.toString());
        } else {
            content += `${seconds}`;
        }

        timerContent = content;
    }

    return (
        <div
            className={classNames(
                styles.timerWrapper,
                props.className,
                {
                    [props.attentionClassName]: days < 1,
                },
                'armory__auto-timer_widget',
            )}
        >
            {!props.withoutIcon && <div className={classNames(styles.timerWrapperIcon, props.iconClassName)} />}
            <div className={classNames(styles.timer, props.timerClassName)}>{timerContent}</div>
        </div>
    );
};

export default Timer;
