import * as React from 'react';
import styles from '~/Layouts/Themes/SantaPage/SantaPage.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import classNames from 'classnames';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { isCertificateShowcase, SANTA_PAGE_BACKGROUND_VIDEO, SANTA_PAGE_BACKGROUND_VIDEO_WITH_GIFT } from '~/Layouts/Themes/SantaPage/settings';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import { SECRET_SANTA_GIFT_NAME } from '~/const';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import useMobile from '~/hooks/useMobile';

interface IStateSelector {
    currentPage: ICurrentPage;
    categoriesAnimationStatuses: ICategoriesAnimationStatuses;
    isTrusted: boolean;
    categories: ICategories;
    secretGift?: IGift;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
        categoriesAnimationStatuses: state.ReducerApp.categoriesAnimationStatuses,
        isTrusted: state.ReducerApp.isTrusted,
        secretGift: state.ReducerAccount.gifts?.secretBundles?.find((bundle) => bundle.name === SECRET_SANTA_GIFT_NAME),
    };
};

interface SantaBackgroundProps {
    video?: IVideo;
    backgroundClassName?: string;
    isInPopup?: boolean;
}

const SantaBackground = (props: SantaBackgroundProps) => {
    const state = useSelector<State, IStateSelector>(stateSelector, shallowEqual);
    const { isBundlePage } = state.currentPage || {};
    const _isDisabledAnimation = useIsDisabledAnimation(state.currentPage?.name);
    const isShowcaseForCertificates = isCertificateShowcase(state.currentPage?.name);
    const isAvailableSoundEffect = useAvailableSound(state.categories[state.currentPage?.name], null);
    const isSecretGiftAvailable = !!state.secretGift;
    const [isMobile] = useMobile();

    const classesBackground = classNames(styles.background, props.backgroundClassName, {
        [styles.hasSecret]: isSecretGiftAvailable,
        [styles.certificates]: isShowcaseForCertificates,
    });

    const classesWrapper = classNames(styles.backgroundWrapper, {
        [styles.certificates]: isShowcaseForCertificates,
    });

    if (isBundlePage && !props.video) {
        if (isMobileOrTabletWindow) {
            return (
                <div className={styles.backgroundWrapper}>
                    <div className={classesBackground}></div>
                </div>
            );
        }

        return null;
    }

    const video: IVideo | null = props.video || (isSecretGiftAvailable ? SANTA_PAGE_BACKGROUND_VIDEO_WITH_GIFT : SANTA_PAGE_BACKGROUND_VIDEO);

    let muted = !isAvailableSoundEffect;
    if (!state.isTrusted) {
        muted = true;
    }

    return (
        <div className={classNames(classesWrapper)}>
            {isMobile || _isDisabledAnimation || !video ? (
                <div className={classesBackground}>
                    <div className={styles.videoBackgroundMask}></div>
                </div>
            ) : (
                <VideoBackground
                    key={video.webm}
                    poster={null}
                    video={video}
                    muted={muted}
                    className={styles.videoBackgroundWrapper}
                    volume={0.5}
                    posterClassName={classNames(styles.background, props.backgroundClassName, {
                        [styles.certificates]: isShowcaseForCertificates,
                    })}
                    videoClassName={styles.videoBackground}
                    isInPopup={props.isInPopup}
                >
                    <div className={styles.videoBackgroundMask}></div>
                </VideoBackground>
            )}
        </div>
    );
};

export default SantaBackground;
