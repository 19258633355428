import * as React from 'react';
import styles from './Menu.scss';
import { getUrl, urlsMap } from '~/utils/menu';
import className from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { isActiveMenuItem } from '~/components/Footer/MobileMenuFooter';
import { scrollMobileContainer } from '~/utils/scrollTop';
import { changeCurrentPage } from '~/Actions/ActionApp';
import { t } from '~/utils/localization';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Spoiler from '~/components/Spoiler/Spoiler';
import { State } from '~/Reducers';
import { isAllSubCategoryDisplayRestricted } from '~/utils/category';
import { CATEGORIES } from '~/const';

interface IState {
    menu: IMenuMap;
    categories: ICategories;
}

const stateSelector = (state: State): IState => {
    return {
        menu: state.ReducerApp.menu,
        categories: state.ReducerApp.categories,
    };
};

const MenuMobile = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const appState: IState = useSelector<State, IState>(stateSelector, shallowEqual);

    const menuWrapperClassNames = className(styles.mobileMenuWrapper);

    React.useEffect(() => {
        scrollMobileContainer({ top: 0 });
        dispatch(
            changeCurrentPage({
                title: t('Навигация'),
                name: 'menu',
                isBundlePage: false,
            }),
        );
    }, []);

    return (
        <div className={menuWrapperClassNames} ref={ref}>
            <div className={styles.mobileMenuContent}>
                {Object.keys(appState.menu).map((key: string, index: number) => {
                    const item = appState.menu[key] as IMenuMapItem;
                    const classNames = className(styles.mobileMenuItemIcon, {
                        [styles[`mobile-menu-${key}`]]: !item.icon,
                        [styles[`mobile-menu-${item.parentIconClassName}`]]: !item.icon,
                        [styles[`mobile-menu-theme__${item.theme}`]]: !!item.theme,
                        [styles[`mobile-menu-theme__${item.parentIconClassName}`]]: !!item.theme,
                    });

                    if (typeof item.isEnabledCategory === 'function' && !item.isEnabledCategory()) {
                        return;
                    }

                    if (item.isHidden) {
                        return;
                    }

                    if (!item.url || key === CATEGORIES.WSMART) {
                        return;
                    }

                    const icon: any = item.icon
                        ? {
                              WebkitMaskImage: `url(${item.icon})`,
                              maskImage: `url(${item.icon})`,
                          }
                        : {};

                    const subCategories = [...(item.subCategories || [])].splice(1, item.subCategories?.length || 0);

                    if (item.subCategories?.length && isAllSubCategoryDisplayRestricted(item)) {
                        return null;
                    }

                    if (subCategories.length > 0) {
                        const content = subCategories.reduce((result: React.ReactChild[], name: ICategoryList, _index) => {
                            const url = getUrl(urlsMap.subCategory, {
                                categoryName: item.name,
                                subCategoryName: name,
                            });

                            const category = appState.categories[name];
                            if (!category || !category?.bundles?.length) {
                                return result;
                            }

                            result.push(
                                <NavLink
                                    to={url}
                                    className={() => {
                                        return className(styles.mobileMenuItem, styles.noMargin, 'armory__auto--menu_item', {
                                            [styles.isActive]: isActiveMenuItem(url, location.pathname),
                                        });
                                    }}
                                    data-category-name={item.name}
                                    key={`subcategory_${category.name}_${_index}`}
                                >
                                    <div className={className(styles.mobileMenuItemIcon, styles.iconHidden)} />
                                    <div className={styles.mobileMenuItemTitle}>{category.title}</div>
                                </NavLink>,
                            );

                            return result;
                        }, []);

                        return (
                            <span className={className(styles.mobileMenuSpoiler, 'armory__auto--menu_parent-category')} data-category-name={item.name} key={`${item.name}_${index}`}>
                                <Spoiler
                                    title={
                                        <div className={className(styles.mobileMenuItem, styles.noMargin, styles.fullWidth, styles.noActive)} key={index}>
                                            <div className={classNames} style={icon} />
                                            <div className={styles.mobileMenuItemTitle}>{item.title}</div>
                                        </div>
                                    }
                                    content={content}
                                    titleClassName={styles.spoilerTitle}
                                />
                            </span>
                        );
                    }

                    const mobileMenuTitle = item.parentCategoryData?.disableFeatured ? item.parentCategoryData.title : item.title;

                    return (
                        <NavLink
                            to={item.url}
                            className={() => {
                                return className(styles.mobileMenuItem, 'armory__auto--menu_item', {
                                    [styles.isActive]: isActiveMenuItem(item.url, location.pathname),
                                });
                            }}
                            data-category-name={item.name}
                            key={`${item.name}_${index}`}
                        >
                            <div className={classNames} style={icon} />
                            <div className={styles.mobileMenuItemTitle}>{mobileMenuTitle}</div>
                        </NavLink>
                    );
                })}
            </div>
        </div>
    );
};

export default MenuMobile;
