import * as React from 'react';
import History from '~/utils/history';
import { useOutletContext } from 'react-router-dom';
import type { OutletContextType } from '~/components/View/View';
import { getScrollTopContainer } from '~/utils/scrollTop';

interface IDefaultRouterWrapper {
    children: React.ReactNode;
}

export default function DefaultRouterWrapper({ children }: IDefaultRouterWrapper) {
    const { isBundlePage } = useOutletContext<OutletContextType>();

    if (!isBundlePage) {
        History.setScrollTop(getScrollTopContainer());
    }

    return <>{children}</>;
}
