import store from '~/Store';
import client from '~/client';
import { preloadedState } from '~/api/auction';
import { finishAuction, updateAuctionState } from '~/Actions/ActionAuction';
import Account from '~/account/Account';
import AuctionProcessor from '~/processors/AuctionProcessor';
import { preloaded_state } from '~/api/mocks/auction';
import { LocalStorageHelper } from '~/utils/storage';
import { devModeKeyMap } from '~/utils/devMode';
import { logInfo } from '~/utils/logging';
import { assets, items as ITEMS } from '@wg/wows-entities/const';
import { settings } from '~/utils/settings';
import VortexDataStorage, { type IMergeData } from '~/core/VortexDataStorage';
import { isAuctionTabDisabled } from '~/utils/auction';
import { AUCTION_STATUS } from '~/const';
import Cron from '~/utils/Cron';
import { PreCacheImage } from '~/utils/preCacheImage';
import { GRAPHQL_QUERIES_MAP } from '~/queries/helpers';
import { SectionNames } from './ManagerData';

class AuctionRepository {
    public async preloadedState() {
        if (LocalStorageHelper.get(devModeKeyMap.mockAuction)) {
            return preloaded_state;
        }

        return await preloadedState(Account.getAccount()?.id);
    }
}

export default class AuctionStorage {
    static isAuctionCategoryEnabled = false;

    static isAlreadyPreloadedContent = false;

    static hasActiveAuction = false;

    static prepareAuctions(auctions: IAuction[]): IAuction[] {
        return auctions
            .map((auction: IAuction) => {
                const promotionStartedAt = new Date(auction.promotionStartedAt).getTime();
                const promotionFinishedAt = new Date(auction.promotionFinishedAt).getTime();
                const startedAt = new Date(auction.startedAt).getTime();
                const finishedAt = new Date(auction.finishedAt).getTime();
                const now = Date.now();

                if (promotionStartedAt) {
                    if (now < promotionFinishedAt && now < startedAt) {
                        auction.isNeedToShowPromoPage = true;
                    }
                }

                if (now >= finishedAt && auction.status === AUCTION_STATUS.ACTIVE) {
                    auction.status = AUCTION_STATUS.CHOOSING_WINNERS;
                }

                return auction;
            })
            .filter((auction) => {
                return auction.lots.length >= 1;
            });
    }

    static preloadContent(auctions: IAuction[]) {
        AuctionStorage.isAlreadyPreloadedContent = true;
        const auctionContentFields: Array<keyof IAuction> = ['promotionStageImageUrl', 'backgroundUrl'];
        const lotContentFields: Array<keyof ILot> = ['previewImageUrl', 'gridImageUrl', 'purchasePreviewImageUrl'];
        const resources: string[] = [];

        auctions.forEach((auction) => {
            auctionContentFields.forEach((field) => {
                if (auction[field]) {
                    resources.push(auction[field] as string);
                }
            });
            auction.lots.forEach((lot) => {
                lotContentFields.forEach((field) => {
                    if (lot[field]) {
                        resources.push(lot[field] as string);
                    }
                });
            });
        });

        if (resources.length) {
            new PreCacheImage().add(...resources);
        }
    }

    static async preloadedState() {
        if (isAuctionTabDisabled()) {
            AuctionStorage.isAuctionCategoryEnabled = false;
            return;
        }

        try {
            const instance = new AuctionRepository();
            const response = await instance.preloadedState();
            if (response) {
                AuctionStorage.isAuctionCategoryEnabled = response.activeAuctions?.length > 0;
                await AuctionProcessor.checkAccountResponse(response.account);
                store.dispatch(updateAuctionState(AuctionStorage.prepareAuctions(response.activeAuctions || []), response.account, response.minimalWonBids));
                AuctionProcessor.checkWonBids(response.account?.activeBids || []);
                AuctionStorage.hasActiveAuction = response.activeAuctions?.some((auction: IAuction) => auction.status === AUCTION_STATUS.ACTIVE) || false;
                if (!AuctionStorage.isAlreadyPreloadedContent) {
                    AuctionStorage.preloadContent(response.activeAuctions);
                }
            }
        } catch (e) {
            AuctionStorage.isAuctionCategoryEnabled = false;
        }
    }

    static createScheduledTask() {
        const auctions = store.getState().ReducerAuction.activeAuctions;

        if (!auctions || !auctions.length) {
            return;
        }

        auctions.forEach((auction: IAuction) => {
            const nowTime = Math.floor(Date.now() / 1000);
            const date = new Date(auction.finishedAt).getTime();
            const activeTill = Math.floor(date / 1000);
            const diff = activeTill - nowTime;

            if (diff > 86400) {
                return;
            }

            const callback = () => store.dispatch(finishAuction(auction.id));

            Cron.newScheduledTask(`auction_${auction.id}`, callback, date);
        });
    }

    static async loadVortexInfoIfNeeded() {
        const auctions = store.getState().ReducerAuction.activeAuctions;
        if (!auctions || !auctions.length) {
            return;
        }

        const requests: Promise<any>[] = [];

        const availableTypes = [ITEMS.CREWS, ITEMS.VEHICLES, ITEMS.PERMOFLAGES, ITEMS.CAMOUFLAGE, ITEMS.STYLE, ITEMS.SKIN];

        auctions.forEach((auction: IAuction) => {
            auction.lots.forEach((lot: ILot) => {
                lot.gameRewards.forEach((item) => {
                    if (availableTypes.includes(item.type as ITEMS)) {
                        requests.push(
                            client
                                .get()
                                .query({
                                    query: GRAPHQL_QUERIES_MAP[item.type],
                                    variables: {
                                        ids: [item.id],
                                        lang: settings.languageCode,
                                    },
                                })
                                .catch(() => {
                                    logInfo('response not successful for item', item.type);
                                }),
                        );
                    }
                });
            });
        });

        if (!requests.length) {
            return;
        }

        const vortexResponse = await Promise.all(requests);
        const data: Record<string, any[]> = {};

        vortexResponse.forEach((_response: any) => {
            if (!_response) {
                return;
            }

            // !CODE TO DELETE (start)
            const vortexRequestNames = Object.keys(_response.data);
            const mainVortexRequestName = vortexRequestNames.find((requestName) => {
                switch (requestName) {
                    case SectionNames.class:
                    case SectionNames.currency:
                    case SectionNames.crews:
                    case SectionNames.dolls:
                    case SectionNames.items:
                    case SectionNames.vehicles:
                    case SectionNames.lootbox:
                    case SectionNames.permoflages:
                        return true;
                    default:
                        return false;
                }
            });

            for (let i = 0; i < vortexRequestNames.length; i++) {
                const currVortexRequestName = vortexRequestNames[i];

                if (data[mainVortexRequestName]) {
                    _response.data[currVortexRequestName].forEach((newData: any) => {
                        const currData = data[mainVortexRequestName].find((currData: any) => currData.id === newData.id);
                        if (currData) {
                            Object.assign(currData, newData);
                        } else {
                            data[mainVortexRequestName].push(newData);
                        }
                    });
                } else {
                    data[mainVortexRequestName] = [..._response.data[currVortexRequestName]];
                }
            }
            // !CODE TO DELETE (end)

            // TODO: uncomment this when ship features moves in ship request and delete the shit above
            // const vortexRequestName = Object.keys(_response.data)[0];
            // if (data[vortexRequestName]) {
            //     data[vortexRequestName].push(..._response.data[vortexRequestName]);
            // } else {
            //     Object.assign(data, { ..._response.data });
            // }
        });

        if (!Object.keys(data).length) {
            return;
        }

        VortexDataStorage.merge(data as IMergeData);
    }
}
