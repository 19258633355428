import * as React from 'react';
import styles from './RandomBundle.scss';
import classNames from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { getUserId } from '~/utils/settings';
import { showParallaxAnimationShip } from '~/Actions/ActionApp';
import { openBundleByUrl } from '~/utils/category';
import PreCacheImage from '~/utils/preCacheImage';
import { isMobileOrTabletWindow, redirectToLogin } from '~/utils/utils';
import Account from '~/account/Account';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { isAllowedNewLabelFromBundle } from '~/utils/labels';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import RandomBundlePurchaseWrapper from '~/components/Bundle/Random/RandomBundlePurchaseWrapper';
import RandomBundleImage from '~/components/Bundle/Random/RandomBundleImage';
import RandomBundleInfo from '~/components/Bundle/Random/RandomBundleInfo';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { BUNDLE_GRID_SIZE } from '~/const';
import GlowEffectDecorator from '~/components/GlowEffect/GlowEffectDecorator';
import { isEnableShinyForBundle } from '~/utils/shiny/shiny';
import { POPUPS_NAME } from '~/components/PopupManager';
import BundleDecoration from '~/components/Bundle/Decoration/BundleDecoration';
import { ICurrentPage } from '~/Actions/ActionAppType';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import {
    getNextTimeForPeriodicBundle,
    isAdmiralPack,
    isCommanderPack,
    isPeriodicBundlePurchaseDisabled,
    isRandomBundleOpeningEnabled,
    isFreeBundle,
    isPeriodicBundle,
    isTimeUpForPeriodicBundleObtain,
    getLatestTimeForPeriodicBundle,
    isAvailableShinyAnimation,
    isBundleDisabledByPromoTimer,
    isRandomBundle,
} from '~/utils/bundles';
import { playCardClickSound } from '~/api/WoWsClient';
import PromoTimer from '~/components/Bundle/PromoTimer/PromoTimer';
import { useState } from 'react';

interface IRandomBundle {
    bundle: IBundle;
    width: string;
}

interface IRandomBundleHoverAnimationComponent {
    bundle: IBundle;
}

export const RandomBundleHoverAnimationComponent = ({ bundle }: IRandomBundleHoverAnimationComponent) => {
    return <></>;
};

interface IStateSelector {
    selectedRandomBundles: AccountSelectedRandomBundles;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems?: number[];
    bundlesInTransaction?: number[];
    randomBundlesHistory?: AccountRandomBundleHistory;
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
    popupActive: IPopup;
    transaction: number[];
    currentPage: ICurrentPage;
    isTrusted: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        randomBundlesHistory: state.ReducerAccount.randomBundlesHistory,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,
        popupActive: state.ReducerApp.popupActive,
        transaction: state.ReducerAccount.bundlesInTransaction,
        currentPage: state.ReducerApp.currentPage,
        isTrusted: state.ReducerApp.isTrusted,
    };
};

const RandomBundle = (props: IRandomBundle): React.ReactChild | any => {
    const userId = getUserId();
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, shallowEqual);

    const isEnabledFreeOpen = userId && isRandomBundleOpeningEnabled(props.bundle);

    const _isAlreadyPurchased = props.bundle.isPurchased;
    const activeBundle = Account.getRandomBundleChild(state.selectedRandomBundles || {}, props.bundle);
    const noHiddenTitle = userId && !activeBundle.entitlements.length;

    const hasActiveTransaction = !!state.transaction?.includes(activeBundle?.id);

    const _isPeriodicBundle = isPeriodicBundle(props.bundle);
    const _isRandomBundle = isRandomBundle(props.bundle);
    const _commanderPack = isCommanderPack(props.bundle);
    const _isAdmiralPack = isAdmiralPack(props.bundle);

    const isEnabledDoors = !_isPeriodicBundle;
    let isEnablePurchase = !_isAlreadyPurchased;

    const isTimeUp = _isPeriodicBundle && !isEnabledFreeOpen && isTimeUpForPeriodicBundleObtain(props.bundle) && !state.selectedRandomBundles?.[props.bundle.id];
    const _isFree = isFreeBundle(props.bundle);

    const [isPromoTimerCompeted, setPromoTimerCompleted] = useState(!isBundleDisabledByPromoTimer(props.bundle));

    const getPreviewVideo = (activeBundle: IBundle, parentBundle: IBundle) => {
        if (!activeBundle.parentBundleId) {
            return parentBundle.previewCardVideo || null;
        }

        return activeBundle.previewCardVideo || null;
    };

    function completePromoTimer() {
        if (isPromoTimerCompeted) return;
        setPromoTimerCompleted(true);
    }

    if (isTimeUp && userId) {
        isEnablePurchase = false;
    }

    const goToBundle = () => {
        playCardClickSound();

        const categoryLinkName = state.currentPage?.name;
        openBundleByUrl(categoryLinkName, props.bundle.id);
    };

    const purchase = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (_isAlreadyPurchased || (_isPeriodicBundle && isTimeUp)) {
            goToBundle();
            return;
        }

        const purchaseProcessor = new PurchaseProcessor(props.bundle);
        if (!purchaseProcessor.isPurchaseAvailable()) {
            return;
        }

        if (isEnabledFreeOpen) {
            purchaseProcessor.chooseRandomBundle().then((data) => {
                goToBundle();
            });
            return;
        }

        if (_isPeriodicBundle) {
            return purchaseProcessor.purchasePeriodicBundle(activeBundle);
        }

        purchaseProcessor.purchaseBundleFromPopup(activeBundle, null, null, _isFree);
    };

    const _isDisabledPurchasePeriodicBundle = userId && isPromoTimerCompeted && !isEnabledFreeOpen && _isPeriodicBundle && isPeriodicBundlePurchaseDisabled(props.bundle) && !_isAlreadyPurchased;
    const periodicBundleNextDate = isTimeUp ? null : _isDisabledPurchasePeriodicBundle && getNextTimeForPeriodicBundle(props.bundle);

    const onClick = (event: React.MouseEvent) => {
        if (isEnabledFreeOpen) {
            purchase(event);
            return;
        }

        if (!userId) {
            return redirectToLogin();
        }

        goToBundle();
    };

    const isEnableGlowEffect = isEnableShinyForBundle(props.bundle);

    const contentClassNames = classNames(styles.content, {
        [styles.enabled]: isEnablePurchase,
        [styles.noHiddenTitle]: isEnabledFreeOpen || noHiddenTitle,
    });

    activeBundle && PreCacheImage.add(activeBundle.icons?.small);

    const bundleWidth = props.width || props.bundle.sizeInGrid;

    const bundleClassNames = classNames(
        styles.bundle,
        {
            [styles.disabledByTimer]: !isPromoTimerCompeted,
            [styles.admiralPack]: _isAdmiralPack,
            [styles.commander]: _commanderPack,
            [styles.alreadyPurchased]: !isEnablePurchase,

            [styles.periodic]: isEnablePurchase && _isPeriodicBundle && !_isDisabledPurchasePeriodicBundle,
            [styles.random]: isEnablePurchase && _isRandomBundle,

            [styles.third]: bundleWidth === BUNDLE_GRID_SIZE.ONE_THIRD,
            [styles.half]: bundleWidth === BUNDLE_GRID_SIZE.HALF,
            [styles.oneFour]: bundleWidth === BUNDLE_GRID_SIZE.ONE_FOUR,
            [styles.threeQuarters]: bundleWidth === BUNDLE_GRID_SIZE.THREE_QUARTERS,
            [styles.twoThirds]: bundleWidth === BUNDLE_GRID_SIZE.TWO_THIRDS,

            'armory__auto--bundle_random': !_isPeriodicBundle,
            'armory__auto--bundle_random_periodic': _isPeriodicBundle && !_isFree,
            'armory__auto--bundle_free_random_periodic': _isPeriodicBundle && _isFree,
        },
        ...(props.bundle.decoration || []),
    );

    React.useEffect(() => {
        const isVisibleSuccessPurchasePopup = state.popupActive?.name === POPUPS_NAME.SUCCESS_PURCHASE && !state.popupActive;
        const totalCount = state.randomBundlesHistory?.[props.bundle.id]?.length;
        const _isAvailableShinyAnimation = isAvailableShinyAnimation(activeBundle, totalCount);
        if (isVisibleSuccessPurchasePopup && _isAvailableShinyAnimation) {
            if (_isAvailableShinyAnimation) {
                dispatch(showParallaxAnimationShip(props.bundle.id));
                Account.markViewedShinyAnimationFromRandomBundle(props.bundle, totalCount);
            }
        }
    }, [state.popupActive]);

    React.useEffect(() => {
        if (_isDisabledPurchasePeriodicBundle && periodicBundleNextDate && new Date().getTime() - getLatestTimeForPeriodicBundle(props.bundle) <= 10000) {
            new PurchaseProcessor(props.bundle).chooseRandomBundle();
        }
    }, []);

    const isAllowedNewLabel = isAllowedNewLabelFromBundle(props.bundle);

    const [isAnimationVisible, setAnimationVisible] = React.useState<boolean>(false);

    const mouseenter = () => {
        !isMobileOrTabletWindow && setAnimationVisible(true);
    };

    const mouseleave = () => {
        !isMobileOrTabletWindow && setAnimationVisible(false);
    };

    const icons = props.bundle.icons as RandomBundleIcons;
    const cardBackgroundUrl = icons.randomCardBackground || props.bundle.icons.medium;
    const classesBundleBackground = classNames(styles.randomBundleBackground, ...(props.bundle.decoration || []));

    return (
        <BundleDecoration bundle={props.bundle}>
            <div className={bundleClassNames} data-id={props.bundle.id} onMouseEnter={mouseenter} onMouseLeave={mouseleave}>
                <PromoTimer bundle={props.bundle} activeClassName={styles.activePromoTimer} onComplete={completePromoTimer}>
                    <LabelDecorator onClick={onClick} id={props.bundle.name} labelTill={props.bundle.labelNewActivityPeriod.activeTill} isEnabled={isAllowedNewLabel} byHover type={LABEL_TYPE.bundle}>
                        <GlowEffectDecorator isEnabled={isEnableGlowEffect} decorations={props.bundle.decoration}>
                            <LabelContainer className={styles.label} isVisible={isAllowedNewLabel} />
                            <HoverVideo
                                previewVideo={getPreviewVideo(activeBundle, props.bundle)}
                                video={props.bundle.bundleCardHoverVideo}
                                className={contentClassNames}
                                parentClassName={styles.bundle}
                                muted={!state.isTrusted}
                            >
                                {cardBackgroundUrl && <img className={classesBundleBackground} src={cardBackgroundUrl} />}
                                <RandomBundleImage
                                    isEnabledFreeOpen={isEnabledFreeOpen}
                                    bundle={props.bundle}
                                    activeBundle={activeBundle}
                                    isEnabled={isEnabledDoors}
                                    periodicBundleNextDate={periodicBundleNextDate}
                                    isAlreadyPurchased={_isAlreadyPurchased}
                                />
                                <div className={styles.info}>
                                    <RandomBundleInfo
                                        bundle={props.bundle}
                                        activeBundle={activeBundle}
                                        isPeriodicBundle={_isPeriodicBundle}
                                        periodicBundleNextDate={periodicBundleNextDate}
                                        isDisabledPurchasePeriodicBundle={_isDisabledPurchasePeriodicBundle}
                                        selectedRandomBundles={state.selectedRandomBundles}
                                        isTimeUp={isTimeUp}
                                    />
                                    <RandomBundlePurchaseWrapper
                                        bundle={props.bundle}
                                        purchase={purchase}
                                        isEnablePurchase={isEnablePurchase}
                                        isPeriodicBundle={_isPeriodicBundle}
                                        isAlreadyPurchased={_isAlreadyPurchased}
                                        isDisabledPurchasePeriodicBundle={_isDisabledPurchasePeriodicBundle}
                                        isEnabledFreeOpen={isEnabledFreeOpen}
                                        hasActiveTransaction={hasActiveTransaction}
                                    />
                                </div>
                                {isAnimationVisible && <RandomBundleHoverAnimationComponent bundle={props.bundle} />}
                            </HoverVideo>
                        </GlowEffectDecorator>
                    </LabelDecorator>
                </PromoTimer>
            </div>
        </BundleDecoration>
    );
};

export default RandomBundle;
