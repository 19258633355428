import { IGuideStep } from '~/components/WelcomePage/steps';
import {
    IAccountUpdateData,
    IAccountUpdateState,
    IActivateCoupon,
    IAddBundleInTransaction,
    ICompleteAction,
    IDeniedBundleByUniqueItems,
    IOpenRandomBundle,
    IPurchaseBundle,
    IPurchaseRandomBundle,
    IRemoveActivatedCoupon,
    IRemoveBundleInTransaction,
    IRemovePurchasingBundleId,
    IResetCoupon,
    ISetAskConfirmationPurchase,
    ISetPurchasingBundleId,
    IUpdateCouponsFromState,
    IUpdateViewNotification,
    IDevMode,
    IDevModeParams,
    ICloseRandomBundle,
    IGiftStatus,
    AVAILABLE_GIFT_STATUS,
    IMarkLabelIsRead,
    IPushRewardsToStack,
    IMarkRewardAsViewed,
    IUpdateBalance,
    IInitGuideSteps,
    IAddGuideStep,
    IUnshiftGuideStep,
    ISetNotifications,
    IHideGuideStep,
    IUpdateUserAdultFlag,
    ISetInventory,
    ISetStorage,
    IAccountInitialize,
    ISetCoupons,
    ISetUserPcPerformance,
} from '~/Actions/ActionAccountType';

export const ACTIVATE_COUPON = 'ACTIVATE_COUPON';
export const activateCoupon = (id: number, bundleId: number): IActivateCoupon => {
    return {
        type: ACTIVATE_COUPON,
        id,
        bundleId,
    };
};

export const RESET_COUPON = 'RESET_COUPON';
export const resetCoupon = (): IResetCoupon => {
    return {
        type: RESET_COUPON,
    };
};

export const REMOVE_ACTIVATED_COUPON = 'REMOVE_ACTIVATED_COUPON';
export const removeActivatedCoupon = (id: number): IRemoveActivatedCoupon => {
    return {
        type: REMOVE_ACTIVATED_COUPON,
        id,
    };
};

export const PURCHASE_BUNDLE = 'PURCHASE_BUNDLE';
export const purchaseBundle = (id: number, quantity: number): IPurchaseBundle => {
    return {
        type: PURCHASE_BUNDLE,
        id,
        quantity,
    };
};

export const PURCHASE_RANDOM_BUNDLE = 'PURCHASE_RANDOM_BUNDLE';
export const purchaseRandomBundle = (bundleId: number, newSelectedBundleId: number): IPurchaseRandomBundle => {
    return {
        type: PURCHASE_RANDOM_BUNDLE,
        bundleId,
        newSelectedBundleId,
    };
};

export const ACCOUNT_UPDATE_STATE = 'ACCOUNT_UPDATE_STATE';
export const accountUpdateState = (data: IAccountUpdateData, coupons: ICoupon[]): IAccountUpdateState => {
    return {
        type: ACCOUNT_UPDATE_STATE,
        data,
        coupons,
    };
};

export const DENIED_BUNDLES_BY_UNIQUE_ITEMS = 'DENIED_BUNDLES_BY_UNIQUE_ITEMS';
export const deniedBundleByUniqueItems = (bundleId: number): IDeniedBundleByUniqueItems => {
    return {
        type: DENIED_BUNDLES_BY_UNIQUE_ITEMS,
        bundleId,
    };
};

export const UPDATE_VIEW_NOTIFICATION = 'UPDATE_VIEW_NOTIFICATION';
export const updateViewNotification = (name: string): IUpdateViewNotification => {
    return {
        type: UPDATE_VIEW_NOTIFICATION,
        name,
    };
};

export const SET_ASK_CONFIRMATION_PURCHASE = 'SET_ASK_CONFIRMATION_PURCHASE';
export const setAskConfirmationPurchase = (value: boolean): ISetAskConfirmationPurchase => {
    return {
        type: SET_ASK_CONFIRMATION_PURCHASE,
        value,
    };
};

export const ADD_BUNDLE_IN_TRANSACTION = 'ADD_BUNDLE_IN_TRANSACTION';
export const addBundleInTransaction = (bundleIds: number[]): IAddBundleInTransaction => {
    return {
        type: ADD_BUNDLE_IN_TRANSACTION,
        bundleIds,
    };
};

export const REMOVE_BUNDLE_IN_TRANSACTION = 'REMOVE_BUNDLE_IN_TRANSACTION';
export const removeBundleInTransaction = (bundleId: number): IRemoveBundleInTransaction => {
    return {
        type: REMOVE_BUNDLE_IN_TRANSACTION,
        bundleId,
    };
};

export const COMPLETE_ACTION = 'COMPLETE_ACTION';
export const completeAction = (action: string): ICompleteAction => {
    return {
        type: COMPLETE_ACTION,
        action,
    };
};

export const UPDATE_COUPONS_FROM_STATE = 'UPDATE_COUPONS_FROM_STATE';
export const updateCouponsFromState = (): IUpdateCouponsFromState => {
    return {
        type: UPDATE_COUPONS_FROM_STATE,
    };
};

export const SET_PURCHASING_BUNDLE_ID = 'SET_PURCHASING_BUNDLE_ID';
export const setPurchasingBundleId = (bundleId: number): ISetPurchasingBundleId => {
    return {
        type: SET_PURCHASING_BUNDLE_ID,
        bundleId,
    };
};

export const REMOVE_PURCHASING_BUNDLE_ID = 'REMOVE_PURCHASING_BUNDLE_ID';
export const removePurchasingBundleId = (): IRemovePurchasingBundleId => {
    return {
        type: REMOVE_PURCHASING_BUNDLE_ID,
    };
};

export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const initializeAccount = (bundles: IBundleList): IAccountInitialize => {
    return {
        type: ACCOUNT_INITIALIZE,
        bundles,
    };
};

export const OPEN_BUNDLE = 'OPEN_BUNDLE';
export const openRandomBundle = (bundleId: number): IOpenRandomBundle => {
    return {
        type: OPEN_BUNDLE,
        bundleId,
    };
};

export const DEV_MODE = 'DEV_MODE';
export const devMode = (params: IDevModeParams): IDevMode => {
    return {
        type: DEV_MODE,
        params,
    };
};

export const CLOSE_RANDOM_BUNDLE = 'CLOSE_RANDOM_BUNDLE';
export const closeRandomBundle = (bundleId: number): ICloseRandomBundle => {
    return {
        type: CLOSE_RANDOM_BUNDLE,
        bundleId,
    };
};

export const MARK_LABEL_AS_READ = 'MARK_LABEL_AS_READ';
export const markLabelAsRead = (name: string): IMarkLabelIsRead => {
    return {
        type: MARK_LABEL_AS_READ,
        name,
    };
};

export const GIFT_STATUS = 'GIFT_STATUS';
export const giftStatus = (status: AVAILABLE_GIFT_STATUS | boolean, gifts: IGift[], secretGifts: IGift[] = []): IGiftStatus => {
    return {
        type: GIFT_STATUS,
        status: Number(status) as AVAILABLE_GIFT_STATUS,
        gifts,
        secretGifts,
    };
};

export const PUSH_REWARDS_TO_STACK = 'PUSH_REWARDS_TO_STACK';
export const pushRewardsToStack = (rewards: IReward[]): IPushRewardsToStack => {
    return {
        type: PUSH_REWARDS_TO_STACK,
        rewards,
    };
};

export const MARK_REWARD_AS_VIEWED = 'MARK_REWARD_AS_VIEWED';
export const markRewardAsViewed = (reward: IReward): IMarkRewardAsViewed => {
    return {
        type: MARK_REWARD_AS_VIEWED,
        reward,
    };
};

export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const updateBalance = (balance: IBalance): IUpdateBalance => {
    return {
        type: UPDATE_BALANCE,
        balance,
    };
};

export const INIT_GUIDE_STEPS = 'INIT_GUIDE_STEPS';
export const initGuideSteps = (guideSteps: IGuideStep[]): IInitGuideSteps => {
    return {
        type: INIT_GUIDE_STEPS,
        guideSteps,
    };
};

export const ADD_GUIDE_STEP = 'ADD_GUIDE_STEP';
export const addGuideStep = (step: IGuideStep): IAddGuideStep => {
    return {
        type: ADD_GUIDE_STEP,
        step,
    };
};

export const UNSHIFT_GUIDE_STEP = 'UNSHIFT_GUIDE_STEP';
export const unshiftGuideStep = (step: IGuideStep): IUnshiftGuideStep => {
    return {
        type: UNSHIFT_GUIDE_STEP,
        step,
    };
};

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const setNotifications = (notifications: INotification[], categories: ICategories, bundles: IBundleList): ISetNotifications => {
    return {
        type: SET_NOTIFICATIONS,
        notifications,
        categories,
        bundles,
    };
};

export const HIDE_GUIDE_STEP = 'HIDE_GUIDE_STEP';
export const hideGuideStep = (step: IGuideStep): IHideGuideStep => {
    return {
        type: HIDE_GUIDE_STEP,
        step,
    };
};

export const SET_USER_ADULT_FLAG = 'SET_USER_ADULT_FLAG';
export const updateUserAdultFlag = (): IUpdateUserAdultFlag => {
    return {
        type: SET_USER_ADULT_FLAG,
    };
};

export const SET_INVENTORY = 'SET_INVENTORY';
export const setInventory = (inventory: Inventory): ISetInventory => {
    return {
        type: SET_INVENTORY,
        inventory,
    };
};

export const SET_STORAGE = 'SET_STORAGE';
export const setStorage = (storage: StorageInventory): ISetStorage => {
    return {
        type: SET_STORAGE,
        storage,
    };
};

export const SET_COUPONS = 'SET_COUPONS';
export const setCoupons = (coupons: ICoupon[], categories: ICategories): ISetCoupons => {
    return {
        type: SET_COUPONS,
        coupons,
        categories,
    };
};

export const SET_USER_PC_PERFORMANCE = 'SET_USER_PC_PERFORMANCE';
export const setUserPcPerformance = (isLow: boolean): ISetUserPcPerformance => {
    return {
        type: SET_USER_PC_PERFORMANCE,
        isLow,
    };
};
