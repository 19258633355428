import { isNotAvailableBundleByCategory, isParagonBundle } from '~/utils/bundles';
import { sort } from 'fast-sort';
import { clearString, normalizeString } from '~/utils/normalization';

export const getSearchResults = (value: string, bundles: IBundleList, categories: ICategories): ISearchResultItem[] => {
    const results: ISearchResultItem[] = [];
    const normalizedValue = normalizeString(clearString((value || '').toLowerCase()));

    Object.values(bundles).forEach((bundle: any) => {
        if (isNotAvailableBundleByCategory(bundle, categories)) {
            return;
        }
        if (bundle.normalizedTitle && bundle.normalizedTitle.includes(normalizedValue)) {
            results.push({
                bundleId: bundle.id,
                category: bundle.category,
                isParagonBundle: isParagonBundle(bundle),
                title: bundle.title,
                normalizedTitle: bundle.normalizedTitle,
            });
        }
    });

    return sort(results).asc((item) => item.normalizedTitle);
};
