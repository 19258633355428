import * as React from 'react';
import classNames from 'classnames';
import styles from '../common.scss';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import SignalPerks from '~/components/SignalPerks/SignalPerks';
import { getAmountString } from '~/Layouts/BundlePage/helpers';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import Spoiler from '~/components/Spoiler/Spoiler';
import { ManagerData } from '~/core/ManagerData';
import { ItemTypes, PresentationStyles } from '@wg/wows-entities/const';
import { BUNDLE_TYPE } from '~/utils/bundles';

interface ISignalSubTitleContentProps {
    item: IBundleEntity;
    quantity: number;
}

export const SignalSubTitleContent = (props: ISignalSubTitleContentProps) => {
    const primaryItem = props.item;
    const amount = primaryItem.amount * (props.quantity || 1);
    const text = getAmountString(amount, '<b>', '</b>');
    const signalHandler = BUNDLE_TYPE[primaryItem.type as ItemTypes.SIGNAL];

    return (
        <div className={styles.flex}>
            <div className={styles.preBundleTitle}>
                <SignalPerks signalId={primaryItem.identifier} signalType={signalHandler(primaryItem)} />
            </div>
            <div className={styles.preBundleTitle} dangerouslySetInnerHTML={{ __html: text }} />
            <InventoryCounterContainer item={primaryItem} className={classNames(styles.preBundleTitle, styles.inventoryWidget)} size={InventoryCounterSize.FULL} />
        </div>
    );
};

interface ISignalContent {
    item: IBundleEntity;
    renderSpoiler?: boolean;
}

const SignalContent = (props: ISignalContent) => {
    const data = ManagerData.getItem(props.item.identifier);
    if (!data?.ttc) {
        return null;
    }

    if (props.renderSpoiler) {
        return (
            <Spoiler
                title={
                    <div className={classNames(styles.contentAdditionalBlock)}>
                        <div className={styles.contentSpoilerTitle}>
                            <WoWSEntity
                                key={props.item.identifier}
                                type={props.item.type}
                                id={props.item.identifier}
                                presentation={{
                                    withText: true,
                                    withoutAssetIcon: true,
                                }}
                            />
                        </div>
                    </div>
                }
                content={
                    <div className={classNames(styles.contentBlock, styles.borderBottom, styles.spoilerContent)}>
                        <div className={styles.spoilerSubTitle}>{t('Характеристики: ')}</div>
                        <WoWSEntity
                            type={props.item.type}
                            id={props.item.identifier}
                            className={styles.ttcText}
                            presentation={{
                                style: PresentationStyles.TTC,
                            }}
                        />
                    </div>
                }
            />
        );
    }

    return (
        <div className={styles.borderBottom}>
            <div className={classNames(styles.contentBlock, styles.borderBottom)}>
                <div className={styles.contentBlockTitle}>{t('Характеристики: ')}</div>
                <WoWSEntity
                    type={props.item.type}
                    id={props.item.identifier}
                    className={styles.ttcText}
                    presentation={{
                        style: PresentationStyles.TTC,
                    }}
                />
            </div>
        </div>
    );
};

export default SignalContent;
