import WowsCommerce from '@wg/wows-commerce';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { assets } from '@wg/wows-entities/const';
import {
    ISetFastGoldLib,
    ISetFastGoldProducts,
    ISetWowsCommerce,
    ISetWsmartCurrentProduct,
    IShopUnavailable,
    ISetWsmartPaymentMethod,
    ISetTsvManagementUrl,
    ISetTsv,
    ISetBillingAddressSettings,
    ISetRegionalInfo,
} from '~/Actions/ActionWSMartType';
import { ClientPaymentMethod, PrepareTsv } from '@wg/wows-commerce/api/platform/web/webPreparePurchase';
import { BillingAddress, RegionalInfo } from '@wg/wows-commerce/api/user';

export const SET_WOWS_COMMERCE_LIB = 'SET_WOWS_COMMERCE_LIB';
export const setWowsCommerce = (wowsCommerce: WowsCommerce): ISetWowsCommerce => {
    return {
        type: SET_WOWS_COMMERCE_LIB,
        wowsCommerce,
    };
};

export const SET_FAST_GOLD_LIB = 'SET_FAST_GOLD_LIB';
export const setFastGoldLib = (fastGold: WowsCommerce): ISetFastGoldLib => {
    return {
        type: SET_FAST_GOLD_LIB,
        fastGold,
    };
};

export const SET_FAST_GOLD_PRODUCTS = 'SET_FAST_GOLD_PRODUCTS';
export const setFastGoldProducts = (products: ProductEntity[]): ISetFastGoldProducts => {
    const result: WsmartGoldProductWrapper[] = [];

    products.forEach((product) => {
        const entitlements = product.entitlements || [];
        const goldTotal = entitlements.reduce((sum, current) => {
            if (current.type === assets.GOLD) {
                sum += current.amount;
            }
            return sum;
        }, 0);

        if (goldTotal > 0) {
            result.push({
                product: product,
                totalGoldAmount: goldTotal,
            });
        }
    });

    result.sort((a, b) => a.totalGoldAmount - b.totalGoldAmount);

    return {
        type: SET_FAST_GOLD_PRODUCTS,
        products: result,
        defaultProducts: _getFastGoldDefaultProducts(result),
    };
};

const _getFastGoldDefaultProducts = (productWrappers: WsmartGoldProductWrapper[]) => {
    const defaults = [2500, 12500, 25000];

    let result = productWrappers.filter((value) => defaults.includes(value.totalGoldAmount));
    if (result.length === 3) {
        return result;
    }

    const len = productWrappers.length;
    if (len > 3) {
        result = [productWrappers[0], productWrappers[Math.floor(len / 2)], productWrappers[len - 1]];
    } else {
        result = productWrappers;
    }

    return result;
};

export const SHOP_UNAVAILABLE = 'SHOP_UNAVAILABLE';
export const shopUnavailable = (): IShopUnavailable => {
    return {
        type: SHOP_UNAVAILABLE,
    };
};

export const SET_BILLING_ADDRESS_SETTINGS = 'SET_BILLING_ADDRESS_SETTINGS';
export const setBillingAddressSettings = (settings: BillingAddress): ISetBillingAddressSettings => {
    return {
        type: SET_BILLING_ADDRESS_SETTINGS,
        settings,
    };
};

export const SET_WSMART_CURRENT_PRODUCT = 'SET_WSMART_CURRENT_PRODUCT';
export const setWsmartCurrentProduct = (currentProductId: string): ISetWsmartCurrentProduct => {
    return {
        type: SET_WSMART_CURRENT_PRODUCT,
        currentProductId: currentProductId,
    };
};

export const SET_WSMART_PAYMENT_METHOD = 'SET_WSMART_PAYMENT_METHOD';
export const setWsmartPaymentMethod = (paymentMethod: ClientPaymentMethod): ISetWsmartPaymentMethod => {
    return {
        type: SET_WSMART_PAYMENT_METHOD,
        paymentMethod,
    };
};

export const SET_TSV_MANAGEMENT_URL = 'SET_TSV_MANAGEMENT_URL';
export const setTsvManagementUrl = (managementUrl: string): ISetTsvManagementUrl => {
    return {
        type: SET_TSV_MANAGEMENT_URL,
        managementUrl,
    };
};

export const SET_TSV = 'SET_TSV';
export const setTsv = (tsv: PrepareTsv): ISetTsv => {
    return {
        type: SET_TSV,
        tsv,
    };
};

export const SET_REGIONAL_INFO = 'SET_REGIONAL_INFO';
export const setRegionalInfo = (regional: Nullable<RegionalInfo>): ISetRegionalInfo => {
    return {
        type: SET_REGIONAL_INFO,
        regional,
    };
};
