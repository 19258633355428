import { sort } from 'fast-sort';
import { isEnabledPortForItemInsideBundle, isEnabledPortFromAuction, openPortByAuction, openPortByBundleItem } from '~/settings/port';
import { getValueFromInventory } from '~/utils/inventory';
import Account from '~/account/Account';
import { PortProps } from './Port';

export enum PortType {
    DEFAULT,
    PURCHASE,
}

export enum PortDataType {
    DEFAULT,
    BUNDLE,
    AUCTION_LOT,
}

export interface IPortContainItem {
    itemId: number;
    id: number;
    type: string;

    amount: number;
    customisation: {
        shipId?: number;
        points?: number;
    };
    customContent?: {
        title: string;
        description?: string;
    };

    isBonus?: boolean;
    isEnabledPortPreview?: boolean;
    portPreviewOnClick?: () => void;
}

//For entitlements
export function sortEntitlements(items: Array<IBundleEntity>) {
    return sort([...items]).by([
        {
            desc: (i) => isEnabledPortForItemInsideBundle(i),
        },
        {
            asc: (i) => items.findIndex((item) => item.identifier === i.identifier),
        },
    ]);
}

//For bunles
export function sortBundleItems(bundle: IBundle): IBundleEntity[] {
    if (!bundle.entitlements) {
        return [];
    }

    return sortEntitlements([...bundle.entitlements]);
}

//For auctions
export function sortLotRewardsItems(rewards: IGameRewards[]): IGameRewards[] {
    return sort(rewards).desc((item) => {
        return isEnabledPortFromAuction(item.type, item.id);
    });
}

export const prepareItems = (data: PortProps): IPortContainItem[] | null => {
    switch (data.dataType) {
        case PortDataType.BUNDLE:
            const { bundle } = data;

            return sortBundleItems(bundle).map((item) => {
                const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item?.type, item?.identifier, item.customisation?.shipId);

                const customContent: IPortContainItem['customContent'] = item.customTitle
                    ? {
                          title: item.customTitle,
                          description: item.customDescription,
                      }
                    : undefined;

                return {
                    itemId: item.identifier,
                    id: item.identifier,
                    type: item.type,

                    amount: item.amount,
                    customisation: {
                        ...(item.customisation || {}),
                        inventoryCount: !item.isUnique && inventoryCount,
                        isExistsInInventory: item?.isUnique && isExistsItemInInventory,
                        shipShardsId: item.shipShardsId,
                    },
                    customContent,

                    isBonus: item.isBonus,
                    isEnabledPortPreview: isEnabledPortForItemInsideBundle(item, bundle),
                    portPreviewOnClick: () => {
                        openPortByBundleItem({ identifier: item.identifier, type: item.type, shipId: item.customisation?.shipId }, bundle.id, true);
                    },
                };
            });
        case PortDataType.AUCTION_LOT:
            const { lot, inventory } = data;

            return sortLotRewardsItems(lot.gameRewards).map((reward) => {
                return {
                    itemId: reward.id,
                    id: reward.id,
                    type: reward.type,
                    amount: reward.amount,
                    customisation: {
                        shipId: reward.customisation?.ship_id,
                        points: reward.customisation?.points,
                        inventoryCount: getValueFromInventory(inventory, reward.type, reward.id),
                    },
                    isEnabledPortPreview: isEnabledPortFromAuction(reward.type, reward.id),
                    portPreviewOnClick: () => {
                        openPortByAuction({ identifier: reward.id, shipId: reward.customisation?.ship_id, type: reward.type }, lot.id);
                    },
                };
            });
        case PortDataType.DEFAULT:
        default:
            return null;
    }
};

export const getItemById = (id: number, items: IPortContainItem[] = []): IPortContainItem => {
    return items?.find((item) => item.id?.toString() === id?.toString());
};

export const getTotalCountPreviewItems = (items: IPortContainItem[]) => {
    return items.reduce((count: number, item) => {
        if (item.isEnabledPortPreview) {
            count += 1;
        }
        return count;
    }, 0);
};
