import * as React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { changeVisiblePopup } from '~/Actions/ActionApp';
import { ITradeItem } from '~/types/tradein';
import { openPortByDefaultItem } from '~/settings/port';
import { isInGameBrowser } from '~/utils/utils';
import { t } from '~/utils/localization';
import { getShipFeatures } from '~/utils/getShipFeatures';

import { items, ItemTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import MainFeaturesLayout from '~/Layouts/Features/Views/MainFeaturesLayout/MainFeaturesLayout';
import { POPUPS_NAME } from '~/components/PopupManager';
import PortPreviewButton, { PortPreviewTemplate } from '~/components/PortPreviewButton/PortPreviewButton';

import styles from './TradeInHeader.scss';

interface ITradeInHeader {
    item: ITradeItem;
    className?: string;
}

const HeaderContent = ({ item }: ITradeInHeader) => {
    const dispatch = useDispatch();

    //* State for ship features (can't do this in switch :) );
    // TODO: move ship code in custom component
    const [shipFeaturesTags, setShipFeaturesTags] = React.useState<IFeatureTag[] | undefined>();
    React.useLayoutEffect(() => {
        item.type === ItemTypes.VEHICLES && getShipFeatures(item.identifier).then((data) => setShipFeaturesTags(data.featuresTags));
    }, []);

    switch (item?.type) {
        case items.VEHICLES:
            const onClick = () => dispatch(changeVisiblePopup(POPUPS_NAME.ITEM_DESCRIPTION_POPUP, true, { item }));
            return (
                <div className={styles.featured}>
                    <div className={styles.featuredTitle}>{t('Ключевые особенности корабля')}</div>
                    <MainFeaturesLayout shipId={item.identifier} featuresTags={shipFeaturesTags} />
                    <div className={styles.additionalLink}>
                        <span onClick={onClick}>{t('Подробнее')}</span>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

const getSubContent = (item: ITradeItem) => {
    switch (item?.type) {
        case items.VEHICLES:
            return (
                <>
                    {isInGameBrowser && (
                        <div className={styles.subContentBlock}>
                            <PortPreviewButton
                                template={PortPreviewTemplate.flat}
                                onClick={() => {
                                    openPortByDefaultItem({
                                        identifier: item.identifier,
                                        shipId: item.additionalData?.shipId,
                                        type: item.type,
                                    });
                                }}
                            />
                        </div>
                    )}
                </>
            );
    }

    return null;
};

const TradeInHeader = ({ item, className }: ITradeInHeader) => {
    const classesContent = classNames(
        styles.content,
        {
            [styles.hidden]: !item?.identifier,
        },
        className,
    );

    return (
        <div className={classesContent}>
            <div className={styles.header}>
                <div className={styles.title}>{!!item && <WoWSEntity type={item.type} id={item.identifier} presentation={{ renderWithoutFade: true }} />}</div>
                <div className={styles.subContent}>{getSubContent(item)}</div>
            </div>
            {item && <HeaderContent item={item} />}
        </div>
    );
};

export default TradeInHeader;
