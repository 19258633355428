import classNames from 'classnames';
import styles from './ShipComplexity.scss';
import { t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import React from 'react';
import { getShipFeatures } from '~/utils/getShipFeatures';

const MAX_COMPLEXITY = 3;

type ShipComplexityProps = {
    withoutTooltip?: boolean;
    // TODO: delete the line below after redesign title of ship bundle page (WEB-2640)
    isShipBundlePageSubTitle?: boolean;
} & (ShipComplexityAutoProps | ShipComplexityManualProps);

interface ShipComplexityAutoProps {
    complexity: IFeatureItemComplexity;
    shipId?: never;
}

interface ShipComplexityManualProps {
    complexity?: never;
    shipId: string | number;
}

export function ShipComplexity({ withoutTooltip, isShipBundlePageSubTitle, complexity, shipId }: ShipComplexityProps) {
    const [shipComplexity, setShipComplexity] = React.useState<IFeatureItemComplexity | undefined>();

    React.useLayoutEffect(() => {
        if (complexity) {
            setShipComplexity(complexity);
        }

        if (shipId) {
            getShipFeatures(shipId).then((data) => setShipComplexity(data.complexity));
        }
    }, [complexity, shipId]);

    return shipComplexity ? (
        <DivTooltip
            isDisabled={!!withoutTooltip}
            tooltipBody={
                <div className={styles.tooltipContent}>
                    <div className={styles.tooltipContent__header}>{shipComplexity.header}</div>
                    <div className={styles.divider} />
                    <div className={styles.tooltipContent__description}>{shipComplexity.description}</div>
                </div>
            }
        >
            <div
                className={classNames('shipComplexity', styles.wrapper, {
                    [styles.wrapper_shipBundlePageHeader]: !!isShipBundlePageSubTitle,
                })}
            >
                <span className={styles.text}>{t('Сложность:')}</span>
                <div className={classNames(styles.iconsWrapper)}>
                    {new Array(MAX_COMPLEXITY).fill(0).map((_, index) => {
                        const iconStyles: React.CSSProperties = {
                            backgroundImage: `url(${index < shipComplexity.level ? shipComplexity.icons.filled : shipComplexity.icons.empty})`,
                        };

                        return <div key={`ship_complexity_icon_${index}`} className={styles.iconWrapper} style={iconStyles} />;
                    })}
                </div>
            </div>
        </DivTooltip>
    ) : null;
}
