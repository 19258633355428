import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { getParsedTime } from '~/utils/time';
import classNames from 'classnames';
import styles from '~/components/Menu/Menu.scss';
import { isLabelNewShownForCategory } from '~/utils/labels';
import { playButtonClickSound } from '~/api/WoWsClient';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { NavLink, useLocation } from 'react-router-dom';
import { urlsMap } from '~/utils/menu';
import { isActiveMenuItem } from '~/components/Footer/MobileMenuFooter';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import GlowEffectDecorator, { ParticlesAnimation } from '~/components/GlowEffect/GlowEffectDecorator';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { scrollTop } from '~/utils/scrollTop';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { IUpdateActiveFilterPreset } from '~/Actions/ActionAppType';
import { changeVisiblePopup, updateActiveFilterPreset } from '~/Actions/ActionApp';
import CountDown from '~/components/CountDown/CountDown';
import { CATEGORIES } from '~/const';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import useShinyCategory from '~/hooks/useShinyCategory';
import { CategoryType } from '~/types/category';
import store from '~/Store';
import { POPUPS_NAME } from '~/components/PopupManager';
import { Clock } from '../Clock/Clock';

interface IMenuItem {
    item: ICategory;
    url: string;
    guideNames?: GUIDE_NAMES[];
    timer?: ITimerData;
    type?: CategoryType;
    hasParentCategory?: boolean;
}

interface IStateSelector {
    viewedLabels: string[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        viewedLabels: state.ReducerAccount.viewedLabels,
    };
};

const MenuItem = (props: IMenuItem) => {
    const { item, type } = props;
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const location = useLocation();
    const dispatch = useDispatch();
    const [isLabelNewAllowed, setLabelNewAllowed] = React.useState(isLabelNewShownForCategory(item.name));
    const isShiny = useShinyCategory(item.name as ICategoryList);

    const isTimerShown = !!(props.timer && props.timer.activeTill);
    const timerDate = isTimerShown ? props.timer.activeTill.getTime() : 0;
    const timerDaysLeft = isTimerShown ? getParsedTime(Math.floor((timerDate - Date.now()) / 1000) || 0)?.days : 0;

    const iconClassNames = classNames(
        styles.menuItemIcon,
        styles[`menu-${item.name}`] || styles[`menu-${item.parentIconClassName}`],
        styles[`${item.theme}-back`] || styles[`${item.parentIconClassName}-back`],
    );

    const descriptionClassNames = classNames({
        [styles.categoryTooltipDescription]: !!props.timer,
    });

    const footer = [];
    if (props.item?.description) {
        footer.push(<div className={descriptionClassNames} dangerouslySetInnerHTML={{ __html: props.item?.description }} />);
    }

    if (isTimerShown) {
        const timerTitle = props.timer.title;

        footer.push(
            <div className={styles.timerWrapper}>
                <Clock wrapperClassName={styles.timerClock} />
                <div className={styles.timerInfo}>
                    <div className={styles.timerTitle}>{timerTitle}</div>
                    <div>
                        <CountDown date={timerDate} className={styles.timerLabel} notice={timerDaysLeft < 1} withoutIcon />
                    </div>
                </div>
            </div>,
        );
    }

    const icon: any = item.icon
        ? {
              WebkitMaskImage: `url(${item.icon})`,
              '--url': `url(${item.icon})`,
          }
        : {};

    React.useEffect(() => {
        setLabelNewAllowed(isLabelNewShownForCategory(item.name));
    }, [state.viewedLabels]);

    const onClick = () => {
        playButtonClickSound();
        if (type === CATEGORIES.LANDING) {
            store.dispatch(changeVisiblePopup(POPUPS_NAME.IFRAME_POPUP, true, { iframeUrl: item.value }));
        } else {
            scrollTop(0);
            dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(null));
        }
    };

    if (type === CATEGORIES.LANDING) {
        return (
            <LabelDecorator id={item.labelNewActivityPeriod?.activeTill ? item.name : null} isEnabled={isLabelNewAllowed} type={LABEL_TYPE.category}>
                <GuideDecorator names={props.guideNames}>
                    <div
                        className={classNames(styles.menuItem, styles[item.theme], 'armory__auto--menu_item', {
                            [styles.isShiny]: isShiny,
                        })}
                        onClick={onClick}
                        data-category-name={item.name}
                    >
                        <DivTooltip
                            className={classNames(styles.tooltipContainer, styles[`${item.theme}-item`])}
                            style={{ cursor: 'pointer' }}
                            tooltipBody={<DefaultTooltip title={item.title} footer={footer.length ? footer : null} />}
                            position={'center-right'}
                            fixed
                        >
                            <GlowEffectDecorator
                                isEnabled={isShiny}
                                className={styles.particles}
                                withoutSun={true}
                                particles={{
                                    value: 20,
                                    config: ParticlesAnimation.CIRCLE,
                                }}
                            >
                                <div className={iconClassNames}>
                                    <span
                                        style={icon}
                                        className={classNames(styles.icon, styles[`${item.theme}-icon`], {
                                            [styles.coloredCategoryIcon]: !!item?.coloredIcon,
                                        })}
                                    />
                                </div>
                            </GlowEffectDecorator>
                            <LabelContainer className={styles.label} isVisible={isLabelNewAllowed} />
                        </DivTooltip>
                        {isTimerShown && (
                            <Clock
                                wrapperClassName={classNames(styles.clockIcon, {
                                    [styles.clockIcon_alarm]: timerDaysLeft < 1,
                                })}
                            />
                        )}
                    </div>
                </GuideDecorator>
            </LabelDecorator>
        );
    }

    return (
        <LabelDecorator id={item.labelNewActivityPeriod?.activeTill ? item.name : null} isEnabled={isLabelNewAllowed} type={LABEL_TYPE.category}>
            <GuideDecorator names={props.guideNames}>
                <NavLink
                    to={props.url}
                    end={props.url === urlsMap.home}
                    className={({ isActive }) => {
                        return classNames(styles.menuItem, styles[item.theme], 'armory__auto--menu_item', {
                            [styles.santa]: item.theme === SANTA_PAGE_THEME,
                            [styles.itemMoney]: item.name === CATEGORIES.WSMART,
                            [styles.isShiny]: isShiny,
                            [styles.isActive]: isActive || isActiveMenuItem(props.url, location.pathname),
                        });
                    }}
                    onClick={onClick}
                    data-category-name={item.name}
                >
                    <DivTooltip
                        className={classNames(styles.tooltipContainer, styles[`${item.theme}-item`])}
                        style={{ cursor: 'pointer' }}
                        tooltipBody={
                            <DefaultTooltip
                                title={props.hasParentCategory && item.parentCategoryData.disableFeatured ? item.parentCategoryData.title : item.title}
                                footer={footer.length ? footer : null}
                            />
                        }
                        position={'center-right'}
                        fixed
                    >
                        <GlowEffectDecorator
                            isEnabled={isShiny}
                            className={styles.particles}
                            withoutSun={true}
                            particles={{
                                value: 20,
                                config: ParticlesAnimation.CIRCLE,
                            }}
                        >
                            <div className={iconClassNames}>
                                <span
                                    style={icon}
                                    className={classNames(styles.icon, styles[`${item.theme}-icon`], {
                                        [styles.coloredCategoryIcon__icon]: !!item?.coloredIcon,
                                    })}
                                />
                            </div>
                        </GlowEffectDecorator>
                        <LabelContainer className={styles.label} isVisible={isLabelNewAllowed} />
                    </DivTooltip>
                    {isTimerShown && (
                        <Clock
                            wrapperClassName={classNames(styles.clockIcon, {
                                [styles.clockIcon_alarm]: timerDaysLeft < 1,
                            })}
                        />
                    )}
                </NavLink>
            </GuideDecorator>
        </LabelDecorator>
    );
};

export default React.memo(MenuItem);
