import * as React from 'react';

const useMaskScrollEffect = (ref: React.RefObject<HTMLElement>) => {
    const handleScroll = React.useCallback(() => {
        const scrollTop = ref.current?.scrollTop;
        const maxScrollTop = ref.current?.scrollHeight - ref.current?.offsetHeight;

        const isTopMaskEnabled = scrollTop > 0;
        const isBottomMaskEnabled = scrollTop < maxScrollTop;

        if (ref.current) {
            ref.current.style.webkitMaskImage =
                (isTopMaskEnabled && isBottomMaskEnabled && 'linear-gradient(0deg, rgba(153,153,153,0) 0%, rgba(0,0,0,1) 3%, rgba(0,0,0,1) 97%, rgba(153,153,153,0) 100%)') ||
                (isTopMaskEnabled && 'linear-gradient(0deg, rgba(0,0,0,1) 97%, rgba(153,153,153,0) 100%)') ||
                (isBottomMaskEnabled && 'linear-gradient(0deg, rgba(153,153,153,0) 0%, rgba(0,0,0,1) 3%)') ||
                'none';
        }
    }, [ref]);

    React.useEffect(() => {
        if (!ref || !ref.current) {
            return;
        }

        const element = ref.current;

        handleScroll();

        element.addEventListener('scroll', handleScroll);

        return () => element?.removeEventListener('scroll', handleScroll);
    }, [handleScroll, ref]);
};

export default useMaskScrollEffect;
