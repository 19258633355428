import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import styles from './SubCategoryMenu.scss';
import GlowEffectDecorator, { ParticlesAnimation } from '~/components/GlowEffect/GlowEffectDecorator';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import * as React from 'react';
import { getTabTitle, isCategoryWithoutBundles } from '~/utils/category';
import { isLabelNewShownForCategory } from '~/utils/labels';
import classNames from 'classnames';
import useShinyCategory from '~/hooks/useShinyCategory';
import { CSSProperties, useState } from 'react';

interface SubCategoryMenuItemProps {
    category: ICategory;
    parentCategoryName?: string;
    onClick?: () => void;
    className?: string;
}

export default function SubCategoryMenuItem(props: SubCategoryMenuItemProps) {
    const subCategory = props.category;
    const isFeatured = props.parentCategoryName === subCategory.name;
    const bundleSpliceLength = isFeatured ? 4 : null;
    const isShiny = useShinyCategory(subCategory.name, bundleSpliceLength);

    const [iconImageWidth, setIconImageWidth] = useState(0);
    const iconImageWidthStyles: React.CSSProperties = React.useMemo(() => {
        return {
            minWidth: iconImageWidth,
            backgroundImage: `url(${subCategory.titleImage})`,
        };
    }, [iconImageWidth, subCategory.titleImage]);

    const iconImageSrc = React.useMemo(() => {
        return subCategory?.titleImage;
    }, [subCategory]);

    const onIconImageLoadCallback = React.useCallback<(this: HTMLImageElement) => void>(function () {
        setIconImageWidth(this.naturalWidth);
    }, []);

    // Get natural size of image icon
    React.useLayoutEffect(() => {
        const iconImage = new Image();
        iconImage.addEventListener('load', onIconImageLoadCallback);
        iconImage.src = iconImageSrc;

        return () => {
            iconImage.removeEventListener('load', onIconImageLoadCallback);
        };
    }, [iconImageSrc]);

    if (!subCategory || (!isFeatured && !subCategory?.bundles?.length && !isCategoryWithoutBundles(subCategory))) {
        return null;
    }

    const className = classNames(
        styles.menuItem,
        {
            [styles.isShiny]: isShiny,
        },
        props.className,
        `armory__auto--submenu_item`,
    );
    const style: CSSProperties = {};
    //@ts-ignore
    if (subCategory.tabBorderColor) style['--tabBorderColor'] = subCategory.tabBorderColor;

    const isEnabledLabelNew = isLabelNewShownForCategory(subCategory.name, bundleSpliceLength, isFeatured, true);

    return (
        <LabelDecorator id={subCategory.name} isEnabled={isEnabledLabelNew} type={LABEL_TYPE.category} key={subCategory.name} className={styles.menuItemWrapper} onClick={props.onClick}>
            <GlowEffectDecorator
                isEnabled={isShiny}
                withoutSun={true}
                className={styles.particles}
                particles={{
                    value: 20,
                    config: ParticlesAnimation.CIRCLE,
                }}
            >
                <div className={className} data-sub-menu-category={subCategory.name} style={style}>
                    <div className={styles.categoryTitle}>
                        {subCategory.titleImage ? <span className={styles.titleImage} style={iconImageWidthStyles} /> : <span>{getTabTitle(subCategory, props.parentCategoryName)}</span>}
                        <LabelContainer className={styles.categoryLabel} isVisible={isEnabledLabelNew} />
                    </div>
                </div>
            </GlowEffectDecorator>
        </LabelDecorator>
    );
}
