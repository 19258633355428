import classNames from 'classnames';
import * as React from 'react';
import styles from './SerialBundleIndex.scss';
import { interpolate, t } from '~/utils/localization';

interface ISerialBundleIndex {
    index: number;
    total?: number;
    className?: string;
}

const SerialBundleIndex = ({ index, className, total }: ISerialBundleIndex) => {
    let str: string | number = index + 1;
    if (total) {
        str = `${str}/${total}`;
    }
    return <div className={classNames(styles.wrapper, className)}>{str}</div>;
};

export default SerialBundleIndex;
