import * as React from 'react';
import styles from './Footer.scss';
import { settings } from '~/utils/settings';
import classNames from 'classnames';

interface IPegi {
    isMobile?: boolean;
}

const Pegi = ({ isMobile }: IPegi) => {
    if (!settings.showPegi) {
        return null;
    }

    if (settings.languageCode === 'de') {
        return (
            <a
                href={'https://usk.de/'}
                target={'_blank'}
                className={classNames(styles.uskGreen, {
                    [styles.mobile]: isMobile,
                })}
            />
        );
    }

    return (
        <a
            href={'https://pegi.info/'}
            target={'_blank'}
            className={classNames({
                [styles.pegi]: !isMobile,
                [styles.pegiMobile]: isMobile,
            })}
        />
    );
};

export default Pegi;
