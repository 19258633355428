import History from '~/utils/history';
import store from '~/Store';
import { changeVisiblePopup, changeVisiblePort, setEmotionFromBoxSource, setFetching } from '~/Actions/ActionApp';
import { logInfo } from '~/utils/logging';
import { settings } from '~/utils/settings';
import { urlsMap } from '~/utils/menu';
import { isDevWoWSClient } from '~/utils/devMode';
import { PortDataType, PortType } from '~/components/Port/settings';
import { closePortPreview } from '@wg/web2clientapi/port/closePortPreview';
import { openShipPreviewInPort } from '@wg/web2clientapi/port/openShipPreviewInPort';
import { sendCommand } from '@wg/web2clientapi/core/command';
import { sendAction as web2clientSendAction } from '@wg/web2clientapi/core/action';
import { changeTTXVisibility } from '@wg/web2clientapi/port/changeTTXVisibility';
import { POPUPS_NAME } from '~/components/PopupManager';
import { openCategoryByName } from '~/utils/category';

export {
    playCheckboxSound,
    playDropdownSound,
    playInputSound,
    playButtonClickSound,
    playButtonDialogClickSound,
    playButtonWalletClickSound,
    playButtonGoldClickSound,
    playCheckboxClickSound,
    playDropdownClickSound,
    playTabClickSound,
    playCardClickSound,
    playPaperCardClickSound,
    playExchangeSound,
} from '@wg/web2clientapi/sound';

interface IClientApiData {
    actionId: string;
    enabled?: boolean;
    shipId?: number;
    exteriorId?: number;
    showTTX?: boolean;
    browserHasControl?: boolean;
    soundsetName?: string;
    url?: string;
    visible?: boolean;
}

export enum EMOTION_SOURCES {
    TREASURE_CHAIN = 'treasure',
}

export const openPort = (params: IPort) => {
    const showTTCFlag = params.showTTC || (params.exteriorId !== -1 ? false : !settings.isShipTTXPreviewDisabled);

    store.dispatch(setFetching(true));
    store.dispatch(changeVisiblePort({ ...params, showTTC: showTTCFlag }));

    openShipPreviewInPort(params.shipId, params.exteriorId, showTTCFlag);

    if (isDevWoWSClient()) {
        window.onClientStateChange(JSON.stringify({ actionId: 'startShipPreview' }));
    }
};

export const closePort = () => {
    store.dispatch(changeVisiblePort({ isVisible: false, dataType: PortDataType.DEFAULT, portType: PortType.DEFAULT }));
    changeTTXVisibility(false);
    closePortPreview();
};

export const request = (data: any) => {
    sendCommand(data);
};

export const sendAction = (params: IClientApiData) => {
    logInfo('attempt to execute a request to the client', JSON.stringify(params));
    web2clientSendAction(params);
};

export const setClientStateListener = () => {
    logInfo('client state listener init');

    window.onClientStateChange = (data: string) => {
        const event = JSON.parse(data);

        switch (event.actionId) {
            case 'startShipPreview':
                const portData = store.getState().ReducerApp.port;

                History.navigate(urlsMap.port, {
                    replace: portData?.isVisible,
                });

                store.dispatch(changeVisiblePort({ ...portData, isVisible: true, isLoading: false }));
                store.dispatch(setFetching(false));
                break;

            case 'finishShipPresentationAnimation':
                if (store.getState().ReducerApp.emotionFromBoxSource === EMOTION_SOURCES.TREASURE_CHAIN) {
                    const boxName = store.getState().ReducerTreasure.currentBox?.name;
                    boxName && openCategoryByName(boxName, null, true);
                    store.dispatch(changeVisiblePopup(POPUPS_NAME.TREASURE_SUCCESS_PURCHASE, true));
                }
                store.dispatch(setEmotionFromBoxSource());
                break;

            default:
                break;
        }
    };
};

export const setScrollover = (isOver: boolean) => {
    sendAction({
        actionId: 'updateBrowserOverScrollableState',
        enabled: isOver,
    });
};
