import * as React from 'react';
import SantaBackground from '~/Layouts/Themes/SantaPage/SantaBackground';
import { SANTA_CONFIRM_PURCHASE_VIDEO } from '~/Layouts/Themes/SantaPage/settings';
import styles from './SantaCustomization.scss';

interface ISantaCustomization {
    children: React.ReactChild | React.ReactChild[];
}

const SantaCustomization = ({ children }: ISantaCustomization) => {
    return (
        <React.Fragment>
            <SantaBackground video={SANTA_CONFIRM_PURCHASE_VIDEO} backgroundClassName={styles.santaBackground} isInPopup />
            <>{children}</>
        </React.Fragment>
    );
};

export default SantaCustomization;
