import { changeVisiblePopup } from '~/Actions/ActionApp';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';
import * as React from 'react';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { Processing, Button } from '@wg/wows-react-uikit';
import useClickAway from '~/hooks/useClickAway';
import { useRef } from 'react';
import styles from './SeabattleLoaderPopup.scss';
import { CATEGORIES } from '~/const';
import { openCategoryByName } from '~/utils/category';

interface IStateSelector {
    isError: boolean;
    isEnabled: boolean;
}
function stateSelector(state: State): IStateSelector {
    return {
        isEnabled: state.ReducerSeaBattle.isEnabled,
        isError: state.ReducerSeaBattle.isError,
    };
}

export default function SeabattleLoaderPopup(): React.ReactElement {
    const ref = useRef(null);
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const dispatch = useDispatch();
    const close = () => {
        dispatch(changeVisiblePopup(null));
    };

    useClickAway(ref, close);

    if (state.isEnabled && !state.isError) {
        openCategoryByName(CATEGORIES.SEA_BATTLE);
        close();
    }

    const errorMessage = t('Произошла ошибка. Повторите попытку позже');
    const showError = !!state.isError;
    const showLoader = !state.isEnabled && !state.isError;

    return (
        <Popup onClose={close} renderEscButton={!showLoader}>
            <div className={styles.inner}>
                <div ref={ref}>
                    {showLoader && <Processing isFetching isInside />}
                    {showError && (
                        <>
                            <PopupHeader title={errorMessage} className={styles.errorBlock} />
                            <PopupFooter className={styles.errorBlock}>
                                <Button onClick={close} isFlat>
                                    {t('Закрыть')}
                                </Button>
                            </PopupFooter>
                        </>
                    )}
                </div>
            </div>
        </Popup>
    );
}
