import * as React from 'react';
import { t } from '~/utils/localization';
import ActiveBid from '~/components/Popups/Auction/ActiveBid/ActiveBid';
import { Interpolate } from '@wg/wows-react-uikit';

interface IAuctionCurrentBid {
    bid: IBids;
    className?: string;
}

const AuctionCurrentBid = ({ bid, className }: IAuctionCurrentBid) => {
    return <Interpolate str={t('Ваша ставка: %(bet)s')} bet={<ActiveBid currency={bid.bid.currency} amount={bid.bid.amount} withoutTooltip={false} className={className} />} />;
};

export default AuctionCurrentBid;
