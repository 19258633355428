import React from 'react';
import store from '~/Store';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import { State } from '~/Reducers';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import Account from '~/account/Account';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import { items as ITEMS_TYPES } from '@wg/wows-entities/const';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { getLevelFromRomanFormat } from '~/utils/utils';
import { isCurrency } from '~/utils/currencies';
import { t } from '~/utils/localization';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import inventory from '~/queries/inventory';
import { parsePlaceholderInBundleDescription } from '~/Layouts/BundlePage/helpers';
import { onClickByLink } from '~/Layouts/BundlePage/BundlePageDescription/handlers';
import { AdditionalFeatures, MainFeatures } from '~/Layouts/BundlePage/Contents/ShipsContent';
import { LoadAmountToReward } from '~/components/Bundle/LoadAmountToReward';
import ShipConsumables from '~/components/ShipConsumables/ShipConsumables';
import { Popup, PopupHeader, PopupBody, PopupFooter } from '~/components/Popup/Popup';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import { ShipComplexity } from '~/components/ShipComplexity/ShipComplexity';

import styles from './MrpsShipDescriptionPopup.scss';
import { ShipWeapons } from '~/components/ShipWeapons/ShipWeapons';
import classNames from 'classnames';

interface MrpsShipDescriptionPopupProps {
    data: MrpsLoadedReward;
    popupStyles?: {
        wrapper?: string;
        body?: string;
        header?: string;
    };
    withoutFooter?: boolean;
    style?: React.CSSProperties;
    withoutZoomIn?: boolean;
}

function getTitle(reward: MrpsLoadedReward) {
    const mainEntitlementAdditionalData = reward.mainEntitlementAdditionalData;

    const formatedShipLevel = getLevelFromRomanFormat(mainEntitlementAdditionalData?.level);

    return (
        <div className={styles.titleWrapper}>
            <div className={styles.title}>
                <div className={styles.titleIconsWrapper}>
                    <img className={styles.titleIcon} loading="lazy" src={mainEntitlementAdditionalData?.nationIcon || ''} alt="" />
                    <img className={styles.titleIcon} loading="lazy" src={mainEntitlementAdditionalData?.typeIcon || ''} alt="" />
                </div>
                <div className={styles.titleShipText}>
                    <div>{formatedShipLevel}</div>
                    <div>{mainEntitlementAdditionalData?.title || reward.title}</div>
                </div>
            </div>
            <div className={styles.subtitle}>
                <ShipComplexity shipId={reward.mainEntitlementId} />
            </div>
        </div>
    );
}

function prepareContainsItems(reward: MrpsLoadedReward) {
    const result: IContainsItem[] = [];
    const uniqueItemsInInventory = store.getState().ReducerAccount.inventory?.uniqueItems;

    reward.entitlements.forEach((item, index) => {
        const itemId = item.type === 'crew' ? item.crewId : item.identifier;
        const itemShipId = item.type === 'permoflage' ? item.shipId : undefined;

        const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item.type, itemId, itemShipId);
        const isUniqueItemByInventory =
            Array.isArray(uniqueItemsInInventory) &&
            uniqueItemsInInventory.some((uniqueItem: number | number[]) => {
                if (Array.isArray(uniqueItem)) {
                    return uniqueItem.includes(itemId);
                }

                return uniqueItem === itemId;
            });

        const content = (
            <div>
                <LoadAmountToReward
                    // @ts-ignore
                    bundle={item}
                    key={`item_${itemId}_${index}_${inventory ? 'loaded' : 'not_loaded'}_${inventoryCount}`}
                    type={item.type}
                    id={itemId}
                    amount={item.amount}
                    customisation={{
                        inventoryCount: !item.additionalData?.isUnique && !isUniqueItemByInventory && inventoryCount,
                        isExistsInInventory: (item.additionalData?.isUnique || isUniqueItemByInventory) && isExistsItemInInventory,
                        shipId: itemShipId,
                    }}
                    presentation={{
                        withText: true,
                        withTooltip: true,
                        iconPosition: 'left',
                        renderWithoutFade: true,
                        hiddenInlineShip: item.type === ITEMS_TYPES.CREWS,
                        hideTooltipAmount: isCurrency(item.type),
                    }}
                />
            </div>
        );

        result.push({
            id: (itemId || `${index}_${item.type}`)?.toString(),
            type: item.type as any,
            isExistsInInventory: true,
            content,
        } as IContainsItem);

        if (item.crew) {
            const crewItem = item.crew;

            const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(crewItem.type, crewItem.crew_id);

            const crewContent = (
                <div>
                    <LoadAmountToReward
                        // @ts-ignore
                        bundle={crewItem}
                        key={`item_${crewItem.type}_${inventory ? 'loaded' : 'not_loaded'}_${inventoryCount}`}
                        type={crewItem.type}
                        id={crewItem.crew_id}
                        amount={crewItem.amount}
                        customisation={{
                            points: crewItem.points,
                            inventoryCount: inventoryCount,
                            isExistsInInventory: isExistsItemInInventory,
                        }}
                        presentation={{
                            withText: true,
                            withTooltip: true,
                            iconPosition: 'left',
                            renderWithoutFade: true,
                            hiddenInlineShip: true,
                            hideTooltipAmount: false,
                        }}
                    />
                </div>
            );

            result.push({
                id: (crewItem.crew_id || `${crewItem.type}`)?.toString(),
                type: crewItem.type,
                isExistsInInventory: true,
                content: crewContent,
            } as IContainsItem);
        }
    });

    return result;
}

interface StateSelector {
    eventName: string;
}

function stateSelector(state: State): StateSelector {
    return {
        eventName: state.reducerMrps.currentEvent?.eventName,
    };
}

export default function MrpsShipDescriptionPopup({ data, popupStyles, withoutFooter, style, withoutZoomIn }: MrpsShipDescriptionPopupProps) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);
    const contentRef = React.useRef<HTMLDivElement>();
    const openDateRef = React.useRef<Date>(new Date());

    const close = () => {
        playButtonClickSound();

        store.dispatch(changeVisiblePopup(null));

        const closeDate = new Date();
        const deltaTiming = (closeDate.getTime() - openDateRef.current.getTime() / 1000).toFixed(1);
        dwhExport.send(DWH_EVENTS.CLOSE_DESCRIPTION_POPUP, { event_name: state?.eventName, duration: deltaTiming });
    };

    useMaskScrollEffect(contentRef);

    return (
        <Popup onClose={close} className={classNames(styles.popup, popupStyles?.wrapper)} renderEscButton style={style} withoutZoomIn={withoutZoomIn}>
            <PopupHeader title={getTitle(data)} className={classNames(styles.popupHeader, popupStyles?.header)} />
            <PopupBody className={styles.popupBodyWrapper}>
                <div
                    className={classNames(styles.popupBody, popupStyles?.body)}
                    ref={(_ref) => {
                        if (_ref) {
                            contentRef.current = _ref;
                        }
                    }}
                >
                    <div className={styles.popupBodyContains}>
                        <Contains items={prepareContainsItems(data)} titleClassName={styles.popupBodyContainsTitle} isLarge />
                    </div>
                    <div className={styles.divider} />
                    <MainFeatures shipId={data.entitlements[0].identifier} className={styles.popupBodyMainFeatures} />
                    {data.description && (
                        <div className={styles.popupBodyShipDescription} dangerouslySetInnerHTML={{ __html: parsePlaceholderInBundleDescription(data.description) }} onClick={onClickByLink} />
                    )}
                    {data.additionalDescription && (
                        <div
                            className={styles.popupBodyShipDescription}
                            dangerouslySetInnerHTML={{ __html: parsePlaceholderInBundleDescription(data.additionalDescription) }}
                            onClick={onClickByLink}
                        />
                    )}
                    <div className={styles.divider} />
                    <AdditionalFeatures shipId={data.entitlements[0].identifier} className={styles.popupBodyAdditionalFeatures} />
                    <div className={styles.divider} />
                    <div className={styles.popupBodyShipConsumables}>
                        <ShipWeapons shipId={data.entitlements[0].identifier} />
                        <ShipConsumables shipId={data.entitlements[0].identifier} />
                    </div>
                </div>
            </PopupBody>
            {!withoutFooter && (
                <PopupFooter className={styles.popupFooter}>
                    <button onClick={close} className={styles.buttonClose}>
                        {t('Закрыть')}
                    </button>
                </PopupFooter>
            )}
        </Popup>
    );
}
