import React from 'react';
import styles from './TypingText.scss';
import classNames from 'classnames';

interface TypingTextProps {
    text: string;
    delay?: number;
    withoutAnimation?: boolean;
    className?: string;
    onFinishCallback?: () => void;
}

export interface TypingTextRef {
    skipAnimation: () => void;
}

export const TypingText = React.forwardRef<TypingTextRef, TypingTextProps>(function TypingText({ text, delay = 20, withoutAnimation, className, onFinishCallback }: TypingTextProps, ref) {
    const [typedText, setTypedText] = React.useState<string>(withoutAnimation ? text : '');
    const [currIndex, setCurrIndex] = React.useState<number>(0);

    const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

    //* Used to call for skip animation somewhere outside of this component
    React.useImperativeHandle(
        ref,
        () => {
            return {
                skipAnimation() {
                    if (currIndex < text.length) {
                        setTypedText(text);
                        setCurrIndex(text.length);

                        if (timerRef.current) {
                            clearTimeout(timerRef.current);
                            timerRef.current = undefined;
                        }
                    }
                },
            };
        },
        [currIndex, text],
    );

    React.useEffect(() => {
        if (withoutAnimation) {
            setTypedText(text);
        } else if (currIndex < text.length) {
            timerRef.current = setTimeout(() => {
                setTypedText((prevText) => prevText + text[currIndex]);
                setCurrIndex((prevIndex) => prevIndex + 1);

                timerRef.current = undefined;
            }, delay);
        } else if (currIndex >= text.length) {
            onFinishCallback?.();

            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = undefined;
            }
        }

        return () => {
            timerRef.current && clearTimeout(timerRef.current);
        };
    }, [currIndex, delay, text, withoutAnimation, onFinishCallback]);

    React.useLayoutEffect(() => {
        setTypedText('');
        setCurrIndex(0);
    }, [text]);

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.fakeText}>{text}</div>
            <div className={styles.typedText}>{typedText}</div>
        </div>
    );
});
