import WowsCommerce from '@wg/wows-commerce';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { assets } from '@wg/wows-entities/const';
import classNames from 'classnames';
import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import CouponWrapper from '~/components/CouponWrapper/CouponWrapper';
import Info from '~/components/Info/Info';
import PriceWrapper, { PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';
import SmallPreloader from '~/components/SmallPreloader/SmallPreloader';
import WSMartQuantity from '~/components/WSMart/Quantity/WSMartQuantity';
import QuantityContainer from '~/containers/QuantityContainer/QuantityContainer';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import { State } from '~/Reducers';
import { TooltipTypes } from '~/types/common';
import { isNotEnabledCoupon } from '~/utils/coupons';
import { t } from '~/utils/localization';
import { calculateBundlePrices, getExchangeFullMessage } from '~/utils/purchase';
import styles from './BundlePage.scss';

interface WsmartFooter {
    bundle: IBundle;
    onDisabledHandler: (isDisabled: boolean) => void;
    onQuantityHandler: (quantity: number) => void;
}

interface IStateSelector {
    accountId: number;
    wowsCommerce: WowsCommerce;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount?.id,
        wowsCommerce: state.ReducerWSMart.wowsCommerce,
    };
};

export const WsmartFooter = ({ bundle, onDisabledHandler, onQuantityHandler }: WsmartFooter) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [currentProduct, setCurrentProduct] = React.useState<ProductEntity>(bundle.originalProduct);
    const [purchaseQuantity, setPurchaseQuantity] = React.useState<number>(1);

    const [isProductPriceLoading, setProductPriceLoading] = React.useState<boolean>(false);
    const [isProductQuantityError, setProductQuantityError] = React.useState<boolean>(false);

    const invalidPurchaseQuantity = purchaseQuantity <= 0;

    const wsmartPurchaseProcessor = new WSMartPurchaseProcessor(bundle.originalProduct);

    const withoutTimer = !!(bundle.activityCountdown?.isEnabled && new Date(bundle.activityCountdown?.activeFrom).getTime() <= new Date().getTime() && bundle.promoTimerActiveTill);

    async function onDebouncedChangeQuantityEvent(quantity: number) {
        await getProductPriceByQuantity(quantity);
    }

    async function onChangeQuantityEvent() {
        setProductPriceLoading(true);
        setProductQuantityError(false);
    }

    async function onInvalidQuantity() {
        setProductPriceLoading(false);
        setProductQuantityError(true);
    }

    async function getProductPriceByQuantity(quantity: number) {
        setProductPriceLoading(true);

        const updatedProduct = await wsmartPurchaseProcessor.updateProductPriceByQuantity(quantity);
        if (!updatedProduct) {
            return;
        }

        setCurrentProduct(updatedProduct);
        setPurchaseQuantity(updatedProduct.quantity);
        onQuantityHandler(updatedProduct.quantity);

        setProductPriceLoading(false);
    }

    React.useEffect(() => {
        const loadProductPrice = async () => {
            if (currentProduct?.isVariablePriceProduct) {
                await getProductPriceByQuantity(Number(currentProduct.variablePriceSettings.default));
                state.wowsCommerce.handleChangeCurrentProduct(currentProduct, WoWsCommerceStorefrontNames.METASHOP);
            }
        };
        loadProductPrice();
    }, []);

    React.useEffect(() => {
        if (currentProduct?.isVariablePriceProduct && currentProduct.originalPrice !== bundle.originalProduct?.originalPrice) {
            const loadProductPrice = async () => {
                await getProductPriceByQuantity(purchaseQuantity);
                state.wowsCommerce.handleChangeCurrentProduct(currentProduct, WoWsCommerceStorefrontNames.METASHOP);
            };
            loadProductPrice();
        }
    }, [bundle.originalProduct]);

    const isDisabled = !currentProduct?.isPurchasable || invalidPurchaseQuantity || isProductQuantityError || isProductPriceLoading;

    React.useEffect(() => {
        if (currentProduct?.isVariablePriceProduct) {
            onDisabledHandler(isDisabled);
        }
    }, [isDisabled]);

    const classesPurchaseWrapper = classNames(styles.purchaseWrapper, styles.variablePrice, {
        [styles.withDiscount]: !!bundle.originalPrice,
    });

    return (
        <div className={classesPurchaseWrapper}>
            <div className={styles.purchaseBlock}>
                {currentProduct?.price !== 0 && <div className={styles.purchaseBlockTitle}>{t('Цена:')}</div>}
                {isProductPriceLoading && !isProductQuantityError && (
                    <div className={styles.pricePreloader}>
                        <SmallPreloader />
                    </div>
                )}
                {!isProductPriceLoading && !isProductQuantityError && (
                    <PriceWrapper
                        bundle={bundle}
                        size={PRICE_WRAPPER_SIZE.BIG}
                        withoutAnimation={bundle.quantityData?.quantity > 1}
                        withoutTimer={withoutTimer}
                        wrapperClassName={styles.purchasePriceContent}
                        useColumnLayoutForCoupon={true}
                    />
                )}
            </div>
            {!!bundle.originalProduct?.isVariablePriceProduct && (
                <div className={styles.purchaseBlock}>
                    <div className={styles.purchaseBlockTitle}>{t('Введите количество дублонов:')}</div>
                    <div className={styles.purchaseQuantityWidget}>
                        <WSMartQuantity
                            product={currentProduct}
                            fromPopup
                            disableInput={false}
                            onChangeValue={onChangeQuantityEvent}
                            onDebouncedChangeValue={onDebouncedChangeQuantityEvent}
                            onInvalidQuantity={onInvalidQuantity}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

interface ArmoryFooter {
    bundle: IBundle;
    activeCoupon: ICoupon;
    canChangeQuantity: boolean;
    isEnabledCoupon: boolean;
    balance: IBalance;
    reasonCantBoughtCode: number;
    isActiveTransaction: boolean;
}

export const ArmoryFooter = ({ bundle, activeCoupon, canChangeQuantity, isEnabledCoupon, balance, reasonCantBoughtCode, isActiveTransaction }: ArmoryFooter) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const calculatePrices = calculateBundlePrices(bundle, balance, bundle.quantityData?.quantity || 1, activeCoupon?.discount);
    const bundleCurrencies = Object.keys(calculatePrices?.final || {});
    const isSteelExchange = state.accountId && bundleCurrencies.length > 1 && bundleCurrencies.includes(assets.STEEL) && bundleCurrencies.includes(assets.COAL);
    const hasDiscount = bundle.discount || (bundle.originalPrice && bundle.originalPrice !== bundle.price);
    const withoutTimer = !!(bundle.activityCountdown?.isEnabled && new Date(bundle.activityCountdown?.activeFrom).getTime() <= new Date().getTime() && bundle.promoTimerActiveTill && !hasDiscount);

    const classesPurchaseWrapper = classNames(styles.purchaseWrapper, {
        [styles.withDiscount]: !!bundle.originalPrice,
    });

    const classesPurchaseBlock = classNames(styles.purchaseBlock, {
        [styles.isLockInterface]: isActiveTransaction,
    });

    return (
        <div className={classesPurchaseWrapper}>
            <div className={styles.purchaseBlock}>
                <div className={styles.purchaseBlockTitle}>{t('Цена:')}</div>
                <PriceWrapper
                    bundle={bundle}
                    coupon={activeCoupon}
                    size={PRICE_WRAPPER_SIZE.BIG}
                    showExchangePrices={false}
                    withoutAnimation={bundle.quantityData?.quantity > 1}
                    withoutTimer={withoutTimer}
                    wrapperClassName={classNames(styles.purchasePriceContent, {
                        [styles.withExhange]: isSteelExchange,
                    })}
                    useColumnLayoutForCoupon={true}
                />
            </div>
            {isSteelExchange && (
                <div className={styles.purchaseBlock}>
                    <div className={styles.purchaseBlockTitle}>
                        {t('Стоимость с использованием стали:')}
                        <Info
                            className={styles.tooltipInfoLabel}
                            tooltip={{
                                text: getExchangeFullMessage(calculatePrices),
                                type: TooltipTypes.WARNING,
                            }}
                        />
                    </div>
                    <PriceWrapper
                        bundle={bundle}
                        coupon={activeCoupon}
                        size={PRICE_WRAPPER_SIZE.BIG}
                        showExchangePrices={true}
                        withoutAnimation={true}
                        withoutTimer={true}
                        wrapperClassName={styles.purchasePriceContent}
                        useColumnLayoutForCoupon={true}
                    />
                </div>
            )}
            {!!canChangeQuantity && (
                <div className={classesPurchaseBlock}>
                    <div className={styles.purchaseBlockTitle}>{t('Количество:')}</div>
                    <div className={styles.purchaseQuantityWidget}>
                        <QuantityContainer bundle={bundle} />
                    </div>
                </div>
            )}
            {!!isEnabledCoupon && (
                <div className={classesPurchaseBlock}>
                    <div className={styles.purchaseBlockTitle}>{t('Примените купон для получения скидки:')}</div>
                    <div className={styles.purchaseCouponBlock}>
                        <CouponWrapper bundle={bundle} isDisableMultiplePurchaseWithCoupon={isNotEnabledCoupon(bundle)} />
                    </div>
                </div>
            )}
        </div>
    );
};
