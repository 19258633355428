import { assets, items } from '@wg/wows-entities/const';
import Account from '~/account/Account';
import { getRandomBundleChild } from './bundles';
import { interpolate, t } from './localization';
import { settings } from './settings';

export const getLevelingRestrictionForBundle = (bundle: IBundle, selectedRandomBundles?: AccountSelectedRandomBundles, isExitFromLoop?: boolean) => {
    if (!Account.getAccount()?.id) {
        return null;
    }

    if (selectedRandomBundles?.[bundle.id]) {
        bundle = getRandomBundleChild(selectedRandomBundles, bundle);
    }

    const types: string[] = [];

    for (const i in bundle.entitlements) {
        const item = bundle.entitlements[i];
        if (settings.accountLevelingRestriction?.[item.type] && Account.getAccountLevel() < settings.accountLevelingRestriction[item.type]) {
            types.push(item.type);
            if (isExitFromLoop) {
                return types;
            }
        }
    }

    return types;
};

export const restrictionText: Record<string, string> = {
    [items.SIGNAL]: interpolate(t('Сигнал можно будет установить на корабль, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction.signal,
    }),
    [items.FLAGS]: interpolate(t('Флаг можно будет установить на корабль, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction.flags,
    }),
    [items.CAMOUFLAGE]: interpolate(t('Камуфляж можно будет установить на корабль, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction.camouflage,
    }),
    [items.PERMOFLAGES]: interpolate(t('Камуфляж можно будет установить на корабль, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.permoflage,
    }),
    [assets.FREE_XP]: interpolate(t('Свободный опыт можно будет использовать, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.free_xp,
    }),
    [items.LOOTBOX]: interpolate(t('Контейнер можно будет открыть, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.lootbox,
    }),
    [items.CREWS]: interpolate(t('Командира можно будет назначить на корабль, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.crew,
    }),
    [items.DEFAULT_CREW]: interpolate(t('Командира можно будет назначить на корабль, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.default_crew,
    }),
    [items.MODERNIZATION]: interpolate(t('Модернизацию можно будет установить на корабль, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.modernization,
    }),
    [items.MULTI_BOOST]: interpolate(t('Экономические бонусы можно будет использовать, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.multiboost,
    }),
    [items.CAMO_BOOST]: interpolate(t('Экономические бонусы можно будет использовать, только когда вам откроется {level} уровень доступа.'), {
        level: settings.accountLevelingRestriction?.camoboost,
    }),

    partialText: t('Некоторое имущество из набора можно будет использовать, только когда вам откроется определённый уровень доступа.'),
    defaultText: t('Имущество из этого набора можно будет использовать, только когда вам откроется определённый уровень доступа.'),
};

export function getLevelingRestrictionTextForBundle(bundle: IBundle, restrictions: string[]) {
    if (bundle.isRandom && bundle.quantityData?.quantity > 1) {
        return restrictionText.partialText;
    }

    if (bundle.entitlements.length === restrictions?.length) {
        return restrictionText[restrictions[0]] || restrictionText.defaultText;
    }

    if (restrictions?.length === 1) {
        if (restrictionText[restrictions[0]]) {
            return restrictionText[restrictions[0]];
        }
        if (bundle.entitlements.length > 1) {
            return restrictionText.partialText;
        }
        return restrictionText.defaultText;
    }

    return restrictionText.partialText;
}
