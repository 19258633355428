import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { Player, PlayerState } from 'video-react';
import styles from './VideoPlayer.scss';
import 'video-react/dist/video-react.css';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '~/Reducers';
import { finishVideo } from '~/Actions/ActionApp';
import { VIDEO_MAP, VIDEO_NAMES } from '~/components/VideoPlayer/videoMap';
import Cron from '~/utils/Cron';
import History from '~/utils/history';
import { isInGameBrowser } from '~/utils/utils';
import VideoSkipLabel from '~/components/VideoPlayer/VideoSkipLabel';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { ICurrentPage } from '~/Actions/ActionAppType';

interface IVideoPlayerState {
    isStartedVideo: boolean;
    currentVideoName: VIDEO_NAMES;
    currentVideoUrl: string;
    fadeOutVideoInSeconds: number;
    onFinishVideoCallback: () => void;
    isTrusted: boolean;
    volume: number;
    currentPage: ICurrentPage;
    categories: ICategories;
}

const stateSelector = (state: State): IVideoPlayerState => {
    return {
        isStartedVideo: state.ReducerApp.isStartedVideo,
        currentVideoName: state.ReducerApp.currentVideoName,
        currentVideoUrl: state.ReducerApp.currentVideoUrl,
        onFinishVideoCallback: state.ReducerApp.onFinishVideoCallback,
        isTrusted: state.ReducerApp.isTrusted,
        fadeOutVideoInSeconds: state.ReducerApp.fadeOutVideoInSeconds,
        volume: state.ReducerApp.volume,
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
    };
};

const VideoPlayer = () => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IVideoPlayerState>(stateSelector, equal);
    const currentCategoryConfig = appState.categories[appState.currentPage?.name];
    const player = React.useRef(null);
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const startTime = Date.now();

    const finish = (watched?: boolean) => {
        if (!watched) {
            const endTime = Date.now();
            const stopTime = Math.floor((endTime - startTime) / 1000);
            dwhExport.send(DWH_EVENTS.CATEGORY_VIDEO_VIEWED, {
                categoryName: currentCategoryConfig?.name,
                currentTime: stopTime,
            });
        } else {
            dwhExport.send(DWH_EVENTS.CATEGORY_VIDEO_VIEWED_FULLY, { categoryName: currentCategoryConfig?.name });
        }

        dispatch(finishVideo());
        appState.onFinishVideoCallback?.();
    };

    const handleStateChange = (state: PlayerState) => {
        if (appState.fadeOutVideoInSeconds && state.currentTime >= state.duration - appState.fadeOutVideoInSeconds) {
            ref?.current?.classList.remove(styles.active);
        }
        if (state.ended) {
            ref?.current?.classList.remove(styles.active);
            Cron.newDeferredTask(
                'finish-video',
                () => {
                    finish(true);
                },
                300,
            );
        }
    };

    React.useEffect(() => {
        if (!player.current) return;
        player.current.volume = appState.volume || 0.5;

        if (appState.isStartedVideo) {
            setTimeout(() => {
                ref?.current?.classList.add(styles.active);
                if (!isInGameBrowser && player.current && History.getHistory()?.length <= 1) {
                    try {
                        player.current.muted = !appState.isTrusted;
                    } catch (e) {
                        // autoplay police
                    }
                }
                player.current?.play();
                player.current?.subscribeToStateChange(handleStateChange.bind(this));
            }, 200);
        }
    }, [appState.isStartedVideo, appState.isTrusted]);

    if (!appState.currentVideoName && !appState.currentVideoUrl) {
        return null;
    }

    return (
        <div className={styles.wrapper} ref={ref}>
            <div className={styles.player}>
                <Player ref={player} autoPlay={appState.isStartedVideo} fluid>
                    <source src={appState.currentVideoName && appState.currentVideoName in VIDEO_MAP ? VIDEO_MAP[appState.currentVideoName] : appState.currentVideoUrl} />
                </Player>
                <VideoSkipLabel callback={finish} />
            </div>
        </div>
    );
};

export default VideoPlayer;
