import * as React from 'react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import SantaCustomization from '~/components/Popups/Purchase/Customization/Santa/SantaCustomization';
import { CustomPopupCustomization } from './Custom/CustomPopupCustomization';

interface IPurchaseCustomizationWrapper {
    category: ICategory;
    bundle: IBundle;
    children: React.ReactChild | React.ReactChild[];
}

const PurchaseCustomizationWrapper = ({ category, bundle, children }: IPurchaseCustomizationWrapper) => {
    const fallback = <>{children}</>;

    if (isMobileOrTabletWindow || !category) {
        return fallback;
    }

    switch (category.theme) {
        case SANTA_PAGE_THEME: {
            return <SantaCustomization>{children}</SantaCustomization>;
        }

        case CUSTOM_PAGE: {
            return <CustomPopupCustomization>{children}</CustomPopupCustomization>;
        }

        default: {
            return fallback;
        }
    }
};

export default PurchaseCustomizationWrapper;
