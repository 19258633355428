import Account from '~/account/Account';
import { WELCOME_GIFT_LS_KEY } from '~/const';
import { LocalStorageHelper } from './storage';
import { redirectToLogin } from '~/utils/utils';
import { takeGiftFromApi } from '~/api/gifts';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import store from '~/Store';
import { giftStatus } from '~/Actions/ActionAccount';

export function markShownWelcomeGiftScreen(name: string) {
    LocalStorageHelper.set(Account.getLSKey(WELCOME_GIFT_LS_KEY.replace('{}', name)), 1);
}

export function isNeedToShowWelcomeGiftScreen(name: string) {
    return !LocalStorageHelper.get(Account.getLSKey(WELCOME_GIFT_LS_KEY.replace('{}', name)));
}

export async function takeGift(gift: IGift): Promise<boolean> {
    try {
        markShownWelcomeGiftScreen(gift.name);

        if (!Account?.getAccount()?.id) {
            redirectToLogin();
            return false;
        }

        await takeGiftFromApi(gift.id);
        updateBrowserControlState(true);
        return true;
    } catch (e) {
        updateBrowserControlState(true);
        return false;
    }
}

export async function removeFirstAvailableGift() {
    const storeGifts = store.getState().ReducerAccount.gifts;
    const [firstGift, ...otherGifts] = storeGifts.bundles;
    store.dispatch(giftStatus(!!otherGifts.length, otherGifts, storeGifts.secretBundles));
}

export async function removeGift(gift: IGift) {
    const storeGifts = store.getState().ReducerAccount.gifts;
    const availableGifts = storeGifts.bundles.filter((bundle) => bundle.name !== gift.name);
    const secretAvailableGifts = storeGifts.secretBundles.filter((bundle) => bundle.name !== gift.name);
    store.dispatch(giftStatus(!!availableGifts.length, availableGifts, secretAvailableGifts));
}
