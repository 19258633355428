import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';

export enum BUNDLE_TYPES {
    default = 'default',
    random = 'random',
    serial = 'serial',
    promo = 'promo',
    periodic = 'periodic',
}

export enum BUNDLE_DECORATION {
    ADMIRAL_PACK = 'admiral_pack',
    COMMANDER_PACK = 'commander_pack',
    SIMPLE_PACK = 'simple_pack',
    WITHOUT_GLOW_ANIMATION = 'without_glow_animation',
    SUBMARINE_BUNDLE = 'submarine_bundle',
    SUBMARINE = 'submarine',
    SEPIA_EFFECT = 'sepia_effect',
    RANDOM_BIG_ITEM = 'random_big_item',
    GRAYSCALE = 'grayscale',
    SCALE_IMAGE = 'scale_image',
    ANIMATE_BORDER = 'animate_border',
    NEW_RANDOM_BUNDLE = 'new_random_bundle',
    SCROLLABLE_VIEW = 'scrollable_view',
    TIER_11 = 'tier_11',
    TIER_11_WITH_VIDEO = 'tier_11_with_video',
    SANTA = 'santa',
    NY22_FEATURED = 'ny22_featured',
    FULLSCREEN_WITHOUT_GALLERY = 'fullscreen_without_gallery',
    SCALE_ON_HOVER = 'scale_on_hover',
    HIDE_GALLERY_DEFAULT_BACKGROUND = 'hide_gallery_default_background',
    DISABLE_BUNDLE_HOVER_BACKGROUND = 'disable_bundle_hover_background',
    DISABLE_BUNDLE_DEFAULT_BACKGROUND = 'disable_bundle_default_background',
    BUNDLE_BIG_ICON = 'bundle_big_icon',
    TURN_OFF_CATEGORY_VIDEO = 'turn_off_category_video',
    HIDE_BUNDLE_IMAGE = 'hide_bundle_image',
    SNOWFLAKES_SHINY_ANIMATION = 'snowflakes_shiny_animation',
    DISABLE_CATEGORY_VIDEO_BACK = 'disable_category_video_back',
    HOLOLIVE_ANIMATION = 'hololive_animation',
    FADER_LTR_GRADIENT = 'fader_ltr_gradient',
    DISCLAIMER_APPEARANCE = 'disclaimer_appearance',
    HIDE_CATEGORY_TITLE = 'hide_category_title',
    HIDE_OPEN_LOOTBOX_BUTTON = 'hide_open_lootbox_button',
}

export enum ILootboxRewardGroupDisplay {
    default = 'default',
    grouped = 'grouped',
}

export enum CommonRewardType {
    SHIP = 'ship',
    CAMO = 'camo',
    CREW = 'crew',
}

export enum PreviewSettingsTemplate {
    default = 'default',
}

export enum PreviewRewardCustomTypes {
    SHIP = 'ship',
    SHIP_WITH_SPECIAL_CREW = 'ship_with_special_crew',
    COAL = 'coal',
    STEEL = 'steel',
    SIGNAL = 'signal',
    CREDITS = 'credits',
    GOLD = 'gold',
    SPECIAL_SIGNAL = 'special_signal',
    ECO_SIGNAL = 'eco_signal',
    BATTLE_SIGNAL = 'battle_signal',
    PERMANENT_CAMO = 'permanent_camo',
    CAMO = 'camo',
    FREE_XP = 'free_xp',
    UNIQUE_CREW = 'unique_crew',
    ELITE_XP = 'elite_xp',
    SPECIAL_SIGNAL_WITH_CAMO = 'special_signal_with_camo',
    ALBUM_COLLECTION = 'collection_album',
    CUSTOM = 'custom',
    WOWS_PREMIUM = 'wows_premium',
}

export enum BundlePurchaseTypes {
    INGAME_CURRENCY = 'ingame_currency',
    REAL_CURRENCY = 'real_currency',
}

declare global {
    interface IBundleEntity extends IItemCommonData {
        allowCompensation: boolean;
        customDescription?: string;
        customTitle?: string;
        isBonus: boolean;
        isPrimary: boolean;
        shipShardsId?: number;
        shipShardsLevel?: number;
    }

    type IBundleList = Record<string, IBundle>;

    interface IPrimaryItemVehicleData {
        class?: string;
        level?: number;
        nation?: string;
        isPremium?: boolean;
        isSpecial?: boolean;
        complexity?: number;
    }

    interface IPrimaryItemCustomisation {
        class?: string[];
        crewLevel?: number[];
        isPremium?: boolean[];
        isSpecial?: boolean[];
        level?: number[];
        tier?: number[];
        nation?: string[];
        type?: string;
        points?: number;
        itemSubType?: string;
        vehicleId?: number;
        shipId?: number;
        shipIds?: number[];
        currency?: string[];
        vehicle?: IPrimaryItemVehicleData;
        complexity?: number[];
    }

    interface DefaultBundleIcons {
        default: string;
        small: string;
        medium: string;
        big: string;
        innerBackground: string;
    }

    interface RandomBundleChildIcons extends DefaultBundleIcons {
        randomShinyImage?: null;
        randomShinyBackground?: null;
    }

    interface BundleIcons extends DefaultBundleIcons {
        mobileInnerBackground: string;
        confirmationBackground: string;
    }

    interface RandomBundleIcons extends BundleIcons {
        randomDoorLeft: string;
        randomDoorRight: string;
        randomCardBackground: string;
        randomShinyImage: string;
        randomShinyBackground: string;
        randomPartialPurchaseImage: string;
        randomDiceImage?: string;
    }

    interface IRandomBundleChild {
        id: number;
        title: string;
        description: string;
        descriptionAdditional?: string;

        entitlements: IBundleEntity[];
        limitedQuantity: Nullable<IBundleLimitedQuantity>;
        autoDescriptionSettings?: IAutoDescriptionSettings;

        icons: RandomBundleChildIcons;

        allowCompensation: boolean;
        isShiny: boolean;
    }

    type IBundlePricesInfo = Partial<
        Record<
            ICategoryList,
            Record<
                string,
                {
                    min: number;
                    max: number;
                    defaultMin?: number;
                    defaultMax?: number;
                }
            >
        >
    >;

    interface IBundleLimitedQuantity {
        personalLimit: number;
    }

    interface IBundlePrice {
        value?: number;
        currency?: string;
        priceWithQuantity?: number;
    }

    type SIZE_IN_GRID = '1/4' | '1/3' | '1/2' | '3/4' | '2/3' | '1';
    type HEIGHT = '2' | '1';

    type IQuantityData = Partial<{
        quantity: number;
        initial: Record<string, number>;
        withDiscount: Record<string, number>;
        final: Record<string, number>;
        lack: Record<string, number>;
    }>;

    type WsmartProductForArmory = Pick<
        ProductEntity,
        | 'price'
        | 'formattedPrice'
        | 'formattedOriginalPrice'
        | 'formattedDiscount'
        | 'currency'
        | 'id'
        | 'entitlements'
        | 'discount'
        | 'originalPrice'
        | 'promoTimerActiveTill'
        | 'availablePersonalCount'
        | 'promoLabels'
    >;

    interface IBundle extends IDisplayRestrictedOptions {
        name: string;
        id: number;
        parentBundleId: Nullable<number>;
        type: BUNDLE_TYPES;
        categories?: string[];
        price: Nullable<string | number>;
        currency: Nullable<string>;
        additionalPrice: Nullable<string | number>;
        additionalCurrency: Nullable<string>;

        discount: Nullable<number>;
        showDiscountLabel: boolean;
        originalPrice: Nullable<number | string>;
        allowCompensation: boolean;

        labelNewActivityPeriod: IActiveTime;

        title: string;
        titleImage?: string;
        normalizedTitle: Nullable<string>;
        description: Nullable<string>;
        descriptionAdditional: Nullable<string>;
        descriptionBrief: string;

        confirmationVideoBackground?: IVideo;
        videoBackground?: IVideo;
        entryVideo: IVideo;
        bundleCardHoverVideo: Nullable<IVideo>;
        previewCardVideo: Nullable<IVideo>;

        audioTrack: Nullable<IAudioTrack>;
        hoverSound: Nullable<IAudioTrack>;

        hideAdultCheck: boolean;

        icons: BundleIcons | RandomBundleIcons;

        backgroundColor: Nullable<string>;

        filterValues?: any;

        sizeInGrid: Nullable<SIZE_IN_GRID>;
        height: Nullable<HEIGHT>;
        decoration: BUNDLE_DECORATION[];
        denyTitleModification: boolean;

        limitedQuantity: Nullable<IBundleLimitedQuantity>;
        singlePurchaseMaxQuantity: Nullable<number>;
        promoTimerActiveTill: Nullable<string>;
        promoTimerActiveFrom: Nullable<string>;
        activityCountdown?: ActivityCountdown;
        disablePortPreview: boolean;

        quantityData: IQuantityData;

        serialPurchase: boolean;
        dependentBundle: number;
        nextBundle: number;
        serialIndex: number;
        serialSequence: number[];
        isValuableSerialBundle: Nullable<boolean>;
        enableMultiplePurchase: Nullable<boolean>;
        isHidden: boolean;

        randomIsInfinite: boolean;
        randomIsTotalCountHidden: boolean;
        randomIsSpecial: boolean;
        randomPurchaseCooldown: number;

        isShiny: boolean;

        /** Used for group same bundles with diffrent amount together */
        groupName: string;
        /** Used for sort grouped bundles in order */
        groupIndex: number;

        randomShowCarusel: boolean;

        gallery: Nullable<IGalleryItem[]>;
        isFullscreenGallery: boolean;

        randomBundleChildren: IRandomBundleChild[];

        primaryId: Nullable<number>;
        primaryItem: Nullable<BundlePrimaryType>;
        entitlements: Nullable<IBundleEntity[]>;

        autoDescriptionSettings: Nullable<IAutoDescriptionSettings>;

        hideAdditionalContent: Nullable<string>;

        progressivePurchase: Nullable<ProgressivePurchaseSettings>;

        randomBundleShinyAnimation: Nullable<RandomBundleShinyAnimationType>;

        randomHideEqualProbabilityNotice: Nullable<boolean>;

        promoLabel: Nullable<string>;

        isPurchased?: boolean;
        isRandom?: boolean;

        purchaseType: BundlePurchaseTypes;
        productCode: Nullable<string>;
        productId: Nullable<string>;
        isLoading?: boolean;
        originalProduct?: ProductEntity;

        promoTimer?: {
            timerTitle?: string;
            timerTooltipDescription?: string;
            timerActiveTill?: string;
        };

        dialogOptions?: DialogOptions;
    }

    type DiscountDiapason = {
        discount: number;
        price: number;
        diapason: number[];
    };

    type PartialPurchaseSettings = {
        isEnabled?: boolean;
        denyForCountries?: Nullable<string[]>;
        denyForPlatforms?: Nullable<string[]>;
    };

    type WholePurchaseSettings = {
        isEnabled?: boolean;
        denyForCountries?: Nullable<string[]>;
        denyForPlatforms?: Nullable<string[]>;
    };

    type ProgressivePurchaseSettings = {
        partialPurchase?: Nullable<PartialPurchaseSettings>;
        wholeBundlePurchase?: Nullable<WholePurchaseSettings>;
        discountDiapasons: DiscountDiapason[];
    };

    type BundlePrimaryType = IBundleEntity & {
        filterValues?: IPrimaryItemCustomisation;
    };

    type RandomBundleShinyAnimationType = {
        isEnabled?: Nullable<boolean>;
        image?: Nullable<string>;
    };

    interface ILootboxStateItemFiller {
        amount: number;
        type: string;
        id: Nullable<number>;
    }

    interface ILootboxStateItemGroupRewards<T> {
        [key: string]: T;
    }

    interface ILootboxIRewardIcons {
        medium: Nullable<string>;
        large: Nullable<string>;
        small: Nullable<string>;
        default: Nullable<string>;
    }

    type LootboxRewardShipAdditionalData = {
        id: string;
        level: number;
        nation: {
            name: string;
            icons: ILootboxIRewardIcons;
        };
        icons: ILootboxIRewardIcons;
        type: {
            name: string;
            icons: ILootboxIRewardIcons;
        };
        title: string;
        isSpecial: boolean;
        isPremium: boolean;
        defaultCrew: Nullable<LootboxRewardCrewAdditionalData>;
    };

    type LootboxRewardMultiBoostAdditionalData = {
        title: string;
        icons: ILootboxIRewardIcons;
        restrictions: {
            levels: number[];
        };
    };

    type LootboxRewardCamoAdditionalData = {
        title: string;
        isNative: Nullable<boolean>;
        icons: ILootboxIRewardIcons;
        type: Nullable<string>;
        subType: Nullable<string>;
        ship: Nullable<LootboxRewardShipAdditionalData>;
    };

    type LootboxRewardCrewAdditionalData = {
        title: string;
        nation: {
            name: string;
            icons: ILootboxIRewardIcons;
        };
        type: string;
        isUnique: boolean;
        icons: ILootboxIRewardIcons;
        hasSampleVo: boolean;
    };

    type LootboxRewardSignalAdditionalData = {
        title: string;
        tags: string[];
        l10NRarity: Nullable<string>;
    };

    type LootboxRewardAdditionalData = LootboxRewardShipAdditionalData &
        LootboxRewardCamoAdditionalData &
        LootboxRewardCrewAdditionalData &
        LootboxRewardSignalAdditionalData &
        LootboxRewardMultiBoostAdditionalData;

    interface ILootboxReward {
        probability: string;
        weight: number;
        probabilityDisplayed: number;
        amount: number;
        type: string;
        defaultCrew: Nullable<number>;
        id: Nullable<number>;
        shipId: Nullable<number>;
        crewLevel: Nullable<number>;
        additionalData: LootboxRewardAdditionalData;
    }

    interface ILootboxRewardGroup {
        title: Nullable<string>;
        shortTitle: Nullable<string>;
        description: Nullable<string>;
        probabilityDisplayed: number;
        display: Nullable<ILootboxRewardGroupDisplay>;
        rerollNonUniqueCrews: boolean;
        rewards: ILootboxReward[];
        savePoint: Nullable<number>;
        commonRewardType: Nullable<CommonRewardType>;
        hasUniqueRewards: boolean;
    }

    type ILootboxStateRewards = ILootboxStateItemGroupRewards<ILootboxRewardGroup>;

    interface ILootboxStateItemSlot {
        title: Nullable<string>;
        shortDescription: Nullable<string>;
        fullDescription: Nullable<string>;
        icon: Nullable<string>;

        continuousRewards: Nullable<boolean>;
        commonRewards: ILootboxStateItemGroupRewards<ILootboxRewardGroup>;
        valuableRewards: ILootboxStateItemGroupRewards<ILootboxRewardGroup>;
    }

    interface ILootboxPreviewSettingsItemReward {
        image: Nullable<string>;
        title: Nullable<string>;
        type: PreviewRewardCustomTypes;
        id: Nullable<number>;
        amount: Nullable<number>;
    }

    interface ILootboxPreviewSettingsDataItem {
        slotIndex: number;
        rewards: ILootboxPreviewSettingsItemReward[];
    }

    type ILootboxPreviewSettingsData = ILootboxPreviewSettingsDataItem[];

    interface ILootboxPreviewSettings {
        template: PreviewSettingsTemplate;
        data: Nullable<ILootboxPreviewSettingsData>;
    }

    interface ILootboxSavePointForValuableGroup {
        savePoint: number;
        groupName: string;
        groupTitle: string;
        isUniqueGroup: boolean;
        groupSavePointIndex: string;
    }

    interface ILootboxStateItem {
        id: number;
        title: Nullable<string>;
        shortTitle: Nullable<string>;
        isPremium: boolean;
        filler: Nullable<ILootboxStateItemFiller>;
        savePoint: number;
        savePointForValuableGroup: Nullable<ILootboxSavePointForValuableGroup>;
        slots: ILootboxStateItemSlot[];
        hasValuableRewards: Nullable<boolean>;
        uniqueGroupTitles: string[];
        slotWithUnique?: string;
    }

    interface ILootboxes {
        [key: number]: ILootboxStateItem;
    }

    interface ILotboxPromotionRewards {
        groupIndex: number;
        slotIndex: number;
        sortOrder: number[];
    }

    type LootboxPromotionRewardsInsideSlots = Nullable<ILotboxPromotionRewards[]>;
    type LootboxPreviewSettings = Nullable<ILootboxPreviewSettings>;

    interface ILootboxAutoDescriptionSettings {
        id: number;
        isDisabled: Nullable<boolean>;
        isDroprateHidden: Nullable<boolean>;
        title: Nullable<string>;
        description: Nullable<string>;
        previewSettings: LootboxPreviewSettings;
        promotionRewardsInsideSlots: LootboxPromotionRewardsInsideSlots;
        isGroupedCommonRewards: Nullable<boolean>;
    }

    interface ILootboxAutoDescriptionSettingsMap {
        [key: number]: ILootboxAutoDescriptionSettings;
    }

    interface IAutoDescriptionSettings {
        isDisabled: Nullable<boolean>;
        lootboxSettings: Nullable<ILootboxAutoDescriptionSettings[] | ILootboxAutoDescriptionSettingsMap>;
    }

    interface IRequestGift {
        data: IGift[];
        meta: {
            total: number;
        };
    }

    interface IGift extends IDisplayRestrictedOptions {
        name: string;
        id: number;
        title: string;
        description: string;
        isObtained: boolean;
        icons: DefaultBundleIcons;
        entryVideo: IVideo;
        decoration: string[];
        offersLimit: number;
        activeTill: string;
        activeFrom: string;
        primaryId: number;
        entitlements: IBundleEntity[];
        videoBackground?: IVideo;
        buttonBackgroundColor: string;
        buttonTextColor: string;
        buttonLabel: string;
        previewCardVideo?: Nullable<IVideo>;
    }

    interface IFeatureItem {
        category: Nullable<string>;
        description: Nullable<string>;
        title: Nullable<string>;
        key: Nullable<string>;
    }

    interface IFeatureCategoryIcons {
        default: string;
        localDefault: string;
    }

    interface IFeatureCategory {
        icons: IFeatureCategoryIcons;
        title: Nullable<string>;
    }

    interface IFeatureCategories {
        category: Nullable<IFeatureCategory>;
        features: Nullable<IFeatureItem[]>;
    }

    interface IFeatureTagsItem {
        id: number;
        featuresTags: Array<IFeatureTag>;
        featuresTagsPerCategory: Array<IFeatureTagPerCategory>;
        complexity: IFeatureItemComplexity;
    }

    interface IFeatureTag {
        color: string;
        icons: IFeatureTagIcons;
        type: IFeatureType;
        mark: string;
    }

    interface IFeatureTagIcons {
        large?: string;
        localLarge?: string;
        localSmall?: string;
        small?: string;
    }

    type IFeatureType = 'unique' | 'positive' | 'negative' | 'neutral';

    interface IFeatureTagPerCategory {
        category: IFeatureTagPerCategoryData;
        featuresTags: Array<IFeatureTag>;
    }

    interface IFeatureTagPerCategoryData {
        icons: IFeatureTagPerCategoryIcons;
        mark: string;
        name: string;
    }

    interface IFeatureTagPerCategoryIcons {
        default?: string;
        localDefault?: string;
    }

    interface IFeatureItemComplexity {
        description: string;
        header: string;
        icons: IFeatureItemComplexityIcons;
        level: number;
    }

    interface IFeatureItemComplexityIcons {
        empty?: string;
        filled?: string;
        localEmpty?: string;
        localFilled?: string;
    }

    type ShipFeatureMap = Record<string, IFeatureTagsItem>;

    interface DialogOptions {
        steps: Array<DialogOptionsStep>;
        theme?: string;
    }

    interface DialogOptionsStep {
        title: string;
        description: string;
        shortDescription: string;
    }
}

export default global;
