import * as React from 'react';
import styles from '../common.scss';
import { t } from '~/utils/localization';
import { ManagerData, SectionNames } from '~/core/ManagerData';
import { items } from '@wg/wows-entities/const';

interface IDogTagSubTitleContentProps {
    item: IBundleEntity;
}

const getSubTitle = (type: string, isPatch?: boolean) => {
    switch (type) {
        case items.EMBLEM:
            return t('Эмблема');

        case items.PATCH:
            if (isPatch) return t('Нашивка');
            return t('Символ');

        case items.SUBSTRATE:
        case items.BACKGROUND:
            return t('Форма');
    }
};

export const DogTagSubTitleContent = (props: IDogTagSubTitleContentProps) => {
    const primaryItem = props.item;

    const dolls = ManagerData.getDataBySection(SectionNames.dolls, 'id') || {};
    const doll = dolls[primaryItem?.identifier] || {};

    const subTitle = getSubTitle(doll?.type, doll?.isPatch);
    if (!subTitle) {
        return null;
    }

    return (
        <div className={styles.flex}>
            <div className={styles.preBundleTitle}>{subTitle}</div>
        </div>
    );
};
