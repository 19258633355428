import * as React from 'react';

interface IState {
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}
