import * as React from 'react';
import FeaturedTimer from '../FeaturedTimer/FeaturedTimer';
import styles from './CategoryPromoTimer.scss';

interface ICategoryPromoTimer {
    category: ICategory;
    onComplete?: () => void;
    delayCompleteHandler?: number;
}

const CategoryPromoTimer = ({ category, onComplete, delayCompleteHandler }: ICategoryPromoTimer) => {
    const { promoTimer } = category;
    const isOnlyTimer = !promoTimer.title || !promoTimer.description;
    const timer = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    });

    const handler = () => {
        if (delayCompleteHandler) {
            timer.current = setTimeout(onComplete, delayCompleteHandler);
        } else {
            onComplete();
        }
    };

    return (
        <div className={styles.wrapper}>
            {isOnlyTimer ? (
                <div className={styles.centerTimer}>
                    <FeaturedTimer
                        activeTill={promoTimer.timerActiveTill}
                        title={promoTimer.timerTitle}
                        countDownClassName={styles.timer}
                        onComplete={handler}
                        description={promoTimer.timerTooltipDescription}
                    />
                </div>
            ) : (
                <div className={styles.content}>
                    <div className={styles.title}>{promoTimer.title}</div>
                    <div className={styles.description}>{promoTimer.description}</div>
                    <FeaturedTimer activeTill={promoTimer.timerActiveTill} title={promoTimer.timerTitle} className={styles.leftTimer} countDownClassName={styles.timer} onComplete={handler} />
                </div>
            )}
        </div>
    );
};

export default CategoryPromoTimer;
