import * as React from 'react';
import styles from './Volume.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import { t } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IVolume {
    onChange: (isMuted: boolean) => void;
    defaultValue?: boolean;
    className?: string;
    id?: string;
    disableClick?: boolean;
}

const Volume = (props: IVolume) => {
    const [isMuted, setMuted] = React.useState<boolean>(props.defaultValue !== undefined ? props.defaultValue : true);

    const onClick = () => {
        if (props.disableClick) {
            return;
        }
        playButtonClickSound();
        setMuted(!isMuted);
        props.onChange?.(!isMuted);
    };

    const classes = classNames(styles.volume, props.className, {
        [styles.off]: !isMuted,
    });

    return (
        <div id={props.id}>
            <DivTooltip className={classes} onClick={onClick} tooltipBody={<DefaultTooltip text={!isMuted ? t('Включить звук') : t('Выключить звук')} />} style={{ cursor: 'pointer' }} />
        </div>
    );
};

export default Volume;
