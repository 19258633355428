import React from 'react';
import classNames from 'classnames';
import { Howl } from 'howler';
import store from '~/Store';
import { changeVisiblePopup, updateCustomPagePopupBackground, updateNestedPopupSettings } from '~/Actions/ActionApp';
import { playButtonClickSound } from '~/api/WoWsClient';
import { isInGameBrowser, isMobileOrTabletWindow, isSafari } from '~/utils/utils';
import { isEnabledAudioContext, isEnabledSounds } from '~/utils/sounds/settings';
import useResourceLoader from '~/hooks/useResourceLoader';
import { ICustomGroupContent } from '../../../../types/customPage';
import { CUSTOM_PAGE } from '../../ThemeManager';
import { POPUPS_NAME } from '~/components/PopupManager';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import PurchaseButton from '~/components/Button/PurchaseButton';

import styles from './CustomPageGroupedBundle.scss';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';

interface CustomPageGroupedBundleProps {
    bundles: ICustomGroupContent['bundles'];
    customParams: ICustomGroupContent['customParams'];
}

export function CustomPageGroupedBundle({ bundles, customParams }: CustomPageGroupedBundleProps) {
    const [choosenBundleId, setChoosenBundleId] = React.useState(bundles[0].id);
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const videoRef = React.useRef<HTMLVideoElement>(null);

    const commonInfo = React.useMemo(() => {
        return {
            title: bundles[0].title,
            titleImage: bundles[0].titleImage,
            icons: bundles[0].icons,
            hoverVideo: bundles[0].bundleCardHoverVideo,
            hoverSound: bundles[0].hoverSound,
            soundKey: bundles[0]?.categories?.[0],
        };
    }, [bundles]);

    const howl = new Howl({ src: commonInfo.hoverSound.mp3 });

    const wrapperStyles = React.useMemo<React.CSSProperties>(() => {
        const gridColumnStartIndex = customParams.place[0];
        const gridRowStartIndex = customParams.place[1];

        return {
            gridColumn: `${gridColumnStartIndex} / span ${customParams.width}`,
            gridRow: `${gridRowStartIndex} / span ${customParams.height}`,
        };
    }, [customParams]);

    const bundleClickHanlder = React.useCallback(() => {
        const bundleInfo = bundles.find((bundleInfo) => bundleInfo.id === choosenBundleId);

        const nestedPopupSettings: PopupSettings['nestedPopup'] = {
            mainBundle: bundleInfo,
        };

        playButtonClickSound();

        store.dispatch(updateCustomPagePopupBackground(bundleInfo?.icons?.innerBackground));
        store.dispatch(updateNestedPopupSettings(nestedPopupSettings));
        store.dispatch(
            changeVisiblePopup(POPUPS_NAME.ITEM_DESCRIPTION_POPUP, true, {
                item: bundleInfo.primaryItem,
                autoDescriptionSettings: bundleInfo?.autoDescriptionSettings,
                theme: CUSTOM_PAGE,
                isFirstOpen: true,
            }),
        );
    }, [choosenBundleId, bundles]);

    const [isLoadingResources, resourcesMap] = useResourceLoader([commonInfo.hoverVideo?.webm], isInGameBrowser, isSafari);
    const isVideoEnabled = !isMobileOrTabletWindow && isLoadingResources;

    const startAnimation = () => {
        const hover = videoRef.current;
        hover.currentTime = 0;
        hover.play().catch();
    };

    const stopAnimation = () => {
        const hover = videoRef.current;
        hover.pause();
        setTimeout(() => {
            hover.currentTime = 0;
        }, 500);
    };

    const onMouseEnter = () => {
        if (isMobileOrTabletWindow) return;
        if (isEnabledAudioContext() && !!isEnabledSounds(commonInfo.soundKey)) {
            howl.seek(0);
            howl.volume(1);
            howl.play();
        }

        isVideoEnabled && startAnimation();
    };

    const onMouseLeave = () => {
        isVideoEnabled && stopAnimation();
    };

    return (
        <div ref={wrapperRef} className={styles.wrapper} style={wrapperStyles}>
            <div className={styles.contentWrapper}>
                <div className={styles.bundleTitle}>{commonInfo.titleImage ? <img className={styles.titleImage} src={commonInfo.titleImage} alt={commonInfo.title} /> : commonInfo.title}</div>
                <div className={styles.chooseButtonsWrapper}>
                    {bundles.map((bundleInfo) => {
                        function buttonClickHandler() {
                            playButtonClickSound();
                            setChoosenBundleId(bundleInfo.id);
                        }

                        return (
                            <button
                                className={classNames(styles.chooseButton, {
                                    [styles.chooseButton_active]: bundleInfo.id === choosenBundleId,
                                })}
                                onClick={buttonClickHandler}
                            >
                                {`×${bundleInfo.primaryItem.amount}`}
                                {bundleInfo.discount ? <div className={styles.chooseButton__discountLabel}>{`-${bundleInfo.discount}%`}</div> : null}
                            </button>
                        );
                    })}
                </div>
                <div className={styles.purchaseWrapper}>
                    <PriceWrapper
                        bundle={bundles.find((bundle) => bundle.id === choosenBundleId)}
                        withoutAnimation={false}
                        wrapperClassName={styles.priceWrapper}
                        className={styles.price}
                        oldPriceClassName={styles.priceOld}
                        priceClassName={styles.priceInner}
                    />
                    <PurchaseButton
                        bundle={bundles.find((bundle) => bundle.id === choosenBundleId)}
                        place={FAST_GOLD_PLACE.CUSTOM_VIEW_PAGE}
                        shortageClassName={styles.purchaseWrapper__fastGoldWrapper}
                        classNameFastGoldLink={styles.purchaseWrapper__fastGoldLink}
                        classNameChinaFastGoldLink={styles.purchaseWrapper__fastGoldLink}
                    />
                </div>
            </div>
            {customParams.image ? (
                <div
                    className={classNames(styles.imageWrapper, {
                        [styles.imageWrapper_withOnHoverVideo]: commonInfo.hoverVideo && isVideoEnabled,
                    })}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={bundleClickHanlder}
                >
                    <img className={styles.image} src={commonInfo.icons[customParams.image.size]} alt={commonInfo.title} />
                    {commonInfo.hoverVideo && isVideoEnabled ? (
                        <video ref={videoRef} preload="auto" autoPlay={false} loop className={styles.hoverVideo} src={resourcesMap[commonInfo.hoverVideo.webm]} />
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
