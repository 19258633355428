import * as React from 'react';
import styles from './PointRight.scss';
import classNames from 'classnames';

interface IPointRight {
    className?: string;
    label: string | number;
}

const PointRight = ({ className, label }: IPointRight) => {
    return <div className={classNames(styles.point, className)}>{label}</div>;
};

export default PointRight;
