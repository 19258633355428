import { RESET_TREASURE_CURRENT_BOX_STATE, SAVE_TREASURE_ACCOUNT_DATA, SAVE_TREASURE_BOXES, DO_PURCHASE, TREASURE_SHOW_ANIMATION, TreasureAction } from '~/Actions/ActionTreasureType';

export interface TreasureState {
    boxes: TreasureMasterBox[]; // all master boxes at categories & subcategories
    account?: TreasureAccountData;
    currentBox?: TreasureMasterBox; // master box in focus
    showAnimation: boolean;
    animationData?: TreasureAnimationData;
    activeTransaction?: TreasureTransaction;
}

const initialState: TreasureState = {
    boxes: [],
    showAnimation: false,
};

export default function ReducerTreasure(state: TreasureState = initialState, action: TreasureAction): TreasureState {
    switch (action.type) {
        case SAVE_TREASURE_BOXES: {
            action.masterBoxes.forEach((masterBox) => masterBox.chains.sort((a, b) => a.weight - b.weight));
            action.masterBoxes.forEach((masterBox) => masterBox.chains.forEach((chain) => chain.rewards.sort((a, b) => a.weight - b.weight)));
            return {
                ...state,
                boxes: action.masterBoxes,
            };
        }
        case RESET_TREASURE_CURRENT_BOX_STATE: {
            return {
                ...state,
                currentBox: action.currentBox,
            };
        }
        case TREASURE_SHOW_ANIMATION: {
            return {
                ...state,
                showAnimation: !!action.show,
                animationData: action.data,
            };
        }
        case SAVE_TREASURE_ACCOUNT_DATA: {
            return {
                ...state,
                account: action.account,
            };
        }
        case DO_PURCHASE: {
            return {
                ...state,
                account: { ...state.account, pendingTransaction: action.pendingTransaction?.id },
                activeTransaction: action.pendingTransaction,
            };
        }
        default:
            return { ...state };
    }
}
