import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './AuctionLotPage.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { useDispatch, useSelector } from 'react-redux';
import AuctionHeader from '~/components/Auction/AuctionHeader';
import Currency from '~/components/Currency/Currency';
import BidInput from '~/components/BidInput/BidInput';
import AuctionProcessor from '~/processors/AuctionProcessor';
import AuctionBidButton from '~/components/AuctionBidButton/AuctionBidButton';
import { State } from '~/Reducers';
import Attention from '~/components/Attention/Attention';
import { arrayToObjectByKey, formatNumber, isMobileOrTabletWindow } from '~/utils/utils';
import AuctionCancelButton from '~/components/AuctionBidButton/AuctionCancelButton';
import useBackground from '~/hooks/useBackground';
import CountDown from '~/components/CountDown/CountDown';
import PortPreviewButton from '~/components/PortPreviewButton/PortPreviewButton';
import { changeLotBackground, setBidLot } from '~/Actions/ActionAuction';
import { AUCTION_KIND, AUCTION_LOT_PROMO_TYPE, CATEGORIES } from '~/const';
import AuctionContains from '~/Layouts/AuctionLotPage/AuctionContains';
import { scrollTop } from '~/utils/scrollTop';
import AuctionStatus from '~/components/Auction/AuctionStatus';
import { getNoticeByKind, getShip, isLost, startHeadShakeAnimation } from '~/utils/auction';
import ActiveBid from '~/components/Popups/Auction/ActiveBid/ActiveBid';
import { isEnabledPortByLot, openPortForFirstAuctionReward } from '~/settings/port';
import { parseText } from '~/utils/html/';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import { urlsMap } from '~/utils/menu';
import useDelegateContainerScroll from '~/hooks/useDelegateContainerScroll';
import { changeViewBackground } from '~/Actions/ActionApp';
import History from '~/utils/history';
import useDelegationClickForLinkHandler from '~/hooks/useDelegationClickForLinkHandler';
import { getCurrency } from '~/utils/currencies';
import { getShipFeatures } from '~/utils/getShipFeatures';

interface IAuctionLotPage {
    lot: ILot;
    auction: IAuction;
    hideNoticeText?: boolean;
    fromCategory?: boolean;
}

interface IAuctionLotState {
    auctionAccount: IAuctionAccountState;
    inventory: InventoryState;
    activeBids: {
        [key: string]: number;
    };
    minimalWonBids: IAuctionMinimalWonBids[];
    balance: any;
    accountId: number;
}

const stateSelector = (state: State): IAuctionLotState => {
    return {
        auctionAccount: state.ReducerAuction.account,
        activeBids: state.ReducerAuction.activeBids,
        inventory: state.ReducerAccount.inventory,
        minimalWonBids: state.ReducerAuction.minimalWonBids,
        balance: state.ReducerAccount.balance,
        accountId: state.ReducerAccount.id,
    };
};

const AuctionLotPage = (props: IAuctionLotPage) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IAuctionLotState>(stateSelector, equal);

    const lineOneRef = React.useRef<HTMLDivElement>(null);
    const lineTwoRef = React.useRef<HTMLDivElement>(null);
    const bidInputWrapper = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const descriptionRef = React.useRef<HTMLDivElement>(null);

    const [isDisabledBidButton, setDisabledBidButton] = React.useState<boolean>(false);

    const auctionProcessor = new AuctionProcessor(props.auction, props.lot, state.auctionAccount);
    const isBidAlreadyPlaced = auctionProcessor.isBidAlreadyPlaced();
    const currentBidData = auctionProcessor.getActiveBidByLotId();
    const isAlreadyHaveLot = auctionProcessor.isAlreadyHaveLot(props.lot.id);
    const canPlacedBid = auctionProcessor.canPlacedBid(props.lot.id);
    const isBidCanceling = auctionProcessor.isBidCanceling();
    const isCurrentBidCanceling = auctionProcessor.isBidCanceling(true);
    const hasActiveTransaction = state.auctionAccount?.transactions?.length > 0;
    const currentBid = state.activeBids?.[props.lot.id] ?? (currentBidData?.bid?.amount || 0);
    const CURRENT_BID_STATUS = auctionProcessor.getBidStatus();
    const isLotLanding = !props.fromCategory && props.lot.promoType === AUCTION_LOT_PROMO_TYPE.LANDING;
    const isLanding = isLotLanding && !isMobileOrTabletWindow;
    const additionalContentRef = React.useRef(null);
    const slotContentRef = React.useRef<HTMLDivElement>(null);
    const [lotWithShipFeatures, setLotWithShipFeatures] = React.useState(false);

    useMaskScrollEffect(slotContentRef);
    useDelegateContainerScroll(slotContentRef, [bidInputWrapper], [props.auction.status]);

    useDelegationClickForLinkHandler(descriptionRef);

    useBackground(props.auction.backgroundImg, [CATEGORIES.AUCTION]);
    useMaskScrollEffect(additionalContentRef);

    const updateViewBackground = React.useCallback(() => {
        const lotBackgroundImg = isLanding ? props.lot.previewImageUrl : props.lot.imageInnerBackground;
        if (lotWithShipFeatures) {
            dispatch(changeLotBackground(lotBackgroundImg, props.lot.backgroundColor, props.lot.previewImageUrl4K));
        } else {
            dispatch(changeViewBackground(null, lotBackgroundImg || props.auction.backgroundImg));
        }
    }, [lotWithShipFeatures]);

    const resetViewBackground = React.useCallback(() => {
        if (lotWithShipFeatures) {
            dispatch(changeLotBackground(null, null));
        } else {
            dispatch(changeViewBackground(null, null));
        }
    }, [lotWithShipFeatures]);

    React.useEffect(() => {
        if (!props.lot.disableShipFeatures) {
            getShipFeatures(getShip(props.lot)?.id).then((data) => {
                data.featuresTags.length ? setLotWithShipFeatures(true) : setLotWithShipFeatures(false);
            });
        } else {
            setLotWithShipFeatures(false);
        }
    }, []);

    React.useEffect(() => {
        scrollTop(0, true);
        updateViewBackground();

        return () => {
            dispatch(setBidLot(props.lot.id, null));
            resetViewBackground();
        };
    }, []);

    const [realBid, setRealBid] = React.useState<number>(currentBid || props.lot.minimalBid.amount);
    const [isFinished, setFinish] = React.useState<boolean>(auctionProcessor.isFinishedLot());
    const balanceObject = state.accountId ? arrayToObjectByKey(state.balance, 'currency') : {};
    const getCurrencyBalance = (name: string) => {
        return balanceObject[name]?.value;
    };

    React.useEffect(() => {
        if (!isFinished && auctionProcessor.isFinishedLot()) {
            setFinish(true);
        }
    }, [props.auction.status]);

    const placeBet = () => auctionProcessor.showBetPopup(props.lot, true);

    const isDisabledChangeBid = currentBid && !props.auction?.bidRaisingAllowed && !props.auction?.bidDropAllowed;

    const lineClasses = classNames(styles.line, {
        [styles.isFinished]: isFinished,
    });

    const wrapperClasses = classNames(styles.wrapper, {
        [styles.landing]: isLanding,
    });

    let imageStyle = !isLanding && props.lot.previewImageUrl ? { backgroundImage: `url(${props.lot.previewImageUrl})` } : {};

    if (isLotLanding && isMobileOrTabletWindow) {
        imageStyle = {
            backgroundImage: `url(${props.lot.purchasePreviewImageUrl})`,
        };
    }

    const description = props.lot.description ? parseText(Array.isArray(props.lot.description) ? props.lot.description.join('{br}') : props.lot.description) : '';

    const classesDescription = classNames(styles.description, {
        [styles.isLanding]: isLanding,
    });

    if (props.auction.isNeedToShowPromoPage) {
        History.navigate(urlsMap.auction, { replace: true });
        return;
    }

    const minimalWonBid = state.minimalWonBids.find((bid) => bid.auctionId === props.auction.id && bid.lotId === props.lot.id);

    const classesContent = classNames(styles.content, {
        [styles.fromCategory]: props.fromCategory,
        [styles.isLanding]: isLanding,
    });

    const isShowNoticeMessage = !props.hideNoticeText && !isFinished && !isAlreadyHaveLot;

    return (
        <div className={wrapperClasses} key={`auction_${props.auction.status}`}>
            {!isLanding && (
                <div className={styles.image} style={imageStyle}>
                    <div className={styles.imageZipper} />
                </div>
            )}
            <div className={classesContent}>
                <div className={styles.header} ref={slotContentRef}>
                    <div className={classNames(styles.title, styles[props.lot.type])}>
                        <AuctionHeader {...props.lot} crewClassName={styles.crewTitlePage} />
                    </div>
                    <div className={lineClasses}>
                        {!isFinished && (
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockTitle}>{t('До завершения аукциона:')}</div>
                                <div className={classNames(styles.infoBlockContent, styles.infoBlockContentStart)}>
                                    <CountDown date={auctionProcessor.getFinishedAt()} isLarge={true} />
                                </div>
                            </div>
                        )}
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockTitle}>{t('Количество победителей:')}</div>
                            <div className={classNames(styles.infoBlockContent, styles.infoBlockContentStart)}>{formatNumber(props.lot.winnersCount)}</div>
                        </div>
                    </div>
                    <AuctionContains lot={props.lot} items={props.lot.gameRewards} lotId={props.lot.id} inventory={state.inventory} />
                    {!!props.lot.description?.length && <div className={classesDescription} dangerouslySetInnerHTML={{ __html: description }} ref={descriptionRef} />}
                </div>
                <div>
                    {isFinished && <div className={styles.finishedBlock}>{t('Аукцион завершён')}</div>}
                    <div className={lineClasses} ref={(_ref) => (lineOneRef.current = _ref)}>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockTitle}>{t('Минимальная ставка:')}</div>
                            <div className={classNames(styles.infoBlockContent, styles.infoBlockContentStart)}>
                                <Currency
                                    className={classNames({
                                        [styles.minBid]: !isFinished,
                                    })}
                                    currency={props.lot.minimalBid.currency}
                                    amount={props.lot.minimalBid.amount}
                                    withoutAnimation
                                    showDescriptionTooltip={props.auction.showDescriptionInPriceTooltip && getCurrency(props.lot.minimalBid.currency)?.showDescriptionInPriceTooltip}
                                />
                            </div>
                        </div>
                        {isFinished && isLost(auctionProcessor.getBidStatus()) && !!props.lot.hintBid && (
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockTitle}>{t('Средняя ставка:')}</div>
                                <div className={classNames(styles.infoBlockContent, styles.infoBlockContentStart)}>
                                    <Currency
                                        withoutAnimation
                                        currency={props.lot.hintBid?.currency}
                                        amount={props.lot.hintBid?.amount}
                                        showDescriptionTooltip={props.auction.showDescriptionInPriceTooltip && getCurrency(props.lot.hintBid?.currency)?.showDescriptionInPriceTooltip}
                                    />
                                </div>
                            </div>
                        )}
                        {isFinished && props.auction.kind === AUCTION_KIND.SECOND_PRICE && minimalWonBid && (
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockTitle}>{t('Минимальная сыгравшая ставка:')}</div>
                                <div className={classNames(styles.infoBlockContent, styles.infoBlockContentStart)}>
                                    <Currency
                                        currency={minimalWonBid.bid.currency}
                                        amount={minimalWonBid.bid.amount}
                                        withoutAnimation
                                        showDescriptionTooltip={props.auction.showDescriptionInPriceTooltip && getCurrency(minimalWonBid.bid.currency)?.showDescriptionInPriceTooltip}
                                    />
                                </div>
                            </div>
                        )}
                        {isFinished && !!isBidAlreadyPlaced && (
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockTitle}>{t('Ваша ставка:')}</div>
                                <div className={classNames(styles.infoBlockContent, styles.infoBlockContentStart)}>
                                    <div className={styles.cancelBidWrapper}>
                                        <ActiveBid
                                            auction={props.auction}
                                            placedAt={auctionProcessor.getBid()?.placedAt}
                                            currency={props.lot.minimalBid.currency}
                                            amount={auctionProcessor.getBid()?.bid?.amount}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {canPlacedBid && (
                        <React.Fragment>
                            {!isFinished && (
                                <div className={styles.line} ref={(_ref) => (lineTwoRef.current = _ref)}>
                                    {!!isBidAlreadyPlaced && (
                                        <div className={styles.infoBlock}>
                                            <div className={styles.infoBlockTitle}>{t('Ваша ставка:')}</div>
                                            <div className={classNames(styles.infoBlockContent)}>
                                                <div className={styles.cancelBidWrapper}>
                                                    <ActiveBid
                                                        auction={props.auction}
                                                        placedAt={currentBidData?.placedAt}
                                                        currency={props.lot.minimalBid.currency}
                                                        amount={currentBidData?.bid?.amount}
                                                    />
                                                    {auctionProcessor.canCanceledBid(props.lot.id) && (
                                                        <AuctionCancelButton
                                                            isProcessing={hasActiveTransaction}
                                                            isFetching={isCurrentBidCanceling}
                                                            lot={props.lot}
                                                            className={styles.cancelBidButton}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!isDisabledChangeBid && (
                                        <div className={styles.infoBlock}>
                                            <div className={styles.infoBlockTitle}>{isBidAlreadyPlaced ? t('Новая ставка:') : t('Ваша ставка:')}</div>
                                            <div className={styles.infoBlockContent} ref={bidInputWrapper}>
                                                <BidInput
                                                    activeBid={auctionProcessor.getPlacedActiveBidByLotId()?.bid?.amount}
                                                    inputClassName={styles.inputBid}
                                                    bidCancellationsAllowed={props.auction?.bidCancellationsAllowed}
                                                    bidRaisingAllowed={props.auction?.bidRaisingAllowed}
                                                    bidDropAllowed={props.auction?.bidDropAllowed}
                                                    currency={props.lot.minimalBid.currency}
                                                    minLotBid={props.lot.minimalBid.amount}
                                                    hasTransaction={hasActiveTransaction || isBidCanceling}
                                                    bid={realBid}
                                                    lotId={props.lot.id}
                                                    maxBidAmount={props.lot.maxBidAmount}
                                                    currencyBalance={getCurrencyBalance(props.lot.minimalBid.currency)}
                                                    changeBid={(bid) => {
                                                        setDisabledBidButton(false);
                                                        setRealBid(bid);
                                                    }}
                                                    onInput={(bid) => {
                                                        setDisabledBidButton(bid < props.lot.minimalBid.amount);
                                                        setRealBid(bid);
                                                    }}
                                                    setRef={(_ref) => {
                                                        if (_ref) {
                                                            inputRef.current = _ref;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!isDisabledChangeBid && (
                                        <div className={classNames(styles.infoBlock, styles.buttonBid)}>
                                            <div className={styles.infoBlockTitle}>&nbsp;</div>
                                            <div className={styles.infoBlockContent}>
                                                <AuctionBidButton
                                                    isBidAlreadyPlaced={isBidAlreadyPlaced}
                                                    isDisabled={(isBidAlreadyPlaced && currentBidData?.bid?.amount === realBid) || isDisabledBidButton}
                                                    isProcessing={hasActiveTransaction || isBidCanceling}
                                                    lotId={props.lot.id}
                                                    onClick={placeBet}
                                                    typeButton={'orange'}
                                                    coolDownAt={auctionProcessor.getCoolDownAt()}
                                                    minimalBid={props.lot.minimalBid}
                                                    renderFastGoldLink={true}
                                                    onFallbackClick={() => {
                                                        startHeadShakeAnimation(bidInputWrapper.current, styles.headShakeAnim);
                                                        inputRef.current?.focus();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {isEnabledPortByLot(props.lot) && (
                                        <div className={classNames(styles.infoBlock)}>
                                            <div className={styles.infoBlockTitle}>&nbsp;</div>
                                            <div className={styles.infoBlockContent}>
                                                <PortPreviewButton
                                                    onClick={() => {
                                                        openPortForFirstAuctionReward(props.lot);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {isShowNoticeMessage && isLanding && <div className={classNames(styles.message, styles.forLanding)}>{getNoticeByKind(props.auction.kind)}</div>}
                    {isAlreadyHaveLot && (
                        <div className={classNames(styles.auctionStatus, styles.win)}>
                            <Attention level={'done'} label={t('Имущество получено')} className={styles.statusText} isInline />
                        </div>
                    )}
                    <AuctionStatus
                        className={styles.auctionStatus}
                        currentBid={currentBid}
                        minimalWonBid={minimalWonBid}
                        bidStatus={CURRENT_BID_STATUS}
                        auctionStatus={props.auction.status}
                        isBidPlaced={isBidAlreadyPlaced}
                        mergeStatusToOneBlock
                    />
                </div>
            </div>
            {isShowNoticeMessage && !isLanding && <div className={styles.message}>{getNoticeByKind(props.auction.kind)}</div>}
        </div>
    );
};

export default AuctionLotPage;
