import * as React from 'react';
import styles from './Beacon.scss';

interface IBeacon {
    top?: number;
    left?: number;
}

const Beacon = (props: IBeacon) => {
    const top = props.top || 0 + 'px';
    const left = props.left || 0 + 'px';

    return (
        <div className={styles.wrapper} style={{ top, left }}>
            <div className={styles.beacon} id={'beacon'} />
        </div>
    );
};

export default Beacon;
