import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ICurrentPage } from '~/Actions/ActionAppType';
import FrostAnimation from '~/customization/Frost/FrostAnimation';
import { NY_THEME } from '~/Layouts/Themes/ThemeManager';
import { State } from '~/Reducers';
import { getGlobalContent } from '~/utils/settings';

interface IAnimationDecorator {
    children: React.ReactChild | React.ReactChild[];
    mainColor?: string;
    isDisabled?: boolean;
}

interface IStateSelector {
    currentPage: ICurrentPage;
    categories: ICategories;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
    };
};

export const THEMES_WITH_PERSONAL_ANIMATION: string[] = [];

const AnimationDecorator = ({ children, mainColor, isDisabled }: IAnimationDecorator) => {
    const { currentPage, categories } = useSelector<State, IStateSelector>(stateSelector, equal);
    const currentCategory = categories?.[currentPage?.name];
    const theme = THEMES_WITH_PERSONAL_ANIMATION?.includes(currentCategory?.theme) ? currentCategory.theme : getGlobalContent()?.theme;

    switch (theme) {
        case NY_THEME:
            return (
                <FrostAnimation mainColor={mainColor} isDisabled={isDisabled}>
                    {children}
                </FrostAnimation>
            );

        default:
            return <>{children}</>;
    }
};

export default AnimationDecorator;
