import equal from 'fast-deep-equal/react';
import * as React from 'react';
import styles from './AccountLevelingRestiction.scss';
import { useSelector } from 'react-redux';
import { ICurrentPage } from '~/Actions/ActionAppType';
import InfoBlock from '~/components/InfoBlock/InfoBlock';
import { CATEGORIES } from '~/const';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import { State } from '~/Reducers';
import { IBundleCategory } from '~/Reducers/ReducerApp';
import { getLevelingRestrictionForBundle } from '~/utils/levelingResctrictions';
import { t } from '~/utils/localization';
import { settings } from '~/utils/settings';
import { getStaticUrl } from '~/utils/utils';

interface IStateSelector {
    currentPage: ICurrentPage;
    bundleCategory: IBundleCategory;
    bundles: IBundleList;
    selectedRandomBundles?: AccountSelectedRandomBundles;
    categories: ICategories;
    featuring: IFeature[];
    accountId: number;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
        bundleCategory: state.ReducerApp.bundleCategory,
        bundles: state.ReducerApp.bundles,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        featuring: state.ReducerApp.featuring,
        accountId: state.ReducerAccount.id,
    };
};

const categoriesWithLevelingRestrictionMap: Record<string, boolean> = {};

function hasLevelingRestictionInFeaturing(featuring: IFeature[], bundles: IBundleList, bundleCategory: IBundleCategory, selectedRandomBundles: AccountSelectedRandomBundles, categories: ICategories) {
    return featuring.some((contentBlock) => {
        const content = contentBlock.contentBlocks;
        let flag = false;
        content.forEach((block) => {
            if (block.type === FEATURING_TEMPLATES.BUNDLE_LIST) {
                (block.data as IContentBlockData).bundles.forEach((bundle) => {
                    const currentBundle = bundles[bundle.id];
                    if (!currentBundle) {
                        return;
                    }
                    const restrictions = getLevelingRestrictionForBundle(currentBundle, selectedRandomBundles, true);
                    if (!flag && restrictions?.length) {
                        flag = true;
                    }
                });
            } else if (block.type === FEATURING_TEMPLATES.CATEGORY_PREVIEW) {
                if (!flag) {
                    const data = block.data as IContentBlockData;
                    const category = categories[data.categoryName];
                    if (category) {
                        if (!category.labelNewAllowed) {
                            flag = hasLevelingRestictionInCategory(category, bundleCategory, bundles, selectedRandomBundles);
                        } else {
                            let categoryBundles = Object.values(bundles).filter((bundle: IBundle) => category.bundles.includes(bundle.id));
                            categoryBundles = data.bundlesCount ? categoryBundles.splice(0, data.bundlesCount) : categoryBundles;
                            flag = categoryBundles.some((bundle: IBundle) => getLevelingRestrictionForBundle(bundle, selectedRandomBundles, true)?.length);
                        }
                    }
                }
            }
        });

        return flag;
    });
}

function hasLevelingRestictionInCategory(category: ICategory, bundleCategory: IBundleCategory, bundles: IBundleList, selectedRandomBundles: AccountSelectedRandomBundles) {
    const bundleIds = bundleCategory[category.name];

    for (const i in bundleIds) {
        const bundle = bundles[bundleIds[i]];
        const restrictions = getLevelingRestrictionForBundle(bundle, selectedRandomBundles, true);
        if (restrictions?.length) {
            categoriesWithLevelingRestrictionMap[category.name] = true;
            return true;
        }
    }

    return false;
}

function hasLevelingRestiction(
    category: ICategory,
    bundleCategory: IBundleCategory,
    bundles: IBundleList,
    selectedRandomBundles: AccountSelectedRandomBundles,
    featuring: IFeature[],
    categories: ICategories,
) {
    if (!category) {
        return false;
    }

    if (category.name in categoriesWithLevelingRestrictionMap) {
        return categoriesWithLevelingRestrictionMap[category.name];
    }

    if (category.name === CATEGORIES.FEATURED) {
        categoriesWithLevelingRestrictionMap[category.name] = hasLevelingRestictionInFeaturing(featuring, bundles, bundleCategory, selectedRandomBundles, categories);
        return categoriesWithLevelingRestrictionMap[category.name];
    }

    if (category.subCategories?.length) {
        for (const i in category.subCategories) {
            const categoryName = category.subCategories[i];
            const subCategory = categories[categoryName];
            const hasRestriction = hasLevelingRestictionInCategory(subCategory, bundleCategory, bundles, selectedRandomBundles);
            if (hasRestriction) {
                categoriesWithLevelingRestrictionMap[category.name] = true;
                return true;
            }
        }
        categoriesWithLevelingRestrictionMap[category.name] = false;
        return false;
    }

    categoriesWithLevelingRestrictionMap[category.name] = hasLevelingRestictionInCategory(category, bundleCategory, bundles, selectedRandomBundles);

    return categoriesWithLevelingRestrictionMap[category.name];
}

const AccountLevelingRestictionContainer = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    if (!state.accountId) {
        return <></>;
    }

    if (!settings.accountLevelingRestriction) {
        return <></>;
    }

    const hasRestriction = hasLevelingRestiction(state.categories[state.currentPage?.name], state.bundleCategory, state.bundles, state.selectedRandomBundles, state.featuring, state.categories);

    if (!hasRestriction) {
        return <></>;
    }

    return (
        <InfoBlock
            className={styles.wrapper}
            title={t('Развивайте аккаунт и открывайте новые возможности!')}
            description={t('Вы можете приобрести любое имущество в этой категории, но некоторые предметы можно будет использовать, только когда вам откроется определённый уровень доступа.')}
            iconUrl={getStaticUrl(require('../../assets/images/icon_account_level_restriction_info.png'))}
        />
    );
};

export default AccountLevelingRestictionContainer;
