import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import store from '~/Store';
import { loadSeaBattleSettings } from '~/Actions/ActionSeaBattle';

export default class SeaBattlePreloader extends AbstractPreloader {
    async load(): Promise<any> {
        // @ts-ignore
        store.dispatch(loadSeaBattleSettings());
    }
}
