import styles from './TreasureChainGroup.scss';
import TreasureProgressBar from '~/components/TreasureChain/TreasurePurchaseBlock/TreasureProgress/TreasureProgressBar/TreasureProgressBar';
import { getChainGuaranteeMsg } from '~/components/TreasureChain/TreasurePurchaseBlock/TreasureProgress/TreasureProgress';
import { t } from '~/utils/localization';
import { State } from '~/Reducers';
import { shallowEqual, useSelector } from 'react-redux';
import { TreasureRewardItem } from '~/components/TreasureChain/TreasureInfoBlock/TreasureRewardsSlider/TreasureReward/TreasureReward';
import { isMobileOrTabletWindow } from '~/utils/utils';
import React from 'react';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import Info from '~/components/Info/Info';
import { DivTooltip } from '@wg/wows-react-uikit';

type Props = {
    chain: TreasureChain;
    key: number;
    allWeights: number;
    box: string;
    background?: string;
    inventory: InventoryState;
};

type StateSelector = {
    account: TreasureAccountData;
};

function stateSelector(state: State): StateSelector {
    return {
        account: state.ReducerTreasure.account,
    };
}

export function getChainWeightMsg(weight: number): string {
    return t('Вероятность: {number}%').replace('{number}', weight.toString());
}

export default function TreasureChainGroup({ chain, key, allWeights, box, background, inventory }: Props) {
    const state = useSelector<State, StateSelector>((state) => stateSelector(state), shallowEqual);
    const rotation: number = state.account?.rotations?.[box]?.[chain.name] || chain.rotationsTillGuarantee || 0;
    const weight = (chain.weight / allWeights) * 100;
    return (
        <div key={key} className={styles.wrapper}>
            <div className={styles.group}>
                <div className={styles.block}>
                    {chain?.icon ? <img className={styles.icon} src={chain.icon} alt={'icon'} /> : null}
                    <div className={styles.group__info}>
                        <div className={styles.group__title}>
                            {chain?.title || chain?.name}
                            <DivTooltip
                                className={styles.infoIcon}
                                tooltipBody={<DefaultTooltip text={t('Вероятность получить конкретную награду из группы одинаковая для всего имущества в группе.')} />}
                            >
                                <Info color={'white'} />
                            </DivTooltip>
                        </div>
                        <div className={styles.group__weight}>{getChainWeightMsg(weight)}</div>
                    </div>
                </div>
                <div className={styles.group__description}>
                    {chain.guarantee && (
                        <>
                            {getChainGuaranteeMsg(rotation)}
                            <TreasureProgressBar chain={chain} tilGuarantee={rotation} hideProgressBarInMobile />
                        </>
                    )}
                </div>
            </div>
            <div className={styles.composition}>
                {chain.rewards.map((reward) => (
                    <div className={styles.reward}>
                        <TreasureRewardItem rewardData={reward} inventory={inventory} withAmount fromPopup withoutPopup={isMobileOrTabletWindow} background={background} />
                    </div>
                ))}
            </div>
        </div>
    );
}
