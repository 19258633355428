import styles from './TreasurePurchase.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { t } from '~/utils/localization';
import Info from '~/components/Info/Info';
import Price from '~/components/Price/Price';
import { Button, ButtonColorPresets } from '~/components/Button/Buttons';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { assets, EntityTypes } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import * as React from 'react';
import TreasureProcessor from '~/processors/TreasureProcessor';
import { playButtonClickSound } from '~/api/WoWsClient';
import CurrencyShortageBlock from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { isEnoughCurrency } from '~/utils/auction';
import Discount, { DiscountSize } from '~/components/Discount/Discount';
import { getBalanceByKey } from '~/utils/purchase';
import { State } from '~/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { redirectToLogin } from '~/utils/utils';
import { isNeedToShowDownloadGamePopup, showDownloadGamePopup } from '~/components/Popups/settings';

type Props = {
    masterBox: TreasureMasterBox;
    balance: IBalance;

    hasPending: boolean;
    setPending: (val: boolean) => void;
};

const processor = new TreasureProcessor();

interface ITreasurePurchaseStatus {
    accountId?: number;
}

const stateSelector = (state: State): ITreasurePurchaseStatus => {
    return {
        accountId: state.ReducerAccount.id,
    };
};

export default function TreasurePurchase(props: Props) {
    const state = useSelector<State, ITreasurePurchaseStatus>(stateSelector, equal);
    const dispatch = useDispatch();
    const [activeCurrency, setActiveCurrency] = useState<number>(0);
    const currencyCodes = Object.keys(props.masterBox.prices);
    const activeCurrencyCode = currencyCodes[activeCurrency];
    const isPurchaseByGold = activeCurrencyCode === assets.GOLD;

    const initFastGold = (goldShortage: number) => {
        dwhExport.send(DWH_EVENTS.OPEN_FAST_GOLD_POPUP, { place: FAST_GOLD_PLACE.MRPS_VIEW_PAGE });
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(POPUPS_NAME.WSMART_DOUBLOONS_POPUP, true, { goldShortage }));
    };

    const initPurchase = useCallback(
        async (multiplier = 1) => {
            props.setPending(true);
            await processor.buy({ masterboxName: props.masterBox.name, currency: activeCurrencyCode, amount: multiplier });
            props.setPending(false);
        },
        [activeCurrencyCode],
    );

    const getPurchaseBtn = useCallback(
        (multiplier = 1) => {
            const text = props.hasPending ? t('Обработка') : t(`Купить ${multiplier} шт`);
            const amount = props.masterBox.prices[activeCurrencyCode][multiplier];
            let discount = 0;
            const basePrice = multiplier * props.masterBox.prices[activeCurrencyCode][1];
            if (multiplier > 1) {
                if (basePrice !== amount) {
                    discount = 100 - Math.floor((amount / basePrice) * 100);
                }
            }
            const disabled = props.balance && !isEnoughCurrency(activeCurrencyCode, amount, props.balance);
            const wallet = getBalanceByKey(props.balance, activeCurrencyCode);
            const neededMoney = props?.balance ? amount - wallet.value : 0;
            return (
                <div className={styles.purchase__pay__column} key={multiplier}>
                    <div className={styles.priceBlock}>
                        {discount ? <Discount size={DiscountSize.DEFAULT} className={styles.discount} discount={discount} /> : null}
                        <div>
                            {discount ? <Price withoutIcon withoutTooltip currency={activeCurrencyCode} amount={basePrice} className={styles.price} /> : null}
                            <Price withoutTooltip currency={activeCurrencyCode} amount={amount} className={styles.price} />
                        </div>
                    </div>
                    {!disabled || isPurchaseByGold ? (
                        <Button
                            label={text}
                            backgroundColor={ButtonColorPresets.ORANGE}
                            className={styles.btn}
                            isFetching={props.hasPending}
                            onClick={(event) => {
                                event.stopPropagation();
                                playButtonClickSound();
                                if (!state.accountId) {
                                    redirectToLogin();
                                    return;
                                }
                                if (isNeedToShowDownloadGamePopup()) {
                                    showDownloadGamePopup();
                                    return;
                                }
                                disabled ? initFastGold(neededMoney) : initPurchase(multiplier);
                            }}
                        />
                    ) : (
                        <div className={classNames(styles.btn, styles.currencyShortageBlock)}>
                            <CurrencyShortageBlock currencies={[{ currency: activeCurrencyCode, amount: amount - wallet.value }]} />
                        </div>
                    )}
                </div>
            );
        },
        [activeCurrency, props.hasPending, props.balance],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.informer}>
                <span>{t('Покупка без подтверждения')}</span>
                <DivTooltip
                    tooltipBody={<DefaultTooltip classes={{ body: styles.noAccept__tooltip }} text={t('При нажатии кнопки «Купить» транзакция будет совершена без дополнительного подтверждения')} />}
                    className={styles.noAccept}
                >
                    <Info color={'white'} />
                </DivTooltip>
            </div>
            <div className={styles.purchase}>
                <div className={styles.purchase__currencies}>
                    {currencyCodes.map((currency, i) => {
                        return (
                            <div key={i} className={classNames(styles.purchase__currencies__item, i && styles.additional, activeCurrency === i && styles.active)} onClick={() => setActiveCurrency(i)}>
                                <WoWSEntity
                                    key={currency}
                                    type={EntityTypes.CURRENCY}
                                    id={currency}
                                    presentation={{
                                        withText: true,
                                        iconPosition: 'left',
                                        withTooltip: false,
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className={styles.purchase__pay}>
                    {Object.keys(props.masterBox.prices[activeCurrencyCode]).map((multiplier) => {
                        return getPurchaseBtn(parseInt(multiplier));
                    })}
                </div>
            </div>
        </div>
    );
}
