import * as React from 'react';
import styles from './FeaturedTimer.scss';
import classNames from 'classnames';
import CountDown from '~/components/CountDown/CountDown';
import Info from '~/components/Info/Info';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';

interface IFeaturedTimer {
    activeTill: string;
    color?: string;
    title: string;
    description?: string;
    className?: string;
    countDownClassName?: string;
    onComplete?: () => void;
}

const FeaturedTimer = ({ activeTill, color, title, description, className, countDownClassName, onComplete }: IFeaturedTimer) => {
    const style = { '--featured-timer-color': color } as React.CSSProperties;
    const dropShadowFilter = { [styles.dropShadowFilter]: !color };

    return (
        <div className={classNames(styles.wrapper, className, dropShadowFilter)}>
            {description ? (
                <DivTooltip className={styles.title} style={style} tooltipBody={<DefaultTooltip text={description} />}>
                    <span className={styles.info}>
                        <Info color={color} />
                    </span>
                    <span className={styles.label}>{title}</span>
                </DivTooltip>
            ) : (
                <div className={styles.title} style={style}>
                    <span className={styles.label}>{title}</span>
                </div>
            )}
            <div className={styles.timer} style={style}>
                <CountDown date={new Date(activeTill).getTime()} isLarge={true} className={classNames(styles.fontNormal, countDownClassName)} onComplete={onComplete} />
            </div>
        </div>
    );
};

export default FeaturedTimer;
