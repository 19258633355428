import * as React from 'react';
import styles from './AttentionLimitWidget.scss';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { getSumFromArray } from '~/utils/number';
import { DivTooltip } from '@wg/wows-react-uikit';
import Attention from '~/components/Attention/Attention';
import BundleLimitTooltip, { BUNDLE_LIMIT_ERROR_TEXTS } from '~/components/BundleLimitWidget/Tooltip/BundleLimitTooltip';
import { interpolate, t } from '~/utils/localization';
import Timer from '~/components/Timer/Timer';
import { ManagerData } from '~/core/ManagerData';
import { getContainerIdsFromBundle, getContainerInfoFromTooltip, getExpireTime, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';

interface IStateSelector {
    lootboxesBundlesMap: ILootboxesBundlesMap;
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
    bundles: IBundleList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        lootboxesBundlesMap: state.ReducerApp.lootboxesBundlesMap,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,
        bundles: state.ReducerApp.bundles,
    };
};

interface IAttentionLimitWidget {
    reason: REASON_FAIL_PURCHASE_CONTAINER;
    bundle?: IBundle;
    className?: string;
}

const AttentionLimitWidget = ({ reason, bundle, className }: IAttentionLimitWidget) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const restrictedLootboxesPurchaseCount = state.restrictedLootboxesPurchaseCount;
    const totalPurchased = getSumFromArray(Object.values(restrictedLootboxesPurchaseCount || []));
    const bundlesInfo = getContainerInfoFromTooltip(bundle, restrictedLootboxesPurchaseCount);
    const containersIds = getContainerIdsFromBundle(bundle) || [];

    let label = BUNDLE_LIMIT_ERROR_TEXTS[reason];
    if (reason === REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_PER_LIMIT_AFTER_PURCHASE && containersIds.length > 1) {
        label = BUNDLE_LIMIT_ERROR_TEXTS[REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE];
    }

    if (
        containersIds.length === 1 &&
        (reason === REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_PER_TYPE_LIMIT ||
            reason === REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE ||
            reason === REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_PER_LIMIT_AFTER_PURCHASE)
    ) {
        const container = ManagerData.getLootbox(containersIds[0]);
        if (container) {
            label = interpolate(label, { name: container.shortTitle });
        }
    }

    return (
        <DivTooltip tooltipBody={<BundleLimitTooltip bundlesInfo={bundlesInfo} total={totalPurchased} />}>
            <Attention
                className={className}
                label={
                    <React.Fragment>
                        {label && (
                            <React.Fragment>
                                {label}
                                <br />
                            </React.Fragment>
                        )}
                        {t('Обновление лимита:')}
                        <Timer className={styles.timerWrapper} time={getExpireTime()} withoutIcon={true} />
                    </React.Fragment>
                }
            />
        </DivTooltip>
    );
};

export default AttentionLimitWidget;
