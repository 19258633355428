import * as React from 'react';
import styles from './RandomBundleFullPurchaseButton.scss';
import { getProgressiveDiscountFromBundle } from '~/utils/bundles';
import { interpolate, t } from '~/utils/localization';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IRandomBundleFullPurchaseButton {
    bundle: IBundle;
    availableAmountForPurchase: number;
    onClick?: () => void;
}

const RandomBundleFullPurchaseButton = ({ bundle, availableAmountForPurchase, onClick }: IRandomBundleFullPurchaseButton) => {
    const progressivePurchase = getProgressiveDiscountFromBundle(bundle, availableAmountForPurchase);
    const text = interpolate(t('Все наборы, {amount} шт.'), { amount: availableAmountForPurchase });
    const hasDiscount = !!progressivePurchase?.discount;

    return (
        <div
            className={styles.wrapper}
            onClick={() => {
                playButtonClickSound();
                onClick?.();
            }}
        >
            {hasDiscount && <div className={styles.discount}>{interpolate(t('-{percent}%'), { percent: progressivePurchase.discount })}</div>}
            <span>{text}</span>
        </div>
    );
};

export default RandomBundleFullPurchaseButton;
