import Account from '~/account/Account';
import { redirectToLogin } from '~/utils/utils';

export function redirectToLoginIfNeeded(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    descriptor.value = function () {
        return Account.getAccount() ? method.apply(this, arguments) : redirectToLogin.apply(this);
    };
}

export function authorizationRequired(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    descriptor.value = function () {
        return !!Account.getAccount()?.id ? method.apply(this, arguments) : false;
    };
}
