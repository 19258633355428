import * as React from 'react';
import styles from './User.scss';
import className from 'classnames';
import { armoryState, openUrl, settings } from '~/utils/settings';
import { interpolate, t } from '~/utils/localization';
import useClickAway from '~/hooks/useClickAway';
import { isInGameBrowser } from '~/utils/utils';
import { logOut } from '~/utils/authMenu';

interface IUser {
    useProfileIcon?: boolean;
}

const User = (props: IUser) => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const [isVisibleMenu, setVisibleMenu] = React.useState(false);
    const accountInfo = armoryState.account;

    const userNameClassNames = className(styles.userName, {
        [styles.active]: isVisibleMenu,
    });

    const changeMenuVisible = () => setVisibleMenu(!isVisibleMenu);

    useClickAway(ref, (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.classList.contains(styles.userName) || target.classList.contains(styles.userIcon)) {
            return;
        }

        setVisibleMenu(false);
    });

    const goToSupport = () => {
        openUrl(settings.urls.supportUrl);
    };

    if (!accountInfo) {
        const logInUrl = `${settings.urls.loginUrl}?next=${document.location.pathname}`;
        const logInText = interpolate(t('{login_open}Войти{login_close} или {create_open}Создать аккаунт{create_close}'), {
            login_open: `<a href="${logInUrl}">`,
            login_close: `</a>`,
            create_open: `<a href="${settings.urls.registrationUrl}">`,
            create_close: `</a>`,
        });

        return (
            <div className={styles.wrapper}>
                <div className={styles.logInText} dangerouslySetInnerHTML={{ __html: logInText }} />
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                {!props.useProfileIcon ? (
                    <div className={userNameClassNames} onClick={changeMenuVisible}>
                        {accountInfo.name}
                    </div>
                ) : (
                    <div className={styles.userIcon} onClick={changeMenuVisible} />
                )}
            </div>
            {isVisibleMenu && (
                <div className={styles.menuWrapper} ref={ref}>
                    {props.useProfileIcon && <div className={className(styles.menuItem, styles.profileItem)}>{accountInfo.name}</div>}
                    <div className={styles.menuItem} onClick={goToSupport}>
                        {t('Центр поддержки')}
                    </div>
                    {!isInGameBrowser && (
                        <div className={className(styles.menuItem, styles.logoutMenuItem)} onClick={logOut}>
                            {t('Выйти')}
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default User;
