import React from 'react';
import classNames from 'classnames';

import styles from './RadioButton.scss';

interface RadioButtonProps {
    isActive: boolean;

    wrapperClassName?: string;

    onClick?: () => void;
}

export function RadioButton(props: RadioButtonProps) {
    return (
        <button
            className={classNames(styles.wrapper, props.wrapperClassName, {
                [styles.wrapper_active]: props.isActive,
            })}
            onClick={props.onClick}
        >
            <div className={styles.hover} />
            <div className={styles.active} />
        </button>
    );
}
