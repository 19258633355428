import * as React from 'react';
import styles from './SerialBundlePage.scss';
import equal from 'fast-deep-equal/react';
import { useSelector } from 'react-redux';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { State } from '~/Reducers';
import classNames from 'classnames';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import useDelegateContainerScroll from '~/hooks/useDelegateContainerScroll';
import SerialItemsSmallCarousel from '~/components/SerialItemsSmallCarousel/SerialItemsSmallCarousel';
import { getAvailabelSerialBundleForPurchase, getSerialBundlesById } from '~/utils/bundles';
import BundlePageLabels from '~/Layouts/BundlePage/BundlePageLabels/BundlePageLabels';
import BundlePageHeader from '~/Layouts/BundlePage/BundlePageHeader/BundlePageHeader';
import BundlePageDescription from '~/Layouts/BundlePage/BundlePageDescription/BundlePageDescription';
import SerialBundleFooter from './SerialBundleFooter';
import { BundleLimitContainerWidget } from '../BundlePage/BundlePageHeader/content';
import { renderContainsBlock } from '~/Layouts/BundlePage/BundlePageDescription/handlers';

interface ISerialBundlePage {
    bundle: IBundle;
}

interface IStateSelector {
    accountId: number;

    currentPage: ICurrentPage;

    categories: ICategories;
    disabledCategories?: string[];

    bundles: IBundleList;
    purchasedLimitedBundles?: AccountPurchasedLimitedBundles;
    bundlesInTransaction: number[];
    restrictedLootboxesPurchaseCount?: AccountRestrictedLootboxesPurchaseCount;

    balance: IBalance;
    activeCouponId?: number;
    coupons: ICoupon[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount?.id,

        currentPage: state.ReducerApp.currentPage,

        categories: state.ReducerApp.categories,
        disabledCategories: state.ReducerApp.disabledCategories,

        bundles: state.ReducerApp.bundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        bundlesInTransaction: state.ReducerAccount.bundlesInTransaction,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,

        balance: state.ReducerAccount.balance,
        activeCouponId: state.ReducerAccount.activeCouponId,
        coupons: state.ReducerAccount.coupons,
    };
};

const SerialBundlePage = ({ bundle }: ISerialBundlePage) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const refContent = React.useRef<HTMLDivElement>(null);
    const sequence = getSerialBundlesById(state.bundles, bundle.id);

    useMaskScrollEffect(refContent);
    useDelegateContainerScroll(refContent);

    const availableBundle = getAvailabelSerialBundleForPurchase(sequence, state.purchasedLimitedBundles);
    const isContainsBlockRendered = !!renderContainsBlock(bundle);

    return (
        <div className={classNames(styles.container, 'armory__auto--bundle-page_wrapper')} data-bundle-id={bundle.id}>
            <div className={styles.carousel}>
                <SerialItemsSmallCarousel bundle={bundle} sequence={sequence} availableBundle={availableBundle} />
            </div>
            <div className={styles.content} ref={refContent}>
                <div className={styles.bundleContent}>
                    <BundlePageLabels bundle={bundle} />
                    <BundlePageHeader bundle={bundle} bundles={state.bundles} renderPreviewContent={!isContainsBlockRendered} />
                    <BundleLimitContainerWidget bundle={bundle} className={styles.limitWidget} />
                    <div className={styles.mobileImage}>
                        <img src={bundle.icons?.big} />
                    </div>
                    <BundlePageDescription bundle={bundle} />
                </div>
            </div>
            <SerialBundleFooter
                sequence={sequence}
                currentPage={state.currentPage}
                isAuthorized={!!state.accountId}
                bundle={bundle}
                purchasedLimitedBundles={state.purchasedLimitedBundles}
                restrictedLootboxesPurchaseCount={state.restrictedLootboxesPurchaseCount}
                balance={state.balance}
                bundlesInTransaction={state.bundlesInTransaction || []}
                bundles={state.bundles}
            />
        </div>
    );
};

export default SerialBundlePage;
