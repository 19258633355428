interface EntriesMap {
    target: HTMLElement;
    callback: (isIntersecting: boolean) => void;
}

class IntersectionObserverBundles {
    private intersectionObserver: IntersectionObserver;

    private entries = new Map<string, EntriesMap>();

    observe(target: EntriesMap['target'], callback: EntriesMap['callback'], id: string) {
        this.entries.set(id, {
            target,
            callback,
        });

        this.intersectionObserver?.observe(target);
    }

    unobserve(id: string) {
        if (this.entries.has(id)) {
            const entry = this.entries.get(id);

            this.intersectionObserver?.unobserve(entry.target);
            this.entries.delete(id);
        }
    }

    clear() {
        this.entries.forEach((entry) => {
            this.intersectionObserver?.unobserve(entry.target);
        });

        return this;
    }

    init() {
        this.intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const target: any = entry.target;
                    const id = target.dataset.lazyId;

                    this.entries.get(id)?.callback(entry.isIntersecting);
                });
            },
            {
                rootMargin: '300px',
            },
        );

        this.entries.forEach((entry) => {
            this.intersectionObserver.observe(entry.target);
        });
    }
}

export const BundlesObserver = new IntersectionObserverBundles();
