import classNames from 'classnames';
import * as React from 'react';
import Label from '~/components/Label/Label';

import styles from './LabelContainer.scss';

interface ILabelContainer {
    className?: string;
    text?: string;
    isVisible?: boolean;
}

const LabelContainer = (props: ILabelContainer) => {
    return (
        <div className={classNames(styles.wrapper, props.className)}>
            <Label text={props.text} isVisible={props.isVisible} />
        </div>
    );
};

export default LabelContainer;
