import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { changeCurrentPage } from '~/Actions/ActionApp';
import { urlsMap } from '~/utils/menu';
import AuctionLayout from '~/Layouts/Auction/AuctionLayout';
import AuctionLotPage from '~/Layouts/AuctionLotPage/AuctionLotPage';
import { getLotByDisplayId, isAuctionTabDisabled } from '~/utils/auction';
import { ErrorBoundary } from '@sentry/react';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { scrollTop } from '~/utils/scrollTop';
import History from '~/utils/history';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS, CATEGORIES } from '~/const';
import useVideoAutoPlay from '~/hooks/useVideoAutoPlay';
import { useParams } from 'react-router-dom';
import useEntryVideo from '~/hooks/useEntryVideo';

interface IAuctionContainerState {
    currentPage: ICurrentPage;
    activeAuctions: IAuction[];
    auctionAccount: IAuctionAccountState;
    isStartedVideo: boolean;
    menu: IMenuMap;
    categoryCurrencies: string[];
    isTrusted: boolean;
    categories: ICategories;
}

interface IAuctionContainerInsideState {
    selectedLot: ILot;
    isNeedToForceRenderLotPage?: boolean;
}

const stateSelector = (state: State): IAuctionContainerState => {
    return {
        currentPage: state.ReducerApp.currentPage,
        activeAuctions: state.ReducerAuction.activeAuctions,
        auctionAccount: state.ReducerAuction.account,
        isStartedVideo: state.ReducerApp.isStartedVideo,
        menu: state.ReducerApp.menu,
        categoryCurrencies: state.ReducerApp.categoryCurrencies,
        isTrusted: state.ReducerApp.isTrusted,
        categories: state.ReducerApp.categories,
    };
};

const initialState: IAuctionContainerInsideState = {
    selectedLot: null,
    isNeedToForceRenderLotPage: false,
};

const AuctionContainer = () => {
    const dispatch = useDispatch();
    const routingParams: { id?: string } = useParams();
    const [auctionState, setState] = React.useState<IAuctionContainerInsideState>(initialState);
    const appState = useSelector<State, IAuctionContainerState>(stateSelector, shallowEqual);
    const currencies = new Set(appState.activeAuctions[0]?.lots.map((lot) => lot.currency));
    const currentCategory = appState.menu[CATEGORIES.AUCTION];

    React.useEffect(() => {
        if (!currentCategory) {
            return History.navigate(urlsMap.home);
        }

        dispatch(
            changeCurrentPage({
                title: currentCategory.title,
                name: currentCategory.name,
                isBundlePage: !!auctionState.selectedLot && !auctionState.isNeedToForceRenderLotPage,
                lotId: auctionState.selectedLot ? auctionState.selectedLot.id : null,
                currencies: [...currencies],
            }),
        );
    }, [auctionState.selectedLot]);

    React.useEffect(() => {
        if (isAuctionTabDisabled()) {
            return History.navigate(urlsMap.home);
        }

        dwhExport.send(DWH_EVENTS.OPEN_CATEGORY, { category: CATEGORIES.AUCTION });

        scrollTop(0, true);
    }, []);

    const promotionVideoUrl = appState.activeAuctions[0]?.promotionVideoUrl;

    const currentAuction = appState.activeAuctions?.filter((auction: IAuction) => {
        return auction.lots.some((lot) => lot.id === auctionState.selectedLot?.id);
    })?.[0];

    useEntryVideo(
        {
            fadeOutVideoInSeconds: 0,
            videoUrlsMap: currentCategory?.entryVideo,
            isAllowedVideoForMobile: false,
            categoryName: CATEGORIES.AUCTION,
            isViewAccountSpecific: false,
        },
        [],
    );

    useVideoAutoPlay(currentAuction?.id, 1.05, appState.activeAuctions?.length > 0 && !!promotionVideoUrl, false, promotionVideoUrl);

    React.useEffect(() => {
        if (appState.activeAuctions?.length === 1) {
            if (appState.activeAuctions[0]?.lots.length === 1) {
                setState({
                    selectedLot: appState.activeAuctions[0].lots[0],
                    isNeedToForceRenderLotPage: true,
                });
                return;
            }
        }

        if (!routingParams.id || !appState.activeAuctions?.length) {
            setState({
                selectedLot: null,
            });
            return;
        }

        const lot = getLotByDisplayId(routingParams.id.toString(), appState.activeAuctions || []);
        if (lot) {
            setState({
                selectedLot: lot,
            });
        }
    }, [routingParams.id, appState.activeAuctions]);

    if (!auctionState.selectedLot) {
        return (
            <ErrorBoundary>
                <AuctionLayout auctions={appState.activeAuctions} auctionAccount={appState.auctionAccount} />
            </ErrorBoundary>
        );
    }

    return (
        <ErrorBoundary>
            <AuctionLotPage lot={auctionState.selectedLot} auction={currentAuction} />
        </ErrorBoundary>
    );
};

export default AuctionContainer;
