import React from 'react';
import classNames from 'classnames';

import styles from './DialogChangeStepButton.scss';
import { DialogChangeStepArrowIcon } from '../DialogChangeStepIcons/DialogChangeStepArrowIcon/DialogChangeStepArrowIcon';

interface DialogChangeStepButtonProps {
    className?: string;
    theme?: string;
    disabled?: boolean;
    flipped?: boolean;
    onClick?: () => void;
}

export function DialogChangeStepButton({ disabled, theme, className, onClick, flipped }: DialogChangeStepButtonProps) {
    const icon = React.useMemo(() => {
        if (theme) {
            switch (theme) {
                case 'everlasting_summer':
                default:
                    return <DialogChangeStepArrowIcon theme={theme} flipped={!!flipped} />;
            }
        }

        return <DialogChangeStepArrowIcon flipped={!!flipped} />;
    }, [flipped, theme]);

    return (
        <button
            className={classNames(styles.wrapper, className, {
                [styles[`wrapper_${theme}`]]: !!theme,
                [styles.wrapper_disabled]: !!disabled,
            })}
            onClick={onClick}
        >
            {icon}
        </button>
    );
}
