import * as React from 'react';
import classNames from 'classnames';
import styles from '../common.scss';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import { getAmountString } from '~/Layouts/BundlePage/helpers';

interface ISlotContent {
    item: IBundleEntity;
    quantity?: number;
}

export const SlotContent = ({ item, quantity }: ISlotContent) => {
    const amount = item.amount * (quantity || 1);
    const text = getAmountString(amount, '<b>', '</b>');

    return (
        <div className={classNames(styles.flex, styles.camouflageAdditionalInfo)}>
            <div className={classNames(styles.preBundleTitle, styles.marginRight)} dangerouslySetInnerHTML={{ __html: text }} />
            <InventoryCounterContainer item={item} className={classNames(styles.preBundleTitle, styles.inventoryWidgetStyle)} size={InventoryCounterSize.FULL} />
        </div>
    );
};
