import { csrf, settings } from '~/utils/settings';
import { post } from '~/utils/ajax';
import { getAnalyticsData } from '~/utils/analytics';
import { checkCSRFToken } from '~/api/account';

export async function purchase(bundleId: string, quantity = 1, coupon: string = null, price: Record<string, number> = {}) {
    const responseCSRFToken = await checkCSRFToken();

    const url = settings.urls.purchase.split('{}').join(bundleId);
    const source = getAnalyticsData();

    const payload = { quantity, coupon, source, price };

    return await post(url, { data: JSON.stringify(payload) }, {}, true);
}

export async function chooseRandomBundle(bundleId: number) {
    const responseCSRFToken = await checkCSRFToken();

    const url = settings.urls.chooseRandomBundle.split('{}').join(bundleId.toString());

    return await post(url, null, {}, true);
}

type PurchaseSerialBundlesBody = {
    id: number;
    price: Record<string, string>;
};
export async function purchaseSerialBundle(bundles: PurchaseSerialBundlesBody[]) {
    const responseCSRFToken = await checkCSRFToken();

    const source = getAnalyticsData();
    const payload = { bundles, source };
    return await post(settings.urls.purchaseSerial, { data: JSON.stringify(payload) }, {}, true);
}
