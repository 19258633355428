import { prepareFeaturing } from '~/utils/featuring';
import { prepareCategoriesForState } from '~/utils/category';
import { prepareCurrenciesForState, ICurrency } from '~/utils/currencies';
import { arrayToObjectByKey, isEmptyObject } from '~/utils/utils';
import { getPrimaryItem, isDisplayRestricted, isParagonBundle, isPeriodicBundle, isRandomBundle } from '~/utils/bundles';
import Account from '~/account/Account';
import { CATEGORIES, BUNDLE_GRID_SIZE } from '~/const';
import { clearString, normalizeString } from '~/utils/normalization';
import VortexDataManager from '~/core/VortexDataStorage';
import { logInfo } from '~/utils/logging';
import { BundlePurchaseTypes, BUNDLE_TYPES } from '~/types/bundle';
import { SectionNames } from '~/core/ManagerData';

export type GlobalArmoryContentForState = {
    categories: ICategories;
    currencies: ICurrency[];
    coupons: ICoupon[];
    featuring: IFeature[];
    notifications: INotification[];
};

export const getArmoryContentFromSettings = ((): ((bundles: Nullable<IBundleList>, vortexCurrencies: Nullable<ICurrency[]>) => Partial<GlobalArmoryContentForState>) => {
    const result: Partial<GlobalArmoryContentForState> = {};

    return function (bundles, vortexCurrencies): Partial<GlobalArmoryContentForState> {
        if (!isEmptyObject(result)) {
            return result;
        }

        const { content } = window.metashop.state;

        Object.keys(content).forEach((key: keyof GlobalArmoryContentForState) => {
            let data;

            switch (key) {
                case 'featuring':
                    if (!result.categories) {
                        result.categories = prepareCategoriesForState(bundles);
                    }
                    data = prepareFeaturing(bundles, result.categories);
                    break;

                case 'categories':
                    if (!result.categories) {
                        data = prepareCategoriesForState(bundles);
                    }
                    break;

                case 'currencies':
                    let currencies = vortexCurrencies;
                    if (!currencies) {
                        currencies = VortexDataManager.getDataBySection(SectionNames.currency) as ICurrency[];
                    }
                    if (currencies) {
                        data = prepareCurrenciesForState(currencies);
                    }
                    break;

                default:
                    data = content[key] as any;
            }

            result[key] = data;
        });

        return result;
    };
})();

const prepareAutoDescriptionSettings = (bundle: IRandomBundleChild | IBundle) => {
    if (bundle?.autoDescriptionSettings?.lootboxSettings) {
        bundle.autoDescriptionSettings.lootboxSettings = arrayToObjectByKey(bundle.autoDescriptionSettings.lootboxSettings as ILootboxAutoDescriptionSettings[], 'id');
    }
};

const prepareProgressiveDiscount = (bundle: IBundle) => {
    if (!bundle.progressivePurchase) {
        return;
    }

    if (!bundle.progressivePurchase.partialPurchase) {
        bundle.progressivePurchase.partialPurchase = { isEnabled: true };
    } else {
        if (bundle.progressivePurchase.partialPurchase.isEnabled) {
            bundle.progressivePurchase.partialPurchase.isEnabled = !isDisplayRestricted(bundle.progressivePurchase.partialPurchase);
        }
    }

    if (!bundle.progressivePurchase.wholeBundlePurchase) {
        bundle.progressivePurchase.wholeBundlePurchase = { isEnabled: true };
    } else {
        if (bundle.progressivePurchase.wholeBundlePurchase.isEnabled) {
            bundle.progressivePurchase.wholeBundlePurchase.isEnabled = !isDisplayRestricted(bundle.progressivePurchase.wholeBundlePurchase);
        }
    }
};

export const prepareArmoryBundles = ((): ((bundleList: IBundleList, categories: ICategories) => IBundleList) => {
    const result: IBundleList = {};

    return function (bundleList: IBundleList, categories: ICategories): IBundleList {
        if (!isEmptyObject(result)) {
            return result;
        }

        const bundles: IBundle[] = Object.values(bundleList);
        const hiddenBundles: number[] = [];
        const disabledBundles: number[] = [];

        bundles.forEach((bundle) => {
            if (isDisplayRestricted(bundle)) {
                if (bundle.serialSequence?.length) {
                    disabledBundles.push(...bundle.serialSequence);
                }
                return;
            }

            if (disabledBundles.includes(bundle.id)) {
                return;
            }

            const _isParagon = isParagonBundle(bundle);
            if (_isParagon && !Account.isParagonsEnabled()) {
                return;
            }

            if (bundle.productCode) {
                bundle.isLoading = true;
                bundle.price = null;
                bundle.entitlements = [];
                bundle.purchaseType = BundlePurchaseTypes.REAL_CURRENCY;
            }

            const _isPeriodicBundle = isPeriodicBundle(bundle);

            bundle.isRandom = isRandomBundle(bundle);

            if (bundle.isRandom && !bundle.randomBundleChildren?.length) {
                return;
            }

            const category: string[] = Object.values(categories)
                .filter((category: ICategory) => !isDisplayRestricted(category) && category.bundles?.includes(bundle.id))
                .map((category) => category.name);

            bundle.categories = category;

            bundle.normalizedTitle = clearString(normalizeString(bundle.title));
            bundle.primaryItem = getPrimaryItem(bundle);

            if (!bundle.sizeInGrid) {
                if (bundle.categories.includes(CATEGORIES.SHIPS)) {
                    bundle.sizeInGrid = BUNDLE_GRID_SIZE.ONE_THIRD as SIZE_IN_GRID;
                } else {
                    bundle.sizeInGrid = BUNDLE_GRID_SIZE.ONE_FOUR as SIZE_IN_GRID;
                }
            }

            if (!isEmptyObject(bundle.autoDescriptionSettings)) {
                prepareAutoDescriptionSettings(bundle);
            }

            if (bundle.isRandom && !_isPeriodicBundle) {
                prepareProgressiveDiscount(bundle);
            }

            bundle.randomBundleChildren?.forEach((randomBundleChild) => {
                prepareAutoDescriptionSettings(randomBundleChild);
            });

            if (bundle.serialSequence?.length) {
                bundle.type = BUNDLE_TYPES.serial;
                hiddenBundles.push(...bundle.serialSequence);
                const isCrookedSerialSequence = bundle.serialSequence.some((serialBundleId) => {
                    if (isParagonBundle(bundleList[serialBundleId]) && !Account.isParagonsEnabled()) {
                        return true;
                    }
                    return !bundleList[serialBundleId] || isDisplayRestricted(bundleList[serialBundleId]);
                });
                if (isCrookedSerialSequence) {
                    logInfo(`Serial bundle ${bundle.id} was hidden because had a crook sequence`);
                    return;
                }
            }

            result[bundle.id] = bundle;
        });

        hiddenBundles.forEach((bundleId: number) => {
            if (result[bundleId]) {
                result[bundleId].isHidden = true;
            }
        });

        return result;
    };
})();
