import styles from './ValuableRewardsLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import Info from '~/components/Info/Info';
import { getFeaturedRewardsByIndex, getSortOrderRewardsBySlotAndGroup } from '~/Layouts/Lootboxes/utils';
import { getStringForFullItemsListByGroup, getTextsTooltipAboutGroup } from '~/Layouts/Lootboxes/utils/texts';
import LootboxRewardItemCard, { RewardItemPlaces } from '~/components/LootboxRewardItemCard/LootboxRewardItemCard';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { IChangeVisiblePopup, ICurrentPage } from '~/Actions/ActionAppType';
import { State } from '~/Reducers';
import { playButtonClickSound } from '~/api/WoWsClient';
import equal from 'fast-deep-equal/react';
import LootboxRerollIcon from '~/components/LootboxRerollIcon/LootboxRerollIcon';
import useMatchMedia from '~/hooks/useMatchMedia';
import { useState } from 'react';
import { items } from '@wg/wows-entities/const';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';

interface IValuableRewardsLayout {
    rewards: ILootboxStateItemGroupRewards<ILootboxRewardGroup>;
    slotIndex: number;
    className?: string;
    lootbox: ILootboxStateItem;
    continuousRewardsTooltipText?: string[];
    autoDescriptionSettings: ILootboxAutoDescriptionSettings;
    rewardClassName?: string;
    isShort?: boolean;
    theme?: ALLOWED_THEMES;
}

interface IStateSelector {
    inventory: InventoryState;
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        inventory: state.ReducerAccount.inventory,
        currentPage: state.ReducerApp.currentPage,
    };
};

const ValuableRewardsLayout = ({ rewards, slotIndex, className, lootbox, continuousRewardsTooltipText, autoDescriptionSettings, rewardClassName, isShort, theme }: IValuableRewardsLayout) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isShortLayout, setIsShortLayout] = useState(isShort);
    const groups = Object.keys(rewards).sort();
    let isNeedToShowLinkOpenPopup = true;
    const isNeedToRenderBranch = groups.length > 1;

    useMatchMedia('(max-width: 1770px)', (event) => {
        if (!isShort && isShortLayout !== event.matches) setIsShortLayout(event.matches);
    });

    const openPopup = (groupName?: string) => {
        playButtonClickSound();
        dispatch(
            changeVisiblePopup(POPUPS_NAME.AUTO_DESCRIPTION_POPUP, true, {
                groupName,
                containerId: lootbox.id,
                slotIndex,
            }),
        );
    };

    const classesContent = classNames(
        styles.content,
        {
            [styles.withBranch]: isNeedToRenderBranch,
            [styles.short]: isShortLayout,
        },
        className,
    );

    const showTooltipPopup = (contents: string[]) => {
        if (!isMobileOrTabletDeviceByAgent()) {
            return;
        }
        dispatch<IChangeVisiblePopup>(
            changeVisiblePopup(POPUPS_NAME.MOBILE_TOOLTIP_POPUP, true, {
                contents,
            }),
        );
    };

    const continuousRewards = lootbox.slots[slotIndex].continuousRewards;

    return (
        <div className={classesContent}>
            {groups.map((groupKey: string, index) => {
                const itemsWeakLimit = 4;
                const itemsStrongLimit = isShortLayout ? 4 : 6;
                const group = rewards[groupKey];
                const promotionRewards = getSortOrderRewardsBySlotAndGroup(autoDescriptionSettings?.promotionRewardsInsideSlots, slotIndex + 1, index + 1);
                const previewItems = getFeaturedRewardsByIndex(group.rewards, promotionRewards, itemsWeakLimit, itemsStrongLimit);
                isNeedToShowLinkOpenPopup = previewItems.length !== group.rewards.length;

                const footerLinkText = getStringForFullItemsListByGroup(group.commonRewardType, group.rewards.length);
                const copiedTextsTooltipAboutGroup = getTextsTooltipAboutGroup();

                const classesGroup = classNames(styles.group, {
                    [styles.lastGroup]: index === groups.length - 1,
                    [styles.prevLastGroup]: index === groups.length - 2,
                    [styles.fullList]: !isNeedToShowLinkOpenPopup,
                });

                let showRerollTooltip = group.hasUniqueRewards || group.rerollNonUniqueCrews;
                if (!group.rerollNonUniqueCrews && group.hasUniqueRewards && previewItems.length === 1) {
                    showRerollTooltip = false;
                }

                return (
                    <div className={classesGroup} key={`group_${groupKey}_${index}`}>
                        {isNeedToRenderBranch && <div className={styles.rewardsBranch}>{!index && <div className={styles.rewardsBranchStartPoint} />}</div>}
                        <div className={styles.groupHeader}>
                            <div className={styles.groupTitle}>
                                {group.title}
                                <span className={styles.groupTitleLabels}>
                                    &ensp;
                                    <div className={styles.groupLabelsWrapper}>
                                        {showRerollTooltip && (
                                            <span className={styles.groupRerollLabel}>
                                                <LootboxRerollIcon enable={group.rerollNonUniqueCrews} groupName={group.title} />
                                            </span>
                                        )}
                                        <span className={styles.groupChanceLabel}>
                                            <ChanceLabel chance={group.probabilityDisplayed} isContinuousRewards={continuousRewards} tooltipContent={continuousRewardsTooltipText} />
                                        </span>
                                        {previewItems.length > 1 && (
                                            <span
                                                className={styles.groupInfoLabel}
                                                onClick={() => {
                                                    showTooltipPopup(copiedTextsTooltipAboutGroup);
                                                }}
                                            >
                                                <Info
                                                    tooltip={{
                                                        text: copiedTextsTooltipAboutGroup,
                                                    }}
                                                    className={styles.groupInfoLabelInfo}
                                                />
                                            </span>
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className={styles.items}>
                            {previewItems.map((item, index) => {
                                const isLootboxReward = item.type === items.LOOTBOX;
                                return (
                                    <LootboxRewardItemCard
                                        key={`group_${groupKey}_${item.id}_${index}`}
                                        inventory={state.inventory}
                                        className={classNames(styles.rewardItem, rewardClassName, {
                                            [styles.lootboxReward]: isLootboxReward,
                                        })}
                                        reward={item}
                                        place={RewardItemPlaces.LIST}
                                        isUniqueGroup={group.hasUniqueRewards}
                                        bundleId={+state.currentPage?.bundleId}
                                        lootboxId={lootbox.id}
                                        isValuableLootboxReward={true}
                                        theme={theme}
                                    />
                                );
                            })}
                        </div>
                        {isNeedToShowLinkOpenPopup && (
                            <div className={styles.groupFooter}>
                                <span
                                    className={styles.openPopupLink}
                                    onClick={() => {
                                        openPopup(groupKey);
                                    }}
                                >
                                    {footerLinkText}
                                </span>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ValuableRewardsLayout;
