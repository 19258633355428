const _account_mock_state: any = {
    account: {
        placedBids: [],
        activeBids: [],
        transactions: [],
        isDeveloper: false,
    },
};

let _preloaded_state: any = null;
if (process.env.NODE_ENV !== 'production') {
    _preloaded_state = {
        activeAuctions: [
            {
                id: 'auction_1',
                status: 'active',
                title: 'Аукцион для разработчиков',
                description: null,
                promotionTitle: '',
                promotionDescription: '',
                promotionStageImageUrl: '',
                promotionStartedAt: '2021-03-09T08:44:06Z',
                startedAt: '2021-03-09T08:44:06Z',
                finishedAt: new Date().getTime() + 86400000,
                promotionFinishedAt: '2021-12-07T08:44:06Z',
                realm: 'global',
                kind: 'first_price',
                bidCoolDown: 60.0,
                bidCancellationsAllowed: false,
                bidRaisingAllowed: true,
                bidDropAllowed: true,
                lots: [
                    {
                        id: 'auction_1_lot_1',
                        auctionId: 'auction_1',
                        title: 'Постоянный камуфляж: Стальной',
                        description:
                            'Постоянный камуфляж «Стальной» можно установить на корабли X уровня (кроме ARP Yamato). После установки на корабль он приобретает свойства обычного постоянного камуфляжа и закрепляется за этим кораблём.',
                        previewImageUrl: '',
                        gridImageUrl: '',
                        purchasePreviewImageUrl: '',
                        kind: 'currency',
                        promoType: 'default',
                        winnersCount: 100,
                        maxBidAmount: 100_000_000,
                        minimalBid: {
                            currency: 'credits',
                            amount: 100,
                        },
                        hintBid: null,
                        itemsToWithdrawInvoice: [
                            {
                                amount: 20000,
                                asset: 'coal',
                            },
                        ],
                    },
                    {
                        id: 'auction_1_lot_2',
                        auctionId: 'auction_1',
                        title: 'Гора итальянских жетонов',
                        description:
                            'Итальянские жетоны — временный ресурс события «Линкоры Италии». До конца обновления 0.10.2 итальянские жетоны можно обменять на различное игровое имущество, включая корабли раннего доступа Dante Alighieri, Conte di Cavour, Andrea Doria, Vittorio Veneto и постоянные камуфляжи «Легион» и «Римский».',
                        previewImageUrl: '',
                        gridImageUrl: '',
                        purchasePreviewImageUrl: '',
                        kind: 'signal',
                        promoType: 'landing',
                        winnersCount: 1000,
                        maxBidAmount: 2_000_000,
                        minimalBid: {
                            currency: 'gold',
                            amount: 100,
                        },
                        gameRewards: [
                            {
                                id: 3760175088,
                                type: 'ship',
                                amount: 1,
                            },
                            {
                                amount: 1,
                                customisation: { ship_id: 4188977104, points: 6 },
                                id: 3867322320,
                                type: 'crew',
                            },
                        ],
                        hintBid: null,
                        itemsToWithdrawInvoice: [
                            {
                                item_id: 4269633456,
                                amount: 10,
                            },
                        ],
                    },
                    {
                        id: 'auction_1_lot_3',
                        auctionId: 'auction_1',
                        title: 'Horace Hood',
                        description:
                            'Сэр Хорас Худ (1870–1916) — контр-адмирал Королевского флота (1913). Происходил из знатного рода, давшего Британской империи нескольких адмиралов. Молодым офицером отличился в целом ряде военных кампаний в Африке. В годы Первой мировой во главе Дуврского патруля боролся с немецкими подводными лодками, затем командовал эскадрой линейных крейсеров. Погиб в ходе Ютландского сражения.',
                        previewImageUrl: '',
                        gridImageUrl: '',
                        purchasePreviewImageUrl: '',
                        kind: 'crew',
                        promoType: 'default',
                        winnersCount: 100,
                        maxBidAmount: 2_000_000,
                        minimalBid: {
                            currency: 'gold',
                            amount: 100,
                        },
                        hintBid: null,
                        itemsToWithdrawInvoice: [
                            {
                                crew_id: 4293043920,
                            },
                        ],
                    },
                ],
                isGlobal: true,
            },
        ],
        account: _account_mock_state.account,
    };
}

export const preloaded_state = _preloaded_state;
