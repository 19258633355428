import gql from 'graphql-tag';

const entityIcons = gql`
    query EntityIcons {
        icons {
            camoboost {
                small {
                    rare
                    special
                    standard
                    unique
                }
            }
            flag {
                small {
                    rare
                    special
                    standard
                    unique
                }
            }
            ribbon {
                small {
                    unique
                    rare
                    special
                    standard
                    legendary
                }
            }
        }
    }
`;

export default entityIcons;
