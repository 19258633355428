import * as React from 'react';
import equal from 'fast-deep-equal/react';
import classNames from 'classnames';
import styles from './BidInput.scss';
import { getCurrency } from '~/utils/currencies';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { arrayToObjectByKey, formatNumber, isMobileOrTabletWindow, redirectToLogin, toInt } from '~/utils/utils';
import { t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { isEnoughCurrency } from '~/utils/auction';
import { setBidLot } from '~/Actions/ActionAuction';
import dwhExport from '~/api/dwhExport';
import { CURRENCY, DWH_EVENTS } from '~/const';
import { NumberFormatBase, type OnValueChange } from 'react-number-format';
import Currency from '~/components/Currency/Currency';
import useForceUpdate from '~/hooks/useForceUpdate';
import { playButtonClickSound, playInputSound } from '~/api/WoWsClient';

interface IProps {
    activeBid: number;
    currency: string;
    bid: number;
    lotId?: string;
    minLotBid: number;
    changeBid?: (bet: number) => void;
    onInput?: (bid: number) => void;
    bidCancellationsAllowed?: boolean;
    bidDropAllowed?: boolean;
    bidRaisingAllowed?: boolean;
    inputClassName?: string;
    maxBidAmount: number;
    hasTransaction?: boolean;
    setRef?: (input: HTMLInputElement) => void;
    currencyBalance: number;
}

const PERCENT_STEP_CHANGE_BID = 10;

const getStepToChangeBid = (amount: number): number => {
    if (amount < 10) {
        return 1;
    }

    return Math.floor((amount * PERCENT_STEP_CHANGE_BID) / 100);
};

const getStepToChangeBidWithPercent = (amount: number, percent: number): number => {
    return Math.floor((amount * percent) / 100);
};

interface IBidInputState {
    accountId: number;
    balance: IBalance;
    activeBids: {
        [key: string]: number;
    };
}

const stateSelector = (state: State): IBidInputState => {
    return {
        accountId: state.ReducerAccount.id,
        balance: state.ReducerAccount.balance,
        activeBids: state.ReducerAuction.activeBids,
    };
};

const BidInput = React.forwardRef((props: IProps, ref: React.RefObject<HTMLInputElement>) => {
    const boostFactor = React.useRef(null);
    const reductionFactor = React.useRef(null);

    const mouseDropTimeout = React.useRef(null);
    const mouseDropInterval = React.useRef(null);
    const mouseRaiseTimeout = React.useRef(null);
    const mouseRaiseInterval = React.useRef(null);
    const forceUpdate = useForceUpdate();

    const [isVisibleLimitMessage, setVisibleLimitMessage] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const accountState = useSelector<State, IBidInputState>(stateSelector, equal);

    const defaultBid = props.bid || props.minLotBid;
    const [inputBid, setBid] = React.useState<number>(defaultBid);
    const currencyConfig = getCurrency(props.currency);

    const [maxAvailableCurrency, setMaxAvailableCurrency] = React.useState<number>(props.currencyBalance + (props.activeBid || 0));

    const currencyIcon = {
        backgroundImage: `url(${currencyConfig.icons.default})`,
    };

    const _setBid = (bid: number) => {
        if (!props.hasTransaction) {
            dispatch(setBidLot(props.lotId, bid));
        }
    };

    const clearMouseDrop = () => {
        clearTimeout(mouseDropTimeout.current);
        clearInterval(mouseDropInterval.current);
    };

    const clearMouseRaise = () => {
        clearTimeout(mouseRaiseTimeout.current);
        clearInterval(mouseRaiseInterval.current);
    };

    const clearAllIntervals = () => {
        boostFactor.current = 0;
        reductionFactor.current = 0;
        clearMouseDrop();
        clearMouseRaise();
    };

    const isLimit = (num: number) => !!props.maxBidAmount && num > props.maxBidAmount;
    const isShownLimit = React.useRef<boolean>(false);

    const onInput: OnValueChange = (values) => {
        setVisibleLimitMessage(false);

        let value = toInt(values.value);

        if (isShownLimit.current && value < props.maxBidAmount) {
            isShownLimit.current = false;
        }

        if (isLimit(value)) {
            setVisibleLimitMessage(true);
            isShownLimit.current = true;
            value = props.maxBidAmount;
        }

        if (value > maxAvailableCurrency) {
            value = maxAvailableCurrency;
        }
        props.onInput?.(value);

        _setBid(value);
        setBid(value);
        forceUpdate();
    };

    const getValidValue = (value: number): number => {
        isShownLimit.current = false;
        setVisibleLimitMessage(false);

        if (!value || value < 0) {
            value = props.bid || props.minLotBid;
            clearAllIntervals();
        }

        if (isLimit(value)) {
            isShownLimit.current = true;
            setVisibleLimitMessage(true);
            value = props.maxBidAmount;
            clearAllIntervals();
        }

        if (value > maxAvailableCurrency) {
            value = maxAvailableCurrency;
            clearAllIntervals();
        }

        if (value < props.minLotBid) {
            value = props.minLotBid;
            clearAllIntervals();
        }

        // ставку можно только понижать
        if (props.bid && !props.bidRaisingAllowed && props.bidDropAllowed && value > props.bid) {
            value = props.bid;
        }

        // ставку можно только повышать
        if (props.bid && props.bidRaisingAllowed && !props.bidDropAllowed && value < props.bid) {
            value = props.bid;
        }

        return value;
    };

    const onBlur = (event?: React.FocusEvent<HTMLInputElement>) => {
        dwhExport.send(DWH_EVENTS.BID_INPUT, { lot_id: props.lotId });
    };

    const canChangeBid = props.bidRaisingAllowed || props.bidDropAllowed;

    const inputClasses = classNames(
        styles.betInput,
        styles[props.currency],
        {
            [styles.canChangeBid]: canChangeBid,
        },
        props.inputClassName,
    );

    const _isEnoughCurrency = !!accountState.accountId && isEnoughCurrency(props.currency, props.minLotBid, accountState.balance);
    const isDisabledRaisingBid = (props.bid && props.bid <= inputBid && !props.bidRaisingAllowed) || inputBid >= maxAvailableCurrency || (!props.activeBid && !_isEnoughCurrency);
    const isDisabledDropBid = (props.bid && props.bid >= inputBid && !props.bidDropAllowed) || inputBid <= props.minLotBid || (!props.activeBid && !_isEnoughCurrency);
    const isDisabledInput = (props.bid && !canChangeBid) || (!props.activeBid && !_isEnoughCurrency) || !accountState.accountId;

    const raisingBid = (amount?: number, k: number = null) => {
        if (isDisabledRaisingBid) {
            return;
        }

        setBid((value) => {
            const newBid = getValidValue(value + (k || getStepToChangeBid(amount || props.minLotBid)));
            props.changeBid?.(newBid);
            return newBid;
        });
    };

    const dropBid = (amount?: number, k: number = null) => {
        if (isDisabledDropBid) {
            return;
        }

        setBid((value) => {
            const _k = k || getStepToChangeBid(amount || props.minLotBid);
            let val = value - _k;
            if (val < 0) {
                val = value - getStepToChangeBid(props.minLotBid);
            }
            const newBid = getValidValue(val);
            props.changeBid?.(newBid);
            return newBid;
        });
    };

    const onWheel = (event: React.WheelEvent) => {
        playInputSound();

        if (event.deltaY > 0) {
            dropBid(inputBid);
        } else {
            raisingBid(inputBid);
        }
    };

    const onClick = () => {
        if (!accountState.accountId) {
            return redirectToLogin();
        }
    };

    const holdUp = () => {
        clearAllIntervals();
        raisingBid();
        mouseRaiseTimeout.current = setTimeout(() => {
            let k = 0;
            let count = 0;
            let interval = 50;
            mouseRaiseInterval.current = setInterval(() => {
                k += 10;
                count += 1;
                const step = getStepToChangeBidWithPercent(props.minLotBid, k);
                raisingBid(null, step);
                if (count % 4 === 0) {
                    interval = Math.max(interval - 2.5, 10);
                }
                boostFactor.current = k;
            }, interval);
        }, 250);
    };

    const holdDown = () => {
        clearAllIntervals();
        dropBid();
        mouseDropTimeout.current = setTimeout(() => {
            let k = 0;
            mouseDropInterval.current = setInterval(() => {
                k += 2.5;
                const step = getStepToChangeBidWithPercent(props.minLotBid, k);
                reductionFactor.current = k;
                dropBid(null, step);
            }, 50);
        }, 250);
    };

    React.useEffect(() => {
        const _bid = accountState.activeBids?.[props.lotId];
        if (_bid) {
            setBid(_bid);
        }
    }, [accountState.activeBids?.[props.lotId]]);

    React.useEffect(() => {
        setMaxAvailableCurrency(props.currencyBalance + (props.activeBid || 0));
    }, [props.activeBid, props.currencyBalance]);

    const classesLimitBid = classNames(styles.limitBid, {
        [styles.visible]: isShownLimit.current || isLimit(inputBid),
    });

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.betInputWrapper, {
                    [styles.disabled]: props.hasTransaction,
                })}
                onClick={onClick}
            >
                <DivTooltip tooltipBody={isDisabledInput ? <DefaultTooltip text={t('Ставку изменить нельзя')} /> : null}>
                    <NumberFormatBase
                        getInputRef={(_ref: HTMLInputElement) => {
                            if (_ref && props.setRef) {
                                props.setRef(_ref);
                            }
                        }}
                        value={inputBid}
                        className={inputClasses}
                        onValueChange={onInput}
                        onWheel={onWheel}
                        onBlur={onBlur}
                        disabled={isDisabledInput}
                        autoComplete={'off'}
                        spellCheck={false}
                        autoFocus={!isMobileOrTabletWindow && !props.hasTransaction}
                        format={(value) => {
                            return formatNumber(getValidValue(+value));
                        }}
                        // @ts-ignore
                        noimesupport={'true'}
                        onKeyDown={(event) => {
                            if (event.key !== 'Unidentified') {
                                playInputSound();
                            }
                        }}
                    />
                </DivTooltip>
                {canChangeBid && (
                    <div className={styles.controls}>
                        <DivTooltip tooltipBody={<DefaultTooltip text={isDisabledRaisingBid ? t('Ставку повысить нельзя') : t('Повысить ставку')} />}>
                            <div
                                onMouseDown={() => holdUp()}
                                onTouchStart={(event) => {
                                    if (!isMobileOrTabletWindow) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        holdUp();
                                    }
                                }}
                                onTouchCancel={clearMouseRaise}
                                onTouchEnd={clearMouseRaise}
                                onMouseUp={clearMouseRaise}
                                onMouseLeave={clearMouseRaise}
                                className={classNames(styles.controlUp, {
                                    [styles.disabled]: isDisabledRaisingBid || isShownLimit.current || isLimit(inputBid),
                                })}
                                onClick={() => {
                                    if (!isDisabledRaisingBid) {
                                        playButtonClickSound();
                                        const meta = {
                                            boost_factor: boostFactor.current || PERCENT_STEP_CHANGE_BID,
                                            minimal_bid: props.minLotBid,
                                            lot_id: props.lotId,
                                        };
                                        dwhExport.send(DWH_EVENTS.BID_RAISING, meta);
                                    }
                                }}
                            />
                        </DivTooltip>
                        <DivTooltip tooltipBody={<DefaultTooltip text={isDisabledDropBid ? t('Ставку понизить нельзя') : t('Понизить ставку')} />}>
                            <div
                                onTouchStart={(event) => {
                                    if (!isMobileOrTabletWindow) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        holdDown();
                                    }
                                }}
                                onTouchCancel={clearMouseDrop}
                                onTouchEnd={clearMouseDrop}
                                onMouseDown={() => holdDown()}
                                onMouseUp={clearMouseDrop}
                                onMouseLeave={clearMouseDrop}
                                onClick={() => {
                                    if (!isDisabledRaisingBid) {
                                        playButtonClickSound();
                                        const meta = {
                                            reduction_factor: reductionFactor.current || PERCENT_STEP_CHANGE_BID,
                                            minimal_bid: props.minLotBid,
                                            lot_id: props.lotId,
                                        };
                                        dwhExport.send(DWH_EVENTS.BID_DROP, meta);
                                    }
                                }}
                                className={classNames(styles.controlDown, {
                                    [styles.disabled]: isDisabledDropBid,
                                })}
                            />
                        </DivTooltip>
                    </div>
                )}
                <Currency className={styles.currencyIcon} currency={props.currency} withoutAnimation showDescriptionTooltip={getCurrency(props.currency)?.showDescriptionInPriceTooltip} />
            </div>
            <div className={classesLimitBid}>{t('Размер ставки больше не может быть увеличен.')}</div>
        </div>
    );
});

export default BidInput;
