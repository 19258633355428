import * as React from 'react';
import CrewVoiceButton from '~/components/CrewVoiceButton/CrewVoiceButton';
import { SectionNames } from '~/core/ManagerData';
import useLoadPrimaryItemData from '~/hooks/useLoadPrimaryItemData';
import { Crew } from '~/vortex';

interface ICrewVoiceButtonContainer {
    className?: string;
    primaryItem: IBundleEntity;
}

const CrewVoiceButtonContainer = ({ className, primaryItem }: ICrewVoiceButtonContainer) => {
    const hasSampleVo = useLoadPrimaryItemData<Crew>(primaryItem?.type, primaryItem?.identifier, SectionNames.crews)?.hasSampleVo;

    if (!hasSampleVo) {
        return null;
    }

    return (
        <div className={className}>
            <CrewVoiceButton crewId={primaryItem?.identifier} />
        </div>
    );
};

export default CrewVoiceButtonContainer;
