import classNames from 'classnames';
import * as React from 'react';
import styles from './InfoBlock.scss';

interface IInfoBlock {
    title: string;
    description: string;
    iconUrl: string;
    className?: string;
}

const InfoBlock = ({ title, description, iconUrl, className }: IInfoBlock) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.icon} style={{ backgroundImage: `url(${iconUrl})` }}></div>
            <div className={styles.info}>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
            </div>
        </div>
    );
};

export default InfoBlock;
