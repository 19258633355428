export enum BANNER_LINK_TYPE {
    CATEGORY = 'category',
    BUNDLE = 'bundle',
    LINK = 'link',
    IFRAME = 'iframe',
}

export enum IBannerButtonVisibility {
    HOVER = 'hover',
    HIDDEN = 'hidden',
    SHOWN = 'shown',
}

export enum BannerBarWidth {
    HALF = '1/2',
    FULL = '1',
}

export enum FEATURING_DESTINATION {
    MAIN = 'main',
    CATEGORY = 'category',
}

export enum FEATURING_PLACEHOLDER {
    MAIN = 'main',
    BOTTOM = 'bottom',
}

declare global {
    interface IContentBlockBundle {
        id: number;
        image: string;
        width: string;
    }

    type BANNERS = IBanner | IBannerBar | IAccordionBanner;
    type BANNER_HEIGHT = '1' | '1/2';

    interface IDefaultBanner {
        title: string;
        description: string;
        image: string;
        mobileImage: string;
        videoHover: IVideo;
        previewCardVideo?: Nullable<IVideo>;

        link: string;
        categoryName: string;
        linkValue: string;
        linkType: BANNER_LINK_TYPE;
    }

    interface IBanner extends IDefaultBanner {
        secondaryLink: string;
        buttonPrimaryTitle: string;
        buttonSecondaryTitle: string;
        buttonsVisibility: IBannerButtonVisibility;
    }

    interface IBannerBar extends IDefaultBanner {
        width: BannerBarWidth;
        buttonVisibility: IBannerButtonVisibility;
        buttonTitle: string;
    }

    interface IAccordion {
        banners: IAccordionBanner[];
    }

    interface IAccordionBanner extends IDefaultBanner {
        smallImage: string;
        smallImagePosition: number;
        buttonTitle: string;
        buttonVisibility: IBannerButtonVisibility;
        group: string;
        height?: Nullable<BANNER_HEIGHT>;
    }

    interface IFeaturedTimer {
        title: string;
        description: string;
        color?: string;
        hideFromMenu?: boolean;
    }

    type BANNER_TYPES = IBannerBar | IBanner | IAccordionBanner;

    interface IContentBlockData {
        title: string;
        categoryName?: ICategoryList;
        link?: string;
        linkValue?: string;
        bundlesCount: number;
        bundles: IContentBlockBundle[];
    }

    interface IContentBlock extends IDisplayRestrictedOptions {
        data: IContentBlockData | IBanner | IBannerBar | IAccordionBanner | IFeaturedTimer;
        id?: number;
        type: string;
        activeFrom?: string;
        activeTill?: string;
    }

    interface IFeature {
        placeholder: string;
        destination: FEATURING_DESTINATION;
        destinationId?: string;
        contentBlocks: IContentBlock[];
    }

    interface IInfoScreen {
        background: string;
        description: string;
        name: string;
        title: string;
        validTill: string;
        redirect_to_category: string;
    }
}

export default global;
