import * as React from 'react';
import classNames from 'classnames';
import styles from './SantaBundleBackground.scss';
import { isMobileOrTabletWindow } from '~/utils/utils';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { SANTA_SIZE, VIDEO_BUNDLE_PREVIEW } from '~/Layouts/Themes/SantaPage/settings';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';

interface ISantaBundleBackground {
    bundle: IBundle;
}

interface IStateSelector {
    isTrusted: boolean;
    categories: ICategories;
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        isTrusted: state.ReducerApp.isTrusted,
        categories: state.ReducerApp.categories,
        currentPage: state.ReducerApp.currentPage,
    };
};

const SantaBundleBackground = ({ bundle }: ISantaBundleBackground) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const isAvailableSoundEffect = useAvailableSound(state.categories[state.currentPage?.name], bundle);
    const isDisabledAnimation = useIsDisabledAnimation(state.currentPage?.name);

    let muted = !isAvailableSoundEffect;
    if (!state.isTrusted) {
        muted = true;
    }
    const video = bundle.videoBackground;

    const style: React.CSSProperties = {};
    if (bundle.icons.innerBackground) style.backgroundImage = bundle.icons.innerBackground;

    const withVideo = video && !isDisabledAnimation;

    return (
        <div className={styles.wrapper} style={style}>
            <VideoBackground
                key={bundle.id}
                poster={bundle.icons.innerBackground}
                video={withVideo && video}
                className={styles.videoBackgroundWrapper}
                posterClassName={classNames(styles.background)}
                videoClassName={styles.videoBackground}
                muted={muted}
                volume={0.5}
            />
        </div>
    );
};

export default SantaBundleBackground;
