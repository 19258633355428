import History from '~/utils/history';
import { getUrl, urlsMap } from '~/utils/menu';

interface ITradeInParentLayout {
    category: ICategory;
}

const TradeInParentLayout = ({ category }: ITradeInParentLayout): JSX.Element => {
    if (category.subCategories?.length) {
        const url = getUrl(urlsMap.subCategory, {
            categoryName: category.name,
            subCategoryName: category.subCategories[1],
        });

        History.navigate(url, { replace: true });
        return null;
    }
    return null;
};

export default TradeInParentLayout;
