import * as React from 'react';
import classNames from 'classnames';

import { ItemTypes } from '@wg/wows-entities/const';
import { isCurrency } from '~/utils/currencies';
import { getTotalCountPreviewItems, IPortContainItem, PortDataType, PortType, prepareItems } from '~/components/Port/settings';
import { isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { IContainsItem } from '~/components/ItemContains/Contains';

import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { PortContentBlock } from './components/PortContentBlock/PortContentBlock';
import { PortCamoBlock } from './components/PortCamoBlock/PortCamoBlock';
import { PortPurchaseBlock } from './components/PortPurchaseBlock/PortPurchaseBlock';
import { PortSwitchButtonsBlock } from './components/PortSwitchButtonsBlock/PortSwitchButtonsBlock';
import { PortHeaderBlock } from './components/PortHeaderBlock/PortHeaderBlock';

import styles from './Port.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '../Tooltip/DefaultTooltip';

interface IPortBaseProps extends Omit<IPortBase, 'isVisible' | 'isLoading' | 'showTTC'> {
    inventory: InventoryState;

    onClose: () => void;
}

interface IPortBasePropsDefault extends IPortBaseProps {
    portType: PortType.DEFAULT;
}

interface IPortBasePropsPurchase extends IPortBaseProps {
    portType: PortType.PURCHASE;

    balance: IBalance;
    coupons: ICoupon[];

    isDisabledPurchase?: boolean;

    purchaseHandler: () => void;
}

interface PortPropsDefault extends IPortBasePropsDefault {
    dataType: PortDataType.DEFAULT;
}

interface PortPropsBundle extends IPortBasePropsPurchase {
    bundle: IBundle;

    dataType: PortDataType.BUNDLE;
}

interface PortPropsAuctionLot extends IPortBasePropsDefault {
    lot: ILot;

    dataType: PortDataType.AUCTION_LOT;
}

export type PortProps = PortPropsDefault | PortPropsBundle | PortPropsAuctionLot;

interface IPortState {
    shipId: number;
    exteriorId: number;
    containsItems?: IPortContainItem[] | null;
    selectedItemId?: number;
    totalCountPreviewItems?: number;
}

export default class Port extends React.PureComponent<PortProps, IPortState> {
    protected isAvailableContainsWidget = false;
    protected containsRef: { current: HTMLDivElement } = { current: null };

    constructor(props: PortProps) {
        super(props);

        const containsItems = prepareItems(props);

        this.state = {
            shipId: this.props.shipId,
            exteriorId: this.props.exteriorId,
            containsItems,
            totalCountPreviewItems: Array.isArray(containsItems) ? getTotalCountPreviewItems(containsItems) : 1,
        };
    }

    protected formItems(): IContainsItem[] {
        // const itemsAddToEnd: any = [];

        return this.state.containsItems.reduce((result, item, index, arr) => {
            const isSelected = isPermanentCamouflage(this.props.itemType) ? item.id === this.props.exteriorId : item.id === this.props.shipId || item.id === this.props.exteriorId;
            const isShards = item.type.includes(ItemTypes.SHIP_COMPONENTS) || item.type.includes(ItemTypes.SHIP_MATERIALS);
            const isActive = this.state.totalCountPreviewItems > 1 && isSelected;

            const content = item.customContent ? (
                <DivTooltip tooltipBody={<DefaultTooltip text={item.customContent.description || item.customContent.title} />}>
                    <div dangerouslySetInnerHTML={{ __html: item.customContent.title }} />
                </DivTooltip>
            ) : (
                <WoWSEntity
                    type={item.type}
                    id={item.id}
                    amount={!isPermanentCamouflage(item.type) ? item.amount : null}
                    customisation={item.customisation}
                    presentation={{
                        withTooltip: true,
                        iconPosition: 'left',
                        hiddenInlineShip: item.type === ItemTypes.CREWS,
                        hideTooltipAmount: isCurrency(item.type) || isShards,
                        isShards,
                    }}
                />
            );

            result.push({
                isPortPreviewEnabled: this.state.totalCountPreviewItems > 1 ? item.isEnabledPortPreview : false,
                portPreviewOnClick: () => {
                    if (!isSelected) {
                        item.portPreviewOnClick?.();
                    }
                },
                onClick: () => {
                    if (!isSelected) {
                        item.portPreviewOnClick?.();
                    }
                },
                classNames: {
                    portPreviewIcon: classNames({
                        [styles.isActivePortIcon]: isActive,
                    }),
                },
                isBonus: item.isBonus,
                id: item.id?.toString(),
                type: item.type,
                content: content,
            });

            // if (this.props.portPreviewType === PortPreviewType.MRPS) {
            //     const mrpsItem = item as unknown as MrpsLoadedEntitlement;

            //     if (mrpsItem.crew) {
            //         const crewItem = mrpsItem.crew;

            //         itemsAddToEnd.push({
            //             id: (crewItem.crew_id || `${crewItem.type}`)?.toString(),
            //             type: crewItem.type,
            //             isPortPreviewEnabled: false,
            //             isExistsInInventory: true,
            //             content: (
            //                 <WoWSEntity
            //                     type={crewItem.type}
            //                     id={crewItem.crew_id}
            //                     amount={crewItem.amount}
            //                     presentation={{
            //                         withTooltip: true,
            //                         iconPosition: 'left',
            //                     }}
            //                 />
            //             ),
            //         });
            //     }
            // }

            // if (index === arr.length - 1) {
            //     result.push(...itemsAddToEnd);
            // }

            return result;
        }, [] as Array<IContainsItem>);
    }

    render() {
        this.isAvailableContainsWidget = this.state.containsItems?.length > 1;

        return (
            <div className={styles.wrapper}>
                <div className={styles.headerWrapper}>{this.renderHeaderBlock()}</div>
                <div className={styles.contentWrapper}>{this.renderLeftBlock()}</div>
                <div className={styles.bottomWrapper}>{this.renderBottomBlock()}</div>
            </div>
        );
    }

    protected renderHeaderBlock(): React.ReactNode {
        return <PortHeaderBlock {...this.props} />;
    }

    protected renderLeftBlock(): React.ReactNode {
        return (
            <div className={styles.content}>
                {this.renderContentBlock()}
                {this.renderPurchaseBlock()}
            </div>
        );
    }

    protected renderContentBlock(): React.ReactNode {
        return (
            <PortContentBlock
                shipId={this.props.shipId}
                exteriorId={this.props.exteriorId}
                itemType={this.props.itemType}
                items={this.state.containsItems}
                options={{
                    isAvailableContainsWidget: this.isAvailableContainsWidget,
                    containsItems: this.isAvailableContainsWidget ? this.formItems() : undefined,
                }}
            />
        );
    }

    protected renderBottomBlock(): React.ReactNode {
        const needToRenderButtonsFlag = this.state.totalCountPreviewItems > 1;

        return (
            <>
                <PortCamoBlock shipId={this.props.shipId} exteriorId={this.props.exteriorId} items={this.state.containsItems} />
                {needToRenderButtonsFlag ? (
                    <PortSwitchButtonsBlock
                        shipId={this.props.shipId}
                        exteriorId={this.props.exteriorId}
                        itemType={this.props.itemType}
                        items={this.state.containsItems}
                        previewItemCount={this.state.totalCountPreviewItems}
                    />
                ) : null}
            </>
        );
    }

    protected renderPurchaseBlock(): React.ReactNode {
        if (this.props.portType !== PortType.PURCHASE || this.props.isDisabledPurchase) {
            return null;
        }

        return <PortPurchaseBlock bundle={this.props.bundle} balance={this.props.balance} coupons={this.props.coupons} purchaseHandler={this.props.purchaseHandler} />;
    }
}
