import * as React from 'react';
import styles from './DefaultTemplate.scss';
import { WoWSEntities } from '@wg/wows-entities';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import classNames from 'classnames';
import { isChina } from '~/utils/settings';
import { PreviewDefaultRewardsMap } from '~/Layouts/Lootboxes/Views/PreviewLayout/DefaultTemplate/rewards';
import { PreviewRewardCustomTypes } from '~/types/bundle';

interface IPreviewRewardItem {
    reward: ILootboxPreviewSettingsItemReward;
}

interface PreviewRewardItemState {
    isDefaultRewardItem: boolean;
    data?: {
        title: string;
        image?: Nullable<string>;
        isCurrencyItem?: boolean;
    };
}

const PreviewRewardItem = ({ reward }: IPreviewRewardItem) => {
    const defaultRewardSettings = PreviewDefaultRewardsMap[reward.type];
    const isCustomReward = reward.type === PreviewRewardCustomTypes.CUSTOM;
    const isWoWsPremium = reward.type === PreviewRewardCustomTypes.WOWS_PREMIUM;
    const [state, setState] = React.useState<PreviewRewardItemState>({
        isDefaultRewardItem: !!reward.id,
        data: {
            title: isCustomReward ? reward.title : defaultRewardSettings?.title,
            image: reward.image,
            isCurrencyItem: defaultRewardSettings?.isCurrencyItem,
        },
    });

    React.useEffect(() => {
        const loadInfo = async () => {
            const wowsStorage = WoWSEntities.get().getStorage();
            const response = await wowsStorage.loadEntities({ [reward.type]: [reward.id] });
            const key = Object.keys(response)[0];
            const data = response[key][0];
            setState({
                ...state,
                data: {
                    title: state.data.title || data.title,
                    image: state.data.image || data.icons?.default,
                },
            });
        };

        if (reward.id) {
            loadInfo();
        }
    }, []);

    const classesItem = classNames(
        styles.item,
        styles[`reward-${reward.type}`],
        {
            [styles.isChina]: isChina(),
        },
        reward.type,
    );

    const imageStyle = state.data?.image && { backgroundImage: `url(${state.data.image})` };

    return (
        <div className={classesItem}>
            <div className={styles.imageWrapper} style={imageStyle} />
            {state.data?.isCurrencyItem && (
                <div className={styles.rewardCurrencyWrapper}>
                    <WoWSEntity
                        type={reward.type}
                        amount={reward.amount}
                        presentation={{
                            withShortText: isWoWsPremium,
                            withoutAssetIcon: isWoWsPremium,
                        }}
                    />
                </div>
            )}
            <div className={styles.info}>{state.data?.title}</div>
        </div>
    );
};

export default PreviewRewardItem;
