import * as React from 'react';
import styles from './DefaultTemplate.scss';
import PointRight from '~/components/PointRight/PointRight';
import PreviewRewardItem from '~/Layouts/Lootboxes/Views/PreviewLayout/DefaultTemplate/PreviewRewardItem';

interface IDefaultTemplate {
    data: ILootboxPreviewSettingsData;
}

const DefaultTemplate = ({ data }: IDefaultTemplate) => {
    return (
        <div className={styles.content}>
            {data.map((options, index) => {
                return (
                    <div className={styles.slotItem} key={`slot_group_${options.slotIndex}_${index}`}>
                        {data.length > 1 && (
                            <div className={styles.slotIndex}>
                                <PointRight label={options.slotIndex} />
                            </div>
                        )}
                        <div className={styles.slotRewards}>
                            {options.rewards.map((reward, index) => {
                                const isLastReward = index === options.rewards.length - 1;

                                return (
                                    <div className={styles.rewardItem} key={`slot_group_reward_${reward.type}_${index}`}>
                                        <PreviewRewardItem reward={reward} />
                                        {!isLastReward && <div className={styles.delimiter} />}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default DefaultTemplate;
