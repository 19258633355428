import * as React from 'react';
import { State } from '~/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { loadLootbox } from '~/Actions/ActionLootbox';
import { ErrorBoundary } from '@sentry/react';
import CommonLayout from '~/Layouts/Lootboxes/Views/CommonLayout/CommonLayout';
import { ALLOWED_THEMES } from '../Themes/types';

interface ILootboxAutoDescription {
    id: number;
    autoDescriptionSettings: IAutoDescriptionSettings;
    theme?: ALLOWED_THEMES;
}

interface IStateSelector {
    lootboxes: ILootboxes;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        lootboxes: state.ReducerLootbox.lootboxes,
    };
};

const LootboxAutoDescriptionWrapper = ({ id, autoDescriptionSettings, theme }: ILootboxAutoDescription) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const currentLootbox = state.lootboxes[id];

    React.useEffect(() => {
        if (!currentLootbox) {
            // @ts-ignore
            dispatch(loadLootbox(id));
        }
    }, [currentLootbox]);

    if (currentLootbox) {
        return (
            <ErrorBoundary>
                <CommonLayout lootbox={currentLootbox} lootboxAutoDescriptionSettings={autoDescriptionSettings} theme={theme} />
            </ErrorBoundary>
        );
    }

    return null;
};

export default React.memo(LootboxAutoDescriptionWrapper);
