import * as React from 'react';
import classNames from 'classnames';

import { IPopupSoundset } from '~/types/soundsets';
import { playExchangeSound, playButtonDialogClickSound, playButtonClickSound } from '~/api/WoWsClient';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import useClickAway from '~/hooks/useClickAway';
import useDisableMobileBodyScroll from '~/hooks/useDisableMobileBodyScroll';

import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import { ZoomInDiv } from '~/components/Transitions';

import styles from './Popup.scss';

interface IPopup {
    children: React.ReactNode;
    BeforePopupComponent?: React.ReactNode;
    AfterPopupComponent?: React.ReactNode;

    wrapperRef?: React.MutableRefObject<HTMLDivElement>;
    contentRef?: React.MutableRefObject<HTMLDivElement>;

    soundset?: IPopupSoundset;

    renderEscButton?: boolean;
    outsideClick?: boolean;
    withoutZoomIn?: boolean;

    onClose: (event?: React.MouseEvent | React.TouchEvent) => void;

    className?: string;
    popupClassName?: string;
    closeIconClassName?: string;
    style?: React.CSSProperties;
}

const soundsetsMap: Record<IPopupSoundset, (() => void) | null> = {
    [IPopupSoundset.MUTED]: null,
    [IPopupSoundset.DEFAULT]: () => playButtonDialogClickSound(),
    [IPopupSoundset.EXCHANGE]: () => playExchangeSound(),
};

function playSoundset(soundsetName: IPopupSoundset) {
    soundsetsMap[soundsetName || IPopupSoundset.DEFAULT]?.();
}

export function Popup(props: IPopup) {
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);

    function closePopup(event?: React.MouseEvent | React.TouchEvent | any) {
        props.onClose?.(event);
    }

    React.useEffect(() => {
        playSoundset(props.soundset);
    }, []);

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }

        window?.tooltipProvider?.hide?.();

        playButtonClickSound();
        closePopup();
    }, [KEYS_CODE.ESC]);

    useDisableMobileBodyScroll();

    useClickAway(
        props.contentRef || contentRef,
        () => {
            if (isPopupActive && props.outsideClick) {
                closePopup();
            }
        },
        [isPopupActive],
    );

    const Div = React.forwardRef(({ children, className }: { children: any; className?: string }, ref: React.RefObject<HTMLDivElement>) => {
        return (
            <div className={className} ref={ref}>
                {children}
            </div>
        );
    });

    const ContentWrapper = !props.withoutZoomIn ? ZoomInDiv : Div;

    return (
        <div className={classNames(styles.popup, props.popupClassName)} ref={props.wrapperRef || wrapperRef} style={props.style}>
            {props.BeforePopupComponent}
            <ContentWrapper className={classNames(styles.content, props.className)} ref={props.contentRef || contentRef}>
                {props.renderEscButton && (
                    <div className={classNames(styles.close, props.closeIconClassName)}>
                        <ButtonEsc onClick={closePopup} className={styles.closeIconButton} />
                    </div>
                )}
                {props.children}
            </ContentWrapper>
            {props.AfterPopupComponent}
        </div>
    );
}

interface IPopupHeader {
    title: React.ReactNode;
    className?: string;
}

interface IPopupBody {
    children: React.ReactNode;
    className?: string;
}

interface IPopupFooter {
    children: React.ReactNode;
    className?: string;
}

export const PopupHeader = (props: IPopupHeader) => {
    return <div className={classNames(styles.header, props.className)}>{props.title}</div>;
};

export const PopupBody = (props: IPopupBody) => {
    return <div className={classNames(styles.body, props.className)}>{props.children}</div>;
};

export const PopupFooter = (props: IPopupFooter) => {
    return <div className={classNames(styles.footer, props.className)}>{props.children}</div>;
};
