import { settings } from '~/utils/settings';
import { getCookie } from '~/utils/cookie';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';

export const getNextUrl = (authNextUrl?: string) => {
    return (authNextUrl || window.location.pathname) + window.location.search;
};

export const logOut = () => {
    dwhExport.send(DWH_EVENTS.USER_LOGOUT, {}, true);

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = settings.urls.logoutUrl;

    const next = getNextUrl();
    const formFields: any = {
        next: { value: next, type: 'hidden' },
        logout: { value: 'logout', type: 'submit' },
    };

    const cookie = getCookie(settings.csrfTokenCookieName);

    if (cookie) {
        formFields['csrfmiddlewaretoken'] = { value: cookie, type: 'hidden' };
    }

    for (const name in formFields) {
        if (formFields.hasOwnProperty(name)) {
            const field = formFields[name];
            const input = document.createElement('input');
            input.type = field['type'];
            input.name = name;
            input.value = field['value'];
            form.appendChild(input);
        }
    }

    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
};
