import * as React from 'react';
import styles from './ScrollToTopButton.scss';
import RefManager, { RefManagerKeys } from '~/RefManager';
import store from '~/Store';
import { FilterPosition } from '~/types/category';

interface IButtonToTop {
    onClick: () => void;
}

const onScroll = () => {
    const button = RefManager.getRef(RefManagerKeys.ButtonToTop);
    const mainContainerElement = RefManager.getRef(RefManagerKeys.MainContainer);
    const topPanelElement = RefManager.getRef(RefManagerKeys.TopPanel);
    const filtersElement = RefManager.getRef(RefManagerKeys.Filters);
    const { filterPosition } = store.getState().ReducerApp;
    let padding = 300;

    if (filtersElement && filterPosition === FilterPosition.RIGHT) {
        padding = filtersElement?.getBoundingClientRect().top + filtersElement.offsetHeight + 100;
    }

    if (mainContainerElement.scrollTop > padding) {
        button.classList.add(styles.buttonToTopActive);
    } else {
        button.classList.remove(styles.buttonToTopActive);
    }

    if (mainContainerElement.scrollTop > 10) {
        topPanelElement?.classList.add('shadow');
    } else {
        topPanelElement?.classList.remove('shadow');
    }
};

const ScrollToTopButton = (props: IButtonToTop) => {
    const setRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.ButtonToTop, ref);
        }
    };

    React.useEffect(() => {
        const mainContainerElement = RefManager.getRef(RefManagerKeys.MainContainer);
        if (!mainContainerElement) {
            return;
        }
        mainContainerElement.addEventListener('scroll', onScroll);
        return () => {
            mainContainerElement.removeEventListener('scroll', onScroll);
        };
    }, []);

    return <div className={styles.button} onClick={props.onClick} ref={setRef} />;
};

export default ScrollToTopButton;
