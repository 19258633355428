export enum NotificationDisplay {
    NOTIFICATION = 'notification',
    POPUP = 'popup',
    SPECIAL = 'special',
}

export enum NotificationBannerPosition {
    RIGHT = 'right',
    LEFT = 'left',
    CENTER = 'center',
    FULLSCREEN = 'fullscreen',
}

declare global {
    interface INotification extends IDisplayRestrictedOptions {
        id: number;
        name: string;
        title: string;
        description: string;
        background: string;
        previewImage: string;
        validTill: string;
        dateCreated: string;
        display: NotificationDisplay;
        redirectToCategory: string;
        linkHref: string;
        textPosition: string[];
        bannerPosition: string;
        size: 'big' | 'small';
    }
}

export default global;
