import * as React from 'react';
import styles from './Auction.scss';
import { getFinishedStatusSubTitle, getFinishedStatusTitle, isLost, isWon } from '~/utils/auction';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { AUCTION_STATUS } from '~/const';
import { BID_STATUSES } from '~/types/auction';

interface IAuctionStatus {
    bidStatus: BID_STATUSES;
    auctionStatus: AUCTION_STATUSES;
    isBidPlaced: boolean;
    className?: string;
    hiddenChoosingWinnersStatus?: boolean;
    currentBid?: number;
    minimalWonBid?: IAuctionMinimalWonBids;
    shortFinishedStatusTitle?: boolean;
    mergeStatusToOneBlock?: boolean;
}

const AuctionStatus = ({ bidStatus, auctionStatus, className, hiddenChoosingWinnersStatus, currentBid = 0, minimalWonBid = null, shortFinishedStatusTitle, mergeStatusToOneBlock }: IAuctionStatus) => {
    if (isWon(bidStatus) || isLost(bidStatus)) {
        const isLostMinimalWonBidTime = isLost(bidStatus) && minimalWonBid?.bid?.amount === currentBid;
        const classes = classNames(styles.auctionStatus, styles[bidStatus], className, {
            [styles.tooltipBorderTop]: !mergeStatusToOneBlock,
        });

        return (
            <>
                <div className={classes}>
                    <span>{getFinishedStatusTitle(isLostMinimalWonBidTime && shortFinishedStatusTitle ? BID_STATUSES.LOST_MINIMAL_WON_BID_TIME : bidStatus)}</span>
                    <span>{mergeStatusToOneBlock && getFinishedStatusSubTitle(bidStatus)}</span>
                </div>
                {!mergeStatusToOneBlock && <div className={classNames(styles.auctionStatus, styles[bidStatus], className, styles.tooltipBorderTop)}>{getFinishedStatusSubTitle(bidStatus)}</div>}
            </>
        );
    }

    if (!hiddenChoosingWinnersStatus && auctionStatus === AUCTION_STATUS.CHOOSING_WINNERS) {
        return (
            <div className={classNames(styles.auctionStatus, className)}>
                <span>{t('Идёт определение победителей.')}</span>
            </div>
        );
    }

    return null;
};

export default AuctionStatus;
