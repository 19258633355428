import { getCookie } from '~/utils/cookie';
import { settings } from '~/utils/settings';
import { logError } from '~/utils/logging';

interface IOverrideOptions {
    credentials?: 'same-origin' | 'omit' | 'include';
}

type Payload = Record<string, any>;

export async function post(
    url: string,
    payload?: Payload,
    headers?: Headers,
    formData = false,
    parseResponse = true,
    isSameOrigin = true,
    overrideOptions: IOverrideOptions = {},
    withoutCSRFToken = false,
) {
    return await makeRequest(url, 'POST', payload, headers, formData, parseResponse, isSameOrigin, overrideOptions, withoutCSRFToken);
}

export async function put(url: string, payload?: Payload, headers?: Headers, formData = false, parseResponse = true, isSameOrigin = true, overrideOptions: IOverrideOptions = {}) {
    return await makeRequest(url, 'PUT', payload, headers, formData, parseResponse, isSameOrigin, overrideOptions);
}

export async function del(url: string, payload?: Payload, headers?: Headers, formData = false, parseResponse = true, isSameOrigin = true, overrideOptions: IOverrideOptions = {}) {
    return await makeRequest(url, 'DELETE', payload, headers, formData, parseResponse, isSameOrigin, overrideOptions);
}

export async function get(url: string, headers?: Payload, formData = false, parseResponse = true, isSameOrigin = true, overrideOptions: IOverrideOptions = {}) {
    return await makeRequest(url, 'GET', null, headers, formData, parseResponse, isSameOrigin, overrideOptions);
}

export async function head(url: string) {
    return await fetch(url, { method: 'HEAD' });
}

interface Headers {
    [key: string]: string;
}

export async function makeRequest(
    url: string,
    method = 'GET',
    payload?: Payload,
    headers?: Headers,
    formData = false,
    parseResponse = true,
    isSameOrigin = true,
    overrideOptions: IOverrideOptions = {},
    withoutCSRFToken = false,
) {
    headers = headers || {};
    headers['X-Requested-With'] = 'XMLHttpRequest';
    if (!formData) {
        headers['Content-Type'] = 'application/json';
    }
    if (method === 'POST' && !withoutCSRFToken) {
        headers['X-CSRFToken'] = getCookie(settings.csrfTokenCookieName || 'metashopcsrftoken');
    }

    let body: string | FormData = null;
    if (payload) {
        if (formData) {
            body = new FormData();
            for (const [k, v] of Object.entries(payload)) {
                body.append(k, v);
            }
        } else {
            body = JSON.stringify(payload);
        }
    }

    let responseData;
    let ok;
    const response = await fetch(url, {
        method: method,
        ...(isSameOrigin ? { credentials: 'same-origin' } : {}),
        headers: headers,
        body: body,
        ...(overrideOptions || {}),
    });

    try {
        ok = response.ok;
        if (parseResponse) {
            responseData = await response.json();
        } else {
            responseData = await response.text();
        }
    } catch (e) {
        ok = false;
        responseData = { status: 'error' };

        if (method === 'POST' && settings.isRequestsErrorLogEnabled === true) {
            logError(`Request ${method} ${url} failed`, e);
        }
    }

    if (!ok) {
        throw responseData;
    } else {
        return responseData;
    }
}
