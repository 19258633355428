import * as React from 'react';
import styles from './AuctionLayout.scss';
import { interpolate, t } from '~/utils/localization';
import AuctionLot from '~/components/Auction/AuctionLot';
import AuctionPromoLayout from '~/Layouts/Auction/AuctionPromoLayout';
import AuctionLotPage from '~/Layouts/AuctionLotPage/AuctionLotPage';
import { ErrorBoundary } from '@sentry/react';
import { finishedStatuses, getNoticeByKind } from '~/utils/auction';
import CountDown from '~/components/CountDown/CountDown';
import { AUCTION_KIND, AUCTION_STATUS, CATEGORIES } from '~/const';
import classNames from 'classnames';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { useDispatch } from 'react-redux';
import useBackground from '~/hooks/useBackground';
import { LotWidth } from '~/types/auction';
import { playButtonDialogClickSound } from '~/api/WoWsClient';

interface IProps {
    auctions: IAuction[];
    auctionAccount: IAuctionAccountState;
}

interface IAuctionLayoutItem {
    auction: IAuction;
    auctionAccount: IAuctionAccountState;
}

const AuctionLayoutItem = ({ auction, auctionAccount }: IAuctionLayoutItem) => {
    const dispatch = useDispatch();
    const finishedAt = new Date(auction.finishedAt).getTime();
    const _isFinished = () => finishedAt <= Date.now() || finishedStatuses.includes(auction.status);
    const [isFinished, setFinish] = React.useState<boolean>(_isFinished());
    const auctionSecondPrice = auction.kind === AUCTION_KIND.SECOND_PRICE;

    const openAuctionAboutPopup = (event: React.MouseEvent) => {
        playButtonDialogClickSound();
        const target = event.target as Element;
        if (target.classList.contains(styles.auctionHeaderAbout)) {
            dispatch(changeVisiblePopup(POPUPS_NAME.AUCTION_ABOUT_POPUP, true));
        }
    };

    React.useEffect(() => {
        setFinish(_isFinished());
    }, [auction.status]);

    const guideStr = interpolate(t('Единая цена для всех победителей! Подробности в {block}правилах{end_block}.'), {
        block: `<span class="${styles.auctionHeaderAbout}">`,
        end_block: '</span>',
    });

    useBackground(auction.backgroundImg, [CATEGORIES.AUCTION]);

    return (
        <div className={styles.auction} key={`auction_wrapper_${auction.id}_${auction.status}_${isFinished}`}>
            <div className={styles.auctionHeader}>
                <div
                    className={classNames(styles.auctionTimer, {
                        [styles.auctionTimerSecondPrice]: !isFinished && auctionSecondPrice,
                    })}
                >
                    {!isFinished &&
                        (auctionSecondPrice ? (
                            <React.Fragment>
                                <div className={styles.auctionSecondPriceTitle}>{t('Аукцион единой цены')}</div>
                                <CountDown isLarge={true} className={styles.fontNormal} date={new Date(auction.finishedAt).getTime()} />
                                <div className={styles.auctionSecondPriceHint}>
                                    <div style={{ width: 'fit-content' }}>
                                        <div onClick={openAuctionAboutPopup} dangerouslySetInnerHTML={{ __html: guideStr }} />
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className={styles.auctionTimerTitle}>{t('До завершения аукциона:')}</div>
                                <CountDown isLarge={true} className={styles.fontNormal} date={new Date(auction.finishedAt).getTime()} />
                            </React.Fragment>
                        ))}
                    {isFinished && (
                        <React.Fragment>
                            <div className={styles.auctionFinishedTimer}>
                                <div className={styles.timerIcon} />
                                <div className={styles.finishedLabel}>{t('Аукцион завершен.')}</div>
                            </div>
                            {auction.status === AUCTION_STATUS.CHOOSING_WINNERS && (
                                <div className={styles.auctionFinishedTimer}>
                                    <div className={styles.auctionTimerTitle}>{t('Идёт определение победителей.')}</div>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
                <div className={styles.auctionInfo}>{!!auction.title && <div className={styles.auctionTitle}>{auction.title}</div>}</div>
                {!!auction.description && <div className={styles.auctionDescription}>{auction.description}</div>}
            </div>
            {auctionAccount?.isStaff && !auction.staffAllowed && <StaffMessage />}
            <div className={styles.auctionLine} key={auction.id}>
                {auction.lots.map((lot) => {
                    return (
                        <ErrorBoundary key={`${auction.id}${auction.status}${lot.id}`}>
                            <AuctionLot
                                key={`${lot.id}_${auction.status}`}
                                auction={auction}
                                template={lot.width ?? (auction.lots.length === 2 || lot.width === LotWidth.HALF ? LotWidth.HALF : LotWidth.DEFAULT)}
                                {...lot}
                            />
                        </ErrorBoundary>
                    );
                })}
            </div>
        </div>
    );
};

const StaffMessage = () => {
    const text1 = t('Вы не можете принимать участие в аукционах, так как являетесь сотрудником компании.');
    const text2 = interpolate(t('Однако вы можете воспользоваться преимуществами, описанными в {link_open}Personal Corporate Game Account Use Policy{link_close}.'), {
        link_open: '<span>',
        link_close: '</span>',
    });

    return <div className={styles.staffMessage} dangerouslySetInnerHTML={{ __html: `${text1} ${text2}` }} />;
};

const AuctionLayout = (props: IProps) => {
    if (!props.auctions || !props.auctions.length) {
        return (
            <div className={styles.emptyWrapper}>
                {t('Сейчас нет активных аукционов.')}&nbsp;{t('Информация о будущих аукционах появится в новостях в игре и на портале.')}
            </div>
        );
    }

    const auctions = props.auctions.filter((auction) => !auction.isNeedToShowPromoPage);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                {props.auctions.map((auction) => {
                    if (auction.isNeedToShowPromoPage) {
                        const firstLot = auction.lots[0];

                        return (
                            <ErrorBoundary key={auction.id}>
                                <AuctionPromoLayout
                                    auctionId={auction.id}
                                    title={auction.promotionStageTitle}
                                    description={auction.promotionStageDescription}
                                    startedAt={auction.startedAt}
                                    currency={firstLot.minimalBid.currency}
                                    promotionImageUrl={auction.promotionStageImageUrl}
                                    minimalBidAmount={firstLot.minimalBid.amount.toString()}
                                    promotionBackgroundColor={auction.promotionBackgroundColor}
                                />
                            </ErrorBoundary>
                        );
                    }

                    if (auction.lots.length === 1) {
                        return (
                            <ErrorBoundary key={auction.id}>
                                <AuctionLotPage auction={auction} lot={auction.lots[0]} hideNoticeText={true} fromCategory={true} />
                            </ErrorBoundary>
                        );
                    }

                    return (
                        <ErrorBoundary key={auction.id}>
                            <AuctionLayoutItem auction={auction} auctionAccount={props.auctionAccount} />
                        </ErrorBoundary>
                    );
                })}
            </div>
            {!!auctions.length && <div className={styles.footer}>{getNoticeByKind(auctions[auctions.length - 1]?.kind)}</div>}
        </div>
    );
};

export default AuctionLayout;
