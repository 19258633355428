import * as React from 'react';
import styles from './PaginationDots.scss';
import stylesView from '../View/View.scss';
import classNames from 'classnames';
import { playTabClickSound } from '~/api/WoWsClient';

interface IPaginationDots<T> {
    items: T[];
    currentIndex?: number;
    onChange?: (index: number) => void;
    className?: string;
}

const PaginationDots = <T,>(props: IPaginationDots<T>) => {
    const [currentIndex, setCurrentIndex] = React.useState<number>(props.currentIndex || 0);

    const dotsVisible = 5;
    const dotsEdges = Math.floor(dotsVisible / 2);
    const isDynamicBullets = props.items.length > dotsVisible;
    const isBeggining = currentIndex <= 0;
    const isEnding = currentIndex >= props.items.length - 1;

    const handleSlideChange = (index: number) => {
        if (currentIndex === index) {
            return;
        }
        playTabClickSound();
        setCurrentIndex(index);
        props.onChange?.(index);
    };

    const goNext = () => {
        if (isEnding) {
            return;
        }
        handleSlideChange(currentIndex + 1);
    };

    const goPrev = () => {
        if (isBeggining) {
            return;
        }
        handleSlideChange(currentIndex - 1);
    };

    const goTo = (index: number) => {
        handleSlideChange(index);
    };

    const getBulletsOffset = (index: number) => {
        if (index < dotsEdges) {
            return 0;
        }
        if (index + dotsEdges >= props.items.length) {
            return -1 * (props.items.length - dotsVisible);
        }

        return -1 * (index - dotsEdges);
    };

    const bulletsStyles = {
        '--offset': isDynamicBullets ? getBulletsOffset(currentIndex) : 0,
        '--length': Math.min(dotsVisible, props.items.length),
    } as React.CSSProperties;

    return (
        <div className={classNames(styles.wrapper, props.className)} style={bulletsStyles}>
            <div className={styles.controls}>
                <button className={classNames(styles.button, styles.prev, { [styles.disabled]: isBeggining })} tabIndex={-1} onClick={goPrev} />
                <div
                    className={classNames(styles.bulletsWrapper, {
                        [styles.bulletsDynamic]: isDynamicBullets,
                        [styles.bulletsStart]: isDynamicBullets && isBeggining,
                        [styles.bulletsEnd]: isDynamicBullets && isEnding,
                    })}
                >
                    <div className={styles.bullets}>
                        {props.items.map((_, index) => {
                            return (
                                <div
                                    className={classNames(styles.bullet, {
                                        [styles.bulletActive]: currentIndex === index,
                                    })}
                                    onClick={() => goTo(index)}
                                    data-index={index}
                                    key={`pagination_dot_${index}`}
                                />
                            );
                        })}
                    </div>
                </div>
                <button className={classNames(styles.button, styles.next, { [styles.disabled]: isEnding })} tabIndex={-1} onClick={goNext} />
            </div>
        </div>
    );
};

export default React.memo(PaginationDots);
