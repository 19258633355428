import classNames from 'classnames';
import store from '~/Store';
import { updateCurrentInfo } from '~/Actions/ActionMrps';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal';
import { getLevelFromRomanFormat } from '~/utils/utils';
import { t } from '~/utils/localization';

import styles from './MrpsReward.scss';
import checkImage from '~/assets/images/icon_check_gold.png';
import RandomBundleShinyAnimation from '~/Layouts/RandomBundlePage/RandomBundleHistoryCarousel/RandomBundleShinyAnimation';

interface MrpsRewardProps {
    isAvailable?: boolean;
    isReceived?: boolean;
    isBarItem: boolean;
    reward: MrpsLoadedReward;
}

interface StateSelector {
    isCurrentInfo: boolean;
}

function stateSelector(state: State, reward: MrpsLoadedReward) {
    const mrpsState = state.reducerMrps;

    return {
        isCurrentInfo: mrpsState.currentEvent.data.currentInfo?.requiredProgressPoints === reward.requiredProgressPoints,
    };
}

export default function MrpsReward(props: MrpsRewardProps) {
    const state = useSelector<State, StateSelector>((state: State) => stateSelector(state, props.reward), equal);

    function onRewardClickHandler() {
        store.dispatch(updateCurrentInfo(props.reward));
    }

    const receivedText = t('Получено');
    const isShip = props.reward.mainEntitlementType === 'ship';

    const mainEntitlementAdditionalData = props.reward.mainEntitlementAdditionalData;

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_barItem]: props.isBarItem,
                [styles.wrapper_ship]: isShip,
                [styles.wrapper_active]: state.isCurrentInfo,
            })}
            onClick={onRewardClickHandler}
        >
            {props.isReceived && (
                <div className={styles.receivedTextWrapper}>
                    <img
                        className={styles.receivedCheckIcon}
                        src={checkImage}
                        alt="Check"
                        onMouseDown={(event) => {
                            event.preventDefault();
                        }}
                    />
                    <span className={styles.receivedText}>{receivedText}</span>
                </div>
            )}
            <div
                className={classNames(styles.imgWrapper, {
                    [styles.imgWrapper_barItem]: props.isBarItem,
                    [styles.imgWrapper_ship]: isShip,
                    [styles.imgWrapper_received]: props.isReceived,
                })}
            >
                {props.isAvailable && !props.isReceived && (
                    <div className={styles.shinyWrapper}>
                        <RandomBundleShinyAnimation
                            isEnabled={true}
                            shinyAnimation={{
                                isEnabled: true,
                                image: '',
                            }}
                        />
                    </div>
                )}
                <img
                    loading="lazy"
                    className={styles.rewardImg}
                    src={props.reward.image || props.reward.mainEntitlementImage || ''}
                    alt={mainEntitlementAdditionalData?.title || props.reward.title || ''}
                    onDrop={(event) => {
                        event.preventDefault();
                    }}
                />
                {isShip && props.reward.additionalImage && (
                    <img
                        loading="lazy"
                        className={classNames(styles.waterImg, {
                            [styles.waterImg_barItem]: props.isBarItem,
                        })}
                        src={props.reward.additionalImage}
                        onDrop={(event) => {
                            event.preventDefault();
                        }}
                    />
                )}
            </div>
            <div
                className={classNames(styles.inlineInfoWrapper, {
                    [styles.inlineInfoWrapper_ship]: isShip,
                    [styles.inlineInfoWrapper_barItem]: props.isBarItem,
                })}
            >
                {isShip && !props.isBarItem && (
                    <div
                        className={classNames(styles.inlineShipInfoWrapper, {
                            [styles.inlineShipInfoWrapper_barItem]: props.isBarItem,
                        })}
                    >
                        <img
                            className={styles.inlineShipInfoImg}
                            src={mainEntitlementAdditionalData?.nationIcon || ''}
                            alt="nation icon"
                            onDrop={(event) => {
                                event.preventDefault();
                            }}
                        />
                        <img
                            className={styles.inlineShipInfoImg}
                            src={mainEntitlementAdditionalData?.typeIcon || ''}
                            alt="type icon"
                            onDrop={(event) => {
                                event.preventDefault();
                            }}
                        />
                        <span
                            className={classNames(styles.inlineShipInfoTitle, {
                                [styles.inlineShipInfoTitle_premium]: !!mainEntitlementAdditionalData?.isPremium,
                                [styles.inlineShipInfoTitle_special]: !!mainEntitlementAdditionalData?.isSpecial,
                                [styles.inlineShipInfoTitle_barItem]: props.isBarItem,
                            })}
                        >
                            {`${getLevelFromRomanFormat(mainEntitlementAdditionalData?.level || 0) || ''} ${mainEntitlementAdditionalData?.title || props.reward.title}`.trim()}
                        </span>
                    </div>
                )}
                {!isShip && !props.isBarItem && (
                    <div className={styles.inlineRewardInfo}>{`${props.reward.mainEntitlementAdditionalData?.title}${
                        props.reward.entitlements[0].amount > 1 ? ` x${props.reward.entitlements[0].amount}` : ''
                    }`}</div>
                )}
                {!isShip && !props.isBarItem && !!mainEntitlementAdditionalData?.typeTitle && <div className={styles.inlineRewardType}>{mainEntitlementAdditionalData.typeTitle}</div>}
            </div>
        </div>
    );
}
