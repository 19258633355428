import * as React from 'react';
import classNames from 'classnames';
import styles from './RewardPopup.scss';
import { OrangeButton } from '~/components/Button/Buttons';
import { t } from '~/utils/localization';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { PulseInDiv } from '~/components/Transitions';
import { useDispatch } from 'react-redux';
import { blurView } from '~/Actions/ActionApp';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { disableBodyScroll as addHiddenScrollToBody } from '~/utils/dom';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

interface IRewardPopup extends IReward {
    onClose: () => void;
}

const REWARD_POPUP_TITLE_MAP = {
    AUCTION_WON: t('Ваша ставка на Аукционе выиграла и вы получаете этот набор:'),
    AUCTION_DEV: t('К сожалению вы не можете участвовать в Аукционе. Ставки здесь могут делать только игроки. Однако вы можете получить наборы из всех лотов совершенно бесплатно!'),
};

const RewardPopup = (props: IRewardPopup) => {
    const dispatch = useDispatch();

    const close = () => {
        props.onComplete?.();
        props.onClose();
    };

    const content = props.content;

    const classesContent = classNames(styles.content, {
        [styles.minHeight]: content.length === 1,
    });

    React.useEffect(() => {
        dispatch(blurView(true));
        if (isMobileOrTabletWindow) {
            addHiddenScrollToBody(true);
            disableBodyScroll(document.querySelectorAll(`.${styles.content}`)[0]);
        }

        return () => {
            if (isMobileOrTabletWindow) {
                addHiddenScrollToBody(false);
                clearAllBodyScrollLocks();
            }
        };
    }, []);

    return (
        <Popup onClose={close} outsideClick={props.outsideClick}>
            <PopupHeader title={props.title} />
            <PopupBody>
                <div className={classesContent}>
                    <div className={styles.title}>{REWARD_POPUP_TITLE_MAP[props.category]}</div>
                    {content.length === 1 ? (
                        <React.Fragment>
                            <div className={styles.imageWrapper}>
                                <PulseInDiv className={styles.image} style={{ backgroundImage: `url(${content[0].imageUrl})` }} />
                            </div>
                            <div className={styles.bundleTitle}>{content[0].title}</div>
                        </React.Fragment>
                    ) : (
                        <div className={styles.contents}>
                            {content.map((reward, index) => {
                                return (
                                    <div className={styles.reward} key={`reward_${index}`}>
                                        <img src={reward.imageUrl} className={styles.rewardImage} />
                                        <div className={styles.rewardTitle}>{reward.title}</div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </PopupBody>
            <PopupFooter>
                <OrangeButton label={props.successButtonText ?? t('Закрыть')} onClick={close} />
            </PopupFooter>
        </Popup>
    );
};

export default RewardPopup;
