import View from '~/components/View/View';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import { BlurBackgroundStatus, ICurrentPage } from '~/Actions/ActionAppType';
import Account from '~/account/Account';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import equal from 'fast-deep-equal/react';
import { Processing } from '@wg/wows-react-uikit';
import { FilterPosition } from '~/types/category';
import * as React from 'react';
import History from '~/utils/history';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IViewContainer {
    isFinishedRequest: boolean;
    bundles: IBundleList;
    currentPage: ICurrentPage;
    isBlurView: boolean;
    port: IPort;
    popups: IPopup[];
    popupActive: IPopup;
    isFetching?: boolean;
    infoScreen: {
        isVisible: boolean;
        name?: string;
    };
    notifications: INotification[];
    viewClassName: string;
    viewBackground: string;
    currentNotificationVisibleName: string;
    isVisibleTopPanel: boolean;
    isVisibleMenu: boolean;
    isFullscreen: boolean;
    isHiddenMobileNav: boolean;
    isNeedToShowWelcomePage: boolean;
    isGiftAvailable: boolean;
    isVisibleRewardPopup: boolean;
    clientSource: ClientSource_Type;
    filterPosition: FilterPosition;
    isStartedVideo: boolean;
    isTrusted: boolean;
    categories: ICategories;
    isBlurBackground: BlurBackgroundStatus;
    customPageSettings: State['ReducerApp']['customPageSettings'];
}

const stateSelector = (state: State): IViewContainer => {
    const isNeedToShowWelcomePage = Account.getAccount()?.id && !state.ReducerApp.devMode?.disableWelcomePage && !isMobileOrTabletWindow;

    return {
        isFinishedRequest: state.ReducerApp.isFinishedRequest,
        currentPage: state.ReducerApp.currentPage,
        bundles: state.ReducerApp.bundles,
        port: state.ReducerApp.port,
        popups: state.ReducerApp.popups,
        popupActive: state.ReducerApp.popupActive,
        isBlurView: state.ReducerApp.isBlurView,
        isHiddenMobileNav: state.ReducerApp.isHiddenMobileNavigate,
        isFetching: state.ReducerApp.isFetching,
        infoScreen: state.ReducerApp.infoScreen,
        viewClassName: state.ReducerApp.viewClassName,
        viewBackground: state.ReducerApp.viewBackground,
        isVisibleTopPanel: state.ReducerApp.isVisibleTopPanel,
        isVisibleMenu: state.ReducerApp.isVisibleMenu,
        isFullscreen: state.ReducerApp.isFullscreen,
        isNeedToShowWelcomePage: isNeedToShowWelcomePage,
        isGiftAvailable: Account.isGiftsAvailable(),
        currentNotificationVisibleName: state.ReducerApp.currentNotificationVisibleName,
        notifications: state.ReducerAccount.notifications,
        filterPosition: state.ReducerApp.filterPosition,
        isVisibleRewardPopup: state.ReducerAccount.rewards?.isVisible,
        clientSource: state.ReducerApp.clientSource,
        isStartedVideo: state.ReducerApp.isStartedVideo,
        isTrusted: state.ReducerApp.isTrusted,
        categories: state.ReducerApp.categories,
        isBlurBackground: state.ReducerApp.isBlurBackground,
        customPageSettings: state.ReducerApp.customPageSettings,
    };
};

const ViewContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = useSelector<State, IViewContainer>(stateSelector, equal);

    History.initRouterNavigate(navigate);
    React.useEffect(() => {
        History.addToHistory(location.pathname);
    }, [location]);

    if (!window.metashop.state?.content) {
        return <Processing isFetching={true} />;
    }

    if (!state.isFinishedRequest && (isMobileOrTabletWindow || isInGameBrowser)) {
        return <Processing isFetching={true} />;
    }

    return <View {...state} />;
};

export default ViewContainer;
