import classNames from 'classnames';
import store from '~/Store';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import equal from 'fast-deep-equal';
import { resetMrpsCurrentEvent, toggleActiveExchangeTransaction, toggleExchangeMenu } from '~/Actions/ActionMrps';
import { blurBackground, changeVisiblePopup } from '~/Actions/ActionApp';
import Account from '~/account/Account';
import MrpsProcessor from '~/processors/MrpsProcessor';
import dwhExport from '~/api/dwhExport';
import { t } from '~/utils/localization';
import { redirectToLogin } from '~/utils/utils';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { DWH_EVENTS } from '~/const';
import { DivTooltip } from '@wg/wows-react-uikit';
import { showDownloadGamePopup } from '~/components/Popups/settings';
import { POPUPS_NAME } from '~/components/PopupManager';
import { OrangeButton } from '~/components/Button/Buttons';

import styles from './MrpsControls.scss';

interface MrpsControlsProps {
    isPopup?: boolean;
    isSuccessPopup?: boolean;
    isErrorPopup?: boolean;
}
interface StateSelector {
    currentEvent: MrpsCurrentEventState;
    availableRewards: MrpsLoadedReward[];
    currentProgress: number;
    isExchangeMenuOpen: MrpsExchangeMenuStatus;
    isActiveExchangeTransaction: boolean;
}

function stateSelector(state: State): StateSelector {
    return {
        currentEvent: state.reducerMrps.currentEvent,
        availableRewards: state.reducerMrps.currentEvent?.data.availableRewards,
        currentProgress: state.reducerMrps.currentEvent?.currentProgress,
        isExchangeMenuOpen: state.reducerMrps.currentEvent.data.isExchangeMenuOpen,
        isActiveExchangeTransaction: state.reducerMrps.hasPendingTransactions,
    };
}

export default function MrpsControls(props: MrpsControlsProps) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);

    const buttonExchangeText = t('Обменять');
    const buttonResetText = t('Сбросить');
    const buttonClosePopup = t('Отмена');
    const buttonCloseSuccessOrErrorPopup = t('Закрыть');
    const buttonOpenExchangeText = t('Выбрать ресурсы');

    function exchange() {
        playButtonClickSound();

        store.dispatch(changeVisiblePopup(POPUPS_NAME.MRPS_POPUP, true));

        dwhExport.send(DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_CATEGORY, { event_name: state.currentEvent.eventName });
    }

    function confirmExchange() {
        playButtonClickSound();

        store.dispatch(toggleActiveExchangeTransaction(true));

        const rewardsToGet = state.currentEvent.data.availableRewards;

        dwhExport.send(DWH_EVENTS.PURCHASE_REQUEST, { event_name: state.currentEvent.eventName });

        new MrpsProcessor(state.currentEvent.eventName)
            .buy(state.currentEvent.resourcesInUse)
            .then(() => {
                store.dispatch(toggleActiveExchangeTransaction(false));
                store.dispatch(changeVisiblePopup(null));
                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.MRPS_POPUP, true, {
                        isSuccessPopup: true,
                        rewardsToShow: rewardsToGet,
                    }),
                );

                dwhExport.send(DWH_EVENTS.PURCHASE_SUCCESS, { event_name: state.currentEvent.eventName });
            })
            .catch((e) => {
                console.log(e);
                store.dispatch(toggleActiveExchangeTransaction(false));
                store.dispatch(changeVisiblePopup(null));
                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.MRPS_POPUP, true, {
                        isErrorPopup: true,
                    }),
                );

                dwhExport.send(DWH_EVENTS.PURCHASE_REQUEST_ERROR, { event_name: state.currentEvent.eventName });
            });
    }

    function resetAll() {
        playButtonClickSound();

        store.dispatch(resetMrpsCurrentEvent(state.currentEvent.eventName, true) as unknown as AnyAction);
    }

    function closePopup() {
        playButtonClickSound();

        store.dispatch(changeVisiblePopup(null));

        dwhExport.send(DWH_EVENTS.PURCHASE_CANCEL, { event_name: state.currentEvent.eventName });
    }

    function closeFinalPopup() {
        playButtonClickSound();

        store.dispatch(changeVisiblePopup(null));
        store.dispatch(toggleExchangeMenu(0));
        store.dispatch(blurBackground(0));
    }

    function openExchangeMenu() {
        playButtonClickSound();

        if (!Account.getAccount()) {
            redirectToLogin();

            return;
        }

        if (Account.isDeactivated()) {
            showDownloadGamePopup();

            return;
        }

        store.dispatch(toggleExchangeMenu(1));
        store.dispatch(blurBackground(1));
    }

    if (state.isActiveExchangeTransaction) {
        return (
            <div
                className={classNames(styles.wrapper, {
                    [styles.wrapper_popup]: props.isPopup || props.isSuccessPopup,
                    [styles.wrapper_isExchangeMenuOpen]: state.isExchangeMenuOpen === 1,
                })}
            >
                <OrangeButton label={t('Обработка')} isFetching />
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_popup]: props.isPopup || props.isSuccessPopup,
                [styles.wrapper_isExchangeMenuOpen]: state.isExchangeMenuOpen === 1,
            })}
        >
            {state.isExchangeMenuOpen === 1 ? (
                <>
                    {!props.isSuccessPopup && !props.isErrorPopup && (
                        <DivTooltip
                            className={styles.buttonTooltipWrapper}
                            fixed
                            tooltipBody={state.availableRewards.length === 0 ? <div className={styles.buttonTooltip}>{t('Для обмена добавьте необходимое количество ресурсов')}</div> : null}
                        >
                            <button
                                className={classNames(styles.button, styles.button_orange, {
                                    [styles.button_disabled]: state.availableRewards.length === 0,
                                })}
                                onClick={(props.isPopup && confirmExchange) || exchange}
                            >
                                {buttonExchangeText}
                            </button>
                        </DivTooltip>
                    )}
                    <button
                        className={classNames(styles.button, {
                            [styles.button_disabled]: state.currentProgress === 0,
                        })}
                        onClick={((props.isSuccessPopup || props.isErrorPopup) && closeFinalPopup) || (props.isPopup && closePopup) || resetAll}
                    >
                        {((props.isSuccessPopup || props.isErrorPopup) && buttonCloseSuccessOrErrorPopup) || (props.isPopup && buttonClosePopup) || buttonResetText}
                    </button>
                </>
            ) : (
                <button
                    className={classNames(styles.button, { [styles.button_orange]: !props.isSuccessPopup && !props.isErrorPopup })}
                    onClick={((props.isSuccessPopup || props.isErrorPopup) && closeFinalPopup) || openExchangeMenu}
                >
                    {((props.isSuccessPopup || props.isErrorPopup) && buttonCloseSuccessOrErrorPopup) || buttonOpenExchangeText}
                </button>
            )}
        </div>
    );
}
