import * as React from 'react';
import styles from './RandomBundlesPurchaseSuccess.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IChangeVisiblePopup, IUpdateQuantity } from '~/Actions/ActionAppType';
import { changeVisiblePopup, updateQuantity } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { Popup, PopupHeader, PopupFooter, PopupBody } from '~/components/Popup/Popup';
import { t, interpolate } from '~/utils/localization';
import { OrangeButton } from '~/components/Button/Buttons';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { getSumFromArray } from '~/utils/number';
import { getPrimaryItem } from '~/utils/bundles';
import { arrayToObjectByKey } from '~/utils/utils';
import RandomBundleItem from '~/components/RandomBundleItem/RandomBundleItem';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import Ship from '~/components/Ship/Ship';
import { items } from '@wg/wows-entities/const';
import classNames from 'classnames';
import { ItemsContainsTooltip } from '~/components/Tooltip/Tooltips';
import { DivTooltip } from '@wg/wows-react-uikit';
import { sort } from 'fast-sort';
import { IPopupSoundset } from '~/types/soundsets';

interface IRandomBundlePurchase {
    data: {
        parentBundleId: number;
        bundleIds: PurchaseRandomBundleIds;
        closeCallback: () => void;
    };
}

interface IStateSelector {
    bundles: IBundleList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        bundles: state.ReducerApp.bundles,
    };
};

const RandomBundleShinyItem = ({ shinyItem, template }: { shinyItem: IBundle; template?: 'half' | 'default' }) => {
    const primaryItem = getPrimaryItem(shinyItem);
    const [style, setStyle] = React.useState<React.CSSProperties>(null);

    React.useEffect(() => {
        setStyle({
            transitionDelay: `2.23s`,
            opacity: 1,
        });
    }, []);

    const tooltipBody = <ItemsContainsTooltip bundle={shinyItem} />;
    const icons = shinyItem.icons as RandomBundleIcons;

    return (
        <DivTooltip className={classNames(styles.shinyWrapper, styles[template])} style={style} tooltipBody={tooltipBody}>
            <div className={styles.shinyImageWrapper}>
                <img src={icons.randomShinyImage || shinyItem.icons.medium} />
            </div>
            <div className={styles.shinyInfo}>
                {primaryItem?.type === items.VEHICLES ? (
                    <Ship shipId={primaryItem.identifier} fallback={shinyItem.title} />
                ) : (
                    <span className={styles.shinyInfoTitle} dangerouslySetInnerHTML={{ __html: shinyItem.title }} />
                )}
            </div>
        </DivTooltip>
    );
};

const RandomBundlesPurchaseSuccess = ({ data }: IRandomBundlePurchase) => {
    const dispatch = useDispatch();
    const { bundleIds, parentBundleId, closeCallback } = data;
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const parentRandomBundle = state.bundles[parentBundleId];
    const randomBundleIds = Object.keys(bundleIds);
    const randomBundleMap = arrayToObjectByKey(parentRandomBundle.randomBundleChildren, 'id');
    const preparedBundleIds = sort(randomBundleIds).asc((id) => {
        return parentRandomBundle.randomBundleChildren.findIndex((item) => item.id === +id);
    });
    const refScrollableContent = React.useRef<HTMLDivElement>(null);
    const [scrollableContentStyles, setScrollableContentStyles] = React.useState<React.CSSProperties>(null);

    const close = () => {
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(POPUPS_NAME.CONFIRM_PURCHASE));
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            refScrollableContent.current = _ref;
        }
    };

    useMaskScrollEffect(refScrollableContent);

    React.useEffect(() => {
        dispatch<IUpdateQuantity>(updateQuantity(parentRandomBundle.id, null, null));
    }, []);

    return (
        <Popup
            onClose={close}
            outsideClick={false}
            renderEscButton={true}
            popupClassName={styles.popupWrapper}
            className={classNames(styles.popup, 'armory__auto--success-purchase-popup')}
            soundset={IPopupSoundset.EXCHANGE}
        >
            <PopupHeader title={t('Спасибо за покупку!')} />
            <PopupBody className={styles.body}>
                <div className={styles.scrollableContent} ref={setRef}>
                    <div className={styles.items}>
                        {preparedBundleIds.map((bundleId, index) => {
                            const delay = Math.random() + (Math.random() % 0.5);
                            const flipDelay = 2 + delay + (Math.random() % (index + 0.8));
                            return (
                                <RandomBundleItem
                                    key={`random_bundle_item_${bundleId}`}
                                    className={styles.item}
                                    bundle={randomBundleMap[bundleId]}
                                    parentRandomBundle={parentRandomBundle}
                                    amount={bundleIds[+bundleId]}
                                    animationStyles={{
                                        transitionDelay: `${delay}s`,
                                        opacity: 1,
                                        transform: 'scale(1)',
                                    }}
                                    flipAnimationStyles={{
                                        transitionDelay: `${flipDelay}s`,
                                        transform: 'rotateY(180deg)',
                                    }}
                                    flipFrontCardStyles={{
                                        transitionDelay: `${flipDelay}s`,
                                        opacity: '0',
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.infoTitle} dangerouslySetInnerHTML={{ __html: parentRandomBundle.title }} />
                    <div className={styles.itemSubTitle}>{interpolate(t('Количество наборов: {amount}'), { amount: getSumFromArray(Object.values(bundleIds)) })}</div>
                </div>
            </PopupBody>
            <PopupFooter>
                <OrangeButton label={t('Закрыть')} onClick={close} className={classNames('armory__auto--success_purchase_button')} />
            </PopupFooter>
        </Popup>
    );
};

export default RandomBundlesPurchaseSuccess;
