import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';

interface IStateSelector {
    lootboxes: StorageInventory;
    lootboxes_without_dropped_rewards: LootboxesWithoutDroppedRewardsInventory;
    allLootboxes: ILootboxes;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        lootboxes: state.ReducerAccount.inventory?.lootboxes,
        lootboxes_without_dropped_rewards: state.ReducerAccount.inventory?.lootboxes_without_dropped_rewards,
        allLootboxes: state.ReducerLootbox.lootboxes,
    };
};

export function useSavePointInfo(lootbox: ILootboxStateItem | number) {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    if (typeof lootbox !== 'object') lootbox = state.allLootboxes[lootbox];
    if (!lootbox) return [];
    const { savePoint, savePointForValuableGroup } = lootbox;
    const pityTimer = savePoint || savePointForValuableGroup?.savePoint;
    let amountToReward = pityTimer;
    let amountOpenedFromList: number;
    let amountOpened = 0;
    const amountObtained = state.lootboxes?.[lootbox.id] || 0;

    if (state.lootboxes && state.lootboxes_without_dropped_rewards) {
        if (lootbox.savePointForValuableGroup && state.lootboxes_without_dropped_rewards[lootbox.id]?.[lootbox.slotWithUnique]) {
            amountOpenedFromList = state.lootboxes_without_dropped_rewards[lootbox.id]?.[lootbox.slotWithUnique]?.[lootbox.savePointForValuableGroup.groupSavePointIndex];
        }
        amountOpened = (amountOpenedFromList || state.lootboxes_without_dropped_rewards?.[lootbox.id]?.[lootbox.slotWithUnique]?.[0] || 0) % pityTimer;
        amountToReward -= amountOpened;
    }

    return [amountToReward, amountOpened, amountObtained, !!(state.lootboxes && state.lootboxes_without_dropped_rewards)];
}
