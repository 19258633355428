import styles from './TreasureProgress.scss';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import Info from '~/components/Info/Info';
import { DivTooltip } from '@wg/wows-react-uikit';
import * as React from 'react';
import { t } from '~/utils/localization';

type Props = {
    account: TreasureAccountData;
    text: string;
    boxName: string;
    chains: TreasureChain[];
};

export function ProgressTooltip({ account, text, boxName, chains }: Props) {
    const progressInfo: Array<string> = [t('До гарантированного выпадения:')];
    function getTooltipBody() {
        const text = [...progressInfo];
        const chainText = chains.map((chain, i) => (
            <span className={styles.line} key={i}>
                <span>{chain.title}</span>
                <span>{account?.rotations[boxName][chain.name] || chain.rotationsTillGuarantee}</span>
            </span>
        ));
        return [...text, ...chainText];
    }

    return (
        <div className={styles.informer}>
            <span>{text}</span>
            <DivTooltip position={'center-left'} tooltipBody={<DefaultTooltip classes={{ body: styles.info }} text={getTooltipBody()} />}>
                <Info color={'rgba(255, 255, 255, 0.75)'} />
            </DivTooltip>
        </div>
    );
}
