import React from 'react';
import store from '~/Store';
import { BackgroundSizes, ICustomGroupContent } from '../../../types/customPage';

import styles from './CustomPage.scss';
import { CustomPageGroupedBundle } from './CustomPageGroupedBundle/CustomPageGroupedBundle';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal';
import { AppState } from '~/Reducers/ReducerApp';
import { CustomPageAdditionalContent } from './CustomPageAdditionalContent/CustomPageAdditionalContent';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { FEATURING_DESTINATION } from '~/types/contents';
import { openUrl } from '~/utils/settings';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';

interface CustomPageProps {
    bundles: number[];
    category?: IMenuMapItem;
}

interface StateSelector {
    featureInfo?: IFeature;
    customBackground?: AppState['customPageSettings']['background'];
}

function stateSelector(state: State): StateSelector {
    const featureInfo = state.ReducerApp.featuring.find((feature) => {
        return feature.destination === FEATURING_DESTINATION.CATEGORY && feature.destinationId === state.ReducerApp.currentPage?.name;
    });

    return {
        customBackground: state.ReducerApp.customPageSettings?.background,
        featureInfo,
    };
}

export function groupBundles(bundlesIds: Array<number>) {
    const bundles = store.getState().ReducerApp.bundles;

    const groupNames: Array<string> = [];
    const groupedBundles = bundlesIds.reduce((state: Record<string, ICustomGroupContent>, bundleId: number) => {
        const bundle = bundles[bundleId];
        if (!bundle.groupName) {
            return state;
        }

        if (!state[bundle.groupName]) {
            groupNames.push(bundle.groupName);
            state[bundle.groupName] = {
                bundles: [],
                customParams: {
                    place: [7, 6],
                    width: 4,
                    height: 6,
                    image: {
                        size: 'big',
                        position: 'left',
                    },
                },
            };
        }

        state[bundle.groupName].bundles.push(bundle);
        return state;
    }, {});

    groupNames.forEach((groupName) => {
        groupedBundles[groupName].bundles.sort((a: IBundle, b: IBundle) => a.groupIndex - b.groupIndex);
    });

    return groupedBundles;
}

export function CustomPage({ bundles, category }: CustomPageProps) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);
    const groups = groupBundles(bundles);

    const onAdditionalContentClickHandler = React.useCallback(() => {
        console.log(state);

        const featureContentBlockData = state.featureInfo?.contentBlocks[0]?.data as IContentBlockData;

        featureContentBlockData.linkValue && store.dispatch(changeVisiblePopup(POPUPS_NAME.IFRAME_POPUP, true, { iframeUrl: featureContentBlockData.linkValue }));
    }, [state]);

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.contentWrapper}
                style={{
                    width: state.customBackground.naturalSizes.width ? state.customBackground.currentSizes.width : 0,
                    height: state.customBackground.naturalSizes.height ? state.customBackground.currentSizes.height : 0,
                    opacity: state.customBackground.naturalSizes.width && state.customBackground.naturalSizes.height ? 1 : 0,
                }}
            >
                {Object.entries(groups).map(([groupName, content]) => {
                    return <CustomPageGroupedBundle bundles={content.bundles} customParams={content.customParams} key={`bundle_group_${groupName}`} />;
                })}
                <CustomPageAdditionalContent
                    customParams={{
                        place: [4, 3],
                        width: 2,
                        height: 7,
                    }}
                    onClick={onAdditionalContentClickHandler}
                />
            </div>
        </div>
    );
}
