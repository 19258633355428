import store from '~/Store';
import Account from '~/account/Account';
import { armoryState } from '~/utils/settings';
import { isValidDateInterval } from '~/utils/time';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import { FEATURING_DESTINATION } from '~/types/contents';
import { CATEGORIES } from '~/const';
import { isSerialBundle } from './bundles';

export const isAllowedNewLabelFromCoupons = (): boolean => {
    if (!Account.getAccount()) {
        return false;
    }

    const accountState = store.getState().ReducerAccount;
    const coupons = accountState.coupons;

    return coupons.some((coupon: ICoupon) => isValidDateInterval(coupon.labelNewActivityPeriod) && !Account.isViewedLabel(coupon.name));
};

export const getCountCouponsWithLabelNew = (): number => {
    if (!Account.getAccount()) {
        return 0;
    }

    const accountState = store.getState().ReducerAccount;
    const coupons = accountState.coupons;

    return coupons.reduce((count: number, coupon: ICoupon) => {
        if (isValidDateInterval(coupon.labelNewActivityPeriod) && !Account.isViewedLabel(coupon.name) && !coupon.isDisabled) {
            count++;
        }

        return count;
    }, 0);
};

export const isAllowedNewLabelFromFeatured = (): boolean => {
    if (!Account.getAccount()) {
        return false;
    }

    const featuring = armoryState.content.featuring.filter((featuring) => featuring.destination === FEATURING_DESTINATION.MAIN);
    const appState = store.getState().ReducerApp;
    const bundles = appState.bundles;

    return featuring.some((contentBlock) => {
        const content = contentBlock.contentBlocks;
        let flag = false;
        content.forEach((block) => {
            if (block.type === FEATURING_TEMPLATES.BUNDLE_LIST) {
                const bundlesToCheckList = (block.data as IContentBlockData).bundles;

                for (let i = 0; i < bundlesToCheckList.length; i++) {
                    const bundleToCheck = bundles[bundlesToCheckList[i].id];
                    const isAllowedLabel = isSerialBundle(bundleToCheck)
                        ? isAllowedLabelFromSerialBundle([bundleToCheck].concat(bundleToCheck.serialSequence.map((bundleId) => bundles[bundleId])))
                        : isAllowedNewLabelFromBundle(bundleToCheck);

                    if (isAllowedLabel) {
                        flag = true;
                        break;
                    }
                }
            } else if (block.type === FEATURING_TEMPLATES.CATEGORY_PREVIEW) {
                if (!flag) {
                    const data = block.data as IContentBlockData;
                    const category = appState.categories[data.categoryName];
                    if (category) {
                        if (!category.labelNewAllowed) {
                            flag = isLabelNewShownForCategory(data.categoryName, data.bundlesCount);
                        } else {
                            let categoryBundles = Object.values(bundles).filter((bundle: IBundle) => category.bundles.includes(bundle.id));
                            categoryBundles = data.bundlesCount ? categoryBundles.splice(0, data.bundlesCount) : categoryBundles;
                            flag = categoryBundles.some((bundle: IBundle) => isAllowedNewLabelFromBundle(bundle));
                        }
                    }
                }
            }
        });

        return flag;
    });
};

export const getEventLabelIdFromCategory = (category: ICategory) => {
    return category.name + '_event';
};

export const isEventLabelEnabledForCategory = (category: ICategory) => {
    const labelId = getEventLabelIdFromCategory(category);
    return isValidDateInterval(category?.label?.labelNewActivityPeriod) && !!Account?.getAccount()?.id && !Account.isViewedLabel(labelId);
};

export const isLabelNewShownForCategory = (name: string, bundleSpliceLength?: number, isNotMarkedParentCategory = false, ignoreEventLabel = false): boolean => {
    const { categories } = store.getState().ReducerApp;
    const category = categories?.[name];

    if (!ignoreEventLabel && isEventLabelEnabledForCategory(category)) return true;

    if (!Account.getAccount()) {
        return false;
    }

    if (!category) {
        return false;
    }

    if (name === CATEGORIES.FEATURED) {
        const hasCategoryLabel = isValidDateInterval(category.labelNewActivityPeriod) && !Account.isViewedLabel(category.name);
        return hasCategoryLabel || isAllowedNewLabelFromFeatured();
    }

    const appState = store.getState().ReducerApp;
    const bundles = appState.bundles;

    const isEnabledLabel = (_category: ICategory): boolean => {
        let isAllowedLabelFromBundle = false;
        for (let i = 0; i < (bundleSpliceLength || _category.bundles.length); i++) {
            const bundleId = _category.bundles[i];
            const bundle = bundles[bundleId] as IBundle & { id: string };
            isAllowedLabelFromBundle = isAllowedNewLabelFromBundle(bundle);
            if (isAllowedLabelFromBundle) break;
        }

        if (isAllowedLabelFromBundle) {
            return true;
        }

        if (isNotMarkedParentCategory) {
            return false;
        }

        return isValidDateInterval(_category.labelNewActivityPeriod) && !Account.isViewedLabel(_category.name);
    };

    if (!category.subCategories?.length) return isEnabledLabel(category);
    return category.subCategories.some((_category: string) => {
        const categoryConfig = categories[_category];
        if (!ignoreEventLabel && isEventLabelEnabledForCategory(categoryConfig)) return true;
        if (categoryConfig) {
            return isEnabledLabel(categoryConfig);
        }

        return false;
    });
};

export const isAllowedNewLabelFromBundle = (bundle: IBundle) => {
    if (!Account.getAccount() || !bundle) {
        return false;
    }

    if (bundle.isPurchased) {
        return false;
    }

    return isValidDateInterval(bundle.labelNewActivityPeriod) && !Account.isViewedLabel(bundle.name);
};

export function isAllowedLabelFromSerialBundle(bundles: IBundle[]) {
    for (let i = 0; i < bundles.length; i++) {
        const bundle = bundles[i];
        if (isAllowedNewLabelFromBundle(bundle)) {
            return true;
        }
    }

    return false;
}
