import React from 'react';

import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { t } from '~/utils/localization';
import { IPortContainItem } from '../../settings';

import DivTooltip from '@wg/wows-react-uikit/components/DivTooltip/DivTooltip';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { RadioButton } from '~/components/RadioButton/RadioButton';

import styles from './PortSwitchButtonsBlock.scss';

interface PortSwitchButtonsBlockProps {
    shipId?: string | number;
    exteriorId?: string | number;
    itemType?: string;

    items: Array<IPortContainItem>;
    previewItemCount: number;
}

function getCurrentItemIndex(props: PortSwitchButtonsBlockProps) {
    return props.items.findIndex((item) => isActiveItem(props.shipId, props.exteriorId, props.itemType, item));
}

function isActiveItem(
    shipId: PortSwitchButtonsBlockProps['shipId'],
    exteriorId: PortSwitchButtonsBlockProps['exteriorId'],
    itemType: PortSwitchButtonsBlockProps['itemType'],
    itemToCheck: IPortContainItem,
) {
    const isPermanentCamouflageFlag = isPermanentCamouflage(itemType);
    const isCamouflageFlag = isCamouflage(itemType);

    if (isPermanentCamouflageFlag || isCamouflageFlag) {
        return itemToCheck.id === exteriorId;
    }

    return itemToCheck.id === shipId;
}

export function PortSwitchButtonsBlock(props: PortSwitchButtonsBlockProps) {
    const activeItemIndex = getCurrentItemIndex(props);

    function sideButtonClickHandler(step: number) {
        let currentIndex = activeItemIndex;

        if (step < 0) {
            currentIndex -= Math.abs(step);
            if (currentIndex < 0) {
                currentIndex = props.previewItemCount - 1;
            }
        } else {
            currentIndex += step;
            if (currentIndex >= props.previewItemCount) {
                currentIndex = 0;
            }
        }

        playButtonClickSound();
        props.items[currentIndex]?.portPreviewOnClick?.();
    }

    function buttonClickHandler(index: number) {
        if (activeItemIndex === index) return;

        playButtonClickSound();
        props.items[index]?.portPreviewOnClick?.();
    }

    return (
        <div className={styles.wrapper}>
            <DivTooltip tooltipBody={<DefaultTooltip text={t('Предыдущий предмет')} />} className={styles.sideButtonWrapper}>
                <button className={styles.prevButton} onClick={sideButtonClickHandler.bind(null, -1)} />
            </DivTooltip>
            <div className={styles.radioButtonsWrapper}>
                {props.items.map((item, index) => {
                    if (!item.isEnabledPortPreview) return null;

                    return <RadioButton isActive={activeItemIndex === index} onClick={buttonClickHandler.bind(null, index)} />;
                })}
            </div>
            <DivTooltip tooltipBody={<DefaultTooltip text={t('Следующий предмет')} />} className={styles.sideButtonWrapper}>
                <button className={styles.nextButton} onClick={sideButtonClickHandler.bind(null, 1)} />
            </DivTooltip>
        </div>
    );
}
