import * as React from 'react';
import RandomBundle from '~/components/Bundle/Random/RandomBundle';
import LazyRenderingBundle from '~/components/LazyRendering/LazyRenderingBundle';
import { ErrorBoundary } from '@sentry/react';
import SerialBundleCarousel from '~/components/Bundle/Serial/SerialBundleCarousel';

export interface IBundleManager {
    bundle: IBundle;
    className?: string;
    image?: string;
    width?: string;
    isLazy?: boolean;
    presetName?: string;
}

export const BUNDLE_TYPES = {
    default: 'default',
    random: 'random',
    serial: 'serial',
    promo: 'promo',
    periodic: 'periodic',
};

const BundleManager = (props: IBundleManager): any => {
    switch (props.bundle.type) {
        case BUNDLE_TYPES.serial:
            return (
                <ErrorBoundary>
                    <SerialBundleCarousel key={`bundle_${props.bundle.id}_${props.presetName || 'no_preset'}`} bundle={props.bundle} {...props} />
                </ErrorBoundary>
            );

        case BUNDLE_TYPES.random:
        case BUNDLE_TYPES.periodic:
            return (
                <ErrorBoundary>
                    <RandomBundle key={`bundle_${props.bundle.id}_${props.presetName || 'no_preset'}`} bundle={props.bundle} width={props.width} {...props} />
                </ErrorBoundary>
            );

        case BUNDLE_TYPES.default:
        default:
            return (
                <ErrorBoundary>
                    <LazyRenderingBundle {...props} />
                </ErrorBoundary>
            );
    }
};

export default BundleManager;
