import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import { MrpsProgressTooltip } from '../MrpsProgressTooltip/MrpsProgressTooltip';

import styles from './MrpsCursorInfo.scss';

interface StateSelector {
    resourceInUse?: MrpsResourcesSpent;
    resourceSpent?: MrpsResourcesSpent;
}

function stateSelector(state: State): StateSelector {
    const mrpsState = state.reducerMrps;
    const mrpsAccountData = mrpsState.account;
    const currentEvent = mrpsState.currentEvent;
    const currentEventName = currentEvent?.eventName;

    return {
        resourceInUse: currentEvent?.resourcesInUse,
        resourceSpent: mrpsAccountData?.[currentEventName]?.resourcesSpent,
    };
}
interface MrpsCursorInfoProps {
    progress: number;
    isCompletedByUniqueRewards?: boolean;
}

export function MrpsCursorInfo({ progress, isCompletedByUniqueRewards = false }: MrpsCursorInfoProps) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);

    return (
        <div className={styles.wrapper}>
            {`${isCompletedByUniqueRewards ? 100 : progress}%`}
            {progress > 0 && (
                <MrpsProgressTooltip resourceInUse={state.resourceInUse} resourceSpent={state.resourceSpent} progress={progress}>
                    <div className={styles.icon} />
                </MrpsProgressTooltip>
            )}
        </div>
    );
}
