import * as React from 'react';
import BlackFridayAnimation from '~/customization/BlackFriday/Category/BlackFridayAnimation';

interface IBlackFriday {
    categoryName: ICategoryList;
    children: React.ReactChild | React.ReactChild[];
}

const BlackFriday = ({ categoryName, children }: IBlackFriday) => {
    return (
        <React.Fragment>
            <BlackFridayAnimation categoryName={categoryName} />
            {children}
        </React.Fragment>
    );
};

export default BlackFriday;
