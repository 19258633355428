import * as React from 'react';
import classNames from 'classnames';

import store from '~/Store';
import { hidePopup } from '~/Actions/ActionApp';
import { openPortByDefaultItem } from '~/settings/port';
import { settings } from '~/utils/settings';
import { isInGameBrowser } from '~/utils/utils';
import { isCurrency } from '~/utils/currencies';

import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import PortPreviewButton from '~/components/PortPreviewButton/PortPreviewButton';
import { isCamouflage } from '~/components/Camouflages/Camouflages';

import styles from './AutoDescription.scss';

interface IAutoDescriptionDefaultItem {
    reward: ILootboxReward;
    className?: string;
    inventoryCount?: number;
    bundleId?: number;
    lootboxId: number;
    goToPortHandler?: () => void;
    onRender?: () => void;
}

const AutoDescriptionDefaultItem = ({ reward, inventoryCount, className, bundleId, goToPortHandler, lootboxId, onRender }: IAutoDescriptionDefaultItem) => {
    const openPort = () => {
        window?.tooltipProvider?.hide?.();

        store.dispatch(hidePopup());

        openPortByDefaultItem(
            {
                identifier: reward.id,
                shipId: settings.camouflagePreviewDefaultShip,
                type: reward.type,
            },
            false,
        );
        goToPortHandler?.();
    };

    const isAvailablePortPreview = bundleId && isInGameBrowser && isCamouflage(reward.type) && settings.camouflagePreviewDefaultShip;

    return (
        <div className={classNames(styles.defaultItem, className)}>
            {isAvailablePortPreview && <PortPreviewButton onClick={openPort} className={styles.iconPortPreviewButton} onlyIcon />}
            <WoWSEntity
                id={reward.id}
                type={reward.type}
                className={styles.wowsEntity}
                amount={reward.amount}
                customisation={{
                    inventoryCount,
                }}
                presentation={{
                    withTooltip: true,
                    withText: true,
                    iconPosition: 'left',
                    renderWithoutFade: true,
                    hideTooltipAmount: isCurrency(reward.type),
                }}
                onRender={onRender}
            />
            <ChanceLabel chance={reward.probabilityDisplayed} />
        </div>
    );
};

export default AutoDescriptionDefaultItem;
