import * as React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Account from '~/account/Account';
import { State } from '~/Reducers';
import RewardPopup from '~/components/Popups/Reward/RewardPopup';
import { markRewardAsViewed } from '~/Actions/ActionAccount';
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary';

interface RewardsManagerState {
    rewards: IReward[];
    isVisible: boolean;
}

const stateSelector = (state: State): RewardsManagerState => {
    return {
        rewards: state.ReducerAccount.rewards?.rewards,
        isVisible: state.ReducerAccount.rewards?.isVisible,
    };
};

const RewardPopupManager = () => {
    const dispatch = useDispatch();
    const state = useSelector<State, RewardsManagerState>(stateSelector, shallowEqual);

    if (!Account.getAccount() || !state.isVisible || !state.rewards?.length) {
        return null;
    }

    const onClose = () => {
        dispatch(markRewardAsViewed(state.rewards[0]));
    };

    const reward = state.rewards[0];
    if (!reward) {
        return null;
    }

    return (
        <ErrorBoundary>
            <RewardPopup key={reward.id} onClose={onClose} {...reward} />
        </ErrorBoundary>
    );
};

export default RewardPopupManager;
