import Account from '~/account/Account';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { LocalStorageHelper } from '~/utils/storage';
import { DWH_EVENTS } from '~/const';
import dwhExport from '~/api/dwhExport';
import { isEmptyObject, isInGameBrowser } from '~/utils/utils';
import { isSceneCategory } from '../scene';

export const isEnabledSoundsByCategory = (category?: ICategory | undefined) => {
    if (category?.videoBackground?.hasSound) {
        return true;
    }

    return !isEmptyObject(category?.audioTrack || category?.parentCategoryData?.audioTrack);
};

export const isEnabledSoundsByBundle = (bundle: IBundle) => {
    if (bundle?.videoBackground?.hasSound) {
        return true;
    }
    return !isEmptyObject(bundle?.audioTrack);
};

export const isEnabledSoundsByBundleFullscreenGallery = (bundle: IBundle) => {
    if (bundle?.isFullscreenGallery && bundle.gallery?.find((s) => s.video?.hasSound)) {
        return true;
    }
    return false;
};

export const getSoundKeyForCurrentPage = (category?: ICategory, bundle?: IBundle) => {
    if (isSceneCategory(category)) {
        return null;
    }

    if (isEnabledSoundsByBundle(bundle)) {
        return bundle.name;
    }

    if (isEnabledSoundsByCategory(category)) {
        return !isEmptyObject(category?.audioTrack) ? category.name : category.parentCategory;
    }

    if (isEnabledSoundsByBundleFullscreenGallery(bundle)) {
        return `gallery_${bundle.name}`;
    }

    return null;
};

export const isEnabledSoundsForCurrentPage = (category: ICategory, bundle: IBundle) => {
    return isEnabledSoundsByCategory(category) || isEnabledSoundsByBundle(bundle);
};

export const isAvailableSoundByKey = (soundKey: string) => {
    return LocalStorageHelper.get(Account.getLSKey(`audio:${soundKey}`)) !== false;
};

export const updateSoundValueByKey = (soundKey: string, isEnabled: boolean) => {
    LocalStorageHelper.set(Account.getLSKey(`audio:${soundKey}`), isEnabled);
};

export const hasSoundSettingByCategory = (soundKey: string) => {
    if (isMobileOrTabletDeviceByAgent()) {
        return true;
    }

    return isAvailableSoundByKey(soundKey);
};

export const changeVolumeSoundByKey = (soundKey: string, isEnabled: boolean, isBundle = false) => {
    updateSoundValueByKey(soundKey, isEnabled);
    dwhExport.send(DWH_EVENTS.CHANGE_SOUND_STATUS, {
        [!isBundle ? 'category_name' : 'bundle_name']: soundKey,
        status: isEnabled ? 'on' : 'off',
    });
};

export const isEnabledSounds = (soundKey: string) => {
    if (isMobileOrTabletDeviceByAgent()) {
        return false;
    }

    return isAvailableSoundByKey(soundKey);
};

export const getDefaultValueBySoundKey = (soundKey: string, isTrusted: boolean) => {
    if (!soundKey || (!isInGameBrowser && !isTrusted)) {
        return false;
    }

    return isEnabledSounds(soundKey);
};

export function isEnabledAudioContext() {
    return new AudioContext().state === 'running';
}
