import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './AuctionLotPage.scss';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { ICurrentPage } from '~/Actions/ActionAppType';
import classNames from 'classnames';

interface IAuctionBackgroundState {
    currentPage: ICurrentPage;
    activeBackgroundLotUrl4K: string;
    activeBackgroundLotUrl: string;
    activeBackgroundColorLot: string;
    activeAuctions: IAuction[];
    zoomLotBackground: boolean;
}

const stateSelector = (state: State): IAuctionBackgroundState => {
    return {
        currentPage: state.ReducerApp.currentPage,
        activeBackgroundLotUrl4K: state.ReducerAuction.activeBackgroundLotUrl4K,
        activeBackgroundLotUrl: state.ReducerAuction.activeBackgroundLotUrl,
        activeBackgroundColorLot: state.ReducerAuction.activeBackgroundColorLot,
        activeAuctions: state.ReducerAuction.activeAuctions,
        zoomLotBackground: state.ReducerAuction.zoomLotBackground,
    };
};

const AuctionBackground = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const state = useSelector<State, IAuctionBackgroundState>(stateSelector, equal);
    const lot = state.activeAuctions[0]?.lots?.find((lot) => lot.id === state.currentPage?.lotId);

    if (!state.activeBackgroundLotUrl) {
        return null;
    }

    return (
        <div
            className={classNames(styles.auctionBackground, {
                [styles.lotWithFeatures]: !lot?.disableShipFeatures,
                [styles.art4k]: !!state.activeBackgroundLotUrl4K,
                [styles.zoomLotBackground]: state.zoomLotBackground,
            })}
            ref={ref}
            style={
                {
                    backgroundImage: `url(${state.activeBackgroundLotUrl})`,
                    backgroundColor: state.activeBackgroundColorLot || 'initial',
                    '--lot-background-4k': `url(${state.activeBackgroundLotUrl4K})`,
                    '--lot-default-background': `url(${state.activeBackgroundLotUrl})`,
                } as React.CSSProperties
            }
        />
    );
};

export default AuctionBackground;
