export enum LotWidth {
    DEFAULT = 'default',
    HALF = 'half',
    FULL = 'full',
}

export enum BID_STATUSES {
    PLACED = 'placed',
    PLACING = 'placing',
    PLACING_ERROR = 'placing_error',

    ACTIVE = 'active',

    WON = 'won',
    WON_SENDING_REWARD = 'sending_reward',
    WON_REWARD_SENT = 'reward_sent',
    WON_REWARD_SENDING_ERROR = 'reward_sending_error',

    LOST = 'lost',
    LOST_MONEY_RETURNING = 'compensation',
    LOST_MONEY_RETURNED = 'successfully_compensated',
    LOST_MONEY_RETURNING_ERROR = 'compensation_error',
    LOST_MINIMAL_WON_BID_TIME = 'minimal_won_bid_time',

    RAISED = 'raised',
    CANCELED = 'canceled',

    CANCELING = 'canceling',
    CANCELING_ERROR = 'canceling_error',
}

declare global {
    type LOT_PROMO_TYPE = 'default' | 'landing';

    interface ILot {
        id: string;
        auctionId: string;
        displayId: string;
        title: string;
        denyTitleModification: boolean;
        description: string | string[];
        type: string;
        winnersCount: number;
        promoType: LOT_PROMO_TYPE;
        width: LotWidth;
        hintBid?: AuctionLotBid;
        minimalBid: AuctionLotBid;
        maxBidAmount: number;
        startedAt: string;
        finishedAt: string;
        previewImageUrl: string;
        backgroundColor: string;
        previewImageUrl4K: string;
        gridImageUrl: string;
        purchasePreviewImageUrl: string;
        previewVideoUrl: string;
        previewClientVideoName: string;
        itemsToWithdrawInvoice?: { [key: string]: any }[];
        gameRewards: IGameRewards[];
        maxUserBidsCount: number;
        currency: string;
        enablePortPreview: boolean;
        items: ILotItem[];
        disableShipFeatures?: boolean;
        imageInnerBackground: string;
        bidPlacingPopupBackground?: string;
    }

    interface ILotItem {
        type: string;
        id: number;
    }

    interface IGameRewards {
        amount: number;
        customisation: {
            ship_id: number;
            points: number;
        };
        id: number;
        type: string;
    }

    type AuctionKind = 'first_price' | 'second_price';

    interface IAuction {
        id: string;
        title?: string;
        description?: string;
        promotionStageTitle?: string;
        promotionStageDescription?: string[];
        promotionStageImageUrl?: string;
        promotionVideoUrl?: string;
        promotionType?: string;
        slug: string;
        isGlobal?: boolean;
        realm?: string;
        startedAt: string;
        finishedAt: string;
        promotionStartedAt: string;
        promotionFinishedAt: string;
        bidCancellationsAllowed: boolean;
        bidRaisingAllowed: boolean;
        bidDropAllowed: boolean;
        bidCoolDown: number;
        lots?: ILot[];
        backgroundUrl: string;
        backgroundImg: string;
        kind?: AuctionKind;
        status: AUCTION_STATUSES;
        staffAllowed: boolean;
        promotionBackgroundColor: string;
        showDescriptionInPriceTooltip?: boolean;

        // custom fields
        isNeedToShowPromoPage?: boolean;
    }

    interface IFeatureItem {
        category: Nullable<string>;
        description: Nullable<string>;
        title: Nullable<string>;
        key: Nullable<string>;
    }

    interface IFeatureCategory {
        icons: {
            default: string;
            localDefault: string;
        };
        title: Nullable<string>;
    }

    interface IFeatureCategories {
        category: Nullable<IFeatureCategory>;
        features: Nullable<IFeatureItem[]>;
    }

    // interface IFeaturesItem {
    //     id: number;
    //     mainFeatures: Nullable<IFeatureItem[]>;
    //     categories: Nullable<IFeatureCategories[]>;
    // }

    // interface IShipFeatureMap {
    //     [key: string]: IFeaturesItem;
    // }

    type AUCTION_STATUSES = 'active' | 'choosing_winners' | 'rewarding' | 'rewarding_error' | 'finished';

    interface IBids {
        placedAt: string;
        lot?: ILot;
        status?: BID_STATUSES;
        bid: AuctionLotBid;
    }

    interface IActiveBids {
        placedAt: string;
        lot?: ILot;
        id?: number;
        status?: BID_STATUSES;
        transactionId: string;
        changesCount: number;
        bid: AuctionLotBid;
    }

    interface IAuctionAccountCompletedAction {
        actionId: string;
        completedAt: string;
        id: number;
    }

    interface IAuctionAccountState {
        placedBids: IBids[];
        activeBids: IActiveBids[];
        transactions: string[];
        completedActions: IAuctionAccountCompletedAction[];
        uniqueLotsInInventory: string[];
        isStaff: boolean;
    }

    type AuctionLotBid = {
        currency: string;
        amount: number;
    };

    interface IAuctionMinimalWonBids {
        lotId: string;
        auctionId: string;
        bid: AuctionLotBid;
    }

    interface IResponsePreloadedState {
        activeAuctions: IAuction[];
        account: IAuctionAccountState | null;
        minimalWonBids: {
            lotId: string;
            auctionId: string;
            bid: AuctionLotBid;
        }[];
    }

    interface IResponseAccountUpdateState {
        account: IAuctionAccountState;
    }

    type IRewardCategory = 'AUCTION_WON' | 'AUCTION_DEV';
}

export default global;
