import * as React from 'react';
import styles from './PurchaseBlock.scss';
import { t } from '~/utils/localization';
import PurchaseMethod from '~/components/WSMart/PurchaseBlock/PurchaseMethod';
import { ClientPaymentMethod } from '@wg/wows-commerce/api/platform/web/webPreparePurchase';
import { State } from '~/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { BillingAddress } from '@wg/wows-commerce/api/user';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { DWH_EVENTS as WOWS_COMMERCE_DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents';
import classNames from 'classnames';
import useDelegationClickForLinkHandler from '~/hooks/useDelegationClickForLinkHandler';
import RefManager, { RefManagerKeys } from '~/RefManager';

interface IStateSelector {
    fastGoldProductWrappers: WsmartGoldProductWrapper[];
    paymentMethod: ClientPaymentMethod;
    currentProductId: string;
    billingAddressSettings: BillingAddress;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        paymentMethod: state.ReducerWSMart.paymentMethod,
        currentProductId: state.ReducerWSMart.currentProductId,
        fastGoldProductWrappers: state.ReducerWSMart.fastGoldProducts,
        billingAddressSettings: state.ReducerWSMart.billingAddressSettings,
    };
};

const PurchaseBlock = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const managementUrlRef = React.useRef<HTMLAnchorElement>();
    const dispatch = useDispatch();
    const billingAddress = state.billingAddressSettings;
    const selectedProduct = !!state.currentProductId && state.fastGoldProductWrappers.find((productWrapper) => productWrapper.product.id === state.currentProductId)?.product;

    const changePaymentMethod = async (event?: React.MouseEvent) => {
        if (!selectedProduct) return;
        const wsmartPurchaseProcessor = new WSMartPurchaseProcessor(selectedProduct, WoWsCommerceStorefrontNames.FASTGOLD);
        await dispatch<IChangeVisiblePopup>(changeVisiblePopup(POPUPS_NAME.WSMART_DOUBLOONS_POPUP));
        event?.stopPropagation();
        await wsmartPurchaseProcessor.buy(WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE, selectedProduct?.quantity);
    };

    useDelegationClickForLinkHandler(managementUrlRef);

    return (
        <div className={styles.purchaseWrapper}>
            <div className={styles.purchaseMethods}>
                {state.paymentMethod && (
                    <>
                        <div className={styles.purchaseTitle}>{t('Способ оплаты')}</div>
                        <div className={styles.purchaseMethod}>
                            <PurchaseMethod paymentMethod={state.paymentMethod} />
                            <div className={styles.purchaseMethodChange} onClick={changePaymentMethod}>
                                {t('Оплатить другим методом')}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {billingAddress?.mandatory && (
                <div
                    className={styles.purchaseBilling}
                    ref={(_ref) => {
                        RefManager.setRef(RefManagerKeys.BillingAddress, _ref);
                    }}
                >
                    <div className={styles.billingTitle}>{t('Billing address')}</div>
                    <div className={styles.billingAddress}>
                        {billingAddress.address && (
                            <span
                                className={styles.address}
                            >{`${billingAddress.address.streetAddress}, ${billingAddress.address.city}, ${billingAddress.address.postalCode}, ${billingAddress.address.country}`}</span>
                        )}
                        <a className={classNames('external-link', styles.billingAddressLink)} target="_blank" href={state.billingAddressSettings.managementUrl} ref={managementUrlRef}>
                            {billingAddress.address ? t('Edit billing address') : t('Добавить адрес для выставления счёта')}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PurchaseBlock;
