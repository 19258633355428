import * as React from 'react';
import { useDispatch } from 'react-redux';
import { t } from '~/utils/localization';
import Info from '~/components/Info/Info';
import styles from './CommonLayout.scss';
import { getAboutLootboxText } from '~/Layouts/BundlePage/helpers';
import useDelegationClickForLinkHandler from '~/hooks/useDelegationClickForLinkHandler';
import classNames from 'classnames';
import FillerLayout from '~/Layouts/Lootboxes/Views/FillerLayout/FillerLayout';
import { Interpolate } from '@wg/wows-react-uikit';
import { getShortTextAboutSavePointForTooltip, getTextAboutSavePoint } from '~/Layouts/Lootboxes/utils/texts';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';

interface ICommonLayoutFooter {
    lootbox: ILootboxStateItem;
}

interface IFooterRules extends ICommonLayoutFooter {
    onClick?: (texts: string[]) => void;
}

interface IFooterLootboxDescription extends ICommonLayoutFooter {
    autoDescriptionSettings: ILootboxAutoDescriptionSettings;
}

const SavePointAbout = ({ lootbox, onClick }: IFooterRules) => {
    const text = getShortTextAboutSavePointForTooltip(lootbox);

    return (
        <div
            className={classNames(styles.footerText)}
            onClick={() => {
                onClick?.(text);
            }}
        >
            <Interpolate str={getTextAboutSavePoint(lootbox)} icon={<Info className={styles.footerLabelInfo} svgClassName={styles.footerLabelInfoSvg} tooltip={{ text }} />} />
        </div>
    );
};

const LootboxProbabilityAbout = ({ lootbox, onClick }: IFooterRules) => {
    const texts = [
        t(
            'Содержимое контейнера определяется случайным образом при его открытии, исходя из указанной выше вероятности. Заданная вероятность не означает, что открыв определённое количество контейнеров, вы гарантированно получите желаемое содержимое.',
        ),
    ];

    if (lootbox.hasValuableRewards) {
        texts.push(t('Вероятность выпадения конкретной группы не зависит от того, сколько имущества из этой группы у вас есть.'));
    }

    return (
        <div
            className={classNames(styles.footerText)}
            onClick={() => {
                onClick?.(texts);
            }}
        >
            <Interpolate
                str={t('В процентах указана вероятность получения каждой группы предметов из одного контейнера. %(icon)s')}
                icon={<Info className={styles.footerLabelInfo} tooltip={{ text: texts }} />}
            />
        </div>
    );
};

const CommonLayoutFooter = ({ lootbox, autoDescriptionSettings }: IFooterLootboxDescription) => {
    const dispatch = useDispatch();
    const refFooterAboutLootboxText = React.useRef<HTMLDivElement>(null);

    useDelegationClickForLinkHandler(refFooterAboutLootboxText);

    const showTooltipPopup = (contents: string[]) => {
        if (!isMobileOrTabletDeviceByAgent()) {
            return;
        }
        dispatch<IChangeVisiblePopup>(
            changeVisiblePopup(POPUPS_NAME.MOBILE_TOOLTIP_POPUP, true, {
                contents,
            }),
        );
    };

    return (
        <div className={styles.footerWrapper}>
            <LootboxProbabilityAbout lootbox={lootbox} onClick={showTooltipPopup} />
            <FillerLayout lootbox={lootbox} />
            {!autoDescriptionSettings?.isDroprateHidden && (lootbox.savePoint || lootbox.savePointForValuableGroup?.savePoint) && <SavePointAbout lootbox={lootbox} onClick={showTooltipPopup} />}
            <div
                className={styles.footerAboutLootboxText}
                dangerouslySetInnerHTML={{ __html: getAboutLootboxText() }}
                ref={(_ref) => {
                    if (_ref) {
                        refFooterAboutLootboxText.current = _ref;
                    }
                }}
            />
        </div>
    );
};

export default CommonLayoutFooter;
