import { Crew, Currency, Doll, Item, Nation, Permoflages, Vehicle, VehicleType } from '~/vortex';
import { ITEM_DATA } from '~/core/VortexDataStorage';

class Mock {
    getClassMock(): VehicleType {
        return {
            icons: {
                default: null,
            },
            name: '',
            title: '',
        };
    }

    getCrewsMock(): Crew {
        return {
            icons: [
                {
                    default: '',
                },
            ],
            id: null,
            name: null,
            title: null,
            nation: {
                title: null,
                name: null,
            },
            isUnique: false,
            unlocks: [],
            skills: [],
            talents: [],
            category: null,
        };
    }

    getCurrencyMock(): Currency {
        return {
            description: null,
            title: null,
            icons: {
                default: null,
                small: null,
            },
            name: null,
        };
    }

    getDollMock(): Doll {
        return {
            icons: {
                default: null,
                large: null,
                medium: null,
                small: null,
            },
            name: null,
            title: null,
            id: null,
        };
    }

    getItemMock(): Item {
        return {
            description: null,
            name: null,
            title: null,
            icons: {
                default: null,
            },
            tags: [],
            ttc: [],
            type: null,
        };
    }

    getNationMock(): Nation {
        return {
            title: null,
            name: null,
            icons: {
                small: null,
                medium: null,
                large: null,
                default: null,
            },
        };
    }

    getPermoflagesMock(): Permoflages {
        return {
            description: null,
            icons: {
                small: null,
                medium: null,
                large: null,
                default: null,
            },
            name: null,
            title: null,
        };
    }

    getVehicleMock(): Vehicle {
        return {
            icons: {
                small: null,
                medium: null,
                large: null,
                default: null,
            },
            level: null,
            modernizations: [],
            title: null,
            description: null,
            ttc: [],
            nation: {
                icons: {
                    small: null,
                    medium: null,
                    large: null,
                    default: null,
                },
                name: null,
                title: null,
            },
            type: {
                icons: {
                    default: null,
                },
                name: null,
                title: null,
            },
            complexity: {
                level: null,
                header: null,
                description: null,
                icons: {
                    filled: null,
                    empty: null,
                    localFilled: null,
                    localEmpty: null,
                },
            },
        };
    }

    getMockByType(type: string): ITEM_DATA {
        switch (type) {
            case 'class':
                return this.getClassMock();

            case 'crews':
                return this.getCrewsMock();

            case 'currency':
                return this.getCurrencyMock();

            case 'dolls':
            case 'dogTagComponents':
                return this.getDollMock();

            case 'items':
                return this.getItemMock();

            case 'nation':
                return this.getNationMock();

            case 'permoflages':
                return this.getPermoflagesMock();

            case 'vehicles':
                return this.getVehicleMock();
        }
    }
}

export default new Mock();
