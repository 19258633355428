import * as React from 'react';
import styles from './Quantity.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import { playButtonClickSound, playInputSound } from '~/api/WoWsClient';
import { KEYS_CODE } from '~/hooks/useKeyDown';
import { isMobileOrTabletWindow } from '~/utils/utils';

export interface IQuantityTooltips {
    plusButton?: React.ReactNode;
    lockedPlusButton?: React.ReactNode;
    minusButton?: React.ReactNode;
    lockedMinusButton?: React.ReactNode;
    mainTooltip?: React.ReactNode;
}

interface IQuantity {
    isDisabled?: boolean;
    tooltips?: IQuantityTooltips;
    value: number;
    isEditable?: boolean;
    step?: number;
    min?: number;
    max: number;
    checkAbilityIncrement?: (value: number) => boolean;
    isAllowedFirstIncrement: boolean;
    onUpdate: (value: number) => void;
}

const Quantity = (props: IQuantity) => {
    const ref: React.RefObject<HTMLInputElement> = React.useRef();
    const [value, setValue]: any = React.useState(props.value || 1);

    React.useEffect(() => {
        setValue(props.value || 1);
    }, [props.value]);

    const plus = () => {
        if (props.isDisabled) {
            return;
        }

        playButtonClickSound();

        let val = parseInt(value || 0, 10) + 1;

        if (val > props.max) {
            val = props.max;
        }

        updateValue(val);
    };

    const minus = () => {
        const val = Math.max(parseInt(value || 0, 10) - 1, 1);

        if (val === value) {
            return;
        }

        playButtonClickSound();

        updateValue(val);
    };

    const keyUp = (event?: React.KeyboardEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        input.value = input.value.replace(/\D/g, '');

        updateValue(input.value);
    };

    const keyDown = (event?: React.KeyboardEvent<HTMLInputElement>) => {
        if ([KEYS_CODE.UP, KEYS_CODE.DOWN].includes(event.keyCode)) {
            event.preventDefault();

            if (KEYS_CODE.UP === event.keyCode) {
                plus();
            } else if (KEYS_CODE.DOWN === event.keyCode) {
                minus();
            }
        } else {
            if (KEYS_CODE.ESC !== event.keyCode && event.key !== 'Unidentified') {
                playInputSound();
            }
        }
    };

    const onBlur = (event?: React.FocusEvent<HTMLInputElement>) => {
        if (!event.target.value.length) {
            updateValue(1);
        }
    };

    const updateValue = (value: number | string) => {
        if (props.isDisabled) {
            return;
        }

        const strValue = value.toString();
        if (!strValue.length) {
            setValue(strValue);
            return;
        }

        const intValue = Math.max(Math.min(parseInt(strValue, 10), props.max), 1);

        if (props.checkAbilityIncrement && !props.checkAbilityIncrement(intValue)) {
            return;
        }

        props.onUpdate(intValue);
        setValue(intValue);
    };

    const wrapperClassNames = classNames(styles.wrapper, {
        [styles.disabled]: props.isDisabled,
    });

    let inputValue = value;
    if (inputValue.toString().length && props.step > 1) {
        inputValue *= props.step;
    }

    const isLockMinusButton = !inputValue || +inputValue <= 1 * props.step;
    const buttonMinusClassNames = classNames(styles.button, styles.minus, {
        [styles.lock]: isLockMinusButton,
    });

    const isLockPlusButton = !props.isAllowedFirstIncrement || (props.checkAbilityIncrement && !props.checkAbilityIncrement(value + 1)) || +inputValue === props.max;
    const buttonPlusClassNames = classNames(styles.button, styles.plus, {
        [styles.lock]: isLockPlusButton,
    });

    const tooltipPropsForMinusButton: Record<string, React.ReactNode> = {};
    if (props.tooltips?.lockedMinusButton || props.tooltips?.minusButton) {
        if (isLockMinusButton && props.tooltips?.lockedMinusButton) {
            tooltipPropsForMinusButton['tooltipBody'] = props.tooltips?.lockedMinusButton;
        } else if (props.tooltips?.minusButton) {
            tooltipPropsForMinusButton['tooltipBody'] = props.tooltips?.minusButton;
        }
    }

    const tooltipPropsForPlusButton: Record<string, React.ReactNode> = {};
    if (props.tooltips?.lockedPlusButton || props.tooltips?.plusButton) {
        if (isLockPlusButton && props.tooltips?.lockedPlusButton) {
            tooltipPropsForPlusButton['tooltipBody'] = props.tooltips?.lockedPlusButton;
        } else if (props.tooltips?.plusButton) {
            tooltipPropsForPlusButton['tooltipBody'] = props.tooltips?.plusButton;
        }
    }

    let isDisableInput = false;
    if (isLockPlusButton && isLockMinusButton) {
        isDisableInput = true;
    }

    return (
        <DivTooltip tooltipBody={props.tooltips?.mainTooltip}>
            <div className={wrapperClassNames}>
                <DivTooltip className={buttonMinusClassNames} onClick={() => !isLockMinusButton && minus()} {...tooltipPropsForMinusButton} />
                <input
                    // @ts-ignore
                    // eslint-disable-next-line react/no-unknown-property
                    noimesupport={'true'}
                    type="text"
                    onInput={keyUp}
                    onKeyDown={keyDown}
                    onBlur={onBlur}
                    className={styles.value}
                    value={inputValue}
                    disabled={isDisableInput || !props.isEditable}
                    onMouseDown={() => false}
                    autoFocus={!isMobileOrTabletWindow}
                    onPaste={() => false}
                    ref={ref}
                />
                <DivTooltip className={buttonPlusClassNames} onClick={() => !isLockPlusButton && plus()} {...tooltipPropsForPlusButton} />
            </div>
        </DivTooltip>
    );
};

export default Quantity;
