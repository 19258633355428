import store from '~/Store';
import { getUserId, settings, armoryState } from '~/utils/settings';
import { accountUpdateState, deniedBundleByUniqueItems, purchaseRandomBundle, removeBundleInTransaction, removePurchasingBundleId, resetCoupon } from '~/Actions/ActionAccount';
import { changeVisiblePopup, updateBundles } from '~/Actions/ActionApp';
import { hasUniqueItems } from '~/utils/bundles';
import { POPUPS_NAME } from '~/components/PopupManager';
import { openBundleById, resetCouponFiltersIfNeeded } from '~/utils/category';
import { logInfo } from '~/utils/logging';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { excludeCouponsForNotExistingCategories, prepareCouponsFromState } from '~/utils/coupons';
import { prepareBalanceFromState } from '~/utils/balance';
import { ITradeInResult, ITradeList, TradeInStatus } from '~/types/tradein';
import { clearState, setTradeInLists, setTs } from '~/Actions/ActionTradeIn';
import { findTarget, markItemsAsUnAvailableAfterTransaction } from '~/utils/tradein';

export const ACCOUNT_STATE_UPDATE = 'ACCOUNT_STATE_UPDATE';
export const PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
export const PURCHASE_ERROR = 'PURCHASE_ERROR';
export const PURCHASE_RANDOM_BUNDLE_SUCCESS = 'PURCHASE_RANDOM_BUNDLE_SUCCESS';
export const RANDOM_BUNDLE_SELECTED = 'RANDOM_BUNDLE_SELECTED';
export const PURCHASE_SERIAL_BUNDLES_SUCCESS = 'PURCHASE_SERIAL_BUNDLES_SUCCESS';
export const TRADEIN_SUCCESS = 'TRADEIN_SUCCESS';
export const TRADEIN_ERROR = 'TRADEIN_ERROR';

class Socket {
    private ws: WebSocket;

    private FREEZE = 10000;

    private timeout: any;

    private countAttempt = 0;

    private maxCountAttempt = 10;

    constructor() {
        if (!getUserId()) {
            return;
        }

        this.openConnect();
    }

    openConnect(): void {
        this.ws = new WebSocket(settings.urls.ws.split('{}').join(armoryState.account.id.toString()));

        this.ws.onopen = () => {
            clearTimeout(this.timeout);
            logInfo('server socket init');
        };

        this.ws.onmessage = (message: any) => {
            const data = JSON.parse(message.data);
            this.onMessage(data);
        };

        this.ws.onclose = () => {
            if (this.countAttempt > this.maxCountAttempt) {
                clearTimeout(this.timeout);
                return;
            }
            this.tryToOpenConnect();
        };
    }

    tryToOpenConnect(): void {
        this.timeout = setTimeout(() => {
            this.countAttempt++;
            logInfo('attempt to open a socket connection');
            this.openConnect();
        }, this.FREEZE);
    }

    onMessage(message: ISocketResponse): void {
        const popupData: Partial<IPopup> = store.getState().ReducerApp.popupActive || {};
        const account = store.getState().ReducerAccount;
        const { bundles, currentPage, clientSource, categories } = store.getState().ReducerApp;
        const activePreset = store.getState().ReducerApp.activePreset || null;

        switch (message.action) {
            case ACCOUNT_STATE_UPDATE:
                if (account.ts > message.data.ts) {
                    return;
                }
                const coupons = prepareCouponsFromState(account.coupons, message.data.usedCoupons);
                store.dispatch(
                    accountUpdateState(
                        {
                            ...message.data,
                            balance: prepareBalanceFromState(message.data.balance),
                        },
                        coupons,
                    ),
                );
                store.dispatch(updateBundles(message.data.purchasedLimitedBundles, message.data.deniedBundlesByUniqueItems, coupons));
                resetCouponFiltersIfNeeded();
                break;

            case PURCHASE_ERROR:
                dwhExport.send(DWH_EVENTS.PURCHASE_ERROR, {
                    bundle_id: message?.data?.bundleId,
                });

                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.ERROR_PURCHASE, true, {
                        bundleId: message.data.bundleId,
                        errorCode: message.data.errorCode,
                    }),
                );

                store.dispatch(removeBundleInTransaction(message.data.bundleId));
                store.dispatch(removePurchasingBundleId());
                break;

            case PURCHASE_SERIAL_BUNDLES_SUCCESS:
                const bundleIds = message?.data?.bundleIds;

                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.SERIAL_SEQUENCE_SUCCESS_PURCHASE, true, {
                        bundleIds,
                    }),
                );

                if (currentPage.isBundlePage) {
                    const lastBundleId = bundleIds.at(-1);
                    if (bundles[lastBundleId]?.nextBundle) {
                        openBundleById(bundles[lastBundleId].nextBundle);
                    }
                }
                break;

            case PURCHASE_SUCCESS:
                const dwhMessage: Record<string, string | number> = {
                    bundle_id: message?.data?.bundleId,
                };
                if (activePreset) {
                    dwhMessage['from_filter_preset'] = activePreset;
                }
                if (clientSource) {
                    dwhMessage['client_source'] = clientSource;
                }
                dwhExport.send(DWH_EVENTS.PURCHASE_SUCCESS, dwhMessage);

                popupData?.data?.callback && popupData.data.callback();

                const bundle = store.getState().ReducerApp.bundles[message.data.bundleId];

                if (hasUniqueItems(bundle) && !bundle.allowCompensation) {
                    store.dispatch(deniedBundleByUniqueItems(message.data.bundleId));
                }

                if (message.data.bundleId === account.purchasingBundleId) {
                    store.dispatch(
                        changeVisiblePopup(POPUPS_NAME.SUCCESS_PURCHASE, true, {
                            bundleId: message.data.bundleId,
                            quantity: message.data.quantity,
                            closeCallback: popupData.data?.closeCallback,
                        }),
                    );
                }

                if (account.activeCouponFromBundle === message.data.bundleId) {
                    store.dispatch(resetCoupon());
                }

                store.dispatch(removePurchasingBundleId());
                break;

            case PURCHASE_RANDOM_BUNDLE_SUCCESS:
                const response = message.data as IPurchaseRandomBundleSuccess;

                dwhExport.send(DWH_EVENTS.PURCHASE_SUCCESS, {
                    bundle_id: message?.data?.bundleId,
                });

                popupData?.data?.callback && popupData.data.callback();

                const bundleIdsArray = Object.keys(response.bundleIds);
                if (bundleIdsArray.length === 1 && response.bundleIds[bundleIdsArray[0] as any] === 1) {
                    store.dispatch(
                        changeVisiblePopup(POPUPS_NAME.SUCCESS_PURCHASE, true, {
                            bundleId: bundleIdsArray[0],
                            closeCallback: popupData.data?.closeCallback,
                        }),
                    );
                } else {
                    if (bundles[message.data.parentBundleId]) {
                        store.dispatch(
                            changeVisiblePopup(POPUPS_NAME.PURCHASE_RANDOM_BUNDLE_SUCCESS, true, {
                                bundleIds: response.bundleIds,
                                parentBundleId: message.data.parentBundleId,
                                closeCallback: popupData.data?.closeCallback,
                            }),
                        );
                    }
                }

                store.dispatch(purchaseRandomBundle(message.data.parentBundleId, message.data.newSelectedBundleId));
                break;

            case TRADEIN_ERROR: {
                const target = findTarget(store.getState().ReducerTradeIn.lists, (message.data as ITradeInResult).targetId);
                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.TRADEIN_STATUS, true, {
                        status: TradeInStatus.ERROR,
                        item: target,
                    }),
                );
                break;
            }

            case TRADEIN_SUCCESS: {
                const data = message.data as ITradeInResult;
                const { lists } = store.getState().ReducerTradeIn;
                const target = findTarget(lists, data.targetId);
                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.TRADEIN_STATUS, true, {
                        status: TradeInStatus.SUCCESS,
                        item: target,
                    }),
                );
                store.dispatch(setTradeInLists(markItemsAsUnAvailableAfterTransaction(lists, data.sourceId, data.targetId)));
                store.dispatch(setTs(data.ts));
                store.dispatch(clearState());
                break;
            }

            default:
                break;
        }
    }
}

export default Socket;
