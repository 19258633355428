import * as React from 'react';
import styles from './popups.scss';
import { useDispatch } from 'react-redux';
import useClickAway from '~/hooks/useClickAway';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { interpolate, t } from '~/utils/localization';
import { armoryState } from '~/utils/settings';
import classNames from 'classnames';
import { getDefaultExchangeCurrency, getPurchaseExchangeRates } from '~/utils/bundles';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

const CurrenciesPopup = (): any => {
    const ref = React.useRef(null);
    const dispatch = useDispatch();
    const { isPopupActive } = React.useContext(PopupManagerContext);

    useClickAway(
        ref,
        () => {
            if (!isPopupActive) {
                return;
            }
            dispatch(changeVisiblePopup(null));
        },
        [isPopupActive],
    );

    const currencies = armoryState.content.currencies;
    const purchaseExchangeRates = getPurchaseExchangeRates();
    const defaultExchangeCurrency = getDefaultExchangeCurrency();
    const exchangeToCurrency = purchaseExchangeRates[`${defaultExchangeCurrency}_coal`];

    return (
        <div className={styles.popup} ref={ref}>
            <div className={styles.popupHeader}>
                <div className={styles.popupHeaderTitle}>{t('Подробнее о ресурсах')}</div>
                <div className={styles.popupHeaderDescription}>{t('Ресурсы вы можете обменять на корабли, сигналы и многое другое')}</div>
            </div>
            <div className={styles.popupContent}>
                <div className={styles.columnWrapper}>
                    {currencies.map((currency: ICurrencies) => {
                        if (!currency.description) {
                            return null;
                        }

                        const text = interpolate(
                            currency.description,
                            {
                                tag_open: `<span class="${styles.textBold} ${styles.textWhite}">`,
                                tag_close: `</span>`,
                            },
                            true,
                            false,
                        );

                        const iconClassNames = classNames(`icon-${currency.name}`, 'size-25');

                        return (
                            <div className={styles.itemText} key={currency.name}>
                                <span className={styles.assetIcon}>
                                    <span className={iconClassNames} />
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: text }} />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.popupFooter}>
                <div className={styles.popupHeaderDescription}>{t('В случае нехватки угля вам будет предложено обменять товар, дополнив недостающее количество ресурса сталью.')}</div>
                <div className={styles.tip}>
                    <span>{t('Курс стали к углю')}</span>
                    <span className={styles.exchange}>
                        <span className={`icon-${defaultExchangeCurrency} size-25`}>1</span>
                        <span>=</span>
                        <span className={`icon-coal size-25`}>{exchangeToCurrency}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CurrenciesPopup;
