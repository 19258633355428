import styles from './TreasureProgressBar.scss';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { isMobileOrTabletWindow } from '~/utils/utils';

type Props = {
    chain: TreasureChain;
    tilGuarantee: number;
    hideProgressBarInMobile?: boolean;
    showAnimation?: boolean;
    animationData?: TreasureAnimationData;
};

export default function TreasureProgressBar(props: Props) {
    const selectedChain = props.chain;
    const hideProgressBar = props.hideProgressBarInMobile && isMobileOrTabletWindow;

    const source = 100 - ((props.animationData?.from || props.tilGuarantee) / selectedChain.rotationsTillGuarantee) * 100;
    const target = useMemo(() => (props.animationData?.to ? 100 - (props.animationData?.to / selectedChain.rotationsTillGuarantee) * 100 : source), [props.showAnimation]);

    const innerStyle = classNames(styles.inner, props.showAnimation && styles.addProgress);

    if (hideProgressBar) return null;

    return (
        <div
            className={styles.wrapper}
            style={
                {
                    '--initial-width': `${source}%`,
                    '--width-change': `${props.animationData?.to === 0 ? 100 : target}%`,
                } as React.CSSProperties
            }
        >
            <div className={innerStyle} style={{ background: props.chain.color } as React.CSSProperties}></div>
        </div>
    );
}
