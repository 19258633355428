import * as React from 'react';
import styles from './Label.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';

interface ILabel {
    text?: string | React.ReactChild | number;
    isVisible?: boolean;
}

const Label = (props: ILabel) => {
    const [isVisible, setVisible] = React.useState(props.isVisible);
    const label = props.text || t('новое');

    React.useEffect(() => {
        setVisible(props.isVisible);
    }, [props.isVisible]);

    return (
        <div
            className={classNames(styles.label, {
                [styles.hidden]: !isVisible,
            })}
        >
            {label}
        </div>
    );
};

export default Label;
