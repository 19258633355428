import Account from '~/account/Account';
import { POPUPS_NAME } from '~/components/PopupManager';
import store from '~/Store';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { LocalStorageHelper } from '~/utils/storage';
import { getActivateAccountLSKey } from '~/components/Popups/DownloadGamePopup';

export const isNeedToShowDownloadGamePopup = () => Account.getAccount() && Account.isDeactivated();

export const isNeedToFirstShowDownloadGamePopup = () => {
    return !LocalStorageHelper.get(getActivateAccountLSKey()) && isNeedToShowDownloadGamePopup();
};

export const getInitialPopup = (id: number): IPopup => {
    if (isNeedToFirstShowDownloadGamePopup()) {
        return {
            id: id,
            name: POPUPS_NAME.DOWNLOAD_GAME_POPUP,
        };
    }

    return null;
};

export interface IShowDownloadGamePopupOptions {
    onClose: () => void;
}

export const showDownloadGamePopup = (options?: IShowDownloadGamePopupOptions): void => {
    store.dispatch(changeVisiblePopup(POPUPS_NAME.DOWNLOAD_GAME_POPUP, true, options));
};
