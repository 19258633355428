export const LOCAL_STORAGE_SHINY_EFFECT = 'LOCAL_STORAGE_SHINY_EFFECT_{}_{}_{}';
export const LOCAL_STORAGE_VORTEX_DB = 'VORTEX_DATA';
export const LOCAL_STORAGE_COUNT_ALL_BUNDLES = 'LOCAL_STORAGE_COUNT_ALL_BUNDLES';
export const LOCAL_STORAGE_LABELS = 'LOCAL_STORAGE_LABELS_{}';
export const LOCAL_STORAGE_ACTIVATE_ACCOUNT_POPUP = 'LOCAL_STORAGE_ACTIVATE_ACCOUNT_POPUP_{}';
export const LOCAL_STORAGE_WOWS_CLIENT_DEV = 'LOCAL_STORAGE_WOWS_CLIENT_DEV';
export const LOCAL_STORAGE_FILTER_POSITION = 'LOCAL_STORAGE_FILTER_POSITION_{}';
export const LOCAL_STORAGE_SUBMARINES_PROMO_VIDEO_SHOWN = 'LOCAL_STORAGE_SUBMARINES_PROMO_VIDEO_SHOWN_{}';
export const LOCAL_STORAGE_CATEGORIES_SOUNDS = 'LOCAL_STORAGE_CATEGORIES_SOUNDS_{}';
export const LOCAL_STORAGE_AUCTION_PREVIEW_LOT_VIDEO = 'LOCAL_STORAGE_AUCTION_PREVIEW_LOT_VIDEO_{}_{}';
export const LOCAL_STORAGE_STOREFRONT_BUNDLE_WAIT_TO_BE_ADDED_TO_INVENTORY = 'LOCAL_STORAGE_STOREFRONT_BUNDLE_WAIT_TO_BE_ADDED_TO_INVENTORY';
export const LOCAL_STORAGE_VIEWED_BUNDLE_DIALOGS = 'LOCAL_STORAGE_VIEWED_BUNDLE_DIALOGS';
export const LOCAL_STORAGE_LAST_SEEN_SCENE = 'LOCAL_STORAGE_LAST_SEEN_SCENE';
export const LOCAL_STORAGE_SCENE_ANIMATION_DISABLED_STATUS = 'LOCAL_STORAGE_SCENE_ANIMATION_DISABLED_STATUS';
