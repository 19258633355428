import * as React from 'react';
import classNames from 'classnames';
import styles from './PortPreviewButton.scss';
import { t } from '~/utils/localization';
import { playButtonClickSound } from '~/api/WoWsClient';

export enum PortPreviewTemplate {
    default = 'default',
    miniature = 'miniature',
    flat = 'flat',
}

interface IPortPreviewButton {
    className?: string;
    template?: PortPreviewTemplate;
    onClick?: () => void;
    onlyIcon?: boolean;
}

const PortPreviewButton = ({ className, onClick, template, onlyIcon }: IPortPreviewButton) => {
    const classes = classNames(
        {
            [styles.button]: !template || template === PortPreviewTemplate.default,
            [styles.miniatureButton]: template === PortPreviewTemplate.miniature,
            [styles.flat]: template === PortPreviewTemplate.flat,
        },
        className,
    );

    if (onlyIcon) {
        return (
            <div
                className={classNames(styles.iconButton, className)}
                onClick={() => {
                    playButtonClickSound();
                    onClick?.();
                }}
            />
        );
    }

    return (
        <div
            className={classes}
            onClick={() => {
                playButtonClickSound();
                onClick?.();
            }}
        >
            {t('Просмотреть в порту')}
        </div>
    );
};

export default PortPreviewButton;
