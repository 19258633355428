import * as React from 'react';
import styles from './Tooltip.scss';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items } from '@wg/wows-entities/const';

interface IShipsTooltip {
    shipIds: number[];
}

const ShipsTooltip = ({ shipIds }: IShipsTooltip): React.ReactChild | any => {
    return (
        <div className={styles.shipsTooltip}>
            {shipIds.map((id) => {
                return (
                    <div className={styles.shipItem} key={`tooltip_ship_${id}`}>
                        <WoWSEntity type={items.VEHICLES} id={id} presentation={{ renderWithoutFade: true }} />
                    </div>
                );
            })}
        </div>
    );
};

export default ShipsTooltip;
