import type { NavigateFunction, NavigateOptions, To } from 'react-router-dom';
import { createPath } from 'react-router-dom';
import { urlsMap } from '~/utils/menu';
import { scrollTop } from '~/utils/scrollTop';

interface IHistoryItem {
    url: string;
    scrollTop?: number;
}

class History {
    private historyUrls: IHistoryItem[] = [];
    private routerNavigate: NavigateFunction = () => {};

    public initRouterNavigate(navigate: NavigateFunction): void {
        if (this.routerNavigate !== navigate) this.routerNavigate = navigate;
    }

    public navigate(delta: number): void;
    public navigate(to: To, options?: NavigateOptions): void;
    public navigate(to: To | number, options?: NavigateOptions): void {
        if (typeof to === 'number') {
            this.routerNavigate(to);
        } else {
            if (options?.replace) this.replaceLast(this.createUrl(to));
            this.routerNavigate(to, options);
        }
    }

    public back(): void {
        if (this.historyUrls.length <= 1) {
            this.routerNavigate(urlsMap.home);
            return;
        }

        // Current url
        this.historyUrls.pop();
        // Prev url
        let lastUrl = this.historyUrls.pop();

        if (lastUrl && lastUrl.url.includes('port')) {
            lastUrl = this.historyUrls.pop();
        }

        if (!lastUrl) {
            return;
        }

        this.routerNavigate(lastUrl.url);

        setTimeout(() => {
            lastUrl.scrollTop && scrollTop(lastUrl.scrollTop, true);
        }, 0);
    }

    public backHome(): void {
        if (!this.hasHistory()) {
            this.routerNavigate(urlsMap.home);
        } else {
            this.back();
        }
    }

    public getHistory(): IHistoryItem[] {
        return this.historyUrls;
    }

    public hasHistory(): boolean {
        return this.historyUrls.length > 1;
    }

    public addToHistory(location: string): void {
        if (location === this.historyUrls[this.historyUrls.length - 1]?.url) {
            return;
        }

        this.historyUrls.push({
            url: location,
        });
    }

    public replaceLast(location: string): void {
        if (!this.historyUrls.length) return;
        this.historyUrls[this.historyUrls.length - 1] = {
            url: location,
        };
    }

    public createUrl(to: To): string {
        return typeof to === 'object' ? createPath(to) : to;
    }

    public setScrollTop(scrollTop: number): void {
        if (this.historyUrls.length) {
            this.historyUrls[this.historyUrls.length - 1].scrollTop = scrollTop;
        }
    }
}

export default new History();
