import { PREMIUM_TIME } from '~/const';

export const prepareBalanceFromState = (balance: IBalanceData[]) => {
    const now = +new Date() / 1000;

    const premiumCurrencies = [PREMIUM_TIME.WARSHIPS_PREMIUM, PREMIUM_TIME.GENERAL_PREMIUM];

    premiumCurrencies.forEach((name) => {
        const currency = balance.find((obj: IBalanceData) => obj.currency === name);
        if (!currency) {
            return;
        }

        currency.value -= now;

        if (currency.value < 0) {
            currency.value = 0;
        }
    });

    return balance;
};
