import React from 'react';

type CallbackType = () => void;

const useVisibilityChange = (onVisibleCallback: CallbackType, onHiddenCallback: CallbackType) => {
    React.useEffect(() => {
        const visibilitychange = () => {
            if (document.visibilityState === 'hidden') {
                onHiddenCallback();
            } else {
                onVisibleCallback();
            }
        };

        document.addEventListener('visibilitychange', visibilitychange);

        return () => {
            document.removeEventListener('visibilitychange', visibilitychange);
        };
    }, []);
};

export default useVisibilityChange;
