import * as React from 'react';
import { openUrl } from '~/utils/settings';

const useDelegationClickForLinkHandler = (ref: React.RefObject<HTMLElement>) => {
    const handleClick = (event: MouseEvent) => {
        let target = event.target as HTMLLinkElement;
        if (target.tagName.toLowerCase() !== 'a') {
            target = target.parentNode as HTMLLinkElement;
        }
        if (target.tagName.toLowerCase() === 'a') {
            event.preventDefault();
            event.stopPropagation();
            openUrl(target.href);
        }
    };

    React.useEffect(() => {
        if (!ref || !ref.current) {
            return;
        }

        ref.current.addEventListener('click', handleClick);

        return () => ref.current?.removeEventListener('click', handleClick);
    }, []);
};

export default useDelegationClickForLinkHandler;
