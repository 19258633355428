import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './AuctionBidButton.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import AuctionProcessor from '~/processors/AuctionProcessor';
import { t } from '~/utils/localization';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IProps {
    lot: ILot;
    auction?: IAuction;
    className?: string;
    isFetching?: boolean;
    isProcessing?: boolean;
}

interface IAuctionBidButtonState {
    auctionAccount: IAuctionAccountState;
}

const stateSelector = (state: State): IAuctionBidButtonState => {
    return {
        auctionAccount: state.ReducerAuction.account,
    };
};

const AuctionCancelButton = (props: IProps) => {
    const state = useSelector<State, IAuctionBidButtonState>(stateSelector, equal);
    const auctionProcessor = new AuctionProcessor(props.auction, props.lot, state.auctionAccount);
    const [isFetching, setFetching] = React.useState<boolean>(props.isFetching);

    const onClick = async () => {
        if (isFetching) {
            return;
        }
        playButtonClickSound();
        setFetching(true);
        await auctionProcessor.cancelBid();
    };

    React.useEffect(() => {
        setFetching(props.isFetching);
    }, [props.isFetching]);

    const classes = classNames(
        styles.cancelButton,
        {
            [styles.isFetching]: isFetching || props.isProcessing,
        },
        props.className,
    );

    return <DivTooltip className={classes} onClick={onClick} tooltipBody={<DefaultTooltip text={isFetching ? t('Обработка') : t('Отменить ставку')} />} />;
};

export default AuctionCancelButton;
