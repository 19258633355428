import { ReactNode, useState } from 'react';
import styles from './PromoTimer.scss';
import Countdown from 'react-countdown';
import { interpolate, ngettext, t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import * as React from 'react';
import { reloadCurrentCategoryFilter } from '~/utils/category';
import PromoTimerTooltip from '~/components/Bundle/PromoTimer/PromoTimerTooltip';

interface CountDownRenderer {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
}

interface PromoTimerProps {
    children: ReactNode;
    bundle: IBundle;
    activeClassName?: string;
    onComplete?: () => void;
}

export default function PromoTimer(props: PromoTimerProps) {
    const promoTimer = props.bundle.promoTimer;
    const [isCompleted, setIsCompleted] = useState(false);
    const [interval, setInterval] = useState(1000);

    if (!promoTimer?.timerActiveTill || isCompleted) return props.children;

    function timerRenderer({ days, hours, minutes, completed }: CountDownRenderer) {
        if (completed && !isCompleted) {
            setIsCompleted(true);
            reloadCurrentCategoryFilter();
            props.onComplete?.();
        }

        let str;
        let cropMinutes = false;
        if (days >= 2) {
            str = interpolate(ngettext('{days} день', '{days} дня', days), { days });
            setInterval(1000 * 60 * 60);
        } else if (days >= 1) {
            str = interpolate(ngettext('{days} день', '{days} дня', days), { days });
            if (hours) str += ' ' + interpolate(ngettext('{count} час', '{count} часа', hours), { count: hours });
            setInterval(1000 * 60 * 60);
        } else if (hours >= 1 || minutes == 59) {
            if (!hours) {
                hours = 1;
                cropMinutes = true;
            } // when 0 hours and 59 minutes it becomes 60 minutes, we want to show 1 hour in that case
            str = interpolate(ngettext('{count} час', '{count} часа', hours), { count: hours });
            if (minutes && !cropMinutes) str += ' ' + interpolate(ngettext('{count} минута.', '{count} минуты.', minutes), { count: minutes });
            setInterval(1000 * 60);
        } else {
            minutes += 1; // to avoid 0 minutes without seconds. For 59 + 1 = 60 fix is above
            str = interpolate(ngettext('{count} минута.', '{count} минуты.', minutes), { count: minutes });
            setInterval(1000 * 30);
        }

        str = str.replace(/\.$/, '');

        return <span className={styles.time}>{str}</span>;
    }

    const tooltipBody = <PromoTimerTooltip bundle={props.bundle} />;

    return (
        <DivTooltip className={props.activeClassName} tooltipBody={tooltipBody}>
            {props.children}
            <div className={styles.inner}>
                <p className={styles.title}>{promoTimer.timerTitle || t('Будет доступен через:')}</p>
                <Countdown date={promoTimer.timerActiveTill} renderer={timerRenderer} intervalDelay={interval} />
            </div>
        </DivTooltip>
    );
}
