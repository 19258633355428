import * as React from 'react';
import styles from '~/Layouts/Layouts.scss';
import OfferMark from '~/components/OfferMark/OfferMark';
import { getPurchaseLimit, hasUniqueItems } from '~/utils/bundles';
import PriceWrapper, { PRICE_WRAPPER_PLACE } from '~/components/PriceWrapper/PriceWrapper';
import PurchaseButton from '~/components/Button/PurchaseButton';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import { BundlePurchaseTypes } from '~/types/bundle';
import RealPurchaseButton from '~/components/Button/RealPurchaseButton';

interface IBundleFooterContent {
    primaryItem: IBundleEntity;
    bundle: IBundle;
    isFreeBundle: boolean;
}

const BundleFooterContent = ({ primaryItem, bundle, isFreeBundle }: IBundleFooterContent) => {
    const isNeededToShownTimer = !!bundle.activityCountdown?.isEnabled && bundle.promoTimerActiveTill && new Date(bundle.activityCountdown?.activeFrom).getTime() <= new Date().getTime();
    const hasLabels = isNeededToShownTimer || bundle.limitedQuantity || bundle.promoLabel;
    const isBundleForRealCurrency = bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY;

    const descriptionClassNames = classNames(styles.itemDescription, {
        [styles.purchased]: bundle.isPurchased,
        [styles.transform]: !bundle.isPurchased,
    });

    const onmousemove = (event: React.MouseEvent) => {
        event.stopPropagation();
        window.tooltipProvider?.hide();
    };

    return (
        <div className={descriptionClassNames}>
            {!bundle.isPurchased ? (
                <React.Fragment>
                    {!!hasLabels && (
                        <div className={styles.mark}>
                            <OfferMark
                                limit={getPurchaseLimit(bundle)}
                                label={bundle.promoLabel}
                                expiredTime={isNeededToShownTimer && bundle.promoTimerActiveTill}
                                isNeedToHiddenTimer={bundle.originalPrice && bundle.showDiscountLabel}
                                isNeedToHiddenLimit={hasUniqueItems(bundle) || (isNeededToShownTimer && bundle.originalPrice && bundle.showDiscountLabel)}
                            />
                        </div>
                    )}
                    <div className={styles.bundlePackFooter}>
                        {!bundle.originalProduct?.isVariablePriceProduct && (
                            <div className={styles.priceWrapper}>
                                <PriceWrapper
                                    bundle={bundle}
                                    key={`price_${bundle.categories.join('_')}_${primaryItem?.identifier || bundle.id}`}
                                    place={PRICE_WRAPPER_PLACE.LAYOUT}
                                    discountClassName={styles.discountWrapper}
                                    useInitialPrice
                                    withoutAnimation
                                />
                            </div>
                        )}
                        <div className={styles.buttonWrapper}>
                            {isBundleForRealCurrency ? (
                                <RealPurchaseButton
                                    bundle={bundle}
                                    label={isFreeBundle ? t('Получить') : t('Купить')}
                                    buttonClassName={['armory__auto--category_button_purchase', styles.purchaseButton]}
                                />
                            ) : (
                                <PurchaseButton
                                    bundle={bundle}
                                    label={isFreeBundle ? t('Получить') : t('Купить')}
                                    buttonClassName={['armory__auto--category_button_purchase', styles.purchaseButton]}
                                    isForceRenderPurchaseButton={true}
                                    onMouseMove={onmousemove}
                                />
                            )}
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className={styles.footer}>
                    <PriceWrapper
                        bundle={bundle}
                        key={`price_${bundle.categories.join('_')}_${primaryItem?.identifier || bundle.id}`}
                        place={PRICE_WRAPPER_PLACE.LAYOUT}
                        useInitialPrice
                        withoutAnimation
                    />
                </div>
            )}
        </div>
    );
};

export default BundleFooterContent;
