import Account from '~/account/Account';
import { authorizationRequired } from '~/decorators/ts';

class WelcomePageHelper {
    @authorizationRequired
    public isNeedToShownStep(step: string) {
        return !Account.getCompletedActions().includes(step);
    }
}

export default new WelcomePageHelper();
