import { getCDNUrl, getStaticPathFromClientOrWeb } from '~/utils/utils';
import { CATEGORIES } from '~/const';
import store from '~/Store';
import santaTitleImage from './images/title.png';

export const SANTA_SIZE: Record<string, string> = {
    santa_1: 'small',
    santa_2: 'big',
    santa_3: 'big',
    santa_certificate_1: 'small',
    santa_certificate_2: 'big',
    santa_certificate_3: 'big',
};

export const DEFAULT_SELECTED_INDEX_BY_GROUP: Record<string, number> = {
    santa_1: 2,
    santa_2: 2,
    santa_3: 2,
    santa_certificate_1: 1,
    santa_certificate_2: 1,
    santa_certificate_3: 1,
};

export const isSantaBundle = (bundle: IBundle): boolean => {
    const availableTypes = Object.keys(SANTA_SIZE) || [];

    return availableTypes.includes(bundle?.groupName);
};

export const SANTA_TITLE_IMAGE = santaTitleImage;

export const SANTA_BUNDLES_AUDIO_MAP: any = {
    small: getCDNUrl('armory/1211/2024_box_small_sound.mp3'),
    big: getCDNUrl('armory/1211/2024_box_big_sound.mp3'),
};

// CATEGORY PAGE BACKGROUND
export const SANTA_PAGE_BACKGROUND_VIDEO: IVideo = {
    webm: getStaticPathFromClientOrWeb({
        cdn: getCDNUrl('armory/1211/2024_background_without_gift.webm'),
        client: 'armory/ny24/2024_background_without_gift.webm',
    }),
    loopStartTime: 60,
};

export const SANTA_PAGE_BACKGROUND_VIDEO_WITH_GIFT: IVideo = {
    webm: getStaticPathFromClientOrWeb({
        cdn: getCDNUrl('armory/1211/2024_background_with_gift.webm'),
        client: 'armory/ny24/2024_background_with_gift.webm',
    }),
    loopStartTime: 60,
};

export const SANTA_CONFIRM_PURCHASE_VIDEO: IVideo = {
    webm: getStaticPathFromClientOrWeb({
        cdn: getCDNUrl('armory/1211/2024_purchase_confirmation.webm'),
        client: 'armory/ny24/2024_purchase_confirmation.webm',
    }),
};

export const SANTA_GIFT_VIDEO: IVideo = {
    webm: getStaticPathFromClientOrWeb({
        cdn: getCDNUrl('armory/1211/2024_gift.webm'),
        client: 'armory/ny24/2024_gift.webm',
    }),
};

export const SANTA_SECRET_GIFT_VIDEO: IVideo = {
    webm: getStaticPathFromClientOrWeb({
        cdn: getCDNUrl('armory/1211/2024_gift_1.webm'),
        client: 'armory/ny24/2024_gift_1.webm',
    }),
};

export const SANTA_GIFT_BANNER_VIDEO: IVideo = {
    webm: getStaticPathFromClientOrWeb({
        cdn: getCDNUrl('armory/1211/2024_gift_banner.webm'),
        client: 'armory/ny24/2024_gift_banner.webm',
    }),
};

type VideoAnimation = {
    idle: ReturnType<typeof getStaticPathFromClientOrWeb>;
    hover: ReturnType<typeof getStaticPathFromClientOrWeb>;
};

export const SANTA_BUNDLE_CARD_VIDEOS: Record<string, VideoAnimation> = {
    small: {
        idle: getStaticPathFromClientOrWeb({
            cdn: getCDNUrl('armory/1211/2024_box_small.webm'),
            client: 'armory/ny24/2024_box_small.webm',
        }),
        hover: getStaticPathFromClientOrWeb({
            cdn: getCDNUrl('armory/1211/2024_box_small_click.webm'),
            client: 'armory/ny24/2024_box_small_click.webm',
        }),
    },
    big: {
        idle: getStaticPathFromClientOrWeb({
            cdn: getCDNUrl('armory/1211/2024_box_big.webm'),
            client: 'armory/ny24/2024_box_big.webm',
        }),
        hover: getStaticPathFromClientOrWeb({
            cdn: getCDNUrl('armory/1211/2024_box_big_click.webm'),
            client: 'armory/ny24/2024_box_big_click.webm',
        }),
    },
};

export const VIDEO_BUNDLE_PREVIEW: Record<string, Record<string, IVideo>> = {
    small: {
        intro: {
            webm: getStaticPathFromClientOrWeb({
                cdn: getCDNUrl('armory/1211/2024_transition.webm'),
                client: 'armory/ny24/2024_transition.webm',
            }),
        },
    },
    big: {
        intro: {
            webm: getStaticPathFromClientOrWeb({
                cdn: getCDNUrl('armory/1211/2024_transition.webm'),
                client: 'armory/ny24/2024_transition.webm',
            }),
        },
    },
};

export const isAvailableSwitcherBetweenSantaCategories = (categoryName: string) => {
    const { categories } = store.getState().ReducerApp;
    const categoriesNames = Object.keys(categories);
    const hasBothCategories = categoriesNames?.includes(CATEGORIES.SANTA_CONTAINERS) && categoriesNames?.includes(CATEGORIES.SANTA_CERTIFICATES);
    return (categoryName === CATEGORIES.SANTA_CERTIFICATES || categoryName === CATEGORIES.SANTA_CONTAINERS) && hasBothCategories;
};

export const isCertificateShowcase = (categoryName: string) => {
    return [CATEGORIES.SANTA_CERTIFICATES, CATEGORIES.SANTA_CERTIFICATES_ONLY].includes(categoryName);
};
