import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { State } from '~/Reducers';
import { isTreasureCategoryEnabled } from '~/utils/treasure';
import { openCategoryByName } from '~/utils/category';

import styles from './TreasureChainPage.scss';
import { Processing } from '@wg/wows-react-uikit';
import store from '~/Store';
import { AnyAction } from 'redux';
import { resetCurrentMasterBox } from '~/Actions/ActionTreasure';
import TreasureInfoBlock from '~/components/TreasureChain/TreasureInfoBlock/TreasureInfoBlock';
import TreasurePurchaseBlock from '~/components/TreasureChain/TreasurePurchaseBlock/TreasurePurchaseBlock';
import { MaskWrapper, MaskWrapperType } from '~/components/MaskWrapper/MaskWrapper';
import TreasureTopPanel from '~/components/TreasureChain/TreasureTopPanel/TreasureTopPanel';
import equal from 'fast-deep-equal';

interface StateSelector {
    boxes: TreasureMasterBox[];
    currentBox?: TreasureMasterBox;
    hasPendingTransactions: boolean;
}

function stateSelector(state: State): StateSelector {
    return {
        boxes: state.ReducerTreasure.boxes,
        currentBox: state.ReducerTreasure.currentBox,
        hasPendingTransactions: !!state.ReducerTreasure.account?.pendingTransaction,
    };
}

export default function TreasureChainPage() {
    const { categoryName, id: subCategoryName } = useParams();
    const { categories } = store.getState().ReducerApp;

    const state = useSelector<State, StateSelector>((state) => stateSelector(state), equal);
    const [hasPending, setPending] = useState<boolean>(state.hasPendingTransactions);
    const isCategoryEnabled = React.useMemo(() => {
        if (isTreasureCategoryEnabled(subCategoryName)) {
            return [subCategoryName, true];
        }

        if (isTreasureCategoryEnabled(categoryName)) {
            return [categoryName, true];
        }

        return ['', false];
    }, [categoryName, subCategoryName]);

    React.useEffect(() => {
        store.dispatch(resetCurrentMasterBox(categoryName) as unknown as AnyAction);
    }, [categoryName]);

    if (!isCategoryEnabled) {
        openCategoryByName();
        return;
    }

    const getTreasureWithWrapperTag = (children: React.ReactElement): React.ReactElement => {
        const hideMask = !!subCategoryName;
        if (!hideMask) {
            return (
                <MaskWrapper type={MaskWrapperType.INNER} withoutBackgrounds maskColor={categories[categoryName]?.backgroundColor || '#013d52'}>
                    {children}
                </MaskWrapper>
            );
        }
        return <>{children}</>;
    };

    return (
        <div className={styles.wrapper}>
            {getTreasureWithWrapperTag(
                state.currentBox?.name === categoryName ? (
                    <div className={styles.background} style={{ backgroundImage: `url(${categories[categoryName].background})` }}>
                        <TreasureTopPanel />
                        <div className={styles.content}>
                            <TreasureInfoBlock className={styles.block} masterBox={state.currentBox} hasPending={hasPending} title={categories[categoryName]?.titleImage} />
                            <TreasurePurchaseBlock className={styles.block} masterBox={state.currentBox} hasPending={hasPending} setPending={setPending} />
                        </div>
                    </div>
                ) : (
                    <div className={styles.loader}>
                        <Processing isFetching isInside />
                    </div>
                ),
            )}
        </div>
    );
}
