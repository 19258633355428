import * as React from 'react';
import styles from './TabMenu.scss';
import classNames from 'classnames';
import { playTabClickSound } from '~/api/WoWsClient';

export interface ITabMenuItem {
    title: string;
    isActive: boolean;
    onClick: () => void;
}

interface ITabMenu {
    tabs: ITabMenuItem[];
}

const TabMenu = ({ tabs }: ITabMenu) => {
    return (
        <div className={styles.wrapper}>
            {tabs.map((item) => {
                return (
                    <div
                        onClick={() => {
                            playTabClickSound();
                            !item.isActive && item.onClick();
                        }}
                        className={classNames(styles.item, {
                            [styles.active]: item.isActive,
                        })}
                    >
                        {item.title}
                    </div>
                );
            })}
        </div>
    );
};

export default TabMenu;
