import { useMemo } from 'react';
import classNames from 'classnames';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { calculateProgressBarWidth, calculateRewardsSectionWidth } from '~/utils/mrps';
import MrpsProgressBarItem from '~/components/Mrps/MrpsProgressBarItem/MrpsProgressBarItem';
import { MrpsCursorInfo } from '../MrpsCursorInfo/MrpsCursorInfo';
import { MrpsMainReward } from '../MrpsMainReward/MrpsMainReward';

import styles from './MrpsProgressBar.scss';

interface MrpsProgressBarProps {
    isCompletedByUniqueRewards?: boolean;
    isSubCategoryLayout?: boolean;
}

interface StateSelector {
    event?: MrpsEventWithUpdatedRewards;
    eventState?: MrpsCurrentEventState;
}

function stateSelector(state: State): StateSelector {
    const mrpsState = state.reducerMrps;
    const eventName = mrpsState.currentEvent?.eventName;
    return {
        event: mrpsState.events.find((event) => event.name === eventName),
        eventState: mrpsState.currentEvent,
    };
}

export default function MrpsProgressBar({ isCompletedByUniqueRewards = false, isSubCategoryLayout = false }: MrpsProgressBarProps) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);
    const rewards = state.event.rewards;
    const isExchangeMenuOpen = state.eventState.data.isExchangeMenuOpen;

    const mainReward = useMemo(() => rewards.find((reward) => reward.isMainReward), [rewards]);

    const rewardsSectionWidth = useMemo(() => {
        return calculateRewardsSectionWidth(rewards);
    }, [rewards]);

    const baseProgressWidth = useMemo(() => {
        return !!isCompletedByUniqueRewards ? 100 : calculateProgressBarWidth(state.eventState.baseProgress, rewardsSectionWidth);
    }, [state.eventState.baseProgress, isCompletedByUniqueRewards, rewardsSectionWidth]);
    const additionalProgressWidth = useMemo(() => {
        return calculateProgressBarWidth(state.eventState.currentProgress, rewardsSectionWidth) - baseProgressWidth;
    }, [state.eventState.currentProgress, rewardsSectionWidth, baseProgressWidth]);

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_subCategory]: isSubCategoryLayout,
                [styles.wrapper_singleReward]: rewards.length === 1,
                [styles.wrapper_exchangeMenuOpen]: isExchangeMenuOpen === 1,
                [styles.wrapper_exchangeMenuClose]: isExchangeMenuOpen === 0,
            })}
        >
            <div className={styles.bar}>
                <div className={styles.baseProgress} style={{ width: `${baseProgressWidth}%` }} />
                <div
                    className={classNames(styles.additionalProgress, {
                        [styles.additionalProgress_full]: state.eventState.currentProgress === 100,
                        [styles.additionalProgress_none]: state.eventState.currentProgress === 0,
                    })}
                    style={{ width: `${additionalProgressWidth}%` }}
                />
                <div className={styles.cursorWrapper}>
                    <div className={styles.cursor} />
                    <MrpsCursorInfo isCompletedByUniqueRewards={isCompletedByUniqueRewards} progress={state.eventState.currentProgress} />
                </div>
                {rewards.length > 1 && (
                    <div
                        className={classNames(styles.rewards, {
                            [styles.rewards_exchangeMenuOpen]: isExchangeMenuOpen === 1,
                        })}
                    >
                        {rewards.map((reward) => {
                            return (
                                <MrpsProgressBarItem
                                    reward={reward}
                                    key={`reward_${reward.requiredProgressPoints}`}
                                    isAvailable={reward.requiredProgressPoints <= state.eventState.currentProgress}
                                    isReceived={reward.requiredProgressPoints <= state.eventState.baseProgress}
                                    width={rewardsSectionWidth[reward.requiredProgressPoints]}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
            {(isExchangeMenuOpen === 2 || isExchangeMenuOpen === 0 || (isExchangeMenuOpen === 1 && rewards.length === 1)) && (
                <div className={styles.mainReward}>
                    <MrpsMainReward reward={mainReward} />
                </div>
            )}
            {state.eventState.currentProgress !== 100 && !isCompletedByUniqueRewards && <div className={styles.finalProgress}>100%</div>}
        </div>
    );
}
