import * as React from 'react';

const useWindowResize = (callback: () => void): void => {
    React.useEffect(() => {
        window.addEventListener('resize', callback);

        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);
};

export default useWindowResize;
