import * as React from 'react';
import styles from './SignalPerks.scss';
import classNames from 'classnames';

interface ISignalPerksProps {
    signalId: number;
    signalType: string;
}

const SignalPerks = (props: ISignalPerksProps): any => {
    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.signal, styles.icon)}>
                <span>{props.signalType}</span>
            </div>
        </div>
    );
};

export default SignalPerks;
