import Account from '~/account/Account';
import { redirectToLogin, redirectToLoginForPurchase } from '~/utils/utils';
import { isNeedToShowDownloadGamePopup, showDownloadGamePopup } from '~/components/Popups/settings';
import { isBundleDisabledByPromoTimer, isFreeBundle } from '~/utils/bundles';
import { _purchase, _purchaseRandomBundle, purchaseBundleFromPopup } from '~/utils/purchase';
import store from '~/Store';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { chooseRandomBundle } from '~/api/purchase';
import { addBundleInTransaction, openRandomBundle, purchaseRandomBundle, removeBundleInTransaction } from '~/Actions/ActionAccount';

interface IBundleProcessorParams {
    popupData?: {
        bundleId?: number;
        fromCategory?: boolean;
    };
}

export default class PurchaseProcessor {
    private readonly bundle: IBundle;

    private readonly params: IBundleProcessorParams;

    constructor(bundle: IBundle, params?: IBundleProcessorParams) {
        this.bundle = bundle;
        this.params = params;
    }

    public isPurchaseAvailable(): boolean {
        if (!Account.getAccount()) {
            if (isFreeBundle(this.bundle)) {
                redirectToLogin();
            } else {
                redirectToLoginForPurchase(this.bundle.id);
            }
            return false;
        }

        if (isNeedToShowDownloadGamePopup()) {
            showDownloadGamePopup();
            return false;
        }

        return !isBundleDisabledByPromoTimer(this.bundle);
    }

    public purchasePeriodicBundle(bundle: IBundle) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        if (isFreeBundle(bundle)) {
            this.purchase(bundle);
        } else {
            purchaseBundleFromPopup(bundle, this.bundle);
        }
    }

    public purchaseRandomBundle(activeBundle: IBundle, callback?: () => void, closeCallback?: () => void, isFree?: boolean) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        _purchaseRandomBundle(activeBundle, this.bundle, callback, closeCallback, isFree);
    }

    public async chooseRandomBundle() {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        store.dispatch(addBundleInTransaction([this.bundle.id]));

        const responsePurchase = await chooseRandomBundle(this.bundle.id);

        store.dispatch(removeBundleInTransaction(this.bundle.id));
        store.dispatch(openRandomBundle(this.bundle.id));
        store.dispatch(purchaseRandomBundle(this.bundle.id, responsePurchase.id));

        return responsePurchase;
    }

    public purchase(bundle?: IBundle, callback?: () => void) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        _purchase(bundle || this.bundle, callback);
    }

    public purchaseBundleFromPopup(currentChildBundle: IBundle, callback?: () => void, closeCallback?: () => void, isFree?: boolean) {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        purchaseBundleFromPopup(currentChildBundle, this.bundle, callback, closeCallback, isFree);
    }

    public showPopup() {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        store.dispatch(changeVisiblePopup(POPUPS_NAME.CONFIRM_PURCHASE, true, this.params?.popupData || { bundleId: this.bundle.id }));
    }
}
