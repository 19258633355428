import * as React from 'react';
import { SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import styles from './WelcomePage.scss';
import { GUIDE_NAMES, IGuideStep, PLACEMENT } from '~/components/WelcomePage/steps';
import Beacon from '~/components/Beacon/Beacon';
import WelcomePageTooltip from '~/components/WelcomePage/WelcomePageTooltip';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { isInViewport } from '~/utils/dom';
import classNames from 'classnames';
import useWindowResize from '~/hooks/useWindowResize';

interface IGuide {
    step: IGuideStep;
    run: boolean;
    target: HTMLDivElement;
    onBeaconClick?: (step: IGuideStep) => void;
    onStartGuide?: (step: IGuideStep) => void;
    onFinishGuide?: (step: IGuideStep, isNotFoundTarget?: boolean) => void;
    className?: string;
    placement?: PLACEMENT;
}

interface IGuideState {
    isVisibleTooltip: boolean;
    beaconPositions: {
        top: number;
        left: number;
    };
}

export const getCoorsByElement = (target: HTMLElement, placement: PLACEMENT): { top: number; left: number } => {
    let top = 0;
    let left = 0;

    if (!target) {
        return {
            top,
            left,
        };
    }

    const targetCoords = target.getBoundingClientRect();

    switch (placement) {
        case PLACEMENT.TOP: {
            left = targetCoords.x + targetCoords.width / 2 - 28;
            top = targetCoords.top - 28;
            break;
        }
        case PLACEMENT.RIGHT: {
            left = targetCoords.x + targetCoords.width - 28;
            top = targetCoords.y + target.offsetHeight / 2 - 28;
            break;
        }
        case PLACEMENT.BOTTOM: {
            left = targetCoords.x + targetCoords.width / 2 - 28;
            top = targetCoords.y + target.offsetHeight - 28;
            break;
        }
        case PLACEMENT.LEFT: {
            left = targetCoords.x - 28;
            top = targetCoords.y + target.offsetHeight / 2 - 28;
            break;
        }
        case PLACEMENT.CENTER: {
            left = targetCoords.x + target.offsetWidth / 2 - 26;
            top = targetCoords.y + target.offsetHeight / 2 - 26;
            break;
        }
        default: {
            break;
        }
    }

    return {
        top,
        left,
    };
};

export const prepareCorrectPlacement = (step: IGuideStep, target: any) => {
    const beaconPositions = getCoorsByElement(target, step.placement);

    if (step.name === GUIDE_NAMES.guide_wallet && document.body.offsetWidth <= 1366) {
        step.placement = PLACEMENT.LEFT;
    } else if (step.name === GUIDE_NAMES.guide_filters_and_presets && step.placement === PLACEMENT.CENTER && document.body.offsetWidth <= 1366) {
        step.placement = PLACEMENT.RIGHT;
    }
};

const Guide = ({ target, step, onFinishGuide, className, placement }: IGuide) => {
    prepareCorrectPlacement(step, target);

    const [state, setState]: [IGuideState, SetStateAction<any>] = React.useState({
        isVisibleTooltip: false,
        beaconPositions: getCoorsByElement(target, placement || step.placement),
    });

    const onResize = () => {
        setState({
            ...state,
            beaconPositions: getCoorsByElement(target, placement || step.placement),
        });
    };

    const close = () => {
        onFinishGuide?.(step);
    };

    const onScroll = () => {
        if (step.closeAfterScroll) {
            close();
            return;
        }

        if (!step.checkVisibleInViewPort) {
            return;
        }

        const viewContainer = RefManager.getRef(RefManagerKeys.MainContainer);

        if (isInViewport(target, viewContainer)) {
            close();
        }
    };

    const ref = React.useRef<HTMLDivElement>(null);
    const appState = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);

    useWindowResize(onResize);

    React.useEffect(() => {
        if (!target) {
            close();
        }

        // Check layout shifts after navigation
        const coords = getCoorsByElement(target, placement || step.placement);
        if (coords.top !== state.beaconPositions.top || coords.left !== state.beaconPositions.left) {
            setState({
                ...state,
                beaconPositions: coords,
            });
        }
    }, [appState.currentPage?.name, appState.currentPage?.isBundlePage]);

    React.useEffect(() => {
        const viewContainer = RefManager.getRef(RefManagerKeys.MainContainer);
        viewContainer?.addEventListener('scroll', onScroll);

        return () => {
            viewContainer?.removeEventListener('scroll', onScroll);
        };
    }, []);

    step.beforeShown && step.beforeShown();

    return ReactDOM.createPortal(
        <div className={classNames(styles.guideTooltip, className)} ref={ref}>
            <Beacon left={state.beaconPositions.left} top={state.beaconPositions.top} />
            <WelcomePageTooltip beaconPosition={state.beaconPositions} position={step.placement || placement} closeProps={{ onClick: close }} step={step} />
        </div>,
        document.body,
    );
};

export default Guide;
