import * as React from 'react';
import styles from './WelcomePage.scss';
import classNames from 'classnames';
import { IGuideStep, PLACEMENT } from '~/components/WelcomePage/steps';
import { Popover } from '@wg/wows-react-uikit';
import { playButtonClickSound } from '~/api/WoWsClient';

export interface IWelcomePageTooltip {
    beaconPosition: {
        top: number;
        left: number;
    };
    position: PLACEMENT;
    step: IGuideStep;
    closeProps: {
        onClick: (step: IGuideStep) => void;
    };
    onCallback?: () => void;
}

const WelcomePageTooltip = ({ beaconPosition, position, step, closeProps, onCallback }: IWelcomePageTooltip) => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const [isVisible, setVisible] = React.useState(false);
    const [tooltipPosition, setTooltipPosition] = React.useState({ left: 0, top: 0 });
    position = position === PLACEMENT.CENTER ? PLACEMENT.BOTTOM : position;

    const wrapperClassNames = classNames(styles.tooltipWrapper, {
        [styles.noVisible]: !isVisible,
        [styles.top]: position === PLACEMENT.BOTTOM,
        [styles.bottom]: position === PLACEMENT.TOP,
        [styles.left]: position === PLACEMENT.RIGHT,
        [styles.right]: position === PLACEMENT.LEFT,
    });

    React.useEffect(() => {
        const wrapper = ref.current;
        let top,
            left = 0;

        if (position === PLACEMENT.BOTTOM) {
            top = beaconPosition.top + 22;
            left = beaconPosition.left - wrapper.offsetWidth / 2 + 26;
        } else if (position === PLACEMENT.RIGHT) {
            top = beaconPosition.top - wrapper.offsetHeight / 2 + 22;
            left = beaconPosition.left + 30;
        } else if (position === PLACEMENT.LEFT) {
            top = beaconPosition.top - wrapper.offsetHeight / 2 + 22;
            left = beaconPosition.left - wrapper.offsetWidth + 22;
        } else if (position === PLACEMENT.TOP) {
            top = beaconPosition.top - wrapper.offsetHeight + 22;
            left = beaconPosition.left - wrapper.offsetWidth / 2 + 26;
        }

        setTooltipPosition({ top, left });

        const timer = setTimeout(() => {
            setVisible(true);
            onCallback && onCallback();
        }, 1000);

        return () => clearTimeout(timer);
    }, [beaconPosition, position]);

    const top = tooltipPosition.top + 'px';
    const left = tooltipPosition.left + 'px';

    return (
        <div
            className={wrapperClassNames}
            style={{ top, left }}
            ref={ref}
            onClick={() => {
                if (closeProps.onClick) {
                    playButtonClickSound();
                    closeProps.onClick(step);
                }
            }}
        >
            <Popover
                className={classNames(styles.popover, 'armory__auto--popover')}
                onClose={() => {
                    // empty function
                }}
                position={position}
                isVisible={isVisible}
                header={step.title}
                content={step.content}
            />
        </div>
    );
};

export default WelcomePageTooltip;
