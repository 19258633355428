import * as React from 'react';
import styles from './BundleDescriptionSkeleton.scss';
import classNames from 'classnames';

const BundleDescriptionSkeleton = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={classNames(styles.line, styles.half)} />
        </div>
    );
};

export default BundleDescriptionSkeleton;
