import { isInGameBrowser } from '~/utils/utils';

export function preloadVideo() {
    const urls: string[] = [];

    if (isInGameBrowser) {
        return;
    }

    Promise.all(urls.map((url) => fetchAndCache(url)));

    function fetchAndCache(url: string) {
        return new Promise((resolve) => {
            const video = document.createElement('video');
            video.src = url;
            video.preload = 'auto';
            video.oncanplaythrough = () => {
                if (!window.clientCache) {
                    window.clientCache = {};
                }
                window.clientCache[url] = video;
                resolve(1);
            };
        });
    }
}
