import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip, { IDefaultTooltipClasses } from '../Tooltip/DefaultTooltip';
import { TooltipTypes } from '~/types/common';

import styles from './Clock.scss';
import React from 'react';

interface ClockProps {
    color?: string;
    wrapperClassName?: string;
    clockStyle?: React.CSSProperties;
    tooltip?: {
        title?: string;
        text?: string | (string | React.ReactChild)[];
        type?: TooltipTypes;
        classNames?: IDefaultTooltipClasses;
    };
    onClick?: () => void;
}

export function Clock({ color, wrapperClassName, clockStyle, tooltip, onClick }: ClockProps) {
    let tooltipBody = null;
    if (tooltip) {
        tooltipBody = <DefaultTooltip text={tooltip.text} title={tooltip.title} type={tooltip.type} classes={tooltip.classNames} />;
    }

    const svgStyle = color ? ({ '--timer-color': color } as React.CSSProperties) : undefined;

    return (
        <DivTooltip tooltipBody={tooltipBody} className={wrapperClassName} onClick={() => onClick?.()}>
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.clock} style={Object.assign(svgStyle || {}, clockStyle)}>
                <path d="M10 5.5V9.5H13.5V10.5H9V5.5H10Z" stroke="black" strokeOpacity="0.2" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 10C16 13.5899 13.0899 16.5 9.5 16.5C5.91015 16.5 3 13.5899 3 10C3 6.41015 5.91015 3.5 9.5 3.5C13.0899 3.5 16 6.41015 16 10ZM4.5 10.5V9.5H4.02242C4.2602 6.86143 6.36143 4.7602 9 4.52242V5H10V4.52242C12.6386 4.7602 14.7398 6.86143 14.9776 9.5H14.5V10.5H14.9776C14.7398 13.1386 12.6386 15.2398 10 15.4776V15H9V15.4776C6.36143 15.2398 4.2602 13.1386 4.02242 10.5H4.5Z"
                    stroke="black"
                    strokeOpacity="0.2"
                />
                <path d="M10 5V9.5H13.5V10.5H9V5H10Z" fill="white" fillOpacity="0.75" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 10C16 13.5899 13.0899 16.5 9.5 16.5C5.91015 16.5 3 13.5899 3 10C3 6.41015 5.91015 3.5 9.5 3.5C13.0899 3.5 16 6.41015 16 10ZM4.5 10.5V9.5H4.02242C4.2602 6.86143 6.36143 4.7602 9 4.52242V5H10V4.52242C12.6386 4.7602 14.7398 6.86143 14.9776 9.5H14.5V10.5H14.9776C14.7398 13.1386 12.6386 15.2398 10 15.4776V15H9V15.4776C6.36143 15.2398 4.2602 13.1386 4.02242 10.5H4.5Z"
                    fill="white"
                    fillOpacity="0.75"
                />
            </svg>
        </DivTooltip>
    );
}
