import * as React from 'react';
import classNames from 'classnames';
import styles from './BundleAmount.scss';

interface IBundleAmount {
    bundles: IBundle[];
    selectedBundleId: number;
    onChange: (bundle: IBundle) => void;
}

const BundleAmount = (props: IBundleAmount) => {
    return (
        <div className={styles.wrapper}>
            {props.bundles.map((bundle) => {
                let item = bundle.primaryItem;
                if (!item) {
                    item = bundle.entitlements[0];
                }

                const itemClassNames = classNames(styles.item, {
                    [styles.selected]: props.selectedBundleId === bundle.id,
                    [styles.discount]: bundle.originalPrice,
                });

                const onClick = (event: React.MouseEvent) => {
                    event.stopPropagation();
                    props.onChange?.(bundle);
                };

                return (
                    <div className={itemClassNames} key={bundle.id} onClick={onClick}>
                        <span className={styles.x}>&times;</span>
                        {item.amount}
                    </div>
                );
            })}
        </div>
    );
};

export default BundleAmount;
