import store from '~/Store';
import wowsEntities from '@wg/wows-entities';
import { EntityTypes } from '@wg/wows-entities/const';
import { toggleActiveExchangeTransaction } from '~/Actions/ActionMrps';
import { getMrpsAccountData } from '~/api/mrps';
import { ManagerData, getSectionName } from '~/core/ManagerData';
import VortexDataStorage from '~/core/VortexDataStorage';
import { settings } from './settings';
import { isEmptyObject } from './utils';

export function getProgressForResources(eventName: string, resources: MrpsResourcesSpent): number {
    const event = store.getState().reducerMrps.events?.find((event) => event.name === eventName);
    if (!event) return 0;
    return Object.keys(resources).reduce((sum, resourceName) => {
        const resourceSettings = event.currencies.find((currency) => currency.name === resourceName);
        const amount = resources[resourceName];
        return sum + countMrpsProgress(resourceSettings.baseProgressPoints, resourceSettings.amountPerPoint, amount);
    }, 0);
}

export function getMaxProgressForResource(resource: MrpsResource) {
    if (!resource.amountLimit) return Infinity;
    return countMrpsProgress(resource.baseProgressPoints, resource.amountPerPoint, resource.amountLimit);
}

export function countMrpsProgress(baseProgressPoints: number, amountPerPoint: number, amount: number) {
    return baseProgressPoints * Math.floor(amount / amountPerPoint); // TODO: check math errors
}

export async function loadAdditionalRewardDataFromVrtx(rewards: MrpsReward[]): Promise<MrpsLoadedReward[]> {
    const requests = rewards.map(async (reward): Promise<MrpsLoadedReward> => {
        const loadedMrpsReward: MrpsLoadedReward = window.structuredClone(reward);
        for (let i = 0; i < loadedMrpsReward.entitlements.length; i++) {
            const entitlement = loadedMrpsReward.entitlements[i];

            if (entitlement.type === 'slot') {
                continue;
            }

            let entitlementId: number | string;
            switch (entitlement.type) {
                case 'crew':
                    entitlementId = entitlement.crewId;
                    break;
                case 'ship':
                default:
                    entitlementId = entitlement.identifier;
            }

            try {
                let response;

                const rewardVortexData = ManagerData.getData(getSectionName(entitlement.type), entitlementId);
                if (rewardVortexData?.id) {
                    response = rewardVortexData;
                } else {
                    const responseFromDb = !!settings.version && (await wowsEntities.getDb().getItem(entitlementId.toString()));

                    if (!isEmptyObject(responseFromDb?.data || {})) {
                        response = responseFromDb.data;
                    } else {
                        response = await wowsEntities.getStorage().loadEntity(entitlement.type as EntityTypes, entitlementId);
                    }
                }

                entitlement.additionalData = {} as MrpsLoadedRewardAdditionalData;

                if (entitlement.type === 'ship') {
                    entitlement.additionalData.nationIcon = response?.nation?.icons?.tiny || response?.nation?.icons?.small || response?.nation?.icons?.large || '';
                    entitlement.additionalData.typeIcon =
                        (response?.isPremium && response?.type?.icons?.premium) || (response?.isSpecial && response?.type?.icons?.special) || response?.type?.icons?.default || '';
                    entitlement.additionalData.level = response?.level;
                    entitlement.additionalData.isPremium = response?.isPremium;
                    entitlement.additionalData.isSpecial = response?.isSpecial;
                    entitlement.additionalData.shipType = response?.type?.name || '';
                }

                if (entitlement.type === 'crew') {
                    entitlement.additionalData.shipId = entitlement.shipId;
                }

                const rewardImage = response?.icons?.large || response?.icons?.small || response?.icons?.default || '';

                entitlement.additionalData.isUnique = !!response?.isUnique;
                entitlement.additionalData.rewardImage = rewardImage;
                entitlement.additionalData.typeTitle = response?.type?.title || '';
                entitlement.additionalData.title = response?.titleShort || response?.title || '';

                if (!i) {
                    loadedMrpsReward.mainEntitlementType = entitlement.type;
                    loadedMrpsReward.mainEntitlementImage = rewardImage;
                    loadedMrpsReward.mainEntitlementAdditionalData = entitlement.additionalData;
                    loadedMrpsReward.mainEntitlementId = entitlementId;
                }

                if (Number(rewardVortexData?.id) !== entitlementId && !isEmptyObject(response)) {
                    const currentVortexStorageData = ManagerData.getAll();
                    const rewardVortexSectionName = getSectionName(entitlement.type);

                    const newVortexStorageData = {
                        ...currentVortexStorageData,
                        data: {
                            ...currentVortexStorageData.data,
                            [rewardVortexSectionName]: [...(currentVortexStorageData.data[rewardVortexSectionName] || []), { ...response }],
                        },
                    };

                    VortexDataStorage.add(newVortexStorageData);
                }
            } catch (e) {
                console.error(e);
            }
        }

        return loadedMrpsReward;
    });

    return await Promise.all(requests);
}

export function calculateRewardsSectionWidth(rewards: MrpsLoadedReward[]) {
    let percentsSum = 0;
    const nonMinWidths: Array<[string, boolean]> = [];
    let nonMinWidthSum = 0;

    const calculatedRewardsSectionWidth = rewards.reduce(
        (result, reward, index) => {
            const prevRewardProgressPoints = rewards[index - 1]?.requiredProgressPoints || 0;
            const isShip = reward.mainEntitlementType === 'ship';
            let rewardSectionWidth = reward.requiredProgressPoints - prevRewardProgressPoints;
            const keyProgress = reward.requiredProgressPoints.toString();

            if (isShip && rewardSectionWidth <= 18) {
                rewardSectionWidth = 18;
            } else if (rewardSectionWidth <= 9) {
                rewardSectionWidth = 9;
            } else {
                nonMinWidths.push([keyProgress, isShip]);
                nonMinWidthSum = nonMinWidthSum + (rewardSectionWidth - (isShip ? 18 : 9));
            }

            percentsSum += rewardSectionWidth;

            result[keyProgress] = rewardSectionWidth;

            return result;
        },
        {} as Record<string, number>,
    );

    if (percentsSum > 100) {
        const subtract = percentsSum - 100;
        const subtractPart = subtract / nonMinWidthSum;
        nonMinWidths.forEach(([keyProgress, isShip]) => {
            const prevValue = calculatedRewardsSectionWidth[keyProgress];

            calculatedRewardsSectionWidth[keyProgress] = prevValue - (prevValue - (isShip ? 18 : 9)) * subtractPart;
        });
    }

    return calculatedRewardsSectionWidth;
}

export function calculateProgressBarWidth(currentProgress: number, rewardsSectionWidth: Record<string, number>) {
    let width = 0;

    const rewardsSectionWidthEntries = Object.entries(rewardsSectionWidth);
    for (let i = 0; i < rewardsSectionWidthEntries.length; i++) {
        const intPrevRewardProgress = Number(rewardsSectionWidthEntries[i - 1]?.[0]) || 0;
        if (intPrevRewardProgress >= currentProgress) {
            break;
        }

        const [rewardProgress, rewardWidth] = rewardsSectionWidthEntries[i];
        const intRewardProgress = Number(rewardProgress);

        if (currentProgress >= intRewardProgress) {
            width += rewardWidth;
            continue;
        }

        const rewardsProgressPointsDiff = intRewardProgress - intPrevRewardProgress;
        const lastRewardProgressPointDiff = currentProgress - intPrevRewardProgress;

        width = width + (lastRewardProgressPointDiff / rewardsProgressPointsDiff) * rewardWidth;
    }

    return width;
}

export function checkMrpsPendingTransactions() {
    (async function run() {
        const mrpsAccoundData = await getMrpsAccountData();

        if (mrpsAccoundData.hasPendingTransactions) {
            setTimeout(() => {
                run();
            }, 1000);
        }

        store.dispatch(toggleActiveExchangeTransaction(mrpsAccoundData.hasPendingTransactions));
    })();
}

export function isMrpsCategory(categoryName: string): boolean {
    return store.getState().ReducerApp.categories[categoryName].type === 'mrps';
}

export function isMrpsCategoryEnabled(mrpsCategoryName: MrpsEvent['name']): boolean {
    const { ReducerApp, reducerMrps } = store.getState();

    if (!reducerMrps.events.length) {
        return false;
    }

    const mrpsCategoryData = ReducerApp.categories[mrpsCategoryName];
    if (!mrpsCategoryData) {
        return false;
    }

    const mrpsEventData = reducerMrps.events.find((event) => event.name === mrpsCategoryName);
    if (!mrpsEventData) {
        return false;
    }

    return true;
}
