import * as React from 'react';
import styles from '../common.scss';
import { t } from '~/utils/localization';

interface ISignalSubTitleContentProps {
    item: IBundleEntity;
    quantity: number;
}

export const EnsignSubTitleContent = (props: ISignalSubTitleContentProps) => {
    return (
        <div className={styles.flex}>
            <div className={styles.preBundleTitle}>{t('Памятный флаг')}</div>
        </div>
    );
};
