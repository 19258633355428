import * as React from 'react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

export default function useDisableMobileBodyScroll() {
    const isMobile = isMobileOrTabletWindow;

    React.useEffect(() => {
        if (isMobile) {
            disableBodyScroll(document.body);
        } else {
            clearAllBodyScrollLocks();
        }

        return () => {
            clearAllBodyScrollLocks();
        };
    }, [isMobile]);
}
