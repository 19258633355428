export const range = (length: number, start = 0, step = 1, handler?: (index: number) => void) => {
    function* generateRange() {
        let i = start - step;
        while (i < length - step) {
            i += step;
            yield handler ? handler(i) : i;
        }
    }

    return {
        [Symbol.iterator]: generateRange,
    };
};
