import * as React from 'react';
import styles from './AnimatedBorder.scss';

interface IAnimatedBorder {
    children: React.ReactChild | React.ReactChild[];
}

const AnimatedBorder = ({ children }: IAnimatedBorder) => {
    return (
        <div className={styles.wrapper}>
            {children}
            <div className={styles.borders}>
                <div className={styles.top} />
                <div className={styles.right} />
                <div className={styles.bottom} />
                <div className={styles.left} />
            </div>
        </div>
    );
};

export default AnimatedBorder;
