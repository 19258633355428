import * as React from 'react';
import Purchased from '../Purchased/Purchased';
import styles from './Tooltip.scss';

class IPurchasedTooltip {
    bundle: IBundle;
}

const PurchasedTooltip = ({ bundle }: IPurchasedTooltip): React.ReactChild | any => {
    return (
        <div className={styles.purchasedTooltip}>
            <Purchased bundle={bundle} />
        </div>
    );
};

export default PurchasedTooltip;
