import * as React from 'react';
import styles from './SantaPage.scss';
import { groupBundlesByName } from '~/utils/bundles';
import SantaBundle from '~/Layouts/Themes/SantaPage/SantaBundle/SantaBundle';
import SantaSecretGift from '~/Layouts/Themes/SantaPage/SantaSecretGift';
import { FEATURING_PLACEHOLDER } from '~/types/contents';
import FeaturingLayout from '~/Layouts/FeaturingLayout';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

export interface SantaPageProps {
    bundles: number[];
    category?: IMenuMapItem;
}

interface SantaPageSelector {
    featuring: IFeature[];
}

function stateSelector(state: State) {
    return {
        featuring: state.ReducerApp.featuring,
    };
}

const SantaPage = (props: SantaPageProps) => {
    const state = useSelector<State, SantaPageSelector>(stateSelector);
    const groups = groupBundlesByName(props.bundles);
    const allNames = Object.keys(groups);
    const featuringPlace = document.querySelector('.js_viewMiddle');

    return (
        <>
            <div className={styles.wrapper}>
                {featuringPlace &&
                    createPortal(
                        <div className={styles.featuringWrapper}>
                            <FeaturingLayout featuring={state.featuring} key={`${props.category.name}_featuring_main`} categoryName={props.category.name} placeholder={FEATURING_PLACEHOLDER.MAIN} />
                        </div>,
                        featuringPlace,
                    )}
                <div className={styles.content}>
                    {allNames.map((group: string, index) => {
                        return <SantaBundle key={group} groupName={group} groups={groups} isLastGroup={index === allNames.length - 1} />;
                    })}
                    <SantaSecretGift />
                </div>
            </div>
        </>
    );
};

export default SantaPage;
