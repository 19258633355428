import * as React from 'react';
import styles from './RandomBundleImage.scss';

interface IRandomBundleImage {
    bundle: IBundle;
    parentBundle: IBundle;
    imageStyles: React.CSSProperties;
    availableAmountForFullPurchase: number;
    hasRestriction: boolean;
}

const RandomBundleImage = ({ bundle, parentBundle, imageStyles, availableAmountForFullPurchase, hasRestriction }: IRandomBundleImage) => {
    const quantity = parentBundle?.quantityData?.quantity || 1;
    const icons = parentBundle.icons as RandomBundleIcons;

    if (quantity === 1) {
        return <div className={styles.defaultImage} style={imageStyles} />;
    } else if (!hasRestriction && !parentBundle.randomIsInfinite && quantity === availableAmountForFullPurchase && parentBundle.icons.big) {
        return <div className={styles.defaultImage} style={{ backgroundImage: `url(${parentBundle.icons.big})` }} />;
    } else if (icons.randomPartialPurchaseImage) {
        return <div className={styles.defaultImage} style={{ backgroundImage: `url(${icons.randomPartialPurchaseImage})` }} />;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.cube} />
        </div>
    );
};

export default RandomBundleImage;
