export function handleForceLoginRedirect(): void {
    const baseLoginUrl = window?.metashop?.settings?.urls?.loginUrl || null;
    const accountId = window?.metashop?.state?.account?.id || null;
    const forceLoginKey = 'force_login';
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get(forceLoginKey) && !accountId && !!baseLoginUrl) {
        urlParams.delete(forceLoginKey);
        const nextUrl = window.location.pathname + '?' + urlParams.toString();
        window.location.href = `${baseLoginUrl}?next=${nextUrl}`;
    }
}
