import * as React from 'react';
import styles from './PhotoViewer.scss';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode, Mousewheel, Controller } from 'swiper/modules';
import type { Swiper as SwiperInstance } from 'swiper/types';
import { SetStateAction } from 'react';

interface IPhotoViewer {
    photos: IGalleryItem[];
    autoScrolling?: boolean;
    bundleDecorations?: string[];
    className?: string;
}

interface IPhotoViewerState {
    currentImage?: number;
}

export type ScrollState = 'start' | 'end' | 'middle' | 'full';

const SCROLL_STATE = {
    START: 'start',
    END: 'end',
    MIDDLE: 'middle',
    FULL: 'full',
};

const PhotoViewer = (props: IPhotoViewer): React.ReactChild | any => {
    const [swiper, setSwiper]: [SwiperInstance, (sw: SwiperInstance) => void] = React.useState(null);
    const [bigSwiper, setBigSwiper]: [SwiperInstance, (sw: SwiperInstance) => void] = React.useState(null);
    const swiperNextButton = React.useRef<HTMLDivElement>();
    const swiperPrevButton = React.useRef<HTMLDivElement>();

    const duration = 5;
    const containerLinesWrap: React.RefObject<HTMLDivElement> = React.useRef(null);
    const wrapper: React.RefObject<HTMLDivElement> = React.useRef(null);

    const [state, setState]: [IPhotoViewerState, SetStateAction<any>] = React.useState({
        currentImage: 0,
    });

    const onClick = (index: number) => {
        if (index === state.currentImage) {
            return;
        }

        setState({
            ...state,
            currentImage: index,
        });

        bigSwiper.slideTo(index);
        bigSwiper.autoplay.stop();
    };

    React.useEffect(() => {
        swiper?.slideTo(state.currentImage);
    }, [state.currentImage]);

    const { currentImage = 0 } = state;

    const onSliderScrollProgress = (sliderState: number) => {
        if (props.photos.length <= 4) {
            return;
        }

        if (sliderState > 0 && sliderState < 1) {
            if (containerLinesWrap.current) {
                containerLinesWrap.current.style.webkitMaskImage =
                    '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgba(0, 0, 0, 0)), color-stop(0.02, rgb(0, 0, 0)), color-stop(0.98, rgb(0, 0, 0)), color-stop(1, rgba(0, 0, 0, 0)))';
            }
        } else if (sliderState === 0) {
            if (containerLinesWrap.current) {
                containerLinesWrap.current.style.webkitMaskImage =
                    '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(0, 0, 0)), color-stop(0.02, rgb(0, 0, 0)), color-stop(0.98, rgb(0, 0, 0)), color-stop(1, rgba(0, 0, 0, 0)))';
            }
        } else if (sliderState === 1) {
            if (containerLinesWrap.current) {
                containerLinesWrap.current.style.webkitMaskImage =
                    '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgba(0, 0, 0, 0)), color-stop(0.02, rgb(0, 0, 0)), color-stop(0.98, rgb(0, 0, 0)), color-stop(1, rgb(0, 0, 0)))';
            }
        } else {
            if (containerLinesWrap.current) {
                containerLinesWrap.current.style.webkitMaskImage = 'none';
            }
        }
    };

    const goNext = () => {
        if (bigSwiper) {
            bigSwiper.slideTo(bigSwiper.activeIndex + 1);
        }
    };

    const goPrev = () => {
        if (bigSwiper) {
            bigSwiper.slideTo(bigSwiper.activeIndex - 1);
        }
    };

    const setRefSwiperNextButton = (ref?: HTMLDivElement) => {
        if (ref) {
            swiperNextButton.current = ref;
        }
    };

    const setRefSwiperPrevButton = (ref?: HTMLDivElement) => {
        if (ref) {
            swiperPrevButton.current = ref;
        }
    };

    const onGalleryProgress = (scrollState: ScrollState) => {
        swiperNextButton.current?.classList.remove(styles.hidden);
        swiperPrevButton.current?.classList.remove(styles.hidden);

        switch (scrollState) {
            case SCROLL_STATE.START: {
                swiperPrevButton.current?.classList.add(styles.hidden);
                break;
            }

            case SCROLL_STATE.END: {
                swiperNextButton.current?.classList.add(styles.hidden);
                break;
            }
        }
    };

    return (
        <div className={classNames(styles.wrapper, ...(props.bundleDecorations || []), props.className)} ref={wrapper}>
            <div className={styles.currentImageWrapper}>
                <div className={styles.imageSlider}>
                    <Swiper
                        controller={{
                            control: swiper,
                        }}
                        onSwiper={setBigSwiper}
                        slidesPerView={'auto'}
                        initialSlide={0}
                        updateOnWindowResize={true}
                        fadeEffect={{
                            crossFade: true,
                        }}
                        autoplay={{
                            delay: duration * 1000,
                            disableOnInteraction: true,
                        }}
                        onProgress={function () {
                            const scrollStatus = (this.isBeginning ? SCROLL_STATE.START : this.isEnd ? SCROLL_STATE.END : SCROLL_STATE.MIDDLE) as ScrollState;
                            onGalleryProgress(scrollStatus);
                        }}
                        onSlideChange={function () {
                            setState({ currentImage: this.realIndex });
                        }}
                        modules={[Navigation, Autoplay, Controller]}
                    >
                        {props.photos.map((_, index) => {
                            const itemStyles = {
                                backgroundImage: `url(${props.photos[index].image})`,
                            };

                            return <SwiperSlide className={styles.image} style={itemStyles} key={`image_${index}`} />;
                        })}
                    </Swiper>
                    {props.photos.length > 1 && (
                        <>
                            <div className={styles.imageNextButton} onClick={goNext} ref={setRefSwiperNextButton} />
                            <div className={styles.imagePrevButton} onClick={goPrev} ref={setRefSwiperPrevButton} />
                        </>
                    )}
                </div>
            </div>
            <div className={styles.linesWrap} ref={containerLinesWrap}>
                <Swiper
                    slidesPerView={'auto'}
                    updateOnWindowResize={true}
                    freeMode={true}
                    mousewheel={true}
                    onSwiper={setSwiper}
                    noSwiping={props.photos.length <= 4}
                    navigation={{
                        nextEl: `.${styles.next}`,
                        prevEl: `.${styles.prev}`,
                    }}
                    onProgress={(swiper, progress) => onSliderScrollProgress(progress)}
                    modules={[Navigation, FreeMode, Mousewheel]}
                >
                    {props.photos.length > 1 &&
                        props.photos.map((_, index) => {
                            const itemClassNames = classNames(styles.itemImage, `photo_item_${index}`);

                            const wrapperClassNames = classNames(styles.item, {
                                [styles.isHidden]: index !== currentImage,
                            });

                            const itemStyles = {
                                backgroundImage: `url(${props.photos[index].image})`,
                            };

                            return (
                                <SwiperSlide className={wrapperClassNames} key={`photo_item_${index}`}>
                                    <div className={itemClassNames} style={itemStyles} onClick={onClick.bind(this, index)} />
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
        </div>
    );
};

export default React.memo(PhotoViewer);
