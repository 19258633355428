import * as React from 'react';
import classNames from 'classnames';
import styles from './VideoBackground.scss';
import useVisibilityChange from '~/hooks/useVisibilityChange';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { getSupportedWebmVideo, videoFormats } from '~/utils/video';
import { logError } from '~/utils/logging';

interface IVideoBackground {
    poster?: string;
    video?: IVideo;
    className?: string;
    posterClassName?: string;
    videoClassName?: string;
    muted?: boolean;
    children?: React.ReactChild[] | React.ReactChild;
    withMask?: boolean;
    withCustomMask?: boolean;
    volume?: number;
    maskClassName?: string;
    disableLoop?: boolean;
    hideStaticBackground?: boolean;
    showPosterOnTheEnd?: boolean;
    isInPopup?: boolean;
    onLoad?: () => void;
}

interface IStateSelector {
    isStartedVideo: boolean;
    isVideoEffect: boolean;
    isPopupActive: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        isStartedVideo: state.ReducerApp.isStartedVideo,
        isVideoEffect: state.ReducerApp.isVideoEffect,
        isPopupActive: !!state.ReducerApp.popupActive,
    };
};

const VideoBackground = ({
    poster,
    video,
    className,
    posterClassName,
    videoClassName,
    muted,
    children,
    withMask,
    withCustomMask,
    volume,
    maskClassName,
    disableLoop,
    hideStaticBackground,
    showPosterOnTheEnd,
    isInPopup,
    onLoad,
}: IVideoBackground) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isLoadedData, setLoadData] = React.useState(false);
    const refVideo = React.useRef<HTMLVideoElement>(null);
    const [isVideoEnded, setVideoEndStatus] = React.useState(false);
    const [videoRetries, setVideoRetry] = React.useState(0);

    const isPopupActive = state.isPopupActive;

    const classesBackground = classNames(styles.background, posterClassName, {
        [styles.withMask]: withMask || withCustomMask,
        [styles.showOnEnd]: showPosterOnTheEnd && !!poster,
        [styles.videoEnded]: isVideoEnded,
    });

    const classesVideoWrapper = classNames(styles.videoWrapper, videoClassName, {
        [styles.show]: isLoadedData,
        [styles.videoEnded]: isVideoEnded,
        [styles.hideOnEnd]: showPosterOnTheEnd && !!poster,
    });
    const backgroundStyles = poster ? { backgroundImage: `url(${poster})` } : {};
    const classesVideoBackgroundWrapper = classNames(styles.videoBackgroundWrapper, className);

    const _muted = muted === undefined || muted === null ? true : muted;
    const _volume = Number.isInteger(volume) || Number.isFloat(volume) ? volume : 1;

    const isManualLoop = video?.loopStartTime > 0;

    useVisibilityChange(
        () => {
            refVideo.current.volume = _volume;
        },
        () => {
            refVideo.current.volume = 0;
        },
    );

    React.useEffect(() => {
        if (!refVideo.current || state.isVideoEffect) return;
        if (state.isStartedVideo || (isPopupActive && !isInPopup)) {
            refVideo.current.pause();
        } else {
            refVideo.current.play();
        }
    }, [state.isStartedVideo, isPopupActive, isInPopup]);

    React.useEffect(() => {
        if (!refVideo.current) return;
        refVideo.current.volume = state.isStartedVideo ? 0 : _volume;

        refVideo.current.addEventListener('loadedmetadata', () => {
            if (!isManualLoop || !refVideo.current) return;
            refVideo.current.loop = false;
            refVideo.current.addEventListener('timeupdate', () => {
                if (refVideo.current.duration <= refVideo.current.currentTime) {
                    refVideo.current.currentTime = video.loopStartTime;
                    refVideo.current.play(); // in case it has stopped
                }
            });
        });

        refVideo.current.addEventListener('ended', () => setVideoEndStatus(true));

        const retries = 4;
        refVideo.current.addEventListener('error', async (e) => {
            if (!refVideo.current) return;
            logError('Video Background error', null, refVideo.current.error);
            if (retries <= 4) await new Promise((r) => setTimeout(r, 1000));
            setVideoRetry((r) => ++r);
        });
    }, [videoRetries]);

    const showBg = !hideStaticBackground || showPosterOnTheEnd;

    return (
        <div className={classesVideoBackgroundWrapper}>
            {showBg && <div className={classesBackground} style={backgroundStyles} />}
            {video && (
                <div className={classesVideoWrapper}>
                    <video
                        key={videoRetries}
                        className={styles.video}
                        muted={_muted}
                        loop={!isManualLoop && !disableLoop}
                        controls={false}
                        autoPlay={true}
                        preload={'metadata'}
                        ref={(_ref) => {
                            if (_ref) {
                                refVideo.current = _ref;
                            }
                        }}
                        onLoadedData={() => {
                            setLoadData(true);
                            onLoad?.();
                        }}
                    >
                        {video.webm && <source type={videoFormats.webm} src={getSupportedWebmVideo(video)} />}
                    </video>
                </div>
            )}
            {withMask && <div className={classNames(styles.videoBackgroundMask, maskClassName)} />}
            {children}
        </div>
    );
};

export default VideoBackground;
