import styles from './BackButton.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IBackButton {
    label?: string;
    onClick: () => void;
    className?: string;
}

const BackButton = ({ label, onClick, className }: IBackButton) => {
    return (
        <div
            className={classNames(styles.navigation, className)}
            onClick={() => {
                playButtonClickSound();
                onClick();
            }}
        >
            {label || t('назад')}
        </div>
    );
};

export default BackButton;
