import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './CategoryFilter.scss';
import classNames from 'classnames';
import { getFiltersInfo } from '~/settings/filtersMap';
import { getLevelFromRomanFormat, isMobileOrTabletWindow } from '~/utils/utils';
import { getCurrency } from '~/utils/currencies';
import Checkbox from '~/components/Checkbox/Checkbox';
import {
    FILTER_CREW_TYPE_NAME,
    FILTER_CREW_TYPE_VALUES,
    FILTER_CURRENCY_NAME,
    FILTER_ICONS_TYPES,
    FILTER_NATION_NAME,
    FILTER_RARITY_TYPE_NAME,
    FILTER_RARITY_TYPE_VALUES,
    FILTER_SHIP_CLASS_NAME,
    FILTER_SHIP_COMPLEXITY_NAME,
    FILTER_SHIP_TIER_NAME,
    FILTER_SIGNAL_TYPE_VALUES,
    NO_NATION_ITEM,
} from '~/const';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrenciesDiapasonFilter, changeFilter, updateCategoryByFilters } from '~/Actions/ActionApp';
import { State } from '~/Reducers';
import store from '~/Store';
import CurrencyInputsRange from '~/components/CategoryFilter/CurrencyInputsRange';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { IChangeCurrenciesDiapasonFilter, IChangeFilter } from '~/Actions/ActionAppType';
import { getAvailableFilters } from '~/utils/filters';
import { FilterPosition } from '~/types/category';

interface IFilterCurrencyRangeState {
    diapason: IBundlePricesInfo;
    bundlesInfo: IBundlePricesInfo;
    pricesInfo: IBundlePricesInfo;
    facet: IFilters;
    facetCurrenciesState: IFacetCurrenciesState;
}

const stateSelector = (state: State): IFilterCurrencyRangeState => {
    return {
        diapason: state.ReducerApp.filtersDiapasonCurrency,
        bundlesInfo: state.ReducerApp.defaultCategoryBundlesPricesInfo,
        pricesInfo: state.ReducerApp.categoryBundlesPricesInfo,
        facet: state.ReducerApp.facetState,
        facetCurrenciesState: state.ReducerApp.facetCurrenciesState,
    };
};

interface ICurrencyRangeFilter {
    categoryName: ICategoryList;
    currencyName: string;
    isDisabled: boolean;
    isChecked: boolean;
}

const CurrencyInputRangeFilter = ({ categoryName, currencyName, isDisabled, isChecked }: ICurrencyRangeFilter) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IFilterCurrencyRangeState>(stateSelector, equal);
    const bundlesInfo = state.bundlesInfo[categoryName]?.[currencyName];
    const facetCurrenciesDiapason = (state.facetCurrenciesState?.[categoryName]?.[currencyName] || {}) as Diapason_Type;
    const { min, max } = state.pricesInfo[categoryName]?.[currencyName] || { min: null, max: null };

    if (bundlesInfo.defaultMin === bundlesInfo.defaultMax) {
        return null;
    }

    const currentMin = state.diapason?.[categoryName]?.[currencyName]?.min;
    const currentMax = state.diapason?.[categoryName]?.[currencyName]?.max;

    return (
        <CurrencyInputsRange
            key={`range_${facetCurrenciesDiapason.min}_${facetCurrenciesDiapason.max}_${currentMin}_${currentMax}_${min}_${max}`}
            isChecked={isChecked}
            isDisabled={isDisabled}
            max={facetCurrenciesDiapason.max || max}
            min={facetCurrenciesDiapason.min || min}
            currentMin={currentMin}
            currentMax={currentMax}
            onFilterApply={(value) => {
                dispatch<IChangeCurrenciesDiapasonFilter>(changeCurrenciesDiapasonFilter(categoryName, currencyName, value.max, value.min));
                dispatch<IChangeFilter>(changeFilter(categoryName, FILTER_CURRENCY_NAME, currencyName));
                dispatch(updateCategoryByFilters(categoryName, FILTER_CURRENCY_NAME, currencyName));
            }}
            onChangeComplete={(value) => {
                dispatch<IChangeCurrenciesDiapasonFilter>(changeCurrenciesDiapasonFilter(categoryName, currencyName, value.max, value.min));
                dispatch(updateCategoryByFilters(categoryName, FILTER_CURRENCY_NAME, currencyName));
            }}
        />
    );
};

const prepareFiltersItems = (categoryName: ICategoryList, name: FILTER_INFO_NAME, callback: (name: string, value: string | IRange) => void, activeFiltersState: Record<string, string[]>) => {
    const reducerApp = store.getState().ReducerApp;
    const availableItems = getAvailableFilters(categoryName, name);
    const { facetState, filters } = reducerApp;

    const disabledFilters: Filters_Type = facetState?.[categoryName as keyof typeof facetState];
    const items = getFiltersInfo()[name]?.items;

    const availbleFilters: Filters_Type = filters?.[categoryName as keyof typeof filters];

    if (!items || !items.length) {
        return null;
    }

    const itemsList = items.reduce((state: any, item: any) => {
        if (!availableItems.includes(item.value)) {
            return state;
        }

        let content;
        content = name === FILTER_SHIP_TIER_NAME ? getLevelFromRomanFormat(item.value) : item.title;

        if (name === FILTER_SHIP_COMPLEXITY_NAME) {
            const complexityItem = reducerApp.complexities[item.value];

            content = (
                <DivTooltip className={styles.complexityIconsWrapper} tooltipBody={<DefaultTooltip text={complexityItem.header} />} style={{ cursor: 'pointer' }}>
                    {new Array(3).fill(0).map((_, index) => {
                        const iconStyles: React.CSSProperties = {
                            backgroundImage: `url(${index < complexityItem.level ? complexityItem.icons.filled : complexityItem.icons.empty})`,
                        };
                        return <div key={`complexity_icon_${index}`} className={styles.complexityIconWrapper} style={iconStyles} />;
                    })}
                </DivTooltip>
            );
        }

        if (name === FILTER_RARITY_TYPE_NAME || name === FILTER_CREW_TYPE_NAME) {
            let iconsMap;
            if (name === FILTER_RARITY_TYPE_NAME) {
                iconsMap = {
                    [FILTER_RARITY_TYPE_VALUES.STANDARD]: FILTER_ICONS_TYPES.STANDARD,
                    [FILTER_RARITY_TYPE_VALUES.SPECIAL]: FILTER_ICONS_TYPES.SPECIAL,
                    [FILTER_RARITY_TYPE_VALUES.RARE]: FILTER_ICONS_TYPES.RARE,
                    [FILTER_RARITY_TYPE_VALUES.ELITE]: FILTER_ICONS_TYPES.UNIQUE,
                    [FILTER_RARITY_TYPE_VALUES.LEGENDARY]: FILTER_ICONS_TYPES.LEGENDARY,
                };
            }
            if (name === FILTER_CREW_TYPE_NAME) {
                iconsMap = {
                    [FILTER_CREW_TYPE_VALUES.COMMON]: FILTER_ICONS_TYPES.STANDARD,
                    [FILTER_CREW_TYPE_VALUES.SPECIAL]: FILTER_ICONS_TYPES.SPECIAL,
                    [FILTER_CREW_TYPE_VALUES.ADVANCED]: FILTER_ICONS_TYPES.RARE,
                    [FILTER_CREW_TYPE_VALUES.ELITE]: FILTER_ICONS_TYPES.UNIQUE,
                    [FILTER_CREW_TYPE_VALUES.UNIQUE]: FILTER_ICONS_TYPES.LEGENDARY,
                };
            }
            const rarityIcons = reducerApp.serviceIcons?.ribbon;
            const iconType = iconsMap[item.value] as keyof typeof rarityIcons;
            const iconStyles = {
                backgroundImage: `url(${rarityIcons?.[iconType]})`,
            };
            content = (
                <div className={styles.rarityWrapper}>
                    <div className={styles.rarityIcon} style={iconStyles}></div>
                    <div className={styles.rarityText}>{item.title}</div>
                </div>
            );
        }

        const nationItem = reducerApp.nations[item.value];
        if (name === FILTER_NATION_NAME && nationItem) {
            const iconClassNames = classNames(styles.iconNation, {
                [styles.iconNation_noNation]: nationItem?.name === NO_NATION_ITEM.name,
            });

            const icon = nationItem.icons?.tiny || nationItem.icons?.default;

            content = (
                <DivTooltip
                    className={iconClassNames}
                    tooltipBody={<DefaultTooltip text={nationItem.title} />}
                    style={{
                        // @ts-ignore
                        ['--backgroundImage']: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        } else if (name === FILTER_SHIP_CLASS_NAME && reducerApp.vehicleTypes[item.value.toLowerCase()]) {
            const vehicleType = reducerApp.vehicleTypes[item.value.toLowerCase()];
            const icon = vehicleType.icons.default;

            content = (
                <DivTooltip
                    className={styles.iconClass}
                    tooltipBody={<DefaultTooltip text={vehicleType.title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        } else if (name === FILTER_CURRENCY_NAME) {
            const currencyConfig = getCurrency(item.value);
            const icon = currencyConfig?.icons?.default;

            content = (
                <DivTooltip
                    className={styles.currencyIcon}
                    tooltipBody={<DefaultTooltip text={currencyConfig.title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        }

        const isChecked = availbleFilters?.[name]?.includes(item.value);
        const isDisabled = disabledFilters?.[name]?.includes?.(item.value);

        const filterItemsStyles = classNames(styles.filterItem, {
            [styles.tier]: name === FILTER_SHIP_TIER_NAME,
            [styles.shipClass]: name === FILTER_SHIP_CLASS_NAME,
            [styles.nation]: name === FILTER_NATION_NAME,
            [styles.currency]: name === FILTER_CURRENCY_NAME,
            [styles.atba]: item.value === FILTER_SIGNAL_TYPE_VALUES.ATBA,
        });

        state.push(
            <div key={item.value} className={filterItemsStyles}>
                <Checkbox
                    onChange={() => {
                        callback(name, item.value);
                    }}
                    isDisabled={isDisabled}
                    isChecked={isChecked}
                    label={content}
                    className={styles.checkboxLabel}
                />
                {!isDisabled && name === FILTER_CURRENCY_NAME && <CurrencyInputRangeFilter currencyName={item.value} categoryName={categoryName} isDisabled={isDisabled} isChecked={isChecked} />}
            </div>,
        );

        return state;
    }, []);

    if (isMobileOrTabletWindow) {
        return <div className={styles.filterColumnBodyRow}>{itemsList}</div>;
    }

    const itemsColumns = [];
    let limit = 6;

    if (reducerApp.filterPosition === FilterPosition.RIGHT) {
        if (name === FILTER_SHIP_TIER_NAME) {
            limit = 3;
        } else if (name === FILTER_SHIP_CLASS_NAME) {
            limit = 2;
        } else if (name === FILTER_NATION_NAME) {
            limit = 4;
        }
    }

    const itemsAmount: number = itemsList?.length || 0;
    for (let i = 0; i < itemsAmount; i += limit) {
        const array = itemsList.slice(i, i + limit);
        itemsColumns.push(
            <div className={styles.filterColumnBodyRow} key={i}>
                {array}
            </div>,
        );
    }

    return itemsColumns;
};

export default prepareFiltersItems;
