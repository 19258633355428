import * as React from 'react';
import styles from './TradeInItemsSelector.scss';
import TradeInItemWidget from '../TradeInItemWidget/TradeInItemWidget';
import Info from '~/components/Info/Info';
import { ChooseItemFor, ITradeItem } from '~/types/tradein';
import { useDispatch } from 'react-redux';
import { chooseItemFor, selectTargetItem, selectSourceItem } from '~/Actions/ActionTradeIn';
import { TRADEIN_TEXTS, getPrice, isRareItem } from '~/utils/tradein';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { POPUPS_NAME } from '~/components/PopupManager';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import classNames from 'classnames';
import RefManager, { RefManagerKeys } from '~/RefManager';

interface ITradeInItemsSelector {
    selectedTargetItem: ITradeItem;
    selectedSourceItem: ITradeItem;
    choosenItemFor?: ChooseItemFor;
    isNotClickable?: boolean;
    hideTooltipLabels?: boolean;
    type: string;
    withoutAnimation?: boolean;
    onItemClick?: (type: ChooseItemFor) => void;
}

const TradeInItemsSelector = ({ selectedTargetItem, selectedSourceItem, choosenItemFor, isNotClickable, hideTooltipLabels, type, onItemClick, withoutAnimation }: ITradeInItemsSelector) => {
    const dispatch = useDispatch();
    const isSelectedTwoItems = !!(selectedTargetItem && selectedSourceItem);

    const showMobilePopup = (contents: string[]) => {
        if (!isMobileOrTabletDeviceByAgent()) {
            return;
        }
        dispatch(changeVisiblePopup(POPUPS_NAME.MOBILE_TOOLTIP_POPUP, true, { contents }));
    };

    const classesSourceItemWrapper = classNames(styles.itemWrapper, {
        [styles.animate]: !withoutAnimation && !!selectedSourceItem?.identifier,
    });

    const classesTargetItemWrapper = classNames(styles.itemWrapper, {
        [styles.animate]: !withoutAnimation && !!selectedTargetItem?.identifier,
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.exchangeSide}>
                <div className={styles.title}>
                    <span>{TRADEIN_TEXTS[type]?.trade.title}</span>
                    {!hideTooltipLabels && (
                        <Info
                            className={styles.infoLabel}
                            onClick={() => {
                                showMobilePopup([TRADEIN_TEXTS[type]?.trade.rule.title, TRADEIN_TEXTS[type]?.trade.rule.description]);
                            }}
                            tooltip={{
                                text: TRADEIN_TEXTS[type]?.trade.rule.description,
                                title: TRADEIN_TEXTS[type]?.trade.rule.title,
                            }}
                        />
                    )}
                </div>
                <div
                    className={classesSourceItemWrapper}
                    key={`selected_source_item_${selectedSourceItem?.identifier}`}
                    ref={(_ref) => {
                        if (_ref) {
                            RefManager.setRef(RefManagerKeys.TradeInSourceItem, _ref);
                        }
                    }}
                >
                    <TradeInItemWidget
                        className={styles.item}
                        item={selectedSourceItem}
                        isTrade={true}
                        isSelected={choosenItemFor === ChooseItemFor.TRADE}
                        isRareItem={isRareItem(selectedSourceItem?.additionalData?.tags || [])}
                        price={{ price: selectedSourceItem?.price, currency: selectedSourceItem?.currency }}
                        isNotClickable={isNotClickable}
                        onClose={() => {
                            dispatch(selectSourceItem(null));
                            dispatch(chooseItemFor(ChooseItemFor.TRADE));
                        }}
                        onClick={() => {
                            onItemClick?.(ChooseItemFor.TRADE);
                            dispatch(chooseItemFor(ChooseItemFor.TRADE));
                            if (!selectedTargetItem && !selectedSourceItem) {
                                dispatch(selectTargetItem(null));
                            }
                        }}
                    />
                </div>
            </div>
            <div className={styles.arrow} />
            <div className={styles.receiveSide}>
                <div className={styles.title}>
                    <span>{TRADEIN_TEXTS[type]?.get.title}</span>
                    {!hideTooltipLabels && (
                        <Info
                            className={styles.infoLabel}
                            onClick={() => {
                                showMobilePopup([TRADEIN_TEXTS[type]?.get.rule.title, TRADEIN_TEXTS[type]?.get.rule.description]);
                            }}
                            tooltip={{
                                text: TRADEIN_TEXTS[type]?.get.rule.description,
                                title: TRADEIN_TEXTS[type]?.get.rule.title,
                            }}
                        />
                    )}
                </div>
                <div
                    className={classesTargetItemWrapper}
                    key={`selected_target_item_${selectedTargetItem?.identifier}`}
                    ref={(_ref) => {
                        if (_ref) {
                            RefManager.setRef(RefManagerKeys.TradeInTargetItem, _ref);
                        }
                    }}
                >
                    <TradeInItemWidget
                        className={styles.item}
                        item={selectedTargetItem}
                        isSelected={choosenItemFor === ChooseItemFor.GET}
                        isNotClickable={isNotClickable}
                        price={{
                            price: !!selectedSourceItem?.price ? getPrice(selectedSourceItem?.price, selectedTargetItem?.price) : selectedTargetItem?.price,
                            oldPrice: !!selectedSourceItem?.price ? selectedTargetItem?.price : null,
                            currency: selectedTargetItem?.currency,
                        }}
                        onClose={() => {
                            dispatch(selectTargetItem(null));
                            dispatch(chooseItemFor(ChooseItemFor.GET));
                        }}
                        onClick={() => {
                            onItemClick?.(ChooseItemFor.GET);
                            dispatch(chooseItemFor(ChooseItemFor.GET));
                            if (!selectedTargetItem && !selectedSourceItem) {
                                dispatch(selectSourceItem(null));
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TradeInItemsSelector;
