import * as React from 'react';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { getShipFeatures } from '~/utils/getShipFeatures';
import MainFeaturesLayout from '~/Layouts/Features/Views/MainFeaturesLayout/MainFeaturesLayout';
import FeaturesLayout from '~/Layouts/Features/Views/FeaturesLayout/FeaturesLayout';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import Spoiler from '~/components/Spoiler/Spoiler';
import { ShipWeapons } from '~/components/ShipWeapons/ShipWeapons';
import ShipConsumables from '~/components/ShipConsumables/ShipConsumables';

import styles from '../common.scss';

interface IShipsContent {
    item: IItemCommonData;
    renderSpoiler?: boolean;
    renderFeatures?: boolean;
}

export const MainFeatures = ({ shipId, className }: { shipId: number; className?: string }) => {
    const [currentFeaturesTags, setCurrentFeaturesTags] = React.useState<IFeatureTag[] | undefined>();

    React.useLayoutEffect(() => {
        getShipFeatures(shipId).then((data) => setCurrentFeaturesTags(data.featuresTags));
    }, []);

    return currentFeaturesTags ? (
        <div className={classNames(styles.mainFeatures, className)}>
            <div className={styles.contentBlockTitle}>{t('Ключевые особенности корабля:')}</div>
            <MainFeaturesLayout shipId={shipId} featuresTags={currentFeaturesTags} className={styles.mainFeatureLine} />
        </div>
    ) : null;
};

export const AdditionalFeatures = ({ shipId, className }: { shipId: number; className?: string }) => {
    const [currentFeaturesTagsPerCategory, setCurrentFeaturesTagsPerCategory] = React.useState<IFeatureTagPerCategory[] | undefined>();

    React.useLayoutEffect(() => {
        getShipFeatures(shipId).then((data) => setCurrentFeaturesTagsPerCategory(data?.featuresTagsPerCategory));
    }, []);

    return currentFeaturesTagsPerCategory ? (
        <div className={classNames(styles.contentBlock, styles.borderBottom, className)}>
            <div className={styles.contentBlockTitle}>{t('Полный список особенностей корабля:')}</div>
            <div className={styles.contentBlockSubTitle}>{t('Основные отличия от кораблей того же класса и уровня')}</div>
            <FeaturesLayout shipId={shipId} featuresTagsPerCategory={currentFeaturesTagsPerCategory} />
        </div>
    ) : null;
};

const ShipsContent = (props: IShipsContent) => {
    const classesContent = classNames({
        [styles.contentAdditionalBlock]: props.renderSpoiler,
    });
    return (
        <div className={classesContent}>
            {props.renderSpoiler ? (
                <Spoiler
                    title={
                        <div className={styles.contentSpoilerTitle}>
                            <WoWSEntity key={props.item.identifier} type={props.item.type} id={props.item.identifier} />
                        </div>
                    }
                    content={
                        <React.Fragment>
                            <MainFeatures shipId={props.item.identifier} />
                            <AdditionalFeatures shipId={props.item.identifier} />
                            <ShipConsumables shipId={props.item.identifier} />
                        </React.Fragment>
                    }
                />
            ) : (
                <React.Fragment>
                    {props.renderFeatures && (
                        <React.Fragment>
                            <MainFeatures shipId={props.item.identifier} />
                            <AdditionalFeatures shipId={props.item.identifier} />
                        </React.Fragment>
                    )}
                    <ShipWeapons shipId={props.item.identifier} />
                    <ShipConsumables shipId={props.item.identifier} />
                </React.Fragment>
            )}
        </div>
    );
};

export default ShipsContent;
