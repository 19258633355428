import * as React from 'react';
import styles from './MainFeaturesLayout.scss';
import classNames from 'classnames';
import { getShipFeatures } from '~/utils/getShipFeatures';

//* Was decided to show only first 5 features
const FEATURES_TO_SHOW_LIMIT = 5;

interface IMainFeaturesLayout {
    shipId?: number;
    className?: string;
    featuresTags?: Array<IFeatureTag>;
    lineClamp?: boolean;
}

const MainFeaturesLayout = ({ shipId, className, featuresTags, lineClamp }: IMainFeaturesLayout) => {
    const [currentFeaturesTags, setCurrentFeaturesTags] = React.useState<IFeatureTag[] | undefined>(featuresTags);

    React.useEffect(() => {
        !featuresTags && getShipFeatures(shipId).then((data) => setCurrentFeaturesTags(data.featuresTags));
    }, [shipId, featuresTags]);

    return (
        <div className={styles.featuresWrapper}>
            {currentFeaturesTags &&
                currentFeaturesTags.slice(0, Math.min(currentFeaturesTags.length, FEATURES_TO_SHOW_LIMIT)).map((featureTag: IFeatureTag, key) => {
                    const featureCategoryIcon = {
                        backgroundImage: `url(${featureTag.icons.small}`,
                    };

                    return (
                        <div className={classNames(styles.feature, className)} key={key}>
                            <div className={styles.featureCategoryWrapper}>
                                <div style={featureCategoryIcon} className={classNames(styles.featureCategory)} />
                            </div>
                            <div className={styles.featureText}>
                                <div
                                    className={classNames(styles.featureTitle, {
                                        [styles.lineClampOne]: lineClamp && currentFeaturesTags.length > 3,
                                        [styles.lineClampTwo]: lineClamp && currentFeaturesTags.length <= 3,
                                    })}
                                >
                                    {featureTag.mark}
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default MainFeaturesLayout;
