import * as React from 'react';
import classNames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';

import { State } from '~/Reducers';
import { BlurBackgroundStatus, ICurrentPage } from '~/Actions/ActionAppType';
import { CategoryType } from '~/types/category';
import { BUNDLE_DECORATION } from '~/types/bundle';
import Account from '~/account/Account';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { arrayToObjectByKey, isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import { isDisabledCategoryVideoForBundlePage } from '~/utils/bundles';
import { getGlobalContent, settings } from '~/utils/settings';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import useArmoryBackground from '~/hooks/useArmoryBackground';
import { CATEGORIES } from '~/const';

import { ANNIVERSARY_PROMO_THEME, AUCTION_PAGE_THEME, CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import SantaBackground from '~/Layouts/Themes/SantaPage/SantaBackground';
import { CustomBackground } from '~/Layouts/Themes/CustomPage/CustomBackground/CustomBackground';
import AuctionBackground from '~/Layouts/AuctionLotPage/AuctionBackground';
import Anniversary from '~/Layouts/Themes/Anniversary/Anniversary';
import VideoViewBackground from '~/components/ViewBackground/VideoViewBackground';
import GalleryViewBackground from '~/components/ViewBackground/GalleryViewBackground';

import styles from './ViewBackground.scss';

type Theme = Nullable<ALLOWED_THEMES>;

interface IThemeBackground {
    theme: Theme;
}

const ThemeBackground = React.memo((props: IThemeBackground) => {
    switch (props.theme) {
        case SANTA_PAGE_THEME:
            return <SantaBackground />;

        case AUCTION_PAGE_THEME:
            return <AuctionBackground />;

        case ANNIVERSARY_PROMO_THEME:
            return <Anniversary />;
    }

    return null;
});

interface IViewBackground {
    isBlur?: boolean;
}

interface IStateSelector {
    mrpsAccountData?: MrpsAccountState;

    categories: ICategories;
    currentPage: ICurrentPage;
    menu: IMenuMap;

    port: IPort;

    bundles: IBundleList;
    selectedRandomBundles: AccountSelectedRandomBundles;

    viewBackground?: string;
    activeAuctions: IAuction[];
    mrpsEvents?: MrpsEvent[];

    isBlurBackground: BlurBackgroundStatus;
    isFinishedRequest: boolean;
    isFullscreen: boolean;
    isTrusted: boolean;
    zoomLotBackground: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        mrpsAccountData: state.reducerMrps.account,

        categories: state.ReducerApp.categories,
        currentPage: state.ReducerApp.currentPage,
        menu: state.ReducerApp.menu,

        port: state.ReducerApp.port,

        bundles: state.ReducerApp.bundles,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,

        viewBackground: state.ReducerApp.viewBackground,
        activeAuctions: state.ReducerAuction.activeAuctions,
        mrpsEvents: state.reducerMrps.events,

        isBlurBackground: state.ReducerApp.isBlurBackground,
        isFinishedRequest: state.ReducerApp.isFinishedRequest,
        isFullscreen: state.ReducerApp.isFullscreen,
        isTrusted: state.ReducerApp.isTrusted,
        zoomLotBackground: state.ReducerAuction.zoomLotBackground,
    };
};

const ViewBackground = (props: IViewBackground): React.ReactElement => {
    const {
        currentPage,
        categories,
        menu,
        bundles,
        viewBackground,
        port,
        isBlurBackground,
        isFinishedRequest,
        isFullscreen,
        isTrusted,
        selectedRandomBundles,
        activeAuctions,
        zoomLotBackground,
        mrpsEvents,
        mrpsAccountData,
    } = useSelector<State, IStateSelector>(stateSelector, shallowEqual);
    const category = categories?.[currentPage?.name];
    const parentCategory = categories?.[category?.parentCategory];
    const configCategory = menu?.[currentPage?.name];

    // Get current page bundle
    let bundle = bundles?.[currentPage?.bundleId];
    if (bundle && bundle.isRandom && !isEmptyObject(selectedRandomBundles)) {
        const selectedRandomBundleId = selectedRandomBundles[bundle.id];
        const randomBundlesChildren = arrayToObjectByKey(bundle.randomBundleChildren, 'id');
        if (randomBundlesChildren[selectedRandomBundleId]) {
            bundle = Account.getRandomBundleChild(selectedRandomBundles, bundle);
        }
    }
    const parentBudnle = bundles?.[bundle?.parentBundleId];

    // If bundle has video background

    /**
     * parentBundleName
     * bundleName
     * parentCategoryName
     * categoryName
     */

    let bundleVideoBackground = null;
    let hasBundleVideoBackground = false;
    let animationName = null;

    if (!isEmptyObject(parentBudnle?.videoBackground)) {
        bundleVideoBackground = parentBudnle.videoBackground;
        hasBundleVideoBackground = true;
        animationName = parentBudnle.name;
    } else if (!isEmptyObject(bundle?.videoBackground)) {
        bundleVideoBackground = bundle.videoBackground;
        hasBundleVideoBackground = true;
        animationName = bundle.name;
    }

    // Get mrps event data
    const currentMrpsEventData = category?.type === CategoryType.MRPS ? mrpsEvents?.find((event) => event.name === category?.name) : undefined;

    // If category has video background
    const hasCategoryVideoBackground = !isEmptyObject(category?.videoBackground);

    let hasVideoBackground = false;
    if (!isMobileOrTabletWindow) {
        if (bundle) {
            hasVideoBackground = hasBundleVideoBackground;

            const disableCategoryVideoBack = bundle.decoration?.includes(BUNDLE_DECORATION.DISABLE_CATEGORY_VIDEO_BACK);
            if (!disableCategoryVideoBack && !hasVideoBackground && !isDisabledCategoryVideoForBundlePage(bundle)) {
                hasVideoBackground = hasCategoryVideoBackground;
                animationName = parentCategory?.name || category?.name;
            }
        } else if (currentMrpsEventData) {
            hasVideoBackground = !isEmptyObject(currentMrpsEventData.videoBackground);
        } else {
            hasVideoBackground = hasCategoryVideoBackground;
            animationName = parentCategory?.name || category?.name;
        }
    }

    const noVideoDisable = hasBundleVideoBackground ? bundleVideoBackground.noVideoDisable : parentCategory?.videoBackground?.noVideoDisable || category?.videoBackground?.noVideoDisable;
    const _isDisabledAnimation = useIsDisabledAnimation(animationName) && !noVideoDisable;

    useArmoryBackground();

    if (port?.isVisible) return null;

    if (!isFinishedRequest) {
        return <div className={styles.background} />;
    }

    const setRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.ViewBackground, ref);
        }
    };

    // Fullscreen gallery [images or video slider]
    if (!isMobileOrTabletWindow && bundle?.isFullscreenGallery) {
        return <GalleryViewBackground isBlur={props.isBlur} bundle={bundle} category={category} />;
    }

    const theme = (category?.theme || configCategory?.theme || getGlobalContent()?.theme) as Theme;

    if (theme === SANTA_PAGE_THEME && currentPage?.isBundlePage) {
        return <div className={styles.background} />;
    }

    if (theme === CUSTOM_PAGE) {
        return <CustomBackground />;
    }

    //* If no video or animations use static back
    if (!hasVideoBackground || _isDisabledAnimation) {
        let backgroundColor = bundle?.backgroundColor || category?.backgroundColor;
        // let hiddenAuctionMask = false;
        let mrpsBackground: string;

        if (currentPage?.name === CATEGORIES.AUCTION) {
            if (currentPage?.isBundlePage) {
                let lot: ILot;
                activeAuctions.find((auction) => {
                    const currentLot = auction.lots.find((lot) => currentPage?.lotId === lot.id);
                    lot = currentLot;
                    if (lot) {
                        return currentLot;
                    }
                });
                backgroundColor = lot?.backgroundColor;
            } else {
                if (activeAuctions[0]?.isNeedToShowPromoPage) {
                    backgroundColor = activeAuctions[0]?.promotionBackgroundColor;
                }
            }
        }

        if (currentMrpsEventData) {
            mrpsBackground = ((!mrpsAccountData || mrpsAccountData?.[currentMrpsEventData.name]?.rewards?.length === 0) && currentMrpsEventData.promoBackground) || currentMrpsEventData.background;
        }

        const hasBackgroundMask = !!backgroundColor;
        const style: React.CSSProperties = {};
        // @ts-ignore
        if (hasBackgroundMask) style['--bundle-background-color'] = backgroundColor;
        if (viewBackground) style.backgroundImage = `url(${viewBackground})`;
        if (mrpsBackground) style.backgroundImage = `url(${mrpsBackground})`;

        return (
            <div
                ref={setRef}
                style={style}
                key={`background_${category?.name}`}
                className={classNames(styles.background, currentPage?.name, styles[currentPage?.name], styles[configCategory?.backgroundName], styles[settings.realm], styles[configCategory?.theme], {
                    [styles.blur]: props.isBlur,
                    [styles.blurBackground]: isBlurBackground === BlurBackgroundStatus.ON,
                    [styles.unBlurBackground]: isBlurBackground === BlurBackgroundStatus.OFF,
                    [styles.backgroundWithMask]: hasBackgroundMask,
                    [styles.isBundlePage]: currentPage?.isBundlePage,
                    [styles.isFullscreen]: isFullscreen,
                    [styles[`auction_${activeAuctions[0]?.kind}`]]: currentPage?.name === CATEGORIES.AUCTION,
                })}
            >
                <div
                    className={classNames(styles.backgroundMask, {
                        [styles.visibleMask]: hasBackgroundMask,
                    })}
                />
                {category?.backgroundMask && hasBackgroundMask && <div className={styles.backgroundCustomMask} style={{ backgroundImage: `url(${category?.backgroundMask})` }} />}
                <ThemeBackground theme={theme} key={category?.name} />
            </div>
        );
    }

    const videoBackgroundKey = hasBundleVideoBackground ? `background_${category?.name}_${bundle?.id}` : `background_${category?.videoBackground?.webm}`;

    return <VideoViewBackground isBlur={props.isBlur} key={videoBackgroundKey} bundle={bundle} category={category} />;
};

export default ViewBackground;
