import * as React from 'react';
import { assets, items as ITEMS } from '@wg/wows-entities/const';
import { getInlineShipInfo } from '~/utils/ships/ships';
import styles from './BundlePageHeader.scss';
import classNames from 'classnames';
import { isEnabledPurchaseLimitToContainer } from '~/components/BundleLimitWidget/settings';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import { BundleLimitSize } from '~/components/BundleLimitWidget/BundleLimitWidget';
import { SignalSubTitleContent } from '~/Layouts/BundlePage/Contents/SignalContent';
import { CrewsSubTitleContent } from '~/Layouts/BundlePage/Contents/CrewsContent';
import { CamouflageSubTitleContent } from '~/Layouts/BundlePage/Contents/CamouflageContent';
import { ModernizationSubTitleContent } from '~/Layouts/BundlePage/Contents/ModernizationContent';
import { DogTagSubTitleContent } from '~/Layouts/BundlePage/Contents/DogTagContent';
import { SlotContent } from '~/Layouts/BundlePage/Contents/SlotContent';
import { BoostHeaderContent } from '~/Layouts/BundlePage/Contents/BoostContent';
import ContainerAdditionalHeader from '~/Layouts/BundlePage/BundlePageHeader/ContainerAdditionalHeader';
import { EnsignSubTitleContent } from '../Contents/EnsignContent';
import { isEnabledPortByBundle, openPortForFirstBundleItem } from '~/settings/port';
import PortPreviewButton from '~/components/PortPreviewButton/PortPreviewButton';
import { ShipComplexity } from '~/components/ShipComplexity/ShipComplexity';
import { getShipFeatures } from '~/utils/getShipFeatures';

export interface IAdditionalContent {
    bundle: IBundle;
    renderPreviewContent?: boolean;
    className?: string;
    wrapperClassName?: string;
}

export const BundleLimitContainerWidget = ({ bundle, className, wrapperClassName }: IAdditionalContent) => {
    if (!isEnabledPurchaseLimitToContainer(bundle)) {
        return null;
    }

    return (
        <div className={wrapperClassName}>
            <BundleLimitContainer size={BundleLimitSize.BIG} bundle={bundle} className={classNames(styles.bundleLimitWidget, className)} />
        </div>
    );
};

const ShipAdditionalHeader = ({ bundle, renderPreviewContent }: IAdditionalContent) => {
    const ship = bundle.primaryItem;
    const shipInfo = getInlineShipInfo(ship.identifier);
    const isAvailablePort = !bundle.isPurchased && isEnabledPortByBundle(bundle, false, true);

    const [shipComplexity, setShipComplexity] = React.useState<IFeatureItemComplexity | undefined>();

    React.useLayoutEffect(() => {
        getShipFeatures(ship.identifier).then((data) => setShipComplexity(data.complexity));
    }, []);

    return (
        <div className={styles.flex}>
            {!!shipInfo && (
                <div className={styles.preBundleTitle}>
                    <div className={styles.shipInfo}>{shipInfo}</div>
                    {shipComplexity && (
                        <>
                            <div className={styles.verticalDivider} />
                            <ShipComplexity complexity={shipComplexity} isShipBundlePageSubTitle />
                        </>
                    )}
                </div>
            )}
            {renderPreviewContent && isAvailablePort && <PortPreviewButton onlyIcon onClick={() => openPortForFirstBundleItem(bundle)} />}
            <BundleLimitContainerWidget bundle={bundle} />
        </div>
    );
};

const SignalAdditionalHeader = ({ bundle }: IAdditionalContent) => {
    return <SignalSubTitleContent item={bundle.primaryItem} quantity={bundle.quantityData?.quantity || 1} />;
};

const EnsignAdditionalHeader = ({ bundle }: IAdditionalContent) => {
    return <EnsignSubTitleContent item={bundle.primaryItem} quantity={bundle.quantityData?.quantity || 1} />;
};

const CrewsAdditionalHeader = ({ bundle, renderPreviewContent }: IAdditionalContent) => {
    return <CrewsSubTitleContent item={bundle.primaryItem} renderPreviewContent={renderPreviewContent} />;
};

const CamoAdditionalHeader = ({ bundle, renderPreviewContent }: IAdditionalContent) => {
    return <CamouflageSubTitleContent item={bundle.primaryItem} quantity={bundle.quantityData?.quantity || 1} bundle={bundle} renderPreviewContent={renderPreviewContent} />;
};

const ModernizationAdditionalHeader = ({ bundle }: IAdditionalContent) => {
    return <ModernizationSubTitleContent item={bundle.primaryItem} />;
};

const DogTagAdditionalHeader = ({ bundle }: IAdditionalContent) => {
    return <DogTagSubTitleContent item={bundle.primaryItem} />;
};

const SlotAdditionalHeader = ({ bundle }: IAdditionalContent) => {
    return <SlotContent item={bundle.primaryItem} quantity={bundle.quantityData?.quantity || 1} />;
};

const BoostAdditionalHeader = ({ bundle }: IAdditionalContent) => {
    return <BoostHeaderContent item={bundle.primaryItem} quantity={bundle.quantityData?.quantity || 1} />;
};

export default function getAdditionalHeaderContent(bundle: IBundle, renderPreviewContent = false) {
    const primaryItem = bundle.primaryItem;

    switch (primaryItem?.type) {
        case ITEMS.VEHICLES:
            return <ShipAdditionalHeader bundle={bundle} renderPreviewContent={renderPreviewContent} />;

        case ITEMS.SIGNAL:
            return <SignalAdditionalHeader bundle={bundle} />;

        case ITEMS.ENSIGNS:
            return <EnsignAdditionalHeader bundle={bundle} />;

        case ITEMS.CREWS:
            return <CrewsAdditionalHeader bundle={bundle} renderPreviewContent={renderPreviewContent} />;

        case ITEMS.PERMOFLAGES:
        case ITEMS.CAMOUFLAGE:
        case ITEMS.STYLE:
        case ITEMS.SKIN:
            return <CamoAdditionalHeader bundle={bundle} renderPreviewContent={renderPreviewContent} />;

        case ITEMS.MODERNIZATION:
            return <ModernizationAdditionalHeader bundle={bundle} />;

        case ITEMS.DOG_TAG:
            return <DogTagAdditionalHeader bundle={bundle} />;

        case ITEMS.LOOTBOX:
            return <ContainerAdditionalHeader bundle={bundle} />;

        case assets.SLOTS:
        case assets.SLOT:
            return <SlotAdditionalHeader bundle={bundle} />;

        case ITEMS.GLOBAL_BOOST:
        case ITEMS.MULTI_BOOST:
        case ITEMS.CAMO_BOOST:
            return <BoostAdditionalHeader bundle={bundle} />;

        default:
            return null;
    }
}
