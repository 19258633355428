import * as React from 'react';
import styles from './CrewPoints.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import { ngettext } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import classNames from 'classnames';

interface ICrewPerks {
    points: number;
    className?: string;
}

const CrewPoints = (props: ICrewPerks) => {
    const points = props.points;
    const text = ngettext('%(count)s очко навыка', '%(count)s очков навыков', points).replace('%(count)s', String(points));

    return (
        <DivTooltip tooltipBody={<DefaultTooltip text={text} />}>
            <div className={classNames(styles.perk, props.className)}>{points}</div>
        </DivTooltip>
    );
};

export default CrewPoints;
