import * as React from 'react';
import { BundlesObserver } from '~/components/BundlesObserver/BundlesObserver';
import { randomHash } from '~/utils/utils';

export default function useIntersectionObserver(ref: React.RefObject<HTMLElement>, isVisibleAlways = false) {
    const [isVisible, toogleVisibility] = React.useState(isVisibleAlways);
    const hash = React.useRef(randomHash(10));

    React.useEffect(() => {
        if (!ref.current || isVisibleAlways) {
            return;
        }

        BundlesObserver.observe(ref.current, toogleVisibility, hash.current);

        return () => {
            BundlesObserver.unobserve(hash.current);
        };
    }, []);

    return [isVisible, hash.current];
}
