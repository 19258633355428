import store from '~/Store';
import { startVideo } from '~/Actions/ActionApp';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { isEmptyObject } from '../utils';
import { getSupportedVideo } from '~/utils/video';

export const isEnabledCategoryVideo = (category: ICategory) => {
    if (!category) {
        return false;
    }

    switch (category.theme) {
        default:
            switch (category.name) {
                default:
                    return !isEmptyObject(category?.entryVideo);
            }
    }
};

export const startCategoryVideo = (category: ICategory) => {
    if (!category) {
        return false;
    }

    dwhExport.send(DWH_EVENTS.START_VIDEO_FOR_CATEGORY, { category_name: category.name });

    switch (category.theme) {
        default:
            switch (category.name) {
                default:
                    const videoUrl = getSupportedVideo(category.entryVideo);
                    if (videoUrl) {
                        store.dispatch(startVideo(`category_video_replay_${category?.name}`, videoUrl, null, 0));
                    }
            }
    }
};
