import { Actions } from '~/Actions';
import WowsCommerce, { detectPlatform } from '@wg/wows-commerce';
import { CategoryEntity } from '@wg/wows-commerce/core/entity/category.entity';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import {
    SET_FAST_GOLD_LIB,
    SET_FAST_GOLD_PRODUCTS,
    SET_WOWS_COMMERCE_LIB,
    SET_WSMART_CURRENT_PRODUCT,
    SET_WSMART_PAYMENT_METHOD,
    SET_TSV_MANAGEMENT_URL,
    SHOP_UNAVAILABLE,
    SET_TSV,
    SET_BILLING_ADDRESS_SETTINGS,
    SET_REGIONAL_INFO,
} from '~/Actions/ActionWSMart';
import { ClientPaymentMethod, PrepareTsv } from '@wg/wows-commerce/api/platform/web/webPreparePurchase';
import { BillingAddress, RegionalInfo } from '@wg/wows-commerce/api/user';

export interface WSMartState {
    wowsCommerce: WowsCommerce;
    fastGold: WowsCommerce;
    products: ProductEntity[];
    fastGoldProducts: WsmartGoldProductWrapper[];
    fastGoldDefaultProducts: WsmartGoldProductWrapper[];
    categories: CategoryEntity[];
    platform: string;
    shopUnavailable: boolean;
    billingAddressSettings: BillingAddress;
    currentProductId: string;
    paymentMethod: ClientPaymentMethod;
    managementUrl: string;
    tsv: Nullable<PrepareTsv>;
    regional: Nullable<RegionalInfo>;
}

const initialState: WSMartState = {
    wowsCommerce: null,
    fastGold: null,
    products: [],
    categories: null,
    platform: null,
    fastGoldProducts: [],
    fastGoldDefaultProducts: [],
    shopUnavailable: false,
    currentProductId: null,
    paymentMethod: undefined,
    managementUrl: null,
    tsv: null,
    billingAddressSettings: null,
    regional: null,
};

export const ReducerWSMart = (state: WSMartState = initialState, action: Actions) => {
    switch (action.type) {
        case SET_WOWS_COMMERCE_LIB:
            return {
                ...state,
                platform: detectPlatform(),
                wowsCommerce: action.wowsCommerce,
            };

        case SET_FAST_GOLD_LIB:
            return {
                ...state,
                fastGold: action.fastGold,
            };

        case SET_FAST_GOLD_PRODUCTS: {
            return {
                ...state,
                fastGoldProducts: action.products,
                fastGoldDefaultProducts: action.defaultProducts,
            };
        }

        case SHOP_UNAVAILABLE: {
            return {
                ...state,
                shopUnavailable: true,
            };
        }

        case SET_BILLING_ADDRESS_SETTINGS:
            return {
                ...state,
                billingAddressSettings: action.settings,
            };

        case SET_WSMART_CURRENT_PRODUCT:
            return {
                ...state,
                currentProductId: action.currentProductId,
            };

        case SET_WSMART_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.paymentMethod,
            };

        case SET_TSV_MANAGEMENT_URL:
            return {
                ...state,
                managementUrl: action.managementUrl,
            };

        case SET_REGIONAL_INFO:
            return {
                ...state,
                regional: action.regional,
            };

        case SET_TSV:
            return {
                ...state,
                tsv: action.tsv,
            };

        default:
            return { ...state };
    }
};

export default ReducerWSMart;
