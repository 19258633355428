import { State } from '~/Reducers';
import { ICurrentPage } from '~/Actions/ActionAppType';

export interface IRoutingStateSelector {
    bundles: IBundleList;
    categories: ICategories;
    currentPage: ICurrentPage;
}

export const routingStateSelector = (state: State): IRoutingStateSelector => {
    return {
        bundles: state.ReducerApp.bundles,
        categories: state.ReducerApp.categories,
        currentPage: state.ReducerApp.currentPage,
    };
};
