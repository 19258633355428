import { items } from '@wg/wows-entities/const';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { settings } from '~/utils/settings';
import { interpolate, t } from '~/utils/localization';
import { getAboutLootboxText } from '~/Layouts/BundlePage/helpers';
import { CommonRewardType } from '~/types/bundle';
import Account from '~/account/Account';
import { sort } from 'fast-sort';

const LIMIT_FOR_HIDDEN_ITEMS = 6;
export const LIMIT_FOR_FEATURED = 3;

export const getDefaultGroupTitle = (index: number, isOnlyOneGroup?: boolean) => {
    if (isOnlyOneGroup) {
        return t('Группа предметов');
    }
    return interpolate(t('Группа предметов {number}'), { number: index + 1 });
};

export function getSortOrderRewardsBySlotAndGroup(config: LootboxPromotionRewardsInsideSlots = [], slotIndex: number, groupIndex: number): number[] {
    const sortOrderUnique = new Set(
        config.find((_config) => {
            return _config.groupIndex === groupIndex && _config.slotIndex === slotIndex;
        })?.sortOrder,
    );
    return [...sortOrderUnique];
}

export function getFeaturedRewardsByIndex(rewards: ILootboxReward[], featuredRewardsIds?: number[], weakLimit = LIMIT_FOR_FEATURED, limit = LIMIT_FOR_HIDDEN_ITEMS): ILootboxReward[] {
    let previewItems = [...rewards];

    if (previewItems.length <= limit) {
        weakLimit = limit;
    }

    let sortedRewards = previewItems;
    if (featuredRewardsIds?.length >= 1 && featuredRewardsIds?.length <= weakLimit) {
        sortedRewards = sort(sortedRewards).by([
            {
                desc: (reward) => (featuredRewardsIds.indexOf(reward.id) < 0 ? false : true),
            },
            {
                asc: (reward) => featuredRewardsIds.indexOf(reward.id),
            },
        ]);
    }

    if (sortedRewards.length > limit) {
        sortedRewards = sort(sortedRewards).asc((reward) => {
            const [isExistsRewardAInInventory, count] = Account.getInventoryInfoByType(reward.type as keyof Inventory, reward.id, reward.shipId);
            return count >= 1;
        });
    }

    previewItems = [...sortedRewards].splice(0, weakLimit);

    return previewItems;
}

export function flatRewards(rewards: ILootboxStateItemGroupRewards<ILootboxRewardGroup>): ILootboxReward[] {
    return Object.keys(rewards).reduce((_rewards: ILootboxReward[], key) => {
        if (rewards[key].rewards) {
            _rewards.push(...rewards[key].rewards);
        }
        return _rewards;
    }, []);
}

type RewardFields = keyof ILootboxReward;
export enum GroupedRewardsTypes {
    SPECIAL_SIGNAL = 'grouped_special_signal',
    ECO_SIGNAL = 'grouped_eco_signal',
    BATTLE_SIGNAL = 'grouped_battle_signal',
    SIGNAL = 'grouped_signal',
    CAMOBOOST = 'grouped_camoboost',
    CAMOUFLAFE = 'grouped_camouflage',
}

export const TAG_TO_RARITY: { [key: string]: string } = {
    sortOrder_1: 'standard',
    sortOrder_2: 'special',
    sortOrder_3: 'rare',
    sortOrder_4: 'unique',
};
export const RARITY_TAG_TEMPLATE = new RegExp('sortOrder_.');

export function groupedRewardsByFields(rewards: ILootboxReward[], fields: RewardFields[] = ['type', 'probability', 'amount']): any {
    const maps: any = {};

    rewards.forEach((reward) => {
        fields.reduce((state: any, field, index) => {
            let value = reward[field] as string;

            if (typeof value === 'object') {
                return state;
            }

            if (field === 'type') {
                if (value === items.SIGNAL) {
                    value = GroupedRewardsTypes.SIGNAL;
                } else if (isCamouflage(reward.type)) {
                    value = GroupedRewardsTypes.CAMOUFLAFE;
                } else if (value === items.CAMO_BOOST) {
                    value = GroupedRewardsTypes.CAMOBOOST;
                }
            }

            if (!state[value]) {
                state[value] = {};
            }

            if (index + 1 === fields.length) {
                state[value] = { items: [...(state[value]?.items || []), reward] };
            }

            return state[value];
        }, maps);
    });

    return maps;
}

export function getAllGroupTitlesBySlot(lootbox: ILootboxStateItem, slotIndex: number): string[] {
    const slot = lootbox.slots[slotIndex];
    const valuableRewards = slot.valuableRewards;

    return Object.keys(valuableRewards).reduce((array: string[], key: string) => {
        array.push(valuableRewards[key].title);
        return array;
    }, []);
}

export function getTotalProbabilityForValuableRewards(rewards: ILootboxStateRewards): number {
    return Object.keys(rewards).reduce((totalProbability, key) => {
        totalProbability += rewards[key].probabilityDisplayed;
        return totalProbability;
    }, 0);
}

export function clearQuotes(str: string) {
    return str?.replace(/[„”«»"“]/g, '');
}

export function isUniqueGroup(rewards: ILootboxReward[]) {
    const isCrewRewards = rewards.every((reward) => reward.type === items.CREWS);
    const isMultiBoostsRewards = rewards.every((reward) => reward.type === items.MULTI_BOOST);

    return !isCrewRewards && !isMultiBoostsRewards;
}

export function prepareLootbox(lootbox: ILootboxStateItem): ILootboxStateItem {
    lootbox.shortTitle = clearQuotes(lootbox?.shortTitle);

    let savePoint = lootbox.savePoint;
    let savePointGroup = null;
    let savePointTitle = null;
    let isUniqueSavePointGroup = false;
    let groupSavePointIndex: string;
    const uniqueGroupTitles: string[] = [];

    lootbox.slots.forEach((slot, idx) => {
        const valuableRewardsKeys = Object.keys(slot.valuableRewards);

        if (valuableRewardsKeys.length) {
            lootbox.hasValuableRewards = true;
            lootbox.slotWithUnique = idx.toString();
        }

        const onlyOneValuableGroup = valuableRewardsKeys.length === 1;

        valuableRewardsKeys.forEach((groupName, index) => {
            const group = slot.valuableRewards[groupName];
            if (!group.title?.trim()?.length) {
                group.title = getDefaultGroupTitle(index, onlyOneValuableGroup);
            }

            if (!group.shortTitle?.trim()?.length) {
                group.shortTitle = getDefaultGroupTitle(index, onlyOneValuableGroup);
            }

            group.commonRewardType = getCommonRewardsType(group);

            if (group.hasUniqueRewards) {
                uniqueGroupTitles.push(group.title);
            }

            if (!lootbox.savePoint && group.savePoint) {
                savePoint = group.savePoint;
                groupSavePointIndex = groupName.slice(-1);
                lootbox.slotWithUnique = idx.toString();
            }

            if (group.savePoint) {
                savePointGroup = groupName;
                savePointTitle = group.title;
                isUniqueSavePointGroup = group.hasUniqueRewards;
            }
        });
    });

    lootbox.uniqueGroupTitles = uniqueGroupTitles;

    if (savePoint && savePointTitle && savePointGroup) {
        lootbox.savePointForValuableGroup = {
            savePoint: savePoint,
            groupName: savePointGroup,
            groupTitle: savePointTitle,
            isUniqueGroup: isUniqueSavePointGroup,
            groupSavePointIndex: groupSavePointIndex,
        };
    }

    return lootbox;
}

export function getCommonRewardsType(group: ILootboxRewardGroup): Nullable<CommonRewardType> {
    const isOnlyShipRewards = group.rewards.every((reward) => reward.type === items.VEHICLES);
    if (isOnlyShipRewards) {
        return CommonRewardType.SHIP;
    }

    const isOnlyCamoRewards = group.rewards.every((reward) => isCamouflage(reward.type) || isPermanentCamouflage(reward.type));
    if (isOnlyCamoRewards) {
        return CommonRewardType.CAMO;
    }

    const isOnlyCrewRewards = group.rewards.every((reward) => reward.type === items.CREWS);
    if (isOnlyCrewRewards) {
        return CommonRewardType.CREW;
    }

    return null;
}

export function getAmountValuableGroupFromSlot(lootbox: ILootboxStateItem, slotIndex: number) {
    const slot = lootbox.slots[slotIndex];
    return Object.keys(slot.valuableRewards).length;
}

export function getLootboxApiUrl(id: string | number) {
    return settings.urls.lootboxApi.replace('{language_code}', settings.languageCode).replace('{id}', id.toString());
}

export const LOOTBOX_RULES_PLACEHOLDER = '[lootbox-rules]';
export const parseLootboxRulesPlaceholder = (text: string) => {
    return text.replace(LOOTBOX_RULES_PLACEHOLDER, getAboutLootboxText());
};
