import * as React from 'react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import History from '~/utils/history';
import { urlsMap } from '~/utils/menu';

interface IMobileContainerRouter {
    children: React.ReactNode;
}

export default function MobileContainerRouter({ children }: IMobileContainerRouter): JSX.Element {
    if (!isMobileOrTabletWindow) {
        History.navigate(urlsMap.home, { replace: true });
        return;
    }

    return <>{children}</>;
}
