import * as React from 'react';
import styles from './SpecialBundleFooterContent.scss';
import { assets, items } from '@wg/wows-entities/const';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import classNames from 'classnames';

interface ISpecialBundleFooterContent {
    primaryItem: IBundleEntity;
    className?: string;
}

const SpecialBundleFooterContent = ({ primaryItem, className }: ISpecialBundleFooterContent) => {
    switch (primaryItem?.type) {
        case items.SIGNAL:
        case items.MODERNIZATION:
        case items.CAMOUFLAGE:
        case items.STYLE:
        case items.MSKIN:
        case items.MULTI_BOOST:
        case items.CAMO_BOOST:
        case items.GLOBAL_BOOST:
        case items.LOOTBOX:
        case assets.SLOTS:
        case assets.SLOT:
            return (
                <div className={classNames(styles.inventoryWidget, className)}>
                    <InventoryCounterContainer item={primaryItem} />
                </div>
            );

        default:
            return <></>;
    }
};

export default SpecialBundleFooterContent;
