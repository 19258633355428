import gql from 'graphql-tag';

// TODO: DELETE FEATURES TAGS SHIT PART AFTER FEATURE TAGS MOVES TO SHIP REQUEST
export const vehiclesIds = gql`
    query Vehicles($ids: [String], $lang: String) {
        vehicles(vehicleIds: $ids, lang: $lang) {
            id
            title
            titleShort
            isPremium
            isSpecial
            description
            tags
            nation {
                name
                title
                icons {
                    default
                    small
                    medium
                    large
                    tiny
                }
            }
            type {
                name
                title
                titleShort
                icons {
                    default
                    special
                    normal
                    elite
                    premium
                }
            }
            level
        }
        featuresTags(lang: $lang, vehicleIds: $ids) {
            id
            complexity {
                level
                header
                description
                icons {
                    filled
                    empty
                    localFilled
                    localEmpty
                }
            }
            featuresTags {
                color
                type
                mark
                icons {
                    large
                    small
                    localLarge
                    localSmall
                }
            }
            featuresTagsPerCategory {
                category {
                    name
                    mark
                    icons {
                        default
                        localDefault
                    }
                }
                featuresTags {
                    color
                    type
                    mark
                    icons {
                        large
                        small
                        localLarge
                        localSmall
                    }
                }
            }
        }
    }
`;
