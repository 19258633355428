import * as React from 'react';
import styles from './BidPlacingSuccessPopup.scss';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import useClickAway from '~/hooks/useClickAway';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { ZoomInDiv } from '~/components/Transitions';
import { t } from '~/utils/localization';
import { OrangeButton } from '~/components/Button/Buttons';
import { Interpolate } from '@wg/wows-react-uikit';
import Currency from '~/components/Currency/Currency';
import { getNoticeByKind } from '~/utils/auction';
import { playButtonDialogClickSound, playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { getCurrency } from '~/utils/currencies';

interface IProps {
    data: {
        currency: string;
        bet: number;
        newBet?: number;
        auction: IAuction;
        lot: ILot;
    };
}

const oldTexts = [
    t('Вы можете изменить или отменить ставку в любой момент до закрытия аукциона.'),
    t('Вы можете изменить ставку в любой момент до закрытия аукциона.'),
    t('Вы можете повысить ставку в любой момент до закрытия аукциона.'),
    t('Вы можете понизить ставку в любой момент до закрытия аукциона.'),
];

const BidPlacingSuccessPopup = (props: IProps) => {
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const ref = React.useRef(null);

    const close = () => dispatch(changeVisiblePopup(null));

    useClickAway(
        ref,
        () => {
            if (!isPopupActive) {
                return;
            }
            close();
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    const { bidDropAllowed, bidRaisingAllowed, bidCancellationsAllowed } = props.data.auction || {};

    React.useEffect(() => {
        playButtonDialogClickSound();
    }, []);

    return (
        <ZoomInDiv className={styles.wrapper} ref={ref}>
            <div className={styles.content}>
                <div className={styles.icon} />
                <div className={styles.betTitle}>
                    {props.data.newBet ? (
                        <Interpolate
                            str={t('Ставка изменена с %(bet)s на %(newBet)s.')}
                            bet={
                                <Currency
                                    withoutAnimation
                                    className={styles.bet}
                                    currency={props.data.currency}
                                    amount={props.data.bet}
                                    showDescriptionTooltip={props.data.auction.showDescriptionInPriceTooltip && getCurrency(props.data.currency)?.showDescriptionInPriceTooltip}
                                />
                            }
                            newBet={
                                <Currency
                                    withoutAnimation
                                    className={styles.bet}
                                    currency={props.data.currency}
                                    amount={props.data.newBet}
                                    showDescriptionTooltip={props.data.auction.showDescriptionInPriceTooltip && getCurrency(props.data.currency)?.showDescriptionInPriceTooltip}
                                />
                            }
                        />
                    ) : (
                        <Interpolate
                            str={t('Ставка в размере %(bet)s успешно сделана!')}
                            bet={
                                <Currency
                                    withoutAnimation
                                    className={styles.bet}
                                    currency={props.data.currency}
                                    amount={props.data.bet}
                                    showDescriptionTooltip={props.data.auction.showDescriptionInPriceTooltip && getCurrency(props.data.currency)?.showDescriptionInPriceTooltip}
                                />
                            }
                        />
                    )}
                </div>
                <div className={styles.betAbout}>
                    {bidDropAllowed && bidRaisingAllowed && !bidCancellationsAllowed && t('Вы можете изменить ставку в любой момент до закрытия аукциона.')}
                    {bidDropAllowed && bidRaisingAllowed && bidCancellationsAllowed && t('Вы можете изменить или отменить ставку в любой момент до закрытия аукциона.')}
                    {!bidDropAllowed && bidRaisingAllowed && t('Вы можете повысить ставку в любой момент до закрытия аукциона.')}
                    {bidDropAllowed && !bidRaisingAllowed && t('Вы можете понизить ставку в любой момент до закрытия аукциона.')}
                </div>
                <div className={styles.betDescription}>{getNoticeByKind(props.data.auction?.kind)}</div>
                <div className={styles.footer}>
                    <OrangeButton label={t('Закрыть')} onClick={close} />
                </div>
            </div>
        </ZoomInDiv>
    );
};

export default BidPlacingSuccessPopup;
