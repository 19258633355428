import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import * as React from 'react';
import { interpolate, t } from '~/utils/localization';
import Price from '~/components/Price/Price';
import BundleCurrencySequenceTooltip, { CurrencySecuenceItem } from '~/components/Tooltip/BundleCurrencySequenceTooltip';
import styles from './BundlePricesSequence.scss';
import Discount, { DiscountSize } from '~/components/Discount/Discount';
import { getBundlePricesSequence } from '~/utils/bundles';
import { sort } from 'fast-sort';
import { getCurrency } from '~/utils/currencies';

interface IBundlePricesSequence {
    bundles: IBundle[];
    balance: IBalance;
    limit?: number;
    className?: string;
    priceClassName?: string;
}

const BundlePricesSequence = ({ bundles, className, limit, priceClassName, balance }: IBundlePricesSequence) => {
    const pricesMap = getBundlePricesSequence(bundles, balance);

    const totalPrices = sort(Object.keys(pricesMap)).desc((key) => pricesMap[key].discount);
    const _prices = [...totalPrices].splice(0, limit || totalPrices.length);

    const leftCurrencies = [...totalPrices].splice(limit, totalPrices.length).reduce((accumulator: CurrencySecuenceItem, key) => {
        accumulator[key] = pricesMap[key];
        return accumulator;
    }, {});

    let withDiscount = false;

    const prices = _prices.map((key, index) => {
        let price = <Price amount={pricesMap[key].value} currency={key} isBigSize withoutAnimation className={styles.price} />;

        if (pricesMap[key].discount) {
            withDiscount = true;
            price = (
                <div className={styles.wrapperWithDiscount}>
                    <div className={styles.pricesRow}>
                        <Discount formattedDiscount={'%'} size={DiscountSize.SMALL} className={styles.discount} />
                        <Price
                            amount={pricesMap[key].originalValue}
                            currency={key}
                            className={styles.oldPrice}
                            showDescriptionInPriceTooltip={getCurrency(key)?.showDescriptionInPriceTooltip}
                            isOld
                            withoutIcon
                            withoutTooltip
                            withoutAnimation
                        />
                    </div>
                    <Price showDescriptionInPriceTooltip={getCurrency(key)?.showDescriptionInPriceTooltip} amount={pricesMap[key].value} currency={key} isBigSize withoutAnimation />
                </div>
            );
        }

        return (
            <div
                className={classNames(styles.priceWrapper, priceClassName, {
                    [styles.withDiscount]: pricesMap[key].discount,
                })}
                key={`${key}_${index}`}
            >
                {price}
                {index + 1 !== _prices.length && <div className={styles.plus}>+</div>}
            </div>
        );
    });

    return (
        <div
            className={classNames(
                styles.wrapper,
                {
                    [styles.withDiscount]: withDiscount,
                },
                className,
            )}
        >
            {prices}
            {limit && limit < totalPrices.length && (
                <DivTooltip className={styles.moreCurrencies} tooltipBody={<BundleCurrencySequenceTooltip currencies={leftCurrencies} />}>
                    <div className={styles.plus}>+</div>
                    <div className={styles.more}>{interpolate(t('еще {amount}'), { amount: totalPrices.length - limit })}</div>
                </DivTooltip>
            )}
        </div>
    );
};

export default BundlePricesSequence;
