import * as React from 'react';
import styles from './TradeInRules.scss';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { interpolate, t } from '~/utils/localization';
import { Button } from '~/components/Button/Buttons';
import { ITradeList, ITradeLists } from '~/types/tradein';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';

interface IStateSelector {
    lists: ITradeLists;
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        lists: state.ReducerTradeIn.lists,
    };
};

const TradeInRules = () => {
    const dispatch = useDispatch();
    const { currentPage, lists } = useSelector<State, IStateSelector>(stateSelector, equal);
    const groups: ITradeList[] = lists?.[currentPage?.name];

    const onClose = () => {
        dispatch(changeVisiblePopup(null));
    };

    return (
        <Popup renderEscButton={true} onClose={onClose}>
            <PopupHeader title={t('Правила обмена')} />
            <PopupBody>
                <div className={styles.content}>
                    <div className={styles.texts}>
                        <p className={styles.rule}>{t('В рамках одного обмена вы можете выбрать только один из имеющихся кораблей для получения нового.')}</p>
                        <p className={styles.rule}>
                            {t(
                                'Обмениваемый корабль будет списан с аккаунта. Всё имущество корабля будет перемещено во вкладку «Имущество». Командир корабля будет отправлен в Резерв. Получаемый корабль начисляется без командира и слота.',
                            )}
                        </p>
                        <p className={styles.rule}>
                            {t(
                                'Часть стоимости обмениваемого корабля является скидкой на приобретение нового. Если вы обмениваете редкий корабль, который сложно или в данный момент невозможно получить, скидка увеличивается. Точный размер скидки вы увидите, когда выберете нужный корабль в разделе «Корабль для обмена».',
                            )}
                        </p>
                        <p className={styles.rule}>
                            {t('Пример:')}
                            <br />
                            {t(
                                'Корабль А стоит 12 200 дублонов. Вы хотите получить корабль Б стоимостью 12 000 дублонов. Размер скидки, предоставляемой по программе трейд-ин в случае обмена корабля А, — 6 100 дублонов. Таким образом, чтобы получить корабль Б, вам нужно доплатить только 5 900 дублонов вместо обычной стоимости в 12 000 дублонов.',
                            )}
                        </p>
                        <p className={styles.rule}>{t('Если размер скидки выше стоимости получаемого корабля, обмен происходит без оплаты. Разница в стоимости не компенсируется.')}</p>
                        <p className={styles.rule}>{t('Корабли, которые вы обменяли по программе трейд-ин, нельзя восстановить.')}</p>
                        <p className={styles.rule}>{t('Список обмениваемых кораблей:')}</p>
                    </div>
                    {groups?.length > 0 && (
                        <div className={styles.groups}>
                            {groups.map((group, index) => {
                                return (
                                    <div className={styles.group} key={`group_${index}`}>
                                        <div className={styles.groupTitle}>{interpolate(t('Группа {number}'), { number: index + 1 })}</div>
                                        <div className={styles.column}>
                                            <div className={styles.groupSubTitle}>{t('Корабли для обмена')}</div>
                                            <div className={styles.items}>
                                                {group.source.map((item) => {
                                                    return (
                                                        <div className={styles.item} key={`item_${item.identifier}_group_${index}`}>
                                                            <WoWSEntity
                                                                type={item.type}
                                                                className={styles.weItem}
                                                                id={item.identifier}
                                                                presentation={{
                                                                    renderWithoutFade: true,
                                                                    hideNationIcon: true,
                                                                    withShortText: true,
                                                                    withTooltip: true,
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.groupSubTitle}>{t('Корабли для получения')}</div>
                                            <div className={styles.items}>
                                                {group.target.map((item) => {
                                                    return (
                                                        <div className={styles.item} key={`item_${item.identifier}_group_${index}`}>
                                                            <WoWSEntity
                                                                type={item.type}
                                                                id={item.identifier}
                                                                className={styles.weItem}
                                                                presentation={{
                                                                    renderWithoutFade: true,
                                                                    hideNationIcon: true,
                                                                    withShortText: true,
                                                                    withTooltip: true,
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </PopupBody>
            <PopupFooter>
                <Button label={t('Закрыть')} onClick={onClose} />
            </PopupFooter>
        </Popup>
    );
};

export default TradeInRules;
