import * as React from 'react';
import styles from './BankCard.scss';
import { interpolate, t } from '~/utils/localization';

interface IBankCard {
    type: string;
    expiration: string;
    number: string;
    icon: string;
}

const BankCard: React.FC<IBankCard> = ({ type, expiration, number, icon }) => {
    const bankCardInfo = interpolate(t('{type} **** {number} до {date}'), {
        type: `<span class="bank-card__type">${type}</span>`,
        number,
        date: expiration,
    });

    return (
        <div className={styles.bankCard}>
            <div className={styles.bankCardImage} style={{ backgroundImage: `url(${icon})` }} />
            <div className={styles.bankCardInfo} dangerouslySetInnerHTML={{ __html: bankCardInfo }} />
        </div>
    );
};

export default BankCard;
