import * as React from 'react';
import { State } from '~/Reducers';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import ViewCardContainer from '~/containers/ViewCardContainer/ViewCardContainer';

interface ISingleBundlePage {
    bundleId: number;
}

interface IStateSelector {
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
    };
};

const SingleBundlePage = ({ bundleId }: ISingleBundlePage) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    if (!bundleId || !state.currentPage?.name) {
        return null;
    }

    return (
        <ViewCardContainer
            params={{
                id: bundleId,
                categoryName: state.currentPage.name,
            }}
        />
    );
};

export default SingleBundlePage;
