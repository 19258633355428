import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markLabelAsRead } from '~/Actions/ActionAccount';
import { markLabelAsViewed } from '~/api/account';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import Account from '~/account/Account';
import { LocalStorageHelper } from '~/utils/storage';
import { LOCAL_STORAGE_LABELS } from '~/utils/keys';
import { armoryState } from '~/utils/settings';

interface ILabelDecorator {
    isEnabled?: boolean;
    onMouseEnter?: (event: React.MouseEvent) => void;
    onClick?: (event: React.MouseEvent) => void;
    byHover?: boolean;
    children: React.ReactChild | React.ReactChild[];
    className?: string;
    id?: string;
    labelTill?: string;
    isCategory?: boolean;
    type: string;
}

export const getLocalStorageLabelsKey = () => LOCAL_STORAGE_LABELS.split('{}').join(armoryState?.account?.id?.toString());

export const saveLabelToLocalStorage = (name: string) => {
    const key = getLocalStorageLabelsKey();
    let data = LocalStorageHelper.get(key);

    if (data) {
        data.names.push(name);
    } else {
        data = {
            names: [name],
        };
    }

    LocalStorageHelper.set(key, data);
};

export const LABEL_TYPE = {
    bundle: 'bundles',
    category: 'categories',
    coupon: 'coupon',
    categoryEvent: 'categoryEvent',
};

const LabelDecorator = (props: ILabelDecorator) => {
    const app = useSelector((state: State): AppState => state.ReducerApp);
    const [isAlreadyViewed, setFlag] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    function markLabel() {
        if (!props.id) {
            return;
        }

        if (!props.isEnabled) {
            return;
        }

        if (isAlreadyViewed) {
            return;
        }

        if (!Account.getAccount()) {
            return;
        }

        dispatch(markLabelAsRead(props.id));

        markLabelAsViewed(props.id);

        setFlag(true);
    }

    function onClick(event: React.MouseEvent) {
        props.onClick?.(event);
        markLabel();
    }

    const mouseEnter = () => props.byHover && markLabel();

    React.useEffect(() => {
        if (props.type === LABEL_TYPE.category && app.currentPage?.name === props.id) {
            markLabel();
        }
    }, [app.currentPage?.name]);

    return (
        <div onClick={onClick} onMouseEnter={mouseEnter.bind(this)} className={props.className}>
            {props.children}
        </div>
    );
};

export default React.memo(LabelDecorator);
