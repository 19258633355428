import * as React from 'react';
import styles from './PremiumAccountLink.scss';
import className from 'classnames';
import equal from 'fast-deep-equal/react';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import { arrayToObjectByKey } from '~/utils/utils';
import Account from '~/account/Account';
import { openCategoryByName } from '~/utils/category';
import { CATEGORIES } from '~/const';
import { getPremiumText } from '~/utils/auction';

interface IStateSelector {
    balance: IBalance;
    categories: ICategories;
    activeAuctions: IAuction[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        balance: state.ReducerAccount.balance,
        categories: state.ReducerApp.categories,
        activeAuctions: state.ReducerAuction.activeAuctions,
    };
};

const MobilePremiumAccountLink = () => {
    const goToWoWSPremium = () => {
        openCategoryByName(CATEGORIES.PURCHASE_WOWS_PREMIUM);
    };

    const isAuthorizedUser = !!Account.getAccount();

    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const balance = state.balance;
    const balanceObject = !!balance ? arrayToObjectByKey(balance, 'currency') : null;
    const isPremAccountCategoryEnabled = isAuthorizedUser && !!state.categories?.[CATEGORIES.PURCHASE_WOWS_PREMIUM];
    const warshipsAccountPremium = balanceObject?.['warships_account_premium']?.value || 0;
    const generalAccountPremium = balanceObject?.['general_account_premium']?.value || 0;
    const hasPremium = warshipsAccountPremium > 0 || generalAccountPremium > 0;
    const accountPremiumSeconds = warshipsAccountPremium || generalAccountPremium;

    const accountPremiumClassName = className(styles.accountPremiumWrapper, {
        [styles.withIcon]: hasPremium,
        [styles.generalAccountPremium]: !!generalAccountPremium && !warshipsAccountPremium,
    });

    return (
        <>
            {isPremAccountCategoryEnabled && (
                <div className={styles.mobileHeaderAccountPremium} onClick={goToWoWSPremium}>
                    {hasPremium && <div className={accountPremiumClassName}>{getPremiumText(state.activeAuctions, accountPremiumSeconds)}</div>}
                    {!hasPremium && <div className={styles.iconWarshipsPremiumBuy} />}
                </div>
            )}
        </>
    );
};

export default MobilePremiumAccountLink;
