import { Actions } from '~/Actions';
import { SAVE_SEA_BATTLE_SETTINGS } from '~/Actions/ActionSeaBattle';

export interface SeaBattleState {
    isEnabled: boolean;
    isError: boolean;
    isActivatedUser: boolean;
    hasActiveSeason: boolean;
}

export const initialState: SeaBattleState = {
    isEnabled: false,
    isError: false,
    isActivatedUser: true,
    hasActiveSeason: true,
};

const ReducerSeaBattle = (state: SeaBattleState = initialState, action: Actions): SeaBattleState => {
    switch (action.type) {
        case SAVE_SEA_BATTLE_SETTINGS:
            return {
                ...state,
                isError: action.isError,
                isEnabled: action.isEnabled,
                isActivatedUser: action.isActivatedUser,
                hasActiveSeason: action.hasActiveSeason,
            };

        default:
            return { ...state };
    }
};

export default ReducerSeaBattle;
