import styles from './TreasureGuarantee.scss';
import { Interpolate } from '@wg/wows-react-uikit';
import * as React from 'react';
import { t } from '~/utils/localization';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';

type Props = {
    chains: Array<TreasureChain>;
};

export default function TreasureGuarantee(props: Props) {
    const allChainsWeights = props.chains.reduce((sum, chain) => sum + chain.weight, 0);
    const filteredByGuarantee = props.chains.filter((chain) => chain.guarantee);

    return (
        <div className={styles.wrapper}>
            {filteredByGuarantee.map((chain, i) => {
                return (
                    <div className={styles.group} key={i}>
                        <div className={styles.group__name}>
                            <Interpolate str={t('%(name)s: вероятность выпадения -')} name={chain?.title || chain?.name || 'Группа'} />
                            <ChanceLabel chance={(chain.weight / allChainsWeights) * 100} className={styles.group__chance} />
                        </div>
                        <div className={styles.group__description}>
                            <Interpolate
                                str={t('Гарантированное выпадение: %(guarantee)s покупок (если этого не произошло раньше)')}
                                name={chain?.title || chain?.name || 'Группы'}
                                guarantee={chain?.rotationsTillGuarantee || 0}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
