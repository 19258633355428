import * as React from 'react';
import styles from '~/components/Popups/popups.scss';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { changeVisiblePopup, updateQuantity } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { _purchase as apiPurchaseBundle, calculateBundlePrices } from '~/utils/purchase';
import PurchaseButton from '~/components/Button/PurchaseButton';
import useClickAway from '~/hooks/useClickAway';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { resetCoupon, updateUserAdultFlag } from '~/Actions/ActionAccount';
import Account from '~/account/Account';
import { isInGameBrowser, isMobileOrTabletWindow, updateSearchParam } from '~/utils/utils';
import { Button } from '~/components/Button/Buttons';
import dwhExport from '~/api/dwhExport';
import { CATEGORIES, DWH_EVENTS } from '~/const';
import AttentionLimitWidget from '~/components/BundleLimitWidget/Attention/AttentionLimitWidget';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { IChangeVisiblePopup, ICurrentPage, IUpdateQuantity } from '~/Actions/ActionAppType';
import { CopyrightContent } from '~/components/Popups/Purchase/AdditionalContent';
import PurchaseCustomizationWrapper from '~/components/Popups/Purchase/Customization/PurchaseCustomizationWrapper';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { startHeadShakeAnimation } from '~/utils/auction';
import PurchasePopupDetails from '~/components/Popups/Purchase/PurchasePopupDetails';
import { canBoughtContainer, isEnabledPurchaseLimitToContainer, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';
import useRandomBundleData from '~/hooks/useRandomBundleData';
import { playButtonClickSound } from '~/api/WoWsClient';
import { BUNDLE_DECORATION, BundlePurchaseTypes } from '~/types/bundle';
import {
    canBoughtRandomBundleWithMaxAmount,
    getAvailableBundlesForPurchase,
    getDefaultExchangeCurrency,
    getProgressiveDiscountFromBundle,
    getPurchaseExchangeRates,
    getPurchaseLimit,
    getSerialBundlesById,
    hasLootboxRestrictionInSerialSequence,
    hasUniqueItems,
    isAvailableMultiplePurchaseForSerialBundle,
    isContainLootbox,
    isEnoughCurrencyForPurchaseSerialSequence,
    isFreeBundle,
    isMultiplePurchaseAvailable,
    isNeedToShowAdultCopyright,
    isPeriodicBundle,
    isSteelExchangeBundle,
} from '~/utils/bundles';
import Currency from '~/components/Currency/Currency';
import equal from 'fast-deep-equal/react';
import { CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import Link, { LinkColors } from '~/components/Link/Link';
import History from '~/utils/history';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { urlsMap } from '~/utils/menu';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import { CustomBackground } from '~/Layouts/Themes/CustomPage/CustomBackground/CustomBackground';
import store from '~/Store';

interface IConfirmPurchase {
    bundleId?: number;
}

interface IStateSelector {
    accountId: number;

    popupActive: IPopup;

    currentPage: ICurrentPage;
    categories: ICategories;

    port: IPort;

    bundles: IBundleList;
    selectedRandomBundles: AccountSelectedRandomBundles;
    bundlesInTransaction: number[];
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;

    balance: IBalance;
    coupons: ICoupon[];
    activeCouponId: number;
    activePreset: string;
    customBackground?: State['ReducerApp']['customPageSettings']['background'];

    isNoAskForConfirmationPurchase: boolean;
    isAdult: boolean;
    isTrusted?: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,

        popupActive: state.ReducerApp.popupActive,

        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,

        port: state.ReducerApp.port,

        bundles: state.ReducerApp.bundles,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        bundlesInTransaction: state.ReducerAccount.bundlesInTransaction,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,

        balance: state.ReducerAccount.balance,
        coupons: state.ReducerAccount.coupons,
        activeCouponId: state.ReducerAccount.activeCouponId,
        activePreset: state.ReducerApp.activePreset,
        customBackground: state.ReducerApp.customPageSettings.background,

        isNoAskForConfirmationPurchase: state.ReducerAccount.isNoAskForConfirmationPurchase,
        isAdult: state.ReducerAccount.isAdult,
        isTrusted: state.ReducerApp.isTrusted,
    };
};

const ConfirmPurchase = (props: IConfirmPurchase) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);

    const [isBlockedInterface, setIsBlockedInterface] = React.useState(false);
    const popupWrapperRef = React.useRef<HTMLDivElement>(null);

    const popupData = popup?.data || {};
    const bundleId = props.bundleId ? props.bundleId : popupData.bundleId;
    const category = state.categories[state.currentPage?.name];

    let interfaceLockTimer: NodeJS.Timeout = null;

    let bundle: IBundle = state.bundles[bundleId];

    const isFreeBundleFlag = isFreeBundle(bundle);
    const isPeriodicBundleFlag = isPeriodicBundle(bundle);

    const isRandomBundleFlag = bundle.isRandom;
    const randomBundleData = useRandomBundleData(bundle);
    if (randomBundleData.parentRandomBundle) {
        bundle = randomBundleData.currentBundle;
    }

    const serualSequence = getSerialBundlesById(state.bundles, bundle.id);
    const purchasedSequence = serualSequence.filter((bundle) => bundle.isPurchased);
    const availableBundlesForPurchase = getAvailableBundlesForPurchase(state.balance, serualSequence, state.coupons);
    const hasLootboxRestrictionForSerialBundles = hasLootboxRestrictionInSerialSequence(availableBundlesForPurchase);
    const status = isEnoughCurrencyForPurchaseSerialSequence(availableBundlesForPurchase, state.balance);
    const isAvailableWholePurchase =
        isAvailableMultiplePurchaseForSerialBundle(bundle, state.bundles) &&
        purchasedSequence?.length + availableBundlesForPurchase?.length === serualSequence.length &&
        status.isEnought &&
        availableBundlesForPurchase?.length > 1 &&
        !hasLootboxRestrictionForSerialBundles;

    const isActiveCouponFlag = !!state.activeCouponId;
    const isActiveTransactionFlag = state.bundlesInTransaction?.includes(bundle.id);
    const isMobileFlag = !isInGameBrowser && isMobileOrTabletWindow && !state.port?.isVisible;

    const isEnabledCouponFlag = Account.isEnabledCouponsFromBundle(state.coupons, bundle) && (bundle.quantityData?.quantity === 1 || !bundle.quantityData?.quantity);
    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);

    if (!bundle.quantityData) {
        bundle.quantityData = {
            ...calculateBundlePrices(bundle, state.balance, bundle.quantityData?.quantity || 1, Account.getActiveCoupon(state.activeCouponId, state.coupons)?.discount),
        };
    }
    const isNeedToShowExchangeMessageFlag = isSteelExchangeBundle(Object.keys(bundle.quantityData.final));

    const isEnabledPurchaseLimitToContainerFlag = isEnabledPurchaseLimitToContainer(bundle);
    const canChangeQuantityFlag = !(bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) && (isMultiplePurchaseAvailable(bundle) || randomBundleData.isAvailableChangeQuantity);

    const reasonCantBoughtCode: number | REASON_FAIL_PURCHASE_CONTAINER = (() => {
        if (isRandomBundleFlag) {
            return isFreeBundleFlag || canBoughtRandomBundleWithMaxAmount(randomBundleData.purchaseMaxCountForRandomBundle as Nullable<number>)
                ? 1
                : REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE;
        }

        return Number(canBoughtContainer(bundle, state.restrictedLootboxesPurchaseCount));
    })();
    const hasRestrictionFlag = reasonCantBoughtCode < 0;

    const isNeedToShowAdultCopyrightFlag = isNeedToShowAdultCopyright(bundle);
    const isNeedToShowCopyrightFromContainerFlag = isNeedToShowAdultCopyrightFlag && isContainLootbox(bundle, randomBundleData.parentRandomBundle, randomBundleData.availableAmountForFullPurchase);
    const isNeedToShowCopyrightFromRandomFlag = isNeedToShowAdultCopyrightFlag && isRandomBundleFlag;
    const isNeedToShownAdultCheckboxFlag = !bundle.hideAdultCheck && (isNeedToShowCopyrightFromContainerFlag || isNeedToShowCopyrightFromRandomFlag);

    const isNeedToShowLimitFlag = !isRandomBundleFlag && !hasUniqueItems(bundle);
    const limit = state.accountId ? getPurchaseLimit(bundle) : bundle.limitedQuantity?.personalLimit;
    const availableAmountForFullPurchase = randomBundleData?.maxAmountForOnePurchaseWithoutSinglePurchaseLimit;

    const purchaseExchangeRates = getPurchaseExchangeRates();
    const defaultExchangeCurrency = getDefaultExchangeCurrency();
    const exchangeToCurrency = purchaseExchangeRates[`${defaultExchangeCurrency}_coal`];

    const [button, setButton] = React.useState<{ label?: string; isDisabled?: boolean; isDisabledByNotAdult?: boolean; isFetching?: boolean }>({
        label: t('Купить'),
        isDisabled: false,
        isDisabledByNotAdult: isNeedToShownAdultCheckboxFlag && !state.isAdult,
        isFetching: isActiveTransactionFlag,
    });

    const showAppearanceDisclaimerFlag = bundle.decoration?.includes(BUNDLE_DECORATION.DISCLAIMER_APPEARANCE);
    const WebCopyrightContent = React.useCallback(() => {
        return (
            <div className={styles.copyrightContent}>
                <CopyrightContent
                    isNeedToShowCopyrightFromContainer={isNeedToShowCopyrightFromContainerFlag}
                    isNeedToShowCopyrightFromRandom={isNeedToShowCopyrightFromRandomFlag}
                    isNeedToHideDisclaimerAppearance={showAppearanceDisclaimerFlag}
                />
            </div>
        );
    }, []);

    function changeUserAdultFlagCallback() {
        setButton({ isDisabledByNotAdult: !!state.isAdult });
        Account.updateAccountSettings({ is_adult: !state.isAdult });
        store.dispatch(updateUserAdultFlag());
    }

    function onFullPurchaseHandler(amount: number) {
        const bundle = randomBundleData.parentRandomBundle;
        const prices = calculateBundlePrices(bundle, state.balance, amount, null, getProgressiveDiscountFromBundle(bundle, amount));
        store.dispatch<IUpdateQuantity>(updateQuantity(bundle.id, amount, prices));
    }

    function closePopup() {
        if (isBlockedInterface) {
            return;
        }

        if (state.popupActive?.name === POPUPS_NAME.BUNDLE_LIMIT_INFO) {
            return;
        }

        store.dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
        if (category.type === CATEGORIES.LANDING) {
            History.navigate(urlsMap.home, { replace: true });
        }
    }

    function onClickFromDisableButtonCallback() {
        const restrictionRef = RefManager.getRef(RefManagerKeys.PurchaseRestriction);
        if (restrictionRef) {
            startHeadShakeAnimation(restrictionRef, styles.headShakeAnim);
        }
    }

    function purchaseButtonClickCallback() {
        if (isNeedToShownAdultCheckboxFlag && !state.isAdult) {
            return;
        }

        setIsBlockedInterface(true);
        interfaceLockTimer = setTimeout(() => {
            setIsBlockedInterface(false);
        }, 7 * 1000);

        setButton({
            isFetching: true,
            label: t('Обработка'),
        });

        apiPurchaseBundle(bundle, () => {
            state.popupActive.data.callback?.();

            if (isMobileOrTabletWindow) {
                setIsBlockedInterface(false);
                setButton({
                    isDisabled: false,
                    label: t('Купить'),
                });
            }
        });
    }

    function buyAllButtonClickCallback() {
        store.dispatch(
            changeVisiblePopup(POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, true, {
                sequence: availableBundlesForPurchase,
                firstAvailableBundleId: bundle.id,
                callback: state.popupActive?.data?.callback,
            }),
        );
    }

    useClickAway(
        popupWrapperRef,
        (event) => {
            if (!isPopupActive) {
                return;
            }
            const target = event.target as HTMLDivElement;

            if ((target.parentNode as HTMLLinkElement).tagName.toLowerCase() === 'a' || target.tagName.toLowerCase() === 'a') {
                return;
            }

            if (!isMobileFlag) {
                closePopup();
            }
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        closePopup();
    }, [KEYS_CODE.ESC]);

    React.useEffect(() => {
        updateSearchParam({ bundleId: bundleId, purchase: true });
        let dwhEventName = null;

        if (state.port?.isVisible) {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_PORT;
        } else if (state.currentPage?.isBundlePage) {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE;
        } else {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_CATEGORY;
        }

        if (dwhEventName) {
            const dwhMessage: Record<string, number | string> = { bundle_id: bundleId };
            if (state.activePreset) {
                dwhMessage['from_filter_preset'] = state.activePreset;
            }
            dwhExport.send(dwhEventName, dwhMessage);
        }

        return () => {
            updateSearchParam({ bundleId: null, purchase: null });

            if (!state.currentPage?.isBundlePage || popupData.fromCategory || isMobileFlag) {
                store.dispatch(updateQuantity(bundle.parentBundleId ?? bundle.id, null, null));
                store.dispatch(resetCoupon());
            } else if (state.port?.isVisible) {
                store.dispatch(resetCoupon());
            }

            interfaceLockTimer && clearTimeout(interfaceLockTimer);
        };
    }, []);

    React.useEffect(() => {
        if (
            state.popupActive &&
            [
                POPUPS_NAME.ERROR_PURCHASE,
                POPUPS_NAME.SUCCESS_PURCHASE,
                POPUPS_NAME.WSMART_PURCHASE_RESULT,
                POPUPS_NAME.PURCHASE_RANDOM_BUNDLE_SUCCESS,
                POPUPS_NAME.SERIAL_SEQUENCE_SUCCESS_PURCHASE,
            ].includes(state.popupActive.name)
        ) {
            store.dispatch(changeVisiblePopup(POPUPS_NAME.CONFIRM_PURCHASE));
        }
    }, [state.popupActive]);

    if (bundle.isPurchased) {
        store.dispatch(changeVisiblePopup(null));
        return null;
    }

    const confirmBackground = bundle.icons?.confirmationBackground || state.bundles[bundle.parentBundleId]?.icons?.confirmationBackground;

    const popupWrapperStyles: React.CSSProperties = (() => {
        let width: React.CSSProperties['width'];
        let height: React.CSSProperties['width'];

        if (category?.theme === CUSTOM_PAGE) {
            width = state.customBackground.naturalSizes.width && !isMobileFlag ? state.customBackground.currentSizes.width : undefined;
            height = state.customBackground.naturalSizes.height && !isMobileFlag ? state.customBackground.currentSizes.height : undefined;
        }

        return {
            width,
            height,
        };
    })();

    const purchasePopupDetailsImageStyles = { backgroundImage: `url(${bundle.icons.small})` };

    return (
        <PurchaseCustomizationWrapper bundle={bundle} category={category}>
            {!!confirmBackground && (
                <div
                    className={styles.background}
                    style={{
                        backgroundImage: `url(${confirmBackground})`,
                        display: category?.theme === CUSTOM_PAGE ? 'none' : 'block',
                    }}
                />
            )}
            {category?.theme === CUSTOM_PAGE && (
                <CustomBackground
                    popupData={{
                        bundle: bundle,
                    }}
                />
            )}
            <Popup
                AfterPopupComponent={<WebCopyrightContent />}
                wrapperRef={popupWrapperRef}
                renderEscButton={!isBlockedInterface}
                onClose={closePopup}
                className={classNames(
                    styles.purchasePopupWrapper,
                    {
                        [styles.isMobilePage]: isMobileFlag,
                        [styles.withBackground]: !isRandomBundleFlag ? !!confirmBackground : bundle?.quantityData?.quantity === 1 ? !!confirmBackground : false,
                        [styles.customPageContent]: category?.theme === CUSTOM_PAGE,
                    },
                    styles[category?.theme],
                )}
                popupClassName={classNames('armory__auto--purchase_popup', styles.purchasePopupContainer, styles[category?.theme], {
                    [styles.isPort]: state.port?.isVisible,
                    [styles.customPageWrapper]: category?.theme === CUSTOM_PAGE,
                })}
                closeIconClassName={'armory__auto--popup_close'}
                style={popupWrapperStyles}
            >
                <PopupHeader
                    title={t('Подтверждение покупки')}
                    className={classNames({
                        [styles.popupTitleSanta]: category?.theme === SANTA_PAGE_THEME,
                        [styles.customPageHeader]: category?.theme === CUSTOM_PAGE,
                    })}
                />
                <PopupBody
                    className={classNames({
                        [styles.customPageBody]: category?.theme === CUSTOM_PAGE,
                    })}
                >
                    <PurchasePopupDetails
                        popup={popup}
                        category={category}
                        bundle={bundle}
                        parentRandomBundle={randomBundleData.parentRandomBundle}
                        sequence={serualSequence}
                        bundlesInTransaction={state.bundlesInTransaction}
                        coupons={state.coupons}
                        activeCoupon={activeCoupon}
                        activeCouponId={state.activeCouponId}
                        balance={state.balance}
                        limit={isNeedToShowLimitFlag ? limit : null}
                        availableAmountForFullPurchase={availableAmountForFullPurchase}
                        maxAmountForOnePurchaseRandomBundle={randomBundleData.maxAmountForOnePurchaseRandomBundle}
                        reasonCantBoughtCode={reasonCantBoughtCode}
                        progressiveDiscount={randomBundleData.currentProgressiveDiscount}
                        beneficialOffer={randomBundleData.beneficialOffer}
                        isRandomBundle={isRandomBundleFlag}
                        isPeriodicBundle={isPeriodicBundleFlag}
                        isFreeBundle={isFreeBundleFlag}
                        isEnabledCoupon={isEnabledCouponFlag}
                        isActiveCoupon={isActiveCouponFlag}
                        isEnabledPurchaseLimitToContainer={isEnabledPurchaseLimitToContainerFlag}
                        isAvailableFullPurchase={randomBundleData.isAvailableFullPurchaseRandomBundle}
                        isNeedToShownAdultCheckbox={isNeedToShownAdultCheckboxFlag}
                        isActiveTransaction={isActiveTransactionFlag}
                        isAdult={state.isAdult}
                        canChangeQuantity={canChangeQuantityFlag}
                        canPurchaseWholeBundle={randomBundleData.canPurchaseWholeBundle}
                        canIncrementRandomBundleAmount={randomBundleData.canIncrementQuantity}
                        hasProgressiveDiscount={randomBundleData.hasProgressiveDiscount && !!randomBundleData.maxAvailableProgressiveDiscount?.discount}
                        hasRestriction={hasRestrictionFlag}
                        hasRestrictionPurchaseForRandomBundle={randomBundleData.hasRestrictionPurchaseForRandomBundle}
                        changeUserAdultFlag={changeUserAdultFlagCallback}
                        onFullPurchaseHandler={onFullPurchaseHandler}
                        imageStyles={purchasePopupDetailsImageStyles}
                    />
                </PopupBody>
                <PopupFooter
                    className={classNames(styles.purchasePopupFooter, {
                        [styles.customPageFooter]: category?.theme === CUSTOM_PAGE,
                    })}
                >
                    {isNeedToShowExchangeMessageFlag && (
                        <div className={styles.exchangeWrapper}>
                            <span>{t('У вас недостаточно угля для покупки данного товара. Разница будет компенсирована за счет стали.')}</span>
                            <span className={styles.exchangeWrapperContent}>
                                <span>{t('По курсу:')}</span>
                                <span className={styles.exchangeWrapperText}>
                                    <Currency currency={defaultExchangeCurrency} amount={1} withoutAnimation={true} className={styles.exchangeCurrency} />
                                    <span>=</span>
                                    <Currency currency={'coal'} amount={exchangeToCurrency} withoutAnimation={true} className={styles.exchangeCurrency} />
                                </span>
                            </span>
                        </div>
                    )}
                    {hasRestrictionFlag && (
                        <div className={styles.restrictionText}>
                            <AttentionLimitWidget reason={reasonCantBoughtCode} bundle={bundle} className={styles.attentionLimitWrapper} />
                        </div>
                    )}
                    {!bundle.isPurchased && (
                        <div className={styles.purchasePopupButtonsWrapper}>
                            <div className={styles.purchaseButton}>
                                {reasonCantBoughtCode >= 0 && (
                                    <PurchaseButton
                                        bundle={isRandomBundleFlag ? randomBundleData.parentRandomBundle : bundle}
                                        parentRandomBundle={randomBundleData.parentRandomBundle}
                                        coupon={activeCoupon}
                                        label={button.label}
                                        place={FAST_GOLD_PLACE.BUNDLE_PURCHASE_POPUP}
                                        isActiveCoupon={isActiveCouponFlag}
                                        isDisabled={button.isDisabled || button.isDisabledByNotAdult}
                                        isFetching={button.isFetching}
                                        onClick={purchaseButtonClickCallback}
                                        onClickFromDisableButton={onClickFromDisableButtonCallback}
                                        buttonClassName={'armory__auto--button_purchase'}
                                        classNameFastGoldLink={styles.fastGoldLink}
                                        classNameChinaFastGoldLink={styles.fastGoldLink}
                                    />
                                )}
                                {reasonCantBoughtCode >= 0 && isAvailableWholePurchase && (
                                    <div className={styles.serialWholePurchaseLink}>
                                        <Link label={t('Купить все')} onClick={buyAllButtonClickCallback} color={LinkColors.white} />
                                    </div>
                                )}
                            </div>
                            {isMobileFlag && (
                                <div className={styles.closeButton}>
                                    <Button label={t('Закрыть')} onClick={closePopup} />
                                </div>
                            )}
                        </div>
                    )}
                    {bundle.isPurchased && (
                        <div className={styles.purchaseButtonsWrapper}>
                            <div className={styles.purchaseButtons}>{<PurchasedItem bundle={bundle} />}</div>
                        </div>
                    )}
                    {showAppearanceDisclaimerFlag && (
                        <div className={styles.disclaimer}>{t('Обратите внимание: внешний вид и описание объектов в Адмиралтействе могут иметь отличия от представленных в клиенте игры.')}</div>
                    )}
                    <div className={styles.copyrightMobile}>
                        <CopyrightContent
                            isNeedToShowCopyrightFromContainer={isNeedToShowCopyrightFromContainerFlag}
                            isNeedToShowCopyrightFromRandom={isNeedToShowCopyrightFromRandomFlag}
                            isNeedToHideDisclaimerAppearance={showAppearanceDisclaimerFlag}
                        />
                    </div>
                </PopupFooter>
            </Popup>
        </PurchaseCustomizationWrapper>
    );
};

export default ConfirmPurchase;
