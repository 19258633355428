export const normalizeString = function (str = ''): string {
    return String(str)
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u00A0/g, ' ')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ß/g, 'ss')
        .replace(/ł/g, 'l');
};

export const clearString = (str = ''): string => {
    return str.replace(/["«».'`]/gi, '');
};
