import styled, { keyframes } from 'styled-components';
import { fadeIn, zoomIn, pulse, slideInUp, slideInRight, bounceInRight, slideInLeft, tada, hinge, lightSpeedIn, jello, fadeInLeft } from 'react-animations';

const zoomInAnimation = keyframes`${zoomIn}`;
export const ZoomInDiv = styled.div`
    animation: 0.25s ${zoomInAnimation};
`;

const fadeInAnimation = keyframes`${fadeIn}`;
export const FadeInDiv = styled.div`
    animation: 0.5s ${fadeInAnimation};
`;

const pulseAnimation = keyframes`${pulse}`;
export const PulseInDiv = styled.div`
    animation: 1s ${pulseAnimation};
`;

const slideInUpAnimation = keyframes`${slideInUp}`;
export const SlideInUpDiv = styled.div`
    animation: 1s ${slideInUpAnimation};
`;

export const slideInRightAnimation = keyframes`${slideInRight}`;
export const SlideInRightDiv = styled.div`
    animation: 1s ${slideInRightAnimation};
`;

export const slideInLeftAnimation = keyframes`${slideInLeft}`;
export const SlideInLeftDiv = styled.div`
    animation: 1s ${slideInLeftAnimation};
`;

export const bounceInRightAnimation = keyframes`${bounceInRight}`;
export const BounceInRightDiv = styled.div`
    animation: 1s ${bounceInRightAnimation};
`;

export const lightSpeedInAnimation = keyframes`${lightSpeedIn}`;
export const LightSpeedInDiv = styled.div`
    animation: 1s ${lightSpeedInAnimation};
`;

export const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
export const FadeInLeftDiv = styled.div`
    animation: 1s ${fadeInLeftAnimation};
`;
