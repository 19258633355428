import * as React from 'react';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import SantaBundleBackground from './Santa/SantaBundleBackground';

interface ICardCustomization {
    bundle: IBundle;
    theme?: string;
}

const Customization = ({ bundle, theme }: ICardCustomization) => {
    switch (theme) {
        case SANTA_PAGE_THEME:
            return <SantaBundleBackground bundle={bundle} />;
    }

    return <></>;
};

export default Customization;
