import { openBundleById, openCategoryByName } from '~/utils/category';
import History from '~/utils/history';
import { settings } from '~/utils/settings';
import store from '~/Store';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { parseFiltersInQueryParams } from '~/utils/filters';
import { BANNER_LINK_TYPE } from '~/types/contents';

export class BannerUtils {
    static redirectTo(banner: BANNER_TYPES) {
        if (banner.categoryName) {
            openCategoryByName(banner.categoryName);
            return;
        }

        switch (banner.linkType) {
            case BANNER_LINK_TYPE.BUNDLE:
                openBundleById(+banner.linkValue);
                break;

            case BANNER_LINK_TYPE.CATEGORY:
                openCategoryByName(banner.linkValue);
                break;

            case BANNER_LINK_TYPE.IFRAME:
                store.dispatch<IChangeVisiblePopup>(
                    changeVisiblePopup(POPUPS_NAME.IFRAME_POPUP, true, {
                        iframeUrl: banner.linkValue,
                    }),
                );
                break;

            case BANNER_LINK_TYPE.LINK:
            default:
                if (banner.linkValue) {
                    History.navigate(`/${settings.languageCode}${banner.linkValue}`);
                    parseFiltersInQueryParams();
                }
                break;
        }
    }
}
