import * as React from 'react';
import { IBundleManager } from '~/components/Bundle/BundleManager';
import Bundle from '~/components/Bundle/Default/Bundle';
import { BundlesObserver } from '~/components/BundlesObserver/BundlesObserver';

interface ILazyRendering extends IBundleManager {
    bundles?: string[];
    bundle: IBundle;
    className?: string;
    image?: string;
    width?: string;
    isLazy?: boolean;
}

const LazyRenderingBundle = (props: ILazyRendering) => {
    const [isAllowedToRender, setAllowedToRender] = React.useState(!props.isLazy);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    const id = React.useMemo(() => {
        return `${props.bundle.id}_${props.presetName}`;
    }, [props.bundle.id, props.presetName]);

    React.useEffect(() => {
        if (!wrapperRef.current || !props.isLazy) {
            return;
        }

        BundlesObserver.observe(
            wrapperRef.current,
            (isVisible) => {
                isVisible && setAllowedToRender(true);
            },
            id,
        );

        return () => {
            return BundlesObserver.unobserve(id);
        };
    }, [id, props.isLazy]);

    return (
        <Bundle
            bundle={props.bundle}
            className={props.className}
            sizeInGrid={props.width}
            presetName={props.presetName}
            isLazilyRendered={props.isLazy ? isAllowedToRender : true}
            ref={(ref) => {
                if (ref) {
                    if (!props.isLazy) {
                        return;
                    }

                    wrapperRef.current = ref;
                }
            }}
            {...props}
        />
    );
};

export default LazyRenderingBundle;
