import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import store from '~/Store';
import equal from 'fast-deep-equal';
import { blurBackground, changeVisiblePopup } from '~/Actions/ActionApp';
import { toggleExchangeMenu } from '~/Actions/ActionMrps';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { KEYS_CODE } from '~/hooks/useKeyDown';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import MrpsReward from '../MrpsReward/MrpsReward';
import MrpsControls from '../MrpsControls/MrpsControls';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import { CopyrightContent } from '~/components/Popups/Purchase/AdditionalContent';

import styles from './MrpsExchangePopup.scss';

interface MrpsExchangePopupProps {
    isSuccessPopup?: boolean;
    isErrorPopup?: boolean;
    rewardsToShow?: MrpsLoadedReward[];
}
interface StateSelector {
    currentEvent: MrpsCurrentEventState;
    event: MrpsEventWithUpdatedRewards;
    hasPendingTransactions: boolean;
}

function stateSelector(state: State): StateSelector {
    const mrpsState = state.reducerMrps;
    const currentEvent = mrpsState.currentEvent;
    return {
        currentEvent,
        event: mrpsState.events.find((event) => event.name === currentEvent.eventName),
        hasPendingTransactions: mrpsState.hasPendingTransactions,
    };
}

interface filterOptions {
    baseProgress: number;
    currentProgress: number;
}

function prepareRewards(rewards: MrpsLoadedReward[], filterOptions?: filterOptions, isShiny?: boolean) {
    return rewards.reduce((preparedRewards, currentReward) => {
        if (
            (filterOptions &&
                filterOptions.baseProgress < currentReward.requiredProgressPoints &&
                currentReward.requiredProgressPoints <= filterOptions.currentProgress + filterOptions.baseProgress) ||
            !filterOptions
        ) {
            preparedRewards.push(
                <MrpsReward isBarItem={false} reward={currentReward} key={`reward_${currentReward.entitlements[0].identifier}_${currentReward.requiredProgressPoints}`} isAvailable={!!isShiny} />,
            );
        }

        return preparedRewards;
    }, []);
}

export default function MrpsExchangePopup(props: MrpsExchangePopupProps) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);

    const [needToRenderEsc, setNeedToRenderEsc] = useState(!state.hasPendingTransactions);
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        function escCallback(event: React.KeyboardEvent) {
            if (event.keyCode === KEYS_CODE.ESC) {
                closePopup();
            }
        }

        document.removeEventListener('keydown', escCallback.bind(document));
        document.addEventListener('keydown', escCallback.bind(document));

        return () => {
            document.removeEventListener('keydown', escCallback.bind(document));
        };
    }, [props.isErrorPopup, props.isSuccessPopup]);

    useEffect(() => {
        if (state.hasPendingTransactions) {
            setNeedToRenderEsc(false);
            timerRef.current && clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setNeedToRenderEsc(true);
                timerRef.current = null;
            }, 8000);
        } else {
            if (!needToRenderEsc) {
                setNeedToRenderEsc(true);
            }
        }
    }, [state.hasPendingTransactions]);

    function closePopup() {
        store.dispatch(changeVisiblePopup(null));

        if (props.isSuccessPopup || props.isErrorPopup) {
            store.dispatch(toggleExchangeMenu(0));
            store.dispatch(blurBackground(0));
        } else {
            dwhExport.send(DWH_EVENTS.PURCHASE_CANCEL, { event_name: state.currentEvent.eventName });
        }
    }

    const headerText = t('Подтверждение обмена');
    const exchangePriceText = t('Стоимость обмена:');
    const errorHeaderText = t('Ошибка обмена');
    const errorMessage = t('Произошла ошибка. Повторите попытку позже');
    const congratulationsHeaderText = t('Поздравляем! Обмен произведён успешно!');

    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                {needToRenderEsc && (
                    <div className={styles.closeButton}>
                        <ButtonEsc onClick={() => closePopup()} />
                    </div>
                )}
                <div className={styles.header}>{(props.isSuccessPopup && congratulationsHeaderText) || (props.isErrorPopup && errorHeaderText) || headerText}</div>
                <hr className={styles.divider} />
                <div className={styles.content}>
                    {props.rewardsToShow?.length
                        ? prepareRewards(props.rewardsToShow, undefined, true) // Success popup rewards
                        : (!props.isErrorPopup &&
                              prepareRewards(state.event.rewards, {
                                  // Confirm exchange popup rewards
                                  baseProgress: state.currentEvent.baseProgress,
                                  currentProgress: state.currentEvent.currentProgress,
                              })) || ( // Error popup text
                              <div className={styles.errorMessageWrapper}>
                                  <span className={styles.errorMessageText}>{errorMessage}</span>
                              </div>
                          )}
                </div>
                <hr className={styles.divider} />
                <div className={styles.footer}>
                    {!props.isSuccessPopup && !props.isErrorPopup && (
                        <div className={styles.resourceExchangeInfo}>
                            <span className={styles.exchangeText}>{exchangePriceText}</span>
                            <span className={styles.exchangeResources}>
                                {Object.entries(state.currentEvent.resourcesInUse).map(([resourceName, resourceAmount], index) => {
                                    if (!resourceAmount) {
                                        return null;
                                    }

                                    return (
                                        <span className={styles.exchangeResource} key={`resource_${resourceName}_${index}`}>
                                            <WoWSEntity type={resourceName} amount={resourceAmount} />
                                        </span>
                                    );
                                })}
                            </span>
                        </div>
                    )}
                    <MrpsControls isPopup isSuccessPopup={props.isSuccessPopup} isErrorPopup={props.isErrorPopup} />
                </div>
            </div>
            {!props.isSuccessPopup && !props.isErrorPopup && (
                <div className={styles.copyrightWrapper}>
                    <CopyrightContent />
                </div>
            )}
        </div>
    );
}
