import styles from './SlotLayout.scss';
import classNames from 'classnames';
import PointRight from '~/components/PointRight/PointRight';
import { isEmptyObject } from '~/utils/utils';
import { getTextAboutContinuousRewardsBySlot } from '~/Layouts/Lootboxes/utils/texts';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import { getTotalProbabilityForValuableRewards } from '~/Layouts/Lootboxes/utils';
import CommonRewardsLayout from '~/Layouts/Lootboxes/Views/CommonRewardsLayout/CommonRewardsLayout';
import ValuableRewardsLayout from '~/Layouts/Lootboxes/Views/ValuableRewardsLayout/ValuableRewardsLayout';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';

interface ISlotLayout {
    className?: string;
    slot: ILootboxStateItemSlot;
    index: number;
    lootbox: ILootboxStateItem;
    slotCount: number;
    autoDescriptionSettings: ILootboxAutoDescriptionSettings;
    theme?: ALLOWED_THEMES;
}

const SlotLayout = ({ slot, index, className, lootbox, slotCount, autoDescriptionSettings, theme }: ISlotLayout) => {
    const hasValuableRewards = !isEmptyObject(slot.valuableRewards);
    const hasCommonRewards = !isEmptyObject(slot.commonRewards);
    const continuousRewardsTooltipText = slot.continuousRewards ? getTextAboutContinuousRewardsBySlot(lootbox, index) : null;
    const isNeedToRenderSlotPoint = slotCount > 1;
    const totalProbability = getTotalProbabilityForValuableRewards(slot.valuableRewards);
    const hasSlotTitle = !!slot.title?.trim().length;
    const isNeedToShowProbability = hasSlotTitle && totalProbability > 0;
    const isNeedToRenderHeader = hasSlotTitle || isNeedToShowProbability;

    return (
        <div className={classNames(styles.content, className)}>
            {isNeedToRenderSlotPoint && (
                <div className={styles.pointerWrapper}>
                    <PointRight label={index + 1} className={styles.pointer} />
                </div>
            )}
            <div className={styles.rewardsContent}>
                {isNeedToRenderHeader && (
                    <div className={styles.header}>
                        {hasSlotTitle && (
                            <div className={styles.slotTitle}>
                                {slot.title}
                                {isNeedToShowProbability && (
                                    <span className={styles.chanceLabel}>
                                        &ensp;
                                        <ChanceLabel chance={totalProbability} isContinuousRewards={slot.continuousRewards} tooltipContent={continuousRewardsTooltipText} />
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {hasValuableRewards && (
                    <ValuableRewardsLayout
                        className={classNames(styles.valuableRewards, {
                            [styles.underPoint]: isNeedToRenderSlotPoint,
                            [styles.cursorOnLootboxCard]: true,
                        })}
                        rewards={slot.valuableRewards}
                        slotIndex={index}
                        lootbox={lootbox}
                        continuousRewardsTooltipText={continuousRewardsTooltipText}
                        autoDescriptionSettings={autoDescriptionSettings}
                        theme={theme}
                    />
                )}
                {hasCommonRewards && (
                    <CommonRewardsLayout
                        rewards={slot.commonRewards}
                        slotIndex={index}
                        lootbox={lootbox}
                        autoDescriptionSettings={autoDescriptionSettings}
                        hasValuableRewards={hasValuableRewards}
                        theme={theme}
                    />
                )}
            </div>
        </div>
    );
};

export default SlotLayout;
