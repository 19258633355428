import { items as ITEMS } from '@wg/wows-entities/const';
import classNames from 'classnames';
import * as React from 'react';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { ItemsContainsTooltip } from '~/components/Tooltip/Tooltips';
import styles from './RandomBundleHistoryCarousel.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import RandomBundleShinyAnimation from '~/Layouts/RandomBundlePage/RandomBundleHistoryCarousel/RandomBundleShinyAnimation';
import BundleTitle from '~/components/BundleTitle/BundleTitle';

interface IRandomBundleHistoryCarouselItem {
    item: IBundle;
    isSelected?: boolean;
    onClick: () => void;
    isPurchased?: boolean;
    isPending?: boolean;
    shinyAnimation: RandomBundleShinyAnimationType;
}

const isNeedToRenderTooltip = (items: IBundleEntity[]) => {
    return items.some((item: IBundleEntity) => (item.identifier && item.type !== ITEMS.TOKEN) || (item.type && item.type !== ITEMS.TOKEN));
};

const RandomBundleHistoryCarouselItem = ({ item, isSelected, onClick, isPurchased, isPending, shinyAnimation }: IRandomBundleHistoryCarouselItem) => {
    const isNeededToRenderTooltip = isNeedToRenderTooltip(item.entitlements);
    const tooltipBody = isNeededToRenderTooltip ? <ItemsContainsTooltip bundle={item} /> : <DefaultTooltip text={item.title} />;

    const _onClick = () => onClick?.();
    const isEnabledShinyAnimation = shinyAnimation?.isEnabled !== false && isSelected;

    return (
        <React.Fragment>
            {isPurchased && <div className={styles.purchased} />}
            <DivTooltip
                tooltipBody={tooltipBody}
                className={classNames(styles.itemContent, styles.bundleBlock, { [styles.selectedBundle]: isSelected, [styles.isPurchased]: isPurchased })}
                onClick={_onClick}
                style={{ cursor: 'pointer' }}
            >
                <div className={styles.imageWrapper}>
                    <RandomBundleShinyAnimation isEnabled={isEnabledShinyAnimation} shinyAnimation={shinyAnimation} />
                    <div className={styles.image} style={{ backgroundImage: `url(${item.icons.small})` }} />
                </div>
                <div className={styles.itemInfo}>
                    <div className={styles.itemTitleWrapper}>
                        <BundleTitle bundle={item} className={classNames(styles.itemTitle, styles.itemTitleObtained)} />
                    </div>
                </div>
            </DivTooltip>
        </React.Fragment>
    );
};

export default React.memo(RandomBundleHistoryCarouselItem);
