import * as React from 'react';
import styles from './RandomBundleItem.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import { ItemsContainsTooltip } from '~/components/Tooltip/Tooltips';
import { getStaticUrl, isEmptyObject } from '~/utils/utils';
import BundleTitle from '~/components/BundleTitle/BundleTitle';
import QuantityWidget from '~/components/QuantityWidget/QuantityWidget';

interface IRandomBundleItem {
    bundle: IBundle;
    parentRandomBundle: IBundle;
    amount?: number;
    className?: string;
    animationStyles?: React.CSSProperties;
    flipAnimationStyles?: React.CSSProperties;
    flipFrontCardStyles?: React.CSSProperties;
}

const RandomBundleItem = ({ bundle, parentRandomBundle, amount, className, animationStyles, flipAnimationStyles, flipFrontCardStyles }: IRandomBundleItem) => {
    const tooltipBody = <ItemsContainsTooltip bundle={bundle} />;
    const [style, setStyle] = React.useState<React.CSSProperties>(null);
    const [flipStyles, setFlipStyle] = React.useState<React.CSSProperties>(null);
    const [flipStylesFrontCard, setFlipFrontStyle] = React.useState<React.CSSProperties>(null);
    const ref = React.useRef<HTMLDivElement>(null);
    const refFrontCard = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!isEmptyObject(animationStyles)) {
            setStyle(animationStyles);
        }
        if (!isEmptyObject(flipAnimationStyles)) {
            setFlipStyle(flipAnimationStyles);
        }
        if (!isEmptyObject(flipFrontCardStyles)) {
            setFlipFrontStyle(flipFrontCardStyles);
        }
    }, []);

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    const setRefFrontCard = (_ref: HTMLDivElement) => {
        if (_ref) {
            refFrontCard.current = _ref;
        }
    };

    const icons = parentRandomBundle.icons as RandomBundleIcons;
    const cubeImage = icons.randomPartialPurchaseImage || getStaticUrl(require('../../assets/images/cube_random_bundle.svg'));

    const isAllowedQuantity = amount > 1;

    const classesInfo = classNames(styles.info, {
        [styles.withQuantity]: isAllowedQuantity,
    });

    return (
        <div className={classNames(styles.item, className)} style={style}>
            <div className={styles.innerContent} style={flipStyles} ref={setRef}>
                <div className={styles.frontContent} ref={setRefFrontCard} style={flipStylesFrontCard}>
                    <img src={cubeImage} className={styles.image} />
                    <div className={classNames(styles.info, styles.hidden)}>
                        <div className={styles.infoTitle} dangerouslySetInnerHTML={{ __html: bundle.title }} />
                        {isAllowedQuantity && (
                            <div className={styles.quantityWidget}>
                                <QuantityWidget amount={amount} />
                            </div>
                        )}
                    </div>
                </div>
                <DivTooltip className={styles.backContent} tooltipBody={tooltipBody}>
                    <div className={styles.imageWrapper}>
                        <img src={bundle.icons.small} className={styles.image} />
                    </div>
                    <div className={classesInfo}>
                        <BundleTitle bundle={bundle} className={styles.infoTitle} />
                        {isAllowedQuantity && (
                            <div className={styles.quantityWidget}>
                                <QuantityWidget amount={amount} />
                            </div>
                        )}
                    </div>
                </DivTooltip>
            </div>
        </div>
    );
};

export default RandomBundleItem;
