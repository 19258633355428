import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items } from '@wg/wows-entities/const';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { loadLootbox } from '~/Actions/ActionLootbox';
import { IComponentReactProps } from '@wg/wows-entities/types';
import { useSavePointInfo } from '~/hooks/useSavePointInfo';

interface IStateSelector {
    lootboxes: ILootboxes;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        lootboxes: state.ReducerLootbox.lootboxes,
    };
};

interface LoadAmountToReward extends IComponentReactProps {
    bundle: IBundle;
}

export const LoadAmountToReward = ({ bundle, ...weProps }: LoadAmountToReward) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    let primaryItem: any, primaryItemId: any, currentLootbox: any;

    if (!bundle.entitlements) {
        primaryItem = bundle;
        primaryItemId = primaryItem?.identifier;
        currentLootbox = state.lootboxes[primaryItem];
    } else {
        primaryItem = bundle.entitlements.find((item: IBundleEntity) => item.type === items.LOOTBOX && item.isPrimary);
        primaryItemId = primaryItem?.identifier;
        currentLootbox = state.lootboxes[primaryItemId];
    }

    React.useEffect(() => {
        if (!currentLootbox && primaryItem?.type === items.LOOTBOX) {
            // @ts-ignore
            dispatch(loadLootbox(primaryItemId || primaryItem));
        }
    }, [currentLootbox]);

    const [amountToReward] = useSavePointInfo(primaryItem?.type === items.LOOTBOX ? primaryItem?.identifier : 0);
    // @ts-ignore
    return <WoWSEntity {...weProps} customisation={{ ...weProps.customisation, countToReward: amountToReward }} />;
};
