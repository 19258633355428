import * as React from 'react';

import styles from './CustomCustomization.scss';

interface CustomPopupCustomizationProps {
    children: React.ReactChild | React.ReactChild[];
}

export function CustomPopupCustomization({ children }: CustomPopupCustomizationProps) {
    return (
        <React.Fragment>
            <div className={styles.customizationWrapper}>{children}</div>
        </React.Fragment>
    );
}
