import React from 'react';
import classNames from 'classnames';

import { playButtonClickSound, setScrollover } from '~/api/WoWsClient';
import { getItemById, IPortContainItem } from '../../settings';
import { t } from '~/utils/localization';

import { IContainsItem } from '~/components/ItemContains/Contains';
import { PortContentBlockTabContent, PortContentBlockTabType } from './PortContentBlockTab/PortContentBlockTabContent';

import styles from './PortContentBlock.scss';

interface PortContentBlockProps {
    shipId: number;
    exteriorId: number;
    itemType: string;
    items?: IPortContainItem[] | null;
    options?: {
        isAvailableContainsWidget?: boolean;
        containsItems?: Array<IContainsItem>;
    };
}

const contentBlockTabsMap: Record<PortContentBlockTabType, boolean> = {
    [PortContentBlockTabType.SHIP_INFO]: false,
    [PortContentBlockTabType.SHIP_FEATURES]: false,
    [PortContentBlockTabType.CONTAINS]: false,
};

function getAvailableTabs({ shipId, exteriorId, items, options }: PortContentBlockProps) {
    const result = { ...contentBlockTabsMap };

    if (exteriorId >= 0) {
        const portItem = getItemById(exteriorId, items);

        if (portItem) {
            const isExteriorForShipInBundle = items?.some((item) => Number(item.itemId) === Number(shipId));
            if (isExteriorForShipInBundle) {
                result[PortContentBlockTabType.SHIP_INFO] = true;
                result[PortContentBlockTabType.SHIP_FEATURES] = true;
            }
        }
    } else {
        result[PortContentBlockTabType.SHIP_INFO] = true;
        result[PortContentBlockTabType.SHIP_FEATURES] = true;
    }

    if (options?.isAvailableContainsWidget) {
        result[PortContentBlockTabType.CONTAINS] = true;
    }

    return Object.entries(result).reduce((tabs, [tabName, status]) => {
        if (status) {
            tabs.push(tabName);
        }

        return tabs;
    }, []);
}

function getDefaultActiveTab(tabs: Array<PortContentBlockTabType>) {
    //* As default show ship_features tab, if its available, otherwise - first tab
    if (tabs.includes(PortContentBlockTabType.SHIP_FEATURES)) {
        return PortContentBlockTabType.SHIP_FEATURES;
    }

    return tabs[0];
}

export function PortContentBlock(props: PortContentBlockProps) {
    const { shipId, options } = props;

    const availableTabs = React.useMemo(() => {
        return getAvailableTabs(props);
    }, [props]);

    const [activeTab, setActiveTab] = React.useState(() => getDefaultActiveTab(availableTabs));

    const tabClickHandler = React.useCallback((tabName: PortContentBlockTabType) => {
        setActiveTab((prev) => {
            if (prev !== tabName) {
                playButtonClickSound();

                return tabName;
            }

            return prev;
        });
    }, []);

    const mouseEnterHandler = React.useCallback(() => {
        setScrollover(true);
    }, []);

    const mouseLeaveHandler = React.useCallback(() => {
        setScrollover(false);
    }, []);

    React.useEffect(() => {
        setActiveTab((prev) => {
            if (availableTabs.includes(prev)) return prev;

            return getDefaultActiveTab(availableTabs);
        });
    }, [availableTabs]);

    if (!availableTabs.length) {
        return null;
    }

    return (
        <div className={styles.wrapper} onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
            <div className={styles.tabsWrapper}>
                {availableTabs.map((tabName) => (
                    <button
                        className={classNames(styles.tab, {
                            [styles.tab_active]: tabName === activeTab,
                            [styles.tab_single]: availableTabs.length === 1,
                        })}
                        onClick={tabClickHandler.bind(null, tabName)}
                    >
                        {t(tabName)}
                    </button>
                ))}
            </div>
            <PortContentBlockTabContent type={activeTab} shipId={shipId} containsItems={options.containsItems} />
        </div>
    );
}
