import { Dispatch } from 'redux';
import { AppState, IServiceIcons } from '~/Reducers/ReducerApp';
import { getBundlesAndCategoriesMap } from '~/utils/bundles';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { State } from '~/Reducers';
import { VIDEO_NAMES } from '~/components/VideoPlayer/videoMap';
import { filteredByFilters } from '~/utils/filters';
import { CLIENT_BUTTON_COPYRIGHT_BY_ROUTE, DWH_EVENTS } from '~/const';
import { getPresetByName } from '~/utils/category';
import {
    IChangeCurrentPage,
    IChangeFilter,
    IChangeVisiblePort,
    ICurrentPage,
    IFinishRequest,
    IDisableCategory,
    IResetFilter,
    IUpdateQuantity,
    ISetFetching,
    ISetRandomBundleAnimation,
    IChangeViewBackground,
    ISetVisibleTopPanel,
    IResetCategoryFilter,
    IShowParallaxAnimationShip,
    IOnSearch,
    IResetSearch,
    IStartVideo,
    IFinishVideo,
    IChangeMultipleFilter,
    ActionApp,
    IUpdateBundles,
    ITriggerGuideShow,
    IBlurView,
    IHiddenMobileNavigate,
    ISelectGroupBundle,
    ISetMenu,
    IHiddenMenuItem,
    ISetCurrentNotificationName,
    IChangeCurrenciesDiapasonFilter,
    IUpdateActiveFilterPreset,
    ISetSortMethod,
    IResetSortCategory,
    IChangeFiltersPosition,
    IChangeMultipleCurrencyDiapason,
    CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES,
    ISetFiltersByQueryParams,
    SET_FILTERS_BY_QUERY,
    IUpdateBundlePricesInfo,
    IUpdateCategoryByFilter,
    IInitClientSource,
    IRemoveClientSource,
    ISetTrusted,
    ISetIframePopupName,
    IUpdateAnimationStatusCategory,
    IFinishLoadingResources,
    IUpdateSoundStatus,
    IShowMenuItem,
    ISetMenuVisibility,
    ISetFullscreen,
    IUpdateBundleList,
    IUpdateBundlesPurchasedInfo,
    ISetBundleIsPurchased,
    IBlurBackground,
    BlurBackgroundStatus,
    IUpdateCustomPageSettings,
    IUpdateCustomPageBackgroundSizes,
    IUpdateCustomPagePopupBackground,
    IUpdatePopupSettings,
    IUpdateNestedPopupSettings,
    IToggleCategoryTimer,
    IHideEscView,
    IIframeSyncEnabled,
    ISetEmotionFromBoxSource,
} from '~/Actions/ActionAppType';
import { AccountState } from '~/Reducers/ReducerAccount';
import web2clientapi from '@wg/web2clientapi/sound';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { changeTTXVisibility } from '@wg/web2clientapi/port/changeTTXVisibility';
import { FilterPosition, ISortMethods, PRESETS, SortNames } from '~/types/category';
import dwhExport from '~/api/dwhExport';
import { BackgroundSizes, CustomPageSettings } from '~/types/customPage';

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE';
export const changeCurrentPage = (item: ICurrentPage): IChangeCurrentPage => {
    return {
        type: CHANGE_CURRENT_PAGE,
        currentPage: item,
        isBlurBackground: 2,
    };
};

export const DATA_INITIALIZE = 'DATA_INITIALIZE';
export const dataInitialize = (
    response: { nations: any; vehicleTypes: any; data: IBundleList; currencies: any; complexities: Array<IComplexity>; serviceIcons: IServiceIcons },
    purchasedLimitedBundles: AccountPurchasedLimitedBundles,
    deniedBundlesByUniqueItems: number[],
): IFinishRequest => {
    return {
        type: DATA_INITIALIZE,
        response,
        purchasedLimitedBundles,
        deniedBundlesByUniqueItems,
    };
};

export const CHANGE_VISIBLE_PORT = 'CHANGE_VISIBLE_PORT';
export function changeVisiblePort(port: IPort): IChangeVisiblePort {
    return {
        type: CHANGE_VISIBLE_PORT,
        port,
    };
}

export const SET_EMOTION_FROM_BOX_SOURCE = 'SET_EMOTION_FROM_BOX_SOURCE';
export const setEmotionFromBoxSource = (emotionFromBoxSource?: string): ISetEmotionFromBoxSource => {
    return {
        type: SET_EMOTION_FROM_BOX_SOURCE,
        emotionFromBoxSource,
    };
};

export const CHANGE_FILTER = 'CHANGE_FILTER';
export const changeFilter = (category: ICategoryList, name: FILTER_INFO_NAME, value: string): IChangeFilter => {
    return {
        type: CHANGE_FILTER,
        category,
        name,
        value,
    };
};

export const RESET_FILTER = 'RESET_FILTER';
export const resetFilter = (category: string): IResetFilter => {
    return {
        type: RESET_FILTER,
        category,
    };
};

export const RESET_CATEGORY_FILTER = 'RESET_CATEGORY_FILTER';
export const resetCategoryFilter = (category: string, withoutUpdateHistory = false): IResetCategoryFilter => {
    return {
        type: RESET_CATEGORY_FILTER,
        category,
        withoutUpdateHistory,
    };
};

export const UPDATE_CATEGORY_BY_FILTERS = 'UPDATE_CATEGORY_BY_FILTERS';
export const updateCategoryByFilters =
    (category: ICategoryList, filterName?: FILTER_INFO_NAME, filterValue?: string): any =>
    (dispatch: Dispatch, getState: () => State) => {
        const appState: AppState = getState().ReducerApp;
        const accountState: AccountState = getState().ReducerAccount;
        const filters = appState.filters?.[category];
        let bundles: number[] = getBundlesAndCategoriesMap(appState.bundles, appState.categories)[category];
        if (appState.activePreset) {
            const config = getPresetByName(appState.categories[category].filters.presets, appState.activePreset);
            if (config?.type !== PRESETS.ALL) {
                bundles = config.bundles || [];
            }
        }

        const filteredBundles = filteredByFilters(bundles, filters, appState.bundles, appState.filtersDiapasonCurrency?.[category], accountState?.coupons || []);
        const availableBundles =
            filteredBundles?.reduce((state: IBundleList, bundleId) => {
                const bundle = appState.bundles[bundleId];
                state[bundle.id] = bundle;
                return state;
            }, {}) || {};

        dispatch<IUpdateCategoryByFilter>({
            type: UPDATE_CATEGORY_BY_FILTERS,
            category,
            bundles: filteredBundles,
            bundlesList: availableBundles,
            filterName,
            filterValue,
        });
    };

export const CHANGE_VISIBLE_POPUP = 'CHANGE_VISIBLE_POPUP';
export const changeVisiblePopup =
    (name: string, isVisible = false, data?: any): any =>
    (dispatch: Dispatch<ActionApp>, getState: () => State) => {
        const appState: AppState = getState().ReducerApp;
        if (!appState.port?.isVisible) {
            if (isVisible) {
                updateBrowserControlState(true);
            } else if (appState.popups.length <= 1 || name === 'all') {
                updateBrowserControlState(false);
            }
        } else {
            if (appState?.port?.showTTC && appState.popups.length <= 1) {
                changeTTXVisibility(!isVisible);
            }
        }

        dispatch({
            type: CHANGE_VISIBLE_POPUP,
            name,
            isVisible,
            data,
        });
    };

export const hidePopup = () => {
    return changeVisiblePopup('all', false);
};

export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const updateQuantity = (bundleId: number, quantity: number, quantityData: IQuantityData): IUpdateQuantity => {
    return {
        type: UPDATE_QUANTITY,
        bundleId,
        quantity,
        quantityData,
    };
};

export const DISABLE_CATEGORY = 'DISABLE_CATEGORY';
export const disableCategory = (category: string): IDisableCategory => {
    return {
        type: DISABLE_CATEGORY,
        category,
    };
};

export const IS_FETCHING = 'IS_FETCHING';
export const setFetching = (isFetching: boolean): ISetFetching => {
    return {
        type: IS_FETCHING,
        isFetching,
    };
};

export const SET_VISIBLE_INFO_SCREEN = 'SET_VISIBLE_INFO_SCREEN';
export const setVisibleInfoScreen =
    (isVisible: boolean, name?: string, isNotTriggerClientEvent = false): any =>
    (dispatch: Dispatch<ActionApp>, getState: () => State) => {
        const appState: AppState = getState().ReducerApp;
        if (!appState.port?.isVisible && !isNotTriggerClientEvent) {
            if (isVisible) {
                updateBrowserControlState(true);
                dwhExport.send(DWH_EVENTS.OPEN_NOTIFICATIONS, {
                    notification_name: name,
                });
            } else if (appState.popups.length <= 1) {
                updateBrowserControlState(false);
            }
        }

        dispatch({
            type: SET_VISIBLE_INFO_SCREEN,
            isVisible,
            name,
        });
    };

export const SET_RANDOM_BUNDLE_ANIMATION = 'SET_RANDOM_BUNDLE_ANIMATION';
export const setRandomBundleAnimation = (showAnimation: boolean): ISetRandomBundleAnimation => {
    return {
        type: SET_RANDOM_BUNDLE_ANIMATION,
        showAnimation,
    };
};

export const CHANGE_VIEW_BACKGROUND = 'CHANGE_VIEW_BACKGROUND';
export const changeViewBackground = (className: string, background?: string): IChangeViewBackground => {
    return {
        type: CHANGE_VIEW_BACKGROUND,
        className,
        background,
    };
};

export const SET_VISIBLE_TOP_PANEL = 'SET_VISIBLE_TOP_PANEL';
export const setVisibleTopPanel = (isVisible = false): ISetVisibleTopPanel => {
    return {
        type: SET_VISIBLE_TOP_PANEL,
        isVisible,
    };
};

export const SHOW_PARALLAX_ANIMATION_SHIP = 'SHOW_PARALLAX_ANIMATION_SHIP';
export const showParallaxAnimationShip = (bundleId: number): IShowParallaxAnimationShip => {
    return {
        type: SHOW_PARALLAX_ANIMATION_SHIP,
        bundleId,
    };
};

export const ON_SEARCH = 'ON_SEARCH';
export const onSearch = (value: string): IOnSearch => {
    return {
        type: ON_SEARCH,
        value,
    };
};

export const RESET_SEARCH = 'RESET_SEARCH';
export const resetSearch = (): IResetSearch => {
    return {
        type: RESET_SEARCH,
    };
};

export const START_VIDEO = 'START_VIDEO';
export const startVideo = (name: VIDEO_NAMES | string, url?: string, onFinish?: () => void, fadeOutVideoInSeconds?: number, volume?: number, isVideoEffect?: boolean): IStartVideo => {
    updateBrowserControlState(true);
    web2clientapi.shipyardSoundOff();
    return {
        type: START_VIDEO,
        name,
        url,
        onFinish,
        fadeOutVideoInSeconds,
        volume,
        isVideoEffect,
    };
};

export const FINISH_VIDEO = 'FINISH_VIDEO';
export const finishVideo = (): IFinishVideo => {
    updateBrowserControlState(false);
    web2clientapi.shipyardSoundOn();
    return {
        type: FINISH_VIDEO,
    };
};

export const CHANGE_MULTIPLE_FILTER = 'CHANGE_MULTIPLE_FILTER';
export const changeMultipleFilter = (
    category: ICategoryList,
    data: {
        [key: string]: string[];
    },
    triggerFilterName?: FILTER_INFO_NAME,
): IChangeMultipleFilter => {
    return {
        type: CHANGE_MULTIPLE_FILTER,
        triggerFilterName,
        category,
        data,
    };
};

export const UPDATE_BUNDLES = 'UPDATE_BUNDLES';
export const updateBundles = (purchasedLimitedBundles: AccountPurchasedLimitedBundles, deniedBundlesByUniqueItems: number[], coupons: ICoupon[]): IUpdateBundles => {
    return {
        type: UPDATE_BUNDLES,
        purchasedLimitedBundles,
        deniedBundlesByUniqueItems,
        coupons,
    };
};

export const TRIGGER_GUIDE_SHOW = 'TRIGGER_GUIDE_SHOW';
export const triggerGuide = (guideName: GUIDE_NAMES): ITriggerGuideShow => {
    return {
        type: TRIGGER_GUIDE_SHOW,
        name: guideName,
    };
};

export const BLUR_VIEW = 'BLUR_VIEW';
export const blurView = (isBlur = false): IBlurView => {
    return {
        type: BLUR_VIEW,
        isBlur,
    };
};

export const HIDE_ESC = 'HIDE_ESC';
export const hideEsc = (hide = true): IHideEscView => {
    return {
        type: HIDE_ESC,
        hide,
    };
};

export const IFRAME_SYNC_ENABLED = 'IFRAME_SYNC_ENABLED';
export const changeIframeSyncEnabled = (enabled = false): IIframeSyncEnabled => {
    return {
        type: IFRAME_SYNC_ENABLED,
        enabled,
    };
};

export const BLUR_BACKGROUND = 'BLUR_BACKGROUND';
export function blurBackground(isBlurBackground: BlurBackgroundStatus = 2): IBlurBackground {
    return {
        type: BLUR_BACKGROUND,
        isBlurBackground: isBlurBackground,
    };
}

export const HIDDEN_MOBILE_NAVIGATE = 'HIDDEN_MOBILE_NAVIGATE';
export const hiddenMobileNavigate = (isHidden: boolean): IHiddenMobileNavigate => {
    return {
        type: HIDDEN_MOBILE_NAVIGATE,
        isHidden,
    };
};

export const SELECT_GROUP_BUNDLE = 'SELECT_GROUP_BUNDLE';
export const selectGroupBundle = (group: string, id: number): ISelectGroupBundle => {
    return {
        type: SELECT_GROUP_BUNDLE,
        group,
        id,
    };
};

export const SET_MENU = 'SET_MENU';
export const setMenu = (menu: IMenuMap): ISetMenu => {
    return {
        type: SET_MENU,
        menu,
    };
};

export const HIDDEN_MENU_ITEM = 'HIDDEN_MENU_ITEM';
export const hiddenMenuItem = (menuItemKey: string): IHiddenMenuItem => {
    return {
        type: HIDDEN_MENU_ITEM,
        menuItemKey,
    };
};

export const SHOW_MENU_ITEM = 'SHOW_MENU_ITEM';
export const showMenuItem = (menuItemKey: string): IShowMenuItem => {
    return {
        type: SHOW_MENU_ITEM,
        menuItemKey,
    };
};

export const INIT_CLIENT_SOURCE = 'INIT_CLIENT_SOURCE';
export const initClientSource = (source: keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE): IInitClientSource => {
    return {
        type: INIT_CLIENT_SOURCE,
        source,
    };
};

export const REMOVE_CLIENT_SOURCE = 'REMOVE_CLIENT_SOURCE';
export const removeClientSource = (): IRemoveClientSource => {
    return {
        type: REMOVE_CLIENT_SOURCE,
    };
};

export const SET_CURRENT_NOTIFICATION_NAME = 'SET_CURRENT_NOTIFICATION_NAME';
export const setCurrentNotificationName = (name: string): ISetCurrentNotificationName => {
    return {
        type: SET_CURRENT_NOTIFICATION_NAME,
        name,
    };
};

export const CHANGE_CURRENCIES_DIAPASON_FILTER = 'CHANGE_CURRENCIES_DIAPASON_FILTER';
export const changeCurrenciesDiapasonFilter = (category: ICategoryList, currency: string, max: number, min: number): IChangeCurrenciesDiapasonFilter => {
    return {
        type: CHANGE_CURRENCIES_DIAPASON_FILTER,
        category,
        currency,
        min,
        max,
    };
};

export const UPDATE_ACTIVE_FILTER_PRESET = 'UPDATE_ACTIVE_FILTER_PRESET';
export const updateActiveFilterPreset = (preset: string, withoutUpdateHistory = false): IUpdateActiveFilterPreset => {
    return {
        type: UPDATE_ACTIVE_FILTER_PRESET,
        preset,
        withoutUpdateHistory,
    };
};

export const SET_SORT_METHOD = 'SET_SORT_METHOD';
export const setSortMethod = (category: ICategoryList, name: SortNames, method: ISortMethods): ISetSortMethod => {
    return {
        type: SET_SORT_METHOD,
        category,
        name,
        method,
    };
};

export const RESET_SORT_CATEGORY = 'RESET_SORT_CATEGORY';
export const resetSortCategory = (category: ICategoryList): IResetSortCategory => {
    return {
        type: RESET_SORT_CATEGORY,
        category,
    };
};

export const CHANGE_FILTERS_POSITION = 'CHANGE_FILTERS_POSITION';
export const changeFiltersPosition = (position: FilterPosition): IChangeFiltersPosition => {
    return {
        type: CHANGE_FILTERS_POSITION,
        position,
    };
};

export const CHANGE_MULTIPLE_CURRENCY_DIAPASON = 'CHANGE_MULTIPLE_CURRENCY_DIAPASON';
export const changeMultipleCurrencyDiapason = (category: ICategoryList, values: CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES): IChangeMultipleCurrencyDiapason => {
    return {
        type: CHANGE_MULTIPLE_CURRENCY_DIAPASON,
        category,
        values,
    };
};

export const SET_FILTERS_BY_QUERY_PARAMS = 'SET_FILTERS_BY_QUERY_PARAMS';
export const setFiltersByQueryParams = (category: ICategoryList, params: SET_FILTERS_BY_QUERY): ISetFiltersByQueryParams => {
    return {
        type: SET_FILTERS_BY_QUERY_PARAMS,
        category,
        ...params,
    };
};

export const UPDATE_BUNDLE_PRICES_INFO = 'UPDATE_BUNDLE_PRICES_INFO';
export const updateBundlePricesInfo = (category: ICategoryList, bundles: IBundleList): IUpdateBundlePricesInfo => {
    return {
        type: UPDATE_BUNDLE_PRICES_INFO,
        category,
        bundles,
    };
};

export const SET_IFRAME_POPUP_NAME = 'SET_IFRAME_POPUP_NAME';
export const setIframePopupName = (name: string): ISetIframePopupName => {
    return {
        type: SET_IFRAME_POPUP_NAME,
        name,
    };
};

export const SET_TRUSTED = 'SET_TRUSTED';
export const setTrusted = (): ISetTrusted => {
    return {
        type: SET_TRUSTED,
    };
};

export const UPDATE_ANIMATION_STATUS_CATEGORY = 'UPDATE_ANIMATION_STATUS_CATEGORY';
export const updateAnimationStatusCategory = (categoryName: ICategoryList, status: boolean): IUpdateAnimationStatusCategory => {
    return {
        type: UPDATE_ANIMATION_STATUS_CATEGORY,
        categoryName,
        status,
    };
};

export const UPDATE_SOUND_STATUS = 'UPDATE_SOUND_STATUS';
export const updateSoundStatus = (soundKey: string, status: boolean): IUpdateSoundStatus => {
    return {
        type: UPDATE_SOUND_STATUS,
        soundKey,
        status,
    };
};

export const FINISH_LOADING_RESOURCES = 'FINISH_LOADING_RESOURCES';
export const finishLoadingResources = (): IFinishLoadingResources => {
    return {
        type: FINISH_LOADING_RESOURCES,
    };
};

export const SET_MENU_VISIBILITY = 'SET_MENU_VISIBILITY';
export const setMenuVisibility = (isVisible = false): ISetMenuVisibility => {
    return {
        type: SET_MENU_VISIBILITY,
        isVisible,
    };
};

export const SET_FULLSCREEN = 'SET_FULLSCREEN';
export const setFullscreen = (isVisible = false): ISetFullscreen => {
    return {
        type: SET_FULLSCREEN,
        isVisible,
    };
};

export const UPDATE_BUNDLE_LIST = 'UPDATE_BUNDLE_LIST';
export const updateBundleList = (bundles: IBundleList): IUpdateBundleList => {
    return {
        type: UPDATE_BUNDLE_LIST,
        bundles,
    };
};

export const UPDATE_BUNDLES_PURCHASED_INFO = 'UPDATE_BUNDLES_PURCHASED_INFO';
export const updateBundlesPurchasedInfo = (inventory: InventoryState): IUpdateBundlesPurchasedInfo => {
    return {
        type: UPDATE_BUNDLES_PURCHASED_INFO,
        inventory,
    };
};

export const SET_BUNDLE_IS_PURCHASED = 'SET_BUNDLE_IS_PURCHASED';
export const setBundleIsPurchased = (bundleId: number): ISetBundleIsPurchased => {
    return {
        type: SET_BUNDLE_IS_PURCHASED,
        bundleId,
    };
};

export const UPDATE_CUSTOM_PAGE_SETTINGS = 'UPDATE_CUSTOM_PAGE_SETTINGS';
export const updateCustomPageSettings = (settings: CustomPageSettings): IUpdateCustomPageSettings => {
    return {
        type: UPDATE_CUSTOM_PAGE_SETTINGS,
        customPageSettings: settings,
    };
};

export const UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES = 'UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES';
export const UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES = 'UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES';
export const updateCustomPageBackgroundSizes = (
    sizes: BackgroundSizes,
    type: typeof UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES | typeof UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES,
): IUpdateCustomPageBackgroundSizes => {
    return {
        type: type,
        sizes,
    };
};

export const UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND = 'UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND';
export const updateCustomPagePopupBackground = (url: string): IUpdateCustomPagePopupBackground => {
    return {
        type: UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND,
        url,
    };
};

export const UPDATE_POPUP_SETTINGS = 'UPDATE_POPUP_SETTINGS';
export const updatePopupSettings = (popupSettings: PopupSettings): IUpdatePopupSettings => {
    return {
        type: UPDATE_POPUP_SETTINGS,
        popupSettings,
    };
};

export const UPDATE_NESTED_POPUP_SETTINGS = 'UPDATE_NESTED_POPUP_SETTINGS';
export const updateNestedPopupSettings = (nestedPopup: PopupSettings['nestedPopup']): IUpdateNestedPopupSettings => {
    return {
        type: UPDATE_NESTED_POPUP_SETTINGS,
        nestedPopup,
    };
};

export const TOGGLE_CATEGORY_TIMER = 'TOGGLE_CATEGORY_TIMER';
export const toggleCategoryTimer = (categoryName: string, value: boolean): IToggleCategoryTimer => {
    return {
        type: TOGGLE_CATEGORY_TIMER,
        categoryName,
        value,
    };
};
