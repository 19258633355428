import * as React from 'react';
import styles from './Price.scss';
import classNames from 'classnames';
import Currency from '~/components/Currency/Currency';
import { t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '../Tooltip/DefaultTooltip';

interface IPrice {
    currency?: string;
    amount?: number | string;
    className?: string;
    isOld?: boolean;
    isBigSize?: boolean;
    withoutAnimation?: boolean;
    showDescriptionInPriceTooltip?: boolean;
    isFree?: boolean;
    withoutIcon?: boolean;
    withoutTooltip?: boolean;
    isRealPurchase?: boolean;
    formattedPrice?: string;
}

const Price = ({ currency, amount, className, isOld, withoutAnimation, showDescriptionInPriceTooltip, isBigSize, isFree, withoutIcon, withoutTooltip, isRealPurchase, formattedPrice }: IPrice) => {
    if (isFree) {
        return <div className={classNames(styles.priceFree, className)}>{t('Бесплатно')}</div>;
    }

    if (isRealPurchase && formattedPrice) {
        return <div className={classNames(styles.realPrice, className)} dangerouslySetInnerHTML={{ __html: formattedPrice }} />;
    }

    const classes = classNames(styles.price, className, {
        [styles.big]: isBigSize,
        [styles.old]: isOld,
        'armory__auto--bundle_price': !isOld,
        'armory__auto--bundle_old_price': isOld,
    });

    if (formattedPrice) {
        return <div className={classes} dangerouslySetInnerHTML={{ __html: formattedPrice }} />;
    }

    return (
        <div className={classes}>
            <Currency
                currency={currency}
                amount={amount}
                withoutAnimation={withoutAnimation}
                withoutIcon={withoutIcon}
                withoutTooltip={withoutTooltip}
                showDescriptionTooltip={showDescriptionInPriceTooltip}
            />
        </div>
    );
};

export default Price;
