import * as React from 'react';
import styles from './ContainerPityTimer.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import { t, interpolate } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getTextAboutSavePointForTooltip } from '~/Layouts/Lootboxes/utils/texts';
import { useDispatch } from 'react-redux';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import Info from '~/components/Info/Info';
import { useSavePointInfo } from '~/hooks/useSavePointInfo';

interface IContainerPityTimer {
    accountId: number;
    containerId: number;
    bundleName: string;
    lootbox: ILootboxStateItem;
}

const ContainerPityTimer = ({ accountId, lootbox }: IContainerPityTimer) => {
    const dispatch = useDispatch();
    const descriptionText = getTextAboutSavePointForTooltip(lootbox);

    const [amountToReward, amountOpened, amountObtained, hasLootboxes] = useSavePointInfo(lootbox);
    const tooltipBody = [descriptionText];
    let goldStyle;

    if (accountId) {
        if (amountObtained === 0 && amountOpened && hasLootboxes) {
            tooltipBody.push(interpolate(t('Открыто контейнеров: {amount}'), { amount: amountOpened.toString() }), t('Информация о количестве может обновляться с задержкой'));
            goldStyle = null;
        } else if (amountOpened === 0 && amountObtained) {
            tooltipBody.push(interpolate(t('В наличии в Порту: {amount}'), { amount: amountObtained.toString() }), t('Информация о количестве может обновляться с задержкой'));
            goldStyle = { body: styles.goldOnSecond };
        } else if (amountObtained && amountOpened) {
            tooltipBody.push(
                interpolate(t('Открыто контейнеров: {amount}'), { amount: amountOpened.toString() }),
                interpolate(t('В наличии в Порту: {amount}'), { amount: amountObtained.toString() }),
                t('Информация о количестве может обновляться с задержкой'),
            );
            goldStyle = { body: styles.goldOnThird };
        }
    }

    const text = t('До гарантированного выпадения: {number}').replace('{number}', amountToReward.toString());

    return (
        <DivTooltip
            className={styles.wrapper}
            onClick={() => {
                if (!isMobileOrTabletDeviceByAgent()) {
                    return;
                }
                dispatch<IChangeVisiblePopup>(
                    changeVisiblePopup(POPUPS_NAME.MOBILE_TOOLTIP_POPUP, true, {
                        contents: tooltipBody,
                    }),
                );
            }}
            tooltipBody={<DefaultTooltip text={tooltipBody} classes={goldStyle} />}
        >
            {text}
            <Info className={styles.label} />
        </DivTooltip>
    );
};

export default ContainerPityTimer;
