import * as React from 'react';
import styles from './Accordion.scss';
import classNames from 'classnames';
import { PreCacheImage } from '~/utils/preCacheImage';
import { BannerUtils } from '~/utils/banner';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { ACCORDION_SHOW_INTERVAL } from '~/Layouts/Banners/Accordion/Accordion';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import { BANNER_LINK_TYPE, IBannerButtonVisibility } from '~/types/contents';
import { playCardClickSound } from '~/api/WoWsClient';
import { ACCORDION_BANNER_SIZES } from '~/const';

type AccordionBanner_Type = {
    isSelected: boolean;
    banner: IAccordionBanner;
    onHover: () => void;
    onMouseLeave?: () => void;
};

interface IStateSelector {
    categories: ICategories;
    isTrusted: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
        isTrusted: state.ReducerApp.isTrusted,
    };
};

const AccordionBanner = ({ isSelected, banner, onHover, onMouseLeave }: AccordionBanner_Type) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const ref = React.useRef<HTMLDivElement>(null);
    const isHalfBannerHeight = banner.height === ACCORDION_BANNER_SIZES.HALF;

    let category;
    if (BANNER_LINK_TYPE.CATEGORY === banner.linkType) {
        category = state.categories[banner.linkValue];
    }

    const classesItem = classNames(styles.item, {
        [styles.selected]: isSelected,
        [styles['banner-half-height']]: isHalfBannerHeight,
        [category?.theme]: !!category?.theme,
    });

    const imagePosition = banner.smallImagePosition || banner.smallImagePosition == 0 ? `0 ${banner.smallImagePosition}%` : 'center';
    const backgroundStyle = {
        '--small-image': `url(${banner.smallImage})`,
        '--big-image': `url(${banner.image})`,
        backgroundImage: `var(--big-image, ${banner.image})`,
        backgroundPosition: imagePosition,
    };

    React.useEffect(() => {
        if (!isSelected && banner.smallImage && !isMobileOrTabletWindow) {
            ref.current.style.backgroundPosition = '0 0';
            ref.current.style.backgroundImage = `url(${banner.smallImage})`;
        }

        new PreCacheImage().add(banner.image);
    }, []);

    const classesFooter = classNames(styles.footer, {
        [styles.hover]: banner.buttonVisibility === IBannerButtonVisibility.HOVER,
    });

    const classesContent = classNames(styles.content, {
        [styles.hasDescription]: !!banner.description,
    });

    const handleClick = () => BannerUtils.redirectTo(banner);

    const interval = React.useRef(null);

    const mouseEnter = () => {
        if (!ref.current || !banner.smallImage) {
            onHover?.();
            return;
        }

        if (isMobileOrTabletWindow) {
            return;
        }

        interval.current = setTimeout(() => {
            ref.current.style.backgroundImage = `url(${banner.image})`;
        }, ACCORDION_SHOW_INTERVAL);

        onHover?.();
    };

    const mouseLeave = () => {
        onMouseLeave?.();
        if (!ref.current || !banner.smallImage) {
            return;
        }

        clearInterval(interval.current);
    };

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            return;
        }

        if (!isSelected && banner.smallImage) {
            setTimeout(() => {
                ref.current.style.backgroundPosition = '0 0';
                ref.current.style.backgroundImage = `url(${banner.smallImage})`;
            }, ACCORDION_SHOW_INTERVAL);
        }
    }, [isSelected]);

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    return (
        <div
            className={classesItem}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onClick={() => {
                playCardClickSound();
                handleClick();
            }}
            data-category-name={banner.categoryName}
            data-link-type={banner.linkType}
            data-link-value={banner.linkValue}
        >
            <HoverVideo isBanner={true} previewVideo={banner.previewCardVideo} video={banner.videoHover} delay={ACCORDION_SHOW_INTERVAL + 50} muted={!state.isTrusted} parentClassName={styles.item}>
                <div className={styles.image} style={backgroundStyle} ref={setRef} />
                <div className={classesContent}>
                    <div className={styles.header}>
                        <div className={styles.title}>{banner.title}</div>
                        {!!banner.description && <div className={styles.description}>{banner.description}</div>}
                    </div>
                </div>
            </HoverVideo>
        </div>
    );
};

export default AccordionBanner;
