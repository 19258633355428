import * as React from 'react';
import BundleLimitWidget, { BundleLimitSize } from '~/components/BundleLimitWidget/BundleLimitWidget';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { getSumFromArray } from '~/utils/number';
import { getContainerInfoFromTooltip } from '~/components/BundleLimitWidget/settings';
import Account from '~/account/Account';

interface IBundleLimitContainer {
    size?: BundleLimitSize;
    className?: string;
    bundle: IBundle;
}

interface IStateSelector {
    lootboxesBundlesMap: ILootboxesBundlesMap;
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
    bundles: IBundleList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        lootboxesBundlesMap: state.ReducerApp.lootboxesBundlesMap,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,
        bundles: state.ReducerApp.bundles,
    };
};

const BundleLimitContainer = ({ size, className, bundle }: IBundleLimitContainer) => {
    const isAuthorizedUser = !!Account.getAccount();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const restrictedLootboxesPurchaseCount = state.restrictedLootboxesPurchaseCount;
    const totalPurchased = getSumFromArray(Object.values(restrictedLootboxesPurchaseCount || []));
    const bundlesInfo = getContainerInfoFromTooltip(bundle, restrictedLootboxesPurchaseCount);

    if (!isAuthorizedUser) return null;

    return <BundleLimitWidget size={size} total={totalPurchased} bundlesInfo={bundlesInfo} className={className} />;
};

export default BundleLimitContainer;
