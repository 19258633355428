import * as React from 'react';
import styles from './WarnMessage.scss';

interface IWarnMessage {
    title: string;
    description: string;
}

const WarnMessage = ({ title, description }: IWarnMessage) => {
    return (
        <div className={styles.widget}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    );
};

export default WarnMessage;
