import * as React from 'react';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items } from '@wg/wows-entities/const';

import styles from './ShipConsumables.scss';

interface IShipConsumables {
    shipId: number;
}

const ShipConsumables = ({ shipId }: IShipConsumables) => {
    return (
        <div className={classNames(styles.content, styles.borderBottom)}>
            <div className={styles.title}>{t('Снаряжение')}</div>
            <div className={classNames(styles.contentBlockBody, styles.consumablesContent)}>
                <WoWSEntity
                    type={items.CONSUMABLES}
                    id={shipId}
                    presentation={{
                        withTooltip: true,
                        renderWithoutFade: true,
                    }}
                />
            </div>
        </div>
    );
};

export default ShipConsumables;
