import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './PremiumAccountLink.scss';
import { t } from '~/utils/localization';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import className from 'classnames';
import { arrayToObjectByKey } from '~/utils/utils';
import { getPremiumText } from '~/utils/auction';
import { DivTooltip } from '@wg/wows-react-uikit';
import AccountPremiumTooltip from '~/components/Tooltip/AccountPremiumTooltip';
import dwhExport from '~/api/dwhExport';
import { CATEGORIES, DWH_EVENTS } from '~/const';
import { openCategoryByName } from '~/utils/category';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IStateSelector {
    accountId: number;
    balance: IBalance;
    categories: ICategories;
    activeAuctions: IAuction[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        balance: state.ReducerAccount.balance,
        categories: state.ReducerApp.categories,
        activeAuctions: state.ReducerAuction.activeAuctions,
    };
};

const PremiumAccountLink = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const balance = state.balance;
    const balanceObject = !!balance ? arrayToObjectByKey(balance, 'currency') : null;

    const goToWoWSPremium = () => {
        playButtonClickSound();

        dwhExport.send(DWH_EVENTS.OPEN_WOWS_PREM_FROM_TOP_MENU);
        openCategoryByName(CATEGORIES.PURCHASE_WOWS_PREMIUM);
    };

    const isPremAccountCategoryEnabled = !!state.categories?.[CATEGORIES.PURCHASE_WOWS_PREMIUM];

    const renderUserContent = () => {
        if (!state.accountId) {
            return null;
        }

        const warshipsAccountPremium = balanceObject?.['warships_account_premium']?.value || 0;
        const generalAccountPremium = balanceObject?.['general_account_premium']?.value || 0;
        const hasPremium = warshipsAccountPremium > 0 || generalAccountPremium > 0;
        const accountPremiumSeconds = warshipsAccountPremium || generalAccountPremium;

        const accountPremiumText = hasPremium ? getPremiumText(state.activeAuctions, accountPremiumSeconds) : t('Купить Премиум аккаунт');

        const accountPremiumClassName = className(styles.accountPremiumWrapper, {
            [styles.withIcon]: hasPremium,
            [styles.generalAccountPremium]: !!generalAccountPremium && !warshipsAccountPremium,
        });

        return (
            <React.Fragment>
                {isPremAccountCategoryEnabled && (
                    <DivTooltip
                        tooltipBody={
                            <AccountPremiumTooltip warshipsPremiumSeconds={warshipsAccountPremium} generalPremiumSeconds={generalAccountPremium} text={t('Купить дни Корабельного премиум аккаунта')} />
                        }
                    >
                        <div className={accountPremiumClassName} onClick={goToWoWSPremium} data-has-premium={hasPremium}>
                            <span className={styles.accountPremiumText}>{accountPremiumText}</span>
                        </div>
                    </DivTooltip>
                )}
            </React.Fragment>
        );
    };

    return <>{renderUserContent()}</>;
};

export default PremiumAccountLink;
