import * as React from 'react';
import styles from './PlayIcon.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import { t } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IPlayIcon {
    className?: string;
    onClick: (isEnabled?: boolean) => void;
    isPause?: boolean;
}

const PlayIcon = ({ className, onClick, isPause }: IPlayIcon) => {
    const classes = classNames(
        styles.icon,
        {
            [styles.pause]: isPause,
        },
        className,
    );

    return (
        <DivTooltip
            className={classes}
            tooltipBody={<DefaultTooltip text={t('Воспроизвести ролик')} />}
            style={{ cursor: 'pointer' }}
            onClick={() => {
                playButtonClickSound();
                onClick(!isPause);
            }}
        />
    );
};

export default PlayIcon;
