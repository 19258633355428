export enum IPopupSoundset {
    MUTED = 'muted',
    DEFAULT = 'default',
    EXCHANGE = 'exchange',
}

export enum IButtonSoundset {
    MUTED = 'muted',
    DEFAULT = 'default',
    POPUP = 'popup',
}
