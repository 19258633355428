import * as React from 'react';
import { getPurchaseMaxCountFromRandomBundle } from '~/api/account';
import { isEnabledContainerPurchaseLimit } from '~/components/BundleLimitWidget/settings';

interface IMaxCountState {
    isLoading: boolean;
    purchaseMaxCount: number;
}

export default function usePurchaseMaxCountForRandomBundle(bundle: IBundle, deps: React.DependencyList = []) {
    const [state, setState] = React.useState<IMaxCountState>({ isLoading: false, purchaseMaxCount: null });

    React.useEffect(() => {
        if (!bundle.isRandom || !isEnabledContainerPurchaseLimit()) {
            return;
        }

        async function loadPurchaseMaxCount() {
            const response = await getPurchaseMaxCountFromRandomBundle(bundle.id);
            setState({ isLoading: false, purchaseMaxCount: response?.data });
        }

        setState({ isLoading: true, purchaseMaxCount: null });
        loadPurchaseMaxCount();
    }, deps);

    return <[boolean, number]>[state.isLoading, state.purchaseMaxCount];
}
