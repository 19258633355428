import { armoryState } from '~/utils/settings';
import store from '~/Store';
import { accountUpdateState, setCoupons } from '~/Actions/ActionAccount';
import { IAccountUpdateData } from '~/Actions/ActionAccountType';
import { toggleCategoryTimer, updateBundles } from '~/Actions/ActionApp';
import { getAccountInfo } from '~/api/account';
import { logError, logInfo } from '~/utils/logging';
import AuctionStorage from '~/core/AuctionStorage';
import { excludeCouponsForNotExistingCategories, prepareCouponsFromState } from '~/utils/coupons';
import Account from '~/account/Account';
import equal from 'fast-deep-equal/es6';
import { prepareBalanceFromState } from '~/utils/balance';
import TradeInPreloader from './core/AppInit/TradeInPreloader';
import { CouponStateType, syncContentState } from './api/content';
import { resetCouponFiltersIfNeeded } from '~/utils/category';
import { CategoryType } from './types/category';
import MrpsPreloader from './core/AppInit/MrpsPreloader';
import { isMrpsCategoryEnabled } from './utils/mrps';

const updateAccountBalanceEvery = 10000;
const skipSyncMaxCount = 3;

class IdleTracker {
    private _isActive = true;

    start() {
        if (!('visibilityState' in document)) {
            console.log('[sync]Visibility api is not supported, skip');
            return;
        }
        document.addEventListener('visibilitychange', this._onVisibilityChange.bind(this), false);
    }

    stop() {
        document.removeEventListener('visibilitychange', this._onVisibilityChange.bind(this), false);
    }

    private _onVisibilityChange() {
        this._isActive = document.visibilityState !== 'hidden';
    }

    isUserActive() {
        return this._isActive;
    }
}

export const idleTracker = new IdleTracker();

export const loadingLogger = () => {
    const period = 5000;
    const maxCount = 15;
    let currentCount = 0;

    (function run() {
        const appState = store.getState().ReducerApp;
        if (appState.isFinishedRequest) {
            logInfo('Armory loaded and working', currentCount);
        } else {
            logInfo('Armory not loaded', currentCount);
        }
        setTimeout(async () => {
            if (currentCount < maxCount) {
                currentCount++;
                run();
            }
        }, period);
    })();
};

export const syncAccountInfo = async (): Promise<IAccountUpdateData> => {
    const data = await getAccountInfo();

    if (!data) {
        return;
    }

    const options: IAccountUpdateData = {
        bundlesInTransaction: data.bundlesInTransaction,
        balance: prepareBalanceFromState(data.balance),
        deniedBundlesByUniqueItems: data.deniedBundlesByUniqueItems,
        purchasedLimitedBundles: data.purchasedLimitedBundles,
        usedCoupons: data.usedCoupons,
        selectedRandomBundles: data.selectedRandomBundles,
        randomBundlesHistory: data.randomBundlesHistory,
        openedRandomBundles: data.openedRandomBundles,
        restrictedLootboxesPurchaseCount: data.restrictedLootboxesPurchaseCount,
        ts: data.ts,
    };

    return options;
};

type SyncedArray = [IAccountUpdateData, CouponStateType];

function updateStateOnSync([newAccountData, couponsState]: SyncedArray) {
    const account = store.getState().ReducerAccount;
    const { categories } = store.getState().ReducerApp;

    if (account.ts > newAccountData.ts) {
        return;
    }

    let newCoupons = excludeCouponsForNotExistingCategories(couponsState.coupons, categories);
    newCoupons = prepareCouponsFromState(newCoupons || [], newAccountData.usedCoupons);

    const needToUpdateBundlesChecks = [
        () => equal(account.coupons, newCoupons),
        () => equal(account.usedCoupons, newAccountData.usedCoupons),
        () => equal(account.purchasedLimitedBundles, newAccountData.purchasedLimitedBundles),
        () => equal(account.deniedBundlesByUniqueItems?.sort(), newAccountData.deniedBundlesByUniqueItems?.sort()),
    ];

    Object.entries(categories).forEach(([categoryName, categoryData]) => {
        if (categoryData.activityCountdown?.activeFrom && !categoryData.activityCountdown?.isEnabled) {
            const isEnabledByActiveFrom = new Date(categoryData.activityCountdown.activeFrom).getTime() <= new Date().getTime();
            isEnabledByActiveFrom && store.dispatch(toggleCategoryTimer(categoryName, isEnabledByActiveFrom));
        }
    });

    store.dispatch(accountUpdateState(newAccountData, newCoupons));

    if (needToUpdateBundlesChecks.some((fn) => !fn())) {
        store.dispatch(updateBundles(newAccountData.purchasedLimitedBundles, newAccountData.deniedBundlesByUniqueItems, newCoupons));
    }

    setTimeout(() => resetCouponFiltersIfNeeded(), 0);
}

const sync = () => {
    if (!armoryState.account) {
        return;
    }

    let skippedSyncsCount = 0;

    (function run() {
        setTimeout(async () => {
            const account = store.getState().ReducerAccount;
            const appState = store.getState().ReducerApp;
            const tradeInEnabled = store.getState().ReducerTradeIn.lists?.length;
            const mrpsIsEnabled = Object.entries(appState.categories).find(([categoryName, categoryData]) => {
                if (categoryData.type === CategoryType.MRPS && isMrpsCategoryEnabled(categoryName)) {
                    return true;
                }

                return false;
            })?.length;

            if (!appState.isFinishedRequest) {
                return run();
            }

            if (idleTracker.isUserActive() && (!account.purchasingBundleId || skippedSyncsCount >= skipSyncMaxCount)) {
                skippedSyncsCount = 0;

                try {
                    await Promise.all([syncAccountInfo(), syncContentState()]).then(updateStateOnSync);

                    if (tradeInEnabled) {
                        await new TradeInPreloader().load();
                    }
                    if (mrpsIsEnabled) {
                        await new MrpsPreloader().sync();
                    }
                    if (AuctionStorage.isAuctionCategoryEnabled) {
                        await AuctionStorage.preloadedState();
                    }
                    await Account.loadAccountInventoryFromVrtx();
                } catch (e) {
                    logError('Account sync error', e);
                }
            } else {
                skippedSyncsCount++;
            }
            run();
        }, updateAccountBalanceEvery);
    })();
};

export default sync;
