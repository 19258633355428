import * as React from 'react';
import { getPrimaryItem } from '~/utils/bundles';
import { items } from '@wg/wows-entities/const';
import Ship from '~/components/Ship/Ship';

interface IBundleTitle {
    bundle: IBundle;
    className?: string;
}

const BundleTitle = ({ bundle, className }: IBundleTitle) => {
    const primaryItem = getPrimaryItem(bundle);
    if (!primaryItem) {
        return <div className={className} dangerouslySetInnerHTML={{ __html: bundle.title }} />;
    }

    switch (primaryItem.type) {
        case items.VEHICLES:
            return (
                <div className={className}>
                    <Ship shipId={primaryItem.identifier} fallback={bundle.title} />
                </div>
            );

        default:
            return <div className={className} dangerouslySetInnerHTML={{ __html: bundle.title }} />;
    }
};

export default BundleTitle;
