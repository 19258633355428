import { useParams } from 'react-router-dom';
import History from '~/utils/history';
import CategoryContainer from '~/containers/CategoryContainer/CategoryContainer';
import { getUrl, urlsMap } from '~/utils/menu';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { IRoutingStateSelector, routingStateSelector } from '~/routing/routingState';
import equal from 'fast-deep-equal/react';
import { isCategoryDisabledForUser } from '~/routing/helpers';
import { CATEGORIES } from '~/const';
import store from '~/Store';
import { changeVisiblePopup, updateActiveFilterPreset } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { IUpdateActiveFilterPreset } from '~/Actions/ActionAppType';

export default function CategoryRouter() {
    const { categoryName, id } = useParams();
    const { categories } = useSelector<State, IRoutingStateSelector>(routingStateSelector, equal);
    const dispatch = useDispatch();

    // WARNING: only for categories with subCategories
    // navigate directly to first subCategory when featured subCategory is disabled
    if (!!categories[categoryName]?.subCategories.length && categories[categoryName]?.disableFeatured) {
        const subCategoryName = categories[categoryName].subCategories[1];
        const url = getUrl(urlsMap.subCategory, {
            categoryName: categoryName,
            subCategoryName: subCategoryName,
        });
        History.navigate(url, { replace: true });
    }

    if (categories[categoryName]?.type === CATEGORIES.LANDING || isCategoryDisabledForUser(categoryName, id, categories)) {
        if (categories[categoryName]?.type === CATEGORIES.LANDING) {
            store.dispatch(changeVisiblePopup(POPUPS_NAME.IFRAME_POPUP, true, { iframeUrl: categories[categoryName]?.value }));
            dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(null));
        }
        History.navigate(urlsMap.home, { replace: true });
        return null;
    }

    return <CategoryContainer params={{ categoryName, id }} />;
}
