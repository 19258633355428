const bundleTimerListeners = new Map<number, Array<() => void>>();

export function handlePromoTimerComplete(bundle: IBundle, handler: () => void) {
    const timer = bundle?.promoTimer?.timerActiveTill;
    if (!timer) return;
    const timeout = new Date(timer).getTime() - Date.now();
    if (timeout < 0) return;
    let arListeners = bundleTimerListeners.get(bundle.id);
    if (!arListeners) {
        arListeners = [];
        bundleTimerListeners.set(bundle.id, arListeners);
        setTimeout(() => {
            bundleTimerListeners.get(bundle.id).forEach((fn) => fn());
        }, timeout);
    }
    arListeners.push(handler);
}
