import * as React from 'react';
import classNames from 'classnames';
import { interpolate, ngettext } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import Countdown from 'react-countdown';
import styles from './CountDown.scss';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';

interface ICountDown {
    date: number | string;
    isLarge?: boolean;
    tooltipText?: string;
    onComplete?: () => void;
    className?: string;
    notice?: boolean;
    withoutIcon?: boolean;
}

interface RedrererData {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const CountDown = (props: ICountDown) => {
    const { isLarge, date, tooltipText, onComplete } = props;

    const leadingZero = (num: number) => (num < 10 ? '0' : '');

    const renderer = ({ days, hours, minutes, seconds }: RedrererData) => {
        const hh = `${leadingZero(hours)}${hours}`;
        const mm = `${leadingZero(minutes)}${minutes}`;
        const ss = `${leadingZero(seconds)}${seconds}`;

        if (days === 0) {
            return <span>{`${hh}:${mm}:${ss}`}</span>;
        } else {
            const str = ngettext('{days} день', '{days} дня', days) + `&nbsp;${hh}:${mm}:${ss}`;
            const interpolateStr = interpolate(str, {
                days: days,
            });

            return <span dangerouslySetInnerHTML={{ __html: interpolateStr }} />;
        }
    };

    return (
        <DivTooltip
            className={classNames(isLarge ? styles.largeWrapper : styles.wrapper, props.className, {
                [styles.notice]: props.notice,
                [styles.withoutIcon]: props.withoutIcon,
            })}
            tooltipBody={tooltipText ? <DefaultTooltip text={tooltipText} /> : null}
        >
            <Countdown date={date} renderer={renderer} onComplete={onComplete} />
        </DivTooltip>
    );
};

export default CountDown;
