import * as React from 'react';
import styles from './Notice.scss';
import classNames from 'classnames';

interface INotice {
    label: string;
    className?: string;
}

const Notice = ({ label, className }: INotice) => {
    return <div className={classNames(styles.wrapper, className)}>{label}</div>;
};

export default Notice;
