import React from 'react';
import classNames from 'classnames';
import Skeleton from '~/components/Skeleton/Skeleton';

const CategorySkeleton = () => {
    return (
        <div className={'armory-loading__wrapper'}>
            <div className={classNames('armory-loading__block-item', 'armory-loading-item__full')}>
                <Skeleton />
            </div>
            <div className={classNames('armory-loading__block-item', 'armory-loading-item__half')}>
                <Skeleton />
            </div>
            <div className={classNames('armory-loading__block-item', 'armory-loading-item__half')}>
                <Skeleton />
            </div>
            <div className={classNames('armory-loading__block-item')}>
                <Skeleton />
            </div>
            <div className={classNames('armory-loading__block-item')}>
                <Skeleton />
            </div>
            <div className={classNames('armory-loading__block-item')}>
                <Skeleton />
            </div>
            <div className={classNames('armory-loading__block-item')}>
                <Skeleton />
            </div>
        </div>
    );
};

export default React.memo(CategorySkeleton);
