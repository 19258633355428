import gql from 'graphql-tag';

const types = gql`
    query Types($lang: String) {
        vehicleTypes(lang: $lang) {
            name
            title
            icons {
                default
                special
                premium
            }
        }
    }
`;

export default types;
