import * as React from 'react';

interface IProps {
    value: number;
    duration: number;
    formatter: (n: number) => string;
}

const stopAnimation = (animationFrame: number) => {
    if (animationFrame) {
        window.cancelAnimationFrame(animationFrame);
    }
};

const AnimatedNumber = ({ value, duration, formatter }: IProps) => {
    const [count, setCount] = React.useState<number>(value);
    let animationFrame: number | undefined;

    React.useEffect(() => {
        const prevValue: number = count;

        if (duration === 0) {
            setCount(value);
        } else {
            let startTimestamp: number | undefined;
            let previousTimestamp: number | undefined;

            const animationStep = (timestamp: number) => {
                if (duration === 0) {
                    stopAnimation(animationFrame);
                    setCount(value);
                    return;
                }

                if (startTimestamp === undefined) {
                    startTimestamp = timestamp;
                }

                let result: number;
                const progress = (timestamp - startTimestamp) / duration / 1000;

                if (previousTimestamp !== timestamp) {
                    const increment = (value - prevValue) * progress;
                    result = (increment < 0 ? Math.floor(increment) : Math.ceil(increment)) + prevValue;
                    setCount(result);
                }

                if (progress < 1) {
                    animationFrame = window.requestAnimationFrame(animationStep);
                } else {
                    setCount(value);
                }
            };

            stopAnimation(animationFrame);
            animationFrame = window.requestAnimationFrame(animationStep);
        }

        return () => {
            stopAnimation(animationFrame);
        };
    }, [value]);

    return <>{formatter(count)}</>;
};

export default React.memo(AnimatedNumber);
