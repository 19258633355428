import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SortDisplay.scss';
import { t } from '~/utils/localization';
import { State } from '~/Reducers';
import { setSortMethod } from '~/Actions/ActionApp';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS, QUERY_SORT_METHOD, QUERY_SORT_NAME } from '~/const';
import { updateSearchParam } from '~/utils/utils';
import { ISetSortMethod } from '~/Actions/ActionAppType';
import Dropdown from '~/components/Dropdown/Dropdown';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import className from 'classnames';
import { getSortItemsFromDropdown, isReverseMethod, resetSortQueryParams, SORT_MAP, SORT_TITLES, updateSortMethod } from '~/utils/sort/settings';
import { SortNames } from '~/types/category';

interface ISortCategory {
    category: ICategoryList;
    changeUrl?: boolean;
    className?: string;
}

interface IStateSelector {
    categories: ICategories;
    sortBy: ISortCategories;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
        sortBy: state.ReducerApp.sortedBy,
    };
};

const SortDisplay = ({ category, changeUrl }: ISortCategory) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const sortSettings = state.categories[category]?.sortSettings || [];
    const activeSortMethod = state.sortBy?.[category];

    if (!sortSettings.length) {
        return null;
    }

    const onChange = (name: SortNames) => {
        const newMethod = updateSortMethod(name, activeSortMethod?.name, activeSortMethod?.method);
        dwhExport.send(DWH_EVENTS.BUNDLES_SORT, { sort_name: name, sort_method: newMethod, category });
        changeUrl && updateSearchParam({ [QUERY_SORT_NAME]: name, [QUERY_SORT_METHOD]: newMethod });
        dispatch<ISetSortMethod>(setSortMethod(category, name, newMethod));
    };

    const items = getSortItemsFromDropdown(sortSettings).map((item) => {
        let method = SORT_MAP[item.name as SortNames]?.default;
        if (activeSortMethod?.name === item.name) {
            method = SORT_MAP[activeSortMethod.name].deps[activeSortMethod.method];
        }
        const classes = className(styles.sortItem, styles[method]);

        item.content = <div className={classes}>{item.content}</div>;

        return item;
    });

    return (
        <Dropdown
            label={SORT_TITLES[activeSortMethod?.name || 'default']}
            items={items}
            tooltipBody={<DefaultTooltip text={t('Выберите порядок отображения')} />}
            classNames={{
                wrapper: styles.wrapper,
                popup: styles.popup,
                label: !!activeSortMethod?.name ? (isReverseMethod(activeSortMethod.method) ? styles.reverseIcon : styles.activeIcon) : null,
            }}
            onChange={(item) => {
                if (item.name === 'default') {
                    resetSortQueryParams();
                    dwhExport.send(DWH_EVENTS.BUNDLES_SORT, { sort_name: SortNames.default, sort_method: SortNames.default, category });
                    dispatch<ISetSortMethod>(setSortMethod(category, null, null));
                } else {
                    onChange(item.name as SortNames);
                }
            }}
        />
    );
};

export default SortDisplay;
