import styles from './TreasureTopPanel.scss';
import React from 'react';
import { useParams } from 'react-router-dom';
import TopPanel from '~/components/TopPanel/TopPanel';

export default function TreasureTopPanel() {
    const { id: subCategoryName } = useParams();

    if (!!subCategoryName) return null;

    return (
        <div className={styles.wrapper}>
            <TopPanel hideWalletIcon />
        </div>
    );
}
