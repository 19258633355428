import * as React from 'react';
import styles from './PaymentMethod.scss';

interface IPaymentMethod {
    icon: string;
    description: string;
}

const PaymentMethod: React.FC<IPaymentMethod> = ({ icon, description }) => {
    return (
        <div className={styles.paymentMethod}>
            <img className={styles.paymentMethodImage} src={icon} alt={description} />
            <span className={styles.paymentMethodDescription}>{description}</span>
        </div>
    );
};

export default PaymentMethod;
