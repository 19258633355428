import { LocalStorageHelper } from './storage';
import { LOCAL_STORAGE_LAST_SEEN_SCENE, LOCAL_STORAGE_SCENE_ANIMATION_DISABLED_STATUS } from './keys';
import { CategoryType } from '~/types/category';
import { isEmptyObject } from './utils';

export function getSceneToShow(scenePageData: SceneCategoryData, scenesData: Array<SceneData>): SceneData['name'] {
    const dataFromLocalStorage = LocalStorageHelper.get(LOCAL_STORAGE_LAST_SEEN_SCENE);

    const sceneNameFromLocalStorage = dataFromLocalStorage?.[scenePageData.name];
    const isExists = scenePageData.scenes.find((sceneName) => sceneName === sceneNameFromLocalStorage);
    if (sceneNameFromLocalStorage && isExists) return sceneNameFromLocalStorage;

    const defaultSceneName = scenesData.find((scene) => scene.isMainScene)?.name || scenePageData.scenes?.[0];
    LocalStorageHelper.set(LOCAL_STORAGE_LAST_SEEN_SCENE, { ...(dataFromLocalStorage || {}), [scenePageData.name]: defaultSceneName });
    return defaultSceneName;
}

export function setLastSeenSceneToLocalStorage(scenePageData: SceneCategoryData, sceneName: SceneData['name']) {
    const dataFromLocalStorage = LocalStorageHelper.get(LOCAL_STORAGE_LAST_SEEN_SCENE);

    LocalStorageHelper.set(LOCAL_STORAGE_LAST_SEEN_SCENE, { ...(dataFromLocalStorage || {}), [scenePageData.name]: sceneName });
}

export function getSceneCategoryAnimationStatus(scenePageData: SceneCategoryData): boolean {
    return !!LocalStorageHelper.get(LOCAL_STORAGE_SCENE_ANIMATION_DISABLED_STATUS)?.[scenePageData.name];
}

export function setSceneCategoryAnimationStatus(scenePageData: SceneCategoryData, flag: boolean) {
    const dataFromLocalStorage = LocalStorageHelper.get(LOCAL_STORAGE_SCENE_ANIMATION_DISABLED_STATUS);

    LocalStorageHelper.set(LOCAL_STORAGE_SCENE_ANIMATION_DISABLED_STATUS, { ...(dataFromLocalStorage || {}), [scenePageData.name]: flag });
}

export function isSceneCategory(category?: ICategory): category is SceneCategoryData {
    if (!category) return false;
    return category.type === CategoryType.SCENE && !!(category as SceneCategoryData).scenes?.length;
}

export function isSceneCategoryHasAnimations(scenePageData: SceneCategoryData, scenesData: Array<SceneData>): boolean {
    if (!isEmptyObject(scenePageData.videoBackground)) {
        return true;
    }

    if (scenesData.some((sceneData) => !isEmptyObject(sceneData.videoBackground))) {
        return true;
    }

    return false;
}
