import * as React from 'react';
import styles from './CouponPopup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { t } from '~/utils/localization';
import Coupon, { CouponSize } from '~/components/Coupon/Coupon';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { openCategoryByName } from '~/utils/category';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import Account from '~/account/Account';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import { isValidDateInterval } from '~/utils/time';
import { hasCoupons } from '~/utils/coupons';
import { Button } from '~/components/Button/Buttons';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import equal from 'fast-deep-equal/react';
import Attention from '~/components/Attention/Attention';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';

export const couponTip = t('Купон не действует на товары со скидкой и некоторые другие товары. ') + t('Нажмите на купон, чтобы просмотреть список товаров, на которые распространяется скидка.');

interface IStateSelector {
    coupons: ICoupon[];
    usedCoupons: AccountUsedCoupons;
    categories: ICategories;
    viewedLabels: string[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        coupons: state.ReducerAccount.coupons,
        usedCoupons: state.ReducerAccount.usedCoupons,
        categories: state.ReducerApp.categories,
        viewedLabels: state.ReducerAccount.viewedLabels,
    };
};

const CouponPopup = (): any => {
    const ref = React.useRef<HTMLDivElement>(null);
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const dispatch = useDispatch();

    const close = () => dispatch(changeVisiblePopup(null));

    const onClick = (category: ICategoryList, couponId: number) => {
        close();
        dwhExport.send(DWH_EVENTS.OPEN_CATEGORY_FROM_COUPON_POPUP, {
            coupon_id: couponId,
            category: category,
        });
        openCategoryByName(category, {
            place: 'coupon',
            couponId,
        });
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    useMaskScrollEffect(ref);

    if (!hasCoupons()) {
        return (
            <Popup onClose={close} outsideClick={true} popupClassName={styles.popup}>
                <PopupHeader title={t('Нет доступных купонов')} />
                <PopupBody>
                    <div className={styles.couponEmptyWrapper}>{t('Попробуйте вернуться к купонам позднее.')}</div>
                </PopupBody>
                <PopupFooter>
                    <Button label={t('Закрыть')} onClick={close} />
                </PopupFooter>
            </Popup>
        );
    }

    return (
        <Popup onClose={close} popupClassName={styles.popup} className={styles.popupContent}>
            <PopupHeader title={t('Купоны')} className={styles.popupHeader} />
            <PopupBody className={styles.popupBody}>
                <div className={styles.couponsAboutText}>{t('Доступны специальные купоны, которые предоставляют скидку на товары из определённой категории в Адмиралтействе.')}</div>
                <div className={styles.couponsWrapper} ref={setRef}>
                    {state.coupons.map((coupon) => {
                        if (!state.categories?.[coupon.category]) {
                            return null;
                        }
                        const isAllowedNewLabel = isValidDateInterval(coupon.labelNewActivityPeriod) && !Account.isViewedLabel(coupon.name) && !coupon.isDisabled;
                        return (
                            <LabelDecorator
                                key={`coupon_${coupon.id}_${coupon.isDisabled}`}
                                id={coupon.name}
                                labelTill={coupon.labelNewActivityPeriod.activeTill}
                                isEnabled={isAllowedNewLabel}
                                byHover
                                type={LABEL_TYPE.coupon}
                                className={styles.couponItem}
                            >
                                <Coupon
                                    coupon={coupon}
                                    size={CouponSize.BIG}
                                    onClick={() => {
                                        onClick(coupon.category, coupon.id);
                                    }}
                                    shownCurrencies
                                />
                                <LabelContainer className={styles.label} isVisible={isAllowedNewLabel} />
                            </LabelDecorator>
                        );
                    })}
                </div>
                <Attention label={couponTip} level={'info'} isInline className={styles.attention} />
            </PopupBody>
            <PopupFooter className={styles.popupFooter}>
                <Button label={t('Закрыть')} onClick={close} className={'armory__auto--popup_close'} />
            </PopupFooter>
        </Popup>
    );
};

export default CouponPopup;
