import * as React from 'react';
import styles from './purchase_popup.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { changeVisiblePopup, updateQuantity } from '~/Actions/ActionApp';
import { AppState } from '~/Reducers/ReducerApp';
import { Button, OrangeButton } from '~/components/Button/Buttons';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import { arrayToObjectByKey, isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { getPrimaryItem, isAvailableQuantityWidget, isFreeBundle, isHiddenAmountForBundle, isShinyBundle } from '~/utils/bundles';
import { successPurchaseCallback } from '~/utils/purchase';
import { PURCHASE_POPUP_CONTAINER } from '~/containers/ConfirmPurchaseContainer/ConfirmPurchaseContainer';
import { CLIENT_BUTTON_BY_ITEM_TYPE, CLIENT_BUTTON_COPYRIGHT_BY_ROUTE, DWH_EVENTS } from '~/const';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import BundleTitle from '~/components/BundleTitle/BundleTitle';
import { closeBrowser } from '@wg/web2clientapi/browser/closeBrowser';
import QuantityWidget from '~/components/QuantityWidget/QuantityWidget';
import BundleType from '~/components/BundleType/BundleType';
import { hasCurrencyInCache, isCurrency } from '~/utils/currencies';
import Currency from '~/components/Currency/Currency';
import { items } from '@wg/wows-entities/const';
import { navigateTo, ROUTES } from '@wg/web2clientapi/browser/navigateTo';
import Account from '~/account/Account';
import dwhExport from '~/api/dwhExport';
import { playButtonClickSound } from '~/api/WoWsClient';
import { IPopupSoundset } from '~/types/soundsets';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { BUNDLE_DECORATION } from '~/types/bundle';

const PurchaseSuccessPopup = (): any => {
    const dispatch = useDispatch();
    const app = useSelector<State, AppState>((state) => state.ReducerApp, shallowEqual);
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);
    const popupData = popup.data;
    let bundle = app.bundles[popupData.bundleId];

    if (!bundle || (bundle && !bundle.id)) {
        Object.values(app.bundles).forEach((item: IBundle) => {
            if (!item.randomBundleChildren) {
                return false;
            }

            const randomBundleChildren = arrayToObjectByKey(item.randomBundleChildren, 'id');
            if (randomBundleChildren[popupData.bundleId]) {
                bundle = Object.assign({}, item, randomBundleChildren[popupData.bundleId]);
            }
        });
    }

    const close = () => {
        dispatch(changeVisiblePopup(null));

        popupData.closeCallback && popupData.closeCallback();

        if (isMobileOrTabletWindow && app.currentPage?.name === PURCHASE_POPUP_CONTAINER) {
            successPurchaseCallback(bundle);
        }
    };

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    React.useEffect(() => {
        if (!bundle.isRandom) {
            dispatch(updateQuantity(bundle.id, null, null));
        }
    }, []);

    const imageUrl = bundle.icons.small;
    const image = {
        backgroundImage: `url(${imageUrl})`,
    };

    const clientSource = app.clientSource as keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE;
    const isNeedToShownReturnToClientButton = !!(isInGameBrowser && !!clientSource);

    const primaryItem = bundle && getPrimaryItem(bundle);
    const quantity = popupData?.quantity || 1;

    const classesSuccessBackground = classNames(styles.purchaseSuccessImageWrapper, {
        [styles.eliteBackground]: isShinyBundle(bundle),
    });

    const classesImage = classNames(styles.popupImage, styles[primaryItem?.type]);

    const hasCurrencyInState = bundle.entitlements?.length === 1 && isCurrency(bundle.entitlements?.[0].type);
    const isQuantityChangeAllowed = isAvailableQuantityWidget(bundle) || bundle.primaryItem?.amount > 1;

    const classesHeader = classNames(styles.purchaseSuccessContentData, {
        [styles.withQuantity]: isQuantityChangeAllowed,
    });

    const classesTitle = classNames(styles.purchaseSuccessBundleTitle, {
        [styles.withQuantity]: isQuantityChangeAllowed,
    });

    const amount = Math.max(primaryItem?.amount ? primaryItem.amount : bundle.entitlements?.[0]?.amount, 1);

    let totalAmount = amount * (quantity || 1);
    if (primaryItem && !hasCurrencyInState && hasCurrencyInCache(primaryItem.type)) {
        totalAmount = quantity;
    }

    const isLootbox = primaryItem?.type === items.LOOTBOX;

    const _closeBrowser = () => {
        dwhExport.send(DWH_EVENTS.CLIENT_GO_TO, { client_source: clientSource });
        closeBrowser();
    };

    const clientHandler = () => {
        if (isLootbox) {
            const meta: Record<string, string> = { route: ROUTES.barge };
            if (isNeedToShownReturnToClientButton) {
                meta['client_source'] = clientSource;
            }
            dwhExport.send(DWH_EVENTS.CLIENT_GO_TO, meta);
            navigateTo(ROUTES.barge);
        } else {
            _closeBrowser();
        }
    };

    const isAvailableLootboxForAccount = Account.isAvailableLootbox();

    const isOpenLootboxButtonVisible = isInGameBrowser && isLootbox && isAvailableLootboxForAccount && !bundle.decoration?.includes?.(BUNDLE_DECORATION.HIDE_OPEN_LOOTBOX_BUTTON);

    return (
        <React.Fragment>
            <Popup onClose={close} className={'armory__auto--success-purchase-popup'} soundset={IPopupSoundset.EXCHANGE}>
                <PopupHeader title={isFreeBundle(bundle) ? t('Поздравляем, вы получили набор!') : t('Спасибо за покупку!')} />
                <PopupBody>
                    <div className={styles.purchaseSuccessContent}>
                        <div className={classesSuccessBackground}>
                            <div className={classesImage} style={image} />
                        </div>
                        <div className={classesHeader}>
                            <div className={classesTitle}>
                                {hasCurrencyInState ? (
                                    <div className={styles.purchaseSuccessContentTitle}>
                                        <Currency currency={bundle.entitlements[0].type} amount={totalAmount} className={styles.weComponent} withoutAnimation />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <BundleTitle bundle={bundle} className={styles.purchaseSuccessContentTitle} />
                                        {isQuantityChangeAllowed && !isHiddenAmountForBundle(bundle) && (
                                            <div className={styles.quantityWrapper}>
                                                <QuantityWidget amount={primaryItem ? totalAmount : quantity} />
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                            {bundle.entitlements?.length === 1 && bundle.primaryItem && (
                                <div className={styles.bundleType}>
                                    <BundleType item={bundle.primaryItem} isPurchasePopup />
                                </div>
                            )}
                        </div>
                    </div>
                </PopupBody>
                <PopupFooter>
                    <div className={styles.buttons}>
                        {isOpenLootboxButtonVisible && <Button label={CLIENT_BUTTON_BY_ITEM_TYPE[items.LOOTBOX]} onClick={clientHandler} className={classNames(styles.portButton)} />}
                        {isNeedToShownReturnToClientButton && !isOpenLootboxButtonVisible && (
                            <OrangeButton
                                label={CLIENT_BUTTON_COPYRIGHT_BY_ROUTE[clientSource] || CLIENT_BUTTON_COPYRIGHT_BY_ROUTE.default}
                                onClick={clientHandler}
                                className={classNames(styles.portButton)}
                            />
                        )}
                        <Button label={t('закрыть')} onClick={close} className={classNames('armory__auto--success_purchase_button')} />
                    </div>
                </PopupFooter>
            </Popup>
        </React.Fragment>
    );
};

export default PurchaseSuccessPopup;
