import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './BidPlacingPopup.scss';
import { POPUPS_NAME } from '~/components/PopupManager';
import { useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { t } from '~/utils/localization';
import { Interpolate } from '@wg/wows-react-uikit';
import Currency from '~/components/Currency/Currency';
import BidInput from '~/components/BidInput/BidInput';
import AuctionProcessor from '~/processors/AuctionProcessor';
import AuctionLotHeader from '~/components/Auction/AuctionHeader';
import AuctionBidButton from '~/components/AuctionBidButton/AuctionBidButton';
import { State } from '~/Reducers';
import { getNoticeByKind, isPremiumCurrency, startHeadShakeAnimation } from '~/utils/auction';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { setBidLot } from '~/Actions/ActionAuction';
import { arrayToObjectByKey, formatNumber } from '~/utils/utils';
import AuctionCancelButton from '~/components/AuctionBidButton/AuctionCancelButton';
import ActiveBid from '~/components/Popups/Auction/ActiveBid/ActiveBid';
import BidPlacingBackground from '~/components/Popups/Auction/BidPlacingPopup/BidPlacingBackground/BidPlacingBackground';
import { getCurrency } from '~/utils/currencies';

interface IProps {
    lot: ILot;
    fromLotPage?: boolean;
    closeCallback?: () => void;
}

interface IBidPlacedPopupState {
    auctionAccount: IAuctionAccountState;
    activeAuctions: IAuction[];
    wallet: IBalance;
    activeBids: {
        [key: string]: number;
    };
    popupActive: IPopup;
}

const stateSelector = (state: State): IBidPlacedPopupState => {
    return {
        auctionAccount: state.ReducerAuction.account,
        activeAuctions: state.ReducerAuction.activeAuctions,
        activeBids: state.ReducerAuction.activeBids,
        wallet: state.ReducerAccount?.balance,
        popupActive: state.ReducerApp.popupActive,
    };
};

const BidPlacingPopup = (props: IProps) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IBidPlacedPopupState>(stateSelector, equal);
    const bidInputWrapper = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const currentAuction = state.activeAuctions?.filter((auction: IAuction) => {
        return auction.lots.some((lot) => lot.id === props.lot.id);
    })?.[0] as IAuction;

    const [isDisabledBidButton, setDisabledBidButton] = React.useState<boolean>(false);
    const [isProcessing, setProcessing] = React.useState<boolean>(false);
    const auctionProcessor = new AuctionProcessor(currentAuction, props.lot, state.auctionAccount);
    const isBidAlreadyPlaced = auctionProcessor.isBidAlreadyPlaced(props.lot.id);
    const stateBid = state.activeBids?.[props.lot.id];
    const currentBid = auctionProcessor.getActiveBidByLotId(props.lot.id)?.bid.amount || 0;
    const isBidCanceling = auctionProcessor.isBidCanceling();
    const isCurrentBidCanceling = auctionProcessor.isBidCanceling(true);

    const [isFinishedAuction, setFinishFlagAuction] = React.useState<boolean>(auctionProcessor.isFinishedLot());
    const [realBid, setRealBid] = React.useState<number>(stateBid || currentBid || props.lot.minimalBid.amount);

    const balanceObject = arrayToObjectByKey(state.wallet || [], 'currency');

    const close = () => {
        dispatch(changeVisiblePopup(null));
    };

    const placeBet = async () => {
        setProcessing(true);
        await auctionProcessor.placedBit(props.lot.id, props.lot.minimalBid.currency, realBid, close, props.fromLotPage);
    };

    const style = props.lot.purchasePreviewImageUrl
        ? {
              backgroundImage: `url(${props.lot.purchasePreviewImageUrl})`,
          }
        : {};

    React.useEffect(() => {
        return () => {
            props.closeCallback?.();
            if (!props.fromLotPage) {
                dispatch(setBidLot(props.lot.id, null));
            }
        };
    }, []);

    React.useEffect(() => {
        setFinishFlagAuction(auctionProcessor.isFinishedLot());
    }, [auctionProcessor.isFinishedLot()]);

    React.useEffect(() => {
        if (state.popupActive && [POPUPS_NAME.BID_ERROR_PLACED, POPUPS_NAME.BID_SUCCESSFULLY_PLACED, POPUPS_NAME.AUCTION_WON_POPUP].includes(state.popupActive.name)) {
            dispatch(changeVisiblePopup(POPUPS_NAME.BID_PLACED_POPUP));
        }
    }, [state.popupActive]);

    const currentAmount = () => {
        const currencyName = props.lot.minimalBid.currency;

        if (isPremiumCurrency(currencyName)) {
            return Math.floor(balanceObject?.[props.lot.minimalBid.currency]?.value / 86400);
        }

        return balanceObject?.[props.lot.minimalBid.currency]?.value || 0;
    };

    const Wallet = () => {
        return (
            <React.Fragment>
                <Currency
                    amount={currentAmount()}
                    currency={props.lot.minimalBid.currency}
                    className={styles.walletCurrency}
                    withoutAnimation
                    showDescriptionTooltip={currentAuction.showDescriptionInPriceTooltip && getCurrency(props.lot.minimalBid.currency)?.showDescriptionInPriceTooltip}
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className={styles.miniWalletWrapper}>
                <div className={styles.miniWallet}>
                    <Wallet />
                </div>
            </div>
            <Popup
                onClose={close}
                className={styles.popup}
                popupClassName={styles.popupWrapper}
                renderEscButton={true}
                BeforePopupComponent={<BidPlacingBackground background={props.lot?.bidPlacingPopupBackground} />}
            >
                <PopupHeader title={isBidAlreadyPlaced ? t('Изменение ставки') : t('Подтверждение ставки')} />
                <PopupBody>
                    <div className={styles.mobileMiniWalletWrapper}>
                        <div className={styles.miniWallet}>
                            <Wallet />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.info}>
                            <div className={styles.infoHeader}>
                                <div className={styles.title}>
                                    <AuctionLotHeader {...props.lot} />
                                </div>
                            </div>
                            <div className={styles.betContent}>
                                <div className={styles.amount}>
                                    {t('Количество победителей:')}&nbsp;<b>{formatNumber(props.lot.winnersCount)}</b>
                                </div>
                                <div className={styles.betInfo}>
                                    <Interpolate
                                        str={t('Минимальная ставка: %(bet)s')}
                                        bet={
                                            <Currency
                                                currency={props.lot.minimalBid.currency}
                                                className={styles.betCurrency}
                                                amount={props.lot.minimalBid.amount}
                                                withoutAnimation
                                                showDescriptionTooltip={currentAuction.showDescriptionInPriceTooltip && getCurrency(props.lot.minimalBid.currency)?.showDescriptionInPriceTooltip}
                                            />
                                        }
                                    />
                                </div>
                                {isBidAlreadyPlaced && (
                                    <div className={styles.betInfo}>
                                        <div>{t('Ваша ставка:')}&nbsp;</div>
                                        <ActiveBid
                                            auction={currentAuction}
                                            currency={props.lot.minimalBid.currency}
                                            className={styles.betCurrency}
                                            amount={currentBid}
                                            placedAt={auctionProcessor.getActiveBidByLotId(props.lot.id)?.placedAt}
                                        />
                                        {auctionProcessor.canCanceledBid(props.lot.id) && (
                                            <AuctionCancelButton
                                                isProcessing={isProcessing}
                                                auction={currentAuction}
                                                isFetching={isCurrentBidCanceling}
                                                lot={props.lot}
                                                className={styles.cancelBidButton}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className={styles.betForm}>
                                    <div className={styles.betLabel}>{isBidAlreadyPlaced ? t('Новая ставка:') : t('Ваша ставка:')}</div>
                                    <div ref={bidInputWrapper}>
                                        <BidInput
                                            activeBid={currentBid}
                                            bidCancellationsAllowed={currentAuction?.bidCancellationsAllowed}
                                            bidRaisingAllowed={currentAuction?.bidRaisingAllowed}
                                            hasTransaction={isProcessing || isBidCanceling}
                                            bidDropAllowed={currentAuction?.bidDropAllowed}
                                            currency={props.lot.minimalBid.currency}
                                            minLotBid={props.lot.minimalBid.amount}
                                            bid={stateBid || currentBid}
                                            lotId={props.lot.id}
                                            maxBidAmount={props.lot.maxBidAmount}
                                            inputClassName={styles.inputBid}
                                            changeBid={(bid) => {
                                                setDisabledBidButton(false);
                                                setRealBid(bid);
                                            }}
                                            currencyBalance={currentAmount()}
                                            onInput={(bid) => {
                                                setDisabledBidButton(bid < props.lot.minimalBid.amount || bid === currentBid);
                                                setRealBid(bid);
                                            }}
                                            setRef={(_ref) => {
                                                if (_ref) {
                                                    inputRef.current = _ref;
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.footer}>{getNoticeByKind(currentAuction?.kind)}</div>
                        </div>
                        <div className={styles.imageWrapper}>
                            <div className={styles.image} style={style} />
                        </div>
                    </div>
                </PopupBody>
                <PopupFooter className={styles.popupFooter}>
                    <div>
                        <AuctionBidButton
                            isProcessing={isProcessing || isBidCanceling}
                            isDisabled={(isBidAlreadyPlaced && realBid === currentBid) || isDisabledBidButton || isFinishedAuction}
                            isBidAlreadyPlaced={isBidAlreadyPlaced}
                            minimalBid={props.lot.minimalBid}
                            lotId={props.lot.id}
                            onClick={placeBet}
                            renderFastGoldLink={true}
                            onFallbackClick={() => {
                                startHeadShakeAnimation(bidInputWrapper.current, styles.headShakeAnim);
                                inputRef.current?.focus();
                            }}
                        />
                    </div>
                </PopupFooter>
            </Popup>
        </React.Fragment>
    );
};

export default BidPlacingPopup;
