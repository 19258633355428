import * as React from 'react';
import styles from './BundlePageImage.scss';
import { isAvailableFullscreenTemplateWithoutGallery } from '~/utils/bundles';
import classNames from 'classnames';
import PhotoViewer from '~/components/PhotoViewer/PhotoViewer';
import PaginationDots from '~/components/PaginationDots/PaginationDots';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { isAvailableBigArt, isScrollableTemplate } from '~/Layouts/BundlePage/helpers';
import { State } from '~/Reducers';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeCurrentPage } from '~/Actions/ActionApp';

interface IBundlePageImage {
    bundle: IBundle;
}

interface IStateSelector {
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
    };
};

const BundlePageImage = ({ bundle }: IBundlePageImage) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, shallowEqual);

    if (isScrollableTemplate(bundle) || isAvailableFullscreenTemplateWithoutGallery(bundle)) {
        return null;
    }

    const gallery = !!bundle.gallery?.length ? bundle.gallery : [];

    if (!gallery.length || (isMobileOrTabletWindow && bundle.isFullscreenGallery)) {
        const imageUrl = bundle.icons.big;
        const image = { backgroundImage: `url(${imageUrl})` };
        const iconWrapper = classNames(styles.iconWrapper, {
            [styles.serialBundle]: bundle.serialPurchase,
        });
        const iconClassNames = classNames(styles.icon, {
            [styles.serialBundle]: bundle.serialPurchase,
        });

        if (isAvailableBigArt(bundle)) {
            return <div className={styles.bundleBigImage} style={image} />;
        }

        return (
            <div className={iconWrapper}>
                <img src={imageUrl} className={iconClassNames} />
            </div>
        );
    }

    if (!isMobileOrTabletWindow && bundle.isFullscreenGallery && gallery.length > 1) {
        const onClick = (index: number) => {
            dispatch(
                changeCurrentPage({
                    ...state.currentPage,
                    bgGallerySlideIndex: index,
                }),
            );
        };
        return (
            <div className={styles.PaginationDots}>
                <PaginationDots items={gallery} onChange={onClick} currentIndex={state.currentPage?.bgGallerySlideIndex || 0} />
            </div>
        );
    }

    return (
        <div className={styles.photoViewer}>
            <PhotoViewer photos={gallery} bundleDecorations={bundle.decoration} autoScrolling className={styles.viewer} />
        </div>
    );
};

export default BundlePageImage;
