import * as React from 'react';
import styles from './BundlePageHeader.scss';
import { isObject } from '~/utils/utils';
import { t } from '~/utils/localization';
import { getSerialBundlesById, isHiddenBundleCategoryTitle } from '~/utils/bundles';
import SerialBundleTitle from '~/components/SerialBundleTitle/SerialBundleTitle';
import getAdditionalHeaderContent from '~/Layouts/BundlePage/BundlePageHeader/content';
import { BUNDLE_DECORATION } from '~/types/bundle';

interface IBundlePageHeader {
    bundle: IBundle;
    bundles: IBundleList;
    renderPreviewContent?: boolean;
}

const SerialBundlePageHeader = ({ bundle, bundles }: IBundlePageHeader) => {
    if (!bundle.serialPurchase) {
        return null;
    }

    const serialBundles = getSerialBundlesById(bundles, bundle.id);

    return (
        <div className={styles.serialChainWidget}>
            <SerialBundleTitle step={bundle.serialIndex + 1} totalCount={serialBundles.length} />
        </div>
    );
};

const BundlePageHeader = ({ bundle, bundles, renderPreviewContent }: IBundlePageHeader) => {
    const title: string | React.ReactChild = bundle.title;
    const additionalContent = getAdditionalHeaderContent(bundle, renderPreviewContent);
    const showAppearanceDisclaimer = bundle.decoration?.includes(BUNDLE_DECORATION.DISCLAIMER_APPEARANCE);
    const isHiddenTitle = isHiddenBundleCategoryTitle(bundle); // when category title is hidden, then bundle title replaces it in <TopPanel/>

    if (isHiddenTitle && !(!!additionalContent || !!bundle.serialPurchase) && !showAppearanceDisclaimer) return null;

    return (
        <div className={styles.headerWrapper}>
            {!isHiddenTitle && (isObject(title) ? <div className={styles.title}>{title}</div> : <div className={styles.title} dangerouslySetInnerHTML={{ __html: String(title) }} />)}
            {(!!additionalContent || !!bundle.serialPurchase) && (
                <div className={styles.content}>
                    {additionalContent}
                    {bundle.serialPurchase && <SerialBundlePageHeader bundle={bundle} bundles={bundles} />}
                </div>
            )}
            {showAppearanceDisclaimer && (
                <div className={styles.disclaimer}>{t('Обратите внимание: внешний вид и описание объектов в Адмиралтействе могут иметь отличия от представленных в клиенте игры.')}</div>
            )}
        </div>
    );
};

export default BundlePageHeader;
