import * as React from 'react';
import styles from './AuctionLayout.scss';
import { t } from '~/utils/localization';
import { useDispatch } from 'react-redux';
import { changeLotBackground, startAuction } from '~/Actions/ActionAuction';
import Timer from '~/components/Timer/Timer';
import { parseText } from '~/utils/html';
import { changeViewBackground } from '~/Actions/ActionApp';

interface IAuctionPromoLayout {
    auctionId: string;
    title: string;
    description?: string[];
    startedAt: string;
    currency?: string;
    minimalBidAmount?: string;
    promotionImageUrl?: string;
    promotionBackgroundColor?: string;
}

const AuctionPromoLayout = (props: IAuctionPromoLayout) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeViewBackground(null, props.promotionImageUrl));
    }, [props.promotionImageUrl]);

    const onFinishTimerCallback = () => {
        dispatch(startAuction(props.auctionId));
        dispatch(changeLotBackground(null, null));
    };

    const description = props.description ? parseText(Array.isArray(props.description) ? props.description.join('{br}') : props.description) : '';

    return (
        <div className={styles.promotion}>
            <div className={styles.promotionContent}>
                <div className={styles.promotionHeader}>
                    <div className={styles.promotionTitle}>{props.title}</div>
                </div>
                <div className={styles.promotionFooter}>
                    <div className={styles.promotionBlock}>
                        <div className={styles.promotionBlockTitle}>{t('До начала аукциона:')}</div>
                        <div className={styles.promotionBlockContent}>
                            <Timer
                                time={props.startedAt}
                                renderTimerIfManyDays={true}
                                withoutIcon={true}
                                timerClassName={styles.promotionBlockTimer}
                                className={styles.promotionTimerWrapper}
                                onFinishCallback={onFinishTimerCallback}
                            />
                        </div>
                    </div>
                </div>
                {!!props.description && <div className={styles.promotionDescription} dangerouslySetInnerHTML={{ __html: description }} />}
            </div>
        </div>
    );
};

export default AuctionPromoLayout;
