import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { armoryState, csrf, settings } from '~/utils/settings';
import client, { Client, VORTEX_ENDPOINT } from '~/client';
import gameVersion from '~/queries/version';
import { logError, logInfo } from '~/utils/logging';
import wowsEntities from '@wg/wows-entities';
import VortexDataStorage from '~/core/VortexDataStorage';
import { get } from '~/utils/ajax';
import { prepareSubCategories } from '~/utils/category';
import Account from '~/account/Account';
import store from '~/Store';
import { giftStatus, initGuideSteps, initializeAccount, setCoupons, setNotifications, setUserPcPerformance } from '~/Actions/ActionAccount';
import { dataInitialize, updateBundleList } from '~/Actions/ActionApp';
import { getStepsFromGuide } from '~/components/WelcomePage/steps';
import { preloadCategoryBackgrounds } from '~/utils/preCacheImage';
import { setFiltersInfo } from '~/settings/filtersMap';
import assets from '~/queries/assets';
import { getAllItemsCd, isDisplayRestricted, isSecretGift } from '~/utils/bundles';
import { items as ITEMS } from '@wg/wows-entities/const';
import { flat, isInGameBrowser } from '~/utils/utils';
import { default as queryNations } from '~/queries/nations';
import { default as queryTypes } from '~/queries/types';
import { default as queryComplexities } from '~/queries/complexities';
import { getAvailableGifts } from '~/api/gifts';
import { GRAPHQL_QUERIES_MAP } from '~/queries/helpers';
import { preloadVideo } from '~/Layouts/Themes/SantaPage/preload';
import { isLowPcPerformance } from '@wg/web2clientapi/browser/isLowPcPerformance';
import { isNeedToShowWelcomeGiftScreen } from '~/utils/gift';
import { SectionNames } from '../ManagerData';
import { NO_NATION_ITEM } from '~/const';
import entityIcons from '~/queries/serviceIcons';

type BUNDLE_DESCRIPTIONS_TYPE = Pick<IBundle, 'description' | 'descriptionAdditional'>;
export type SERVICE_ICONS_RARITY = {
    rare: string;
    special: string;
    standard: string;
    unique: string;
    legendary?: string;
};
export type ServiceIcons = {
    flag: SERVICE_ICONS_RARITY;
    camoboost: SERVICE_ICONS_RARITY;
    ribbon: SERVICE_ICONS_RARITY;
};

export default class ArmoryPreloader extends AbstractPreloader {
    constructor(
        private user: IAccount = window.metashop.state?.account,
        private version: string = null,
        private isVortexRequestNeeded: boolean = true,
        private bundles: IBundleList = window.metashop.state.content.bundles,
        private isVortexIsUnavailable: boolean = false,
        private requests: Promise<any>[] = [],
        private vortexData: any = {},
    ) {
        super();
    }

    async load(): Promise<any> {
        // todo: add retries
        csrf().catch(() => {
            logInfo('request to csrf was failed');
        });

        try {
            await this.initWowsEntities().catch((e) => {
                logError('Error during Wows entities init', e);
            });
            let gameVersion = wowsEntities.settings.gameVersion;
            if (!gameVersion) gameVersion = await this.fetchGameVersion(); // fallback if something went wrong in WE
            if (!gameVersion) throw 'No game version';
            this.version = gameVersion;
            window.metashop.settings.version = this.version;
            this.setVortexRequestNeededFlag();
        } catch (e) {
            this.isVortexIsUnavailable = true;
            logError('vortex is unavailable');
        }

        await this.prepareBundles();
        return Promise.resolve(1);
    }

    private async fetchGameVersion() {
        const versionClient = new Client(VORTEX_ENDPOINT.version);
        const response = await versionClient.get().query({ query: gameVersion });
        return response?.data?.version;
    }

    private async initWowsEntities() {
        return wowsEntities.init({
            vortexBaseUrl: settings.urls.vortex,
            languageCode: settings.languageCode,
        });
    }

    private setVortexRequestNeededFlag() {
        this.isVortexRequestNeeded = !(VortexDataStorage.isDataRelevant(this.version, settings.languageCode) && VortexDataStorage.hasData());
    }

    private async prepareBundles() {
        const bundlesCount = Object.keys(this.bundles).length;

        if (this.isVortexIsUnavailable) {
            await this.initApp({ data: this.bundles });
            return Promise.resolve(1);
        }

        if (!VortexDataStorage.isEqualsCountBundles(bundlesCount)) {
            VortexDataStorage.updateCountBundles(bundlesCount);
            this.isVortexRequestNeeded = true;
        }

        if (this.isVortexRequestNeeded) {
            await this.loadVortexData();
        } else {
            await this.readDataFromLocalStorage();
        }

        return Promise.resolve(1);
    }

    private async initApp(response: any) {
        prepareSubCategories();

        if (this.user) {
            store.dispatch(initializeAccount(this.bundles));
            await Account.loadAccountInventoryFromVrtx();
        }

        store.dispatch(dataInitialize(response, Account.getPurchasedLimitedBundles(), Account.getDeniedBundlesByUniqueItems()));

        await this.postInitialisation();

        await this.getUserGraphicsSettings();
    }

    private async postInitialisation() {
        const { bundles, categories } = store.getState().ReducerApp;
        if (this.user) {
            store.dispatch(initGuideSteps(getStepsFromGuide()));
            store.dispatch(setCoupons(armoryState.content?.coupons, categories));
        }
        if (bundles && categories && !!Account.getAccount()?.id) {
            store.dispatch(setNotifications(armoryState.content.notifications as INotification[], categories, bundles));
        }
        preloadCategoryBackgrounds();

        await this.loadBundleDescription();

        await this.checkGifts();

        preloadVideo();
    }

    private async loadBundleDescription() {
        const response: { data: Record<number, BUNDLE_DESCRIPTIONS_TYPE> } = await get(settings.urls.getBundleDescriptions);
        if (!response || !response.data) {
            return;
        }
        const bundles: Record<string, IBundle> = { ...store.getState().ReducerApp.bundles };
        const merge = (bundleId: string | number, bundle: BUNDLE_DESCRIPTIONS_TYPE) => {
            Object.assign(
                bundle,
                response.data[+bundleId] || {
                    description: null,
                    descriptionAdditional: null,
                },
            );
        };
        for (const [id, bundleData] of Object.entries(bundles)) {
            if (Array.isArray(bundleData.randomBundleChildren)) {
                bundleData.randomBundleChildren.forEach((childBundle) => {
                    merge(childBundle.id, childBundle as BUNDLE_DESCRIPTIONS_TYPE);
                });
            }
            merge(id, bundleData);
        }
        store.dispatch(updateBundleList(bundles));
    }

    private async readDataFromLocalStorage() {
        this.vortexData = VortexDataStorage.getAll();

        const response = {
            data: this.bundles,
            vehicleTypes: this.vortexData.data.class || [],
            nations: this.vortexData.data.nation || [],
            currencies: this.vortexData.data.currency || [],
            complexities: this.vortexData.data.complexity || [],
            serviceIcons: this.vortexData.data.serviceIcons || [],
        };

        await setFiltersInfo();
        try {
            await this.initApp(response);
        } catch (e) {
            this.requests = [];
            this.isVortexRequestNeeded = true;
            await this.loadVortexData();
        }
    }

    private async loadVortexData() {
        this.requests.push(
            client.get().query({
                query: queryNations,
                variables: {
                    lang: settings.languageCode,
                },
            }),
            client.get().query({
                query: queryTypes,
                variables: {
                    lang: settings.languageCode,
                },
            }),
            client.get().query({
                query: assets,
                variables: {
                    lang: settings.languageCode,
                },
            }),
            client.get().query({
                query: queryComplexities,
                variables: {
                    lang: settings.languageCode,
                },
            }),
            client.get().query({
                query: entityIcons,
            }),
        );

        const bundleIdsByCategories = getAllItemsCd(this.bundles);
        const [nations, vehicleTypes, currencies, complexities, serviceIcons] = await Promise.all(this.requests);

        const promiseRequestsByCategory: any = {};
        const icons: ServiceIcons = {
            flag: serviceIcons.data.icons.flag.small,
            camoboost: serviceIcons.data.icons.camoboost.small,
            ribbon: serviceIcons.data.icons.ribbon.small,
        };

        const dataBaseData: any = {
            lang: settings.languageCode,
            version: this.version,
            data: {
                class: vehicleTypes.data.vehicleTypes,
                // toDo: убрать хардкод (no_nation_item) в рамках задачи WWSD-232595 по добавлению нации Common "без нации"
                // toDo: планируемое время разработки - ноябрь-декабрь 2024
                nation: [].concat(NO_NATION_ITEM, nations.data.nations),
                currency: currencies.data.currencies,
                complexity: complexities.data.complexities,
                serviceIcons: icons,
            },
        };

        const response: any = {
            data: this.bundles,
            currencies: currencies.data.currencies,
            // toDo: убрать хардкод (no_nation_item) в рамках задачи WWSD-232595 по добавлению нации Common "без нации"
            // toDo: планируемое время разработки - ноябрь-декабрь 2024
            nations: [].concat(NO_NATION_ITEM, nations.data.nations),
            vehicleTypes: vehicleTypes.data.vehicleTypes,
            complexities: complexities.data.complexities,
            serviceIcons: icons,
        };

        Object.keys(bundleIdsByCategories).forEach((key) => {
            if (!GRAPHQL_QUERIES_MAP[key]) {
                return;
            }

            const ids = [...bundleIdsByCategories[key]];

            if (key === ITEMS.VEHICLES) {
                ids.push(...(settings.stylePreviewDefaultShips || []));
            }

            ids.sort();

            promiseRequestsByCategory[key] = [
                client
                    .get()
                    .query({
                        query: GRAPHQL_QUERIES_MAP[key],
                        variables: {
                            ids,
                            lang: settings.languageCode,
                        },
                    })
                    .catch(() => {
                        console.warn('response not successful for item', key);
                    }),
            ];
        });

        const values: any = Object.values(promiseRequestsByCategory) || [];
        const vortexData = await Promise.all(flat(values));

        vortexData.forEach((vortexResponse: any) => {
            if (!vortexResponse) {
                return;
            }

            // !CODE TO DELETE (start)
            const vortexRequestNames = Object.keys(vortexResponse.data);
            const mainVortexRequestName = vortexRequestNames.find((requestName) => {
                switch (requestName) {
                    case SectionNames.class:
                    case SectionNames.currency:
                    case SectionNames.crews:
                    case SectionNames.dolls:
                    case SectionNames.items:
                    case SectionNames.vehicles:
                    case SectionNames.lootbox:
                    case SectionNames.permoflages:
                        return true;
                    default:
                        return false;
                }
            });

            for (let i = 0; i < vortexRequestNames.length; i++) {
                const currVortexRequestName = vortexRequestNames[i];

                if (dataBaseData.data[mainVortexRequestName]) {
                    vortexResponse.data[currVortexRequestName].forEach((newData: any) => {
                        const currData = dataBaseData.data[mainVortexRequestName].find((currData: any) => currData.id === newData.id);
                        if (currData) {
                            Object.assign(currData, newData);
                        } else {
                            dataBaseData.data[mainVortexRequestName].push(newData);
                        }
                    });
                } else {
                    dataBaseData.data[mainVortexRequestName] = [...vortexResponse.data[currVortexRequestName]];
                }
            }
            // !CODE TO DELETE (end)

            // TODO: uncomment this when ship features moves in ship request and delete the shit above
            // const vortexRequestName = Object.keys(vortexResponse.data)[0];
            // if (dataBaseData.data[vortexRequestName]) {
            //     dataBaseData.data[vortexRequestName].push(...vortexResponse.data[vortexRequestName]);
            // } else {
            //     Object.assign(dataBaseData.data, { ...vortexResponse.data });
            // }
        });

        VortexDataStorage.add(dataBaseData);

        await setFiltersInfo();
        await this.initApp(response);
    }

    async checkGifts() {
        const response: IRequestGift = await getAvailableGifts();
        const gifts = response.data;

        if (!gifts?.length) {
            return;
        }

        const availableGifts: IGift[] = [];
        const secretAvailableGifts: IGift[] = [];

        gifts.forEach((gift) => {
            if (gift.isObtained || isDisplayRestricted(gift)) return;
            if (isSecretGift(gift)) {
                secretAvailableGifts.push(gift);
            } else {
                availableGifts.push(gift);
            }
        });

        if (Account.getAccount()?.id) {
            store.dispatch(giftStatus(availableGifts.length > 0, availableGifts, secretAvailableGifts));
        } else if (isNeedToShowWelcomeGiftScreen(gifts?.[0]?.name)) {
            store.dispatch(giftStatus(1, availableGifts, secretAvailableGifts));
        } else {
            store.dispatch(giftStatus(0, [], secretAvailableGifts));
        }
    }

    async getUserGraphicsSettings() {
        if (!isInGameBrowser) {
            return;
        }

        try {
            const isLow = await isLowPcPerformance();
            store.dispatch(setUserPcPerformance(isLow));
        } catch (e) {
            console.log('Graphics settings was failed');
        }
    }
}
