import * as React from 'react';
import classNames from 'classnames';
import styles from './SerialBundleTitle.scss';
import { interpolate, t } from '~/utils/localization';

interface ISerialBundleTitle {
    withoutText?: boolean;
    step: number;
    totalCount?: number;
    className?: string;
}

const SerialBundleTitle = (props: ISerialBundleTitle) => {
    const text = interpolate(t('Пакет {step} из {total}'), {
        step: props.step,
        total: props.totalCount,
    });

    return <div className={classNames(styles.title, props.className)}>{!props.withoutText && text}</div>;
};

export default SerialBundleTitle;
