import gql from 'graphql-tag';

const permoflages = gql`
    query Permoflages($ids: [String], $lang: String) {
        permoflages(permoflageIds: $ids, lang: $lang) {
            id
            title
            typeName
            restrictions {
                specificShips
                forbiddenShips
                levels
                nations
                types
            }
            vehicles {
                isNative
                title
                vehicle {
                    id
                    level
                    nationName
                    typeName
                }
            }
        }
    }
`;

export default permoflages;
