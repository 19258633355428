import classNames from 'classnames';
import styles from './TreasureInfoBlock.scss';
import TreasureGuarantee from '~/components/TreasureChain/TreasureInfoBlock/TreasureGuarantee/TreasureGuarantee';
import TreasureRewardsSlider from '~/components/TreasureChain/TreasureInfoBlock/TreasureRewardsSlider/TreasureRewardsSlider';

type Props = {
    className: string;
    masterBox: TreasureMasterBox;

    hasPending: boolean;
    title?: string;
};

export default function TreasureInfoBlock(props: Props) {
    const wrapper = classNames(styles.wrapper, props.className);
    return (
        <div className={wrapper}>
            <div className={styles.title}>{props.title ? <img src={props.title} /> : props.masterBox.title}</div>
            <div className={styles.guarantee}>
                <TreasureGuarantee chains={props.masterBox.chains} />
            </div>
            <TreasureRewardsSlider chains={props.masterBox.chains} box={props.masterBox} hasPending={props.hasPending} />
        </div>
    );
}
