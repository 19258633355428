import * as React from 'react';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { isMobileOrTabletWindow } from '~/utils/utils';

export default function useDelegateContainerScroll(ref: React.RefObject<HTMLElement>, excludeElements: React.RefObject<HTMLElement>[] = [], deps: React.DependencyList = []) {
    const speed = 25;

    React.useEffect(() => {
        const mainContainer = RefManager.getRef(RefManagerKeys.MainContainer);
        const currentElement = ref.current;

        function scrollBottom() {
            const newElementPositionScroll = currentElement.scrollTop + speed;
            currentElement.scrollTop = newElementPositionScroll;

            if (newElementPositionScroll > currentElement.scrollHeight - currentElement.offsetHeight) {
                const newContainerPositionScroll = mainContainer.scrollTop + speed;
                mainContainer.scrollTop = newContainerPositionScroll;
            }
        }

        function scrollTop() {
            if (mainContainer.scrollTop > 0) {
                mainContainer.scrollTop = Math.max(mainContainer.scrollTop - speed, 0);
            } else {
                currentElement.scrollTop = Math.max(currentElement.scrollTop - speed, 0);
            }
        }

        function onWheel(event: WheelEvent) {
            if (event.ctrlKey || isMobileOrTabletWindow) {
                return;
            }

            if (excludeElements.length) {
                const elementUnderCursor = document.elementFromPoint(event.clientX, event.clientY);
                const isMatched = excludeElements.some((element) => element.current && (element.current === elementUnderCursor || element.current.contains(elementUnderCursor)));
                if (isMatched) {
                    event.preventDefault();
                    return;
                }
            }

            event.preventDefault();
            if (event.deltaY > 0) {
                scrollBottom();
            } else {
                scrollTop();
            }

            return false;
        }

        currentElement && mainContainer?.addEventListener('wheel', onWheel);

        return () => {
            mainContainer?.removeEventListener('wheel', onWheel);
        };
    }, deps);
}
