import * as React from 'react';

const useScroll = (ref: React.MutableRefObject<HTMLElement>, onScroll?: (event?: React.UIEvent) => void, onScrollEnd?: () => void, deps: React.DependencyList = []) => {
    const isScrollingRef = React.useRef<boolean | undefined>();
    const intervalRef = React.useRef<ReturnType<typeof setInterval> | undefined>();

    const elementScrollHandler = React.useCallback(() => {
        isScrollingRef.current = true;
        onScroll?.();
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            isScrollingRef.current = false;
            clearInterval(intervalRef.current);
            onScrollEnd?.();
        }, 100);
    }, [onScroll, onScrollEnd]);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        const element = ref.current;

        element?.addEventListener('scroll', elementScrollHandler);

        return () => {
            element?.removeEventListener('scroll', elementScrollHandler);
        };
    }, [elementScrollHandler, ref]);
};

export default useScroll;
