import * as React from 'react';
import styles from './Notifications.scss';
import { Button, OrangeButton } from '~/components/Button/Buttons';
import { t } from '~/utils/localization';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup, setVisibleInfoScreen } from '~/Actions/ActionApp';
import { LocalStorageHelper } from '~/utils/storage';
import { getNotificationNameFromLocalStorage, getNotificationToShowIfNeeded } from '~/components/Notifications/NotificationManager';
import classNames from 'classnames';
import { POPUPS_NAME } from '~/components/PopupManager';
import { updateViewNotification } from '~/Actions/ActionAccount';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import useClickAway from '~/hooks/useClickAway';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import { markNotificationAsViewed } from '~/api/account';
import { FadeInDiv } from '~/components/Transitions';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import equal from 'fast-deep-equal/react';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { redirectTo } from '~/utils/notifications';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

interface INotificationProps {
    data: INotification;
}

interface IStateSelector {
    accountId: number;
    viewedNotifications: string[];
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        viewedNotifications: state.ReducerAccount.viewedNotifications,
        currentPage: state.ReducerApp.currentPage,
    };
};

export const NotificationPopup = (props: INotificationProps) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const descriptionRef: React.RefObject<HTMLDivElement> = React.useRef(null);
    const dispatch = useDispatch();
    const startTime = Date.now();

    const close = (isDwhSendEvent = true) => {
        dispatch(updateViewNotification(props.data.name));
        const isNeedToShownNextInfoScreen = !!getNotificationToShowIfNeeded();
        dispatch(setVisibleInfoScreen(false, null, isNeedToShownNextInfoScreen));
        if (!isNeedToShownNextInfoScreen) {
            dispatch(changeVisiblePopup(POPUPS_NAME.INFO_SCREEN_POPUP));
        }

        if (isDwhSendEvent) {
            const endTime = Date.now();
            const duration = Math.floor((endTime - startTime) / 1000);
            dwhExport.send(DWH_EVENTS.CLOSE_NOTIFICATION, {
                notification_name: props.data.name,
                duration,
            });
        }
    };

    const openCategory = () => {
        close(false);
        const endTime = Date.now();
        const duration = Math.floor((endTime - startTime) / 1000);
        dwhExport.send(DWH_EVENTS.OPEN_CATEGORY_FROM_NOTIFICATION, {
            notification_name: props.data.name,
            duration,
        });
        if (props.data) {
            redirectTo(props.data);
        }
    };

    useClickAway(
        ref,
        () => {
            if (!isPopupActive) {
                return;
            }

            close.bind(this)();
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close.bind(this)();
    }, [KEYS_CODE.ESC]);

    const onScroll = () => {
        const descriptions = descriptionRef.current;
        if (!descriptions) {
            return;
        }

        if (descriptions.scrollHeight <= Math.ceil(descriptions.scrollTop + descriptions.offsetHeight)) {
            descriptions.classList.remove(styles.mask);
        } else {
            descriptions.classList.add(styles.mask);
        }
    };

    React.useEffect(() => {
        dispatch(setVisibleInfoScreen(true, props.data.name));

        if (descriptionRef.current?.scrollHeight > descriptionRef.current?.offsetHeight) {
            descriptionRef.current?.classList.add(styles.mask);
        }

        if (state.accountId && !state.viewedNotifications?.includes(props.data.name)) {
            markNotificationAsViewed(props.data.name);
        }
    }, [props.data.name]);

    const isNeedToShownRedirectButton = (props.data.redirectToCategory && state.currentPage?.name !== props.data.redirectToCategory) || props.data.linkHref;

    return (
        <div className={styles.wrapper}>
            <FadeInDiv className={styles.popup} ref={ref}>
                <img className={styles.popupImage} src={props.data.background} />
                <div className={styles.about}>
                    <div className={styles.title} dangerouslySetInnerHTML={{ __html: props.data.title }} />
                    <div className={styles.description} onScroll={onScroll} ref={descriptionRef} dangerouslySetInnerHTML={{ __html: props.data.description }} />
                    <div className={styles.footer}>
                        {isNeedToShownRedirectButton && <OrangeButton label={t('Перейти')} onClick={openCategory} />}
                        <Button label={t('Закрыть')} onClick={() => close()} className={'armory__auto--popup_close'} />
                    </div>
                </div>
            </FadeInDiv>
        </div>
    );
};

export const NotificationDefault = (props: INotificationProps) => {
    const dispatch = useDispatch();
    const [isAnimation, setAnimation] = React.useState(false);

    const appState = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);

    const close = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAnimation(false);

        setTimeout(() => {
            LocalStorageHelper.set(getNotificationNameFromLocalStorage(props.data.name), props.data.name);
            dispatch(setVisibleInfoScreen(false));
        }, 1000);
    };

    const showPopup = () => {
        setAnimation(false);
        dispatch(
            changeVisiblePopup(POPUPS_NAME.INFO_SCREEN_POPUP, true, {
                name: props.data.name,
            }),
        );

        setTimeout(() => {
            LocalStorageHelper.set(getNotificationNameFromLocalStorage(props.data.name), props.data.name);
            dispatch(setVisibleInfoScreen(false));
        }, 1000);
    };

    const showNotification = () => {
        setTimeout(() => {
            setAnimation(true);
        }, 500);
    };

    React.useEffect(() => {
        if (!!appState.popupActive) {
            return;
        }
        showNotification();
    }, [props.data.name, appState.popupActive]);

    React.useEffect(() => {
        if (!!appState.popupActive) {
            return;
        }
        showNotification();
    }, []);

    const notificationsClassNames = classNames(styles.notificationInline, {
        [styles.isActive]: isAnimation,
    });

    const style = {
        backgroundImage: `url(${props.data.previewImage})`,
    };

    if (!!appState.popupActive) {
        return null;
    }

    return (
        <div className={notificationsClassNames} onClick={showPopup} style={style}>
            <div className={styles.notificationInlineContent}>
                <div className={styles.notificationInlineClose} onClick={close} />
                <div className={styles.notificationInlineTitle} dangerouslySetInnerHTML={{ __html: props.data.title }} />
                <div className={styles.notificationInlineAbout} dangerouslySetInnerHTML={{ __html: props.data.description }} />
            </div>
        </div>
    );
};
