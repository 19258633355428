import equal from 'fast-deep-equal/react';
import styles from './CategoryTimer.scss';
import { t } from '~/utils/localization';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { disableCategory } from '~/Actions/ActionApp';
import classNames from 'classnames';
import CountDown from '~/components/CountDown/CountDown';
import { State } from '~/Reducers';
import { Clock } from '../Clock/Clock';
import { getParsedTime } from '~/utils/time';

interface ICategoryTimer {
    categoryName: string;
}

interface IStateSelector {
    categories: ICategories;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
    };
};

const CategoryTimer = (props: ICategoryTimer) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const category = state.categories[props.categoryName];

    const timerDate = Math.floor((new Date(category?.activeTill).getTime() - Date.now()) / 1000) || 0;

    return (
        <div className={classNames(styles.wrapper, styles[category.theme])} key={category.name}>
            <DivTooltip className={styles.title} tooltipBody={<DefaultTooltip text={category.activityCountdown?.customization?.description || t('Событие ограничено по времени')} />}>
                <Clock wrapperClassName={styles.clock} />
                <div className={styles.label}>{category.activityCountdown?.customization?.title || t('До конца события:')}</div>
            </DivTooltip>
            <div className={styles.timer}>
                <CountDown
                    className={styles.countDownWrapper}
                    date={category?.activeTill}
                    withoutIcon
                    onComplete={() => {
                        dispatch(disableCategory(props.categoryName));
                    }}
                    notice={(getParsedTime(timerDate)?.days || 0) < 1}
                />
            </div>
        </div>
    );
};

export default CategoryTimer;
