import { isInGameBrowser, isPortPreviewEnabled } from '~/utils/utils';
import { settings } from '~/utils/settings';
import { getBundleEntitlements } from '~/utils/bundles';
import { getLotById } from '~/utils/auction';
import { ItemTypes } from '@wg/wows-entities/const';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { openPort } from '~/api/WoWsClient';
import { PortDataType, PortType, sortBundleItems, sortLotRewardsItems } from '~/components/Port/settings';
import { ManagerData } from '~/core/ManagerData';

export type ItemForPreviewPortType = {
    identifier: number;
    shipId?: number;
    type: string;
};

interface ByBundleItemOptions {
    itemType: string;
    bundleId: number;
    dataType: PortDataType.BUNDLE;
    portType: PortType.PURCHASE;
    isVisible: boolean;
    showTTC?: boolean;
}

interface ByAuctionOptions {
    lotId: string;
    dataType: PortDataType.AUCTION_LOT;
    portType: PortType.DEFAULT;
    isVisible: boolean;
    showTTC?: boolean;
}

interface ByDefaultItemOptions {
    itemType: string;
    dataType: PortDataType.DEFAULT;
    portType: PortType.DEFAULT;
    isVisible: boolean;
}

//Base
export function openPortByItem(item: ItemForPreviewPortType, options: IPort) {
    let shipId: string | number = item.identifier;
    let exteriorId: string | number = -1;

    if (item.type === ItemTypes.VEHICLES) {
        openPort({
            shipId,
            exteriorId,
            ...options,
            showTTC: true,
        });
        return;
    }

    if (isPermanentCamouflage(item.type) && item.shipId) {
        shipId = item.shipId;
        exteriorId = item.identifier;

        openPort({
            ...options,
            shipId,
            exteriorId,
            itemType: ItemTypes.PERMOFLAGES,
            showTTC: options.showTTC ?? false,
        });
        return;
    }

    shipId = Number(getShipIdForStylePreview(item.identifier));
    if (isPermanentCamouflage(item.type) && shipId) {
        exteriorId = item.identifier;

        openPort({
            ...options,
            shipId,
            exteriorId,
            itemType: ItemTypes.PERMOFLAGES,
            showTTC: options.showTTC ?? false,
        });
        return;
    }

    if (isCamouflage(item.type) && item.shipId) {
        shipId = item.shipId;
        exteriorId = item.identifier;

        openPort({
            ...options,
            shipId,
            exteriorId,
            itemType: ItemTypes.CAMOUFLAGE,
            showTTC: options.showTTC ?? false,
        });
        return;
    }

    shipId = settings.camouflagePreviewDefaultShip;
    if (isCamouflage(item.type) && shipId) {
        exteriorId = item.identifier;

        openPort({
            ...options,
            shipId,
            exteriorId,
            itemType: ItemTypes.CAMOUFLAGE,
            showTTC: options.showTTC ?? false,
        });
        return;
    }
}

//Bundle
export function openPortByBundleItem(item: ItemForPreviewPortType, bundleId: number, isVisiblePort = false) {
    const options: ByBundleItemOptions = {
        itemType: item.type,
        bundleId,
        dataType: PortDataType.BUNDLE,
        portType: PortType.PURCHASE,
        isVisible: isVisiblePort,
    };

    if (isPermanentCamouflage(item.type) && item.shipId) {
        const bundleEntitlements = getBundleEntitlements(bundleId);
        const isShipFromBudnle = !!bundleEntitlements && !!bundleEntitlements.find((entity) => entity.identifier === item.shipId);

        options.showTTC = isShipFromBudnle;
    }

    openPortByItem(item, options);
}

export function openPortForFirstBundleItem(bundle: IBundle) {
    const sortedBundleItems = sortBundleItems(bundle);
    const firstItem = sortedBundleItems[0];

    if (firstItem) {
        const portItem: ItemForPreviewPortType = {
            identifier: firstItem.identifier,
            shipId: firstItem.customisation?.shipId,
            type: firstItem.type,
        };

        openPortByBundleItem(portItem, bundle.id, false);
    }
}

//Auction
export function openPortByAuction(item: ItemForPreviewPortType, lotId: string, isVisiblePort = false) {
    const options: ByAuctionOptions = {
        dataType: PortDataType.AUCTION_LOT,
        portType: PortType.DEFAULT,
        isVisible: isVisiblePort,
        lotId,
    };

    if (isPermanentCamouflage(item.type) && item.shipId) {
        const lot = getLotById(lotId);
        const isShipFromBudnle = !!lot?.gameRewards && !!lot.gameRewards.find((reward) => reward.id === item.shipId);

        options.showTTC = isShipFromBudnle;
    }

    debugger;
    openPortByItem(item, options);
}

export function openPortByAuctionReward(reward: IGameRewards, lotId: string) {
    openPortByAuction({ identifier: reward.id, shipId: reward.customisation?.ship_id, type: reward.type }, lotId);
}

export const openPortForFirstAuctionReward = (lot: ILot) => {
    const rewards = sortLotRewardsItems(lot.gameRewards);
    const firstReward = rewards[0];
    firstReward && openPortByAuctionReward(firstReward, lot.id);
};

//Default
export function openPortByDefaultItem(item: ItemForPreviewPortType, isVisiblePort = false) {
    const options: ByDefaultItemOptions = {
        itemType: item.type,
        dataType: PortDataType.DEFAULT,
        portType: PortType.DEFAULT,
        isVisible: isVisiblePort,
    };

    openPortByItem(item, options);
}

//Utils
export function isEnabledPortFromAuction(type: string, id: number, lot?: ILot) {
    if (getShipIdForStylePreview(id) && isPermanentCamouflage(type)) {
        return true;
    }

    if (isCamouflage(type) && settings.camouflagePreviewDefaultShip) {
        return true;
    }

    if (type === ItemTypes.VEHICLES && (lot?.enablePortPreview || lot?.enablePortPreview === null || !lot)) {
        return true;
    }

    return false;
}

export function isEnabledPortByLot(lot: ILot) {
    return isInGameBrowser && !!lot.enablePortPreview;
}

export function isEnabledPortByBundle(bundle: IBundle, isEnableForRandomBundle = true, isPrimary = false) {
    const sortedBundleItems = sortBundleItems(bundle);

    if (!isPortPreviewEnabled || bundle.disablePortPreview) {
        return false;
    }

    const firstItem = sortedBundleItems[0];
    if (!firstItem) {
        return false;
    }

    if (isPrimary && !firstItem.isPrimary) {
        return false;
    }

    if (!isPermanentCamouflage(firstItem.type) && !isCamouflage(firstItem.type) && firstItem.type !== ItemTypes.VEHICLES) {
        return false;
    }

    if (bundle.isRandom && !isEnableForRandomBundle) {
        return false;
    }

    if (isPermanentCamouflage(firstItem.type) && !getShipIdForStylePreview(firstItem.identifier)) {
        return false;
    }

    return true;
}

export function isPortPreviewEnabledByItem(item: ItemForPreviewPortType) {
    if (!isPortPreviewEnabled) {
        return false;
    }

    if (item.type === ItemTypes.VEHICLES) {
        return true;
    }

    if (isPermanentCamouflage(item.type) && getShipIdForStylePreview(item.identifier)) {
        return true;
    }
    if (isCamouflage(item.type) && settings.camouflagePreviewDefaultShip) {
        return true;
    }

    return false;
}

export function isEnabledPortForItemInsideBundle(item: IBundleEntity, bundle?: IBundle) {
    if (!isPortPreviewEnabled) {
        return false;
    }

    if (bundle?.disablePortPreview) {
        return false;
    }

    if (isPermanentCamouflage(item.type) && item.customisation?.shipId) {
        return true;
    }

    return isPortPreviewEnabledByItem(item as ItemForPreviewPortType);
}

export function getShipIdForStylePreview(permoflageId?: number): number | string | null {
    if (!permoflageId) return null;

    const stylePreviewDefaultShips = settings.stylePreviewDefaultShips || [];
    if (!stylePreviewDefaultShips.length) return null;

    const permoflageInfo = ManagerData.getPermoflage(permoflageId);
    if (!permoflageInfo.restrictions) return null;

    const isAllowedField = (array: number[] | string[], field: number | string) => {
        const stringArray: string[] = [...(array || [])].map((item: number | string) => item.toString());

        return !stringArray.length || stringArray.includes(field.toString());
    };

    for (const index in stylePreviewDefaultShips) {
        const shipId = stylePreviewDefaultShips[index];
        const ship = ManagerData.getShip(Number(shipId));

        if (permoflageInfo.restrictions.specificShips?.includes(shipId.toString())) {
            return shipId;
        }

        if (permoflageInfo.restrictions.forbiddenShips?.includes(shipId.toString())) {
            continue;
        }

        if (
            isAllowedField(permoflageInfo.restrictions.types, ship.type.name) &&
            isAllowedField(permoflageInfo.restrictions.nations, ship.nation.name) &&
            isAllowedField(permoflageInfo.restrictions.levels, ship.level)
        ) {
            return shipId;
        }
    }

    return null;
}
