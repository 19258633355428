import * as React from 'react';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import { BLACK_FRIDAY_THEME } from '~/Layouts/Themes/ThemeManager';
import BlackFriday from '~/customization/BlackFriday/Category/BlackFriday';

interface IContentLayoutDecoration {
    theme: ALLOWED_THEMES;
    categoryName: ICategoryList;
    children: React.ReactChild | React.ReactChild[];
}

const ContentLayoutDecoration = ({ theme, children, categoryName }: IContentLayoutDecoration) => {
    switch (theme) {
        case BLACK_FRIDAY_THEME: {
            return <BlackFriday categoryName={categoryName}>{children}</BlackFriday>;
        }

        default: {
            return <React.Fragment>{children}</React.Fragment>;
        }
    }
};

export default ContentLayoutDecoration;
