import * as React from 'react';
import styles from './ButtonEsc.scss';
import { playButtonClickSound } from '~/api/WoWsClient';
import classNames from 'classnames';

interface IProps {
    onClick?: (event?: React.MouseEvent | React.TouchEvent) => void;
    className?: string;
}

const ButtonEsc = (props: IProps) => {
    return (
        <div
            className={classNames(styles.button, props.className)}
            onTouchEnd={(event: React.TouchEvent) => {
                props.onClick?.(event);
            }}
            onClick={(event) => {
                playButtonClickSound();
                props.onClick?.(event);
            }}
        />
    );
};

export default ButtonEsc;
