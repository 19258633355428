import React from 'react';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { LocalStorageHelper } from '~/utils/storage';
import { LOCAL_STORAGE_VIEWED_BUNDLE_DIALOGS } from '~/utils/keys';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { TypingText, TypingTextRef } from '../TypingText/TypingText';
import { DialogChangeStepButton } from './DialogChangeStepButton/DialogChangeStepButton';

import styles from './Dialog.scss';

interface DialogProps {
    wrapperClassName?: string;
    steps: Array<DialogOptionsStep>;
    bundleId: number;
    theme?: string;
}

function updateDialogLocalStorageData(bundleId: number, isMinimized: boolean) {
    const allDialogsDataFromLocalStorage = LocalStorageHelper.get(LOCAL_STORAGE_VIEWED_BUNDLE_DIALOGS) || {};
    const newDialogData = {
        isMinimized,
    };

    allDialogsDataFromLocalStorage[bundleId] = {
        ...(allDialogsDataFromLocalStorage[bundleId] || {}),
        ...newDialogData,
    };

    LocalStorageHelper.set(LOCAL_STORAGE_VIEWED_BUNDLE_DIALOGS, allDialogsDataFromLocalStorage);
}

export function Dialog({ wrapperClassName, steps, theme, bundleId }: DialogProps) {
    const isMinimizedByLocalStorage = React.useMemo(() => {
        const data = LocalStorageHelper.get(LOCAL_STORAGE_VIEWED_BUNDLE_DIALOGS)?.[bundleId];

        return !!data?.isMinimized;
    }, [bundleId]);

    //* Base logic
    const [currentStep, setCurrentStep] = React.useState(0);
    const [isMinimizedView, setIsMinimizedView] = React.useState(!!isMinimizedByLocalStorage);

    const contentRef = React.useRef<HTMLDivElement>(null);
    const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

    const viewToggleButtonClickHandler = React.useCallback(() => {
        if (timerRef.current) {
            return;
        }

        updateDialogLocalStorageData(bundleId, !isMinimizedView);

        contentRef.current.style.opacity = '0';
        timerRef.current = setTimeout(() => {
            contentRef.current.style.opacity = '1';
            setIsMinimizedView(!isMinimizedView);

            timerRef.current = setTimeout(() => {
                contentRef.current.style.opacity = undefined;
                timerRef.current = undefined;
            }, 250);
        }, 250);

        dwhExport.send(DWH_EVENTS.DIALOG_TOGGLE_VIEW, { bundle_id: bundleId });
    }, [bundleId, isMinimizedView]);

    const changeStepButtonNextClickHandler = React.useCallback(() => {
        setCurrentStep((prev) => (prev === steps.length - 1 ? prev : prev + 1));

        dwhExport.send(DWH_EVENTS.DIALOG_NEXT_STEP, { bundle_id: bundleId });
    }, [bundleId, steps.length]);

    const changeStepButtonPrevClickHandler = React.useCallback(() => {
        setCurrentStep((prev) => (prev === 0 ? prev : prev - 1));

        dwhExport.send(DWH_EVENTS.DIALOG_PREV_STEP, { bundle_id: bundleId });
    }, [bundleId]);

    //* Skip animation logic
    const [canSkipAnimation, setCanSkipAnimation] = React.useState(true);
    const typingTextRef = React.useRef<TypingTextRef>(null);

    const contentTextClickHandler = React.useCallback(() => {
        canSkipAnimation && !isMinimizedView && typingTextRef?.current && typingTextRef.current.skipAnimation();
    }, [isMinimizedView, canSkipAnimation]);

    const onFinishCallback = React.useCallback(() => {
        setCanSkipAnimation(false);
    }, []);

    //* Used to enable skip after current step changed
    React.useEffect(() => {
        !isMinimizedView && setCanSkipAnimation(true);
    }, [currentStep, isMinimizedView]);

    React.useEffect(() => {
        return () => {
            timerRef.current && clearTimeout(timerRef.current);
        };
    }, []);

    React.useEffect(() => {
        if (currentStep === steps.length - 1) {
            dwhExport.send(DWH_EVENTS.DIALOG_FINISH, { bundle_id: bundleId });
        }
    }, [currentStep, steps, bundleId]);

    return (
        <div
            className={classNames(styles.wrapper, wrapperClassName, {
                [styles[`wrapper_${theme}`]]: !!theme,
                [styles.wrapper_minimized]: isMinimizedView,
            })}
            ref={contentRef}
        >
            <div className={styles.shadowBlock} />
            <div className={styles.contentWrapper}>
                <DialogChangeStepButton className={styles.changeStepButton} theme={theme} flipped disabled={currentStep === 0} onClick={changeStepButtonPrevClickHandler} />
                <div className={styles.content}>
                    <div
                        className={classNames(styles.contentHeader, {
                            [styles.contentHeader_noTitle]: !steps[currentStep].title,
                        })}
                    >
                        <div className={styles.contentHeaderTitle}>
                            <div className={classNames(styles.contentHeaderItemBlock, styles.contentHeaderItemBlock_right)}>
                                <div className={styles.contentHeaderItemBlockFakeContent}>{steps[currentStep].title}</div>
                            </div>
                            <div className={styles.contentHeaderTitleContent}>{steps[currentStep].title}</div>
                        </div>
                        <button className={styles.viewToggleButton} onClick={viewToggleButtonClickHandler}>
                            <div className={classNames(styles.contentHeaderItemBlock, styles.contentHeaderItemBlock_left)}>
                                <div className={styles.contentHeaderItemBlockFakeContent}>
                                    <span className={styles.viewToggleButtonContentText}>{isMinimizedView ? t('Развернуть') : t('Свернуть')}</span>
                                    <div className={styles.viewToggleButtonIcon} />
                                </div>
                            </div>
                            <div className={styles.viewToggleButtonContent}>
                                <span className={styles.viewToggleButtonContentText}>{isMinimizedView ? t('Развернуть') : t('Свернуть')}</span>
                                <div className={styles.viewToggleButtonIcon} />
                            </div>
                        </button>
                    </div>
                    <div
                        className={classNames(styles.contentTextWrapper, {
                            [styles.contentTextWrapper_canSkip]: canSkipAnimation && !isMinimizedView,
                        })}
                        onClick={contentTextClickHandler}
                    >
                        <TypingText
                            key={`typing_text_${isMinimizedView}`}
                            ref={typingTextRef}
                            className={styles.contentText}
                            text={isMinimizedView ? steps[currentStep].shortDescription : steps[currentStep].description}
                            withoutAnimation={isMinimizedView}
                            onFinishCallback={onFinishCallback}
                        />
                        <div className={styles.contentTextSteps}>
                            <span>{currentStep + 1}</span>
                            <span>/</span>
                            <span>{steps.length}</span>
                        </div>
                    </div>
                </div>
                <DialogChangeStepButton className={styles.changeStepButton} disabled={currentStep === steps.length - 1} onClick={changeStepButtonNextClickHandler} theme={theme} />
            </div>
        </div>
    );
}
