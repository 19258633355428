import * as React from 'react';
import classNames from 'classnames';
import styles from './Tooltip.scss';
import { TooltipFooter } from '@wg/wows-react-uikit';
import { TooltipTypes } from '~/types/common';

export interface IDefaultTooltipClasses {
    header?: string;
    body?: string;
    footer?: string;
}

export interface IDefaultTooltip {
    title?: string;
    text?: string | string[] | React.ReactChild[];
    footer?: any;
    type?: TooltipTypes;
    mouseIcon?: boolean;
    classes?: IDefaultTooltipClasses;
    count?: number;
}

class DefaultTooltip extends React.PureComponent<IDefaultTooltip> {
    render() {
        const isArrayTexts = Array.isArray(this.props.text);

        const classesBody = classNames(
            styles.defaultTooltipBody,
            {
                [styles.warning]: this.props.type === TooltipTypes.WARNING,
                [styles.withBorderBottom]: isArrayTexts,
            },
            this.props.classes?.body,
        );

        const classesHeader = classNames(styles.defaultTooltipHeader, this.props.classes?.header);
        const classesFooter = classNames(styles.footer, this.props.classes?.footer, {
            [styles.hiddenBorder]: this.props.title && this.props.footer && !this.props.text,
        });
        const classesFooterWithCount = classNames(styles.defaultTooltipBody, {
            [styles.withBorderTop]: this.props.count,
        });
        return (
            <React.Fragment>
                <div className={styles.defaultTooltip}>
                    {this.props.title && <div className={classesHeader}>{this.props.title}</div>}
                    {this.props.text && typeof this.props.text === 'string' && <div className={classesBody} dangerouslySetInnerHTML={{ __html: this.props.text }} />}
                    {this.props.text &&
                        isArrayTexts &&
                        (this.props.text as string[]).map((text, index) => {
                            if (typeof text === 'string') {
                                return <div key={`tooltip_text_item_${index}`} className={classesBody} dangerouslySetInnerHTML={{ __html: text }} />;
                            }

                            return (
                                <div key={`tooltip_text_item_${index}`} className={classesBody}>
                                    {text}
                                </div>
                            );
                        })}
                </div>
                {this.props.footer ? (
                    this.props.count ? (
                        <div className={classesFooterWithCount} dangerouslySetInnerHTML={{ __html: this.props.footer }} />
                    ) : (
                        <div className={classesFooter}>
                            {this.props.mouseIcon ? <TooltipFooter mouseIcon={'left'}>{this.props.footer}</TooltipFooter> : <div className={styles.footerText}>{this.props.footer}</div>}
                        </div>
                    )
                ) : null}
            </React.Fragment>
        );
    }
}

export default DefaultTooltip;
