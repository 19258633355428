import * as React from 'react';
import Account from '~/account/Account';
import { VIDEO_NAMES } from '~/components/VideoPlayer/videoMap';
import { startVideo } from '~/Actions/ActionApp';
import { useDispatch } from 'react-redux';

const useVideoAutoPlay = (videoName: VIDEO_NAMES | string, fadeOutVideoInSeconds?: number, isNeedToPlay = true, isViewAccountSpecific = true, url?: string, onFinish?: () => void) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!Account.isViewedVideo(videoName, isViewAccountSpecific) && isNeedToPlay) {
            Account.markViewedVideo(videoName, isViewAccountSpecific);
            dispatch(startVideo(videoName, url, onFinish || null, fadeOutVideoInSeconds));
        }
    }, [isNeedToPlay]);
};

export default useVideoAutoPlay;
