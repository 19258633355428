export function setupIcons() {
    const linkDarkIcon: HTMLLinkElement = document.querySelector('link#dark-favicon');
    const linkLightIcon: HTMLLinkElement = document.querySelector('link#light-favicon');
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');

    function setLight() {
        document.head.append(linkLightIcon);
        linkDarkIcon.remove();
    }

    function setDark() {
        linkLightIcon.remove();
        document.head.append(linkDarkIcon);
    }

    const onUpdate = () => {
        if (matcher.matches) {
            setDark();
        } else {
            setLight();
        }
    };

    matcher.addEventListener('change', onUpdate);
    onUpdate();
}
