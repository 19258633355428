import * as React from 'react';
import MrpsShipDescriptionPopup from '~/components/Mrps/MrpsShipDescriptionPopup/MrpsShipDescriptionPopup';
import { isOnlyShipBundle } from '~/utils/bundles';
import ItemDescriptionPopup, { IItemDescriptionPopupData } from '~/components/Popups/ItemDescription/ItemDescriptionPopup';
import styles from './TreasureDescriptionPopup.scss';
import classNames from 'classnames';

interface Props extends TreasureReward {
    background?: string;
}

export default function TreasureDescriptionPopup(props: Props) {
    const style = { wrapper: styles.wrapper, body: styles.body, header: styles.header };
    const itemStyle = { ...style, header: styles.hidden, body: classNames(styles.body, styles.itemBody) };
    const background = { backgroundImage: `url(${props.background})` } as React.CSSProperties;

    if (isOnlyShipBundle(props)) {
        return <MrpsShipDescriptionPopup data={props} popupStyles={style} withoutFooter style={background} withoutZoomIn />;
    }
    const itemDescriptionData: IItemDescriptionPopupData = {
        item: props.entitlements[0],
        autoDescriptionSettings: { isDisabled: false, lootboxSettings: null },
    };
    return <ItemDescriptionPopup withoutFooter data={itemDescriptionData} popupStyles={itemStyle} withoutZoomIn style={background} />;
}
