export interface ITradeItemIcons {
    small: string;
    big: string;
}

export interface IITradeItemAdditionalData {
    level?: number;
    title?: Record<string, string>;
    images: {
        small: string;
        contour?: string;
    };
    nation?: Nullable<{
        icons: {
            default?: string;
        };
        name: string;
    }>;
    shipType?: Nullable<string>;
    tags?: Nullable<string[]>;
    shipId?: Nullable<number>;
}

export interface ITradeItem {
    identifier: number;
    type: string;
    price: number;
    currency: string;
    isReceived: boolean;
    images: ITradeItemIcons;
    additionalData?: IITradeItemAdditionalData;

    //fe fields
    title: string;
}

export interface ITradeList {
    type: string;
    source: ITradeItem[];
    target: ITradeItem[];
}

export enum ChooseItemFor {
    GET = 'get',
    TRADE = 'trade',
}

export enum TradeInStatus {
    SUCCESS = 'success',
    ERROR = 'error',
}

export type ITradeLists = Record<string, ITradeList[]>;

export interface ITradeInResult {
    targetId: number;
    sourceId: number;
    ts: number;
}
