import { Actions } from '~/Actions';
import { ADD_LOOTBOX_TO_STORE } from '~/Actions/ActionLootbox';

export interface LootboxState {
    lootboxes: ILootboxes;
}

export const initialState: LootboxState = {
    lootboxes: {},
};

const ReducerLootbox = (state: LootboxState = initialState, action: Actions) => {
    switch (action.type) {
        case ADD_LOOTBOX_TO_STORE:
            return {
                ...state,
                lootboxes: {
                    ...(state.lootboxes || {}),
                    [action.lootbox.id]: action.lootbox,
                },
            };

        default:
            return { ...state };
    }
};

export default ReducerLootbox;
