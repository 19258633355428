import { tpl } from '~/utils/graphql';

const inventory = tpl`{
    inventory {
        storage {
            id
            count
        }
    }
}`;

export default inventory;
