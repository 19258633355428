import gql from 'graphql-tag';

const assets = gql`
    query Assets($lang: String) {
        currencies(lang: $lang) {
            name
            title
            tooltipDescription
            tooltipGetDescription
            tooltipStatusInfo
            icons {
                default
                large
                small
            }
        }
    }
`;

export default assets;
