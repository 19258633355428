import * as React from 'react';
// import { history } from '~/utils/history';
import { Provider } from 'react-redux';
import { TooltipProvider } from '@wg/wows-react-uikit';
// import ViewContainer from '~/containers/ViewContainer/ViewContainer';
import { setClientStateListener } from '~/api/WoWsClient';
import * as Sentry from '@sentry/react';
import store from '~/Store';
import { RouterProvider } from 'react-router-dom';
import router from '~/routing/router';

import '@wg/wows-css-uikit/index.css';
import '@wg/wows-entities/index.css';
import 'swiper/scss';
import 'swiper/scss/virtual';
import 'swiper/scss/navigation';
import 'swiper/scss/free-mode';
import 'swiper/scss/effect-fade';
import './assets/styles/common.scss';

import { settings } from '~/utils/settings';
import sync, { idleTracker, loadingLogger } from '~/sync';
import { handleForceLoginRedirect } from '~/utils/redirects';
import { saveAnalyticsData, getAnalyticsData } from '~/utils/analytics';
import { preloadImages } from '~/utils/preCacheImage';
import { setupIcons } from '~/utils/favicon';
import { initDevModeIfNeeded } from '~/utils/devMode';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Socket from '~/ws/socket';
import { disableDoubleClick } from '~/utils/dom';
import { initializationFilterPosition } from '~/utils/filters';
import AppInit from '~/core/AppInit/AppInit';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';

initializationFilterPosition();
saveAnalyticsData();
handleForceLoginRedirect();

setClientStateListener();
idleTracker.start();
sync();
initDevModeIfNeeded();

setupIcons();

const accountId = window.metashop?.state?.account?.id;
const dsn = settings?.sentry?.dsn;
if (dsn) {
    Sentry.init({ dsn: dsn });

    if (accountId) {
        Sentry.setUser({ id: '' + accountId });
    }
}

// @ts-ignore
window.clearAllBodyScrollLocks = clearAllBodyScrollLocks;
// @ts-ignore
window.disableBodyScroll = disableBodyScroll;

function App() {
    function initDWH() {
        const data = getAnalyticsData();
        dwhExport.send(DWH_EVENTS.APP_START, {
            from_auth: document.referrer.includes('://auth') || null,
            campaign: data.campaign,
        });
    }

    React.useEffect(() => {
        updateBrowserControlState(false);
        preloadImages();
        loadingLogger();
        disableDoubleClick();
        initDWH();
        AppInit();
        new Socket();
    }, []);

    return (
        <Provider store={store}>
            <TooltipProvider>
                <RouterProvider router={router} />
            </TooltipProvider>
        </Provider>
    );
}

export default App;
