import { disableBodyScroll as disableScroll, enableBodyScroll } from 'body-scroll-lock';

export const findParentNode = (cssClassName: string, target: any) => {
    let el: any = target;
    while (!el.classList.contains(cssClassName)) {
        el = el.parentNode;
        if (!el?.classList) {
            break;
        }
    }

    return el;
};

export const isInViewport = (element: HTMLElement, viewPort: HTMLElement) => {
    if (!element || !viewPort) {
        return false;
    }

    const bounding = element.getBoundingClientRect();

    return bounding.top >= 0 && bounding.left >= 0 && bounding.right <= viewPort.clientWidth && bounding.bottom <= viewPort.clientHeight;
};

export const disableBodyScroll = (isDisabled = false) => {
    if (isDisabled) {
        document.body.classList.add('disabledScroll');
        document.body.style.overflowY = 'hidden';
    } else {
        document.body.classList.remove('disabledScroll');
        document.body.style.overflowY = 'auto';
    }
};

export const disableDocumentScroll = (isDisabled = false) => {
    if (isDisabled) {
        disableScroll(document.documentElement);
    } else {
        enableBodyScroll(document.documentElement);
    }
};

export const disableDoubleClick = () => {
    document.addEventListener('dblclick', (event) => {
        event.preventDefault();
    });
};
