import { ManagerData } from '~/core/ManagerData';
import { getLevelFromRomanFormat } from '~/utils/utils';
import { interpolate, t } from '~/utils/localization';
import { isLegendaryShip } from '~/components/Ship/Ship';
import { componentHelper } from '@wg/wows-entities';

export const getInlineShipInfo = (shipId: number): string => {
    const ship = ManagerData.getShip(shipId);
    if (!ship) {
        return null;
    }

    const typeName = ship.type?.title;
    const level = getLevelFromRomanFormat(ship.level);

    if (isLegendaryShip(ship.tags || [])) {
        return typeName;
    }

    if (ship.level === 11) {
        const name = componentHelper.getSuperVehicleName(ship.type?.name);
        if (name) {
            return name;
        }
        return null;
    }

    return interpolate(t('{type} {level} уровня'), {
        type: typeName,
        level,
    });
};
