import styles from './TreasurePurchaseSuccessPopup.scss';
import popupStyles from '../TreasureDescriptionPopup/TreasureDescriptionPopup.scss';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';
import * as React from 'react';
import { Button } from '~/components/Button/Buttons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { TreasureRewardItem } from '~/components/TreasureChain/TreasureInfoBlock/TreasureRewardsSlider/TreasureReward/TreasureReward';
import { State } from '~/Reducers';
import classNames from 'classnames';
import { concatRewards } from '~/utils/treasure';
import { showTreasureAnimation, updateAccountOnTreasurePurchase } from '~/Actions/ActionTreasure';
import { POPUPS_NAME } from '~/components/PopupManager';

type Props = {
    transaction?: TreasureTransaction;
};

type StateSelector = {
    inventory: InventoryState;
    storedTransaction: TreasureTransaction;
    box: TreasureMasterBox;
};

function stateSelector(state: State): StateSelector {
    return {
        inventory: state.ReducerAccount.inventory,
        storedTransaction: state.ReducerTreasure.activeTransaction,
        box: state.ReducerTreasure.currentBox,
    };
}

export default function TreasurePurchaseSuccessPopup({ transaction }: Props) {
    const dispatch = useDispatch();
    const close = () => {
        dispatch(showTreasureAnimation(null));
        dispatch(updateAccountOnTreasurePurchase(undefined));
        dispatch(changeVisiblePopup(POPUPS_NAME.TREASURE_SUCCESS_PURCHASE, false));
    };
    const state = useSelector<State, StateSelector>((state) => stateSelector(state), shallowEqual);
    const storedTransaction = transaction || state.storedTransaction;

    if (!storedTransaction?.rewards?.length) return null;

    const boxRewards = concatRewards(state.box, true);
    const treasureRewards: TreasureReward[] = [];
    storedTransaction.rewards.forEach((reward) => {
        const rewardWithFullData = boxRewards.find((item) => item.name === reward.name);
        !!rewardWithFullData && treasureRewards.push(rewardWithFullData);
    });
    treasureRewards.sort((a, b) => a?.weight - b?.weight);

    return (
        <Popup onClose={close} popupClassName={classNames(popupStyles.wrapper, styles.wrapper)} renderEscButton>
            <PopupHeader title={t('Спасибо за покупку')} />
            <PopupBody className={classNames(popupStyles.body, styles.body)}>
                <div className={styles.rewards}>
                    {treasureRewards?.map((reward, i) => {
                        return <TreasureRewardItem key={i} rewardData={reward} withoutPopup withAmount inventory={state.inventory} hideIsOwned fromPopup />;
                    })}
                </div>
            </PopupBody>
            <PopupFooter>
                <Button label={t('Закрыть')} onClick={close} />
            </PopupFooter>
        </Popup>
    );
}
