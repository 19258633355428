import { BundlePurchaseTypes } from '~/types/bundle';
import { isEnoughCurrencyWithBestCoupon } from '~/utils/purchase';

import PurchaseWrapper from '~/components/PurchaseWrapper/PurchaseWrapper';
import PurchaseButton from '~/components/Button/PurchaseButton';

import styles from './PortPurchaseBlock.scss';

interface PortPurchaseBlockProps {
    bundle: IBundle;
    balance: IBalance;
    coupons: Array<ICoupon>;

    isDisabledPurchase?: boolean;

    purchaseHandler: () => void;
}

export function PortPurchaseBlock(props: PortPurchaseBlockProps) {
    if (props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) {
        return null;
    }

    const isEnoughCurrencyWithCoupon = isEnoughCurrencyWithBestCoupon(props.balance, props.bundle, props.coupons);

    return (
        <div className={styles.wrapper}>
            <div className={styles.priceWrapper}>
                <PurchaseWrapper bundle={props.bundle} withoutCoupon={true} withoutAnimation={true} useInitialPrice={true} showExchangePrices={true} withoutQuantityWidget={true} />
            </div>
            {!props.isDisabledPurchase && (
                <div className={styles.purchaseWrapper}>
                    <PurchaseButton
                        bundle={props.bundle}
                        onClick={props.purchaseHandler}
                        buttonClassName={styles.purchaseButton}
                        classNameFastGoldLink={styles.fastGoldLink}
                        isForceRenderPurchaseButton={isEnoughCurrencyWithCoupon}
                    />
                </div>
            )}
        </div>
    );
}
