import {
    MrpsAction,
    RESET_MRPS_CURRENT_EVENT,
    RESET_SPENDED_MRPS_RESOURCES,
    SAVE_MRSP_ACCOUNT_DATA,
    SAVE_MRSP_EVENTS,
    SPEND_MRPS_RESOURCE,
    TOGGLE_ACTIVE_EXCHANGE_TRANSACTION,
    TOGGLE_EXCHANGE_MENU,
    TOGGLE_NEXT_RENDER_WITHOUT_RESET_STATE,
    UPDATE_MRPS_EVENTS_REWARDS,
    UPDATE_MRPS_INFO_BLOCK_CONTENT,
} from '~/Actions/ActionMrpsType';

export interface MrpsState {
    hasPendingTransactions: boolean;
    isNextRenderWithoutReset: boolean;
    events: MrpsEvent[]; // all mrps events
    account?: MrpsAccountState; // mrps account data
    currentEvent?: MrpsCurrentEventState; // mrps event dynamic data of current page
}

const initialState: MrpsState = {
    hasPendingTransactions: false,
    isNextRenderWithoutReset: false,
    events: [],
};

export default function reducerMrps(state: MrpsState = initialState, action: MrpsAction): MrpsState {
    switch (action.type) {
        case SAVE_MRSP_EVENTS: {
            return {
                ...state,
                events: action.events,
            };
        }
        case RESET_MRPS_CURRENT_EVENT: {
            return {
                ...state,
                currentEvent: action.currentEvent,
            };
        }
        case SAVE_MRSP_ACCOUNT_DATA: {
            const currentEventData: MrpsCurrentEvenAdditionalData = {
                isExchangeMenuOpen: state.currentEvent?.data ? state.currentEvent.data.isExchangeMenuOpen : 2,
                availableRewards: state.currentEvent?.data ? state.currentEvent.data.availableRewards || [] : [],
                currentInfo: state.currentEvent?.data?.currentInfo,
            };

            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    data: currentEventData,
                },
                account: action.account,
            };
        }
        case SPEND_MRPS_RESOURCE: {
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    currentProgress: action.currentProgress,
                    resourcesInUse: action.resourcesSpent,
                    data: {
                        ...state.currentEvent.data,
                        availableRewards: action.availableRewards,
                    },
                },
            };
        }
        case RESET_SPENDED_MRPS_RESOURCES: {
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    resourcesInUse: action.resetedResourceInUse,
                    currentProgress: action.resetedCurrentProgress,
                },
            };
        }
        case UPDATE_MRPS_EVENTS_REWARDS: {
            return {
                ...state,
                events: action.updatedEvents,
            };
        }
        case UPDATE_MRPS_INFO_BLOCK_CONTENT: {
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    data: {
                        ...state.currentEvent.data,
                        currentInfo: action.currentInfo,
                    },
                },
            };
        }
        case TOGGLE_EXCHANGE_MENU: {
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    data: {
                        ...state.currentEvent.data,
                        isExchangeMenuOpen: action.isExchangeMenuOpen,
                    },
                },
            };
        }
        case TOGGLE_ACTIVE_EXCHANGE_TRANSACTION: {
            return {
                ...state,
                hasPendingTransactions: action.hasPendingTransactions,
            };
        }
        case TOGGLE_NEXT_RENDER_WITHOUT_RESET_STATE: {
            return {
                ...state,
                isNextRenderWithoutReset: action.isNextRenderWithoutReset,
            };
        }

        default:
            return { ...state };
    }
}
