import { ITradeList } from '~/types/tradein';
import { get, post } from '~/utils/ajax';
import { getAnalyticsData } from '~/utils/analytics';
import { settings } from '~/utils/settings';

export type TradeinStateCategoryType = {
    categoryName: string;
    tradeinLists: ITradeList[];
};
export type TradeinStateSyncType = {
    ts: number;
    tradeinState: TradeinStateCategoryType[];
};
export const tradeinStateSync = async (): Promise<TradeinStateSyncType> => {
    return await get(settings.urls.tradeinState);
};

export const tradein = async (sourceId: number, targetId: number, categoryName: string) => {
    const source = getAnalyticsData();
    const payload = { target_id: targetId, source_id: sourceId, tradein_category: categoryName, source };

    return await post(settings.urls.tradein, { data: JSON.stringify(payload) }, {}, true);
};
