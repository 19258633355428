import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { hasAnimationSetting, isDisabledAnimationByName, updateAnimationStatusByName } from '~/utils/animations/settings';
import { State } from '~/Reducers';
import { shallowEqual, useSelector } from 'react-redux';
import React from 'react';
import { ICurrentPage } from '~/Actions/ActionAppType';

interface IStateSelector {
    categories: ICategories;
    categoriesAnimationStatuses: ICategoriesAnimationStatuses;
    isLowPcPerformance: boolean;
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
        categoriesAnimationStatuses: state.ReducerApp.categoriesAnimationStatuses,
        isLowPcPerformance: state.ReducerAccount.isLowPcPerformance,
        currentPage: state.ReducerApp.currentPage,
    };
};

export function useDisabledAnimationByGraphics(name: string) {
    const { isLowPcPerformance, currentPage } = useSelector<State, IStateSelector>(stateSelector, shallowEqual);

    React.useEffect(() => {
        if (!name || hasAnimationSetting(name) || !isInGameBrowser) {
            return;
        }
        if (isLowPcPerformance && name) {
            updateAnimationStatusByName(name, true);
        }
    }, [name, currentPage?.name, isLowPcPerformance]);
}

export function useIsDisabledAnimation(name?: string, isDisabledMobileAnimation: boolean = isMobileOrTabletWindow) {
    const { categoriesAnimationStatuses, currentPage, categories } = useSelector<State, IStateSelector>(stateSelector, shallowEqual);
    const categoryName = name || categories?.[categories?.[currentPage?.name]?.parentCategory]?.name || currentPage?.name;

    return isDisabledMobileAnimation || isDisabledAnimationByName(categoryName) || !!categoriesAnimationStatuses?.[categoryName];
}
