import * as React from 'react';
import styles from '../common.scss';
import { getSectionName, ManagerData, SectionNames } from '~/core/ManagerData';
import classNames from 'classnames';

interface IDescriptionContent {
    item: IBundleEntity;
}

export const DescriptionContent = (props: IDescriptionContent) => {
    const primaryItem = props.item;

    const section = getSectionName(primaryItem.type);
    const items = ManagerData.getDataBySection(section, 'id') || {};
    const item = items[primaryItem?.identifier] || {};
    const description = primaryItem.customDescription || item?.description;

    if (!description) {
        return null;
    }

    return (
        <div className={styles.flex}>
            <div className={classNames(styles.contentBlock, styles.borderBottom, styles.contentBlockDescription)}>{description}</div>
        </div>
    );
};
