import History from '~/utils/history';
import { urlsMap } from '~/utils/menu';
import { openBundleByUrl } from '~/utils/category';
import ViewCardContainer from '~/containers/ViewCardContainer/ViewCardContainer';
import CategoryContainer from '~/containers/CategoryContainer/CategoryContainer';
import { useParams } from 'react-router-dom';
import { IRoutingStateSelector, routingStateSelector } from '~/routing/routingState';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { isBundleDisabledForUser, isCategoryDisabledForUser, isCurrentCategory } from '~/routing/helpers';
import { isBundleDisabledByPromoTimer } from '~/utils/bundles';

export default function BundlePageRouter() {
    const { id, categoryName } = useParams();
    const { currentPage, bundles, categories } = useSelector<State, IRoutingStateSelector>(routingStateSelector, equal);

    const isBundle = /^[0-9]+$/.test(id);
    if (isBundle) {
        const currentBundle = bundles[id];
        const category = categories[currentPage?.name || categoryName] as ICategory;

        if (isBundleDisabledForUser(currentBundle, category) || isBundleDisabledByPromoTimer(currentBundle)) {
            History.navigate(urlsMap.home, { replace: true });
            return;
        }

        if (!History.hasHistory()) {
            if (!isCurrentCategory(categoryName, category)) {
                openBundleByUrl(category.name, currentBundle?.id, true);
                return null;
            }
        }

        return <ViewCardContainer params={{ categoryName, id: +id }} />;
    }

    if (isCategoryDisabledForUser(categoryName, id, categories)) {
        History.navigate(urlsMap.home, { replace: true });
        return;
    }

    return <CategoryContainer params={{ categoryName, id }} />;
}
