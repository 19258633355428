import * as React from 'react';
import styles from './TradeInLayout.scss';
import classNames from 'classnames';

interface ITradeInLayoutBackground {
    image?: string;
    identifier: number;
    className?: string;
}

const TradeInLayoutBackground = ({ image, identifier, className }: ITradeInLayoutBackground) => {
    const [firstAnimationStatus, setFirstAnimationStatus] = React.useState({
        image: null,
        isStarted: false,
        isOut: false,
    });
    const [secondAnimationStatus, setSecondAnimationStatus] = React.useState({
        image: null,
        isStarted: false,
        isOut: false,
    });

    const firstImageRef = React.useRef<HTMLDivElement>();
    const secondImageRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        if (!image) {
            setFirstAnimationStatus({ image: null, isStarted: false, isOut: false });
            setSecondAnimationStatus({ image: null, isStarted: false, isOut: false });
            return;
        }
        if (!firstAnimationStatus.image) {
            setFirstAnimationStatus({ image, isStarted: true, isOut: false });
            setSecondAnimationStatus({ image: null, isStarted: false, isOut: true });
        } else {
            if (firstAnimationStatus.isOut) {
                setFirstAnimationStatus({ image, isStarted: true, isOut: false });
                setSecondAnimationStatus({ image: secondAnimationStatus.image, isStarted: false, isOut: true });
            }

            if (secondAnimationStatus.isOut) {
                setFirstAnimationStatus({ image: firstAnimationStatus.image, isStarted: false, isOut: true });
                setSecondAnimationStatus({ image, isStarted: true, isOut: false });
            }
        }
    }, [identifier]);

    return (
        <React.Fragment>
            <div
                className={classNames(
                    styles.itemImage,
                    {
                        [styles.jsStarted]: firstAnimationStatus.isStarted,
                        [styles.jsOut]: firstAnimationStatus.isOut,
                    },
                    className,
                )}
                style={{ backgroundImage: `url(${firstAnimationStatus.image})` }}
                ref={(_ref) => {
                    if (_ref) {
                        firstImageRef.current = _ref;
                    }
                }}
            />
            <div
                className={classNames(
                    styles.itemImage,
                    {
                        [styles.jsStarted]: secondAnimationStatus.isStarted,
                        [styles.jsOut]: secondAnimationStatus.isOut,
                    },
                    className,
                )}
                style={{ backgroundImage: `url(${secondAnimationStatus.image})` }}
                ref={(_ref) => {
                    if (_ref) {
                        secondImageRef.current = _ref;
                    }
                }}
            />
        </React.Fragment>
    );
};

export default TradeInLayoutBackground;
