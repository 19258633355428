import * as React from 'react';
import styles from './SearchInput.scss';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import { playInputSound } from '~/api/WoWsClient';

interface ISearchInput {
    value?: string;
    onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
    onInput?: (event?: React.FormEvent<HTMLInputElement>) => void;
    onReset?: () => void;
    className?: string;
    maxLength?: number;
}

const SearchInput = ({ value, onKeyPress, onKeyDown, onKeyUp, onChange, onReset, onInput, maxLength, className }: ISearchInput) => {
    const [val, setValue] = React.useState<string>(value);

    const _resetSearch = () => {
        setValue('');
        onReset?.();
    };

    return (
        <div className={styles.inputWrapper}>
            <div className={styles.icon} />
            <input
                type={'text'}
                value={val}
                className={classNames(styles.input, className)}
                placeholder={t('Поиск по названию')}
                onInput={(event) => {
                    onInput?.(event);
                }}
                onChange={(event) => {
                    setValue(event.target.value);
                    onChange?.(event);
                }}
                onKeyUp={(event) => {
                    onKeyUp?.(event);
                }}
                onKeyDown={(event) => {
                    if (event.key !== 'Unidentified') {
                        playInputSound();
                    }
                    onKeyDown?.(event);
                }}
                onKeyPress={(event) => {
                    onKeyPress?.(event);
                }}
                autoComplete={'off'}
                spellCheck={false}
                maxLength={maxLength || null}
            />
            {!!val?.length && (
                <div
                    className={styles.reset}
                    onClick={_resetSearch}
                    onTouchEnd={(event) => {
                        event.preventDefault();
                        _resetSearch();
                    }}
                />
            )}
        </div>
    );
};

export default SearchInput;
