import { isInGameBrowser } from '~/utils/utils';
import { items } from '@wg/wows-entities/const';

export function isEnabledPortPreviewForReward(reward: IItemCommonData): boolean {
    return isInGameBrowser && ([items.VEHICLES, items.PERMOFLAGES, items.SKIN, items.STYLE, items.MSKIN] as string[]).includes(reward.type);
}

export function rewardHasSampleVo(reward: ILootboxReward): boolean {
    return isInGameBrowser && (reward.additionalData?.hasSampleVo || reward.additionalData?.defaultCrew?.hasSampleVo);
}
