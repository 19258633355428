import * as React from 'react';
import styles from './SmallPreloader.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import classNames from 'classnames';

interface ILoading {
    tooltip?: string;
    className?: string;
}

const SmallPreloader = (props: ILoading) => {
    return <DivTooltip className={classNames(styles.loading, props.className)} tooltipBody={props.tooltip ? <DefaultTooltip text={props.tooltip} /> : null} />;
};

export default SmallPreloader;
