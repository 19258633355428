import * as React from 'react';
import styles from './Dropdown.scss';
import className from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import useClickAway from '~/hooks/useClickAway';
import { playDropdownClickSound } from '~/api/WoWsClient';

export interface IDropdownItem {
    content: string | React.ReactChild;
    name: string;
}

interface IDropdown {
    label: string | React.ReactChild;
    tooltipBody?: string | React.ReactChild;
    onChange: (item: IDropdownItem) => void;
    classNames?: {
        wrapper?: string;
        label?: string;
        activeLabel?: string;
        popup?: string;
    };
    items: IDropdownItem[];
}

const Dropdown = ({ label, classNames, tooltipBody, items, onChange }: IDropdown) => {
    const refPopup = React.useRef(null);
    const [isVisible, setVisible] = React.useState<boolean>(false);

    const classesLabel = className(
        styles.label,
        {
            [styles.active]: isVisible,
            [classNames?.activeLabel]: isVisible,
        },
        classNames?.label,
    );

    const setPopupRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            refPopup.current = _ref;
        }
    };

    useClickAway(refPopup, (event: MouseEvent) => {
        const target = event.target as HTMLDivElement;
        if (target.classList.contains(styles.label)) {
            return;
        }

        setVisible(false);
    });

    return (
        <div className={className(styles.wrapper, classNames?.wrapper)}>
            <DivTooltip
                className={classesLabel}
                tooltipBody={tooltipBody}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    playDropdownClickSound();
                    setVisible(!isVisible);
                }}
            >
                {label}
            </DivTooltip>
            {isVisible && (
                <div className={className(styles.popup, classNames.popup)} ref={setPopupRef}>
                    {items.map((item) => {
                        return (
                            <div
                                key={item.name}
                                className={styles.item}
                                onClick={() => {
                                    playDropdownClickSound();
                                    onChange(item);
                                    setVisible(false);
                                }}
                            >
                                {item.content}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
