import gql from 'graphql-tag';

const nations = gql`
    query Nation($lang: String) {
        nations(lang: $lang) {
            title
            name
            icons {
                small
                tiny
                default
                tiny
            }
        }
    }
`;

export default nations;
