import store from '~/Store';

export class PreCacheImage {
    public cacheImages: Promise<any>[] = [];

    public add(...rest: string[]) {
        rest.forEach((path) => {
            if (!path) {
                return;
            }
            this.cacheImages.push(
                new Promise((resolve) => {
                    const image = new Image();
                    image.src = path;
                    image.onload = resolve;
                }),
            );
        });
    }
}

export class PreCacheImageFromCategory {
    public add(category: string) {
        if (!category) {
            return;
        }

        const state = store.getState();
        const appState = state.ReducerApp;
        const bundles = Object.values(appState.bundles).filter((bundle: IBundle) => bundle.categories.includes(category));
        const images: string[] = [];

        bundles.forEach((bundle: IBundle) => {
            Object.values(bundle.icons).forEach((url: string) => images.push(url));

            if (Array.isArray(bundle.randomBundleChildren)) {
                bundle.randomBundleChildren.forEach((childBundle: IRandomBundleChild) => {
                    images.push(...Object.values(childBundle.icons));
                });
            }
        });

        new PreCacheImage().add(...images);
    }

    public addBackgrounds() {
        const appState = store.getState().ReducerApp;
        const categories = Object.values(appState.categories || {});
        const images: string[] = [];

        categories.forEach((category: ICategory) => {
            if (category.background) {
                images.push(category.background);
            }
        });

        new PreCacheImage().add(...images);
    }

    public addRandomBundleToCache(category: string) {
        if (!category) {
            return;
        }

        const state = store.getState();
        const appState = state.ReducerApp;
        const bundles = Object.values(appState.bundles).filter((bundle: IBundle) => bundle.categories.includes(category));
        const images: string[] = [];

        bundles.forEach((bundle: IBundle) => {
            if (!Array.isArray(bundle.randomBundleChildren)) {
                return;
            }

            Object.values(bundle.icons).forEach((url: string) => images.push(url));

            bundle.randomBundleChildren.forEach((childBundle: IRandomBundleChild) => {
                images.push(...Object.values(childBundle.icons));
            });
        });

        new PreCacheImage().add(...images);
    }
}

export default new PreCacheImage();

export function preloadImages() {
    //empty function
}

export function preloadCategoryBackgrounds() {
    new PreCacheImageFromCategory().addBackgrounds();
}
