import * as React from 'react';
import classNames from 'classnames';
import styles from './PreviewLayout.scss';
import { getTitleForPreviewRewards } from '~/Layouts/Lootboxes/utils/texts';
import DefaultTemplate from '~/Layouts/Lootboxes/Views/PreviewLayout/DefaultTemplate/DefaultTemplate';
import { PreviewSettingsTemplate } from '~/types/bundle';

interface IPreviewLayout {
    preview: ILootboxPreviewSettings;
    className?: string;
    lootbox: ILootboxStateItem;
}

const getPreviewTemplateLayout = (data: ILootboxPreviewSettings) => {
    switch (data.template) {
        case PreviewSettingsTemplate.default:
        default:
            return <DefaultTemplate data={data.data} />;
    }
};

const PreviewLayout = ({ preview, className, lootbox }: IPreviewLayout) => {
    const title = getTitleForPreviewRewards(lootbox.slots.length);

    return (
        <div className={classNames(styles.content, className)}>
            <div className={styles.header}>
                <div className={styles.title}>{title}</div>
            </div>
            <div className={styles.wrapper}>{getPreviewTemplateLayout(preview)}</div>
        </div>
    );
};

export default PreviewLayout;
