import { Actions } from '~/Actions';
import { AUCTION_STATUS } from '~/const';
import {
    ADD_LOT_TO_TRANSACTION,
    CHANGE_LOT_BACKGROUND,
    FINISH_AUCTION,
    PLACED_BID,
    REMOVE_LOT_FROM_TRANSACTION,
    SET_BID_LOT,
    START_AUCTION,
    UPDATE_AUCTION_ACCOUNT_STATE,
    UPDATE_AUCTION_STATE,
    ZOOM_LOT_BACKGROUND,
} from '~/Actions/ActionAuction';
import { finishedStatuses } from '~/utils/auction';

export interface AuctionState {
    activeAuctions: IAuction[];
    account: IAuctionAccountState;
    activeBackgroundLotUrl4K?: string;
    activeBackgroundLotUrl?: string;
    activeBackgroundColorLot?: string;
    activeBids: {
        [key: string]: number;
    };
    minimalWonBids: IAuctionMinimalWonBids[];
    zoomLotBackground: boolean;
}

export const initialAuctionState: AuctionState = {
    activeAuctions: [],
    activeBackgroundLotUrl4K: null,
    activeBackgroundLotUrl: null,
    activeBackgroundColorLot: null,
    account: {
        placedBids: [],
        activeBids: [],
        transactions: [],
        completedActions: [],
        uniqueLotsInInventory: [],
        isStaff: false,
    },
    activeBids: {},
    minimalWonBids: [],
    zoomLotBackground: false,
};

const ReducerAuction = (state: AuctionState = initialAuctionState, action: Actions) => {
    switch (action.type) {
        case UPDATE_AUCTION_STATE: {
            return {
                ...state,
                activeAuctions: action.activeAuctions,
                account: action.account
                    ? {
                          ...action.account,
                          transactions: state.account?.transactions || [],
                      }
                    : action.account,
                minimalWonBids: action.minimalWonBids,
            };
        }

        case UPDATE_AUCTION_ACCOUNT_STATE:
            return {
                ...state,
                account: action.account
                    ? {
                          ...action.account,
                          transactions: state.account?.transactions || [],
                      }
                    : action.account,
            };

        case START_AUCTION:
            const auctions = state.activeAuctions.map((auction) => {
                if (auction.id === action.auctionId) {
                    auction.isNeedToShowPromoPage = false;
                }
                return auction;
            });

            return {
                ...state,
                activeAuctions: auctions,
            };

        case ADD_LOT_TO_TRANSACTION: {
            const transactions: string[] = [...(state.account?.transactions || [])];
            if (Array.isArray(transactions) && !transactions.includes(action.lotId)) {
                transactions.push(action.lotId);
            }

            return {
                ...state,
                account: {
                    ...state.account,
                    transactions,
                },
            };
        }

        case REMOVE_LOT_FROM_TRANSACTION:
            return {
                ...state,
                account: {
                    ...state.account,
                    transactions: [...(state.account?.transactions || [])].filter((id) => id !== action.lotId),
                },
            };

        case PLACED_BID:
            return {
                ...state,
            };

        case CHANGE_LOT_BACKGROUND:
            return {
                ...state,
                activeBackgroundLotUrl4K: action.backgroundUrl4K,
                activeBackgroundLotUrl: action.backgroundUrl,
                activeBackgroundColorLot: action.backgroundColor,
            };

        case ZOOM_LOT_BACKGROUND:
            return {
                ...state,
                zoomLotBackground: action.zoomLotBackground,
            };

        case SET_BID_LOT:
            const activeBids = state.activeBids;
            if (action.bid) {
                activeBids[action.lotId] = action.bid;
            } else {
                delete activeBids[action.lotId];
            }

            return {
                ...state,
                activeBids,
            };

        case FINISH_AUCTION:
            const activeAuctions = state.activeAuctions.map((auction) => {
                if (auction.id === action.auctionId && !finishedStatuses.includes(auction.status)) {
                    auction.status = AUCTION_STATUS.CHOOSING_WINNERS as AUCTION_STATUSES;
                }
                return auction;
            });

            return {
                ...state,
                activeAuctions: activeAuctions,
            };

        default:
            return { ...state };
    }
};

export default ReducerAuction;
