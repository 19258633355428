import * as React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { updateSoundStatus } from '~/Actions/ActionApp';
import { IUpdateSoundStatus } from '~/Actions/ActionAppType';
import styles from '~/components/TopPanel/TopPanel.scss';
import Volume from '~/components/Volume/Volume';
import { changeVolumeSoundByKey, getSoundKeyForCurrentPage, isEnabledSounds } from '~/utils/sounds/settings';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';

interface ISoundControl {
    category: ICategory;
    bundle: IBundle;
    isStartedVideo: boolean;
    isTrusted: boolean;
    className?: string;
}

const SoundControl = ({ category, bundle, isStartedVideo, isTrusted, className }: ISoundControl) => {
    const dispatch = useDispatch();
    const soundKey = getSoundKeyForCurrentPage(category, bundle);
    const isSoundEnableByState = !soundKey || (!isInGameBrowser && !isTrusted) ? false : true;
    const isSoundEnableBySoundKey = isEnabledSounds(soundKey);

    if (isMobileOrTabletWindow || !soundKey) {
        return null;
    }

    return (
        <div className={classNames(styles.iconWrapper, { [styles.disabled]: isStartedVideo })}>
            <Volume
                key={`volume_${soundKey}_${isTrusted}`}
                onChange={(value) => {
                    changeVolumeSoundByKey(soundKey, value);
                    dispatch<IUpdateSoundStatus>(updateSoundStatus(soundKey, value));
                }}
                defaultValue={!isSoundEnableByState ? false : isSoundEnableBySoundKey}
                className={className}
                disableClick={!isSoundEnableByState && isSoundEnableBySoundKey}
            />
        </div>
    );
};

export default SoundControl;
