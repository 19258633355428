export const SAVE_TREASURE_BOXES = 'SAVE_TREASURE_BOXES';
export interface ISaveTreasureBoxes {
    type: typeof SAVE_TREASURE_BOXES;
    masterBoxes: Array<TreasureMasterBox>;
}

export const RESET_TREASURE_CURRENT_BOX_STATE = 'RESET_TREASURE_CURRENT_BOX_STATE';
export interface IResetTreasureCurrentBoxState {
    type: typeof RESET_TREASURE_CURRENT_BOX_STATE;
    currentBox: TreasureMasterBox;
}

export const SAVE_TREASURE_ACCOUNT_DATA = 'SAVE_TREASURE_ACCOUNT_DATA';
export interface ISaveTreasureAccountData {
    type: typeof SAVE_TREASURE_ACCOUNT_DATA;
    account: TreasureAccountData;
}

export const TREASURE_SHOW_ANIMATION = 'TREASURE_SHOW_ANIMATION';
export interface IShowTreasureAnimation {
    type: typeof TREASURE_SHOW_ANIMATION;
    show: boolean | null;
    data?: TreasureAnimationData;
}

export const UPDATE_TREASURE_ACCOUNT = 'UPDATE_TREASURE_ACCOUNT';
export interface IUpdateTreasureAccountData {
    type: typeof UPDATE_TREASURE_ACCOUNT;
    account: TreasureAccountData;
}

export const DO_PURCHASE = 'TREASURE_DO_PURCHASE';
export interface ITreasureDoPurchase {
    type: typeof DO_PURCHASE;
    pendingTransaction?: TreasureTransaction;
}

export type TreasureAction = ISaveTreasureBoxes | ISaveTreasureAccountData | ITreasureDoPurchase | IResetTreasureCurrentBoxState | IUpdateTreasureAccountData | IShowTreasureAnimation;
