import * as React from 'react';
import { TooltipTypes } from '~/types/common';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip, { IDefaultTooltipClasses } from '~/components/Tooltip/DefaultTooltip';

import styles from './Info.scss';
import classNames from 'classnames';

//* If u want to use svg in scss use icon_info_new.svg

interface IInfo {
    style?: React.CSSProperties;
    color?: string;
    className?: string;
    svgClassName?: string;
    tooltipClasses?: IDefaultTooltipClasses;
    onClick?: () => void;
    tooltip?: {
        title?: string;
        text?: string | (string | React.ReactChild)[];
        type?: TooltipTypes;
    };
}

const Info = (props: IInfo): React.ReactChild | any => {
    let tooltip = null;
    if (props.tooltip) {
        tooltip = <DefaultTooltip text={props.tooltip.text} title={props.tooltip.title} type={props.tooltip.type} classes={props.tooltipClasses} />;
    }

    const style = { '--timer-color': props.color || 'rgba(158, 213, 231, .85)' } as React.CSSProperties;

    return (
        <DivTooltip tooltipBody={tooltip} className={classNames(styles.wrapper, props.className)} onClick={() => props.onClick?.()}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                className={classNames(styles.info, props.svgClassName)}
                style={Object.assign(props.style || {}, style)}
            >
                <path d="M10 6V7H9V6H10Z" stroke="black" strokeOpacity="0.2" />
                <path d="M10 8V13H9V8H10Z" stroke="black" strokeOpacity="0.2" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 9.5C16 13.0899 13.0899 16 9.5 16C5.91015 16 3 13.0899 3 9.5C3 5.91015 5.91015 3 9.5 3C13.0899 3 16 5.91015 16 9.5ZM15 9.5C15 12.5376 12.5376 15 9.5 15C6.46243 15 4 12.5376 4 9.5C4 6.46243 6.46243 4 9.5 4C12.5376 4 15 6.46243 15 9.5Z"
                    stroke="black"
                    strokeOpacity="0.2"
                />
                <path d="M10 6V7H9V6H10Z" fill="white" fillOpacity="0.75" />
                <path d="M10 8V13H9V8H10Z" fill="white" fillOpacity="0.75" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 9.5C16 13.0899 13.0899 16 9.5 16C5.91015 16 3 13.0899 3 9.5C3 5.91015 5.91015 3 9.5 3C13.0899 3 16 5.91015 16 9.5ZM15 9.5C15 12.5376 12.5376 15 9.5 15C6.46243 15 4 12.5376 4 9.5C4 6.46243 6.46243 4 9.5 4C12.5376 4 15 6.46243 15 9.5Z"
                    fill="white"
                    fillOpacity="0.75"
                />
            </svg>
        </DivTooltip>
    );
};

export default Info;
