import gql from 'graphql-tag';

export const lootbox = gql`
    query Lootbox($id: String, $lang: String) {
        lootbox(lootboxId: $id, lang: $lang) {
            id
            shortTitle
            isPremium
        }
    }
`;
