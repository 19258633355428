import React from 'react';

import { getItemById, IPortContainItem } from '../../settings';
import { getShipIdForStylePreview } from '~/settings/port';
import { settings } from '~/utils/settings';
import { interpolate, t } from '~/utils/localization';
import { getCamoData } from '~/utils/getCamoData';
import { TooltipTypes } from '~/types/common';
import { CamoData } from '~/types/entities';
import { ItemTypes } from '@wg/wows-entities/const';

import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';

import styles from './PortCamoBlock.scss';
import Info from '~/components/Info/Info';

interface PortCamoBlockProps {
    shipId: string | number;
    exteriorId: string | number;
    items: Array<IPortContainItem>;
}

function getTooltipForSingleExterior(camoData: CamoData | null) {
    if (!camoData) return null;

    const text = interpolate(t('Камуфляж {name} начисляется без корабля'), {
        name: camoData.titleShort || camoData.title,
    });

    return <DefaultTooltip text={text} type={TooltipTypes.WARNING} classes={{ body: styles.tooltipBody }} />;
}

export function PortCamoBlock({ shipId, exteriorId, items }: PortCamoBlockProps) {
    const [camoData, setCamoData] = React.useState<CamoData | null>(null);

    React.useEffect(() => {
        getCamoData(exteriorId).then(setCamoData);
    }, [exteriorId]);

    if (Number(exteriorId) < 0) {
        return null;
    }

    const titleText = camoData?.type?.title
        ? interpolate(t('{type} «{name}»'), {
              type: camoData.type.title,
              name: camoData.titleShort || camoData.title,
          })
        : interpolate(t('Камуфляж «{name}»'), {
              name: camoData?.titleShort,
          });

    const itemCamoData = getItemById(Number(exteriorId), items);
    const isExteriorForShipInBundleFlag = items?.some((item) => Number(item.itemId) === Number(shipId));
    const isPermanentCamoFlag = isPermanentCamouflage(camoData?.type?.name);

    const tooltip: React.ReactNode = isExteriorForShipInBundleFlag ? null : getTooltipForSingleExterior(camoData);

    let finalShipId = shipId || itemCamoData?.customisation?.shipId;

    if (isPermanentCamoFlag && !finalShipId) {
        finalShipId = getShipIdForStylePreview(Number(exteriorId));
    } else if (!finalShipId) {
        finalShipId = settings.camouflagePreviewDefaultShip;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{titleText}</div>
            {!isExteriorForShipInBundleFlag ? (
                <div className={styles.presentationInfo}>
                    <Info color="#fff" tooltip={{ title: tooltip as unknown as string }} className={styles.presentationInfo__icon} />
                    <div className={styles.presentationInfo__title}>{t('Камуфляж презентован на:')}</div>
                    <WoWSEntity key={`exterior_${exteriorId}_${finalShipId}`} type={ItemTypes.VEHICLES} id={finalShipId} className={styles.entity} />
                </div>
            ) : null}
        </div>
    );
}
