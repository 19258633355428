import store from '~/Store';
import { getTransactionStatus, getTreasureAccountData, treasurePurchase, UNSUCCESSFUL_TRANSACTION } from '~/api/treasure';
import { updateAccountOnTreasurePurchase, saveTreasureAccountData, showTreasureAnimation } from '~/Actions/ActionTreasure';
import { changeVisiblePopup, setEmotionFromBoxSource } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import Account from '~/account/Account';
import { isNeedToShowDownloadGamePopup, showDownloadGamePopup } from '~/components/Popups/settings';
import { isIngame } from '@wg/web2clientapi/utils/checkIngame';
import { isOnlyShipBundle } from '~/utils/bundles';
import { items as ITEMS } from '@wg/wows-entities/const';
import { isTransactionInProcess } from '~/utils/treasure';
import { logError } from '~/utils/logging';
import { EMOTION_SOURCES } from '~/api/WoWsClient';
import { syncAccountInfo } from '~/sync';

const WAIT_MS = 500;
const MAX_ATTEMPTS = 60;

export enum TransactionStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
    PAYMENT = 'payment',
}

export default class TreasureProcessor {
    isPurchaseAvailable(): boolean {
        if (!Account.getAccount()) {
            return false;
        }

        if (isNeedToShowDownloadGamePopup()) {
            showDownloadGamePopup();
            return false;
        }

        return true;
    }

    isBoxEmotionTriggered(rewards: TreasureLoadedReward[], inventory: InventoryState): boolean {
        const shipRewards = rewards.filter((reward) => isOnlyShipBundle(reward));
        if (!shipRewards.length) return false;
        return shipRewards.some((reward) => {
            const [isExistsItemInInventory] = Account.getInventoryInfoByType(ITEMS.VEHICLES, reward.entitlements[0]?.identifier, undefined, undefined, undefined, inventory);
            return !isExistsItemInInventory;
        });
    }

    async pollRequest(id: string | number): Promise<unknown> {
        let attempts = 0;
        return new Promise((resolve, reject) => {
            const interval = setInterval(async () => {
                const transaction = await this.getTransaction(id);

                if (transaction.status === TransactionStatus.SUCCESS) {
                    clearInterval(interval);
                    resolve({ ok: true, transaction });
                }

                if (transaction.status === TransactionStatus.FAILED) {
                    clearInterval(interval);
                    resolve({ ok: false, error: UNSUCCESSFUL_TRANSACTION });
                }

                attempts++;

                if (attempts > MAX_ATTEMPTS) {
                    clearInterval(interval);
                    resolve({
                        ok: false,
                        error: 'Timeout',
                    });
                }
            }, WAIT_MS);
        });
    }

    async getTransaction(id: number | string): Promise<TreasureTransaction> {
        return (await getTransactionStatus(id)) as TreasureTransaction;
    }

    markTransactionAsFailed() {
        store.getState().ReducerTreasure.activeTransaction && store.dispatch(updateAccountOnTreasurePurchase(undefined));
        store.dispatch(changeVisiblePopup(POPUPS_NAME.ERROR_PURCHASE, true));
    }

    isGuaranteeTriggered(oldRotation: TreasureRotation, newRotation: TreasureRotation): [boolean, string | undefined] {
        const triggered = Object.keys(newRotation).filter((rotation) => newRotation[rotation] >= oldRotation[rotation]);
        return [!!triggered?.length, triggered?.[0]];
    }

    getChainToShowAnimation(oldRotations: TreasureRotations, newRotations: TreasureRotations): void {
        const box = store.getState().ReducerTreasure.currentBox.name;
        const oldRotation = oldRotations[box];
        const newRotation = newRotations[box];

        const [triggered, triggeredGuarantee] = this.isGuaranteeTriggered(oldRotation, newRotation);
        if (triggered) {
            store.dispatch(showTreasureAnimation(true, { from: oldRotation[triggeredGuarantee], to: 0, targetChain: triggeredGuarantee }));
            return;
        }

        const nearestGuaranteeValue = Math.min(...Object.values(newRotation));
        const nearestGuarantee = Object.keys(newRotation).find((rotation) => newRotation[rotation] === nearestGuaranteeValue);
        store.dispatch(
            showTreasureAnimation(true, {
                from: oldRotation[nearestGuarantee],
                to: nearestGuaranteeValue,
                targetChain: nearestGuarantee,
            }),
        );
    }

    async updateAccount(): Promise<void> {
        await syncAccountInfo();
        const accountData = await getTreasureAccountData();
        this.getChainToShowAnimation(store.getState().ReducerTreasure.account.rotations, accountData.rotations);
        accountData && store.dispatch(saveTreasureAccountData(accountData));
    }

    async processRewards(transaction: TreasureTransaction, inventory: InventoryState) {
        if (isIngame() && this.isBoxEmotionTriggered(transaction.rewards, inventory)) {
            this.updateAccount();
            return;
        }

        await this.updateAccount();
        setTimeout(() => {
            store.dispatch(changeVisiblePopup(POPUPS_NAME.TREASURE_SUCCESS_PURCHASE, true, transaction));
        }, 750);
    }

    async initUserWithPendingTransaction(transactionId: string | number): Promise<void> {
        const transaction = await this.processPendingTransaction(transactionId);
        if (!transaction) return;

        store.dispatch(updateAccountOnTreasurePurchase(transaction));
        store.dispatch(setEmotionFromBoxSource(EMOTION_SOURCES.TREASURE_CHAIN));
        const inventoryCopy = store.getState().ReducerAccount.inventory;
        await this.processRewards(transaction, inventoryCopy as InventoryState);
    }

    async processPendingTransaction(transactionId: string | number): Promise<TreasureTransaction> {
        const { ok, error, transaction } = (await this.pollRequest(transactionId)) as TreasureRequestTransaction;

        if (!ok) {
            logError(error);
            this.markTransactionAsFailed();
            return;
        }

        return transaction;
    }

    async buy(data: TreasurePurchaseRequest): Promise<void> {
        if (!this.isPurchaseAvailable()) {
            return;
        }

        const inventoryCopy = store.getState().ReducerAccount.inventory;
        const response = await treasurePurchase(data);
        if (!response || response?.transaction?.status === TransactionStatus.FAILED) {
            this.markTransactionAsFailed();
            return;
        }

        let initedTransaction = response.transaction;
        if (isTransactionInProcess(initedTransaction)) {
            const transaction = await this.processPendingTransaction(initedTransaction.id);
            if (!!transaction) {
                store.dispatch(setEmotionFromBoxSource(EMOTION_SOURCES.TREASURE_CHAIN));
                initedTransaction = transaction;
                store.dispatch(updateAccountOnTreasurePurchase(initedTransaction));
            } else {
                return;
            }
        }
        await this.processRewards(initedTransaction, inventoryCopy as InventoryState);
    }
}
