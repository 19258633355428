import * as React from 'react';
import styles from './BundleLimitTooltip.scss';
import { IBundleLimitInfo } from '~/components/BundleLimitWidget/BundleLimitWidget';
import { interpolate, ngettext, t } from '~/utils/localization';
import classNames from 'classnames';
import Timer from '~/components/Timer/Timer';
import { getExpireTime, getLimitPerType, getTotalLimit, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';

interface IBundleLimitTooltip {
    total: number;
    bundlesInfo: IBundleLimitInfo[];
}

const title = t('Ограничения на приобретение') || t('Ограничения');

export const BUNDLE_LIMIT_ERROR_TEXTS = {
    [REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT]: t('На сегодня вы приобрели все доступные контейнеры'),
    [REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_PER_TYPE_LIMIT]: t('На сегодня вы приобрели все доступные контейнеры "{name}"'),
    [REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_PER_LIMIT_AFTER_PURCHASE]: t('Покупка этого набора превысит ваш дневной лимит на приобретение контейнеров "{name}".'),
    [REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE]: t('Покупка этого набора превысит ваш дневной лимит на приобретение контейнеров.'),
};

export const newBundleLimitText = t('Покупка этого набора превысит ваш дневной лимит на приобретение контейнеров одного наименования.');

export const getTooltipDescriptionForBundleRestriction = () => {
    const perLimit = getLimitPerType();
    const totalLimit = getTotalLimit();

    const descriptionPerTypeText = interpolate(
        ngettext('Ежедневно вы можете приобрести не более {amount} одинакового контейнера.', 'Ежедневно вы можете приобрести не более {amount} одинаковых контейнеров.', perLimit),
        {
            amount: perLimit,
        },
    );

    const descriptionTotalText = interpolate(ngettext('Всего доступно до {amount} контейнера в день.', 'Всего доступно до {amount} контейнеров в день.', totalLimit), {
        amount: totalLimit,
    });

    return `${descriptionPerTypeText}&nbsp;${descriptionTotalText}`;
};

const BundleLimitTooltip = ({ total, bundlesInfo }: IBundleLimitTooltip) => {
    const expireTime = getExpireTime();
    return (
        <React.Fragment>
            <div className={styles.header}>{t('Ограничения на приобретение контейнеров')}</div>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: getTooltipDescriptionForBundleRestriction() }} />
            {total > 0 && (
                <div className={styles.contentTable}>
                    <div className={styles.contentTableItem}>
                        <div className={classNames(styles.contentTableItemTitle, styles.bold)}>{t('Контейнеров приобретено сегодня')}</div>
                    </div>
                    {bundlesInfo.map((info, index) => {
                        return (
                            <div className={styles.contentTableItem} key={`bundle_limit_info_${index}`}>
                                <div className={styles.contentTableItemTitle}>{info.title}</div>
                                <div className={styles.contentTableItemValue}>{info.amount}</div>
                            </div>
                        );
                    })}
                    <div className={styles.contentTableItem}>
                        <div className={styles.contentTableItemTitle}>{t('Всего:')}</div>
                        <div className={styles.contentTableItemValue}>{total}</div>
                    </div>
                </div>
            )}
            <div className={classNames(styles.contentTable, styles.timerContent)}>
                <div className={styles.contentTableItem}>
                    <div className={classNames(styles.contentTableItemTitle, styles.bold)}>{t('Обновление лимита:')}</div>
                    <div className={styles.contentTableItemValue}>
                        <Timer time={expireTime} withoutIcon={true} className={styles.timerWrapper} timerClassName={styles.timer} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BundleLimitTooltip;
