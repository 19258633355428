import * as React from 'react';
import styles from './Tooltip.scss';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Price from '~/components/Price/Price';
import { arrayToObjectByKey } from '~/utils/utils';
import { NotEnoughCurrencyType } from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import classNames from 'classnames';

interface IShortageCurrenciesTooltip {
    currencies: NotEnoughCurrencyType[];
}

interface IStateSelector {
    currencies: ICurrencies[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currencies: state.ReducerApp.currencies,
    };
};

const ShortageCurrenciesTooltip = ({ currencies }: IShortageCurrenciesTooltip): React.ReactChild | any => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const currenciesMap = arrayToObjectByKey(state.currencies, 'name');
    const shortageCurrenciesMap = arrayToObjectByKey(currencies, 'currency');

    return (
        <div className={styles.currenciesTooltip}>
            {currencies.map((item: NotEnoughCurrencyType) => {
                return (
                    <div className={styles.bundleCurrencyItem} key={item.currency}>
                        <div className={styles.currencyItemTitle}>{currenciesMap[item.currency].title}</div>
                        <div className={styles.currencyItemDotted}></div>
                        <div className={styles.currencyItemWidget}>
                            <Price
                                amount={shortageCurrenciesMap[item.currency].amount}
                                currency={item.currency}
                                className={classNames(styles.currencyItemPrice, styles.shortageColor)}
                                withoutAnimation
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ShortageCurrenciesTooltip;
