import { getShipData } from './getShipData';

export async function getShipFeatures(shipId?: number | string): Promise<IFeatureTagsItem | undefined> {
    const shipData = await getShipData(shipId);

    if (!shipData) return;

    return {
        id: Number(shipData.id),
        complexity: shipData.complexity,
        featuresTags: shipData.featuresTags,
        featuresTagsPerCategory: shipData.featuresTagsPerCategory,
    };
}
