import * as React from 'react';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

import store from '~/Store';
import { State } from '~/Reducers';
import { changeVisiblePort } from '~/Actions/ActionApp';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { closePort as closeClientPort, playButtonClickSound } from '~/api/WoWsClient';
import dwhExport from '~/api/dwhExport';
import Account from '~/account/Account';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { isDevWoWSClient } from '~/utils/devMode';
import History from '~/utils/history';
import { openBundleById, openBundleByUrl, openCategoryByName } from '~/utils/category';
import { getParentRandomBundleByChild, isFreeBundle } from '~/utils/bundles';
import { getLotByDisplayId } from '~/utils/auction';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { DWH_EVENTS } from '~/const';

import { PortDataType, PortType } from '~/components/Port/settings';
import Port from '~/components/Port/Port';

import commonStyles from '../../assets/styles/common.scss';

export function changeBackground(isChange: boolean) {
    if (isDevWoWSClient()) {
        return;
    }

    if (isChange) {
        document.body.classList.add(commonStyles.transparent);
    } else {
        document.body.classList.remove(commonStyles.transparent);
    }
}

interface IStateSelector {
    port: IPort;

    currentPage: ICurrentPage;
    auctions: IAuction[];
    mrpsEventData?: MrpsEvent;

    bundles: IBundleList;
    deniedBundlesByUniqueItems: number[];
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    selectedRandomBundles?: AccountSelectedRandomBundles;
    lootboxes: ILootboxes;

    inventory: InventoryState;
    balance: IBalance;
    coupons: ICoupon[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        port: state.ReducerApp.port,

        currentPage: state.ReducerApp.currentPage,
        auctions: state.ReducerAuction.activeAuctions,
        mrpsEventData: state.reducerMrps.events.find((event) => event.name === state.reducerMrps.currentEvent.eventName),

        bundles: state.ReducerApp.bundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        lootboxes: state.ReducerLootbox.lootboxes,

        inventory: state.ReducerAccount.inventory,
        balance: state.ReducerAccount.balance,
        coupons: state.ReducerAccount.coupons,
    };
};

const PortContainer = (): any => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    const dataType = state.port.dataType;

    function onClosePort(withoutHistoryUpdate = false) {
        changeBackground(false);
        closeClientPort();
        store.dispatch(
            changeVisiblePort({
                isVisible: false,
                dataType: PortDataType.DEFAULT,
                portType: PortType.DEFAULT,
            }),
        );

        if (!withoutHistoryUpdate) {
            if (!History.hasHistory()) {
                return History.backHome();
            }
            History.back();
        }
    }

    useKeyDown((keyCode) => {
        if (KEYS_CODE.ESC === keyCode) {
            const app = store.getState().ReducerApp;
            if (!!app.popupActive) {
                return;
            }
            playButtonClickSound();
            onClosePort();
        }
    }, []);

    React.useEffect(() => {
        updateBrowserControlState(true);

        changeBackground(true);
    }, []);

    React.useEffect(() => {
        const startTime = Date.now();

        return () => {
            const endTime = Date.now();
            const duration = Math.floor((endTime - startTime) / 1000);
            dwhExport.send(DWH_EVENTS.CLOSE_PORT, { duration });
        };
    }, []);

    if (!state.port.shipId || !state.port.exteriorId || !state.port.itemType) {
        onClosePort();
    }

    const defaultProps = {
        shipId: state.port.shipId,
        exteriorId: state.port.exteriorId,
        itemType: state.port.itemType,

        inventory: state.inventory,

        onClose: onClosePort,
    };

    switch (dataType) {
        case PortDataType.BUNDLE: {
            const bundleId = Number(state.port.bundleId);

            //* Bundle for data
            let bundle = state.bundles[bundleId];
            //* Bundle for purchase
            let currentBundle = bundle;

            if (bundleId) {
                //* If bundle is parent random bundle
                if (bundle && bundle.isRandom) {
                    bundle = Account.getRandomBundleChild(state.selectedRandomBundles, bundle);
                } else if (!bundle) {
                    const parentBundleId = getParentRandomBundleByChild(state.bundles, bundleId);

                    currentBundle = state.bundles[parentBundleId];
                    bundle = Object.assign(
                        {},
                        currentBundle,
                        currentBundle.randomBundleChildren.find((bundleData) => bundleData.id === bundleId),
                    );
                }
            }

            const isRandomBundleFlag = bundle.isRandom;
            let isDisabledPurchase = Account.isDisabledForPurchase(state.purchasedLimitedBundles, state.deniedBundlesByUniqueItems, bundle);

            if (!isDisabledPurchase && isRandomBundleFlag) {
                isDisabledPurchase = state.selectedRandomBundles[currentBundle.id] !== bundle.id;
            }

            const portType = state.port.portType;

            function purchaseHandler() {
                const parentBudnle = isRandomBundleFlag ? currentBundle : null;

                function callback() {
                    onClosePort(true);

                    if (!bundle.serialPurchase || !bundle.nextBundle) {
                        if (isRandomBundleFlag && parentBudnle?.id) {
                            openBundleById(parentBudnle?.id, true);
                        } else {
                            openCategoryByName(state.currentPage?.name, null, true);
                        }
                    } else {
                        const nextBundle = state.bundles[bundle.nextBundle];
                        if (!nextBundle) {
                            openCategoryByName(state.currentPage?.name, null, true);
                        } else {
                            openBundleByUrl(state.currentPage?.name, nextBundle.id, true);
                        }
                    }
                }

                const purchaseProcessor = new PurchaseProcessor(currentBundle);
                purchaseProcessor.purchaseBundleFromPopup(bundle, callback, null, isFreeBundle(bundle));
            }

            return (
                <Port
                    {...defaultProps}
                    dataType={dataType}
                    portType={portType}
                    bundle={bundle}
                    balance={state.balance}
                    inventory={state.inventory}
                    coupons={state.coupons}
                    isDisabledPurchase={isDisabledPurchase}
                    purchaseHandler={purchaseHandler}
                />
            );
        }

        case PortDataType.AUCTION_LOT: {
            const lotId = state.port.lotId;
            const lot = getLotByDisplayId(lotId.toString(), state.auctions);

            const portType = state.port.portType;

            return <Port {...defaultProps} dataType={dataType} portType={portType} lot={lot} />;
        }

        case PortDataType.DEFAULT:
        default:
            return <Port {...defaultProps} dataType={PortDataType.DEFAULT} portType={PortType.DEFAULT} />;
    }
};

export default PortContainer;
