import { logError } from '~/utils/logging';
import { LocalStorageDataByKeys, LocalStorageKeysValuesAsType } from '~/types/storage';

class LocalStorage {
    set<N extends LocalStorageKeysValuesAsType | string, V extends LocalStorageDataByKeys[N]>(name: N | string, value: V, expiry: string | null = null) {
        const data = { value, expiry };
        try {
            localStorage.setItem(name, JSON.stringify(data));
        } catch (e) {
            logError('localStorage error', e);
        }
    }

    get<N extends LocalStorageKeysValuesAsType | string>(name: N): LocalStorageDataByKeys[N] {
        const data = localStorage.getItem(name);
        if (!data) {
            return null;
        }

        const json = _parseJsonSafe(data) || null;
        if (!json) {
            return null;
        }

        const now = Date.now();

        if (json.expiry) {
            const expiry = /^[0-9]+$/.test(json.expiry) ? +json.expiry : json.expiry;
            const expiryTime = new Date(expiry).getTime();
            if (now > expiryTime) {
                this.remove(name);
                return null;
            }
        }

        return json.value;
    }

    remove(name: string) {
        localStorage.removeItem(name);
    }
}

export class SessionStorage {
    set(name: string, value: any) {
        sessionStorage.setItem(name, JSON.stringify(value));
    }

    get(name: string) {
        const val = sessionStorage.getItem(name);
        return _parseJsonSafe(val) || null;
    }

    remove(name: string) {
        sessionStorage.removeItem(name);
    }
}

function _parseJsonSafe(data: string): any | null {
    let result = null;

    try {
        result = data && JSON.parse(data);
    } catch (e) {
        console.error(e);
    }

    return result;
}

export const LocalStorageHelper = new LocalStorage();
export const SessionStorageHelper = new SessionStorage();
