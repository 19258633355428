import * as React from 'react';
import { changeViewBackground } from '~/Actions/ActionApp';
import { useDispatch } from 'react-redux';
import { DependencyList } from 'react';
import { getCategoryBackground, getGlobalBackground } from '~/utils/background';

const useBackground = (backgroundUrl: string, deps: DependencyList = []) => {
    const dispatch = useDispatch();
    const globalBackground = getGlobalBackground();
    const back = backgroundUrl || globalBackground;

    React.useEffect(() => {
        dispatch(changeViewBackground(null, back || getCategoryBackground(deps[0] as string)));

        return () => {
            backgroundUrl && dispatch(changeViewBackground(null, null));
        };
    }, deps);
};

export default useBackground;
