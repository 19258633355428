import * as React from 'react';
import { ClientPaymentMethod } from '@wg/wows-commerce/api/platform/web';
import BankCard from '~/components/WSMart/PurchaseBlock/PurchaseMethods/BankCard';
import PaymentMethod from '~/components/WSMart/PurchaseBlock/PurchaseMethods/PaymentMethod';

interface IPurchaseMethod {
    paymentMethod: ClientPaymentMethod;
}

const PurchaseMethod: React.FC<IPurchaseMethod> = ({ paymentMethod }) => {
    const bindingsInfo = paymentMethod?.bindingsInfo[0];

    if (bindingsInfo?.source === 'bank_card') {
        return <BankCard type={bindingsInfo.card_type} expiration={bindingsInfo.expiration_date} number={bindingsInfo.card_number} icon={bindingsInfo.image_url} />;
    }

    return <PaymentMethod icon={bindingsInfo?.image_url} description={paymentMethod?.description} />;
};

export default PurchaseMethod;
