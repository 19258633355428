import * as React from 'react';
import styles from './GroupingPage.scss';
import { openBundleByUrl } from '~/utils/category';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import PurchaseButton from '~/components/Button/PurchaseButton';
import { groupBundlesByName } from '~/utils/bundles';
import BundleAmount from '~/components/BundleAmount/BundleAmount';
import { DivTooltip } from '@wg/wows-react-uikit';
import { ItemsContainsTooltip } from '~/components/Tooltip/Tooltips';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { State } from '~/Reducers';
import { shallowEqual, useSelector } from 'react-redux';

interface IPromoBundleByGroup {
    groupName: string;
    groups: Record<string, IBundle[]>;
}

interface IStateSelector {
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
    };
};

export const PromoBundleByGroup = (props: IPromoBundleByGroup) => {
    const state = useSelector<State, IStateSelector>(stateSelector, shallowEqual);
    const currentGroupsBundles: IBundle[] = props.groups[props.groupName];
    const currentBundle: IBundle = currentGroupsBundles[0];

    const [selectedBundle, updateSelectedBundle] = React.useState(currentBundle);

    if (!selectedBundle) {
        return null;
    }

    const goToBundle = () => openBundleByUrl(state.currentPage?.name, selectedBundle.id);

    return (
        <div className={styles.bundle} onClick={goToBundle}>
            <div className={styles.image}>
                <img src={selectedBundle.icons.medium} className={styles.image} />
            </div>
            <div className={styles.info}>
                <div className={styles.title}>{selectedBundle.title}</div>
                <DivTooltip tooltipBody={<ItemsContainsTooltip bundle={selectedBundle} />}>
                    <div className={styles.contains} />
                </DivTooltip>
            </div>
            <div className={styles.priceWrapper}>
                <PriceWrapper bundle={selectedBundle} />
            </div>
            {currentGroupsBundles.length > 1 && (
                <div className={styles.amount}>
                    <BundleAmount bundles={currentGroupsBundles} selectedBundleId={selectedBundle.id} onChange={(bundle) => updateSelectedBundle(bundle)} />
                </div>
            )}
            <div className={styles.purchaseWrapper}>
                <div className={styles.purchase}>
                    <PurchaseButton bundle={selectedBundle} buttonClassName={styles.button} />
                </div>
            </div>
        </div>
    );
};

export interface IPromoPage {
    bundles: number[];
}

class GroupingPage extends React.Component<IPromoPage> {
    renderHeader(): JSX.Element {
        return null;
    }

    render() {
        const groups = groupBundlesByName(this.props.bundles);

        return (
            <div className={styles.wrapper}>
                {this.renderHeader()}
                <div className={styles.content}>
                    {Object.keys(groups).map((group: string) => {
                        return <PromoBundleByGroup groupName={group} groups={groups} key={group} />;
                    })}
                </div>
            </div>
        );
    }
}

export default GroupingPage;
