import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { clearState } from '~/Actions/ActionTradeIn';
import TradeInLayout from '~/Layouts/TradeIn/Layouts/TradeInLayout/TradeInLayout';
import { State } from '~/Reducers';
import CategorySkeleton from '~/components/CategorySkeleton/CategorySkeleton';
import { FILTERS_TYPE } from '~/components/Filters/Filters';
import { ChooseItemFor, ITradeLists, ITradeItem } from '~/types/tradein';
import { sortItems } from '~/utils/sort/settings';
import { LISTS_FOR_DISPLAY_TYPE, getAvailableFiltersForLists, getPreparedLists } from '~/utils/tradein';
import { isEmptyObject } from '~/utils/utils';

interface IStateSelector {
    selectedTargetItem: ITradeItem;
    selectedSourceItem: ITradeItem;
    lists: ITradeLists;
    currentPage: ICurrentPage;
    choosenItemFor: ChooseItemFor;
    activeFilters: FILTERS_TYPE;
    balance: IBalance;
    categories: ICategories;
    sortedBy: ISortCategories;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        selectedTargetItem: state.ReducerTradeIn.selectedTargetItem,
        selectedSourceItem: state.ReducerTradeIn.selectedSourceItem,
        choosenItemFor: state.ReducerTradeIn.choosenItemFor,
        currentPage: state.ReducerApp.currentPage,
        lists: state.ReducerTradeIn.lists,
        activeFilters: state.ReducerTradeIn.activeFilters,
        balance: state.ReducerAccount.balance,
        categories: state.ReducerApp.categories,
        sortedBy: state.ReducerApp.sortedBy,
    };
};

interface ITradeInContainer {
    categoryName?: string;
}

const TradeInContainer = ({ categoryName }: ITradeInContainer) => {
    const dispatch = useDispatch();
    const { selectedTargetItem, selectedSourceItem, currentPage, lists, choosenItemFor, activeFilters, balance, categories, sortedBy } = useSelector<State, IStateSelector>(
        stateSelector,
        shallowEqual,
    );
    const currentTradeInLists = lists[categoryName || currentPage?.name];
    const state = React.useMemo<LISTS_FOR_DISPLAY_TYPE>(() => {
        const response = getPreparedLists(currentTradeInLists, choosenItemFor, selectedTargetItem, selectedSourceItem, activeFilters);
        if (sortedBy?.[currentPage?.name]?.name) {
            response.availableItems = sortItems(sortedBy[currentPage?.name].name, sortedBy[currentPage?.name].method, response.availableItems);
        }
        return response;
    }, [choosenItemFor, currentTradeInLists, activeFilters, sortedBy?.[currentPage?.name]]);
    const availableFilters = React.useMemo<FILTERS_TYPE>(() => {
        if (!state.availableItemsWithoutFilters?.length) {
            return {};
        }
        return getAvailableFiltersForLists(state.availableItemsWithoutFilters);
    }, [choosenItemFor, currentTradeInLists]);

    React.useEffect(() => {
        return () => {
            if (!document.location.href.includes('/port/')) {
                dispatch(clearState());
            }
        };
    }, [currentPage?.name]);

    if (!lists || isEmptyObject(lists)) {
        return <CategorySkeleton />;
    }

    return (
        <TradeInLayout
            type={currentTradeInLists?.[0]?.type}
            selectedTargetItem={selectedTargetItem}
            selectedSourceItem={selectedSourceItem}
            availableItems={state.availableItems}
            choosenItemFor={choosenItemFor}
            availableFilters={availableFilters}
            activeFilters={activeFilters}
            balance={balance}
            category={categories[currentPage?.name]}
        />
    );
};

export default TradeInContainer;
