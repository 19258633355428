import * as React from 'react';
import RandomBundleHistoryCarouselEmptyItem from '~/Layouts/RandomBundlePage/RandomBundleHistoryCarousel/RandomBundleHistoryCarouselEmptyItem';
import styles from './RandomBundleHistoryCarousel.scss';
import Carousel from '~/components/Carousel/Carousel';
import RandomBundleHistoryCarouselTimer from '~/Layouts/RandomBundlePage/RandomBundleHistoryCarousel/RandomBundleHistoryCarouselTimer';
import RandomBundleHistoryCarouselItem from '~/Layouts/RandomBundlePage/RandomBundleHistoryCarousel/RandomBundleHistoryCarouselItem';
import { getCountRandomBundleChildren, getProgressiveDiscountFromBundle, isPeriodicBundle } from '~/utils/bundles';
import { arrayToObjectByKey } from '~/utils/utils';
import { range } from '~/utils/generate';
import { playTabClickSound } from '~/api/WoWsClient';
import type { Swiper as SwiperInstance } from 'swiper/types';

interface IRandomBundleHistoryCarousel {
    bundle: IBundle;
    parentRandomBundle: IBundle;
    selectedRandomBundles: AccountSelectedRandomBundles;
    randomBundlesHistory: AccountRandomBundleHistory;
    onChangeQuantity: (amount: number) => void;
    setSwiperRef: (swiper: SwiperInstance) => void;
    isNeedToShowTimer?: boolean;
    isAvailablePartialPurchase?: boolean;
    maxAmountForOnePurchaseRandomBundle: number;
    isAlreadyPurchased?: boolean;
    onCarouselItemClick?: (bundle: IBundle) => void;
    isTimeUp?: boolean;
}

const RandomBundleHistoryCarousel = ({
    bundle,
    parentRandomBundle,
    selectedRandomBundles,
    randomBundlesHistory,
    onChangeQuantity,
    setSwiperRef,
    isNeedToShowTimer,
    isAvailablePartialPurchase,
    maxAmountForOnePurchaseRandomBundle,
    isAlreadyPurchased,
    onCarouselItemClick,
    isTimeUp,
}: IRandomBundleHistoryCarousel) => {
    const _isPeriodicBundle = isPeriodicBundle(bundle);
    const data = arrayToObjectByKey(bundle.randomBundleChildren, 'id');
    const bundleIds = [...(randomBundlesHistory?.[parentRandomBundle?.id] || [])];
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [carouselItems, setItems] = React.useState([]);
    const quantity = parentRandomBundle.quantityData?.quantity || 1;

    React.useEffect(() => {
        if (selectedRandomBundles[parentRandomBundle.id]) {
            bundleIds.push(selectedRandomBundles[parentRandomBundle.id]);
        }

        const items = bundleIds.reduce((array: any, id: number, index) => {
            if (!data[id]) {
                return array;
            }

            const isSelectedBundle = id === selectedRandomBundles[parentRandomBundle.id] && bundleIds[bundleIds.length - 1] === id;
            const isSelected = quantity === 1 && isSelectedBundle && index === bundleIds.length - 1;
            if (isSelected) {
                setSelectedIndex(index);
            }

            const isPending = false;
            let isPurchased = false;
            if (!isPending) {
                if (_isPeriodicBundle && isNeedToShowTimer) {
                    isPurchased = true;
                } else {
                    isPurchased = isAlreadyPurchased || randomBundlesHistory?.[parentRandomBundle?.id]?.[index] === id;
                }
            }

            array.push(
                <RandomBundleHistoryCarouselItem
                    key={`random_bundle_carousel_item_${index}`}
                    item={data[id]}
                    isSelected={isSelected}
                    isPurchased={isPurchased}
                    shinyAnimation={parentRandomBundle.randomBundleShinyAnimation}
                    onClick={() => {
                        playTabClickSound();
                        onCarouselItemClick?.(Object.assign({}, bundle, data[id]));
                        isSelectedBundle && isAvailablePartialPurchase && onChangeQuantity(1);
                    }}
                />,
            );

            return array;
        }, []);

        const totalCount = getCountRandomBundleChildren(parentRandomBundle);
        const countEmptyBlocks = bundle.randomIsInfinite ? maxAmountForOnePurchaseRandomBundle : Math.max(totalCount - bundleIds.length, 0);

        if (!_isPeriodicBundle) {
            items.push(
                ...range(countEmptyBlocks, 0, 1, (index) => {
                    const currentIndex = index + 2;
                    return (
                        <RandomBundleHistoryCarouselEmptyItem
                            key={`random_bundle_carousel_empty_item_${currentIndex}`}
                            onClick={(amount) => {
                                playTabClickSound();
                                onChangeQuantity(amount);
                            }}
                            isPeriodic={_isPeriodicBundle}
                            amount={currentIndex}
                            isLastItem={!bundle.randomIsInfinite && index + 1 === countEmptyBlocks}
                            parentRandomBundle={parentRandomBundle}
                            bundle={bundle}
                            isSelected={currentIndex === quantity}
                            isAvailablePartialPurchase={isAvailablePartialPurchase}
                            isDisabledProgressivePurchase={!isAvailablePartialPurchase || maxAmountForOnePurchaseRandomBundle < currentIndex}
                            progressiveDiscount={getProgressiveDiscountFromBundle(parentRandomBundle, currentIndex)}
                            shinyAnimation={parentRandomBundle.randomBundleShinyAnimation}
                        />
                    );
                }),
            );
        } else {
            if (isNeedToShowTimer) {
                items.push(<RandomBundleHistoryCarouselTimer bundle={parentRandomBundle} selectedRandomBundles={selectedRandomBundles} />);
                setSelectedIndex(items.length);
            } else if (parentRandomBundle.randomIsTotalCountHidden && countEmptyBlocks) {
                if (_isPeriodicBundle && isTimeUp) {
                    setItems(items);
                    return;
                }
                items.push(
                    <RandomBundleHistoryCarouselEmptyItem
                        isLastItem={true}
                        parentRandomBundle={parentRandomBundle}
                        bundle={bundle}
                        isDisabledProgressivePurchase={true}
                        shinyAnimation={parentRandomBundle.randomBundleShinyAnimation}
                    />,
                );
            } else if (countEmptyBlocks) {
                if (_isPeriodicBundle && isTimeUp) {
                    setItems(items);
                    return;
                }
                items.push(
                    <RandomBundleHistoryCarouselEmptyItem
                        isLastItem={false}
                        parentRandomBundle={parentRandomBundle}
                        bundle={bundle}
                        isDisabledProgressivePurchase={true}
                        isPeriodic={_isPeriodicBundle}
                        shinyAnimation={parentRandomBundle.randomBundleShinyAnimation}
                    />,
                );
            }
        }

        setItems(items);
    }, [quantity, randomBundlesHistory, selectedRandomBundles, maxAmountForOnePurchaseRandomBundle]);

    const specialProps: Partial<React.ComponentProps<typeof Carousel>> = {};
    const isVirtualSlides = carouselItems.length > 100;
    if (isVirtualSlides) {
        specialProps.virtualSlides = true;
        specialProps.slidesPerView = 1;
        specialProps.dynamicBreakpoints = () => {
            return {
                769: {
                    slidesPerView: window.innerHeight > 1000 ? 10 / 3 : 5,
                },
            };
        };
    }

    return carouselItems?.length ? (
        <Carousel
            items={carouselItems}
            className={{ item: styles.item }}
            centeredSlides={true}
            centeredSlidesBounds={true}
            freeMode={true}
            scrollToId={selectedIndex}
            key={carouselItems?.length}
            hideControls={true}
            setSwiperRef={(swiper) => {
                setSwiperRef(swiper);
            }}
            {...specialProps}
        />
    ) : (
        <></>
    );
};

export default RandomBundleHistoryCarousel;
