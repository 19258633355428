import * as React from 'react';
import styles from './Attention.scss';
import classNames from 'classnames';

interface IAttention {
    label: string | React.ReactChild;
    onClick?: (event: React.MouseEvent) => void;
    onButtonClick?: (event: React.MouseEvent) => void;
    className?: string;
    buttonText?: string;
    level?: ATTENTION_LEVELS;
    isInline?: boolean;
}

const Attention = (props: IAttention) => {
    return (
        <div
            className={classNames(
                styles.wrapper,
                styles[props.level],
                {
                    [styles.isInline]: props.isInline,
                },
                props.className,
            )}
            onClick={(event: React.MouseEvent) => {
                props.onClick && props.onClick(event);
            }}
        >
            <div className={styles.texts}>
                <span className={styles.label}>{props.label}</span>
                {props.buttonText && (
                    <span
                        className={styles.span}
                        onClick={(event: React.MouseEvent) => {
                            props.onButtonClick && props.onButtonClick(event);
                        }}
                    >
                        {props.buttonText}
                    </span>
                )}
            </div>
        </div>
    );
};

export default Attention;
