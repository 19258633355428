import React from 'react';
import classNames from 'classnames';

import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ItemTypes, PresentationStyles } from '@wg/wows-entities/const';
import { t } from '~/utils/localization';

import styles from './ShipWeapons.scss';

interface ShipWeaponsProps {
    shipId: number | string;
    className?: string;
}

export function ShipWeapons({ shipId, className }: ShipWeaponsProps) {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.title}>{t('Вооружение')}</div>
            <WoWSEntity
                type={ItemTypes.WEAPONS}
                id={shipId}
                presentation={{
                    withTooltip: true,
                    style: PresentationStyles.WEAPONS,
                }}
            />
        </div>
    );
}
