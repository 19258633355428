import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import store from '~/Store';
import { AnyAction } from 'redux';
import { hiddenMenuItem, showMenuItem } from '~/Actions/ActionApp';
import { saveMrpsAccountData, saveMrpsEvents, updateEventsRewards, updateMrpsAccountData } from '~/Actions/ActionMrps';
import Account from '~/account/Account';
import { getMrpsAccountData, getMrpsEvents } from '~/api/mrps';
import { isMrpsCategoryEnabled, loadAdditionalRewardDataFromVrtx } from '~/utils/mrps';
import { openCategoryByName } from '~/utils/category';
import { CategoryType } from '~/types/category';

export default class MrpsPreloader extends AbstractPreloader {
    async load() {
        const categories = store.getState().ReducerApp.categories;
        const mrpsCategories = Object.entries(categories).filter(([_, categoryData]) => {
            if (categoryData.type === CategoryType.MRPS) {
                return true;
            }

            return false;
        });

        if (!mrpsCategories.length) return;

        const mrpsData = await getMrpsEvents();

        if (!mrpsData?.events) {
            mrpsCategories.forEach(([categoryName]) => {
                store.dispatch(hiddenMenuItem(categoryName));
            });

            return;
        }

        const events = mrpsData.events;

        store.dispatch(saveMrpsEvents(mrpsData.events));

        new Set([...mrpsCategories.map(([categoryName, _]) => categoryName), ...events.map((event) => event.name)]).forEach((categoryName) => {
            if (!isMrpsCategoryEnabled(categoryName)) {
                store.dispatch(hiddenMenuItem(categoryName));
            }
        });

        if (Account.getAccount() && !Account.isDeactivated()) {
            const mrpsAccountData = await getMrpsAccountData();
            mrpsAccountData && store.dispatch(saveMrpsAccountData(mrpsAccountData));
        }

        const updatedEvents: MrpsEventWithUpdatedRewards[] = [];
        for (let i = 0; i < events.length; i++) {
            const eventToUpdate = events[i];
            const eventRewards = eventToUpdate.rewards;
            const loadedRewards = await loadAdditionalRewardDataFromVrtx(eventRewards);

            updatedEvents.push({ ...eventToUpdate, rewards: loadedRewards });
        }

        store.dispatch(updateEventsRewards(updatedEvents) as unknown as AnyAction);

        return Promise.resolve(1 as const);
    }

    async sync() {
        const { ReducerApp: reducerApp, reducerMrps } = store.getState();
        const { menu, currentPage } = reducerApp;
        const { events } = reducerMrps;

        events.forEach((event) => {
            if (!isMrpsCategoryEnabled(event.name)) {
                menu[event.name] && store.dispatch(hiddenMenuItem(event.name));

                if (currentPage === event.name) {
                    openCategoryByName();
                }
            } else {
                menu[event.name] && store.dispatch(showMenuItem(event.name));
            }
        });

        if (Account.getAccount() && !Account.isDeactivated()) {
            const mrpsAccountData = await getMrpsAccountData();
            mrpsAccountData && store.dispatch(updateMrpsAccountData(mrpsAccountData) as unknown as AnyAction);
        }
    }
}
