import * as React from 'react';
import Account from '~/account/Account';
import { startVideo } from '~/Actions/ActionApp';
import { useDispatch } from 'react-redux';
import { DependencyList } from 'react';
import { getSupportedVideo } from '~/utils/video';
import { isMobileOrTabletWindow } from '~/utils/utils';

interface IHookEntryVideo {
    videoName?: string;
    fadeOutVideoInSeconds?: number;
    isViewAccountSpecific?: boolean;
    videoUrlsMap?: Partial<IVideo>;
    onFinishCallback?: DefaultFunction;
    isAllowedVideoForMobile?: boolean;
    categoryName?: string;
}

interface EntryVideoStatus {
    isNeedToPlay: boolean;
    isFinished: boolean;
}

const useEntryVideo = (
    { videoName, isViewAccountSpecific, videoUrlsMap, fadeOutVideoInSeconds, onFinishCallback, isAllowedVideoForMobile, categoryName }: IHookEntryVideo,
    deps: DependencyList = [],
) => {
    const dispatch = useDispatch();
    const [videoStatus, setVideoStatus] = React.useState<EntryVideoStatus>({
        isNeedToPlay: false,
        isFinished: false,
    });

    React.useLayoutEffect(() => {
        let _videoName = videoName;
        const videoUrl = getSupportedVideo(videoUrlsMap);
        if (!_videoName && videoUrl) {
            _videoName = (categoryName ? `${categoryName}_` : '') + videoUrl;
        }

        let isNeedToPlay = videoUrl && !Account.isViewedVideo(_videoName, isViewAccountSpecific);
        if (isNeedToPlay && isMobileOrTabletWindow) {
            isNeedToPlay = isAllowedVideoForMobile;
        }

        setVideoStatus({ isNeedToPlay, isFinished: false });

        if (isNeedToPlay) {
            dispatch(
                startVideo(
                    _videoName,
                    videoUrl,
                    () => {
                        Account.markViewedVideo(_videoName, isViewAccountSpecific);
                        setVideoStatus({ isNeedToPlay: false, isFinished: true });
                        onFinishCallback?.();
                    },
                    fadeOutVideoInSeconds || 0,
                ),
            );
        }
    }, [videoUrlsMap, ...deps]);

    return {
        isNeedToPlay: videoStatus.isNeedToPlay,
        isFinished: videoStatus.isFinished,
    };
};

export default useEntryVideo;
