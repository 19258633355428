import * as React from 'react';
import styles from './ChangeCouponButton.scss';
import { t } from '~/utils/localization';
import classNames from 'classnames';

interface IChangeCouponButton {
    onClick: () => void;
    className?: string;
}

const ChangeCouponButton = ({ onClick, className }: IChangeCouponButton) => {
    return (
        <div className={classNames(styles.button, className)} onClick={onClick}>
            {t('Выбрать другой купон')}
        </div>
    );
};

export default ChangeCouponButton;
