import * as React from 'react';
import styles from './Purchased.scss';
import { t } from '~/utils/localization';
import { getAlreadyHaveText } from '~/utils/bundles';
import classNames from 'classnames';

interface IPurchasedItem {
    bundle: IBundle;
    className?: string;
}

export const PurchasedItem = (props: IPurchasedItem) => {
    const message = getAlreadyHaveText(props.bundle);

    return <div className={classNames(styles.alreadyPurchasedWrapper, props.className)}>{message}</div>;
};

interface IPurchased {
    bundle?: IBundle;
    onlyIcon?: boolean;
}

const Purchased = (props: IPurchased) => {
    if (props.onlyIcon) {
        return <div className={styles.purchasedIcon} />;
    }
    const message = props.bundle ? getAlreadyHaveText(props.bundle, true) : t('Получено');
    return <div className={styles.alreadyPurchasedText}>{message}</div>;
};

export default Purchased;
