import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { State } from '~/Reducers';
import store from '~/Store';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { resetCoupon } from '~/Actions/ActionAccount';
import { removeClientSource } from '~/Actions/ActionApp';
import WowsCommerce from '@wg/wows-commerce';
import Account from '~/account/Account';
import RefManager, { RefManagerKeys } from '~/RefManager';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { isEnabledPortByBundle } from '~/settings/port';
import { BundlePurchaseTypes } from '~/types/bundle';
import { t } from '~/utils/localization';
import { isAvailableFullscreenTemplateWithoutGallery, isFreeBundle, isMultiplePurchaseAvailable } from '~/utils/bundles';
import { successPurchaseCallback } from '~/utils/purchase';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import useDelegateContainerScroll from '~/hooks/useDelegateContainerScroll';

import CrewVoiceButtonContainer from '~/containers/CrewVoiceButtonContainer/CrewVoiceButtonContainer';
import BundlePagePortPreview from '~/Layouts/BundlePage/BundlePagePortPreview/BundlePagePortPreview';
import BundlePageLeftContent from '~/Layouts/BundlePage/BundlePageLeftContent/BundlePageLeftContent';
import Customization from '~/Layouts/BundlePage/Customization/Customization';
import { isAvailableBigArt, isHiddenBundleImage, isScrollableTemplate } from '~/Layouts/BundlePage/helpers';
import BundlePageImage from '~/Layouts/BundlePage/BundlePageImage/BundlePageImage';
import { canBoughtContainer, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';
import PurchaseButton from '~/components/Button/PurchaseButton';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import AttentionLimitWidget from '~/components/BundleLimitWidget/Attention/AttentionLimitWidget';
import MobilePurchaseWrapper from '~/components/PurchaseWrapper/MobilePurchaseWrapper';
import { FadeInDiv } from '~/components/Transitions';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { getLevelingRestrictionForBundle, getLevelingRestrictionTextForBundle } from '~/utils/levelingResctrictions';
import Attention from '~/components/Attention/Attention';
import RealPurchaseButton from '~/components/Button/RealPurchaseButton';
import { ArmoryFooter, WsmartFooter } from './BundlePageFooter';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import { isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { Dialog } from '~/components/Dialog/Dialog';

import { default as commonStyles } from '~/assets/styles/common.scss';
import styles from './BundlePage.scss';

interface IBundlePage {
    bundle: IBundle;
}

interface IStateSelector {
    accountId: number;

    currentPage: ICurrentPage;

    categories: ICategories;
    disabledCategories?: string[];

    bundles: IBundleList;
    purchasedLimitedBundles?: AccountPurchasedLimitedBundles;
    bundlesInTransaction: number[];
    restrictedLootboxesPurchaseCount?: AccountRestrictedLootboxesPurchaseCount;

    balance: IBalance;
    activeCouponId?: number;
    coupons: ICoupon[];

    wowsCommerce: WowsCommerce;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount?.id,

        currentPage: state.ReducerApp.currentPage,

        categories: state.ReducerApp.categories,
        disabledCategories: state.ReducerApp.disabledCategories,

        bundles: state.ReducerApp.bundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        bundlesInTransaction: state.ReducerAccount.bundlesInTransaction,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,

        balance: state.ReducerAccount.balance,
        activeCouponId: state.ReducerAccount.activeCouponId,
        coupons: state.ReducerAccount.coupons,

        wowsCommerce: state.ReducerWSMart.wowsCommerce,
    };
};

const BundlePage = ({ bundle }: IBundlePage) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isDisabledPurchaseButton, setDisablingPurchaseButton] = React.useState(false);
    const [quantity, setQuantity] = React.useState(1);

    const bundleLeftContentRef = React.useRef<HTMLDivElement>(null);

    const isAlreadyPurchased = bundle.isPurchased;
    const isObtainedPermanentCamouflage = isAlreadyPurchased && isPermanentCamouflage(bundle.primaryItem.type);
    const isAvailablePort = (!isAlreadyPurchased || isObtainedPermanentCamouflage) && isEnabledPortByBundle(bundle, false, true);

    const isScrollableTemplateFlag = isScrollableTemplate(bundle);
    const isHiddenGallery = isAvailableBigArt(bundle) || isScrollableTemplateFlag || isHiddenBundleImage(bundle);
    const hasGallery = !!bundle.gallery?.length && !isHiddenGallery;

    const isDisabledCategory = state.disabledCategories.includes(state.currentPage?.name);
    const isFreeBundleFlag = isFreeBundle(bundle);
    const isBundleForRealCurrency = bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY;
    const isHiddenPortButton = isAvailableFullscreenTemplateWithoutGallery(bundle);
    const isActiveTransaction = state.bundlesInTransaction?.includes(bundle.id);
    const isDialogAvailable = !!bundle.dialogOptions;
    const isEnabledCoupon = Account.isEnabledCouponsFromBundle(state.coupons, bundle);

    const canPurchaseBundle = canBoughtContainer(bundle, state.restrictedLootboxesPurchaseCount);
    const canChangeQuantity = state.accountId && !bundle.isLoading && isMultiplePurchaseAvailable(bundle);

    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);
    const currentCategory = state.categories[state.currentPage?.name];

    React.useEffect(() => {
        return () => {
            store.dispatch(resetCoupon());
            store.dispatch(removeClientSource());
        };
    }, []);

    React.useEffect(() => {
        if (state.activeCouponId) {
            store.dispatch(resetCoupon());
        }
    }, [bundle.id]);

    useMaskScrollEffect(bundleLeftContentRef);
    useDelegateContainerScroll(bundleLeftContentRef);

    const purchase = () => {
        if (isFreeBundleFlag) {
            new PurchaseProcessor(bundle).purchase();
        } else {
            new PurchaseProcessor(bundle).purchaseBundleFromPopup(bundle, null, successPurchaseCallback.bind(this, bundle));
        }
    };

    if (isDisabledCategory) {
        return (
            <div className={commonStyles.bigNotice}>
                <div className={commonStyles.bigNoticeText}>{t('Товары в этой категории более недоступны')}</div>
            </div>
        );
    }

    const classesContent = classNames(styles.content);

    const classesPurchaseContent = classNames(styles.purchaseContent, {
        [styles.isVariablePriceProduct]: bundle.originalProduct?.isVariablePriceProduct,
    });

    const classesRightContent = classNames(styles.rightContent, {
        [styles.withGallery]: hasGallery,
        [styles.withFullscreenGallery]: !isMobileOrTabletWindow && bundle.isFullscreenGallery,
        [styles.hiddenImage]: isHiddenGallery,
    });

    const levelingResctrictions = getLevelingRestrictionForBundle(bundle);

    function getFooterContent() {
        if (bundle.isPurchased) {
            return <PurchasedItem bundle={bundle} />;
        }

        if (Number(canPurchaseBundle) >= 0) {
            if (isBundleForRealCurrency) {
                return (
                    <RealPurchaseButton
                        bundle={bundle}
                        quantity={quantity}
                        isDisabled={isDisabledPurchaseButton}
                        buttonClassName={classNames(styles.purchaseButton, 'armory__auto--bundle_button_purchase')}
                    />
                );
            }

            return (
                <PurchaseButton
                    bundle={bundle}
                    coupon={activeCoupon}
                    isActiveCoupon={state.activeCouponId && isEnabledCoupon}
                    buttonClassName={classNames(styles.purchaseButton, 'armory__auto--bundle_button_purchase')}
                    onClick={purchase}
                    classNameFastGoldLink={styles.fastGoldLink}
                    classNameChinaFastGoldLink={styles.cnFastGold}
                    place={FAST_GOLD_PLACE.BUNDLE_VIEW_PAGE}
                />
            );
        }

        return <AttentionLimitWidget reason={canPurchaseBundle as REASON_FAIL_PURCHASE_CONTAINER} bundle={bundle} />;
    }

    return (
        <React.Fragment>
            <Customization bundle={bundle} theme={currentCategory?.theme} />
            <FadeInDiv className={classNames(styles.wrapper, 'armory__auto--bundle-page_wrapper')} data-bundle-id={bundle.id}>
                <div
                    className={classesContent}
                    ref={(_ref: HTMLDivElement) => {
                        if (_ref) {
                            RefManager.setRef(RefManagerKeys.BundlePageContent, _ref);
                        }
                    }}
                >
                    <BundlePageLeftContent
                        bundle={bundle}
                        bundles={state.bundles}
                        ref={bundleLeftContentRef}
                        isScrollableTemplate={isScrollableTemplateFlag}
                        className={classNames({ [styles.withPort]: isAvailablePort })}
                    />
                    <React.Fragment>
                        <div className={classesRightContent}>
                            <BundlePageImage bundle={bundle} />
                            {isAvailablePort && !isHiddenPortButton && <BundlePagePortPreview bundle={bundle} />}
                            {isInGameBrowser && <CrewVoiceButtonContainer className={styles.crewVoiceButton} primaryItem={bundle.primaryItem} />}
                        </div>
                    </React.Fragment>
                </div>
                <div className={classesPurchaseContent}>
                    {isDialogAvailable && <Dialog {...bundle.dialogOptions} wrapperClassName={styles.dialogWrapper} bundleId={bundle.id} />}
                    {!isAlreadyPurchased &&
                        !isFreeBundleFlag &&
                        (!isBundleForRealCurrency ? (
                            <ArmoryFooter
                                bundle={bundle}
                                activeCoupon={activeCoupon}
                                balance={state.balance}
                                canChangeQuantity={canChangeQuantity}
                                isEnabledCoupon={isEnabledCoupon}
                                reasonCantBoughtCode={canPurchaseBundle as REASON_FAIL_PURCHASE_CONTAINER}
                                isActiveTransaction={isActiveTransaction}
                            />
                        ) : (
                            bundle.originalProduct && <WsmartFooter bundle={bundle} onDisabledHandler={setDisablingPurchaseButton} onQuantityHandler={setQuantity} />
                        ))}
                    {!!levelingResctrictions?.length && (
                        <div className={styles.restrictionText}>
                            <Attention label={getLevelingRestrictionTextForBundle(bundle, levelingResctrictions)} level="info" isInline />
                        </div>
                    )}
                    <div className={styles.purchaseButtonsWrapper}>
                        <div className={styles.purchaseButtons}>{getFooterContent()}</div>
                    </div>
                </div>
                {!bundle.originalProduct?.isVariablePriceProduct && <div className={styles.mobilePurchaseWrapper}>{<MobilePurchaseWrapper bundle={bundle} />}</div>}
            </FadeInDiv>
        </React.Fragment>
    );
};

export default BundlePage;
