import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { openBundleByUrl } from '~/utils/category';
import classNames from 'classnames';
import styles from './SantaBundle.scss';
import { selectGroupBundle, startVideo } from '~/Actions/ActionApp';
import PriceWrapper from '~/components/PriceWrapper/PriceWrapper';
import PurchaseButton, { PurchaseButtonTheme } from '~/components/Button/PurchaseButton';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { DEFAULT_SELECTED_INDEX_BY_GROUP, isCertificateShowcase, SANTA_BUNDLES_AUDIO_MAP, SANTA_BUNDLE_CARD_VIDEOS, SANTA_SIZE, VIDEO_BUNDLE_PREVIEW } from '~/Layouts/Themes/SantaPage/settings';
import { getStaticUrl, isInGameBrowser, isMobileOrTabletWindow, isSafari } from '~/utils/utils';
import { Howl } from 'howler';
import useResourceLoader from '~/hooks/useResourceLoader';
import { getSupportedVideo, isVideoTypeSupported } from '~/utils/video';
import { getBundleDiscount, groupBundlesByName } from '~/utils/bundles';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import preCacheImage from '~/utils/preCacheImage';
import Discount from '~/components/Discount/Discount';
import { playButtonClickSound } from '~/api/WoWsClient';
import { isEnabledAudioContext } from '~/utils/sounds/settings';
import useMobile from '~/hooks/useMobile';
import { useEffect } from 'react';

interface ISantaBundle {
    groupName: string;
    groups: ReturnType<typeof groupBundlesByName>;
    isLastGroup: boolean;
    isDisabledAnimation?: boolean;
}

interface IStateSelector {
    currentPage: ICurrentPage;
    groupBundles: Record<string, number>;
    categoriesAnimationStatuses: ICategoriesAnimationStatuses;
    categories: ICategories;
    isPopupActive: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        groupBundles: state.ReducerApp.groupBundles,
        categoriesAnimationStatuses: state.ReducerApp.categoriesAnimationStatuses,
        categories: state.ReducerApp.categories,
        isPopupActive: !!state.ReducerApp.popupActive,
    };
};

const SantaBundle = React.forwardRef((props: ISantaBundle, ref: React.RefObject<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const selectedBundleStore = state.groupBundles || {};
    const currentGroupsBundles: IBundle[] = props.groups[props.groupName];
    let _isDisabledAnimation = useIsDisabledAnimation(state.currentPage?.name);
    if (!isVideoTypeSupported('webm')) _isDisabledAnimation = true;
    const idleAnimationRef = React.useRef<HTMLVideoElement>(null);
    const hoverAnimationRef = React.useRef<HTMLVideoElement>(null);
    const SIZE = SANTA_SIZE[props.groupName];
    const howl = new Howl({ src: SANTA_BUNDLES_AUDIO_MAP[SIZE] });
    const animationsMap = SANTA_BUNDLE_CARD_VIDEOS[SIZE];
    const [isMobile] = useMobile();

    const selectedBundleIndex = currentGroupsBundles?.reduce((state: number, bundle: IBundle, index: number) => {
        if (bundle.id === selectedBundleStore?.[props.groupName]) {
            state = index;
        }
        return state;
    }, null);

    let currentBundle: IBundle;

    if (selectedBundleIndex !== null && selectedBundleIndex !== undefined) {
        currentBundle = currentGroupsBundles[selectedBundleIndex];
    } else {
        currentBundle = currentGroupsBundles[DEFAULT_SELECTED_INDEX_BY_GROUP[props.groupName] || 0] || currentGroupsBundles[0];
    }

    const supportedIntroVideoUrl = getSupportedVideo(VIDEO_BUNDLE_PREVIEW[SIZE]?.intro);

    const [selectedBundle, updateSelectedBundle] = React.useState(currentBundle);
    const [isLoadingResources, resourcesMap] = useResourceLoader([animationsMap.idle, animationsMap.hover], isInGameBrowser, isSafari);
    // const [isLoadingIntroBundleVideo, resourceIntroVideo] = useResourceLoader([supportedIntroVideoUrl], isInGameBrowser, isSafari);

    const isVideoEnabled = !isMobileOrTabletWindow && !_isDisabledAnimation && isLoadingResources;

    useEffect(() => {
        if (state.isPopupActive) {
            idleAnimationRef.current?.pause();
        } else {
            idleAnimationRef.current?.play();
        }
    }, [state.isPopupActive]);

    if (!selectedBundle) {
        return null;
    }

    const bundle = { ...selectedBundle, quantityData: {} };

    const isShowcaseForCertificates = isCertificateShowcase(state.currentPage?.name);

    const classes = classNames(styles.bundle, styles[SANTA_SIZE[props.groupName]], props.groupName, {
        [styles.fromCertificate]: isShowcaseForCertificates,
    });

    const classesIdleVideo = classNames(styles.video);
    const classesHoverVideo = classNames(styles.video, styles.animationHover);
    const classesImage = classNames(styles.image, styles[SIZE]);
    const classesHoverImage = classNames(styles.image, styles[SIZE], styles.hover);

    const goToBundle = () => {
        playButtonClickSound();
        openBundleByUrl(state.currentPage?.name, selectedBundle.id);
    };

    const startAnimation = () => {
        const hover = hoverAnimationRef.current;
        hover.currentTime = 0;
        hover.play().catch();
    };

    const stopAnimation = () => {
        const hover = hoverAnimationRef.current;
        hover.pause();
        setTimeout(() => {
            hover.currentTime = 0;
        }, 500);
    };

    const onMouseEnter = () => {
        if (!isEnabledAudioContext() || isMobileOrTabletWindow) return;
        howl.seek(0);
        howl.volume(1);
        howl.play();
        isVideoEnabled && startAnimation();
    };

    const onMouseLeave = () => {
        isVideoEnabled && stopAnimation();
    };

    preCacheImage.add(bundle.icons?.small);

    return (
        <div className={classes} ref={ref}>
            <div className={classNames(styles.imageContainer, { [styles.withVideo]: isVideoEnabled })} onClick={goToBundle} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <img className={classesImage} src={isMobile ? bundle.icons.small : getStaticUrl(require(`./images/2024box_${SIZE}.png`))} />
                <img className={classesHoverImage} src={isMobile ? bundle.icons.small : getStaticUrl(require(`./images/2024box_${SIZE}_hover.png`))} />
                {isVideoEnabled && (
                    <>
                        <video className={classesIdleVideo} preload={'auto'} autoPlay={true} muted loop src={resourcesMap[animationsMap.idle]} ref={idleAnimationRef} />
                        <video className={classesHoverVideo} preload={'auto'} autoPlay={false} loop muted src={resourcesMap[animationsMap.hover]} ref={hoverAnimationRef} />
                    </>
                )}
            </div>

            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.title}>{selectedBundle.title}</div>
                </div>
                {currentGroupsBundles.length > 1 && (
                    <div className={styles.amount}>
                        {currentGroupsBundles.map((bundle) => {
                            const discount = getBundleDiscount(bundle);
                            return (
                                <div
                                    key={bundle.id}
                                    className={classNames(styles.amountItem, { [styles.amountSelected]: selectedBundle.id === bundle.id })}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        playButtonClickSound();
                                        updateSelectedBundle(bundle);
                                        dispatch(selectGroupBundle(props.groupName, bundle.id));
                                    }}
                                >
                                    {discount && <Discount className={styles.amountDiscount} discount={discount} />}
                                    <span>&times;</span>
                                    {bundle.primaryItem?.amount || bundle.entitlements[0]?.amount}
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className={styles.priceWrapper}>
                    <PriceWrapper
                        bundle={bundle}
                        withoutAnimation={false}
                        wrapperClassName={styles.pricesWrapper}
                        hiddenDiscount
                        className={styles.price}
                        oldPriceClassName={styles.priceOld}
                        priceClassName={styles.priceInner}
                    />
                </div>

                <div className={styles.purchaseWrapper}>
                    <PurchaseButton
                        bundle={selectedBundle}
                        buttonClassName={styles.button}
                        shortageClassName={styles.shortageClassName}
                        classNameFastGoldLink={styles.fastGoldWrapper}
                        classNameChinaFastGoldLink={styles.fastGoldWrapper}
                        purchaseButtonTheme={isShowcaseForCertificates ? PurchaseButtonTheme.blue : PurchaseButtonTheme.orange}
                        isForceRenderPurchaseButton
                    />
                </div>
            </div>
        </div>
    );
});

export default SantaBundle;
