import * as React from 'react';

const useClickAway = (ref: React.RefObject<any>, callback?: (event: MouseEvent) => void, deps: React.DependencyList = []): void => {
    function handleClickOutside(event?: MouseEvent) {
        if (event.button === 0 && ref.current && !ref.current.contains(event.target)) {
            if (typeof callback === 'function') {
                callback(event);
            }
        }
    }

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, deps);
};

export default useClickAway;
