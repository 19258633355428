import * as React from 'react';
import styles from './Link.scss';
import classNames from 'classnames';
import { playButtonClickSound } from '~/api/WoWsClient';

export enum LinkColors {
    blue = 'blue',
    white = 'white',
}

interface ILink {
    label: string;
    className?: string;
    onClick?: (event?: React.MouseEvent) => void;
    color?: LinkColors;
}

const Link = ({ label, onClick, className, color }: ILink) => {
    return (
        <span
            className={classNames(styles.link, styles[color], className)}
            onClick={(e) => {
                playButtonClickSound();
                onClick?.(e);
            }}
        >
            {label}
        </span>
    );
};

export default Link;
