import * as React from 'react';
import styles from './PromotionLabel.scss';

interface IPromotionLabel {
    text: string;
}

const PromotionLabel = ({ text }: IPromotionLabel) => {
    return <div className={styles.wrapper}>{text}</div>;
};

export default PromotionLabel;
