import React from 'react';
import classNames from 'classnames';
import { BUNDLE_TYPE, BundleType } from '~/utils/bundles';

import styles from './BundleType.scss';

interface IBundleType {
    item: IBundleEntity;
    className?: string;
    size?: 'small' | 'default';
    isPurchasePopup?: boolean;
    withoutAdditionalContent?: boolean;
}

const BundleType = ({ item, className, size, isPurchasePopup, withoutAdditionalContent }: IBundleType) => {
    const handler = BUNDLE_TYPE[item.type as keyof BundleType];
    if (!handler) {
        return null;
    }

    const content = handler(item, isPurchasePopup || withoutAdditionalContent);

    return (
        <div className={classNames(styles.wrapper, styles[size], className)}>
            {Array.isArray(content)
                ? content.reduce((result, item, index) => {
                      if (index && item) {
                          result.push(<div className={styles.verticalDivider} />);
                      }
                      result.push(<span>{item}</span>);

                      return result;
                  }, [])
                : content}
        </div>
    );
};

export default BundleType;
