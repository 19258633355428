import * as React from 'react';
import styles from './Layouts.scss';
import classNames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import BundleManager from '~/components/Bundle/BundleManager';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';

export const isBundleListLayout = (template: string) => FEATURING_TEMPLATES.BUNDLE_LIST === template;

interface IBundlesListLayout {
    data: IContentBlock;
}

const BundlesListLayout = function (props: IBundlesListLayout) {
    const reducerApp = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);
    const bundles = reducerApp.bundles;
    const bundlesListConfig = props.data;
    const bundlesListData = bundlesListConfig.data as IContentBlockData;

    let bundlesList = null;
    if (bundlesListConfig.type === FEATURING_TEMPLATES.BUNDLE_LIST) {
        bundlesList = (bundlesListData?.bundles || []).filter((bundleData) => {
            return !!bundles[bundleData.id];
        });

        if (!bundlesList.length) {
            return null;
        }
    }

    return (
        <div className={classNames(styles.layout, 'bundles-layout', styles.bundles)} key={`${bundlesListConfig.type}_${bundlesListData.title}`}>
            <div className={styles.layoutHeader}>
                <div className={styles.layoutTitle}>{bundlesListData.title}</div>
            </div>
            <div className={classNames(styles.layoutColumns, styles.featuringLayoutColumns)}>
                <div className={classNames(styles.columns, 'columns')}>
                    {bundlesList &&
                        bundlesList.map((bundleData, index: number) => {
                            const bundle = bundles[bundleData.id];

                            if (!bundle) {
                                return null;
                            }

                            return <BundleManager key={`offer_${index}`} bundle={bundles[bundleData.id]} image={bundleData.image} width={bundleData.width} {...props} />;
                        })}
                </div>
            </div>
        </div>
    );
};

export default BundlesListLayout;
