import * as React from 'react';
import styles from './MobileTooltip.scss';
import { Popup, PopupBody, PopupFooter } from '~/components/Popup/Popup';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { useDispatch } from 'react-redux';
import { Button } from '~/components/Button/Buttons';
import { t } from '~/utils/localization';

interface IMobileTooltip {
    contents: string[];
}

const MobileTooltip = ({ contents }: IMobileTooltip) => {
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
    };

    return (
        <Popup onClose={onClose} renderEscButton={false}>
            <PopupBody className={styles.body}>
                {contents.map((content, index) => {
                    return (
                        <div className={styles.contentBlock} key={`content_block_${index}`}>
                            {content}
                        </div>
                    );
                })}
            </PopupBody>
            <PopupFooter className={styles.footer}>
                <Button label={t('Закрыть')} className={styles.buttonClose} onClick={onClose} />
            </PopupFooter>
        </Popup>
    );
};

export default MobileTooltip;
