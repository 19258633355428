import * as React from 'react';
import { getCountRandomBundleChildren, isFreeBundle, isPeriodicBundle } from '~/utils/bundles';
import { interpolate, ngettext, t } from '~/utils/localization';
import { getParsedTime, getPeriodicInfo, getTimeWholePeriod } from '~/utils/time';
import { localizedDateTime, shortTime } from '~/utils/utils';
import styles from '../common.scss';
import { Interpolate } from '@wg/wows-react-uikit';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';

interface IRandomBundleContent {
    title?: string;
    content: React.ReactChild;
}

const getSpoilerContentTitle = (bundle: IBundle) => {
    const _isFreeBundle = isFreeBundle(bundle);
    const _isPeriodic = isPeriodicBundle(bundle);
    let title = '';

    const availablePurchasesCount = getCountRandomBundleChildren(bundle) || 0;

    if (_isPeriodic) {
        if (_isFreeBundle) {
            title = interpolate(
                ngettext(
                    'Периодичный случайный набор — это 1 из {num} бесплатных наборов, объединённых одним игровым событием.',
                    'Периодичный случайный набор — это 1 из {num} бесплатных наборов, объединённых одним игровым событием.',
                    bundle.randomBundleChildren.length,
                ),
                {
                    num: availablePurchasesCount,
                },
            );
        } else {
            title = interpolate(
                ngettext(
                    'Периодичный случайный набор — это 1 из {num} наборов, объединённых одним игровым событием.',
                    'Периодичный случайный набор — это 1 из {num} наборов, объединённых одним игровым событием.',
                    bundle.randomBundleChildren.length,
                ),
                {
                    num: availablePurchasesCount,
                },
            );
        }
    } else {
        if (bundle.randomIsInfinite || bundle.randomIsTotalCountHidden) {
            const headerTitle = t('Случайный набор – это один из множества наборов, объединенных одним игровым событием.');
            const includedBundlesText = interpolate(
                ngettext('Из {} возможных вариантов случайным образом выпадает один.', 'Из {} возможных вариантов случайным образом выпадает один.', bundle.randomBundleChildren.length),
                [bundle.randomBundleChildren.length],
                false,
            );
            const footerText = t('Случайные наборы могут повторяться.');

            title = `${headerTitle}&nbsp;${includedBundlesText}&nbsp;${footerText}`;
        } else {
            title = interpolate(
                ngettext(
                    'Случайный набор – это один из {} наборов, объединенных одним игровым событием.',
                    'Случайный набор – это один из {} наборов, объединенных одним игровым событием.',
                    availablePurchasesCount,
                ),
                [availablePurchasesCount],
                false,
            );
        }
    }

    return title;
};

const getInfoByPeriodicRandomBundle = (bundle: IBundle): IRandomBundleContent => {
    const _isFreeBundle = isFreeBundle(bundle);
    const availablePurchasesCount = getCountRandomBundleChildren(bundle) || 0;
    const title = getSpoilerContentTitle(bundle);
    const activeTill = new Date(bundle.promoTimerActiveTill).getTime();
    const activeFrom = new Date(bundle.promoTimerActiveFrom).getTime();
    const totalSeconds = Math.floor((activeTill - activeFrom) / 1000);
    const { days } = getParsedTime(totalSeconds);
    const activeTillText = interpolate(
        ngettext('Периодичные случайные наборы можно получить в течение {count} дня (до {date}).', 'Периодичные случайные наборы можно получить в течение {count} дней (до {date}).', days as number),
        {
            count: days || (totalSeconds / 86400).toFixed(2),
            date: localizedDateTime(new Date(bundle.promoTimerActiveTill)),
        },
    );

    const finalText = interpolate(
        ngettext(
            'При получении всех {count} набора вам гарантированно будет начислено всё указанное игровое имущество.',
            'При получении всех {count} наборов вам гарантированно будет начислено всё указанное игровое имущество.',
            availablePurchasesCount,
        ),
        {
            count: availablePurchasesCount,
        },
    );

    return {
        content: (
            <React.Fragment>
                <div className={styles.contentBlockDescription}>{title}</div>
                <div className={styles.contentBlockDescription}>{_getPeriodicBundleRenewPeriodText(bundle.randomPurchaseCooldown, activeFrom, bundle?.randomBundleChildren?.length || 0)}</div>
                <div className={styles.contentBlockDescription}>
                    {t('Новый периодичный случайный набор будет доступен только в случае получения предыдущего.')}&nbsp;{activeTillText}
                </div>
                <div className={styles.contentBlockDescription}>{finalText}</div>
            </React.Fragment>
        ),
    };
};

const _getPeriodicBundleRenewPeriodText = (time: number, startTime: number, childrenCount: number) => {
    const { days, hours } = getTimeWholePeriod(time);
    let text = '';

    if (days === 0 && [1, 2, 3, 4].includes(parseInt(hours.toString(), 10))) {
        const startHours = shortTime.format(new Date(startTime));

        text = interpolate(
            ngettext(
                'Ежедневно, каждые {hours} часа, начиная с {date} случайным образом выпадает 1 из {num} возможных вариантов.',
                'Ежедневно, каждые {hours} часа, начиная с {date} случайным образом выпадает 1 из {num} возможных вариантов.',
                hours,
            ),
            {
                hours,
                date: startHours,
                num: childrenCount,
            },
        );
    } else if (days === 1 || [6, 8, 12, 24].includes(parseInt(hours.toString(), 10))) {
        const periods = [];
        let _hours = 24;
        let currentTime = startTime;
        const periodHours = days === 1 ? 24 : hours;
        while (_hours / periodHours !== 0) {
            _hours -= periodHours;
            currentTime += periodHours * 3600 * 1000;
            periods.push(shortTime.format(currentTime));
        }
        periods.sort();
        text = interpolate(
            ngettext('Ежедневно в {time} случайным образом выпадает 1 из {num} возможных вариантов.', 'Ежедневно в {time} случайным образом выпадает 1 из {num} возможных вариантов.', childrenCount),
            {
                num: childrenCount,
                time: periods.join(', '),
            },
        );
    } else {
        const period = getPeriodicInfo(time);
        text = interpolate(t('Период обновления случайного набора: {period}'), { period });
    }

    return text;
};

const getInfoByDefaultRandomBundle = (bundle: IBundle): IRandomBundleContent => {
    const availablePurchasesCount = getCountRandomBundleChildren(bundle) || 0;
    const spoilerHeader = getSpoilerContentTitle(bundle);

    const includedBundlesText =
        !bundle.randomIsInfinite && !bundle.randomIsTotalCountHidden
            ? interpolate(
                  ngettext('Из {} возможных вариантов случайным образом выпадает один.', 'Из {} возможных вариантов случайным образом выпадает один.', bundle.randomBundleChildren.length),
                  [bundle.randomBundleChildren.length],
                  false,
              )
            : '';

    const availablePurchasesText =
        !bundle.randomIsInfinite && !bundle.randomIsTotalCountHidden
            ? interpolate(
                  ngettext(
                      'Приобретя {} набор, вы гарантированно получите всё указанное в них игровое имущество.',
                      'Приобретя все {} наборов, вы гарантированно получите всё указанное в них игровое имущество.',
                      availablePurchasesCount,
                  ),
                  [availablePurchasesCount],
                  false,
              )
            : '';

    const title = `${spoilerHeader}&nbsp;${includedBundlesText}&nbsp;${availablePurchasesText}`;

    const footer = t('Новый случайный набор будет доступен после получения предыдущего.');
    const priceContent =
        isFreeBundle(bundle) || !bundle.price ? null : (
            <Interpolate
                str={t('Каждый набор можно получить в обмен на {currency}.').replace('{currency}', '%(currency)s') + `&nbsp;${footer}`}
                currency={
                    <WoWSEntity
                        type={bundle.currency}
                        className={'wows-entities__assets-icon'}
                        amount={bundle.price}
                        styles={{
                            fontWeight: 'bold',
                        }}
                    />
                }
            />
        );

    return {
        content: (
            <React.Fragment>
                <div className={styles.contentBlockDescription} dangerouslySetInnerHTML={{ __html: title }} />
                <div className={styles.contentBlockDescription}>{priceContent}</div>
            </React.Fragment>
        ),
    };
};

const getRandomBundleContent = (bundle: IBundle): IRandomBundleContent => {
    const _isPeriodicBundle = isPeriodicBundle(bundle);
    const bundleInfo = _isPeriodicBundle ? getInfoByPeriodicRandomBundle(bundle) : getInfoByDefaultRandomBundle(bundle);

    return {
        title: _isPeriodicBundle ? t('Что такое периодичный случайный набор') : t('Что такое случайный набор'),
        content: bundleInfo.content,
    };
};

export { getRandomBundleContent };
