import { PreviewRewardCustomTypes } from '~/types/bundle';
import { t } from '~/utils/localization';

export type PreviewDefaultRewardsMapType = {
    [key in PreviewRewardCustomTypes]?: {
        title: Nullable<string>;
        isCurrencyItem?: boolean;
    };
};

export const PreviewDefaultRewardsMap: PreviewDefaultRewardsMapType = {
    [PreviewRewardCustomTypes.COAL]: {
        title: null,
        isCurrencyItem: true,
    },
    [PreviewRewardCustomTypes.FREE_XP]: {
        title: null,
        isCurrencyItem: true,
    },
    [PreviewRewardCustomTypes.CREDITS]: {
        title: null,
        isCurrencyItem: true,
    },
    [PreviewRewardCustomTypes.GOLD]: {
        title: null,
        isCurrencyItem: true,
    },
    [PreviewRewardCustomTypes.STEEL]: {
        title: null,
        isCurrencyItem: true,
    },
    [PreviewRewardCustomTypes.WOWS_PREMIUM]: {
        title: null,
        isCurrencyItem: true,
    },
    [PreviewRewardCustomTypes.ELITE_XP]: {
        title: null,
        isCurrencyItem: true,
    },
    [PreviewRewardCustomTypes.SHIP]: {
        title: t('Премиум корабль'),
    },
    [PreviewRewardCustomTypes.SHIP_WITH_SPECIAL_CREW]: {
        title: t('Корабль и особый командир'),
    },
    [PreviewRewardCustomTypes.SIGNAL]: {
        title: t('Набор сигналов'),
    },
    [PreviewRewardCustomTypes.BATTLE_SIGNAL]: {
        title: t('Набор боевых сигналов'),
    },
    [PreviewRewardCustomTypes.SPECIAL_SIGNAL]: {
        title: t('Набор особых сигналов'),
    },
    [PreviewRewardCustomTypes.ECO_SIGNAL]: {
        title: t('Набор экономических сигналов'),
    },
    [PreviewRewardCustomTypes.CAMO]: {
        title: t('Набор расходуемых камуфляжей'),
    },
    [PreviewRewardCustomTypes.PERMANENT_CAMO]: {
        title: t('Постоянный камуфляж'),
    },
    [PreviewRewardCustomTypes.SPECIAL_SIGNAL_WITH_CAMO]: {
        title: t('Набор особых сигалов и расходуемых камуфляжей'),
    },
    [PreviewRewardCustomTypes.UNIQUE_CREW]: {
        title: t('Уникальный командир'),
    },
    [PreviewRewardCustomTypes.ALBUM_COLLECTION]: {
        title: t('Элемент коллекции'),
    },
};
