import { csrf, settings } from '~/utils/settings';
import { get, post } from '~/utils/ajax';
import { getCookie } from '~/utils/cookie';
import { FilterPosition } from '~/types/category';

export async function completeActions(actions: string[]) {
    const responseCSRFToken = await checkCSRFToken();

    const url = settings.urls.accountCompleteActions;

    return await post(url, { data: JSON.stringify({ actions: actions }) }, {}, true);
}

export async function getAccountInfo() {
    return await get(settings.urls.accountInfo);
}

export async function markNotificationAsViewed(name: string) {
    const responseCSRFToken = await checkCSRFToken();

    const url = settings.urls.accountMarkNotificationAsViewed;

    return await post(url, { data: JSON.stringify({ name }) }, {}, true);
}

export async function markLabelAsViewed(id: string) {
    const responseCSRFToken = await checkCSRFToken();

    const url = settings.urls.accountMarkLabelAsViewed;

    return await post(url, { data: JSON.stringify({ name: id }) }, {}, true);
}

export async function changeFilterSettings(position: FilterPosition) {
    const responseCSRFToken = await checkCSRFToken();

    const url = settings.urls.changeFilterSettings;

    return await post(url, { data: JSON.stringify({ position }) }, {}, true);
}

export async function updateAccountSettings(data: Record<string, any>) {
    const responseCSRFToken = await checkCSRFToken();

    return await post(settings.urls.accountSettingsUpdate, { data: JSON.stringify({ ...data }) }, {}, true);
}

export async function getPurchaseMaxCountFromRandomBundle(bundleId: number): Promise<{ data: number }> {
    const url = settings.urls.purchaseMaxCount.replace('{}', bundleId.toString());
    return await get(url);
}

export async function checkCSRFToken() {
    return new Promise(async (resolve) => {
        if (!getCookie(settings.csrfTokenCookieName)) {
            csrf().then(resolve).catch(resolve);
        } else {
            resolve(1);
        }
    });
}
