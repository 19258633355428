import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { State } from '~/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '~/Layouts/DefaultLayout';
import { t } from '~/utils/localization';
import { updateActiveFilterPreset } from '~/Actions/ActionApp';
import { sortBundles } from '~/utils/sort/settings';
import { PRESETS } from '~/types/category';
import { scrollTop } from '~/utils/scrollTop';

interface IPresetsLayout {
    categoryName: ICategoryList;
}

interface IPresetsLayoutState {
    categories: ICategories;
    activePreset: string;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems: number[];
    bundles: IBundleList;
    sortedBy: ISortCategories;
}

const stateSelector = (state: State): IPresetsLayoutState => {
    return {
        categories: state.ReducerApp.categories,
        activePreset: state.ReducerApp.activePreset,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        bundles: state.ReducerApp.bundles,
        sortedBy: state.ReducerApp.sortedBy,
    };
};

const BUNDLES_PREVIEW_LIMIT = 4;

const PresetsLayouts = ({ categoryName }: IPresetsLayout) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IPresetsLayoutState>(stateSelector, equal);
    const category = state.categories[categoryName];
    const presets = category.filters?.presets || [];
    const activeSort = state.sortedBy?.[categoryName];

    if (!presets?.length) {
        return null;
    }

    const setFilterPreset = (presetName: string) => {
        dispatch(updateActiveFilterPreset(presetName));
    };

    const content = presets.map((preset) => {
        const isAll = preset.type === PRESETS.ALL;
        if (state.activePreset && state.activePreset !== preset.name) {
            return null;
        }

        const limit = state.activePreset || isAll ? null : preset.bundlesPreviewLimit || BUNDLES_PREVIEW_LIMIT;

        let bundles =
            preset.bundles?.filter((bundleId) => {
                return !!state.bundles[bundleId] && !state.bundles[bundleId].isPurchased;
            }) || [];

        if (!preset.bundles || !preset.bundles?.length || !bundles.length) {
            return null;
        }

        if (limit) {
            bundles = [...bundles].splice(0, limit);
        }

        if (activeSort?.method) {
            bundles = sortBundles(activeSort.name, activeSort.method, state.bundles, bundles);
        }

        return (
            <DefaultLayout
                key={`preset_${preset.name}`}
                title={preset.title}
                description={preset.description}
                bundles={bundles}
                showMoreButtonTitle={t('Полный список')}
                presetName={preset.name}
                isShowMoreButton={true}
                isNotRenderFilter={true}
                isLazy={true}
                onShowMoreButtonClick={() => {
                    setFilterPreset(preset.name);
                    scrollTop(0, true);
                }}
            />
        );
    });

    return <React.Fragment>{content}</React.Fragment>;
};

export default PresetsLayouts;
