import React from 'react';
import classNames from 'classnames';
import { State } from '~/Reducers';
import { KEYS_CODE } from '~/hooks/useKeyDown';
import { playButtonClickSound, playInputSound } from '~/api/WoWsClient';
import { CURRENCY } from '~/const';
import { formatNumber } from '~/utils/utils';
import { t } from '~/utils/localization';
import { Currencies } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { DivTooltip, Interpolate } from '@wg/wows-react-uikit';

import styles from './MrpsQuantity.scss';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import store from '~/Store';
import { spendResource } from '~/Actions/ActionMrps';
import { AnyAction } from 'redux';

const MAX_PROGRESS = 100;

export interface MrpsQuantityTooltips {
    plusButton?: React.ReactNode;
    lockedPlusButton?: React.ReactNode;
    minusButton?: React.ReactNode;
    lockedMinusButton?: React.ReactNode;
    mainTooltip?: React.ReactNode;
}

export interface MrpsQuantityCustomStyles {
    input?: string;
    wrapper?: string;
}

interface MrpsQuantityProps {
    resource: MrpsResource;
    disableChangeCallback?: (flag: boolean) => void;
}

export interface MrpsQuantityRef {
    increment?: () => void;
}

interface StateSelector {
    resourceSpent: number;
    resourceBalance: number;
    resourceInUse: number;
    currentProgress: number;
}

function stateSelector(state: State, resource: MrpsResource): StateSelector {
    const mrpsState = state.reducerMrps;
    const eventName = mrpsState.currentEvent.eventName;
    const accountData = mrpsState.account?.[eventName];
    const balanceData = state.ReducerAccount?.balance?.find((data) => data.currency === resource.name);
    return {
        resourceSpent: accountData?.resourcesSpent[resource.name] || 0,
        resourceBalance: (balanceData && balanceData.value) || 0,
        resourceInUse: mrpsState.currentEvent.resourcesInUse[resource.name] || 0,
        currentProgress: mrpsState.currentEvent.currentProgress || 0,
    };
}

const MrpsQuantity = React.forwardRef<MrpsQuantityRef, MrpsQuantityProps>(({ resource, disableChangeCallback }: MrpsQuantityProps, ref) => {
    const state = useSelector<State, StateSelector>((state) => stateSelector(state, resource), equal);
    const [quantity, setQuantity] = React.useState(state.resourceInUse / resource.amountPerPoint);
    const [manualInputValue, setManualInputValue] = React.useState(0);
    const [isFocused, setIsFocused] = React.useState(false);
    const [isManualInput, setIsManualInput] = React.useState(false);

    const inputRef: React.RefObject<HTMLInputElement> = React.useRef();
    const updateInputRef = React.useRef<ReturnType<typeof setTimeout>>();

    const defaultResourceLimit = React.useMemo(() => {
        return Math.min(resource.amountLimit || Infinity, Math.floor(MAX_PROGRESS / resource.baseProgressPoints) * resource.amountPerPoint);
    }, [resource.amountLimit, resource.amountPerPoint, resource.baseProgressPoints]);
    const accountResourceLimit = React.useMemo(() => {
        return Math.floor(state.resourceBalance / resource.amountPerPoint) * resource.amountPerPoint;
    }, [resource.amountPerPoint, state.resourceBalance]);
    const availableEventProgress = React.useMemo(() => {
        return MAX_PROGRESS - state.currentProgress;
    }, [state.currentProgress]);
    const availableDefaultResourceLimit = React.useMemo(() => {
        return defaultResourceLimit - state.resourceSpent;
    }, [defaultResourceLimit, state.resourceSpent]);
    const availableResourceLimit = React.useMemo(() => {
        return Math.min(availableDefaultResourceLimit, Math.floor(availableEventProgress / resource.baseProgressPoints) * resource.amountPerPoint + state.resourceInUse);
    }, [availableDefaultResourceLimit, availableEventProgress, resource.amountPerPoint, resource.baseProgressPoints, state.resourceInUse]);
    const maxAvailableResourceAmount = React.useMemo(() => {
        return Math.min(availableResourceLimit, accountResourceLimit);
    }, [accountResourceLimit, availableResourceLimit]);

    let inputValue: string | number = quantity;
    if (inputValue.toString().length && resource.amountPerPoint > 1) {
        inputValue *= resource.amountPerPoint;
    }

    const { isLockPlusButton, isLockMinusButton, isInputDisabled, disabledMessage } = React.useMemo(() => {
        const isPlusButtonDisabledByCompletion = availableEventProgress === 0;
        const isPlusButtonDisabledByProgressPoints = availableEventProgress < resource.baseProgressPoints;
        const isPlusButtonDisabledByResourceLimit = state.resourceSpent + state.resourceInUse === defaultResourceLimit;
        const isPlusButtonDisabledByResourceBalance = state.resourceInUse + resource.amountPerPoint > accountResourceLimit;

        const isLockPlusButton =
            isPlusButtonDisabledByCompletion ||
            isPlusButtonDisabledByProgressPoints ||
            isPlusButtonDisabledByResourceLimit ||
            isPlusButtonDisabledByResourceBalance ||
            +inputValue === maxAvailableResourceAmount;

        const isLockMinusButton = !inputValue;

        const isInputDisabled = resource.amountPerPoint > accountResourceLimit || (isLockPlusButton && isLockMinusButton);

        const disabledMessage =
            (isPlusButtonDisabledByCompletion && t('Выбрано достаточное количество ресурсов.')) ||
            ((isPlusButtonDisabledByProgressPoints || isPlusButtonDisabledByResourceLimit) && t('Использовано максимально-возможное количество ресурса')) ||
            (isPlusButtonDisabledByResourceBalance && t('Недостаточное количество ресурса'));

        return {
            isLockPlusButton,
            isLockMinusButton,
            isInputDisabled,
            disabledMessage,
        };
    }, [
        accountResourceLimit,
        availableEventProgress,
        defaultResourceLimit,
        inputValue,
        maxAvailableResourceAmount,
        resource.amountPerPoint,
        resource.baseProgressPoints,
        state.resourceInUse,
        state.resourceSpent,
    ]);

    const buttonPlusClassNames = classNames(styles.button, styles.plus, {
        [styles.lock]: isLockPlusButton,
    });
    const buttonMinusClassNames = classNames(styles.button, styles.minus, {
        [styles.lock]: isLockMinusButton,
    });
    const inputWrapperClassNames = classNames(styles.inputWrapper, {
        [styles.disabled]: isInputDisabled,
    });

    const isPremiumCurrency = React.useMemo(() => {
        return resource.name === Currencies.GOLD || resource.name === CURRENCY.WARSHIPS_PREMIUM;
    }, [resource.name]);

    const limitText = t('Не более %(resource)s');
    const useAllText = t('Использовать все');

    const changeQuantity = React.useCallback(
        (quantity: number) => {
            store.dispatch(spendResource(resource.name, quantity * resource.amountPerPoint) as unknown as AnyAction);
            setQuantity(quantity);
        },
        [resource.amountPerPoint, resource.name],
    );

    const updateValue = React.useCallback(
        (updatingValue: number | string, manualInput: boolean = false) => {
            if (isInputDisabled) {
                return;
            }

            const intUpdatingValue = parseInt(updatingValue.toString(), 10);

            // when typing from keyboard
            if (manualInput) {
                const newValue = Math.ceil(Math.max(Math.min(intUpdatingValue, maxAvailableResourceAmount), 0) / resource.amountPerPoint) || 0;

                if (newValue === quantity) {
                    return;
                }

                changeQuantity(newValue);
                return;
            }

            // when clicking on arrow buttons or arrow keys
            const newInputValue = intUpdatingValue * resource.amountPerPoint;
            if (newInputValue >= 0 && newInputValue <= maxAvailableResourceAmount) {
                changeQuantity(intUpdatingValue);
                return;
            }
        },
        [changeQuantity, isInputDisabled, maxAvailableResourceAmount, quantity, resource.amountPerPoint],
    );

    const plus = React.useCallback(() => {
        if (isInputDisabled) {
            return;
        }

        playButtonClickSound();

        if (manualInputValue) {
            updateValue(manualInputValue, true);
            setIsManualInput(false);
            setManualInputValue(0);

            return;
        }

        updateValue(quantity + 1);
    }, [isInputDisabled, manualInputValue, quantity, updateValue]);

    const minus = React.useCallback(() => {
        playButtonClickSound();

        if (manualInputValue) {
            updateValue(manualInputValue, true);
            setIsManualInput(false);
            setManualInputValue(0);

            return;
        }

        const newValue = Math.max(quantity - 1, 0);

        if (newValue === quantity) {
            return;
        }

        updateValue(newValue);
    }, [manualInputValue, quantity, updateValue]);

    const useAll = React.useCallback(() => {
        if (isInputDisabled) {
            return;
        }

        playButtonClickSound();

        const newValue = maxAvailableResourceAmount / resource.amountPerPoint;

        manualInputValue && setManualInputValue(0);
        isManualInput && setIsManualInput(false);

        updateValue(newValue);
    }, [isInputDisabled, isManualInput, manualInputValue, maxAvailableResourceAmount, resource.amountPerPoint, updateValue]);

    const keyUp = React.useCallback(
        (event?: React.KeyboardEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement;
            playInputSound();
            setManualInputValue(Math.min(parseInt(input.value.replace(/\D/g, ''), 10), maxAvailableResourceAmount));
            setIsManualInput(true);
        },
        [maxAvailableResourceAmount],
    );

    const keyDown = React.useCallback(
        (event?: React.KeyboardEvent<HTMLInputElement>) => {
            switch (event.keyCode) {
                case KEYS_CODE.UP:
                case KEYS_CODE.DOWN: {
                    event.preventDefault();

                    if (KEYS_CODE.UP === event.keyCode) {
                        plus();
                    } else if (KEYS_CODE.DOWN === event.keyCode) {
                        minus();
                    }

                    break;
                }
                case KEYS_CODE.BACKSPACE: {
                    event.preventDefault();

                    if (manualInputValue) {
                        setManualInputValue(parseInt(manualInputValue.toString().slice(0, -1), 10) || 0);
                    } else {
                        const input = event.target as HTMLInputElement;
                        setManualInputValue(parseInt(input.value.slice(0, -1), 10) || 0);
                    }

                    setIsManualInput(true);

                    break;
                }
                case KEYS_CODE.ENTER: {
                    event.preventDefault();
                    const input = event.target as HTMLInputElement;
                    input.blur();

                    if (isManualInput) {
                        updateValue(manualInputValue, true);
                        setManualInputValue(0);
                        setIsManualInput(false);
                    }

                    break;
                }
                case KEYS_CODE.ESC: {
                    event.key !== undefined && playInputSound;

                    break;
                }

                default:
                    break;
            }
        },
        [isManualInput, manualInputValue, minus, plus, updateValue],
    );

    const onBlur: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            if (!event?.target.value.length) {
                updateValue(1);
            }

            setIsFocused(false);
        },
        [updateValue],
    );

    const onFocus: React.FocusEventHandler<HTMLInputElement> = React.useCallback(() => {
        setIsFocused(true);
    }, []);

    React.useEffect(() => {
        updateInputRef.current && clearTimeout(updateInputRef.current);
        if (isManualInput) {
            updateInputRef.current = setTimeout(() => {
                updateValue(manualInputValue, true);
                setManualInputValue(0);
                setIsManualInput(false);
            }, 1000);
        }
    }, [isManualInput, manualInputValue, updateValue]);

    React.useEffect(() => {
        if (!state.resourceInUse && quantity) {
            setQuantity(0);
        }
    }, [quantity, state.resourceInUse]);

    React.useEffect(() => {
        disableChangeCallback?.(isInputDisabled);
    }, [disableChangeCallback, isInputDisabled]);

    React.useImperativeHandle(
        ref,
        () => {
            return {
                increment: () => {
                    plus();
                },
            };
        },
        [plus],
    );

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.disabled]: isInputDisabled,
            })}
        >
            <div
                className={classNames(inputWrapperClassNames, {
                    [styles.inputWrapper_focused]: isFocused,
                })}
            >
                <DivTooltip fixed={true} tooltipBody={isInputDisabled ? <div className={styles.inputTooltipBody}>{disabledMessage}</div> : null}>
                    <div className={styles.contentWrapper}>
                        <input
                            // @ts-ignore
                            // eslint-disable-next-line react/no-unknown-property
                            noimesupport={'true'}
                            className={classNames(styles.input, styles.inputCurrencyTextColor, {
                                [styles.inputPremiumCurrencyTextColor]: isPremiumCurrency,
                            })}
                            value={formatNumber(isManualInput ? manualInputValue : inputValue)}
                            disabled={isInputDisabled}
                            type="text"
                            onInput={keyUp}
                            onKeyDown={keyDown}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onMouseDown={() => false}
                            onPaste={() => false}
                            ref={inputRef}
                        />
                        <div className={styles.buttonsWrapper}>
                            <DivTooltip className={styles.buttonTooltipWrapper} fixed={true} tooltipBody={isLockPlusButton ? <div className={styles.inputTooltipBody}>{disabledMessage}</div> : null}>
                                <button className={buttonPlusClassNames} onClick={() => !isLockPlusButton && plus()} />
                            </DivTooltip>
                            <button className={buttonMinusClassNames} onClick={() => !isLockMinusButton && minus()} />
                        </div>
                    </div>
                </DivTooltip>
            </div>
            <div className={styles.additionalTextWrapper}>
                <div
                    className={classNames(styles.useAllWrapper, {
                        [styles.useAllWrapper_disabled]: isLockPlusButton,
                    })}
                >
                    <button className={styles.useAll} onClick={useAll}>
                        {useAllText}
                    </button>
                </div>
                <div
                    className={classNames(styles.resourceLimitWrapper, {
                        [styles.resourceLimitWrapper_alwaysOnDisplay]: isLockPlusButton,
                    })}
                >
                    <Interpolate
                        className={styles.resourceLimit}
                        str={limitText}
                        resource={
                            <div className={styles.resourceInfo}>
                                <WoWSEntity type={resource.name} amount={availableDefaultResourceLimit} />
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
});

export default MrpsQuantity;
