import * as React from 'react';
import styles from './Commanders.scss';
import classNames from 'classnames';
import { interpolate, ngettext, t } from '~/utils/localization';
import { componentHelper } from '@wg/wows-entities';
import { ManagerData } from '~/core/ManagerData';

interface ICommanderPerks {
    item: IItemCommonData;
    className?: string;
    isShort?: boolean;
}

export const CommanderPerks = (props: ICommanderPerks): any => {
    const item = props.item;
    const shortText = ngettext('%(count)s очко навыка', '%(count)s очков навыков', item.customisation.points);
    let text: string;

    const crew = ManagerData.getCrew(item.identifier);

    if (props.isShort) {
        text = interpolate(shortText, { count: item.customisation.points }, true, false);
    } else {
        text = componentHelper.getCrewFullTitle(null, item.customisation.points, crew?.type, crew?.l10nRarity);
    }

    return (
        <div className={styles.wrapper}>
            {!!text && (
                <div className={classNames(styles.title, props.className, styles[crew?.type])}>
                    <span>{text}</span>
                </div>
            )}
        </div>
    );
};

interface ICommanderName {
    name: string;
    className?: string;
    id: number;
}

export const CommanderName = (props: ICommanderName) => {
    const crew = ManagerData.getCrew(props.id);

    return (
        <div className={classNames(styles.title, styles[crew?.type], props.className)}>
            <span dangerouslySetInnerHTML={{ __html: props.name }} />
        </div>
    );
};
