import * as React from 'react';
import styles from './Filters.scss';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import { getFiltersInfo } from '~/settings/filtersMap';
import FilterVariants from './FilterVariants';
import useClickAway from '~/hooks/useClickAway';
import Tag from '../Tag/Tag';

export type FILTERS_TYPE = Partial<Record<FILTER_INFO_NAME, any[]>>;

interface IFilters {
    iconClassName?: string;
    className?: string;
    filters: FILTERS_TYPE;
    activeFilters: FILTERS_TYPE;
    onChangeFilter: (filterName: FILTER_INFO_NAME, filterValue: any) => void;
    closeAfterChangeFilter?: boolean;
    column?: number;
    children?: React.ReactChild;
}

const FilterColumns = ({ filters, activeFilters, onChangeFilter, column }: IFilters) => {
    const filtersInfo = getFiltersInfo();

    return (
        <>
            {Object.keys(filters).map((filterName: FILTER_INFO_NAME) => {
                const filter = filtersInfo[filterName];
                const classesFilterColumn = classNames(styles.filterColumn, styles[filterName]);

                if (filters[filterName].length < 2) {
                    return;
                }

                return (
                    <div className={classesFilterColumn} key={`filter_column_${filterName}`}>
                        <div className={styles.filterColumnTitle}>{filter.title}</div>
                        <div className={styles.filterColumnBody}>
                            <FilterVariants
                                filterName={filterName}
                                activeFiltersValues={activeFilters[filterName] || []}
                                values={filters[filterName]}
                                onChangeFilter={onChangeFilter}
                                column={column}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

const Filters = ({ className, iconClassName, filters, activeFilters, onChangeFilter, children, closeAfterChangeFilter, column }: IFilters) => {
    const refFilters = React.useRef<HTMLDivElement>();
    const [isVisiblePopup, toogleVisibilityPopup] = React.useState<boolean>(false);

    const classesIconText = classNames(
        styles.iconText,
        {
            [styles.active]: isVisiblePopup,
        },
        iconClassName,
    );

    useClickAway(refFilters, (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (refFilters.current.contains(target)) {
            return;
        }

        toogleVisibilityPopup(false);
    });

    return (
        <div className={classNames(styles.wrapper, className)} ref={refFilters}>
            <div className={classesIconText} onClick={() => toogleVisibilityPopup(!isVisiblePopup)}>
                {t('Фильтры')}
            </div>
            {children}
            {isVisiblePopup && (
                <div className={styles.filtersPopup}>
                    <div className={styles.filtersColumns}>
                        <FilterColumns
                            filters={filters}
                            column={column}
                            activeFilters={activeFilters}
                            onChangeFilter={(filterName: FILTER_INFO_NAME, filterValue: any) => {
                                closeAfterChangeFilter && toogleVisibilityPopup(false);
                                onChangeFilter(filterName, filterValue);
                            }}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <Tag
                            label={t('Применить')}
                            onClick={() => toogleVisibilityPopup(!isVisiblePopup)}
                            classNames={{
                                tag: styles.buttonApply,
                            }}
                            isActiveFiltersTag={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Filters;
