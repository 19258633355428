import * as React from 'react';
import styles from './AnimationIcon.scss';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import { t } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { playButtonClickSound } from '~/api/WoWsClient';

interface IAnimationIcon {
    onChange: (isEnabled: boolean) => void;
    defaultValue?: boolean;
    className?: string;
}

const AnimationIcon = ({ onChange, defaultValue, className }: IAnimationIcon) => {
    const [isTurnOn, setTurnOn] = React.useState<boolean>(defaultValue !== undefined ? defaultValue : true);

    const onClick = () => {
        playButtonClickSound();
        setTurnOn(!isTurnOn);
        onChange?.(!isTurnOn);
    };

    React.useEffect(() => {
        if (isTurnOn !== defaultValue) {
            setTurnOn(defaultValue);
        }
    }, [defaultValue]);

    const classes = classNames(styles.icon, className, {
        [styles.off]: !isTurnOn,
    });

    return <DivTooltip className={classes} onClick={onClick} tooltipBody={<DefaultTooltip text={!isTurnOn ? t('Включить анимацию') : t('Выключить анимацию')} />} style={{ cursor: 'pointer' }} />;
};

export default AnimationIcon;
