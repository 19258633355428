import * as React from 'react';
import classNames from 'classnames';

interface ISkeleton {
    className?: string;
}

const Skeleton = ({ className }: ISkeleton) => {
    return <div className={classNames('armory-skeleton', className)} />;
};

export default Skeleton;
