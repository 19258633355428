import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Account from '~/account/Account';
import { State } from '~/Reducers';
import { getProgressiveDiscountFromBundle, getPurchaseLimit, getSinglePurchaseMaxQuantity } from '~/utils/bundles';
import { items as ITEMS, assets as ASSETS } from '@wg/wows-entities/const';
import Quantity, { IQuantityTooltips } from '~/components/Quantity/Quantity';
import { calcMaxQuantityByBalance, calculateBundlePrices, isQuantityIncrementOverdraftAvailable } from '~/utils/purchase';
import { updateQuantity } from '~/Actions/ActionApp';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { t } from '~/utils/localization';

interface IQuantityContainer {
    bundle: IBundle;
    offersLimit?: number;
    fromPopup?: boolean;
    tooltips?: IQuantityTooltips;
    idDisabled?: boolean;
    onUpdateHandler?: (n: number) => void;
}

interface IStateSelector {
    activeCouponId: number;
    coupons: ICoupon[];
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    balance: IBalance;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        activeCouponId: state.ReducerAccount.activeCouponId,
        coupons: state.ReducerAccount.coupons,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        balance: state.ReducerAccount.balance,
    };
};

export const ALLOWED_LIST_WITH_MANY_ITEMS: string[] = [ITEMS.SIGNAL, ITEMS.CAMOUFLAGE, ITEMS.LOOTBOX, ITEMS.CAMO_BOOST, ITEMS.MULTI_BOOST];

function toPositiveNumberOrInfinity(value: any): number {
    value = parseInt(value);
    if (!isFinite(value) || value < 0) return Infinity;
    return value;
}

function getLimit(...values: any[]) {
    return Math.min(...values.map(toPositiveNumberOrInfinity));
}

const QuantityContainer = ({ bundle, offersLimit, fromPopup, tooltips, idDisabled, onUpdateHandler }: IQuantityContainer) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);
    const primaryItem = bundle.primaryItem;
    const isEnabledIncrementByAmount = primaryItem && ALLOWED_LIST_WITH_MANY_ITEMS.includes(primaryItem.type);
    const isEditable = !(primaryItem?.amount > 1 && !(Object.values(ASSETS) as string[]).includes(primaryItem?.type));

    const bundleLimit = React.useMemo(() => {
        const defaultLimit = isFinite(offersLimit) ? toPositiveNumberOrInfinity(offersLimit) : getLimit(getPurchaseLimit(bundle), bundle.singlePurchaseMaxQuantity, getSinglePurchaseMaxQuantity());
        const calcLimit = calcMaxQuantityByBalance(bundle, state.balance, defaultLimit);
        return Math.min(calcLimit, defaultLimit);
    }, [offersLimit, bundle, state.balance]);

    React.useEffect(() => {
        return () => {
            if (!fromPopup) {
                dispatch(updateQuantity(bundle.id, null, null));
            }
        };
    }, []);

    const timeoutId = React.useRef<NodeJS.Timeout>(null);

    function onUpdate(value: number) {
        clearTimeout(timeoutId.current);
        const prices = calculateBundlePrices(bundle, state.balance, value, activeCoupon?.discount, getProgressiveDiscountFromBundle(bundle, value));
        timeoutId.current = setTimeout(() => {
            const _quantity = bundle.quantityData?.quantity;
            if (_quantity !== +value) {
                dispatch(updateQuantity(bundle.id, value, prices));
            }
        }, 45);
        onUpdateHandler?.(value);
    }

    function checkAbilityIncrement(quantity: number) {
        const prices = calculateBundlePrices(bundle, state.balance, quantity, activeCoupon?.discount, getProgressiveDiscountFromBundle(bundle, quantity));
        if (Account.canIncrementQuantity(prices, state.balance, bundle, activeCoupon?.discount)) {
            return true;
        }
        return isQuantityIncrementOverdraftAvailable(prices);
    }

    const step = isEnabledIncrementByAmount ? primaryItem?.amount : 1;

    return (
        <Quantity
            checkAbilityIncrement={checkAbilityIncrement}
            max={bundleLimit * step}
            isEditable={isEditable}
            step={step}
            onUpdate={onUpdate}
            isAllowedFirstIncrement={checkAbilityIncrement(2)}
            value={bundle.quantityData?.quantity}
            isDisabled={idDisabled || !!state.activeCouponId}
            tooltips={{
                mainTooltip: state.activeCouponId ? <DefaultTooltip text={t('Чтобы изменить количество товара, удалите купон')} /> : null,
                ...(tooltips || {}),
            }}
        />
    );
};

export default QuantityContainer;
