import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './Notifications.scss';
import useClickAway from '~/hooks/useClickAway';
import { t } from '~/utils/localization';
import { useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup, setCurrentNotificationName } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import classNames from 'classnames';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { localizedShortDate } from '~/utils/utils';
import { filterNotifications, getUnreadNotifications } from '~/utils/notifications';
import { State } from '~/Reducers';
import Label from '~/components/Label/Label';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { PreCacheImage } from '~/utils/preCacheImage';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { NotificationDisplay } from '~/types/notifications';
import { playDropdownClickSound, playButtonDialogClickSound } from '~/api/WoWsClient';

interface IStateSelector {
    accountId: number;
    viewedNotifications: string[];
    notifications: INotification[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        viewedNotifications: state.ReducerAccount.viewedNotifications,
        notifications: state.ReducerAccount.notifications,
    };
};

const NotificationsWrapper = () => {
    const dispatch = useDispatch();
    const ref = React.useRef();
    const accountState = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isVisible, setVisible] = React.useState(false);

    const arrayNotifications = filterNotifications(accountState.notifications) || [];

    const changeVisible = () => {
        playDropdownClickSound();

        setVisible(!isVisible);
        if (!isVisible) {
            dwhExport.send(DWH_EVENTS.OPEN_NOTIFICATIONS);
        }
    };

    useClickAway(ref, (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.classList.contains(styles.notificationIcon) || target.classList.contains(styles.notificationCounter)) {
            return;
        }

        setVisible(false);
    });

    const openPopup = (notification: INotification) => {
        playButtonDialogClickSound();
        setVisible(!isVisible);
        if (notification.display === NotificationDisplay.SPECIAL) {
            dispatch(setCurrentNotificationName(notification.name));
        } else {
            dispatch(
                changeVisiblePopup(POPUPS_NAME.INFO_SCREEN_POPUP, true, {
                    name: notification.name,
                }),
            );
        }
    };

    const counterUnreadNotifications = getUnreadNotifications();

    const iconClassNames = classNames(styles.notificationIcon, {
        [styles.isActive]: isVisible,
    });

    const instancePreCacheImageLoader = new PreCacheImage();

    return (
        <div className={styles.notificationWrapper}>
            <GuideDecorator names={[GUIDE_NAMES.guide_notifications]}>
                <DivTooltip tooltipBody={<DefaultTooltip text={t('Уведомления и важные сообщения')} />}>
                    <div className={iconClassNames} onClick={changeVisible}>
                        {!!counterUnreadNotifications && (
                            <div className={styles.notificationCounter}>
                                <Label text={counterUnreadNotifications} isVisible={true} />
                            </div>
                        )}
                    </div>
                </DivTooltip>
            </GuideDecorator>
            {isVisible && (
                <div className={styles.notificationWrapperContent} ref={ref}>
                    <div className={styles.notificationWrapperTitle}>{t('Все уведомления')}</div>
                    <div className={styles.notificationWrapperItems}>
                        {arrayNotifications.length ? (
                            arrayNotifications.map((notification: INotification) => {
                                const description = notification.description;

                                if (notification.background) {
                                    instancePreCacheImageLoader.add(notification.background);
                                }

                                const style = {} as React.CSSProperties;

                                if (notification.previewImage) {
                                    style.backgroundImage = `url(${notification.previewImage})`;
                                }

                                const date = new Date(notification.dateCreated);
                                const isVisibleDate = date.getTime() < Date.now();

                                const notificationClassNames = classNames(styles.notificationItem, {
                                    [styles.read]: accountState.viewedNotifications.includes(notification.name),
                                    [styles.customBg]: !!notification.previewImage,
                                });

                                return (
                                    <div
                                        key={notification.name}
                                        className={notificationClassNames}
                                        onClick={() => {
                                            openPopup(notification);
                                        }}
                                    >
                                        <div className={styles.notificationContent} style={style}>
                                            <div className={styles.notificationItemHeader}>
                                                <div className={styles.notificationItemTitle} dangerouslySetInnerHTML={{ __html: notification.title }} />
                                                <div className={styles.notificationItemDescription} dangerouslySetInnerHTML={{ __html: description }} />
                                            </div>
                                            {isVisibleDate && <div className={styles.notificationItemDate}>{localizedShortDate(date)}</div>}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className={styles.notificationEmpty}>{t('У вас нет уведомлений')}</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationsWrapper;
