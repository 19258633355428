import * as React from 'react';
import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import Discount, { DiscountSize } from '~/components/Discount/Discount';
import ProgressiveDiscountTooltip from '~/components/ProgressiveDiscount/ProgressiveDiscountTooltip';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import styles from './RandomBundleHistoryCarousel.scss';
import { interpolate, ngettext, t } from '~/utils/localization';
import RandomBundleShinyAnimation from '~/Layouts/RandomBundlePage/RandomBundleHistoryCarousel/RandomBundleShinyAnimation';
import { getSinglePurchaseLimitTextForRandom } from '~/utils/bundles';

interface IRandomBundleHistoryCarouselEmptyItem {
    isPeriodic?: boolean;
    amount?: number;
    progressiveDiscount?: DiscountDiapason;
    onClick?: (index: number) => void;
    isLastItem?: boolean;
    isDisabledProgressivePurchase?: boolean;
    parentRandomBundle: IBundle;
    bundle: IBundle;
    isSelected?: boolean;
    shinyAnimation: RandomBundleShinyAnimationType;
    isAvailablePartialPurchase?: boolean;
}

const RandomBundleHistoryCarouselEmptyItem = ({
    isPeriodic,
    amount,
    progressiveDiscount,
    isSelected,
    onClick,
    isLastItem,
    isDisabledProgressivePurchase,
    bundle,
    parentRandomBundle,
    shinyAnimation,
    isAvailablePartialPurchase,
}: IRandomBundleHistoryCarouselEmptyItem) => {
    const tooltipText = isPeriodic
        ? [t('Новый периодичный случайный набор будет доступен только в случае получения предыдущего.')]
        : [t('Новый случайный набор будет доступен после получения предыдущего.')];

    if (bundle.singlePurchaseMaxQuantity && isAvailablePartialPurchase) {
        tooltipText.push(getSinglePurchaseLimitTextForRandom(bundle.singlePurchaseMaxQuantity));
    }

    const tooltipBody =
        isPeriodic || isDisabledProgressivePurchase ? (
            <DefaultTooltip text={tooltipText} />
        ) : !!parentRandomBundle.progressivePurchase?.discountDiapasons?.length ? (
            <ProgressiveDiscountTooltip
                isFullBundle={isLastItem}
                data={parentRandomBundle.progressivePurchase.discountDiapasons}
                parentBundleTitle={parentRandomBundle.title}
                childBundleTitle={bundle.title}
                singleLimit={parentRandomBundle.singlePurchaseMaxQuantity}
                amount={amount}
            />
        ) : null;

    const text = isLastItem
        ? t('Все доступные наборы')
        : interpolate(ngettext('{count} случайный набор', '{count} случайных набора', amount), {
              count: amount,
          });

    const isEnabledShinyAnimation = shinyAnimation?.isEnabled !== false && isSelected;

    const classesInfo = classNames(styles.itemInfo, {
        [styles.hidden]: isDisabledProgressivePurchase,
    });

    const randomIcons = parentRandomBundle.icons as RandomBundleIcons;

    return (
        <DivTooltip
            tooltipBody={tooltipBody}
            className={classNames(styles.itemContent, {
                [styles.selected]: isSelected,
                [styles.hover]: !isDisabledProgressivePurchase,
            })}
            onClick={() => !isDisabledProgressivePurchase && onClick?.(amount)}
            style={{ cursor: !isDisabledProgressivePurchase ? 'pointer' : 'initial' }}
        >
            <div className={styles.imageWrapper}>
                <RandomBundleShinyAnimation isEnabled={isEnabledShinyAnimation} shinyAnimation={shinyAnimation} />
                <div
                    className={classNames(styles.image, styles.imageCube, {
                        [styles.imageCubeWithTimer]: isPeriodic,
                        [styles.imageCube_custom]: !!randomIcons.randomDiceImage,
                    })}
                    style={
                        randomIcons.randomDiceImage && {
                            backgroundImage: `url(${randomIcons.randomDiceImage})`,
                        }
                    }
                />
            </div>
            <div className={classesInfo}>
                <div className={styles.itemTitleWrapper}>
                    {!!progressiveDiscount?.discount && (
                        <div className={styles.discountField}>
                            <Discount discount={progressiveDiscount.discount} size={DiscountSize.SMALL} />
                        </div>
                    )}
                    <div className={styles.itemTitle}>{text}</div>
                </div>
            </div>
        </DivTooltip>
    );
};

export default React.memo(RandomBundleHistoryCarouselEmptyItem);
