import styles from './TreasureChainPopup.scss';
import { Popup, PopupBody, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';
import * as React from 'react';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PostProgress from '~/components/Popups/Treasure/TreasureChainPopup/PostProgress/PostProgress';
import TreasureChainGroup from '~/components/Popups/Treasure/TreasureChainPopup/TreasureChainGroup/TreasureChainGroup';
import { State } from '~/Reducers';

type Props = {
    chains: Array<TreasureChain>;
    box: string;
    background?: string;
};

type StateSelector = {
    inventory: InventoryState;
};

function stateSelector(state: State): StateSelector {
    return {
        inventory: state.ReducerAccount.inventory,
    };
}

export default function TreasureChainPopup({ chains, box, background }: Props) {
    const state = useSelector<State, StateSelector>((state) => stateSelector(state), shallowEqual);
    const dispatch = useDispatch();
    const close = () => dispatch(changeVisiblePopup(null));
    const allChainsWeights = chains.reduce((sum, chain) => sum + chain.weight, 0);

    return (
        <Popup onClose={close} popupClassName={styles.wrapper} renderEscButton style={{ backgroundImage: `url(${background})` }}>
            <PopupHeader title={t('Возможные награды')} className={styles.header} />
            <PopupBody className={styles.body}>
                <div className={styles.chains}>
                    {chains.map((chain, i) => (
                        <div className={styles.chain}>
                            <TreasureChainGroup key={i} chain={chain} allWeights={allChainsWeights} box={box} background={background} inventory={state.inventory} />
                            {chain.filler ? <PostProgress reward={chain.filler} inventory={state.inventory} background={background} /> : null}
                        </div>
                    ))}
                </div>
            </PopupBody>
        </Popup>
    );
}
