import * as React from 'react';
import styles from './AuctionLotVisitCard.scss';
import AuctionLotHeader from '~/components/Auction/AuctionHeader';
import classNames from 'classnames';
import AuctionCurrentBid from '~/components/AuctionCurrentBid/AuctionCurrentBid';

interface IAuctionLotVisitCard {
    lot: ILot;
    bid?: IBids;
    className?: string;
    imageClassName?: string;
    titleClassName?: string;
    ComponentWrapper?: React.FunctionComponent;
}

const AuctionLotVisitCard = ({ lot, bid, className, imageClassName, titleClassName, ComponentWrapper }: IAuctionLotVisitCard) => {
    const classesItem = classNames(styles.item, className);

    const Div = ({ children, className }: { children: any; className?: string }) => {
        return <div className={className}>{children}</div>;
    };

    const Wrapper = ComponentWrapper ? ComponentWrapper : Div;

    return (
        <Wrapper className={classesItem}>
            <div className={classNames(styles.imageWrapper, imageClassName)}>
                <img src={lot.purchasePreviewImageUrl} className={styles.image} />
            </div>
            <div className={classNames(styles.title, titleClassName)}>
                <AuctionLotHeader {...lot} />
            </div>
            {bid && (
                <div className={styles.bid}>
                    <AuctionCurrentBid bid={bid} className={styles.bidCurrency} />
                </div>
            )}
        </Wrapper>
    );
};

export default AuctionLotVisitCard;
