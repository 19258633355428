import styles from './SantaPage.scss';
import * as React from 'react';
import { markShownWelcomeGiftScreen, removeGift, takeGift } from '~/utils/gift';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { SECRET_SANTA_GIFT_NAME } from '~/const';
import Account from '~/account/Account';
import { redirectToLogin } from '~/utils/utils';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import equal from 'fast-deep-equal/react';

interface SantaSecretGiftSelector {
    gift: IGift;
}

function stateSelector(state: State): SantaSecretGiftSelector {
    return {
        gift: state.ReducerAccount.gifts?.secretBundles?.find((bundle) => bundle.name === SECRET_SANTA_GIFT_NAME),
    };
}

export default function SantaSecretGift() {
    const dispatch = useDispatch();
    const { gift } = useSelector<State, SantaSecretGiftSelector>(stateSelector, equal);

    async function takeSecretGift() {
        if (!Account?.getAccount()?.id) {
            return redirectToLogin();
        }
        dispatch(changeVisiblePopup(POPUPS_NAME.GIFT_ACCEPT, true, { gift: gift }));
    }

    if (!gift) return null;
    return <div className={styles.secretGift} onClick={takeSecretGift}></div>;
}
