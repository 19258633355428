import { postMrpsProgress } from '~/api/mrps';
import store from '~/Store';
import { resetMrpsCurrentEvent, saveMrpsAccountData } from '~/Actions/ActionMrps';
import { AnyAction } from 'redux';

export default class MrpsProcessor {
    eventName: MrpsEvent['name'];

    constructor(eventName: MrpsEvent['name']) {
        this.eventName = eventName;
    }

    async buy(resources: MrpsResourcesSpent) {
        // TODO: add check for correct purchase
        const resourcesKeys = Object.keys(resources);
        const filteredResources = {} as MrpsResourcesSpent;
        for (let i = 0; i < resourcesKeys.length; i++) {
            const resourceAmount = resources[resourcesKeys[i]];
            if (resourceAmount) {
                filteredResources[resourcesKeys[i]] = resourceAmount;
            }
        }

        const accountData = await postMrpsProgress({
            eventName: this.eventName,
            resourcesSpent: filteredResources,
        });

        // TODO: add check for errors

        store.dispatch(saveMrpsAccountData(accountData));
        store.dispatch(resetMrpsCurrentEvent(this.eventName) as unknown as AnyAction);
    }
}
