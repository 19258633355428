import * as React from 'react';
import styles from './Camouflages.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { items } from '@wg/wows-entities/const';

interface ICamouflagesPerks {
    item: IBundleEntity;
    className?: string;
    isShort?: boolean;
}

export const isPermanentCamouflage = (type: string) => {
    return [items.PERMOFLAGES, items.SKIN, items.STYLE, items.MSKIN].includes(type as items);
};

export const isCamouflage = (type: string) => {
    return [items.CAMOUFLAGE].includes(type as items);
};

const CamouflagesPerks = (props: ICamouflagesPerks): any => {
    const item = props.item;
    const camouflageTypeTitle = isPermanentCamouflage(item.type) ? t('Постоянный камуфляж') : t('Расходуемый камуфляж');

    return (
        <div className={styles.wrapper}>
            {item.customisation && (
                <div className={classNames(styles.permanent, styles.camouflage, props.className)}>
                    <span>{camouflageTypeTitle}</span>
                </div>
            )}
        </div>
    );
};

export default CamouflagesPerks;
