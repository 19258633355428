export const SAVE_MRSP_EVENTS = 'SAVE_MRSP_EVENTS';
export interface ISaveMrpsEvents {
    type: typeof SAVE_MRSP_EVENTS;
    events: MrpsEvent[];
}

export const SAVE_MRSP_ACCOUNT_DATA = 'SAVE_MRSP_ACCOUNT_DATA';
export interface ISaveMrpsAccountData {
    type: typeof SAVE_MRSP_ACCOUNT_DATA;
    account: MrpsAccountState;
}

export const UPDATE_MRPS_ACCOUNT_DATE = 'UPDATE_MRPS_ACCOUNT_DATE';
export interface IUpdateMrpsAccountData {
    type: typeof UPDATE_MRPS_ACCOUNT_DATE;
    account: MrpsAccountState;
}

export const RESET_MRPS_CURRENT_EVENT = 'RESET_MRPS_CURRENT_EVENT';
export interface IResetMrpsCurrentEvent {
    type: typeof RESET_MRPS_CURRENT_EVENT;
    currentEvent: MrpsCurrentEventState;
}

export const SPEND_MRPS_RESOURCE = 'SPEND_MRPS_RESOURCE';
export interface ISpendMrpsResource {
    type: typeof SPEND_MRPS_RESOURCE;
    currentProgress: number;
    resourcesSpent: MrpsResourcesSpent;
    availableRewards: MrpsLoadedReward[];
}

export const RESET_SPENDED_MRPS_RESOURCES = 'RESET_SPENDED_MRPS_RESOURCES';
export interface IResetSpendedMrpsResources {
    type: typeof RESET_SPENDED_MRPS_RESOURCES;
    resetedCurrentProgress: number;
    resetedResourceInUse: MrpsResourcesSpent;
}

export const UPDATE_MRPS_EVENTS_REWARDS = 'UPDATE_MRPS_EVENT_REWARDS';
export interface IUpdateMrpsEventsRewards {
    type: typeof UPDATE_MRPS_EVENTS_REWARDS;
    updatedEvents: MrpsEventWithUpdatedRewards[];
}

export const UPDATE_MRPS_INFO_BLOCK_CONTENT = 'UPDATE_MRPS_INFO_BLOCK_CONTENT';
export interface IUpdateMrpsInfoBlockContent {
    type: typeof UPDATE_MRPS_INFO_BLOCK_CONTENT;
    currentInfo: MrpsLoadedReward;
}

export const TOGGLE_EXCHANGE_MENU = 'TOGGLE_EXCHANGE_MENU';
export interface ITogggleExchangeMenu {
    type: typeof TOGGLE_EXCHANGE_MENU;
    isExchangeMenuOpen: MrpsExchangeMenuStatus;
}

export const TOGGLE_ACTIVE_EXCHANGE_TRANSACTION = 'TOGGLE_ACTIVE_EXCHANGE_TRANSACTION';
export interface IToggleActiveExchangeTransaction {
    type: typeof TOGGLE_ACTIVE_EXCHANGE_TRANSACTION;
    hasPendingTransactions: boolean;
}

export const TOGGLE_NEXT_RENDER_WITHOUT_RESET_STATE = 'TOGGLE_NEXT_RENDER_WITHOUT_RESET_STATE';
export interface IToggleNextRenderWithoutReset {
    type: typeof TOGGLE_NEXT_RENDER_WITHOUT_RESET_STATE;
    isNextRenderWithoutReset: boolean;
}

export type MrpsAction =
    | ISaveMrpsEvents
    | ISaveMrpsAccountData
    | IResetMrpsCurrentEvent
    | ISpendMrpsResource
    | IResetSpendedMrpsResources
    | IUpdateMrpsEventsRewards
    | IUpdateMrpsInfoBlockContent
    | ITogggleExchangeMenu
    | IToggleActiveExchangeTransaction
    | IToggleNextRenderWithoutReset;
