import gql from 'graphql-tag';

export const dollIds = gql`
    query DogTagComponents($ids: [String], $lang: String) {
        dogTagComponents(componentIds: $ids, lang: $lang) {
            id
            isPatch
            type
            description
        }
    }
`;
