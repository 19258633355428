import { updateAnimationStatusCategory as updateGlobalAnimationStatus } from '~/Actions/ActionApp';
import { LocalStorageHelper } from '~/utils/storage';
import { devModeKeyMap } from '~/utils/devMode';
import Account from '~/account/Account';
import store from '~/Store';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { isEmptyObject } from '~/utils/utils';
import { SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import { CategoryType } from '~/types/category';

export const isDisabledAnimationByName = (name: string) => {
    const flag = LocalStorageHelper.get(Account.getLSKey(`animation:${name}`));
    return flag === true;
};

export const hasAnimationSetting = (name: string) => {
    return LocalStorageHelper.get(Account.getLSKey(`animation:${name}`)) !== null;
};

export const updateAnimationStatusByName = (name: string, isDisabled = true) => {
    dwhExport.send(DWH_EVENTS.CHANGE_ANIMATION_STATUS, {
        name: name,
        status: !isDisabled ? 'on' : 'off',
    });
    LocalStorageHelper.set(Account.getLSKey(`animation:${name}`), isDisabled);
    store.dispatch(updateGlobalAnimationStatus(name, isDisabled));
};

export const isEnabledAnimationByCategory = (category: ICategory, bundle?: IBundle) => {
    if (!isEmptyObject(bundle?.videoBackground || {})) {
        return true;
    }

    if (!isEmptyObject(category?.videoBackground || {})) {
        return true;
    }

    if (category.type === CategoryType.MRPS) {
        const eventData = store.getState().reducerMrps.events.find((event) => event.name === category.name);
        if (!isEmptyObject(eventData?.videoBackground || {})) {
            return true;
        }
    }

    return false;
};

export const getAnimationStatusByName = (category: ICategory, bundle?: IBundle, disableAllAnimation?: boolean) => {
    if (!category && !bundle) {
        return false;
    }

    if (bundle && !isEmptyObject(bundle.videoBackground) && !disableAllAnimation) {
        return !isDisabledAnimationByName(bundle.name);
    }

    return !isDisabledAnimationByName(category.name);
};

export const isDisabledVideo = () => {
    return LocalStorageHelper.get(devModeKeyMap.disableVideo);
};
