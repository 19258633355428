import {
    DO_PURCHASE,
    IResetTreasureCurrentBoxState,
    ISaveTreasureAccountData,
    ISaveTreasureBoxes,
    IShowTreasureAnimation,
    ITreasureDoPurchase,
    RESET_TREASURE_CURRENT_BOX_STATE,
    SAVE_TREASURE_ACCOUNT_DATA,
    SAVE_TREASURE_BOXES,
    TREASURE_SHOW_ANIMATION,
} from '~/Actions/ActionTreasureType';
import { ThunkAction } from 'redux-thunk';
import { State } from '~/Reducers';

export function saveTreasureBoxes(masterBoxes: Array<TreasureMasterBox>): ISaveTreasureBoxes {
    masterBoxes.forEach((box, i) => {
        box.currencies = Object.keys(box.prices);
    });
    return {
        type: SAVE_TREASURE_BOXES,
        masterBoxes,
    };
}

export function saveTreasureAccountData(account: TreasureAccountData): ISaveTreasureAccountData {
    return {
        type: SAVE_TREASURE_ACCOUNT_DATA,
        account,
    };
}

export function resetCurrentMasterBox(masterBoxName: TreasureMasterBox['name']): ThunkAction<void, State, any, IResetTreasureCurrentBoxState> {
    return (dispatch, getState) => {
        const boxes = getState().ReducerTreasure.boxes;
        const currentBox = boxes.find((box) => box?.name === masterBoxName);

        dispatch({
            type: RESET_TREASURE_CURRENT_BOX_STATE,
            currentBox,
        });
    };
}

export function updateAccountOnTreasurePurchase(activeTransaction?: TreasureTransaction): ITreasureDoPurchase {
    return {
        type: DO_PURCHASE,
        pendingTransaction: activeTransaction,
    };
}

export function showTreasureAnimation(show: boolean | null, data?: TreasureAnimationData): IShowTreasureAnimation {
    return {
        type: TREASURE_SHOW_ANIMATION,
        show,
        data,
    };
}
