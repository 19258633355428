import { FilterPosition } from './category';

export enum InventoryKeys {
    storage = 'storage',
    slots = 'slots',
    emptySlots = 'emptySlots',
    permoflages = 'permoflages',
    ships = 'ships',
    itemsStorage = 'itemsStorage',
    lootboxes = 'lootboxes',
    shipMaterials = 'ship_materials',
    shipComponents = 'ship_components',
}

declare global {
    type ICurrencyBalanceNames = 'brass' | 'coal' | 'credits' | 'eventum_1' | 'eventum_2' | 'gold' | 'molybdenum' | 'paragon_xp' | 'steel' | 'recruitment_points' | 'wows_premium' | 'premium';

    interface IBalanceData {
        currency: string;
        value: number;
        paidPart: number;
    }

    type IBalance = IBalanceData[];

    type StorageInventory = Record<number, number>;
    type LootboxesWithoutDroppedRewardsInventory = Record<string, Record<string, Record<string, number>>>;

    type InventoryPermoflage = [number, number];
    type InventoryPermoflages = InventoryPermoflage[];
    type InventoryShips = number[];
    type InventoryUniqueItems = number[];

    type InventoryState = {
        storage: StorageInventory;
        slots: number;
        emptySlots: number;
        permoflages: InventoryPermoflages;
        ships: InventoryShips;
        uniqueItems: InventoryUniqueItems;
        itemsStorage: StorageInventory;
        lootboxes: StorageInventory;
        lootboxes_without_dropped_rewards: LootboxesWithoutDroppedRewardsInventory;
        ship_materials: StorageInventory;
        ship_components: StorageInventory;
    };

    type Inventory = {
        storage: StorageInventory;
        slots: number;
        empty_slots: number;
        permoflages: InventoryPermoflages;
        ships: InventoryShips;
        items_storage: StorageInventory;
        unique_items: InventoryUniqueItems;
        lootboxes: StorageInventory;
        lootboxes_without_dropped_rewards: LootboxesWithoutDroppedRewardsInventory;
        ship_materials: StorageInventory;
        ship_components: StorageInventory;
    };

    type ResponseInventory = {
        data: Inventory;
        status: string;
    };

    type ResponseStorage = {
        data: Record<string, number>;
        status: string;
    };

    type AccountBalanceCurrency = {
        currency: string;
        value: Nullable<string>;
        paidPark: number;
    };

    type AccountFilterSettings = {
        position: FilterPosition;
    };

    type AccountSettings = {
        isWelcomePageShown: boolean;
    };

    type AccountUsedCoupons = Record<string, number>;

    type AccountRandomBundleHistory = Record<number, number[]>;

    type AccountPurchasedLimitedBundles = Record<number, number>;

    type AccountSelectedRandomBundles = Record<string, number>;

    type AccountRestrictedLootboxesPurchaseCount = Record<number, number>;

    interface IAccount {
        isAdult: boolean;
        viewedNotifications: string[];
        viewedLabels: string[];
        isDeactivated: boolean;
        filtersSettings: AccountFilterSettings;
        inventory: Record<string, number>;
        filtersPresetsRecommended: number[];
        balance: AccountBalanceCurrency[];
        isParagonsEnabled: boolean;
        country: string | null;
        denied_bundles: number[];
        id: number;
        name: string;
        settings: AccountSettings;
        openedRandomBundles: number[];
        completedActions: string[];
        usedCoupons: AccountUsedCoupons;
        deniedBundlesByUniqueItems: number[];
        randomBundlesHistory: AccountRandomBundleHistory;
        purchasedLimitedBundles: AccountPurchasedLimitedBundles;
        bundlesInTransaction: number[];
        selectedRandomBundles: AccountSelectedRandomBundles;
        restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
        level: number;
    }
}

export default global;
