import * as React from 'react';
import styles from '~/Layouts/BundlePage/BundlePage.scss';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { getIdFromButtonToPort } from '~/Layouts/BundlePage/helpers';
import PortPreviewButton, { PortPreviewTemplate } from '~/components/PortPreviewButton/PortPreviewButton';
import { openPortForFirstBundleItem } from '~/settings/port';

interface IBundlePagePortPreview {
    bundle: IBundle;
    template?: PortPreviewTemplate;
    onlyIcon?: boolean;
}

export default function BundlePagePortPreview({ bundle, template, onlyIcon }: IBundlePagePortPreview) {
    const openPort = () => {
        openPortForFirstBundleItem(bundle);
    };

    return (
        <div className={styles.portPreviewWrapper}>
            <GuideDecorator names={getIdFromButtonToPort(bundle)}>
                <PortPreviewButton onClick={openPort} template={template} onlyIcon={onlyIcon} />
            </GuideDecorator>
        </div>
    );
}
