import * as React from 'react';
import styles from './CategoryFilter.scss';
import equal from 'fast-deep-equal/react';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { disableBodyScroll as addHiddenScrollToBody } from '~/utils/dom';
import { State } from '~/Reducers';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { changeFiltersPosition } from '~/Actions/ActionApp';
import FiltersPopup from '~/components/CategoryFilter/FiltersPopup';
import { changeFilterSettings } from '~/api/account';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { IChangeFiltersPosition, ICurrentPage } from '~/Actions/ActionAppType';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { scrollTop } from '~/utils/scrollTop';
import { isEnabledDefaultFilterPosition, isNeedToRenderFilter, saveFilterPositionToLocalStorage } from '~/utils/filters';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import useWindowResize from '~/hooks/useWindowResize';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES, PLACEMENT } from '~/components/WelcomePage/steps';
import { FilterPosition } from '~/types/category';
import { playDropdownClickSound } from '~/api/WoWsClient';

export interface IFilter {
    category: ICategoryList;
    onChangeVisible?: (isVisible: boolean) => void;
    withoutActiveFilters?: boolean;
    position?: FilterPosition;
}

interface IFilterState {
    categories: ICategories;
    activePreset: string;
    accountId: number;
    filtersPosition: FilterPosition;
    accountFilterPosition: FilterPosition;
    disabledCategories: string[];
    coupons: ICoupon[];
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IFilterState => {
    return {
        categories: state.ReducerApp.categories,
        activePreset: state.ReducerApp.activePreset,
        filtersPosition: state.ReducerApp.filterPosition,
        accountId: state.ReducerAccount.id,
        accountFilterPosition: state.ReducerAccount.filterPosition,
        disabledCategories: state.ReducerApp.disabledCategories,
        coupons: state.ReducerAccount.coupons,
        currentPage: state.ReducerApp.currentPage,
    };
};

const filterTitle = t('Фильтры');

let prevFilterPosition: FilterPosition = null;

const CategoryFilter = (props: IFilter) => {
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    const refFilterPopup = React.useRef(null);
    const refFilterButton = React.useRef(null);
    const state = useSelector<State, IFilterState>(stateSelector, equal);
    const thisFilterPosition = props.position || FilterPosition.TOP;
    const isDisplayRight = thisFilterPosition === FilterPosition.RIGHT;
    const isDisplayDefault = thisFilterPosition !== FilterPosition.RIGHT;
    const [isVisible, setVisible] = React.useState(!isDisplayDefault || prevFilterPosition === FilterPosition.RIGHT);

    const isPositionMatches = state.filtersPosition === thisFilterPosition;
    const isDisabledCategory = state.disabledCategories?.includes(props.category);
    const isFilterEnabled = !isDisabledCategory && isNeedToRenderFilter(state.currentPage?.name) && !state.currentPage?.isBundlePage && isPositionMatches;

    React.useEffect(() => {
        if (!isPositionMatches) return;

        if (state.filtersPosition === FilterPosition.RIGHT && !isEnabledDefaultFilterPosition()) {
            dispatch<IChangeFiltersPosition>(changeFiltersPosition(FilterPosition.TOP));
        }

        if (!isDisplayDefault) return;

        if (isMobileOrTabletWindow) {
            window.addEventListener('popstate', (event) => {
                setVisible(false);
            });
        }

        function handleClickOutside(event?: React.KeyboardEvent) {
            const target = event.target as HTMLDivElement;
            if (target === refFilterButton.current || isDisplayRight) {
                return;
            }

            if (event.which === 1 && refFilterPopup.current && !refFilterPopup.current.contains(event.target)) {
                setVisible(false);
            }
        }

        const _callback = handleClickOutside.bind(this);

        document.addEventListener('mousedown', _callback);

        return () => {
            prevFilterPosition = null;
            document.removeEventListener('mousedown', _callback);
        };
    }, []);

    useWindowResize(() => {
        if (isDisplayDefault) return;
        const { offsetWidth } = document.body;
        if (offsetWidth <= 1919) {
            dispatch<IChangeFiltersPosition>(changeFiltersPosition(FilterPosition.TOP));
        }
    });

    if (!isFilterEnabled) return null;

    const _changeFilterPosition = (position: FilterPosition) => {
        prevFilterPosition = position === FilterPosition.TOP ? FilterPosition.RIGHT : FilterPosition.TOP;

        scrollTop(0, true);

        if (state.accountId) {
            dwhExport.send(DWH_EVENTS.FILTERS_POSITION, {
                position: position,
                category: props.category,
            });
            changeFilterSettings(position);
        }

        saveFilterPositionToLocalStorage(position);
        dispatch<IChangeFiltersPosition>(changeFiltersPosition(position));
    };

    const changeVisible = () => {
        if (isDisplayRight) {
            return;
        }

        if (isMobileOrTabletWindow) {
            if (!isVisible) {
                addHiddenScrollToBody(true);
                disableBodyScroll(document.querySelectorAll(`.${styles.columns}`)[0]);
            } else {
                addHiddenScrollToBody(false);
                clearAllBodyScrollLocks();
            }

            document.location.hash = !isVisible ? 'filters' : '';
        }

        setVisible(!isVisible);
        props.onChangeVisible && props.onChangeVisible(!isVisible);
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            RefManager.setRef(RefManagerKeys.Filters, _ref);
            ref.current = _ref;
        }
    };

    const classesFilterPositionTop = classNames(styles.filterColumnTop, { [styles.active]: isDisplayDefault });
    const classesWrapper = classNames(styles.filterWrapper, { [styles.displayRight]: isDisplayRight });
    const classesFilterIcon = classNames(styles.filterIcon, { [styles.right]: isDisplayRight });
    const filterHeadClassNames = classNames(styles.filterHeader, {
        [styles.active]: isVisible,
    });

    return (
        <GuideDecorator
            names={[GUIDE_NAMES.guide_filters_and_presets]}
            placement={state.filtersPosition === FilterPosition.RIGHT ? PLACEMENT.LEFT : PLACEMENT.CENTER}
            key={`decorator_${state.filtersPosition}`}
        >
            <div className={classesWrapper} ref={setRef}>
                <div className={filterHeadClassNames}>
                    <div
                        className={classesFilterIcon}
                        onClick={() => {
                            if (isDisplayRight) return;
                            playDropdownClickSound();
                            changeVisible();
                        }}
                        ref={refFilterButton}
                    />
                    {isDisplayRight && (
                        <DivTooltip tooltipBody={<DefaultTooltip text={t('Отображать фильтры сверху')} />}>
                            <div
                                className={classesFilterPositionTop}
                                onClick={(event) => {
                                    playDropdownClickSound();
                                    _changeFilterPosition(FilterPosition.TOP);
                                }}
                            />
                        </DivTooltip>
                    )}
                </div>
                {isDisplayRight && <FiltersPopup onChangeVisible={changeVisible} category={props.category} />}
            </div>
            {isVisible && isDisplayDefault && (
                <FiltersPopup onChangeVisible={changeVisible} category={props.category} ref={refFilterPopup} isDisplayDefault={isDisplayDefault} changeFilterPosition={_changeFilterPosition} />
            )}
        </GuideDecorator>
    );
};

export default CategoryFilter;
