import { del, get, post, put } from '~/utils/ajax';
import { settings } from '~/utils/settings';

const buildAuctionUrl = (url: string): string => {
    const searchParams = new URLSearchParams();
    searchParams.append('realm', settings.realm);
    searchParams.append('lang', settings.languageCode);

    if (process.env.NODE_ENV !== 'production') {
        searchParams.append('spa_id', window.metashop.state.account?.id.toString());
    }

    return `${url}?${searchParams.toString()}`;
};

const AUCTION_API_PATH = {
    PRELOADED_STATE: 'auctions/api/preloaded_state/',
    BID: 'auctions/api/bids/{lot_id}/',
    COMPLETE_ACTION: 'auctions/api/actions/complete',
    SYNC_ACCOUNT: 'auctions/api/account/',
};

export const placeBid = async (lotId: string, bid: number): Promise<IResponseAccountUpdateState> => {
    if (!settings.urls.auction) {
        return;
    }

    const url = buildAuctionUrl(`${settings.urls.auction}${AUCTION_API_PATH.BID}`.replace('{lot_id}', lotId));

    return await post(url, { bid }, {}, false, true, true, {
        credentials: 'include',
    });
};

export const cancelBid = async (lotId: string): Promise<IResponseAccountUpdateState> => {
    if (!settings.urls.auction) {
        return;
    }

    const url = buildAuctionUrl(`${settings.urls.auction}${AUCTION_API_PATH.BID}`.replace('{lot_id}', lotId));

    return await del(url, null, {}, false, true, true, {
        credentials: 'include',
    });
};

export const preloadedState = async (spaId: number): Promise<IResponsePreloadedState> => {
    if (!settings.urls.auction) {
        return;
    }

    const url = buildAuctionUrl(`${settings.urls.auction}${AUCTION_API_PATH.PRELOADED_STATE}`);

    return await get(url, null, false, true, true, {
        credentials: 'include',
    });
};

export const completeAction = async (id: string, actionIds: string[]): Promise<IResponseAccountUpdateState> => {
    if (!settings.urls.auction) {
        return;
    }

    const url = buildAuctionUrl(`${settings.urls.auction}${AUCTION_API_PATH.COMPLETE_ACTION}`);

    return await put(url, { auctionId: id, actionIds }, null, false, true, true, {
        credentials: 'include',
    });
};

export const syncAccount = async (): Promise<IResponseAccountUpdateState> => {
    if (!settings.urls.auction) {
        return;
    }

    const url = buildAuctionUrl(`${settings.urls.auction}${AUCTION_API_PATH.SYNC_ACCOUNT}`);

    return await get(url, null, false, true, true, {
        credentials: 'include',
    });
};

/**********************************************************************************************************************
 ********************************************** DEV PANEL METHODS ******************************************************
 **********************************************************************************************************************/

export const finishAuction = async (auctionId: string, realm?: string) => {
    const url = `${settings.urls.auction}auctions/api/auctions/${auctionId}/finish_auction/?force=true&realm=${realm || settings.realm}`;

    return await post(url);
};

export const restartAuction = async (auctionId: string, realm?: string) => {
    const url = `${settings.urls.auction}auctions/api/auctions/${auctionId}/restart/?realm=${realm || settings.realm}`;

    return await post(url);
};

export const makeFakeBids = async (auctionId: string, lotId: string, count: string, bidSize: string, realm?: string) => {
    const url = `${settings.urls.auction}auctions/api/auctions/${auctionId}/make_fake_bids/${lotId}/?count=${count}&bid_size=${bidSize}&realm=${realm || settings.realm}`;

    return await post(url);
};

export const startWinnersChoosing = async (auctionId: string, realm?: string) => {
    const url = `${settings.urls.auction}auctions/api/auctions/${auctionId}/start_winners_choosing/?realm=${realm || settings.realm}`;

    return await post(url);
};

export const fetchWinnersFromBranches = async (auctionId: string, realm?: string) => {
    const url = `${settings.urls.auction}auctions/api/auctions/${auctionId}/fetch_winners_from_branches/?realm=${realm || settings.realm}`;

    return await post(url);
};

export const chooseAuctionWinners = async (auctionId: string, realm?: string) => {
    const url = `${settings.urls.auction}auctions/api/auctions/${auctionId}/choose_auction_winners/?realm=${realm || settings.realm}`;

    return await post(url);
};

export const sendRewardsAndCompensation = async (auctionId: string, realm?: string) => {
    const url = `${settings.urls.auction}auctions/api/auctions/${auctionId}/send_rewards_and_compensation/?realm=${realm || settings.realm}`;

    return await post(url);
};
