import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { useDispatch, useSelector } from 'react-redux';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import FastGoldLink from '~/components/FastGoldLink/FastGoldLink';
import { State } from '~/Reducers';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { isChina, openUrl, settings } from '~/utils/settings';
import classNames from 'classnames';
import { isWsmartProductsAvailable } from '~/utils/purchase';

export enum FAST_GOLD_PLACE {
    AUCTION_BID_PLACING_POPUP = 'auction_bid_placing_popup',
    AUCTION_LOT_VIEW = 'auction_lot_view',
    BUNDLE_PURCHASE_POPUP = 'bundle_purchase_popup',
    BUNDLE_VIEW_PAGE = 'bundle_view_page',
    MRPS_VIEW_PAGE = 'mrps_view_page',
    CUSTOM_VIEW_PAGE = 'custom_view_page',
}

interface IFastGoldLinkContainer {
    withIcon?: boolean;
    className?: string;
    place?: FAST_GOLD_PLACE;
    goldShortage: number;
    isSantaBundle?: boolean;
    groupName?: string;
}

interface IStateSelector {
    accountId: number;
    products: WsmartGoldProductWrapper[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        products: state.ReducerWSMart.fastGoldProducts,
    };
};

const FastGoldLinkContainer = ({ withIcon, className, place, goldShortage, groupName }: IFastGoldLinkContainer) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    const click = (event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (place) {
            dwhExport.send(DWH_EVENTS.OPEN_FAST_GOLD_POPUP, { place });
        }
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(POPUPS_NAME.WSMART_DOUBLOONS_POPUP, true, { goldShortage, groupName }));
    };

    const chinaOnClick = (event?: React.MouseEvent) => {
        event?.stopPropagation();
        openUrl(settings.urls.cnWoWsShopUrl);
    };

    if (isChina()) {
        return <FastGoldLink onClick={chinaOnClick} className={classNames(className, 'external-link external-link_gold')} withIcon={withIcon} />;
    }

    if (!isWsmartProductsAvailable(state.products)) {
        return null;
    }

    return <FastGoldLink onClick={click} className={className} withIcon={withIcon} />;
};

export default FastGoldLinkContainer;
