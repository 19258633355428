import * as React from 'react';
import styles from './TradeInStatus.scss';
import { ITradeItem, TradeInStatus as TradeInStatusTypes } from '~/types/tradein';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { t } from '~/utils/localization';
import { Button } from '~/components/Button/Buttons';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import classNames from 'classnames';

interface ITradeInStatus {
    data: {
        status: TradeInStatusTypes;
        item: ITradeItem;
    };
}

const TradeInStatus = ({ data }: ITradeInStatus) => {
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(changeVisiblePopup(null));
    };

    const isSuccess = data.status === TradeInStatusTypes.SUCCESS;

    const classesImages = classNames(styles.image, {
        [styles.error]: !isSuccess,
    });

    return (
        <Popup onClose={onClose} popupClassName={styles.popup}>
            <PopupHeader title={isSuccess ? t('Обмен завершён') : t('Произошла ошибка. Повторите попытку позже')} />
            <PopupBody>
                {isSuccess && <div className={styles.shinyEffect} />}
                <img className={classesImages} src={data.item.images.small || data.item.images.big} />
                <div className={styles.info}>
                    <WoWSEntity
                        type={data.item.type}
                        id={data.item.identifier}
                        presentation={{
                            renderWithoutFade: true,
                            hideNationIcon: true,
                            withShortText: true,
                        }}
                    />
                </div>
            </PopupBody>
            <PopupFooter>
                <Button label={t('Закрыть')} onClick={onClose} />
            </PopupFooter>
        </Popup>
    );
};

export default TradeInStatus;
