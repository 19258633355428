import React from 'react';
import classNames from 'classnames';

import styles from './MaskWrapper.scss';

const DEFAULT_MAX_SIZES = {
    width: 2560,
    height: 1440,
};

interface MaskWrapperProps {
    children?: React.ReactNode;
    customBackground?: React.ReactNode;
    wrapperClassName?: string;

    contentMaxSizes?: {
        width: number;
        height: number;
    };

    maskColor?: string;
    type?: MaskWrapperType;

    withoutBackgrounds?: boolean;
}

export enum MaskWrapperType {
    OUTER = 'outer',
    INNER = 'inner',
}

type ContentSettings = {
    isNeedToShowMask: boolean;
} & Required<MaskWrapperProps>['contentMaxSizes'];

const getRecalculatedSizes = (() => {
    const contentMaxSizesAspectRatioMap = new Map<string, number>();

    return (contentMaxSizes: MaskWrapperProps['contentMaxSizes'] = DEFAULT_MAX_SIZES): ContentSettings => {
        const contentMaxSizesAspectRatioKey = `${contentMaxSizes.width}_${contentMaxSizes.height}`;
        const contentMaxSizesAspectRatio =
            contentMaxSizesAspectRatioMap.get(contentMaxSizesAspectRatioKey) ??
            contentMaxSizesAspectRatioMap
                .set(contentMaxSizesAspectRatioKey, Number(Number.parseFloat((contentMaxSizes.width / contentMaxSizes.height).toString()).toFixed(3)))
                .get(contentMaxSizesAspectRatioKey);

        const clientWidth = window.document.documentElement.clientWidth;
        const clientHeight = window.document.documentElement.clientHeight;

        let isNeedToShowMask = false;

        if (clientWidth >= contentMaxSizes.width && clientHeight >= contentMaxSizes.height) {
            if (clientWidth === contentMaxSizes.width && clientHeight === contentMaxSizes.height) isNeedToShowMask = false;
            if (clientWidth > contentMaxSizes.width || clientHeight > contentMaxSizes.height) isNeedToShowMask = true;

            return {
                width: contentMaxSizes.width,
                height: contentMaxSizes.height,
                isNeedToShowMask,
            };
        }

        if (clientWidth < contentMaxSizes.width && clientHeight >= contentMaxSizes.height) {
            isNeedToShowMask = true;

            return {
                width: clientWidth,
                height: clientWidth / contentMaxSizesAspectRatio,
                isNeedToShowMask,
            };
        }

        if (clientWidth >= contentMaxSizes.width && clientHeight < contentMaxSizes.height) {
            isNeedToShowMask = true;

            return {
                width: clientHeight * contentMaxSizesAspectRatio,
                height: clientHeight,
                isNeedToShowMask,
            };
        }

        if (clientWidth < contentMaxSizes.width && clientHeight < contentMaxSizes.height) {
            const currentClientAspectRatio = Number(Number.parseFloat((clientWidth / clientHeight).toString()).toFixed(3));

            const heightByClientWidth = currentClientAspectRatio !== contentMaxSizesAspectRatio ? clientWidth / contentMaxSizesAspectRatio : clientHeight;
            if (heightByClientWidth <= clientHeight) {
                if (heightByClientWidth === clientHeight) isNeedToShowMask = false;
                if (heightByClientWidth < clientHeight) isNeedToShowMask = true;

                return {
                    width: clientWidth,
                    height: heightByClientWidth,
                    isNeedToShowMask,
                };
            }

            const widthByClientHeight = currentClientAspectRatio !== contentMaxSizesAspectRatio ? clientHeight * contentMaxSizesAspectRatio : clientWidth;
            if (widthByClientHeight <= clientWidth) {
                if (widthByClientHeight === clientWidth) isNeedToShowMask = false;
                if (widthByClientHeight < clientWidth) isNeedToShowMask = true;

                return {
                    width: widthByClientHeight,
                    height: clientHeight,
                    isNeedToShowMask,
                };
            }
        }
    };
})();

export const MaskWrapper = React.forwardRef<HTMLDivElement, MaskWrapperProps>(
    ({ children, wrapperClassName, customBackground, contentMaxSizes = DEFAULT_MAX_SIZES, maskColor, type = MaskWrapperType.INNER, withoutBackgrounds }: MaskWrapperProps, contentWrapperRef) => {
        const [contentSettings, setContentSettings] = React.useState<ContentSettings>(() => getRecalculatedSizes(contentMaxSizes));

        const onResizeCallback = React.useCallback(() => {
            setContentSettings(getRecalculatedSizes(contentMaxSizes));
        }, [contentMaxSizes]);

        React.useLayoutEffect(() => {
            window.addEventListener('resize', onResizeCallback);

            return () => {
                window.removeEventListener('resize', onResizeCallback);
            };
        }, [onResizeCallback]);

        React.useEffect(() => {
            onResizeCallback();
        }, [onResizeCallback]);

        const wrapperStyles: React.CSSProperties = React.useMemo(() => {
            return {
                ['--maskColor']: maskColor,
            } as React.CSSProperties;
        }, [maskColor]);

        const contentWrapperStyles: React.CSSProperties = React.useMemo(() => {
            return {
                width: contentSettings.width || undefined,
                height: contentSettings.height || undefined,
            } as React.CSSProperties;
        }, [contentSettings.width, contentSettings.height]);

        return (
            <div className={classNames(styles.wrapper, wrapperClassName)} style={wrapperStyles}>
                <div className={styles.contentWrapper} style={contentWrapperStyles} ref={contentWrapperRef}>
                    <div
                        className={classNames(styles.mask, {
                            [styles.mask_active]: contentSettings.isNeedToShowMask,
                            [styles.mask_outer]: type === MaskWrapperType.OUTER,
                        })}
                    >
                        {type === MaskWrapperType.OUTER && !withoutBackgrounds && (customBackground || <div />)}
                        <div className={styles.mask__left} />
                        <div className={styles.mask__right} />
                        <div className={styles.mask__bottom} />
                    </div>
                    {type === MaskWrapperType.INNER && !withoutBackgrounds && (customBackground || <div />)}
                    {children}
                </div>
            </div>
        );
    },
);
