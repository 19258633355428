import { items } from '@wg/wows-entities/const';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup, setFetching, updateCustomPagePopupBackground, updatePopupSettings } from '~/Actions/ActionApp';
import { Button } from '~/components/Button/Buttons';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { getSectionName } from '~/core/ManagerData';
import useLoadPrimaryItemData from '~/hooks/useLoadPrimaryItemData';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import getCrewsContent, { CrewsSubTitleContent } from '~/Layouts/BundlePage/Contents/CrewsContent';
import ShipsContent from '~/Layouts/BundlePage/Contents/ShipsContent';
import LootboxAutoDescriptionWrapper from '~/Layouts/Lootboxes/LootboxAutoDescriptionWrapper';
import { t } from '~/utils/localization';
import { Crew, LootBox, Vehicle } from '~/vortex';
import styles from './ItemDescriptionPopup.scss';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import classNames from 'classnames';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import PityTimerContainer from '~/containers/PityTimerContainer/PityTimerContainer';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import { CUSTOM_PAGE } from '~/Layouts/Themes/ThemeManager';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import { State } from '~/Reducers';
import { renderDescriptionBlock } from '~/Layouts/BundlePage/BundlePageDescription/handlers';
import { ShipComplexity } from '~/components/ShipComplexity/ShipComplexity';

export interface IItemDescriptionPopupData {
    item: IItemCommonData;
    autoDescriptionSettings: IAutoDescriptionSettings;
    theme?: ALLOWED_THEMES;
    isFirstOpen?: boolean;
}

interface IItemDescriptionPopup {
    data: IItemDescriptionPopupData;
    popupStyles?: {
        wrapper?: string;
        body?: string;
        header?: string;
    };
    withoutFooter?: boolean;
    style?: React.CSSProperties;
    withoutZoomIn?: boolean;
}

function getContentByType(data: IItemDescriptionPopupData) {
    switch (data.item.type) {
        case items.LOOTBOX:
            return <LootboxAutoDescriptionWrapper id={data.item.identifier} autoDescriptionSettings={data.autoDescriptionSettings} theme={data.theme} />;
        case items.VEHICLES:
            return <ShipsContent item={data.item} renderFeatures />;
        case items.CREWS:
            return getCrewsContent({ item: data.item });
    }
}

function getSubContentByType(item: IItemCommonData, vrtxItem: Crew | Vehicle | LootBox, data: IItemDescriptionPopupData) {
    switch (item.type) {
        case items.CREWS:
            return <CrewsSubTitleContent item={item} />;
        case items.LOOTBOX:
            return (
                <div className={styles.subTitle}>
                    <div className={styles.subTitleItem}>{(vrtxItem as LootBox).isPremium ? t('Премиум контейнер') : t('Контейнер')}</div>
                    <PityTimerContainer
                        identifier={item.identifier}
                        autoDescriptionSettings={data.autoDescriptionSettings}
                        title={(vrtxItem as LootBox).shortTitle}
                        className={styles.subTitleItem}
                        fallback={<InventoryCounterContainer item={item} className={classNames(styles.subTitleItem, styles.inventoryWidgetContainer)} size={InventoryCounterSize.FULL} />}
                    />
                </div>
            );
    }
}

interface StateSelector {
    popupSettings?: State['ReducerApp']['popupSettings'];
}

function stateSelector(state: State): StateSelector {
    return {
        popupSettings: state.ReducerApp.popupSettings,
    };
}

const ItemDescriptionPopup = ({ data, popupStyles, withoutFooter, style, withoutZoomIn }: IItemDescriptionPopup) => {
    const popupSettings = useSelector<State, StateSelector>(stateSelector, equal).popupSettings;
    const { nestedPopup } = popupSettings;
    const dispatch = useDispatch();
    const contentRef = React.useRef<HTMLDivElement>();
    const item = useLoadPrimaryItemData(data.item?.type, data.item?.identifier, getSectionName(data.item.type));
    const [isVisiblePopup, toggleVisibilityPopup] = React.useState(!!item);

    const getTitle = () => {
        if (data.item.type === items.CREWS) {
            return (item as Crew).title;
        } else if (data.item.type === items.VEHICLES) {
            return (
                <div className={styles.title__ship}>
                    <WoWSEntity type={data.item.type} id={data.item.identifier} presentation={{ renderWithoutFade: true }} />
                    <ShipComplexity shipId={data.item.identifier} />
                </div>
            );
        } else if (data.item.type === items.LOOTBOX) {
            return (item as LootBox).shortTitle;
        }
    };

    const close = () => {
        dispatch(changeVisiblePopup(null));

        if (data.theme === CUSTOM_PAGE && data.isFirstOpen) {
            dispatch(updateCustomPagePopupBackground(''));
        }

        if (data.isFirstOpen && nestedPopup) {
            const newPopupSettings: State['ReducerApp']['popupSettings'] = {
                ...popupSettings,
                nestedPopup: undefined,
            };

            dispatch(updatePopupSettings(newPopupSettings));
        }
    };

    React.useEffect(() => {
        if (item) {
            toggleVisibilityPopup(true);
            dispatch(setFetching(false));
        } else {
            dispatch(setFetching(true));
        }
    }, [item]);

    useMaskScrollEffect(contentRef);

    React.useEffect(() => {
        if (data.isFirstOpen && nestedPopup?.mainBundle?.description) {
            window.WoWSEntities.parseDocument();
        }
    }, [nestedPopup?.mainBundle, data.isFirstOpen]);

    if (!isVisiblePopup) {
        return <></>;
    }

    return (
        <Popup
            onClose={close}
            outsideClick={true}
            renderEscButton={true}
            className={classNames(styles.popup, popupStyles?.wrapper, {
                [styles.popup__content_custom]: data.theme === CUSTOM_PAGE,
            })}
            popupClassName={classNames({
                [styles.popup_custom]: data.theme === CUSTOM_PAGE,
            })}
            closeIconClassName={classNames({
                [styles.popup_custom__buttonClose]: data.theme == CUSTOM_PAGE,
            })}
            style={style}
            withoutZoomIn={withoutZoomIn}
        >
            <PopupHeader
                title={t('Дополнительная информация')}
                className={classNames(popupStyles?.header, {
                    [styles.popup__header_custom]: data.theme === CUSTOM_PAGE,
                })}
            />
            <PopupBody className={styles.bodyWrapper}>
                <div
                    className={classNames(styles.body, popupStyles?.body)}
                    ref={(_ref) => {
                        if (_ref) {
                            contentRef.current = _ref;
                        }
                    }}
                >
                    <div className={styles.header}>
                        <div className={styles.title}>{getTitle()}</div>
                        {item && getSubContentByType(data.item, item, data)}
                    </div>
                    {(item as Vehicle)?.description && <div className={styles.description}>{(item as Vehicle).description}</div>}
                    {data.isFirstOpen && nestedPopup?.mainBundle?.description && <div className={styles.description}>{renderDescriptionBlock(nestedPopup?.mainBundle)}</div>}
                    {getContentByType(data)}
                </div>
            </PopupBody>
            {!withoutFooter && (
                <PopupFooter>
                    <Button label={t('Закрыть')} onClick={close} />
                </PopupFooter>
            )}
        </Popup>
    );
};

export default ItemDescriptionPopup;
