import * as React from 'react';
import { isCurrency } from '~/utils/currencies';
import styles from './FillerLayout.scss';
import { t } from '~/utils/localization';
import { isEmptyObject } from '~/utils/utils';
import { Interpolate } from '@wg/wows-react-uikit';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';

interface IFillerLayout {
    lootbox: ILootboxStateItem;
}

const FillerLayout = ({ lootbox }: IFillerLayout) => {
    const { filler, savePointForValuableGroup, uniqueGroupTitles } = lootbox;

    if (!filler || isEmptyObject(filler)) {
        return null;
    }

    let fillerText;
    if (savePointForValuableGroup?.savePoint && uniqueGroupTitles?.length === 1) {
        fillerText = t('Если у вас уже есть все предметы группы «%(groupName)s» и из контейнера «%(containerName)s» вам выпадет предмет из этой группы, вы получите: %(filler)s');
    } else {
        fillerText = t('Если у вас уже есть все предметы из групп уникальных предметов, и из контейнера «%(containerName)s» вам выпадет предмет из этих групп, вы получите: %(filler)s');
    }

    return (
        <span className={styles.footerText}>
            <Interpolate
                str={fillerText}
                groupName={uniqueGroupTitles[0]}
                containerName={lootbox.shortTitle}
                className={styles.fillerText}
                filler={
                    <WoWSEntity
                        id={filler.id}
                        type={filler.type}
                        amount={filler.amount}
                        className={styles.fillerWeComponent}
                        presentation={{
                            withText: !isCurrency(filler.type),
                            withTooltip: true,
                            iconPosition: 'left',
                            hideTooltipAmount: isCurrency(filler.type),
                        }}
                    />
                }
            />
        </span>
    );
};

export default FillerLayout;
