import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

import store from '~/Store';
import { State } from '~/Reducers';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { updateUserAdultFlag } from '~/Actions/ActionAccount';
import Account from '~/account/Account';
import { startHeadShakeAnimation } from '~/utils/auction';
import { isNeedToShowAdultCopyright } from '~/utils/bundles';
import { interpolate, t } from '~/utils/localization';
import { purchaseSerialSequence } from '~/utils/purchase';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';

import { CheckboxWithLabel } from '@wg/wows-react-uikit';
import BundlePricesSequence from '~/components/BundlePricesSequence/BundlePricesSequence';
import { OrangeButton } from '~/components/Button/Buttons';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { POPUPS_NAME } from '~/components/PopupManager';
import SerialItemsSmallCarouselItem from '~/components/SerialItemsSmallCarousel/SerialItemsSmallCarouselItem';
import { CopyrightContent } from '~/components/Popups/Purchase/AdditionalContent';

import styles from './SerialSequencePurchasePopup.scss';

interface ISerialSequencePurchasePopup {
    data: {
        sequence: IBundle[];
        firstAvailableBundleId: number;
        callback?: () => void;
    };
}

interface IStateSelector {
    popups: IPopup[];

    port: IPort;

    bundlesInTransaction: number[];

    balance: IBalance;

    isAdult: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        popups: state.ReducerApp.popups,

        port: state.ReducerApp.port,

        bundlesInTransaction: state.ReducerAccount.bundlesInTransaction,

        balance: state.ReducerAccount.balance,

        isAdult: state.ReducerAccount.isAdult,
    };
};

const SerialSequencePurchasePopup = ({ data }: ISerialSequencePurchasePopup) => {
    const { isAdult, balance, popups, port } = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isDisabledButton, toggleAvailabilityButton] = React.useState(!isAdult);
    const [buttonIsFetching, setButtonIsFetching] = React.useState(false);
    const [isBlockedInterface, setBlockedInterface] = React.useState(false);
    const restrictionRef = React.useRef<HTMLDivElement>();
    const itemsRef = React.useRef<HTMLDivElement>();
    const { sequence, firstAvailableBundleId } = data;
    const interfaceBlockTimer = React.useRef<NodeJS.Timeout>();

    const _isNeedToShowAdultCopyright = React.useCallback(() => {
        return sequence.some((bundle) => isNeedToShowAdultCopyright(bundle));
    }, [])();

    const closePopup = function () {
        if (isBlockedInterface) {
            return;
        }
        store.dispatch(changeVisiblePopup(null));
    };

    React.useEffect(() => {
        for (const popup of popups) {
            if ([POPUPS_NAME.ERROR_PURCHASE, POPUPS_NAME.SERIAL_SEQUENCE_SUCCESS_PURCHASE].includes(popup.name)) {
                store.dispatch(changeVisiblePopup(POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE));
                return;
            }
        }
    }, [popups]);

    const WebCopyrightContent = React.useCallback(() => {
        return (
            <div className={styles.copyrightContent}>
                <CopyrightContent isNeedToShowCopyrightFromContainer={_isNeedToShowAdultCopyright} />
            </div>
        );
    }, []);

    const changeUserAdultFlag = () => {
        toggleAvailabilityButton(!!isAdult);
        Account.updateAccountSettings({ is_adult: !isAdult });
        store.dispatch(updateUserAdultFlag());
    };

    const onClickFromDisableButton = () => {
        if (restrictionRef.current) {
            startHeadShakeAnimation(restrictionRef.current, styles.headShakeAnim);
        }
    };

    const goToFirstAvailableBundle = () => {
        store.dispatch(
            changeVisiblePopup(POPUPS_NAME.CONFIRM_PURCHASE, true, {
                bundleId: firstAvailableBundleId,
            }),
        );
    };

    const purchase = () => {
        if (buttonIsFetching) {
            return;
        }

        setButtonIsFetching(true);
        setBlockedInterface(true);
        interfaceBlockTimer.current = setTimeout(() => {
            setBlockedInterface(false);
        }, 7000);

        purchaseSerialSequence(sequence, balance, data.callback);
    };

    useMaskScrollEffect(itemsRef);

    React.useEffect(() => {
        return () => {
            clearTimeout(interfaceBlockTimer.current);
        };
    }, []);

    let title = t('Все наборы');
    if (sequence.at(-1).nextBundle) {
        title = interpolate(t('Наборы с {amount} по {total}'), {
            amount: sequence.at(0).serialIndex + 1,
            total: sequence.at(-1).serialIndex + 1,
        });
    }

    return (
        <Popup
            renderEscButton={!isBlockedInterface}
            onClose={closePopup}
            closeIconClassName={'armory__auto--popup_close'}
            popupClassName={classNames('armory__auto--purchase_popup', styles.purchasePopupContainer, {
                [styles.purchasePopupContainer_portVisible]: port.isVisible,
            })}
            className={classNames(styles.purchasePopupWrapper)}
            AfterPopupComponent={<WebCopyrightContent />}
        >
            <PopupHeader title={t('Подтверждение покупки')} />
            <PopupBody className={styles.body}>
                <div className={styles.info}>
                    <div className={styles.title}>{title}</div>
                </div>

                <div
                    className={styles.items}
                    ref={(_ref) => {
                        if (_ref) {
                            itemsRef.current = _ref;
                        }
                    }}
                >
                    {sequence.map((bundle) => {
                        return <SerialItemsSmallCarouselItem key={`serial_item_${bundle.id}_${bundle.serialIndex}`} className={styles.item} showTitle showDiscount bundle={bundle} />;
                    })}
                </div>

                <div className={styles.confirmationInfo}>
                    <div className={styles.priceTitle}>{t('Цена:')}</div>
                    <BundlePricesSequence bundles={sequence} balance={balance} />
                    {_isNeedToShowAdultCopyright && (
                        <div
                            ref={(_ref) => {
                                if (_ref) {
                                    restrictionRef.current = _ref;
                                }
                            }}
                            className={classNames(styles.adultCheckbox, 'armory__auto--adult-checkbox', {
                                ['armory__auto--adult-checkbox-checked']: isAdult,
                            })}
                        >
                            <CheckboxWithLabel
                                isChecked={isAdult}
                                labelText={<span className={styles.ageRestrictionLabel}>{t('Я подтверждаю, что мне исполнилось 18 лет')}</span>}
                                onChange={changeUserAdultFlag}
                                className={classNames({
                                    [styles.warn]: !isAdult,
                                })}
                            />
                        </div>
                    )}
                </div>
            </PopupBody>
            <PopupFooter className={styles.purchasePopupFooter}>
                <div className={styles.purchasePopupButtonsWrapper}>
                    <OrangeButton
                        label={buttonIsFetching ? t('Обработка') : t('Купить')}
                        disabled={_isNeedToShowAdultCopyright ? isDisabledButton : false}
                        onFallback={onClickFromDisableButton}
                        isFetching={buttonIsFetching}
                        onClick={purchase}
                        className={classNames(styles.purchaseButton, 'armory__auto--button_purchase')}
                    />
                </div>
                <div className={styles.copyrightMobile}>
                    <CopyrightContent isNeedToShowCopyrightFromContainer={_isNeedToShowAdultCopyright} />
                </div>
            </PopupFooter>
        </Popup>
    );
};

export default SerialSequencePurchasePopup;
