import classNames from 'classnames';
import styles from './DialogChangeStepArrowIcon.scss';

interface DialogChangeStepArrowIconProps {
    wrapperClassName?: string;
    theme?: string;
    flipped?: boolean;
}

export function DialogChangeStepArrowIcon({ theme, flipped, wrapperClassName }: DialogChangeStepArrowIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            className={classNames(styles.wrapper, wrapperClassName, {
                [styles[`wrapper_${theme}`]]: !!theme,
                [styles.wrapper_flipped]: !!flipped,
            })}
        >
            <g filter="url(#filter0_b_129_4408)">
                <g filter="url(#filter1_i_129_4408)">
                    <path
                        d="M27.6136 22H36.2472C36.6167 22 36.9637 22.1817 37.1767 22.4873L45.7912 34.8351C46.0696 35.234 46.0696 35.7666 45.7912 36.1655L37.1767 48.5127C36.9632 48.8183 36.6167 49 36.2472 49H27.6136C27.1169 49 26.8268 48.4329 27.1132 48.0221L35.5999 35.8577C35.7495 35.6431 35.7495 35.3563 35.5999 35.1418L27.1137 22.9779C26.8273 22.5671 27.1174 22 27.6141 22H27.6136Z"
                        fill="url(#paint0_linear_129_4408)"
                    />
                </g>
            </g>
            <defs>
                <filter id="filter0_b_129_4408" x="-32" y="-32" width="134" height="134" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="16" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_129_4408" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_129_4408" result="shape" />
                </filter>
                <filter id="filter1_i_129_4408" x="13.7573" y="15" width="45.7847" height="40.8333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.00019189 0 0 0 0 0.276389 0 0 0 0 0.101949 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_129_4408" />
                </filter>
                <linearGradient id="paint0_linear_129_4408" x1="11.8744" y1="35.4167" x2="60" y2="35.4167" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="var(--arrow-color-stop-1)" />
                    <stop offset="1" stopColor="var(--arrow-color-stop-2)" />
                </linearGradient>
            </defs>
        </svg>
    );
}
