import equal from 'fast-deep-equal/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import RefManager, { RefManagerKeys } from '~/RefManager';

interface IStateSelector {
    viewClassName: string;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        viewClassName: state.ReducerApp.viewClassName,
    };
};

export default function useArmoryBackground() {
    const { viewClassName } = useSelector<State, IStateSelector>(stateSelector, equal);

    React.useEffect(() => {
        const body = document.body;

        if (viewClassName) {
            body.classList.add(viewClassName);
        } else {
            body.className = [...body.classList].filter((name) => name.includes('transparent') || (viewClassName && name === viewClassName)).join(' ');
        }
    }, [viewClassName]);
}
