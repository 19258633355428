import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import * as React from 'react';
import { t } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import styles from './CrewVoiceLabel.scss';

interface ICrewVoiceLabel {
    className?: string;
}

const CrewVoiceLabel = ({ className }: ICrewVoiceLabel) => {
    return (
        <DivTooltip
            className={classNames(styles.wrapper, className)}
            tooltipBody={<DefaultTooltip text={t('Когда у вас появляется командир с оригинальной озвучкой, соответствующая модификация становится доступна на вкладке «Звук» в меню настроек')} />}
        >
            <span>{t('Оригинальная озвучка')}</span>
        </DivTooltip>
    );
};

export default CrewVoiceLabel;
