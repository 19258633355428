import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import styles from './ChooseCouponPopup.scss';
import { t } from '~/utils/localization';
import { State } from '~/Reducers';
import Coupon, { CouponSize } from '~/components/Coupon/Coupon';
import PriceWrapper, { PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';
import { isEnabledCouponFromBundle } from '~/utils/coupons';
import classNames from 'classnames';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { Button } from '~/components/Button/Buttons';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

interface IStateSelector {
    coupons: ICoupon[];
    activeCouponId: number;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        coupons: state.ReducerAccount?.coupons,
        activeCouponId: state.ReducerAccount.activeCouponId,
    };
};

const ChooseCouponPopup = (): any => {
    const { coupons, activeCouponId } = useSelector<State, IStateSelector>(stateSelector, equal);
    const { popup } = React.useContext(PopupManagerContext);

    const dispatch = useDispatch();
    const filteredCoupons = coupons.filter((coupon: ICoupon) => !coupon.isDisabled && popup.data?.bundle && isEnabledCouponFromBundle(coupon, popup.data?.bundle));

    const close = () => {
        dispatch(changeVisiblePopup(null));
    };

    const classesChooseCouponPopup = classNames(styles.chooseCouponPopup);

    const classesItems = classNames(styles.items);

    return (
        <Popup onClose={close} closeIconClassName={'armory__auto--popup_close'} className={classesChooseCouponPopup} outsideClick={true}>
            <PopupHeader title={t('Доступные купоны')} className={styles.popupHeader} />
            <PopupBody className={classesItems}>
                {filteredCoupons.map((coupon: ICoupon) => {
                    return (
                        <div className={styles.item} key={coupon.id}>
                            <Coupon
                                coupon={coupon}
                                isActive={coupon.id === activeCouponId}
                                size={CouponSize.BIG}
                                onClick={() => {
                                    dwhExport.send(DWH_EVENTS.APPLY_COUPON, {
                                        bundle_id: popup.data.bundle.id,
                                        coupon_id: coupon.id,
                                    });
                                    popup.data.onClick(coupon.id);
                                }}
                            />
                            <div className={styles.itemTextContent}>{t('Цена с купоном')}</div>
                            <div className={styles.itemContent}>
                                <PriceWrapper
                                    bundle={popup.data.bundle}
                                    coupon={coupon}
                                    size={PRICE_WRAPPER_SIZE.BIG}
                                    isActiveCoupon
                                    withoutAnimation
                                    hiddenPriceWithoutDiscount
                                    priceClassName={styles.price}
                                />
                            </div>
                        </div>
                    );
                })}
            </PopupBody>
            <PopupFooter className={styles.footer}>
                <Button label={t('Закрыть')} onClick={close} />
            </PopupFooter>
        </Popup>
    );
};

export default ChooseCouponPopup;
