import { interpolate, ngettext, t } from '~/utils/localization';
import { AUCTION_KIND } from '~/const';

interface IContentBlock {
    type?: string;
    title?: string;
    youtubeLink?: string;
    description?: string;
    imagesContent?: {
        title?: string;
        subTitle?: string;
        className?: string;
        description?: string;
    }[];
}

interface IPopupSettings {
    title: string;
    titleFirstPrice: string;
    titleSecondPrice: string;
    contentBlocks: IContentBlock[];
}

const DURATION = 4;

const getYoutubeLink = () => window.metashop.settings.auctionYoutubeLink;

const popupSettings: IPopupSettings = {
    title: t('Правила аукциона'),
    titleFirstPrice: t('Правила аукциона первой цены'),
    titleSecondPrice: t('Правила аукциона единой цены'),
    contentBlocks: [
        {
            youtubeLink: getYoutubeLink(),
        },
        {
            title: t('Ставки принимаются в Адмиралтействе'),
            description: t('Принять участие в аукционе можно только в специальной категории Адмиралтейства.'),
        },
        {
            title: t('Выигрывают самые высокие и ранние ставки'),
            description:
                t('Одинаковые наборы на торгах объединены в лоты.') +
                ' ' +
                t('Количество выставленных на аукцион наборов в каждом лоте ограничено, поэтому чем выше ставка и чем раньше она сделана, тем больше вероятность получить предмет.') +
                '<br/>' +
                t('До начала подсчёта результатов аукциона вы можете изменить свою ставку. При этом также изменится время, когда была сделана ставка.'),
        },
        {
            type: AUCTION_KIND.FIRST_PRICE,
            title: t('Аукцион первой цены'),
            description: t(
                'После подсчёта результатов аукциона выигрывают самые высокие ставки. При равном размере ставок выигрывает ставка, сделанная раньше других. Победившая ставка полностью расходуется на приобретение набора из лота. Каждый победитель получит по одному набору.',
            ),
        },
        {
            type: AUCTION_KIND.SECOND_PRICE,
            title: t('Аукцион единой цены'),
            description: t(
                'После подсчёта результатов аукциона выигрывают самые высокие ставки. При равном размере ставок выигрывает ставка, сделанная раньше других. Однако все победители заплатят минимальную сыгравшую ставку, а остальная часть средств будет возвращена на их счета. Каждый победитель получит по одному набору.',
            ),
        },
        {
            title: t('Никаких потерь'),
            description: t('Если ваша ставка не сыграла, не расстраивайтесь! Она автоматически возвращается вам после завершения аукциона.'),
        },
        {
            title: t('Ставки, подсчет результатов, награды победителям'),
            description: t('Весь аукцион состоит из трех этапов:'),
            imagesContent: [
                {
                    subTitle: t('Время ставок.'),
                    className: 'auctionStep1',
                    description:
                        interpolate(ngettext('Ставки принимаются в течение {count} дней.', 'Ставки принимаются в течение {count} дня.', DURATION), {
                            count: DURATION,
                        }) +
                        ' ' +
                        t('В это время так же можно изменять сделанные ставки.'),
                },
                {
                    className: 'delimiter1',
                },
                {
                    subTitle: t('Подсчет результатов.'),
                    className: 'auctionStep2',
                    description: t('Подведение итогов аукциона. Ставки больше не принимаются.'),
                },
                {
                    className: 'delimiter2',
                },
                {
                    subTitle: t('Награждение победителей.'),
                    className: 'auctionStep3',
                    description: t('Победители аукциона получают свои награды, а остальным участникам торгов возвращаются их ставки.'),
                },
            ],
        },
        {
            title: interpolate(ngettext('Всего {count} дня', 'Всего {count} дней', DURATION), {
                count: DURATION,
            }),
            description:
                interpolate(
                    ngettext(
                        'Весь аукцион занимает {count} дня, после чего торги прекращаются до следующего аукциона.',
                        'Весь аукцион занимает {count} дней, после чего торги прекращаются до следующего аукциона.',
                        DURATION,
                    ),
                    {
                        count: DURATION,
                    },
                ) +
                ' ' +
                t('Следите за новостями — о его проведении мы обязательно сообщим в игре и на портале.'),
        },
    ],
};

export default popupSettings;
