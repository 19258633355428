import { isOnlyShipBundle } from '~/utils/bundles';
import { prepareContainsItems } from '~/components/Mrps/MrpsInfo/MrpsInfo';
import { DivTooltip } from '@wg/wows-react-uikit';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ItemTypes, PresentationStyles } from '@wg/wows-entities/const';
import Contains from '~/components/ItemContains/Contains';
import React from 'react';

import styles from './TreasureReward.scss';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import Account from '~/account/Account';
import classNames from 'classnames';
import { UNIQUE_ITEMS } from '~/const';
import { ItemForPreviewPortType, openPortByDefaultItem } from '~/settings/port';
import { isEnabledPortPreviewForReward } from '~/utils/rewardPreview';

type Props = {
    rewardData: TreasureReward;
    inventory: InventoryState;
    withAmount?: boolean;
    withoutPopup?: boolean;
    background?: string;
    hideIsOwned?: boolean;
    fromPopup?: boolean;
    className?: string;
};

export function TreasureRewardItem({ rewardData, inventory, withAmount, withoutPopup, background, hideIsOwned, fromPopup, className }: Props): React.ReactElement {
    const dispatch = useDispatch();
    const initDescriptionPopup = (reward: TreasureReward) => {
        dispatch(changeVisiblePopup(POPUPS_NAME.TREASURE_DESCRIPTION_POPUP, true, { ...reward, background }));
    };
    const isShip = isOnlyShipBundle(rewardData);
    const isComplex = rewardData.entitlements.length > 1 && !isShip;

    if (isComplex) {
        const preparedItem = prepareContainsItems(rewardData.entitlements, inventory, false);
        return <DivTooltip className={styles.bundleWrapper} tooltipBody={<Contains items={preparedItem} wrapperClassName={styles.bundleTooltip} />} />;
    }

    const reward = rewardData.entitlements[0];
    const hasDescription = reward.type === ItemTypes.LOOTBOX || reward.type === ItemTypes.VEHICLES || reward.type === ItemTypes.CREWS;
    const isEnabledPortPreview = isEnabledPortPreviewForReward(reward) && !hideIsOwned;

    function handleClick(reward: TreasureLoadedEntitlement) {
        if (isEnabledPortPreview && !fromPopup) {
            window?.tooltipProvider?.hide?.();
            openPortByDefaultItem({ ...reward } as ItemForPreviewPortType);
            return;
        } else if (!withoutPopup && hasDescription) {
            initDescriptionPopup(rewardData);
        }
    }

    const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(reward.type, reward?.identifier, reward.customisation?.shipId);

    const colored = { '--color': rewardData?.color } as React.CSSProperties;

    return (
        <div className={classNames(styles.reward, !isShip && styles.common, (!hasDescription || withoutPopup) && styles.noBackgroundChange, className)}>
            {rewardData?.color && (
                <>
                    <img className={styles.shinyAnimation} style={colored}></img>
                    <img className={classNames(styles.shinyAnimation, styles.reverse)} style={colored}></img>
                </>
            )}
            <WoWSEntity
                type={reward.type}
                id={reward?.identifier}
                amount={withAmount ? reward.amount : undefined}
                onClick={() => handleClick(reward)}
                presentation={{
                    style: !isShip ? PresentationStyles.WIDGET : PresentationStyles.CARD,
                    withTooltip: true,
                    withText: false,
                    renderWithoutFade: true,
                    imgSrc: rewardData?.imagePreview,
                    hideTooltipImg: true,
                    showPreviewElement: isEnabledPortPreview && !fromPopup,
                }}
                customisation={{
                    inventoryCount: !UNIQUE_ITEMS.includes(reward.type) && inventoryCount,
                    isExistsInInventory: isExistsItemInInventory && !hideIsOwned,
                }}
                className={!isShip ? styles.commonCard : undefined}
            />
        </div>
    );
}
