import * as React from 'react';
import styles from './Layouts.scss';
import classNames from 'classnames';
import BundleManager from '~/components/Bundle/BundleManager';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { Button } from '~/components/Button/Buttons';
import { t } from '~/utils/localization';
import { openCategoryByName } from '~/utils/category';

interface IBundlesListSubLayout {
    title: Nullable<string>;
    bundlesList: PreviewCategoryBundlesList[];
    categoryName: string;
}

interface IStateSelector {
    bundles: IBundleList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        bundles: state.ReducerApp.bundles,
    };
};

const BundlesListSubLayout = ({ title, bundlesList, categoryName }: IBundlesListSubLayout) => {
    const { bundles } = useSelector<State, IStateSelector>(stateSelector, equal);

    const showMore = () => {
        openCategoryByName(categoryName);
    };

    return (
        <div className={classNames(styles.layout, 'bundles-layout')} data-category-layout={categoryName}>
            <div className={styles.layoutHeader}>
                <div className={styles.layoutTitle}>{title}</div>
                <div className={styles.layoutBorder} />
            </div>
            <div className={classNames(styles.layoutColumns)}>
                <div className={classNames(styles.columns, 'columns')}>
                    {bundlesList.map(({ image, id, width }) => {
                        const bundle = bundles[id];

                        if (!bundle) {
                            return null;
                        }

                        return <BundleManager key={`bundle_${id}`} bundle={bundle} image={image} width={width} />;
                    })}
                </div>
                <div className={styles.buttonShowMoreWrapper}>
                    <Button label={t('Перейти в категорию')} onClick={showMore} className={[styles.buttonShowMore, 'armory__auto--button_category']} />
                </div>
            </div>
        </div>
    );
};

export default BundlesListSubLayout;
