import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import store from '~/Store';
import { toggleExchangeMenu } from '~/Actions/ActionMrps';
import { blurBackground } from '~/Actions/ActionApp';
import equal from 'fast-deep-equal';
import MrpsResourceBlockItem from '~/components/Mrps/MrpsResourceBlockItem/MrpsResourceBlockItem';

import styles from './MrpsResourceBlock.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { playButtonClickSound } from '@wg/web2clientapi/sound';

interface StateSelector {
    resources: MrpsResource[];
    isExchangeMenuOpen: MrpsExchangeMenuStatus;
}

function stateSelector(state: State): StateSelector {
    const eventName = state.reducerMrps.currentEvent.eventName;

    return {
        isExchangeMenuOpen: state.reducerMrps.currentEvent.data.isExchangeMenuOpen,
        resources: state.reducerMrps.events.find((event) => event.name === eventName).currencies,
    };
}

export default function MrpsResourcesBlock() {
    const state = useSelector<State, StateSelector>(stateSelector, equal);

    function closeExchangeMenu() {
        playButtonClickSound();

        store.dispatch(toggleExchangeMenu(0));
        store.dispatch(blurBackground(0));
    }

    return (
        <div
            className={classNames(styles.maskWrapper, {
                [styles.maskWrapper_show]: state.isExchangeMenuOpen === 1,
                [styles.maskWrapper_hide]: state.isExchangeMenuOpen === 0,
            })}
        >
            <div
                className={classNames(styles.mask, {
                    [styles.mask_show]: state.isExchangeMenuOpen === 1,
                    [styles.mask_hide]: state.isExchangeMenuOpen === 0,
                })}
            >
                <div className={styles.wrapper}>
                    {state.resources.map((resource) => (
                        <MrpsResourceBlockItem resource={resource} key={`respurce_${resource.name}`} />
                    ))}
                </div>
            </div>
            <button
                className={classNames(styles.wrappButton, {
                    [styles.wrappButton_hide]: state.isExchangeMenuOpen === 0,
                })}
                onClick={closeExchangeMenu}
            >
                <div className={styles.wrappButtonText}>{t('Свернуть')}</div>
                <div className={styles.wrappButtonArrows} />
            </button>
        </div>
    );
}
