import Account from '~/account/Account';
import { settings } from '~/utils/settings';
import { get, post } from '~/utils/ajax';
import { logError } from '~/utils/logging';

export async function getMrpsEvents(): Promise<MrpsResponse> {
    const baseUrl = settings.urls.mrps;
    if (!baseUrl) return;
    const url = new URL('/', baseUrl);

    try {
        const result: MrpsResponse = await get(url.toString(), null, false, true, true, {
            credentials: 'include',
        });
        return result;
    } catch (e) {
        logError('MRPS is unavailable', e);
    }
}

export async function getMrpsAccountData(): Promise<MrpsAccountData> {
    const baseUrl = settings.urls.mrps;
    if (!Account.getAccount()?.id || !baseUrl) return;
    const url = new URL('/api/accounts/', baseUrl);
    try {
        const result: MrpsAccountData = await get(url.toString(), null, false, true, true, {
            credentials: 'include',
        });
        return result;
    } catch (e) {
        logError('MRPS is unavailable', e);
    }
}

export async function postMrpsProgress(params: MrpsPostProgress): Promise<MrpsAccountData> {
    const baseUrl = settings.urls.mrps;
    if (!Account.getAccount()?.id || !baseUrl) return;
    const url = new URL('/api/accounts/', baseUrl);
    try {
        const result: MrpsAccountData = await post(url.toString(), params, null, false, true, true, {
            credentials: 'include',
        });
        // TODO: check request errors
        return result;
    } catch (e) {
        logError('MRPS is unavailable', e);
    }
}
