import { SessionStorageHelper } from '~/utils/storage';
import { isInGameBrowser, isMobileOrTabletWindow, randomHash } from '~/utils/utils';
import { getPlatform, settings } from '~/utils/settings';

const ANALYTICS_KEY = 'accountAnalyticsData';
interface AccountAnalyticsData {
    platform?: string;
    source?: string;
    campaign?: string;
    medium?: string;
}
const dataAllowedKeys = ['platform', 'source', 'campaign', 'medium'];

let _analyticsDataCache: AccountAnalyticsData | null = null;
let _frontendSessionId: string | null = null;

export function saveAnalyticsData() {
    const params = new URLSearchParams(document.location.search.substring(1));
    const data = _getSavedAnalyticsData() || {};

    data.platform = data.platform || _determinePlatform();
    data.source = data.source || _getCleanedParam(params, settings.analyticsSourceParameterName || 'utm_source');
    data.campaign = data.campaign || _getCleanedParam(params, settings.analyticsCampaignParameterName || 'utm_campaign');
    data.medium = data.medium || _getCleanedParam(params, settings.analyticsMediumParameterName || 'utm_medium');
    SessionStorageHelper.set(ANALYTICS_KEY, data);

    _analyticsDataCache = data;
}

export function getAnalyticsData(): AccountAnalyticsData | null {
    if (_analyticsDataCache !== null) {
        return _analyticsDataCache;
    }

    const data = _getSavedAnalyticsData();
    if (data !== null) {
        _analyticsDataCache = data;
    }

    return data;
}

export function getFrontendSessionId() {
    if (!_frontendSessionId) {
        _frontendSessionId = randomHash(20);
    }

    return _frontendSessionId;
}

function _determinePlatform() {
    const platformWeb = settings.analyticsPlatformWeb || 'web';
    const platformMobile = settings.analyticsPlatformMobile || 'mobile';

    let platform;

    if (isInGameBrowser) {
        platform = getPlatform();
    } else {
        platform = isMobileOrTabletWindow ? platformMobile : platformWeb;
    }

    return platform;
}

function _getCleanedParam(params: URLSearchParams, name: string, maxLen = 20): string | null {
    let value = params.get(name);

    if (value) {
        value = ('' + value).replace(/[^a-z0-9-_]/gi, '');
    }

    if (value && maxLen) {
        value = value.substring(0, maxLen);
    }

    return value || null;
}

function _getSavedAnalyticsData(): AccountAnalyticsData | null {
    let data = SessionStorageHelper.get(ANALYTICS_KEY);

    if (!_isAnalyticsDataValid(data)) {
        SessionStorageHelper.remove(ANALYTICS_KEY);
        data = null;
    }

    return data;
}

function _isAnalyticsDataValid(data: any) {
    if (typeof data !== 'object' || data === null) {
        return false;
    }

    for (const [key, val] of Object.entries(data)) {
        if (!dataAllowedKeys.includes(key) || !(typeof val === 'string' || val === null)) {
            return false;
        }
    }

    return true;
}
