import classNames from 'classnames';
import styles from './CommonRewardsLayout.scss';
import { groupedRewardsTextForTooltip } from '~/Layouts/Lootboxes/utils/texts';
import { interpolate, t } from '~/utils/localization';
import CommonReward from './CommonReward';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';

interface IGroupedLootboxRewardsTooltip {
    lootboxId?: number;
    rewards: ILootboxReward[];
    renderTextAboutChance?: boolean;
    theme?: ALLOWED_THEMES;
}

export const LIMIT_FOR_SHOW = 5;

const GroupedLootboxRewardsTooltip = ({ rewards, renderTextAboutChance, lootboxId, theme }: IGroupedLootboxRewardsTooltip) => {
    const hasLimits = !(LIMIT_FOR_SHOW + 1 === rewards?.length);
    const previewRewards = hasLimits ? [...rewards].splice(0, LIMIT_FOR_SHOW) : rewards;
    const leftRewards = rewards.length - LIMIT_FOR_SHOW;

    const classesGroupedTooltipContent = classNames(styles.groupedTooltipContent, {
        [styles.borderBottom]: renderTextAboutChance,
    });

    return (
        <div className={styles.groupedTooltip}>
            <div className={styles.groupedTooltipBody}>
                <div className={classNames(styles.text, styles.headerText)}>{groupedRewardsTextForTooltip}</div>
                <div className={classesGroupedTooltipContent}>
                    {previewRewards.map((reward, index) => {
                        return (
                            <CommonReward
                                lootboxId={lootboxId}
                                className={styles.groupedTooltipRewardItem}
                                chanceLabelClassName={styles.groupedTooltipRewardChanceLabel}
                                key={`tooltip_grouped_reward_${index}_${reward.id}`}
                                reward={reward}
                                theme={theme}
                            />
                        );
                    })}
                    {hasLimits && leftRewards > 0 && <div className={styles.groupedTextFooter}>{interpolate(t('и ещё {count}'), { count: leftRewards })}</div>}
                </div>
                {renderTextAboutChance && (
                    <div className={classNames(styles.tooltipFooter)}>{t('Вероятность получения награды округлена с точностью до сотых по стандартным математическим правилам.')}</div>
                )}
            </div>
        </div>
    );
};

export default GroupedLootboxRewardsTooltip;
