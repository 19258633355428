import * as React from 'react';
import styles from './Filters.scss';
import { useSelector, shallowEqual } from 'react-redux';
import { State } from '~/Reducers';
import { arrayToObjectByKey, formatNumber, getDiapasonOfNumbers, getLevelFromRomanFormat, getNumbersFromDiapason, isEmptyObject } from '~/utils/utils';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getFiltersInfo, HIDDEN_FILTERS_NAMES } from '~/settings/filtersMap';
import { t } from '~/utils/localization';
import Tag from '~/components/Tag/Tag';
import { FadeInDiv } from '~/components/Transitions';
import { Interpolate } from '@wg/wows-react-uikit';
import { getCurrency } from '~/utils/currencies';
import classNames from 'classnames';
import { FILTER_SHIP_TIER_NAME, FILTER_SHIP_CLASS_NAME, FILTER_NATION_NAME, FILTER_CURRENCY_NAME, NO_NATION_ITEM, FILTER_SHIP_COMPLEXITY_NAME } from '~/const';

export type ACTIVE_FILTERS_VALUES = {
    values: any[];
    diapasons?: Record<string, Diapason_Type>;
};
export type ACTIVE_FILTERS_TYPE = Partial<Record<FILTER_INFO_NAME, ACTIVE_FILTERS_VALUES>>;

interface IActiveFilter {
    activeFilters: ACTIVE_FILTERS_TYPE;
    resetAll: (event: React.MouseEvent) => void;
    onClick: (filterName: FILTER_INFO_NAME, value: any) => void;
    className?: string;
}

interface IRenderActiveFilterByType {
    type: FILTER_INFO_NAME;
    filters: ACTIVE_FILTERS_VALUES;
    onClick?: (filterName: FILTER_INFO_NAME, value: any) => void;
}

interface IStateSelector {
    nations: Record<string, INation>;
    vehicleTypes: Record<string, IVehicleClass>;
    complexities: Record<string, IComplexity>;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        nations: state.ReducerApp.nations,
        vehicleTypes: state.ReducerApp.vehicleTypes,
        complexities: state.ReducerApp.complexities,
    };
};

export const RenderActiveFilterByType = ({ type, filters, onClick }: IRenderActiveFilterByType) => {
    const appState = useSelector<State, IStateSelector>(stateSelector, shallowEqual);
    const data = arrayToObjectByKey(getFiltersInfo()[type].items, 'value');
    const nations = appState.nations || {};
    const vehicleTypes = appState.vehicleTypes || {};
    const complexities = appState.complexities || {};
    const allComplexityLevels = Object.keys(complexities).length;
    let diapasonLevels: string[] | null = null;

    if (type === FILTER_SHIP_COMPLEXITY_NAME) {
        const activeComplexityLevels = filters.values;

        return (
            <React.Fragment>
                {activeComplexityLevels.map((level: number, index) => {
                    const complexityItem = complexities[level];
                    return (
                        <FadeInDiv key={level + index}>
                            <Tag
                                classNames={{ tag: styles.filterTag }}
                                tooltipBody={<DefaultTooltip text={complexityItem.header} />}
                                showCloseIcon={true}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onClick?.(type, level);
                                }}
                                label={
                                    <div className={styles.complexityIconsWrapper}>
                                        {new Array(allComplexityLevels).fill(0).map((_, index) => {
                                            const iconStyles: React.CSSProperties = {
                                                backgroundImage: `url(${index < complexityItem?.level ? complexityItem?.icons.filled : complexityItem?.icons.empty})`,
                                            };
                                            return <div key={`complexity_icon_${index}`} className={styles.complexityIconWrapper} style={iconStyles}></div>;
                                        })}
                                    </div>
                                }
                                isActiveFiltersTag={true}
                            />
                        </FadeInDiv>
                    );
                })}
            </React.Fragment>
        );
    }

    if (type === FILTER_SHIP_TIER_NAME) {
        const numbers = filters.values.map((item) => parseInt(item, 10));
        diapasonLevels = getDiapasonOfNumbers(numbers);

        return (
            <React.Fragment>
                {diapasonLevels.map((diapason) => {
                    let content = null;
                    const diapasonData = diapason.split ? diapason.split('-') : [];
                    let numbers: number[] | number;
                    if (diapasonData.length > 1) {
                        content = `${getLevelFromRomanFormat(+diapasonData[0])}-${getLevelFromRomanFormat(+diapasonData.pop())}`;
                        numbers = getNumbersFromDiapason(diapason);
                    } else {
                        content = getLevelFromRomanFormat(+diapason);
                        numbers = +diapason;
                    }

                    return (
                        <FadeInDiv key={`${type}_${diapason}`}>
                            <Tag
                                classNames={{ tag: styles.filterTag }}
                                label={content}
                                showCloseIcon={true}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onClick(type, numbers);
                                }}
                                isActiveFiltersTag={true}
                            />
                        </FadeInDiv>
                    );
                })}
            </React.Fragment>
        );
    }

    if (type === FILTER_NATION_NAME || type === FILTER_SHIP_CLASS_NAME) {
        return (
            <React.Fragment>
                {filters.values.map((filterName: string, index) => {
                    const data = type === FILTER_NATION_NAME ? nations[filterName] : vehicleTypes[filterName];
                    const iconClassNames = classNames(styles.iconWrapper, {
                        [styles.iconClass]: type === FILTER_SHIP_CLASS_NAME,
                        [styles.activeFilterItemClassIcon]: type === FILTER_SHIP_CLASS_NAME,
                        [styles.iconNation]: type === FILTER_NATION_NAME,
                        [styles.iconNation_noNation]: data?.name === NO_NATION_ITEM.name,
                    });

                    const icon = type === FILTER_NATION_NAME ? (data as INation).icons?.tiny || data.icons?.default : data.icons?.default;

                    return (
                        <FadeInDiv key={filterName + type + index}>
                            <Tag
                                classNames={{ tag: styles.filterTag }}
                                tooltipBody={<DefaultTooltip text={data.title} />}
                                showCloseIcon={true}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onClick?.(type, filterName);
                                }}
                                label={
                                    <div
                                        className={iconClassNames}
                                        style={{
                                            // @ts-ignore
                                            ['--backgroundImage']: `url(${icon})`,
                                        }}
                                    />
                                }
                                isActiveFiltersTag={true}
                            />
                        </FadeInDiv>
                    );
                })}
            </React.Fragment>
        );
    }

    if (type === FILTER_CURRENCY_NAME) {
        return (
            <React.Fragment>
                {filters.values.map((filterName: string) => {
                    const currencyName = data[filterName].value;
                    const diapason = filters.diapasons?.[filterName];
                    let min, max;

                    if (diapason?.min && diapason?.max) {
                        [min, max] = [diapason.min, diapason.max];
                    }

                    const currencyConfig = getCurrency(currencyName);
                    const icon = currencyConfig?.icons?.default;

                    const currencyText = t('{currencyName} {min} - {max}').replace('{currencyName}', '%(currencyName)s').replace('{min}', '%(min)s').replace('{max}', '%(max)s');

                    return (
                        <FadeInDiv key={filterName}>
                            <Tag
                                label={
                                    <Interpolate
                                        str={min === max || min === undefined || max === undefined ? '%(currencyName)s' : currencyText}
                                        min={formatNumber(min)}
                                        max={formatNumber(max)}
                                        currencyName={
                                            <div
                                                className={styles.activeCurrencyIcon}
                                                style={{
                                                    backgroundImage: `url(${icon})`,
                                                }}
                                            />
                                        }
                                    />
                                }
                                tooltipBody={<DefaultTooltip text={currencyConfig.title} />}
                                classNames={{ tag: styles.filterCurrencyTag, closeIcon: styles.currencyIconCloseTag }}
                                showCloseIcon={true}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onClick?.(type, filterName);
                                }}
                                isActiveFiltersTag={true}
                            />
                        </FadeInDiv>
                    );
                })}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {filters.values.map((filterName: string) => {
                return (
                    <FadeInDiv key={filterName}>
                        <Tag
                            label={data[filterName].title}
                            classNames={{ tag: styles.filterTag }}
                            showCloseIcon={true}
                            onClick={(event) => {
                                event.stopPropagation();
                                onClick?.(type, filterName);
                            }}
                            isActiveFiltersTag={true}
                        />
                    </FadeInDiv>
                );
            })}
        </React.Fragment>
    );
};

const ActiveFilters = ({ className, activeFilters, resetAll, onClick }: IActiveFilter) => {
    return (
        <div className={classNames(styles.activeFiltersWrapper, styles.activeFilterContent, className)}>
            {Object.keys(activeFilters).map((key: FILTER_INFO_NAME, index) => {
                if (HIDDEN_FILTERS_NAMES.includes(key) || !activeFilters?.[key]?.values.length) {
                    return;
                }

                return <RenderActiveFilterByType key={`${key}_${index}`} type={key} filters={activeFilters?.[key]} onClick={onClick} />;
            })}
            {!isEmptyObject(activeFilters) && (
                <FadeInDiv>
                    <Tag
                        label={<div className={styles.resetFilters}>{t('Сбросить все')}</div>}
                        showCloseIcon={false}
                        classNames={{
                            tag: styles.filterResetTag,
                        }}
                        onClick={resetAll}
                        isActiveFiltersTag={true}
                        resetTag={true}
                    />
                </FadeInDiv>
            )}
        </div>
    );
};

export default ActiveFilters;
