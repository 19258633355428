import * as React from 'react';
import filtersMap, { getFiltersInfo } from '~/settings/filtersMap';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import styles from '~/components/CategoryFilter/CategoryFilter.scss';
import prepareFiltersItems from '~/components/CategoryFilter/FilterItems';
import classNames from 'classnames';
import { FILTER_CURRENCY_NAME } from '~/const';
import { getAvailableFilters, isNeedToRenderCurrencyFilter } from '~/utils/filters';
import { ReactNode } from 'react';

interface IFilterItemsProps {
    category: ICategoryList;
    onChangeFilter: (name: string, value: string) => void;
    state?: Record<string, string[]>;
    isDisplayDefault?: boolean;
}

const FiltersContent = ({ category, onChangeFilter, state, isDisplayDefault }: IFilterItemsProps): any => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef();
    const filterNames = filtersMap[category];

    useMaskScrollEffect(ref);

    const filterContentMap: Record<FILTER_INFO_NAME, ReactNode | null> = {
        tier: null,
        class: null,
        nation: null,
        crew_type: null,
        ship_type: null,
        boost_type: null,
        camouflage_type: null,
        doll_type: null,
        signal_type: null,
        complexity: null,
        rarity_type: null,
        currency: null,
        discount_type: null,
        crew_category: null,
        allowed_bundles: null,
    };

    filterNames.forEach((name: FILTER_INFO_NAME) => {
        const filter = getFiltersInfo()[name];
        if (!filter) return;

        const availableItems = getAvailableFilters(category, name);

        if (availableItems.length < 2) {
            if (filter.isForcedDisplay && !availableItems.length) {
                return;
            }

            if (!filter.isForcedDisplay && !(filter.name === FILTER_CURRENCY_NAME && isNeedToRenderCurrencyFilter(category, availableItems[0]))) {
                return;
            }
        }

        const classesFilterColumn = classNames(styles.filterColumn, styles[name]);
        const classesDivider = classNames(styles.columnsDivider, {
            [styles.displayRight]: !isDisplayDefault,
        });

        filterContentMap[name] = (
            <React.Fragment key={`filter_column_fragment_${name}`}>
                <div className={classesFilterColumn}>
                    <div className={styles.filterColumnTitle}>{filter.title}</div>
                    <div className={styles.filterColumnBody}>{prepareFiltersItems(category, name, onChangeFilter, state)}</div>
                </div>
                <div className={classesDivider}>
                    <div className={styles.leftSideDivider}></div>
                    <div className={styles.centerDivider}></div>
                    <div className={styles.rightSideDivider}></div>
                </div>
            </React.Fragment>
        );
    });

    return (
        <div className={styles.columns} ref={ref}>
            {Object.values(filterContentMap)}
        </div>
    );
};

export default FiltersContent;
