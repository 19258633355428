import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

import store from '~/Store';
import { State } from '~/Reducers';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { IPopupSoundset } from '~/types/soundsets';
import { t } from '~/utils/localization';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';

import { OrangeButton } from '~/components/Button/Buttons';
import { Popup, PopupHeader, PopupBody, PopupFooter } from '~/components/Popup/Popup';
import SerialItemsSmallCarouselItem from '~/components/SerialItemsSmallCarousel/SerialItemsSmallCarouselItem';

import styles from './SerialSequencePurchase.scss';

interface ISerialSequenceSucessPurchase {
    data: {
        bundleIds: number[];
    };
}

interface IStateSelector {
    port: IPort;

    bundles: IBundleList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        port: state.ReducerApp.port,

        bundles: state.ReducerApp.bundles,
    };
};

const AnimatedDiv = ({ children, index }: { children: React.ReactChild; index: number }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        ref.current.classList.add(styles.done);
    }, []);

    return (
        <div
            className={classNames(styles.animatedDiv)}
            style={{ transitionDelay: `${0.1 + 0.1 * index}s` }}
            ref={(_ref) => {
                if (_ref) {
                    ref.current = _ref;
                }
            }}
        >
            {children}
        </div>
    );
};

const SerialSequenceSucessPurchase = ({ data }: ISerialSequenceSucessPurchase) => {
    const { bundles, port } = useSelector<State, IStateSelector>(stateSelector, equal);
    const itemsRef = React.useRef<HTMLDivElement>(null);
    const { bundleIds } = data;
    const sequence = bundleIds.map((bundleId) => bundles[bundleId]);

    const close = () => {
        store.dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
    };

    useMaskScrollEffect(itemsRef);

    return (
        <Popup
            onClose={close}
            outsideClick={false}
            renderEscButton={true}
            popupClassName={classNames(styles.popupWrapper, {
                [styles.popupWrapper_portVisible]: port.isVisible,
            })}
            className={classNames(styles.popup, 'armory__auto--success-purchase-popup')}
            soundset={IPopupSoundset.EXCHANGE}
        >
            <PopupHeader title={t('Спасибо за покупку!')} />
            <PopupBody className={styles.body}>
                <div
                    className={styles.items}
                    ref={(_ref) => {
                        if (_ref) {
                            itemsRef.current = _ref;
                        }
                    }}
                >
                    {sequence.map((bundle, index) => {
                        return (
                            <AnimatedDiv key={`purchase_success_item_${bundle.id}_${bundle.serialIndex}`} index={index}>
                                <SerialItemsSmallCarouselItem className={styles.item} bundle={bundle} showTitle />
                            </AnimatedDiv>
                        );
                    })}
                </div>
            </PopupBody>
            <PopupFooter>
                <OrangeButton label={t('Закрыть')} onClick={close} className={classNames('armory__auto--success_purchase_button')} />
            </PopupFooter>
        </Popup>
    );
};

export default SerialSequenceSucessPurchase;
