import * as React from 'react';
import styles from './Currency.scss';
import { formatNumber } from '~/utils/utils';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import classNames from 'classnames';
import AnimatedNumber from '~/components/AnimatedNumber/AnimatedNumber';
import { getCurrency } from '~/utils/currencies';
import SmallPreloader from '~/components/SmallPreloader/SmallPreloader';
import { assets } from '@wg/wows-entities/const';
import { CURRENCY } from '~/const';
import { interpolate, ngettext, t } from '~/utils/localization';
import CurrencyTooltip from '~/components/Tooltip/CurrencyTooltip';

interface ICurrency {
    currency: string;
    amount?: number | string;
    withoutTooltip?: boolean;
    withoutIcon?: boolean;
    showDescriptionTooltip?: boolean;
    isSmallIcon?: boolean;
    className?: string;
    iconClassName?: string;
    withoutAnimation?: boolean;
    isProgress?: boolean;
    useCustomIcon?: boolean;
}

const AVAILABLE_CUSTOM_ICON_FOR_CURRENCY: string[] = [
    assets.GOLD,
    assets.CREDITS,
    assets.STEEL,
    assets.COAL,
    assets.PARAGON_XP,
    assets.RECRUITMENT_POINTS,
    assets.BRASS,
    assets.FREE_XP,
    assets.MOLYBDENUM,
    assets.WOWS_PREMIUM,
];

const Currency = React.memo((props: ICurrency) => {
    const data = getCurrency(props.currency);
    let formatter = (n: number) => formatNumber(parseInt(String(n)));

    if (props.currency === CURRENCY.WARSHIPS_PREMIUM) {
        data.title = t('Корабельный премиум аккаунт');

        formatter = (n: number) => interpolate(ngettext('{count} день', '{count} дня', parseInt(String(n))), { count: n });
    }

    const style: React.CSSProperties = {};
    const isAvailableCustomIcon = AVAILABLE_CUSTOM_ICON_FOR_CURRENCY.includes(props.currency);
    if (!isAvailableCustomIcon) {
        style['backgroundImage'] = `url(${props.isSmallIcon ? data.icons.small : data.icons.default})`;
    }

    const iconClassNames = classNames(
        styles.icon,
        {
            [styles.small]: props.isSmallIcon,
            [styles.customIcon]: isAvailableCustomIcon,
        },
        styles[`icon_${props.currency}`],
        props.iconClassName,
    );

    let tooltipBody = null;
    if (!props.withoutTooltip) {
        if (props.showDescriptionTooltip) {
            tooltipBody = <CurrencyTooltip currency={props.currency} isVisibleTitle />;
        } else {
            tooltipBody = <DefaultTooltip text={props.showDescriptionTooltip ? data.description : null} title={data.title} />;
        }
    }

    const amountClassNames = classNames(styles.amount, [styles[`currency-${props.currency}`]], props.className);

    const inner = (
        <div className={styles.wrapper}>
            {props.amount !== undefined && props.amount !== null && !props.isProgress ? (
                <div className={amountClassNames}>
                    <AnimatedNumber value={Number(props.amount)} duration={props.withoutAnimation ? 0 : 0.3} formatter={formatter} />
                </div>
            ) : props.isProgress ? (
                <SmallPreloader />
            ) : null}
            {!props.withoutIcon && <div className={iconClassNames} style={style} />}
        </div>
    );

    return tooltipBody ? <DivTooltip tooltipBody={tooltipBody}>{inner}</DivTooltip> : <div>{inner}</div>;
});

export default Currency;
