import * as React from 'react';
import styles from './CategoryFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { t } from '~/utils/localization';
import Tag from '~/components/Tag/Tag';
import { updateActiveFilterPreset } from '~/Actions/ActionApp';
import { IUpdateActiveFilterPreset } from '~/Actions/ActionAppType';
import { scrollTop } from '~/utils/scrollTop';
import { FilterPosition } from '~/types/category';

interface IFilterPresets {
    category: ICategoryList;
}

interface IFilterPresetsState {
    categories: ICategories;
    activePreset: string;
    filterPosition: FilterPosition;
}

const stateSelector = (state: State): IFilterPresetsState => {
    return {
        categories: state.ReducerApp.categories,
        activePreset: state.ReducerApp.activePreset,
        filterPosition: state.ReducerAccount.filterPosition,
    };
};

const title = t('Подборки');

const FilterPresets = ({ category }: IFilterPresets) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IFilterPresetsState>(stateSelector, equal);
    const filtersPresets = state.categories[category]?.filters?.presets;

    return (
        <div className={styles.presetsWrapper}>
            <div
                className={styles.presetsItems}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                {filtersPresets.map((preset) => {
                    const isActive = state.activePreset === preset.name;

                    if (!preset.type && !preset.bundles?.length) {
                        return null;
                    }

                    return (
                        <Tag
                            key={preset.name}
                            label={preset.title}
                            isActive={isActive}
                            showCloseIcon={isActive}
                            onClick={() => {
                                scrollTop(0, state.filterPosition === FilterPosition.RIGHT);
                                dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(preset.name));
                            }}
                            classNames={{
                                tag: styles.filterPreset,
                                activeTag: styles.active,
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default FilterPresets;
