import * as React from 'react';
import Discount, { DiscountSize } from '~/components/Discount/Discount';
import { interpolate, ngettext, t } from '~/utils/localization';
import styles from './ProgressiveDiscount.scss';

interface IProgressiveDiscountTooltip {
    data: DiscountDiapason[];
    parentBundleTitle: string;
    childBundleTitle: string;
    amount: number;
    hideFirstParagraph?: boolean;
    isFullBundle?: boolean;
    singleLimit?: number;
}

const ProgressiveDiscountTooltip = ({ amount, data, parentBundleTitle, childBundleTitle, hideFirstParagraph, isFullBundle, singleLimit }: IProgressiveDiscountTooltip) => {
    const textForFullPurchaseBundle = t('Приобретая все случайные наборы "{name}", вы гарантированно получаете всё имущество, которое в них содержится.');
    const defaultText = ngettext('{amount} случайный набор "{name}", включая "{childBundle}"', '{amount} случайных набора "{name}", включая "{childBundle}"', amount);
    const commonText = interpolate(isFullBundle ? textForFullPurchaseBundle : defaultText, {
        amount,
        name: parentBundleTitle,
        childBundle: childBundleTitle,
    });

    if (!data?.length) {
        return null;
    }

    return (
        <div className={styles.tooltipWrapper}>
            <div className={styles.tooltipBody}>
                {!hideFirstParagraph && <div className={styles.tooltipAboutText}>{commonText}</div>}
                <div className={styles.tooltipTextAboutDiscount}>
                    <span>{t('Размер скидки зависит от количества одновременно приобретаемых наборов.')}</span>
                    <div className={styles.tooltipProgressiveDiscountItems}>
                        {data.map((item, index) => {
                            if (!item.discount || !Array.isArray(item.diapason)) {
                                return null;
                            }
                            const [min, max] = item.diapason;
                            let text = interpolate(ngettext('от {amount} набора', 'от {amount} наборов', min), {
                                amount: min,
                            });
                            if (min === max || min === singleLimit) {
                                text = interpolate(ngettext('{amount} набор', '{amount} набора', min), {
                                    amount: min,
                                });
                            }
                            return (
                                <div className={styles.tooltipProgressiveDiscountItem} key={`progressive_discount_${item.discount}_${index}`}>
                                    <div className={styles.tooltipProgressiveDiscountItemAmount}>{text}</div>
                                    <div className={styles.tooltipProgressiveDiscountItemDiscount}>
                                        <Discount discount={item.discount} size={DiscountSize.SMALL} className={styles.discountLabel} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressiveDiscountTooltip;
