import * as React from 'react';
import store from '~/Store';
import equal from 'fast-deep-equal/react';
import styles from './DevModePanel.scss';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '~/Reducers';
import { LocalStorageHelper } from '~/utils/storage';
import { devModeKeyMap } from '~/utils/devMode';
import { Button } from '~/components/Button/Buttons';
import { IDevModeParams } from '~/Actions/ActionAccountType';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { changeViewBackground, changeVisiblePopup, startVideo } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { preloaded_state } from '~/api/mocks/auction';
import classNames from 'classnames';
import { finishAuction, restartAuction, makeFakeBids as apiMakeFakeBids, startWinnersChoosing, fetchWinnersFromBranches, chooseAuctionWinners, sendRewardsAndCompensation } from '~/api/auction';
import AuctionStorage from '~/core/AuctionStorage';
import Spoiler from '~/components/Spoiler/Spoiler';
import { LOCAL_STORAGE_WOWS_CLIENT_DEV } from '~/utils/keys';
import { t } from '~/utils/localization';
import { settings } from '~/utils/settings';
import { CATEGORIES } from '~/const';
import { isEmptyObject } from '~/utils/utils';
import { getCookie, setCookie } from '~/utils/cookie';

const AuctionDebugPanel = () => {
    const app = store.getState().ReducerAuction;
    const auctionIdInput = React.useRef<HTMLSelectElement>(null);
    const lotIdInput = React.useRef<HTMLSelectElement>(null);
    const countFakeBidsInput = React.useRef<HTMLInputElement>(null);
    const bidSizeInput = React.useRef<HTMLInputElement>(null);

    const [selectedAuctionId, setAuctionId] = React.useState<string>(null);
    const [realm, setRealm] = React.useState<string>(settings.realm);

    const finish = async () => {
        const auctionId = auctionIdInput.current.value;
        if (auctionId === '0') {
            return;
        }
        await finishAuction(auctionId, realm);
        await AuctionStorage.preloadedState();
    };

    const restart = async () => {
        const auctionId = auctionIdInput.current.value;
        if (auctionId === '0') {
            return;
        }
        await restartAuction(auctionId, realm);
        await AuctionStorage.preloadedState();
    };

    const _startWinnersChoosing = async () => {
        const auctionId = auctionIdInput.current.value;

        await startWinnersChoosing(auctionId, realm);
        await AuctionStorage.preloadedState();
    };

    const _fetchWinnersFromBranches = async () => {
        const auctionId = auctionIdInput.current.value;
        await fetchWinnersFromBranches(auctionId, realm);
        await AuctionStorage.preloadedState();
    };

    const _chooseAuctionWinners = async () => {
        const auctionId = auctionIdInput.current.value;
        await chooseAuctionWinners(auctionId, realm);
        await AuctionStorage.preloadedState();
    };

    const _sendRewardsAndCompensation = async () => {
        const auctionId = auctionIdInput.current.value;
        await sendRewardsAndCompensation(auctionId, realm);
        await AuctionStorage.preloadedState();
    };

    const makeFakeBids = async () => {
        const auctionId = auctionIdInput.current.value;
        const lotId = lotIdInput.current.value;
        const count = countFakeBidsInput.current.value;
        const bidSize = bidSizeInput.current.value;

        if (!lotId.length || !count.length || !bidSize.length) {
            return;
        }

        if (auctionId === '0' || lotId === '0') {
            return;
        }

        await apiMakeFakeBids(auctionId, lotId, count, bidSize, realm);
        await AuctionStorage.preloadedState();

        auctionIdInput.current.value = lotIdInput.current.value = countFakeBidsInput.current.value = bidSizeInput.current.value = '';
    };

    const onAuctionIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const target = event.target as HTMLSelectElement;
        setAuctionId(target.value);
    };

    const onRealmChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const target = event.target as HTMLSelectElement;
        setRealm(target.value);
    };

    const selectedAuction = app.activeAuctions.filter((auction: IAuction) => auction.id === selectedAuctionId)[0];
    const currentLotIds = selectedAuction?.lots.map((lot: ILot) => lot.id) || [];

    return (
        <div className={styles.block}>
            <Spoiler
                title={<div className={styles.title}>Auction hacking:</div>}
                content={
                    <React.Fragment>
                        <div className={styles.inputs}>
                            <select className={styles.select} onChange={onRealmChange}>
                                {settings.realmsConfig.map((realm) => {
                                    return (
                                        <option key={realm.realm} value={realm.realm} selected={realm.realm === settings.realm}>
                                            {realm.realm}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className={styles.inputs}>
                            <select className={styles.select} ref={auctionIdInput} onChange={onAuctionIdChange}>
                                <option value={'0'}>auction id</option>
                                {app.activeAuctions.map((auction: IAuction) => {
                                    return (
                                        <option key={auction.id} value={auction.id}>
                                            {auction.id}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className={styles.buttons}>
                            <Button label={'restart'} onClick={restart} />
                            <Button label={'start winners choosing'} onClick={_startWinnersChoosing} />
                            <Button label={'fetch winners from branches'} onClick={_fetchWinnersFromBranches} />
                            <Button label={'choose auction winners'} onClick={_chooseAuctionWinners} />
                            <Button label={'send rewards'} onClick={_sendRewardsAndCompensation} />
                            <Button label={'finish'} onClick={finish} />
                        </div>
                        <div className={styles.inputs}>
                            <select className={styles.select} ref={lotIdInput} disabled={!currentLotIds.length}>
                                <option value={'0'}>lot id</option>
                                {currentLotIds.map((id: string) => {
                                    return (
                                        <option key={id} value={id}>
                                            {id}
                                        </option>
                                    );
                                })}
                            </select>
                            <input type={'text'} placeholder={'count fake bids'} className={styles.input} ref={countFakeBidsInput} disabled={!currentLotIds.length} />
                            <input type={'text'} placeholder={'bid size'} className={styles.input} ref={bidSizeInput} disabled={!currentLotIds.length} />
                        </div>
                        <div className={styles.buttons}>
                            <Button label={'fake bids'} onClick={makeFakeBids} />
                        </div>
                    </React.Fragment>
                }
            />
        </div>
    );
};

const PopupDebugPanel = (props: { bundles: IBundleList }) => {
    const dispatch = useDispatch();

    const firstKeyId = !isEmptyObject(props.bundles) ? Object.keys(props.bundles)[0] : null;

    const success = () => {
        dispatch(
            changeVisiblePopup(POPUPS_NAME.SUCCESS_PURCHASE, true, {
                bundleId: firstKeyId,
            }),
        );
    };

    const failed = () => {
        dispatch(
            changeVisiblePopup(POPUPS_NAME.ERROR_PURCHASE, true, {
                bundleId: firstKeyId,
            }),
        );
    };

    const bidOk = () => {
        dispatch(
            changeVisiblePopup(POPUPS_NAME.BID_SUCCESSFULLY_PLACED, true, {
                bet: 500000,
                currency: 'gold',
                auction: preloaded_state.activeAuctions[0],
                lot: preloaded_state.activeAuctions[0].lots[0],
            }),
        );
    };

    const bidFail = () => {
        dispatch(
            changeVisiblePopup(POPUPS_NAME.BID_ERROR_PLACED, true, {
                bet: 500000,
                currency: 'gold',
                auction: preloaded_state.activeAuctions[0],
                lot: preloaded_state.activeAuctions[0].lots[0],
            }),
        );
    };

    return (
        <div className={styles.block}>
            <Spoiler
                title={<div className={styles.title}>Popups:</div>}
                content={
                    <div className={styles.buttons}>
                        <Button label={'purchase ok'} onClick={success} />
                        <Button label={'purchase fail'} onClick={failed} />
                        <Button label={'bid ok'} onClick={bidOk} />
                        <Button label={'bid fail'} onClick={bidFail} />
                    </div>
                }
            />
        </div>
    );
};

const WoWSClient = () => {
    const [state, setState] = React.useState<boolean>(!!LocalStorageHelper.get(LOCAL_STORAGE_WOWS_CLIENT_DEV));

    const onClick = () => {
        if (state) {
            LocalStorageHelper.remove(LOCAL_STORAGE_WOWS_CLIENT_DEV);
            setState(false);
        } else {
            LocalStorageHelper.set(LOCAL_STORAGE_WOWS_CLIENT_DEV, true);
            setState(true);
        }

        document.location.reload();
    };

    return (
        <div className={styles.block}>
            <Spoiler
                title={<div className={styles.title}>WoWS Client</div>}
                content={
                    <div className={styles.buttons}>
                        <Button label={!state ? 'turn on' : 'turn off'} onClick={onClick} />
                    </div>
                }
            />
        </div>
    );
};

const BackgroundPreviewPanel = () => {
    const dispatch = useDispatch();
    const refInput = React.useRef<HTMLInputElement>(null);

    const loadImage = () => refInput?.current?.click();

    const changeVideoBack = () => {
        const { current } = refInput;
        if (!current) {
            return;
        }

        const fileReader = new FileReader();

        fileReader.onload = (ev) => {
            const img = document.createElement('img');
            // @ts-ignore
            img.src = ev.target.result;
            dispatch(changeViewBackground(null, img.src));
            current.value = '';
        };

        fileReader.readAsDataURL(current.files[0]);
    };

    return (
        <div className={styles.block}>
            <input type={'file'} style={{ display: 'none' }} onChange={changeVideoBack} ref={refInput} />
            <Spoiler
                title={<div className={styles.title}>Set background:</div>}
                content={
                    <div className={styles.buttons}>
                        <Button label={'load image'} onClick={loadImage} />
                    </div>
                }
            />
        </div>
    );
};

const VideoPreviewPanel = () => {
    const dispatch = useDispatch();
    const refInputVideoBack = React.useRef<HTMLInputElement>(null);
    const refInputVideoPreview = React.useRef<HTMLInputElement>(null);
    const [videoPreviewSource, setVideoSource] = React.useState(null);

    const loadBackVideo = () => refInputVideoBack?.current?.click();

    const loadPreviewVideo = () => refInputVideoPreview?.current?.click();

    const changeVideoBack = () => {
        const { current } = refInputVideoBack;
        if (!current) {
            return;
        }

        const fileReader = new FileReader();

        fileReader.onload = (ev) => {
            const video = document.createElement('video');
            // @ts-ignore
            video.src = ev.target.result;
            video.autoplay = true;
            video.loop = true;
            video.style.position = 'absolute';
            video.style.top = '0';
            video.style.left = '0';
            video.style.width = '100%';
            video.style.height = '100%';
            video.style.objectFit = 'cover';
            video.style.objectPosition = 'top';

            const wrapper = document.querySelector('.ViewBackground_background');
            const videoWrapper = document.querySelector('.ViewBackground_videoWrapper video');
            if (videoWrapper) {
                // @ts-ignore
                videoWrapper.src = ev.target.result;
            } else {
                const videoContentWrapper = document.createElement('div');
                videoContentWrapper.classList.add('ViewBackground_videoWrapper', 'ViewBackground_show');
                videoContentWrapper.appendChild(video);
                wrapper.appendChild(videoContentWrapper);
            }
        };

        fileReader.readAsDataURL(current.files[0]);
    };

    const changeVideoPreview = () => {
        const { current } = refInputVideoPreview;
        if (!current) {
            return;
        }

        const fileReader = new FileReader();
        fileReader.onload = (ev) => {
            setVideoSource(ev.target.result);
        };

        fileReader.readAsDataURL(current.files[0]);
    };

    const playPreviewVideo = () => {
        const loadPreviewVideoButton = document.getElementById('loadPreviewVideoButton');
        if (!videoPreviewSource) {
            return;
        }

        dispatch(startVideo(null, videoPreviewSource));
        loadPreviewVideoButton.blur();
    };

    return (
        <div className={styles.block}>
            <input type={'file'} style={{ display: 'none' }} onChange={changeVideoBack} ref={refInputVideoBack} />
            <input type={'file'} style={{ display: 'none' }} onChange={changeVideoPreview} ref={refInputVideoPreview} />
            <Spoiler
                title={<div className={styles.title}>Video settings:</div>}
                content={
                    <div className={styles.buttons}>
                        <Button label={'load back video'} onClick={loadBackVideo} />
                        <Button label={'load preview video'} onClick={loadPreviewVideo} />
                        <Button label={'play preview video'} onClick={playPreviewVideo} id={'loadPreviewVideoButton'} />
                    </div>
                }
            />
        </div>
    );
};

const WsmartPanel = () => {
    const debugHandler = () => {
        const cookieName = 'debugPremBundles';
        setCookie(cookieName, getCookie(cookieName) === '1' ? '-1' : '1', {});
        document.location.reload();
    };

    return (
        <div className={styles.block}>
            <Spoiler
                title={<div className={styles.title}>Wsmart:</div>}
                content={
                    <div className={styles.buttons}>
                        <Button label={'toogle debug'} onClick={debugHandler} />
                    </div>
                }
            />
        </div>
    );
};

interface IDevModePanelState {
    devMode: IDevModeParams;
    currentPage: ICurrentPage;
    bundles: IBundleList;
}

const stateSelector = (state: State): IDevModePanelState => {
    return {
        devMode: state.ReducerApp.devMode,
        currentPage: state.ReducerApp.currentPage,
        bundles: state.ReducerApp.bundles,
    };
};

const DevModePanel = () => {
    const [isVisible, setVisible] = React.useState<boolean>(false);
    const state: IDevModePanelState = useSelector(stateSelector, equal);

    if (!LocalStorageHelper.get(devModeKeyMap.devMode) || !window.metashop.isStaff) {
        return null;
    }

    const additionalRender = () => {
        switch (state.currentPage?.name) {
            case CATEGORIES.AUCTION:
                return <AuctionDebugPanel />;
        }

        return <></>;
    };

    const render = () => {
        return (
            <>
                <WoWSClient />
                <VideoPreviewPanel />
                <BackgroundPreviewPanel />
                <PopupDebugPanel bundles={state.bundles} />
                <WsmartPanel />
            </>
        );
    };

    const panelClasses = classNames(styles.panel, {
        [styles.hidden]: !isVisible,
    });

    return (
        <div className={panelClasses}>
            <div className={styles.button} onClick={() => setVisible(!isVisible)} />
            {additionalRender()}
            {render()}
        </div>
    );
};

export default DevModePanel;
