import * as React from 'react';
import styles from './Filters.scss';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { FILTER_CURRENCY_NAME, FILTER_NATION_NAME, FILTER_SHIP_CLASS_NAME, FILTER_SHIP_TIER_NAME } from '~/const';
import { getLevelFromRomanFormat } from '~/utils/utils';
import { INations, IVehicleTypes } from '~/Reducers/ReducerApp';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getCurrency } from '~/utils/currencies';
import Checkbox from '~/components/Checkbox/Checkbox';
import { getFiltersInfo } from '~/settings/filtersMap';
import classNames from 'classnames';

interface IFilterItems {
    filterName: FILTER_INFO_NAME;
    values: any[];
    activeFiltersValues: any[];
    onChangeFilter: (filterName: string, filterValue: any) => void;
    column?: number;
}

interface IStateSelector {
    nations: INations;
    vehicleTypes: IVehicleTypes;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        nations: state.ReducerApp.nations,
        vehicleTypes: state.ReducerApp.vehicleTypes,
    };
};

const FilterVariants = ({ filterName, values, activeFiltersValues, onChangeFilter, column }: IFilterItems) => {
    const items = getFiltersInfo()[filterName]?.items.filter((item) => values.includes(item.value));
    const { nations, vehicleTypes } = useSelector<State, IStateSelector>(stateSelector, equal);

    const itemsList = items.reduce((state: any, item: any) => {
        let content = filterName === FILTER_SHIP_TIER_NAME ? getLevelFromRomanFormat(item.value) : item.title;

        if (filterName === FILTER_NATION_NAME && nations[item.value]) {
            const icon = nations[item.value].icons.tiny || nations[item.value].icons.default;

            content = (
                <DivTooltip
                    className={styles.iconNation}
                    tooltipBody={<DefaultTooltip text={nations[item.value].title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        } else if (filterName === FILTER_SHIP_CLASS_NAME && vehicleTypes[item.value.toLowerCase()]) {
            const vehicleType = vehicleTypes[item.value.toLowerCase()];
            const icon = vehicleType.icons.default;

            content = (
                <DivTooltip
                    className={styles.iconClass}
                    tooltipBody={<DefaultTooltip text={vehicleType.title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        } else if (filterName === FILTER_CURRENCY_NAME) {
            const currencyConfig = getCurrency(item.value);
            const icon = currencyConfig?.icons?.default;

            content = (
                <DivTooltip
                    className={styles.currencyIcon}
                    tooltipBody={<DefaultTooltip text={currencyConfig.title} />}
                    style={{
                        backgroundImage: `url(${icon})`,
                        cursor: 'pointer',
                    }}
                />
            );
        }

        state.push(
            <div key={`${filterName}_${item.value}`} className={classNames(styles.filterItem)}>
                <Checkbox
                    onChange={() => {
                        onChangeFilter(filterName, item.value);
                    }}
                    isChecked={activeFiltersValues?.includes(item.value)}
                    label={content}
                    className={styles.checkboxLabel}
                />
            </div>,
        );

        return state;
    }, []);

    const itemsColumns = [];
    const limit = column || 6;

    for (let i = 0; i < itemsList.length; i += limit) {
        const array = itemsList.slice(i, i + limit);
        itemsColumns.push(
            <div className={styles.filterColumnBodyRow} key={`filter_variants_${i}`}>
                {array}
            </div>,
        );
    }

    return <>{itemsColumns}</>;
};

export default FilterVariants;
