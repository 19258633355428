import gql from 'graphql-tag';

const complexities = gql`
    query Complexity($lang: String) {
        complexities(lang: $lang) {
            level
            header
            description
            icons {
                filled
                empty
                localFilled
                localEmpty
            }
        }
    }
`;

export default complexities;
