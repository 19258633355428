import * as React from 'react';
import styles from './RandomBundleHistoryCarousel.scss';
import { getNextTimeForPeriodicBundle } from '~/utils/bundles';
import { localizedDateTime } from '~/utils/utils';
import { interpolate, t } from '~/utils/localization';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { _chooseRandomBundle } from '~/utils/purchase';
import { DivTooltip } from '@wg/wows-react-uikit';
import CountDown from '~/components/CountDown/CountDown';

interface IRandomBundleHistoryCarouselTimer {
    bundle: IBundle;
    selectedRandomBundles: AccountSelectedRandomBundles;
}

const RandomBundleHistoryCarouselTimer = ({ bundle, selectedRandomBundles }: IRandomBundleHistoryCarouselTimer) => {
    const nextTime = getNextTimeForPeriodicBundle(bundle);
    const localTime = localizedDateTime(new Date(nextTime));
    const textTimeToNext = interpolate(t('Сегодня вы уже получили ежедневный набор. Следующий набор будет доступен {date}'), {
        date: localTime,
    });

    return (
        <DivTooltip className={styles.dailyBundleTimer} tooltipBody={<DefaultTooltip text={textTimeToNext} />}>
            <div className={styles.timerWrapper}>
                <div className={styles.timerHeader}>{t('Новый набор будет доступен через:')}</div>
                <div className={styles.dailyTimerContent}>
                    <CountDown
                        date={nextTime}
                        className={styles.dailyTimer}
                        withoutIcon
                        onComplete={() => {
                            if (!selectedRandomBundles?.[bundle.id]) {
                                _chooseRandomBundle(bundle);
                            }
                        }}
                    />
                </div>
            </div>
        </DivTooltip>
    );
};

export default React.memo(RandomBundleHistoryCarouselTimer);
