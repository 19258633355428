import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import { useParams } from 'react-router-dom';
import store from '~/Store';
import { State } from '~/Reducers';
import { resetMrpsCurrentEvent, toggleNextRenderWithoutReset } from '~/Actions/ActionMrps';
import { AnyAction } from 'redux';
import { t } from '~/utils/localization';
import { checkMrpsPendingTransactions, isMrpsCategoryEnabled } from '~/utils/mrps';
import { openCategoryByName } from '~/utils/category';
import MrpsProgressBar from '~/components/Mrps/MrpsProgressBar/MrpsProgressBar';
import MrpsResourcesBlock from '~/components/Mrps/MrpsResourceBlock/MrpsResourceBlock';
import MrpsControls from '~/components/Mrps/MrpsControls/MrpsControls';
import MrpsNotSupported from '~/components/Mrps/MrpsNotSupported/MrpsNotSupported';

import styles from './MrpsPage.scss';
import { Processing } from '@wg/wows-react-uikit';

interface StateSelector {
    events: MrpsEvent[];
    currentEvent?: MrpsCurrentEventState;
    currentEventData?: MrpsEvent;
    uniqueItems?: InventoryUniqueItems;
    hasPendingTransactions: boolean;
    isNextRenderWithoutReset: boolean;
}

function stateSelector(state: State): StateSelector {
    const events = state.reducerMrps.events;
    const currentEvent = state.reducerMrps.currentEvent;
    const currentEventData = state.reducerMrps.events?.find((event) => event.name === currentEvent?.eventName);
    const uniqueItems = state.ReducerAccount.inventory?.uniqueItems;
    const hasPendingTransactions = state.reducerMrps.hasPendingTransactions;
    const isNextRenderWithoutReset = state.reducerMrps.isNextRenderWithoutReset;

    return {
        events,
        currentEvent,
        currentEventData,
        uniqueItems,
        hasPendingTransactions,
        isNextRenderWithoutReset,
    };
}

export default function MrpsPage() {
    const { categoryName, id: subCategoryName } = useParams();

    const state = useSelector<State, StateSelector>((state) => stateSelector(state), equal);

    const [animate, setAnimate] = React.useState(false);

    const [mrpsCategoryName, isCategoryEnabled] = React.useMemo(() => {
        if (isMrpsCategoryEnabled(subCategoryName)) {
            return [subCategoryName, true];
        }

        if (isMrpsCategoryEnabled(categoryName)) {
            return [categoryName, true];
        }

        return ['', false];
    }, [categoryName, subCategoryName]);

    const isCompletedByUniqueRewards = React.useMemo(() => {
        const eventRewards = state.currentEventData?.rewards;

        return eventRewards?.some((reward) => {
            let flag = false;

            for (let i = 0; i < reward.entitlements.length; i++) {
                const entitlement = reward.entitlements[i];

                if (!!state.uniqueItems?.find((id) => id === entitlement.identifier)) {
                    flag = true;
                    break;
                }
            }

            return flag;
        });
    }, [state.currentEventData?.rewards, state.uniqueItems]);

    React.useEffect(() => {
        if (!state.isNextRenderWithoutReset) {
            store.dispatch(resetMrpsCurrentEvent(mrpsCategoryName) as unknown as AnyAction);
        } else {
            store.dispatch(toggleNextRenderWithoutReset(false));
        }

        if (state.hasPendingTransactions) {
            checkMrpsPendingTransactions();
        }
    }, [mrpsCategoryName]);

    React.useLayoutEffect(() => {
        if (!animate) {
            setAnimate(true);
        }
    }, [mrpsCategoryName]);

    if (!isCategoryEnabled) {
        openCategoryByName();

        return;
    }

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapper_animate]: animate,
            })}
        >
            {state.currentEvent?.eventName && state.currentEvent?.eventName === mrpsCategoryName ? (
                <div className={styles.content}>
                    <MrpsProgressBar isCompletedByUniqueRewards={isCompletedByUniqueRewards} isSubCategoryLayout={subCategoryName === mrpsCategoryName} />
                    <MrpsResourcesBlock />
                    {state.currentEvent.baseProgress === 100 ? <div className={styles.finishText}>{t('Поздравляем! Обмен произведён успешно!')}</div> : null}
                    {state.currentEvent.baseProgress !== 100 && !isCompletedByUniqueRewards ? <MrpsControls /> : null}
                    {isCompletedByUniqueRewards && state.currentEvent.baseProgress !== 100 ? <div className={styles.finishText}>{t('Набор или уникальное имущество из набора получены.')}</div> : null}
                </div>
            ) : (
                <div className={styles.loader}>
                    <Processing isFetching isInside />
                </div>
            )}
            <MrpsNotSupported />
        </div>
    );
}
