import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './Layouts.scss';
import BundlesListLayout, { isBundleListLayout } from '~/Layouts/BundlesListLayout';
import DefaultLayout from '~/Layouts/DefaultLayout';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import Banner from '~/Layouts/Banners/Banner/Banner';
import BannerBar, { BannerBarWrapper } from '~/Layouts/Banners/BannerBar/BannerBar';
import Accordion from '~/Layouts/Banners/Accordion/Accordion';
import FeaturedTimer from '~/Layouts/FeaturedTimer/FeaturedTimer';
import classNames from 'classnames';
import { getNowTime } from '~/utils/utils';
import { isMrpsCategory, isMrpsCategoryEnabled } from '~/utils/mrps';
import { CATEGORIES } from '~/const';
import { BannerBarWidth, FEATURING_DESTINATION } from '~/types/contents';
import WowsCommerce from '@wg/wows-commerce';

interface IFeaturingLayout {
    featuring: IFeature[];
    categoryName: string;
    placeholder?: string;
}

export const FEATURING_TEMPLATES = {
    BANNER_BLOCK: 'banner',
    BANNER_BAR: 'banner_bar',
    ACCORDION: 'accordion',
    CATEGORY_PREVIEW: 'category_preview',
    BUNDLE_LIST: 'bundles_list',
    TIMER: 'timer',
};

interface IFeaturingLayoutAppState {
    bundles: IBundleList;
    categories: ICategories;
    bundleCategory: IBundleCategory;
    disabledCategories: string[];
}

const appStateSelector = (state: State): IFeaturingLayoutAppState => {
    return {
        bundles: state.ReducerApp.bundles,
        categories: state.ReducerApp.categories,
        bundleCategory: state.ReducerApp.bundleCategory,
        disabledCategories: state.ReducerApp.disabledCategories,
    };
};

interface IFeaturingLayoutAccountState {
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems: number[];
}

const accountStateSelector = (state: State): IFeaturingLayoutAccountState => {
    return {
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
    };
};

const prepareCategoryPreview = (data: IContentBlockData, appState: IFeaturingLayoutAppState, accountState: IFeaturingLayoutAccountState) => {
    const category = appState.categories[data.categoryName];
    const bundles =
        appState.bundleCategory[data.categoryName]?.filter((bundleId: number) => {
            const bundle = appState.bundles[+bundleId];

            if (!bundle) {
                return false;
            }

            if (category.denyReorder) {
                return true;
            }

            return !appState.bundles[bundleId].isPurchased;
        }) || [];

    if (!bundles.length || !category) {
        return null;
    }

    return (
        <DefaultLayout
            bundles={bundles}
            title={category.title}
            activityCountdown={category.activityCountdown}
            activeTill={category.activeTill}
            categoryName={data.categoryName}
            limit={data.bundlesCount || 4}
            layoutClassName={styles.featuringCategoryPreview}
            isNotRenderFilter
        />
    );
};

interface IFeaturingGroups {
    prevFeaturingType: string;
    prevFeaturingGroupIndex: string;
    content: {
        [key: string]: React.ReactChild[];
    };
}

const getFeaturedContent = (contentBlocks: IContentBlock[], appState: IFeaturingLayoutAppState, accountState: IFeaturingLayoutAccountState) => {
    let nextBannerBarIndex: number;

    const result = contentBlocks.reduce(
        (result: IFeaturingGroups, block, index) => {
            if (index === nextBannerBarIndex) {
                nextBannerBarIndex = null;
                return result;
            }

            if (isBundleListLayout(block.type)) {
                result.prevFeaturingType = block.type;
                result.prevFeaturingGroupIndex = null;
                result.content[`bundles_list_${index}`] = [<BundlesListLayout key={`bundles_list_${index}`} data={block} />];
                return result;
            }

            switch (block.type) {
                case FEATURING_TEMPLATES.CATEGORY_PREVIEW: {
                    const data = block.data as IContentBlockData;
                    const previewCategoryContent = prepareCategoryPreview(data, appState, accountState);
                    if (previewCategoryContent) {
                        result.prevFeaturingGroupIndex = null;
                        result.prevFeaturingType = block.type;
                        result.content[`category_preview_${index}`] = [previewCategoryContent];
                    }
                    break;
                }

                case FEATURING_TEMPLATES.BANNER_BLOCK: {
                    const data = block.data as IBanner;
                    const content = <Banner key={`banner_${block.id}`} data={data} id={block.id} />;

                    if (result.prevFeaturingType === block.type && result.prevFeaturingGroupIndex) {
                        result.content[result.prevFeaturingGroupIndex].push(content);
                    } else {
                        result.prevFeaturingGroupIndex = `banner_${index}`;
                        result.content[result.prevFeaturingGroupIndex] = [content];
                    }
                    result.prevFeaturingType = block.type;
                    break;
                }

                case FEATURING_TEMPLATES.BANNER_BAR: {
                    const data = block.data as IBannerBar;
                    const BannerBarComponent = <BannerBar data={data} />;

                    if (result.prevFeaturingType === block.type && result.prevFeaturingGroupIndex && data.width === BannerBarWidth.FULL) {
                        result.prevFeaturingType = block.type;
                        result.content[result.prevFeaturingGroupIndex].push(BannerBarComponent);
                        return result;
                    } else if (data.width === BannerBarWidth.FULL) {
                        result.prevFeaturingType = block.type;
                        result.prevFeaturingGroupIndex = `banner_bar_${index}`;
                        result.content[result.prevFeaturingGroupIndex] = [BannerBarComponent];
                        return result;
                    }

                    const _nextBannerBarIndex = index + 1;
                    const nextBannerBar = contentBlocks[_nextBannerBarIndex];
                    const nextBannerBarData = nextBannerBar?.data as IBannerBar;
                    const isBannerBar = nextBannerBar?.type === FEATURING_TEMPLATES.BANNER_BAR && nextBannerBarData?.width === BannerBarWidth.HALF;

                    nextBannerBarIndex = _nextBannerBarIndex;

                    const content = (
                        <BannerBarWrapper key={`banner_bar_wrapper_${index}`}>
                            {BannerBarComponent}
                            {!!nextBannerBarData && isBannerBar && <BannerBar data={nextBannerBarData} />}
                        </BannerBarWrapper>
                    );

                    if (result.prevFeaturingType === block.type && result.prevFeaturingGroupIndex) {
                        result.prevFeaturingType = block.type;
                        result.content[result.prevFeaturingGroupIndex].push(content);
                    } else {
                        result.prevFeaturingType = block.type;
                        result.prevFeaturingGroupIndex = `banner_bar_${index}`;
                        result.content[result.prevFeaturingGroupIndex] = [content];
                    }
                    break;
                }

                case FEATURING_TEMPLATES.ACCORDION: {
                    const data = block.data as IAccordionBanner;
                    const currentGroup = block.type + '_' + data.group;
                    if (currentGroup === result.prevFeaturingType) {
                        break;
                    }

                    if (data.linkType === 'category' && isMrpsCategory(data.linkValue) && !isMrpsCategoryEnabled(data.linkValue)) {
                        break;
                    }

                    result.prevFeaturingGroupIndex = null;
                    result.prevFeaturingType = currentGroup;

                    const banners = contentBlocks
                        .filter((contentBlock) => {
                            const currentData = contentBlock.data as IAccordionBanner;
                            return currentData.group === data.group;
                        })
                        .map((contentBlock) => contentBlock.data as IAccordionBanner);

                    result.content[currentGroup] = [<Accordion key={`accordion_${currentGroup}`} banners={banners} />];
                    break;
                }

                case FEATURING_TEMPLATES.TIMER: {
                    const data = block.data as IFeaturedTimer;
                    result.prevFeaturingGroupIndex = null;
                    result.prevFeaturingType = block.type;
                    result.content[`timer_${index}`] = [
                        <FeaturedTimer key={`featured_timer_${index}`} title={data.title} description={data.description} activeTill={block.activeTill} color={data.color} />,
                    ];
                    break;
                }
            }

            return result;
        },
        {
            prevFeaturingType: null,
            prevFeaturingGroupIndex: null,
            content: {},
        },
    );

    return Object.values(result.content || {}).map((children, index) => {
        // @ts-ignore
        const componentName = children[0]?.type?.name;

        return (
            <div
                key={`featuring_block_${componentName}_${index}`}
                className={classNames(styles.featuringBlock, {
                    [styles.featuringTimer]: componentName === FeaturedTimer.name,
                })}
            >
                {children}
            </div>
        );
    });
};

interface IFeaturingCountryRestriction {
    contentBlocks: IContentBlock[];
}

let hasBundlesForRealCurrenciesInFeaturing: Nullable<boolean> = null;

interface IFeaturingCountryRestrictionStateSelector {
    WowsCommerce: WowsCommerce;
    categories: ICategories;
    bundles: IBundleList;
}

const featuringCountryRestrictionStateSelector = (state: State): IFeaturingCountryRestrictionStateSelector => {
    return {
        WowsCommerce: state.ReducerWSMart.wowsCommerce,
        bundles: state.ReducerApp.bundles,
        categories: state.ReducerApp.categories,
    };
};

const FeaturingCountryRestriction = ({ contentBlocks }: IFeaturingCountryRestriction) => {
    const { WowsCommerce, bundles, categories } = useSelector<State, IFeaturingCountryRestrictionStateSelector>(featuringCountryRestrictionStateSelector, equal);

    React.useEffect(() => {
        function checkRestrictions() {
            WowsCommerce?.checkPurchaseRestrictionByCountryMismatch();
            WowsCommerce?.checkPurchaseRestrictionByUserCountryIsSet(true);
        }

        if (hasBundlesForRealCurrenciesInFeaturing === null) {
            hasBundlesForRealCurrenciesInFeaturing = contentBlocks.some((block) => {
                if (isBundleListLayout(block.type)) {
                    return (block.data as IContentBlockData).bundles.some((item) => {
                        return !!bundles[item.id]?.productCode;
                    });
                } else if (block.type === FEATURING_TEMPLATES.CATEGORY_PREVIEW) {
                    const data = block.data as IContentBlockData;
                    return categories[data.categoryName]?.isContainBundlesForReal;
                }
                return false;
            });
        }

        if (hasBundlesForRealCurrenciesInFeaturing === true) {
            checkRestrictions();
        }
    }, []);

    return <></>;
};

const FeaturingLayout = function (props: IFeaturingLayout): any {
    const featuring = props.featuring;
    const appState = useSelector<State, IFeaturingLayoutAppState>(appStateSelector, equal);
    const accountState = useSelector<State, IFeaturingLayoutAccountState>(accountStateSelector, equal);
    const category = appState.categories[props.categoryName];
    const categoryActiveTill = Math.floor(new Date(category.activeTill).getTime() / 1000);
    const isDisabledCategory = appState.disabledCategories.includes(category.name) || (category.activeTill && categoryActiveTill - getNowTime() <= 0);
    const currentFeature = featuring.find((item) => {
        return (
            (item.destinationId === props.categoryName && item.placeholder === props.placeholder) ||
            (!item.destinationId && item.destination === FEATURING_DESTINATION.MAIN && props.categoryName === CATEGORIES.FEATURED)
        );
    });

    if (!currentFeature || isDisabledCategory) {
        return null;
    }

    const contentBlocks = currentFeature.contentBlocks;

    const content = getFeaturedContent(contentBlocks, appState, accountState);

    return (
        <>
            <FeaturingCountryRestriction contentBlocks={contentBlocks} key={`featuring_country_restriction`} />
            {content}
        </>
    );
};

export default FeaturingLayout;
