import * as React from 'react';
import styles from './QuantityWidget.scss';

interface IQuantityWidget {
    amount: number;
}

const QuantityWidget = ({ amount }: IQuantityWidget) => {
    return <div className={styles.widget}>{amount}</div>;
};

export default QuantityWidget;
