import * as React from 'react';
import styles from './Auction.scss';
import { t } from '~/utils/localization';
import { formatNumber } from '~/utils/utils';
import classNames from 'classnames';
import Currency from '~/components/Currency/Currency';
import { Interpolate } from '@wg/wows-react-uikit';
import ActiveBid from '~/components/Popups/Auction/ActiveBid/ActiveBid';
import AuctionStatus from '~/components/Auction/AuctionStatus';
import { BID_STATUSES } from '~/types/auction';
import { AUCTION_KIND } from '~/const';

interface IAuctionTooltip extends ILot {
    auction?: IAuction;
    minimalWonBid: IAuctionMinimalWonBids;
    currentBid: number;
    currentBidStatus: BID_STATUSES;
}

const AuctionTooltip = (props: IAuctionTooltip) => {
    return (
        <div className={styles.tooltipContainer}>
            <div>
                {t('Количество победителей:')}&nbsp;<b>{formatNumber(props.winnersCount)}</b>
            </div>
            {props.auction.kind === AUCTION_KIND.SECOND_PRICE && props.minimalWonBid && (
                <div className={classNames(styles.amount, styles.minimalWinnerBet)}>
                    {t('Минимальная сыгравшая ставка:')}&nbsp;
                    <Currency currency={props.minimalWonBid.bid.currency} className={styles.betCurrency} amount={props.minimalWonBid.bid.amount} withoutAnimation />
                </div>
            )}
            {!!props.currentBid && (
                <div>
                    <Interpolate
                        str={t('Ваша ставка: %(bet)s')}
                        bet={<ActiveBid auction={props.auction} currency={props.minimalBid.currency} amount={props.currentBid} className={styles.betCurrency} />}
                    />
                </div>
            )}
            <AuctionStatus
                currentBid={props.currentBid}
                bidStatus={props.currentBidStatus}
                auctionStatus={props.auction.status}
                isBidPlaced={!!props.currentBid}
                minimalWonBid={props.minimalWonBid}
                shortFinishedStatusTitle={true}
            />
        </div>
    );
};

export default AuctionTooltip;
