import * as React from 'react';
import styles from './BidPlacingBackground.scss';

interface IBidPlacingBackground {
    background?: string;
}

const BidPlacingBackground = ({ background }: IBidPlacingBackground) => {
    if (!background) {
        return null;
    }

    return <div className={styles.imageWrapper} style={{ backgroundImage: `url(${background})` }}></div>;
};

export default BidPlacingBackground;
