import * as React from 'react';
import { State } from '~/Reducers';
import { getStepByName, GUIDE_NAMES, IGuideStep, PLACEMENT } from '~/components/WelcomePage/steps';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Guide from '~/components/WelcomePage/Guide';
import { hideGuideStep } from '~/Actions/ActionAccount';
import { IDevModeParams } from '~/Actions/ActionAccountType';
import { isMobileOrTabletWindow } from '~/utils/utils';

interface IGuideDecorator {
    children: React.ReactNode;
    names: GUIDE_NAMES[];
    placement?: PLACEMENT;

    onClick?: (event: React.MouseEvent) => void;

    className?: string;
}

interface IStateSelector {
    port: IPort;

    popupActive: IPopup;

    steps: IGuideStep[];
    activeGuideName: GUIDE_NAMES;
    currentNotificationVisibleName: string;

    isFinishedRequest: boolean;
    isFullscreen: boolean;
    infoScreenIsVisible: boolean;
    devMode: IDevModeParams;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        port: state.ReducerApp.port,

        popupActive: state.ReducerApp.popupActive,

        steps: state.ReducerAccount?.guideSteps,
        activeGuideName: state.ReducerApp.guide?.name,
        currentNotificationVisibleName: state.ReducerApp.currentNotificationVisibleName,

        isFinishedRequest: state.ReducerApp.isFinishedRequest,
        isFullscreen: state.ReducerApp.isFullscreen,
        infoScreenIsVisible: state.ReducerApp.infoScreen?.isVisible,
        devMode: state.ReducerApp.devMode,
    };
};

const GuideDecorator = ({ children, names, className, onClick, placement }: IGuideDecorator) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [isStarted, setStart] = React.useState<boolean>(false);
    const [target, setTarget] = React.useState<HTMLDivElement>(null);
    const ref = React.useRef<HTMLDivElement>(null);
    const timingRef = React.useRef<{ delay: NodeJS.Timeout; duration: NodeJS.Timeout }>({ delay: null, duration: null });
    const isNeedToHidden = state.isFullscreen || !!state.popupActive || state.port?.isVisible || state.infoScreenIsVisible || state.currentNotificationVisibleName;
    const getStep = () => {
        if (Array.isArray(names) && names.includes(state.steps?.[0]?.name)) {
            return getStepByName(state.steps[0]?.name);
        }

        return null;
    };
    const step = getStep();

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            ref.current = _ref;
            setTarget(ref.current);
        }
    };

    const finish = () => dispatch(hideGuideStep(step));

    const stop = () => {
        if (!isStarted) return;
        setStart(false);
        step.afterShown?.();
        finish();
    };

    const _onClick = (event: React.MouseEvent) => {
        stop();
        onClick?.(event);
    };

    const show = () => {
        if (isStarted) {
            return;
        }

        timingRef.current.delay = setTimeout(
            () => {
                setStart(true);
                if (step.duration) {
                    timingRef.current.duration = setTimeout(stop, step.duration * 1000);
                }
            },
            (step.delay || 1) * 1000,
        );
    };

    const showIfNeeded = () => {
        if (Array.isArray(names) && names.includes(state.steps?.[0]?.name)) {
            show();
        }
    };

    const clearTimings = () => {
        clearTimeout(timingRef.current.delay);
        clearTimeout(timingRef.current.duration);
    };

    React.useEffect(() => {
        if (!Array.isArray(names) || !state.steps?.length || !ref.current) {
            return;
        }
        if (!isNeedToHidden) {
            showIfNeeded();
        }
    }, [state.steps]);

    React.useEffect(() => {
        if (isNeedToHidden || !state.isFinishedRequest) {
            setStart(false);
            clearTimings();
        } else {
            showIfNeeded();
        }
    }, [isNeedToHidden, state.isFinishedRequest]);

    React.useEffect(() => {
        return clearTimings;
    }, []);

    if (!Array.isArray(names) || !step || state.devMode?.disableWelcomePage) {
        return (
            <div className={className} onClick={onClick}>
                {children}
            </div>
        );
    }

    return (
        <React.Fragment>
            <div ref={setRef} className={className} onClick={_onClick}>
                {children}
            </div>
            {!isMobileOrTabletWindow && isStarted && target && !isNeedToHidden && <Guide step={step} run={isStarted} onFinishGuide={stop} target={target} placement={placement} />}
        </React.Fragment>
    );
};

export default GuideDecorator;
