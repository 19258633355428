class Cron {
    deferredTasks: {
        [key: string]: {
            callback: () => void;
            ms: number;
        };
    } = {};

    scheduledTasks: {
        [key: string]: {
            callback: () => void;
            date: number;
        };
    } = {};

    newDeferredTask(name: string, callback: () => void, ms: number) {
        if (this.deferredTasks[name]) {
            return;
        }

        this.deferredTasks[name] = {
            callback,
            ms,
        };

        setTimeout(() => {
            delete this.deferredTasks[name];
            callback();
        }, ms);
    }

    newScheduledTask(name: string, callback: () => void, date: number) {
        if (this.scheduledTasks[name]) {
            return;
        }

        this.scheduledTasks[name] = {
            callback,
            date,
        };

        const _timer = setInterval(() => {
            const now = Date.now();
            if (now >= date) {
                callback();
                clearInterval(_timer);
                delete this.deferredTasks[name];
            }
        }, 1000);
    }
}

export default new Cron();
