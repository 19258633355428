import { ChooseItemFor, ITradeItem, ITradeLists } from '~/types/tradein';
import { IChooseItemFor, IClearState, IUpdateFilter, ISelectTargetItem, ISelectSourceItem, ISetTradeInLists, IClearFilters, ISetTs } from './ActionTradeInTypes';

export const SELECT_TARGET_ITEM = 'SELECT_TARGET_ITEM';
export const selectTargetItem = (item: ITradeItem): ISelectTargetItem => {
    return {
        type: SELECT_TARGET_ITEM,
        item,
    };
};

export const SELECT_SOURCE_ITEM = 'SELECT_SOURCE_ITEM';
export const selectSourceItem = (item: ITradeItem): ISelectSourceItem => {
    return {
        type: SELECT_SOURCE_ITEM,
        item,
    };
};

export const SET_TRADEIN_LISTS = 'SET_TRADEIN_LISTS';
export const setTradeInLists = (lists: ITradeLists): ISetTradeInLists => {
    return {
        type: SET_TRADEIN_LISTS,
        lists,
    };
};

export const CHOOSE_ITEM_FOR = 'CHOOSE_ITEM_FOR';
export const chooseItemFor = (type: ChooseItemFor): IChooseItemFor => {
    return {
        type: CHOOSE_ITEM_FOR,
        chooseItemFor: type,
    };
};

export const CLEAR_STATE = 'CLEAR_STATE';
export const clearState = (): IClearState => {
    return {
        type: CLEAR_STATE,
    };
};

export const UPDATE_FILTER = 'UPDATE_FILTER';
export const updateFilter = (filterName: FILTER_INFO_NAME, value: any): IUpdateFilter => {
    return {
        type: UPDATE_FILTER,
        filterName,
        value,
    };
};

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const clearFilters = (): IClearFilters => {
    return {
        type: CLEAR_FILTERS,
    };
};

export const SET_TS = 'SET_TS';
export const setTs = (ts: number): ISetTs => {
    return {
        type: SET_TS,
        ts,
    };
};
