import * as React from 'react';
import DefaultLayout from '~/Layouts/DefaultLayout';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { getLimitForFeaturedBundlesForSubCategory, isAllPreviewCategoryBundlesPurchased } from '~/utils/category';
import BundlesListSubLayout from '~/Layouts/BundlesListSubLayout';
import { isBundlePurchased } from '~/utils/bundles';

interface ISubCategoriesLayout {
    categories: string[];
    categoryName: string;
}

interface IStateSelector {
    categories: ICategories;
    bundleCategory: Record<string, number[]>;
    allBundles: IBundleList;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
        bundleCategory: state.ReducerApp.bundleCategory,
        allBundles: state.ReducerApp.bundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles, // to force rerender
    };
};

const SubCategoriesLayout = function (props: ISubCategoriesLayout): any {
    const subCategories = props.categories;
    const { categories, bundleCategory, allBundles } = useSelector<State, IStateSelector>(stateSelector, equal);
    const sortedSubCategories = subCategories.reduce(
        (result, categoryName: ICategoryList, index) => {
            const category = categories[categoryName];
            const parentCategory = category.parentCategoryData;
            const purchasedBundles: number[] = [];
            let bundles: number[] = [];
            let bundlesArray: number[] = [];
            let limit = 4;

            if (!category || categoryName === parentCategory?.name || !category?.bundles?.length) {
                return result;
            }

            if (category.previewCategorySettings?.bundlesList?.length && subCategories.length > 2) {
                const isTotallyPurchased = isAllPreviewCategoryBundlesPurchased(category);
                const { bundlesList } = category.previewCategorySettings;
                const component = <BundlesListSubLayout key={`${category.name}_${index}`} categoryName={category.name} bundlesList={bundlesList} title={category.title} />;
                result[isTotallyPurchased ? 'totallyPurchased' : 'rest'].push(component);
                return result;
            }

            let isTotallyPurchased = true;
            const categoryBundlesIds = bundleCategory[categoryName];
            if (category?.denyReorder) {
                isTotallyPurchased = false;
                bundles = [...bundleCategory[categoryName]];
            } else {
                categoryBundlesIds?.forEach((bundleId: number) => {
                    const bundle = allBundles[bundleId];
                    const isPurchasedBundle = !bundle || isBundlePurchased(bundle);

                    if (isPurchasedBundle) {
                        purchasedBundles.push(bundleId);
                        return;
                    }

                    isTotallyPurchased = false;
                    bundles.push(bundleId);
                });
            }

            bundlesArray = bundles.concat(purchasedBundles);
            if (bundlesArray?.length) {
                limit = getLimitForFeaturedBundlesForSubCategory(allBundles, bundlesArray);
            }

            const props: Record<string, any> = {};
            if (subCategories.length > 2) {
                props['activityCountdown'] = category.activityCountdown;
                props['activeTill'] = category.activeTill;
                props['limit'] = limit;
                props['isNotRenderFilter'] = true;
            }
            const component = <DefaultLayout key={`${category.name}_${index}`} bundles={bundlesArray} categoryName={categoryName} title={category.title} {...props} isShowMoreButton />;
            result[isTotallyPurchased ? 'totallyPurchased' : 'rest'].push(component);
            return result;
        },
        {
            totallyPurchased: [],
            rest: [],
        },
    );

    return (
        <React.Fragment>
            {sortedSubCategories.rest}
            {sortedSubCategories.totallyPurchased}
        </React.Fragment>
    );
};

export default SubCategoriesLayout;
