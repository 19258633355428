import * as React from 'react';
import { getSoundKeyForCurrentPage, isEnabledSounds } from '~/utils/sounds/settings';
import { isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import { Howl, Howler } from 'howler';
import { shipyardSoundOff, shipyardSoundOn } from '@wg/web2clientapi/sound';
import { State } from '~/Reducers';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

interface IStateSelector {
    currentPage: ICurrentPage;
    bundles: IBundleList;
    categories: ICategories;
    isStartedVideo: boolean;
    soundStatus: SoundStatus;
    port: IPort;
    videoVolume: number;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
        bundles: state.ReducerApp.bundles,
        isStartedVideo: state.ReducerApp.isStartedVideo,
        soundStatus: state.ReducerApp.soundStatus,
        port: state.ReducerApp.port,
        videoVolume: state.ReducerApp.volume,
    };
};

const MAX_VOLUME = 0.5;

/**
 * Sound priority:
 * 1. Fullscreen video if it has sound
 * 2. Shipyard sound if portPreview is open
 * 3. Sound of category
 * 4. Sound of shipyard in game client
 */
const usePlayMainTheme = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const currentSoundKey = React.useRef<string>(null);
    const soundsMap = React.useRef<Record<string, Howl>>({});
    const wasSoundFaded = React.useRef(false);
    const fadeTimeout = React.useRef<ReturnType<typeof setTimeout>>();

    function addSound(key: string, src: string) {
        if (!key || !src || soundsMap.current[key]) return;
        soundsMap.current[key] = new Howl({
            src: src,
            autoplay: false,
            loop: true,
            mute: false,
            format: ['mp3'],
            pool: 1,
            onplayerror: function () {
                soundsMap.current[key].once('unlock', function () {
                    currentSoundKey.current === key && isEnabledSounds(key) && soundPlay(key);
                });
            },
            onplay() {
                // To prevent sound duplication on multiple play() calls
                // @ts-ignore
                if (soundsMap.current[key]?._sounds?.length > 1) {
                    soundsMap.current[key].stop();
                    soundsMap.current[key].play();
                }
            },
        });
    }

    function isVideoWithSoundPlaying() {
        return !!(state.isStartedVideo && state.videoVolume !== 0);
    }

    function isPortPreviewVisible() {
        return state.port?.isVisible;
    }

    function getSoundStatus(key: string): boolean {
        return state.soundStatus[key] !== false; // undefined should be true
    }

    function soundPlay(key: string, withFade?: boolean) {
        const sound = soundsMap.current[key];
        if (isVideoWithSoundPlaying() || isPortPreviewVisible()) return;
        const isSoundPlaying = sound?.playing() && !fadeTimeout.current;
        if (!sound || !isEnabledSounds(key) || !getSoundStatus(key) || isSoundPlaying) return;
        if (fadeTimeout.current) {
            clearTimeout(fadeTimeout.current);
            fadeTimeout.current = null;
        }
        shipyardSoundOff();
        if (!sound?.playing()) sound.play();
        sound.fade(0, 1, 3000);
    }

    function soundStop(key: string, withFade?: boolean) {
        const sound = soundsMap.current[key];
        if (key && sound) {
            sound.fade(1, 0, 3000);
            if (withFade) {
                fadeTimeout.current = setTimeout(() => {
                    if (!sound.volume()) sound.stop();
                    fadeTimeout.current = null;
                }, 3100);
            } else {
                sound.stop();
            }
        }
        if (!isVideoWithSoundPlaying()) shipyardSoundOn();
    }

    function toggleSound(play: boolean, ...args: Parameters<typeof soundStop>): void;
    function toggleSound(play: boolean, ...args: Parameters<typeof soundPlay>): void {
        if (play) soundPlay(...args);
        if (!play) soundStop(...args);
    }

    function getSoundInfo(currentPage: ICurrentPage) {
        const bundle: IBundle | undefined = state.bundles?.[currentPage?.bundleId];
        const category: ICategory | undefined = state.categories?.[currentPage?.name];
        const soundKey = getSoundKeyForCurrentPage(category, bundle);
        if (currentPage?.isBundlePage && bundle && !isEmptyObject(bundle?.audioTrack)) {
            return [soundKey, bundle.audioTrack.mp3];
        } else if (category && !isEmptyObject(category?.audioTrack)) {
            return [soundKey, category.audioTrack.mp3];
        } else if (category && !isEmptyObject(category?.parentCategoryData?.audioTrack)) {
            return [soundKey, category.parentCategoryData.audioTrack.mp3];
        }
        return [];
    }

    function handleVisibilityChange() {
        if (!currentSoundKey.current) return;
        toggleSound(document.visibilityState !== 'hidden', currentSoundKey.current);
    }

    React.useEffect(() => {
        Howler.volume(MAX_VOLUME);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            soundStop(currentSoundKey.current);
        };
    }, []);

    // Listen page change
    React.useEffect(() => {
        const [soundKey, soundTrack] = getSoundInfo(state.currentPage);
        if (currentSoundKey.current && soundKey !== currentSoundKey.current) {
            soundStop(currentSoundKey.current);
        }

        currentSoundKey.current = soundKey;
        if (!currentSoundKey.current) return;
        addSound(currentSoundKey.current, soundTrack);
        soundPlay(currentSoundKey.current);
    }, [state.currentPage?.name, state.currentPage?.bundleId]);

    // Listen game client events and sound status
    React.useEffect(() => {
        const currentSoundStatus = getSoundStatus(currentSoundKey.current);
        const isSoundPlaying = currentSoundStatus && !isPortPreviewVisible() && !isVideoWithSoundPlaying();
        const withFade = isVideoWithSoundPlaying() || wasSoundFaded.current;
        toggleSound(isSoundPlaying, currentSoundKey.current);
        wasSoundFaded.current = withFade && !isSoundPlaying;
    }, [state.soundStatus, isPortPreviewVisible(), isVideoWithSoundPlaying()]);
};

export default usePlayMainTheme;
