import * as React from 'react';
import styles from './HololiveAnimation.scss';
import Particles from 'react-particles';
import { getStaticUrl } from '~/utils/utils';

interface IHololiveAnimation {
    children: React.ReactChild | React.ReactChild[];
}

const HololiveAnimation = ({ children }: IHololiveAnimation) => {
    const [isVisible, toggleVisibility] = React.useState(false);

    return (
        <div className={styles.wrapper} onMouseEnter={() => toggleVisibility(true)} onMouseLeave={() => toggleVisibility(false)}>
            {children}
            {isVisible && (
                <div className={styles.animations}>
                    <Particles
                        className={styles.canvas}
                        params={{
                            particles: {
                                number: {
                                    value: 120,
                                },
                                shape: {
                                    type: ['image'],
                                    image: [
                                        {
                                            src: getStaticUrl(require('../../assets/images/sparks/hololive/hololive_1.png')),
                                        },
                                        {
                                            src: getStaticUrl(require('../../assets/images/sparks/hololive/hololive_2.png')),
                                        },
                                        {
                                            src: getStaticUrl(require('../../assets/images/sparks/hololive/hololive_3.png')),
                                        },
                                    ],
                                },
                                size: {
                                    value: 25,
                                    random: true,
                                    anim: {
                                        size_min: 0,
                                        speed: 4,
                                        sync: false,
                                    },
                                },
                                opacity: {
                                    anim: {
                                        enable: true,
                                        speed: 1.8,
                                        opacity_min: 0.9,
                                    },
                                },
                                line_linked: {
                                    enable: false,
                                },
                                move: {
                                    enable: false,
                                },
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default HololiveAnimation;
