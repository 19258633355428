import * as React from 'react';
import styles from './CouponWrapper.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { isCouponsTabHidden, isEnabledCouponFromBundle, selectCouponFromBundle, sortedCoupons } from '~/utils/coupons';
import { arrayToObjectByKey } from '~/utils/utils';
import { removeActivatedCoupon } from '~/Actions/ActionAccount';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { interpolate, ngettext, t } from '~/utils/localization';
import Coupon from '~/components/Coupon/Coupon';
import ChangeCouponButton from '~/components/ChangeCouponButton/ChangeCouponButton';
import { DivTooltip } from '@wg/wows-react-uikit';
import classNames from 'classnames';
import { POPUPS_NAME } from '~/components/PopupManager';

interface ICouponWrapper {
    bundle: IBundle;
    isDisableMultiplePurchaseWithCoupon?: boolean;
}

interface IStateSelector {
    activeCouponId: number;
    prevActiveCouponId: number;
    coupons: ICoupon[];
    popupActive: IPopup;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        activeCouponId: state.ReducerAccount.activeCouponId,
        coupons: state.ReducerAccount.coupons,
        prevActiveCouponId: state.ReducerAccount.prevActiveCouponId,
        popupActive: state.ReducerApp.popupActive,
    };
};

const CouponWrapper = ({ bundle, isDisableMultiplePurchaseWithCoupon }: ICouponWrapper) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [currentCouponId, setCurrentCouponId] = React.useState<number>(state.prevActiveCouponId);
    const availableCoupons = state.coupons.filter((coupon: ICoupon) => isEnabledCouponFromBundle(coupon, bundle));
    const couponMap = arrayToObjectByKey(availableCoupons, 'id');
    const couponId = state.activeCouponId || currentCouponId;
    const firstAvailableCoupon = sortedCoupons(availableCoupons, 1)[0];
    const visibleCoupon = couponId ? couponMap[couponId] ?? firstAvailableCoupon : firstAvailableCoupon;

    const removeCoupon = () => {
        dispatch(removeActivatedCoupon(state.activeCouponId));
        dwhExport.send(DWH_EVENTS.RESET_COUPON, {
            bundle_id: bundle.id,
            coupon_id: state.activeCouponId,
        });
    };

    const applyCoupon = () => {
        dwhExport.send(DWH_EVENTS.APPLY_COUPON, {
            bundle_id: bundle.id,
            coupon_id: visibleCoupon.id,
        });
        selectCouponFromBundle(availableCoupons, visibleCoupon, bundle);
    };

    const onClick = () => {
        if (isDisableMultiplePurchaseWithCoupon) {
            return;
        }

        if (state.activeCouponId) {
            removeCoupon();
            return;
        }

        applyCoupon();
    };

    const showMoreCoupons = () => {
        if (isDisableMultiplePurchaseWithCoupon) {
            return;
        }
        selectCouponFromBundle(availableCoupons, null, bundle);
    };

    React.useEffect(() => {
        if (state.prevActiveCouponId) {
            setCurrentCouponId(state.prevActiveCouponId);
        }
        if (state.popupActive?.name === POPUPS_NAME.SUCCESS_PURCHASE) {
            setCurrentCouponId(null);
        }
    });

    if (isCouponsTabHidden()) {
        return null;
    }

    const tooltipContent = isDisableMultiplePurchaseWithCoupon ? <DefaultTooltip text={t('Использование купона возможно только при обмене на один предмет')} /> : null;

    const shownMoreCouponsText = interpolate(ngettext('Показать все {count} купон', 'Показать все {count} купона', availableCoupons.length), {
        count: availableCoupons.length,
    });

    const classesWrapper = classNames(styles.wrapper, {
        [styles.lock]: isDisableMultiplePurchaseWithCoupon,
    });

    const classesOtherCoupons = classNames(styles.otherCoupons, {
        [styles.lock]: isDisableMultiplePurchaseWithCoupon,
    });

    const isActive = state.activeCouponId === visibleCoupon?.id;

    return (
        <DivTooltip tooltipBody={tooltipContent} className={classesWrapper}>
            <div className={styles.activeCoupon}>
                <Coupon coupon={visibleCoupon} onClick={onClick} bundle={bundle} usePriceWithDiscount isActive={isActive} hiddenCounter={true} disableHover={isDisableMultiplePurchaseWithCoupon} />
            </div>
            {availableCoupons?.length > 1 && (
                <div className={classesOtherCoupons}>
                    <div className={styles.otherCouponsLabel}>{t('или')}</div>
                    <ChangeCouponButton onClick={showMoreCoupons} />
                </div>
            )}
        </DivTooltip>
    );
};

export default CouponWrapper;
