import * as React from 'react';
import styles from './FeaturesLayout.scss';
import MainFeaturesLayout from '~/Layouts/Features/Views/MainFeaturesLayout/MainFeaturesLayout';
import { getShipFeatures } from '~/utils/getShipFeatures';

interface IFeaturesLayout {
    shipId: number;
    featuresTagsPerCategory?: IFeatureTagPerCategory[];
}

const FeaturesLayout = ({ shipId, featuresTagsPerCategory }: IFeaturesLayout) => {
    const [currentFeaturesTagsPerCategory, setCurrentFeaturesTagsPerCategory] = React.useState<IFeatureTagPerCategory[] | undefined>(featuresTagsPerCategory);

    React.useLayoutEffect(() => {
        !featuresTagsPerCategory && getShipFeatures(shipId).then((data) => setCurrentFeaturesTagsPerCategory(data.featuresTagsPerCategory));
    }, []);

    return (
        <div className={styles.features}>
            {currentFeaturesTagsPerCategory &&
                currentFeaturesTagsPerCategory.map(({ category, featuresTags }, key) => {
                    if (!featuresTags.every((tag) => tag.mark)) {
                        return null;
                    }
                    return (
                        <div className={styles.feature} key={key}>
                            <div className={styles.featureIcon} style={{ backgroundImage: `url(${category.icons.default})` }} />
                            <div className={styles.featureContent}>
                                <div className={styles.featureTitle}>{category.mark}</div>
                                <MainFeaturesLayout featuresTags={featuresTags} className={styles.featureLine} />
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default FeaturesLayout;
