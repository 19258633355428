import store from '~/Store';
import { interpolate, t } from '~/utils/localization';
import { AUCTION_KIND, AUCTION_STATUS, BID_STATUS, CATEGORIES, CURRENCY } from '~/const';
import { getBalanceByKey } from '~/utils/purchase';
import { armoryState, settings } from '~/utils/settings';
import { getUrl, urlsMap } from '~/utils/menu';
import { getClientPath, isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import History from '~/utils/history';
import { items } from '@wg/wows-entities/const';
import { BID_STATUSES } from '~/types/auction';

export const getFinishedStatusTitle = (status: BID_STATUSES) => {
    switch (status) {
        case BID_STATUS.WON:
        case BID_STATUS.WON_REWARD_SENT:
        case BID_STATUS.WON_SENDING_REWARD:
        case BID_STATUS.WON_REWARD_SENDING_ERROR:
            return t('Ваша ставка выиграла');

        case BID_STATUS.LOST:
        case BID_STATUS.LOST_MONEY_RETURNING:
        case BID_STATUS.LOST_MONEY_RETURNED:
        case BID_STATUS.LOST_MONEY_RETURNING_ERROR:
            return t('Ваша ставка не прошла');
        case BID_STATUS.LOST_MINIMAL_WON_BID_TIME:
            return t('Ваша ставка не выиграла, потому что была сделана позже других ставок того же размера.');
    }
};

export const getFinishedStatusSubTitle = (status: BID_STATUSES) => {
    switch (status) {
        case BID_STATUS.WON:
        case BID_STATUS.WON_SENDING_REWARD:
        case BID_STATUS.WON_REWARD_SENDING_ERROR:
            return t('Имущество будет начисленно на аккаунт');

        case BID_STATUS.WON_REWARD_SENT:
            return t('Имущество начисленно на аккаунт');

        case BID_STATUS.LOST:
        case BID_STATUS.LOST_MONEY_RETURNING:
        case BID_STATUS.LOST_MONEY_RETURNING_ERROR:
            return t('Ставка будет возвращена на ваш счет');

        case BID_STATUS.LOST_MONEY_RETURNED:
        case BID_STATUS.LOST_MINIMAL_WON_BID_TIME:
            return t('Ставка возвращена на ваш счет');
    }
};

export const getNoticeByKind = (kind: AuctionKind) => {
    if (kind === AUCTION_KIND.SECOND_PRICE) {
        return t('Чем выше ставка, тем больше шансов выиграть набор из лота. В случае победы вы заплатите минимальную сыгравшую ставку, а остальная часть средств будет возвращена на ваш счёт.');
    } else {
        return t('Победившая в аукционе ставка расходуется на приобретение набора из лота.') + ' ' + t('Чем выше ставка, тем больше шансов выиграть аукцион.');
    }
};

export enum AUCTION_ACTION_POST_PREFIX {
    WON_POPUP = 'won_popup_viewed',
    ERROR_BID = 'placing_error_bid_popup',
    PROMO_LOT_VIDEO = 'video_viewed',
    GUIDE_TIP = 'guide_tip',
}

export const getActionIdWithPostPrefixByType = (id: string, prefix: AUCTION_ACTION_POST_PREFIX): string => {
    return `${id}_${prefix}`;
};

const ALREADY_HAVE_LOT_TEXT: {
    [key: string]: string;
} = {
    ship: t('Корабль получен'),
};

export const getAlreadyHaveText = (type: string) => {
    return ALREADY_HAVE_LOT_TEXT[type] || t('Набор получен');
};
export function getLotById(lotId: string): ILot | null;
export function getLotById(lotId: string, auctions?: IAuction[]): ILot | null {
    if (!lotId) {
        return null;
    }

    let availableAuctions = auctions;

    if (!availableAuctions || !availableAuctions.length) {
        availableAuctions = store.getState().ReducerAuction.activeAuctions;
        if (!availableAuctions) return null;
    }

    for (let i = 0; i < availableAuctions.length; i++) {
        const auction = availableAuctions[i];
        const lot = auction.lots.find((lot) => lot.id === lotId);

        if (lot) return lot;
    }

    return null;
}

export function getLotByDisplayId(displayId: string, auctions: IAuction[]): ILot | null {
    if (!displayId) {
        return null;
    }

    for (let i = 0; i < auctions.length; i++) {
        const auction = auctions[i];
        const lot = auction.lots.find((lot: ILot) => lot.displayId == displayId);

        if (lot) return lot;
    }

    return null;
}

export const isWon = (status: BID_STATUSES) => {
    return [BID_STATUS.WON_SENDING_REWARD, BID_STATUS.WON_REWARD_SENT, BID_STATUS.WON, BID_STATUS.WON_REWARD_SENDING_ERROR].includes(status);
};

export const isLost = (status: BID_STATUSES) => {
    return [BID_STATUS.LOST, BID_STATUS.LOST_MONEY_RETURNED, BID_STATUS.LOST_MONEY_RETURNING, BID_STATUS.LOST_MONEY_RETURNING_ERROR, BID_STATUS.LOST_MINIMAL_WON_BID_TIME].includes(status);
};

export const isWaitingWinners = (status: AUCTION_STATUSES) => {
    return [AUCTION_STATUS.CHOOSING_WINNERS].includes(status);
};

export const isPremiumCurrency = (currency: string) => {
    return currency === CURRENCY.WARSHIPS_PREMIUM || currency === CURRENCY.GENERAL_PREMIUM;
};

export const isEnoughCurrency = (currency: string, amount: number, balance: IBalance) => {
    const wallet = getBalanceByKey(balance, currency);
    return wallet.value >= amount;
};

export const getNeededAmount = (currency: string, amount: number, balance: IBalance) => {
    if (!balance) {
        return 0;
    }
    const wallet = balance && getBalanceByKey(balance, currency);
    return Math.abs(wallet.value - amount);
};

export const isAuctionTabDisabled = () => {
    return settings.isAuctionTabDisabled || !isAuctionCategoryExists();
};

export const isAuctionCategoryExists = () => {
    return armoryState.content.categories?.find((category) => category.name === CATEGORIES.AUCTION);
};

export const getCommonItem = (lot: ILot) => lot.gameRewards?.[0];

export const finishedStatuses = [AUCTION_STATUS.FINISHED, AUCTION_STATUS.REWARDING, AUCTION_STATUS.REWARDING_ERROR, AUCTION_STATUS.CHOOSING_WINNERS];

let timeoutHeadShakeAnimation: NodeJS.Timeout = null;
export const startHeadShakeAnimation = (wrapper: HTMLDivElement, className: string) => {
    if (timeoutHeadShakeAnimation || !wrapper || !className) {
        return;
    }
    wrapper.classList.add(className);
    timeoutHeadShakeAnimation = setTimeout(() => {
        wrapper?.classList?.remove?.(className);
        clearTimeout(timeoutHeadShakeAnimation);
        timeoutHeadShakeAnimation = null;
    }, 1000);
};

export const getPreviewVideo = (lot: ILot) => {
    if (isInGameBrowser && lot.previewClientVideoName) {
        return getClientPath(`armory/${lot.previewVideoUrl}`);
    }

    return lot.previewVideoUrl;
};

export const openLotById = (id: string) => {
    History.navigate(getUrl(urlsMap.auctionLot, { id }));
};

export const isAvailableShinyWonTemplate = (activeBids: IBids[]) => {
    return !isMobileOrTabletWindow && activeBids.some((bid) => bid.lot.gameRewards.some((reward) => reward.type === items.VEHICLES));
};

export const getBidWithShipFromBids = (bids: IBids[]) => {
    return bids.find((bid) => bid.lot.gameRewards.some((reward) => reward.type === items.VEHICLES));
};

export const hasLotForPremiumDays = (auctions: IAuction[]) => {
    const auctionsLotPremium = auctions.map((auction) => {
        return !!auction.lots.find((lot) => lot.currency === CURRENCY.WARSHIPS_PREMIUM);
    });

    return auctionsLotPremium.includes(true);
};

export const getPremiumText = (activeAuctions: IAuction[], accountPremiumSeconds: number) => {
    const daysLeft = accountPremiumSeconds / 86400;
    let hoursLeft = Math.ceil((accountPremiumSeconds / 3600) % 24);

    hoursLeft = hoursLeft > 23 ? 23 : hoursLeft;

    if (daysLeft < 1) {
        return interpolate(t('{count} ч'), { count: hoursLeft });
    }

    return interpolate(t('{days} д {hours} ч'), { days: Math.floor(daysLeft), hours: hoursLeft });
};

export const getShip = (lot: ILot) => {
    return lot.items.find((item) => item.type === items.VEHICLES);
};
