import * as React from 'react';
import styles from './VideoPlayer.scss';
import { t } from '~/utils/localization';

interface ISkipLabel {
    callback: () => void;
}

const VideoSkipLabel = ({ callback }: ISkipLabel) => {
    const [isVisible, setVisible] = React.useState<boolean>(false);
    const visibleRef = React.useRef<boolean>(isVisible);

    React.useEffect(() => {
        let lastTimeClick: number = null;
        const onkeydown = () => {
            if (lastTimeClick && Date.now() - lastTimeClick < 500) {
                return;
            }
            if (!lastTimeClick) {
                lastTimeClick = Date.now();
            }
            if (!visibleRef.current) {
                setVisible(true);
                visibleRef.current = true;
            } else {
                callback?.();
            }
        };

        const onmousedown = () => {
            if (!visibleRef.current) {
                setVisible(true);
                visibleRef.current = true;
            }
        };

        document.addEventListener('keydown', onkeydown);
        document.addEventListener('mousedown', onmousedown);

        return () => {
            document.removeEventListener('keydown', onkeydown);
            document.removeEventListener('mousedown', onmousedown);
        };
    }, []);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.skipContent}>
            <div className={styles.skipLabel}>{t('Нажмите любую клавишу, чтобы пропустить')}</div>
        </div>
    );
};

export default VideoSkipLabel;
