import * as React from 'react';
import { getDescriptionContentByType } from '~/Layouts/BundlePage/BundlePageDescription/content';

interface IBundlePageDescription {
    bundle: IBundle;
}

const BundlePageDescription = ({ bundle }: IBundlePageDescription) => {
    const descriptionContent = getDescriptionContentByType(bundle);

    React.useEffect(() => {
        window.WoWSEntities.parseDocument();
    }, [bundle.description, bundle.descriptionAdditional]);

    return <>{descriptionContent}</>;
};

export default BundlePageDescription;
