import * as React from 'react';
import styles from './ForbiddenRegion.scss';
import { interpolate, t } from '~/utils/localization';
import { openUrl } from '~/utils/settings';
import { RegionalInfo } from '@wg/wows-commerce/api/user';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

interface IForbiddenRegion {
    data: Nullable<RegionalInfo>;
}

const ForbiddenRegion = ({ data }: IForbiddenRegion) => {
    const { isPopupActive } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTimeout(() => {
            openUrl(data.redirectUrl);
        }, data.redirectDelay || 0);
    }, []);

    const close = () => {
        dispatch(changeVisiblePopup(null));
    };

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        close();
    }, [KEYS_CODE.ESC]);

    return (
        <div className={styles.forbiddenPage}>
            <div className={styles.close}>
                <ButtonEsc onClick={close} />
            </div>
            <div className={styles.forbidden}>
                <div
                    className={styles.forbiddenDescription}
                    dangerouslySetInnerHTML={{
                        __html: interpolate(t('К сожалению, внутриигровой Премиум магазин недоступен в вашем регионе.{br} Вы будете перенаправлены на страницу магазина во внешнем браузере.'), {
                            br: '<br/>',
                        }),
                    }}
                />
            </div>
        </div>
    );
};

export default ForbiddenRegion;
