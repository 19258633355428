import {
    IPlacedBid,
    IRemoveLotFromTransaction,
    IAddLotInTransaction,
    IStartAuction,
    IUpdateAuctionState,
    IUpdateAuctionAccountState,
    IChangeLotBackground,
    ISetBidLot,
    IFinishAuction,
    IZoomLotBackground,
} from '~/Actions/ActionAuctionTypes';

export const START_AUCTION = 'START_AUCTION';
export const startAuction = (auctionId: string): IStartAuction => {
    return {
        type: START_AUCTION,
        auctionId,
    };
};

export const PLACED_BID = 'PLACED_BID';
export const placedBid = (lotId: string, amount: number, currency: string): IPlacedBid => {
    return {
        type: PLACED_BID,
        lotId,
        amount,
        currency,
    };
};

export const UPDATE_AUCTION_STATE = 'UPDATE_AUCTION_STATE';
export const updateAuctionState = (auctions: IAuction[], account: IAuctionAccountState, minimalWonBids: IAuctionMinimalWonBids[]): IUpdateAuctionState => {
    return {
        type: UPDATE_AUCTION_STATE,
        activeAuctions: auctions,
        account,
        minimalWonBids,
    };
};

export const UPDATE_AUCTION_ACCOUNT_STATE = 'UPDATE_AUCTION_ACCOUNT_STATE';
export const updateAuctionAccountState = (account: IAuctionAccountState): IUpdateAuctionAccountState => {
    return {
        type: UPDATE_AUCTION_ACCOUNT_STATE,
        account,
    };
};

export const ADD_LOT_TO_TRANSACTION = 'ADD_LOT_TO_TRANSACTION';
export const addLotInTransaction = (lotId: string): IAddLotInTransaction => {
    return {
        type: ADD_LOT_TO_TRANSACTION,
        lotId,
    };
};

export const REMOVE_LOT_FROM_TRANSACTION = 'REMOVE_LOT_FROM_TRANSACTION';
export const removeLotFromTransaction = (lotId: string): IRemoveLotFromTransaction => {
    return {
        type: REMOVE_LOT_FROM_TRANSACTION,
        lotId,
    };
};

export const CHANGE_LOT_BACKGROUND = 'CHANGE_LOT_BACKGROUND';
export const changeLotBackground = (backgroundUrl: string, backgroundColor?: string, backgroundUrl4K?: string): IChangeLotBackground => {
    return {
        type: CHANGE_LOT_BACKGROUND,
        backgroundUrl,
        backgroundColor,
        backgroundUrl4K,
    };
};

export const SET_BID_LOT = 'SET_BID_LOT';
export const setBidLot = (lotId: string, bid: number): ISetBidLot => {
    return {
        type: SET_BID_LOT,
        lotId,
        bid,
    };
};

export const FINISH_AUCTION = 'FINISH_AUCTION';
export const finishAuction = (auctionId: string): IFinishAuction => {
    return {
        type: FINISH_AUCTION,
        auctionId,
    };
};

export const ZOOM_LOT_BACKGROUND = 'ZOOM_LOT_BACKGROUND';
export const zoomLotBackground = (zoomLotBackground: boolean): IZoomLotBackground => {
    return {
        type: ZOOM_LOT_BACKGROUND,
        zoomLotBackground,
    };
};
