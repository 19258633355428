import * as React from 'react';
import styles from './FrostAnimation.scss';
import classNames from 'classnames';

interface IFrostAnimation {
    isDisableTransition?: boolean;
    children: React.ReactChild | React.ReactChild[];
    isDisabled: boolean;
    mainColor: string;
}

const FrostAnimation = ({ isDisableTransition, children, mainColor, isDisabled }: IFrostAnimation) => {
    const classes = classNames(styles.animation, {
        [styles.withoutTransition]: isDisableTransition,
        [styles.disableAnimation]: isDisabled,
    });

    const style = (mainColor ? { '--frost-animation-color': mainColor } : {}) as React.CSSProperties;

    return (
        <>
            <span className={classes} style={style} />
            {children}
        </>
    );
};

export default FrostAnimation;
