import equal from 'fast-deep-equal/react';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { getSoundKeyForCurrentPage, isEnabledSounds } from '~/utils/sounds/settings';

interface IStateSelector {
    soundStatus: SoundStatus;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        soundStatus: state.ReducerApp.soundStatus,
    };
};

export function useAvailableSound(category: ICategory, bundle: IBundle) {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const soundKey = getSoundKeyForCurrentPage(category, bundle);
    if (!soundKey) {
        return false;
    }
    return isEnabledSounds(soundKey) || state.soundStatus[soundKey];
}
