import React from 'react';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';

import { State } from '~/Reducers';
import { PortType } from '../../settings';
import { BundlePurchaseTypes } from '~/types/bundle';
import { Currencies } from '@wg/wows-entities/const';

import Currency from '~/components/Currency/Currency';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';

import styles from './PortHeaderBlock.scss';
import { isFreeBundle } from '~/utils/bundles';

interface PortHeaderBlockBaseProps {
    onClose?: () => void;
}

interface PortHeaderBlockDefaultProps extends PortHeaderBlockBaseProps {
    portType: PortType.DEFAULT;
}

interface PortHeaderBlockPurchaseProps extends PortHeaderBlockBaseProps {
    portType: PortType.PURCHASE;

    bundle?: IBundle;
}

type PortHeaderBlockProps = PortHeaderBlockDefaultProps | PortHeaderBlockPurchaseProps;

interface StateSelector {
    balance?: IBalance;
}

function stateSelector(state: State): StateSelector {
    return {
        balance: state.ReducerAccount.balance,
    };
}

export function PortHeaderBlock(props: PortHeaderBlockProps) {
    const { balance } = useSelector<State, StateSelector>(stateSelector, equal);

    const isNeedToRenderCurrencies =
        props.portType === PortType.PURCHASE && props.bundle && !(props.bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) && !isFreeBundle(props.bundle) && balance;
    const isNeedToRenderAdditionalSteel =
        isNeedToRenderCurrencies &&
        ((props.bundle.currency === Currencies.COAL && props.bundle.additionalCurrency !== Currencies.STEEL) ||
            (props.bundle.additionalCurrency === Currencies.COAL && props.bundle.currency !== Currencies.STEEL));

    const currenciesData = isNeedToRenderCurrencies
        ? balance.map((balanceData) => {
              if (
                  props.bundle.currency === balanceData.currency ||
                  props.bundle.additionalCurrency === balanceData.currency ||
                  (isNeedToRenderAdditionalSteel && balanceData.currency === Currencies.STEEL)
              ) {
                  return (
                      <div className={styles.currencyWrapper} key={`port_currency_${balanceData.currency}_${balanceData.value}`}>
                          <Currency className={styles.currency} currency={balanceData.currency} amount={balanceData.value} />
                      </div>
                  );
              }

              return null;
          })
        : null;

    return (
        <div className={styles.wrapper}>
            {isNeedToRenderCurrencies ? <div className={styles.currenciesWrapper}>{currenciesData}</div> : null}
            <ButtonEsc onClick={props.onClose} />
        </div>
    );
}
