import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import WoWsCommerceHelper from '~/core/WowsCommerce/WoWsCommerceHelper';
import { logError } from '~/utils/logging';
import { urlsMap } from '~/utils/menu';

export default class PremiumShopPreloader extends AbstractPreloader {
    async load(): Promise<1> {
        try {
            if (location.pathname.includes(urlsMap.premShop)) {
                await new WoWsCommerceHelper().initialization();
            } else {
                await new WoWsCommerceHelper().initialization();
                return Promise.resolve(1);
            }
        } catch (e) {
            logError('Failed to preload Premium Shop', e);
        }
    }
}
