import * as React from 'react';
import { getBundleInnerBackground } from '~/utils/background';
import styles from './SearchPanel.scss';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import { blurView, changeViewBackground, onSearch, resetCategoryFilter, resetSearch, setSortMethod, updateActiveFilterPreset } from '~/Actions/ActionApp';
import { getBundleCurrenciesMap, isBundleDisabledByPromoTimer, isParagonBundle } from '~/utils/bundles';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items as ITEMS } from '@wg/wows-entities/const';
import { openBundleByUrl, openCategoryByName } from '~/utils/category';
import useClickAway from '~/hooks/useClickAway';
import { KEYS_CODE } from '~/hooks/useKeyDown';
import Currency from '~/components/Currency/Currency';
import { disableDocumentScroll, findParentNode } from '~/utils/dom';
import Account from '~/account/Account';
import { isMobileOrTabletWindow } from '~/utils/utils';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { IChangeViewBackground, IResetCategoryFilter, IUpdateActiveFilterPreset } from '~/Actions/ActionAppType';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import PromotionLabel from '~/components/PromotionLabel/PromotionLabel';
import { BundlePurchaseTypes } from '~/types/bundle';
import Price from '../Price/Price';
import { playInputSound, playButtonClickSound } from '~/api/WoWsClient';
import { DivTooltip } from '@wg/wows-react-uikit';
import PromoTimerTooltip from '~/components/Bundle/PromoTimer/PromoTimerTooltip';
import { scrollToBundle } from '~/utils/scrollTop';
import { getLandingCategory } from '~/utils/category';

interface ISearchResult {
    item: ISearchResultItem;
    isSelected?: boolean;
    onClick?: () => void;
    index: number;
}

export const LIMIT_SEARCH_NAME_REQUEST = 50;

const SearchResultItem = (props: ISearchResult): React.ReactChild | any => {
    const dispatch = useDispatch();
    const appState = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);
    const bundle = appState.bundles[props.item.bundleId];
    const primaryItem = bundle.primaryItem;
    const isNeededRenderWoWsEntity = !bundle.denyTitleModification && primaryItem && primaryItem.type === ITEMS.VEHICLES;
    const isDisabledByTimer = isBundleDisabledByPromoTimer(bundle);

    const openBundle = () => {
        if (isDisabledByTimer) {
            openCategoryByName(bundle.categories[0]);
            scrollToBundle(bundle.id);
        } else {
            dispatch<IChangeViewBackground>(changeViewBackground(null, getBundleInnerBackground(bundle)));
            openBundleByUrl(bundle.categories[0], bundle.id);
        }

        if (appState.activePreset) {
            dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(null));
        }
        dispatch<IResetCategoryFilter>(resetCategoryFilter(appState.currentPage?.name));
        if (appState.sortedBy?.[appState.currentPage?.name]?.name) {
            dispatch(setSortMethod(appState.currentPage?.name, null, null));
        }
        props.onClick && props.onClick();
    };

    const itemClassNames = classNames(styles.item, {
        [styles.active]: props.isSelected,
    });

    if (!Account.isParagonsEnabled() && isParagonBundle(bundle)) {
        return null;
    }

    const pricesMap = getBundleCurrenciesMap(bundle);

    const currencies = Object.keys(pricesMap).reduce((result: React.ReactChild[], currency: string, index: number) => {
        const value = pricesMap[currency];
        if (isDisabledByTimer) {
            result.push(
                <DivTooltip className={styles.timerIconWrapper} tooltipBody={<PromoTimerTooltip bundle={bundle} />}>
                    <img src={require('~/assets/images/icon_clock_timer.svg')} alt="" />
                </DivTooltip>,
            );
        } else if (bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) {
            if (!bundle.isLoading) {
                if (bundle.originalProduct?.price > 0) {
                    result.push(<Price isRealPurchase formattedPrice={bundle.currency} currency={bundle.currency} className={styles.realPrice} />);
                } else {
                    result.push(<PromotionLabel text={t('Бесплатно')} />);
                }
            }
        } else if (!value) {
            result.push(<PromotionLabel text={t('Бесплатно')} />);
        } else {
            result.push(<Currency key={index} currency={currency} />);
        }

        if (index + 1 !== Object.keys(pricesMap).length) {
            result.push(
                <div key={`currencies_plus_${index}`} className={styles.currencyJoin}>
                    +
                </div>,
            );
        }

        return result;
    }, []);

    const bundleCurrenciesForAutoTest = Object.keys(pricesMap)
        .map((currency) => currency)
        .join('_');

    return (
        <div
            className={itemClassNames}
            onClick={() => {
                playButtonClickSound();
                openBundle();
            }}
            data-index={props.index}
            data-bundle-id={props.item.bundleId}
            data-bundle-currencies={bundleCurrenciesForAutoTest}
            data-bundle-name={props.item.title}
        >
            <div className={styles.itemTitle}>
                {isNeededRenderWoWsEntity ? (
                    <WoWSEntity key={primaryItem.identifier} type={primaryItem.type} id={primaryItem.identifier} fallback={(nodeElement: Node) => (nodeElement.textContent = bundle.title)} />
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: props.item.title }} />
                )}
            </div>
            <div className={styles.currencies}>{currencies}</div>
        </div>
    );
};

export const scrollToSelectedItem = (refs: Map<number, HTMLDivElement>, wrapper: HTMLDivElement, index: number = null) => {
    const selectedItem = refs.get(index);

    if (!selectedItem || !wrapper) {
        return;
    }

    if (selectedItem.offsetTop + selectedItem.offsetHeight > wrapper.clientHeight + wrapper.scrollTop) {
        wrapper.scrollTop = selectedItem.offsetTop + selectedItem.offsetHeight - wrapper.clientHeight;
    } else if (selectedItem.offsetTop < wrapper.scrollTop) {
        wrapper.scrollTop = selectedItem.offsetTop;
    }
};

export interface ISearchPanel {
    onFocus?: () => void;
    onBlur?: () => void;
    isActive?: boolean;
    isMobile?: boolean;
    className?: {
        input?: string;
    };
}

const disableDocumentScrollIfNeeded = (isDisabled = false) => {
    if (!isMobileOrTabletWindow) {
        return;
    }

    disableDocumentScroll(isDisabled);
};

const searchTitle = t('Поиск');

const SearchPanel = (props: ISearchPanel): React.ReactChild | any => {
    const dispatch = useDispatch();
    const appState = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);
    const ref: React.RefObject<HTMLInputElement> = React.useRef(null);
    const resultItems = React.useRef<Map<number, HTMLDivElement>>(new Map<number, HTMLDivElement>());
    const refResultsWrapper: React.RefObject<HTMLInputElement> = React.useRef(null);
    const searchResultsContainer: React.RefObject<HTMLDivElement> = React.useRef(null);
    const landingCategory = getLandingCategory(appState.categories);
    const searchResults = appState.searchResults?.filter((item: ISearchResultItem) => {
        return !((!Account.isParagonsEnabled() && item.isParagonBundle) || landingCategory?.bundles.includes(item.bundleId));
    });

    const [value, setValue] = React.useState('');
    const [isVisibleSearchResults, setVisibleSearchResults] = React.useState(searchResults && !!searchResults.length);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const changeVisible = (event: React.MouseEvent) => {
        event.stopPropagation();
        setSelectedIndex(-1);
        setVisibleSearchResults(false);
    };

    const inputClassNames = classNames(
        styles.input,
        styles.active,
        {
            [styles.shown]: searchResults && !!searchResults.length && isVisibleSearchResults,
            ['armory__auto--web_search_input']: !props.isMobile,
            ['armory__auto--mobile_search_input']: props.isMobile,
        },
        props.className?.input,
    );

    const blurViewIsNeeded = (isNeeded = false) => {
        if (isMobileOrTabletWindow) {
            dispatch(blurView(isNeeded));
        }
    };

    const onSearchHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;

        if (!value.trim().length) {
            blurViewIsNeeded(false);
            disableDocumentScrollIfNeeded(false);
            dispatch(resetSearch());
            return;
        }

        blurViewIsNeeded(true);
        disableDocumentScrollIfNeeded(true);
        dispatch(onSearch(value.trim()));
    };

    React.useEffect(() => {
        if (!searchResults) {
            setVisibleSearchResults(false);
            return;
        }

        setTimeout(() => {
            setVisibleSearchResults(true);
        }, 150);
    }, [searchResults]);

    React.useEffect(() => {
        scrollToSelectedItem(resultItems.current, refResultsWrapper.current, selectedIndex);
    }, [selectedIndex]);

    const onBeforeInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (target.value.length + 1 > LIMIT_SEARCH_NAME_REQUEST) {
            event.preventDefault();
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const keyCode = event.keyCode;

        if (event.key !== 'Unidentified') {
            playInputSound();
        }

        if (!searchResults) {
            return;
        }

        if (keyCode === KEYS_CODE.UP) {
            event.preventDefault();
            setSelectedIndex(Math.max(selectedIndex - 1, 0));
        } else if (keyCode === KEYS_CODE.DOWN) {
            event.preventDefault();

            let index = selectedIndex + 1;
            if (index >= searchResults.length) {
                index = selectedIndex;
            }

            setSelectedIndex(index);
        } else if (keyCode === KEYS_CODE.ENTER) {
            event.preventDefault();
            target.blur();

            const data = searchResults[selectedIndex];
            if (data) {
                const bundle = appState.bundles[data.bundleId];
                openBundleByUrl(bundle.categories[0], bundle.id);
                dispatch(resetSearch());
                setVisibleSearchResults(false);
            } else {
                target.focus();
            }
        } else {
            setSelectedIndex(0);
        }
    };

    const _resetSearch = () => {
        setValue('');
        dispatch(resetSearch());
        setVisibleSearchResults(false);
        blurViewIsNeeded(false);
        disableDocumentScrollIfNeeded(false);
    };

    React.useEffect(() => {
        _resetSearch();
        dispatch(blurView(false));
    }, [isMobileOrTabletWindow]);

    useClickAway(searchResultsContainer, (event: MouseEvent) => {
        const target = event.target as HTMLDivElement;
        const wrapper = event.target && findParentNode(styles.results, event.target);

        if (wrapper && wrapper?.classList?.contains(styles.results)) {
            return;
        }

        if (target.classList.contains(styles.input)) {
            return;
        }

        if (document.activeElement !== ref.current) {
            return;
        }

        dispatch(resetSearch());

        setVisibleSearchResults(false);
        blurViewIsNeeded(false);
        disableDocumentScrollIfNeeded(false);

        props.onBlur?.();
    });

    const onClick = () => {
        setValue('');
        disableDocumentScrollIfNeeded(false);
        blurViewIsNeeded(false);
        dispatch(resetSearch());
        setVisibleSearchResults(!isVisibleSearchResults);
        dwhExport.send(DWH_EVENTS.SEARCH_GO_TO_RESULT);
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const hasValue = !!event.target.value.length;
        setVisibleSearchResults(hasValue);

        if (hasValue) {
            disableDocumentScrollIfNeeded(true);
            blurViewIsNeeded(true);
            dispatch(onSearch(event.target.value));
        }

        dwhExport.send(DWH_EVENTS.SEARCH_OPEN);

        props.onFocus?.();
    };

    const wrapperClassNames = classNames(styles.wrapper, styles.active);
    const classesResults = classNames(styles.results, {
        ['armory__auto--web_search_results']: !props.isMobile,
        ['armory__auto--mobile_search_results']: props.isMobile,
    });

    const placeholderText = !isMobileOrTabletWindow ? t('Поиск по названию') : t('Поиск');

    return (
        <div className={wrapperClassNames} ref={searchResultsContainer}>
            <GuideDecorator names={!props.isMobile ? [GUIDE_NAMES.guide_search] : null}>
                <div className={styles.inputWrapper}>
                    <div className={styles.icon} onClick={changeVisible} />
                    <input
                        ref={ref}
                        type={'text'}
                        value={value}
                        className={inputClassNames}
                        placeholder={placeholderText}
                        onFocus={onFocus}
                        onChange={(event) => setValue(event.target.value)}
                        onKeyUp={onSearchHandler}
                        onKeyDown={onKeyDown}
                        onBeforeInput={onBeforeInput}
                        autoComplete={'off'}
                        spellCheck={false}
                        maxLength={LIMIT_SEARCH_NAME_REQUEST}
                        data-is-visible={true}
                    />
                    {isVisibleSearchResults && searchResults && isMobileOrTabletWindow && (
                        <div
                            className={styles.reset}
                            onClick={_resetSearch}
                            onTouchEnd={(event) => {
                                event.preventDefault();
                                _resetSearch();
                            }}
                        />
                    )}
                </div>
            </GuideDecorator>
            {searchResults?.length > 0 && isVisibleSearchResults && (
                <div className={classesResults} ref={refResultsWrapper}>
                    {searchResults.map((item: ISearchResultItem, index: number) => {
                        return (
                            <div
                                key={item.bundleId}
                                ref={(ref: HTMLDivElement) => {
                                    if (ref) {
                                        resultItems.current.set(index, ref);
                                    }
                                }}
                            >
                                <SearchResultItem index={index} item={item} onClick={onClick} isSelected={index === selectedIndex} />
                            </div>
                        );
                    })}
                </div>
            )}
            {!!value.length && searchResults && !searchResults.length && isVisibleSearchResults && (
                <div className={classesResults}>
                    <div className={styles.notFound}>{t('Ничего не найдено')}</div>
                </div>
            )}
        </div>
    );
};

export default SearchPanel;
