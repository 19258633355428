import * as React from 'react';
import styles from './RandomBundle.scss';
import classNames from 'classnames';
import { ngettext, t } from '~/utils/localization';
import { _chooseRandomBundle } from '~/utils/purchase';
import { isAdmiralPack, isCommanderPack } from '~/utils/bundles';
import { getUserId } from '~/utils/settings';
import RandomBundleTitle from '~/components/RandomBundleTitle/RandomBundleTitle';
import { isEnabledPurchaseLimitToContainer } from '~/components/BundleLimitWidget/settings';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import { isMobileOrTabletWindow } from '~/utils/utils';
import ShortBundleContains from '~/components/Bundle/Contains/ShortBundleContains';
import { BUNDLE_DECORATION } from '~/types/bundle';
import CountDown from '~/components/CountDown/CountDown';

interface IRandomBundleInfo {
    bundle: IBundle;
    activeBundle: IBundle;
    isDisabledPurchasePeriodicBundle: boolean;
    isTimeUp: boolean;
    isPeriodicBundle: boolean;
    periodicBundleNextDate: number;
    selectedRandomBundles: AccountSelectedRandomBundles;
}

const RandomBundleInfo = (props: IRandomBundleInfo) => {
    const userId = getUserId();
    const primaryItem = props.bundle.primaryItem;
    const _commanderPack = isCommanderPack(props.bundle);
    const _isAdmiralPack = isAdmiralPack(props.bundle);
    const decorateTitle = _commanderPack ? t('Командирский набор') : _isAdmiralPack ? t('Адмиральский набор') : null;
    const _isEnabledPurchaseLimitToContainer = isEnabledPurchaseLimitToContainer(props.activeBundle);

    const randomBundleCube = <RandomBundleTitle bundle={props.bundle} withoutText={true} onlyCounter={!(props.bundle.randomIsTotalCountHidden || props.bundle.randomIsInfinite)} />;

    const isBigItemTemplate = props.bundle?.decoration?.includes(BUNDLE_DECORATION.RANDOM_BIG_ITEM);

    const classesTitle = classNames(styles.title, {
        [styles.bigTemplate]: isBigItemTemplate,
    });

    return (
        <React.Fragment>
            <div className={styles.header}>
                <div
                    className={classNames(styles.icon, {
                        [styles.infinity]: props.bundle.randomIsInfinite,
                        [styles.withBundleLimit]: _isEnabledPurchaseLimitToContainer,
                    })}
                >
                    {!isMobileOrTabletWindow && randomBundleCube}
                    {_isEnabledPurchaseLimitToContainer && <BundleLimitContainer className={styles.bundleLimit} bundle={props.activeBundle} />}
                </div>
                {!!decorateTitle && <div className={styles.decorateTitle}>{decorateTitle}</div>}
                <div className={classesTitle}>
                    <span dangerouslySetInnerHTML={{ __html: props.activeBundle?.title ?? props.bundle.title }} />
                </div>
                <div className={styles.preTitle}>{props.isPeriodicBundle ? t('Периодичный случайный набор') : t('Случайный набор')}</div>
                {!!props.activeBundle?.entitlements?.length ? (
                    <React.Fragment>
                        <div className={styles.randomBundleInfo}>
                            <div className={styles.containsInfo}>
                                {ngettext('{count} в наборе', '{count} в наборе', props.activeBundle.entitlements.length).replace('{count}', String(props.activeBundle.entitlements.length))}
                            </div>
                            {userId && <div className={classNames(styles.mobileCube)}>{randomBundleCube}</div>}
                        </div>
                        <ShortBundleContains
                            className={styles.contains}
                            key={`contains_${props.bundle.categories.join('_')}_${primaryItem?.identifier || props.bundle.id}`}
                            bundle={props.activeBundle}
                            limit={2}
                        />
                    </React.Fragment>
                ) : (
                    <div className={styles.randomBundleInfo}>{userId && <div className={classNames(styles.mobileCube)}>{randomBundleCube}</div>}</div>
                )}
            </div>
            {props.isDisabledPurchasePeriodicBundle && !props.isTimeUp && (
                <div className={styles.periodicBundleContent}>
                    <div className={styles.periodicBundleTitle}>{t('Доступен через:')}</div>
                    <div className={styles.periodicBundleTimerWrapper}>
                        <CountDown
                            date={props.periodicBundleNextDate}
                            className={styles.periodicBundleTimer}
                            withoutIcon
                            onComplete={() => {
                                if (!props.selectedRandomBundles[props.bundle.id]) {
                                    _chooseRandomBundle(props.bundle);
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default React.memo(RandomBundleInfo);
